import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px !important",
  },
  settings: {
    color: "#FFFFFF",
  },
  rootFirstSelect: {
    paddingLeft: "5px",
  },
  dropdownContent: {
    backgroundColor: "#FFFFFF !important",
  },
  menuTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  button: {
    minWidth: "100%",
    justifyContent: "start",
  },

  select: {
    fontWeight: 500,
    fontSize: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      width: 0,
    },
    "& .MuiSelect-iconOutlined": {
      right: "-22px",
      top: "3px",
    },
    "& .MuiSelect-selectMenu": {
      maxWidth: "130px",
    },
  },
  globalSettings: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      borderRadius: "0px",
      cursor: "pointer",
    },
  },
  globalSettingsActive: {
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    "&:hover": {
      // backgroundColor: "rgba(151, 206, 253, 0.2) !important",
    },
  },
  globalSettingsOpen: {
    display: "flex",
    alignItems: "center",
  },
  customBadge: {
    backgroundColor: "rgba(225,228,233,0.7)",
  },
  tooltip: {
    marginTop: "5px",
    width: "223px",
    height: "64px",
    color: "#0C1E33",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  alarmTooltip: {
    marginTop: "5px",
    width: "223px",
    height: "80px",
    color: "#0C1E33",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },

  title: {
    fontWeight: "bold",
    color: "#2D5374",
    backgroundColor: "#DEEFFE",
  },
  input: {
    paddingRight: "0px !important",
  },
  wrapper: {
    padding: theme.spacing(2),
    height: "100%",

    // overflow: "scroll",
    maxHeight: "100%",
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },

  item: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    marginTop: theme.spacing(2),
  },

  label: {
    fontSize: 15,
    fontWeight: 500,
    marginRight: theme.spacing(2),
    alignSelf: "center",
    color: "#0C1E33",
  },
  onHover: {
    opacity: "50%",
    "&:hover": {
      opacity: "100%",
    },
  },
  customTooltip: {
    backgroundColor: "#FFFFFF",
    width: "298px",
    height: "320px",
    bottom: 40,
    borderRadius: "6px",
    zIndex: 1000,

    color: "#0C1E33",
  },
  customArrow: {
    "&:before": {},
    color: theme.palette.common.white,

    position: "absolute",
    width: "100px",
    left: 24,
    marginLeft: "-100px",
  },
}));
