import React from "react";
import Widget from "../Widget";
import { ILine, IYAxis } from "../MultiLineChart";
import Loader from "../Loader";
import { MultiAxisChart } from "../MultiAxisChart";

interface Props {
  title: string;
  lines: Array<ILine>;
  data: Array<any>;
  yAxises?: Array<IYAxis>;
  showYAxises?: boolean;
  customMenu?: React.ReactNode;
  error?: string;
  loading?: boolean;
  tooltipContent?: any;
  height?: number;
  size?: any;
  definition?: any;
  styles?: any;
  toggleLegend?: boolean;
  setLines?: any;
}

export const MultiAxisChartWidget: React.FC<Props> = (props: Props) => {
  const {
    title,
    data,
    lines,
    yAxises,
    showYAxises = true,
    customMenu,
    error,
    loading,
    height,
    tooltipContent,
    size,
    styles,
    toggleLegend = false,
    setLines,
  } = props;
  return (
    <Widget
      label={title}
      customMenu={customMenu}
      height={height}
      size={size}
      styles={styles}
    >
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />

      {!data?.length || loading || error?.length ? null : (
        <MultiAxisChart
          label="sensor_time"
          data={data}
          lines={lines}
          yAxises={yAxises}
          showYAxis={showYAxises}
          tooltipContent={tooltipContent}
          toggleLegend={toggleLegend}
          setLines={setLines}
        />
      )}
    </Widget>
  );
};
