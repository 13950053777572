import React, { useMemo } from "react";
import { FormControl, FormControlLabel, Grid, Skeleton } from "@mui/material";
import {
  AntSwitch,
  DateRange,
  SelectorDropdown,
} from "../../../../../shared/components";
import { useStyles } from "../../styles";
import { ReactComponent as PLANT_BLACK } from "./../../../../../shared/icons/dropdown/PLANT_BLACK.svg";
import { CustomPortfolioIcon } from "./../../../../../shared/icons/dropdown/PORTFOLIO_BLACK.component";
import Divider from "@mui/material/Divider";
import { useGetTimeAgo } from "../../utils";

interface Props {
  selectedPortfolio: any;
  portfolio: Array<any>;
  selectedPlant: any;
  plant: Array<any>;
  date: any;
  setDate: any;
  onChangePlant: (val: string | number) => void;
  onChangePortfolio: (val: string | number) => void;
  handleChangeLiveButton: (val: boolean) => void;
  liveButton: boolean;
}

const getPortfolioIcon = () => {
  return <CustomPortfolioIcon height={22} width={22} />;
};

export const HeaderWidget: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    selectedPortfolio,
    portfolio,
    selectedPlant,
    plant,
    date,
    setDate,
    onChangePortfolio,
    onChangePlant,
    handleChangeLiveButton,
    liveButton,
  } = props;

  const onChangeDate = (item) => {
    setDate([item.selection]);
  };

  const updateTime = useMemo(() => {
    return new Date();
  }, [date, liveButton]);

  const dateCondition = useMemo(() => {
    return date && Array.isArray(date) && date[0]
      ? { date: date[0], liveButton }
      : liveButton;
  }, [date, liveButton]);

  const lastUpdated = useGetTimeAgo(updateTime, dateCondition);

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={12} md={12} lg={8} xl={8}>
          <Grid container alignItems={"center"} justifyContent={"flex-start"}>
            <Grid
              item
              sm={12}
              md={12}
              lg={"auto"}
              xl={"auto"}
              className={classes.firstColumn}
            >
              <FormControl className={classes.selector}>
                <SelectorDropdown
                  Icon={getPortfolioIcon}
                  value={selectedPortfolio}
                  options={portfolio}
                  onChangeValue={(filterValue: string): void =>
                    onChangePortfolio(filterValue)
                  }
                  marginRight="0px"
                />
              </FormControl>
              <FormControl className={classes.divider}>
                <Divider
                  orientation="vertical"
                  variant={"middle"}
                  flexItem
                  style={{ height: "21px" }}
                />
              </FormControl>
              <FormControl className={classes.selector}>
                <SelectorDropdown
                  Icon={PLANT_BLACK}
                  value={selectedPlant}
                  options={plant}
                  onChangeValue={(filterValue: string): void => {
                    onChangePlant(filterValue);
                  }}
                  marginRight="0px"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.horizontalDivier}>
          <Divider orientation="horizontal" flexItem />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          lg={4}
          xl={4}
          sx={{
            paddingTop: 0,
            "@media (max-width: 1199px)": {
              "&.MuiGrid-item": { paddingTop: "4px" },
            },
          }}
        >
          <Grid container className={classes.flexType}>
            <Grid item className={classes.selector}>
              <div className={classes.lastUpdated}>
                Last Updated {lastUpdated}
              </div>
            </Grid>
            <Grid item className={classes.switchMobile}>
              <FormControlLabel
                control={
                  <AntSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    checked={liveButton}
                    onChange={(e) => handleChangeLiveButton(e.target.checked)}
                  />
                }
                label="Live"
              />
            </Grid>
            <Grid item className={classes.selectorDate}>
              <DateRange date={date} onChange={onChangeDate} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
