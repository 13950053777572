import { makeStyles } from "@material-ui/core/styles";
import { GLOBAL_COLORS } from "../../../../../shared/utils/utils";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: theme.spacing(1.1, 3, 1.1, 3),
    borderRadius: theme.spacing(1),
    marginTop: "16px",
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  secondText: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
    display: "flex",
  },
  childDivCommsOn: {
    display: "flex",
    backgroundColor: "#49937D1A",
    alignItems: "center",
    padding: "2px 7px",
    gap: "5px",
    borderRadius: "32px",
  },

  childDivCommsNoComm: {
    display: "flex",
    backgroundColor: "#E6E6E6",
    alignItems: "center",
    padding: "2px 7px",
    borderRadius: "32px",
    gap: "5px",
  },
  childDivCommsOff: {
    display: "flex",
    backgroundColor: "#F8DCDC",
    alignItems: "center",
    padding: "2px 7px",
    borderRadius: "32px",
    gap: "5px",
  },
  divFlex: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    marginLeft: "5px",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
  },
  headerTextCapacity: {
    color: GLOBAL_COLORS.LIGHT_TEXT_SECONDARY
  },
  centerText: {
    alignSelf: "center",
  },
  plantText: {
    fontWeight: 500,
    fontSize: "20px",
  },
  gapBetween: {
    paddingInline: "3px",
  },
  parentDivComms: {
    display: "flex",
    alignItems: "center",
    "@media (min-width: 960px)": {
      justifyContent: "flex-end",
    },
  },
  customBadgeOn: {
    backgroundColor: "#49937D",
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    display: "inline-block",
    animation: "blink 3s infinite",
  },
  customBadgeOff: {
    backgroundColor: "#DE504F",
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    display: "inline-block",
    animation: "blink 3s infinite",
  },
  customBadgeNoComm: {
    backgroundColor: "#808080",
    height: "4px",
    width: "4px",
    borderRadius: "50%",
    display: "inline-block",
    animation: "blink 3s infinite",
  },
  badgeText: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "20px",
  },
  siteInfoButton: {
    color: "#0C1E33 !important",
    height: "41px",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "rgba(225, 228, 233, 1) !important",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: "0px !important",
    },
  },
  dropdownSetting: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
  flex: {
    "@media (max-width: 600px)": {
      flex: "1",
    },
  },
  onOffWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));
