export const weatherIcons = [
  "night",
  "night",
  "night",
  "night",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy-day-3",
  "cloudy-day-3",

  "day",
  "day",

  "cloudy-day-3",
  "cloudy-day-3",

  "day",
  "day",

  "cloudy-day-3",
  "cloudy-day-3",

  "cloudy",
  "cloudy",

  "cloudy-night-3",
  "cloudy-night-3",

  "night",
  "night",

  "cloudy-night-3",
  "cloudy-night-3",

  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",

  "rainy-6",
  "rainy-6",

  "cloudy-day-3",
  "cloudy-day-3",

  "day",
  "day",
  "day",
  "day",

  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",

  "cloudy-night-3",
  "cloudy-night-3",

  "rainy-6",
  "rainy-6",
  "rainy-6",
  "rainy-6",

  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",

  "cloudy-day-3",
  "cloudy-day-3",

  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy",

  "cloudy-day-3",
  "cloudy-day-3",

  "cloudy",
  "cloudy-day-3",

  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",

  "rainy-6",
  "rainy-6",
  "cloudy-night-3",
  "cloudy-night-3",
];

export const areaRange = [
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [22.59, 27.61],
  [63.05, 77.06],
  [122.28, 149.46],
  [207.49, 253.59],
  [251.2, 307.03],
  [256.88, 313.96],
  [221.38, 270.58],
  [225.18, 275.22],
  [270.87, 331.07],
  [270.95, 331.16],
  [274.75, 335.81],
  [225.31, 275.37],
  [214.9, 262.65],
  [126.79, 154.97],
  [31.11, 38.03],
  [6.7, 8.19],
  [0.49, 0.6],
  [0.0, 0.01],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [1.34, 1.64],
  [15.07, 18.41],
  [38.75, 47.36],
  [93.39, 114.15],
  [234.81, 287.0],
  [258.53, 315.98],
  [253.78, 310.18],
  [257.41, 314.61],
  [223.39, 273.03],
  [169.83, 207.57],
  [144.75, 176.92],
  [78.03, 95.37],
  [109.74, 134.12],
  [36.65, 44.8],
  [9.98, 12.2],
  [1.63, 2.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
  [22.45, 41.69],
  [49.53, 91.99],
  [76.94, 142.88],
  [87.03, 161.63],
  [114.27, 212.21],
  [91.0, 169.0],
  [87.39, 162.3],
  [82.58, 153.37],
  [127.3, 236.42],
  [75.4, 140.02],
  [40.46, 75.15],
  [23.06, 42.82],
  [0.0, 0.0],
  [0.0, 0.0],
  [0.0, 0.0],
];

export const categories = [
  "15 Feb, 00:00",
  "15 Feb, 01:00",
  "15 Feb, 02:00",
  "15 Feb, 03:00",
  "15 Feb, 04:00",
  "15 Feb, 05:00",
  "15 Feb, 06:00",
  "15 Feb, 07:00",
  "15 Feb, 08:00",
  "15 Feb, 09:00",
  "15 Feb, 10:00",
  "15 Feb, 11:00",
  "15 Feb, 12:00",
  "15 Feb, 13:00",
  "15 Feb, 14:00",
  "15 Feb, 15:00",
  "15 Feb, 16:00",
  "15 Feb, 17:00",
  "15 Feb, 18:00",
  "15 Feb, 19:00",
  "15 Feb, 20:00",
  "15 Feb, 21:00",
  "15 Feb, 22:00",
  "15 Feb, 23:00",
  "16 Feb, 00:00",
  "16 Feb, 01:00",
  "16 Feb, 02:00",
  "16 Feb, 03:00",
  "16 Feb, 04:00",
  "16 Feb, 05:00",
  "16 Feb, 06:00",
  "16 Feb, 07:00",
  "16 Feb, 08:00",
  "16 Feb, 09:00",
  "16 Feb, 10:00",
  "16 Feb, 11:00",
  "16 Feb, 12:00",
  "16 Feb, 13:00",
  "16 Feb, 14:00",
  "16 Feb, 15:00",
  "16 Feb, 16:00",
  "16 Feb, 17:00",
  "16 Feb, 18:00",
  "16 Feb, 19:00",
  "16 Feb, 20:00",
  "16 Feb, 21:00",
  "16 Feb, 22:00",
  "16 Feb, 23:00",
  "17 Feb, 00:00",
  "17 Feb, 01:00",
  "17 Feb, 02:00",
  "17 Feb, 03:00",
  "17 Feb, 04:00",
  "17 Feb, 05:00",
  "17 Feb, 06:00",
  "17 Feb, 07:00",
  "17 Feb, 08:00",
  "17 Feb, 09:00",
  "17 Feb, 10:00",
  "17 Feb, 11:00",
  "17 Feb, 12:00",
  "17 Feb, 13:00",
  "17 Feb, 14:00",
  "17 Feb, 15:00",
  "17 Feb, 16:00",
  "17 Feb, 17:00",
  "17 Feb, 18:00",
  "17 Feb, 19:00",
  "17 Feb, 20:00",
  "17 Feb, 21:00",
  "17 Feb, 22:00",
  "17 Feb, 23:00",
];

export const powerExpected = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 25.1, 70.05, 135.87, 230.54, 279.11, 285.42,
  245.98, 250.2, 300.97, 301.05, 305.28, 250.34, 238.77, 140.88, 34.57, 7.44,
  0.54, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.49, 16.74, 43.06, 103.77, 260.91,
  287.25, 281.98, 286.01, 248.21, 188.7, 160.83, 86.7, 121.93, 40.72, 11.09,
  1.82, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 32.07, 70.76,
  109.91, 124.33, 163.24, 130.0, 124.84, 117.98, 181.86, 107.71, 57.8, 32.94,
  0.0, 0.0, 0.0,
];

export const powerMeasured = [
  0,
  0,
  0,
  0,
  0,
  0.47,
  27.12,
  64.58,
  { y: 145.23, marker: { enabled: true, radius: 4, fillColor: "#0C1E33" } },
  // 0.0,
  // 0.0,
  // { y: 0.0, marker: { enabled: true, radius: 4, fillColor: "#0C1E33" } },
];
export const xAxisCategories = [
  "7 Feb, 00:00",
  "7 Feb, 01:00",
  "7 Feb, 02:00",
  "7 Feb, 03:00",
  "7 Feb, 04:00",
  "7 Feb, 05:00",
  "7 Feb, 06:00",
  "7 Feb, 07:00",
  "7 Feb, 08:00",
  "7 Feb, 09:00",
  "7 Feb, 10:00",
  "7 Feb, 11:00",
  "7 Feb, 12:00",
  "7 Feb, 13:00",
  "7 Feb, 14:00",
  "7 Feb, 15:00",
  "7 Feb, 16:00",
  "7 Feb, 17:00",
  "7 Feb, 18:00",
  "7 Feb, 19:00",
  "7 Feb, 20:00",
  "7 Feb, 21:00",
  "7 Feb, 22:00",
  "7 Feb, 23:00",
  "8 Feb, 00:00",
  "8 Feb, 01:00",
  "8 Feb, 02:00",
  "8 Feb, 03:00",
  "8 Feb, 04:00",
  "8 Feb, 05:00",
  "8 Feb, 06:00",
  "8 Feb, 07:00",
  "8 Feb, 08:00",
  "8 Feb, 09:00",
  "8 Feb, 10:00",
  "8 Feb, 11:00",
  "8 Feb, 12:00",
  "8 Feb, 13:00",
  "8 Feb, 14:00",
  "8 Feb, 15:00",
  "8 Feb, 16:00",
  "8 Feb, 17:00",
  "8 Feb, 18:00",
  "8 Feb, 19:00",
  "8 Feb, 20:00",
  "8 Feb, 21:00",
  "8 Feb, 22:00",
  "8 Feb, 23:00",
  "9 Feb, 00:00",
  "9 Feb, 01:00",
  "9 Feb, 02:00",
  "9 Feb, 03:00",
  "9 Feb, 04:00",
  "9 Feb, 05:00",
  "9 Feb, 06:00",
  "9 Feb, 07:00",
  "9 Feb, 08:00",
  "9 Feb, 09:00",
  "9 Feb, 10:00",
  "9 Feb, 11:00",
  "9 Feb, 12:00",
  "9 Feb, 13:00",
  "9 Feb, 14:00",
  "9 Feb, 15:00",
  "9 Feb, 16:00",
  "9 Feb, 17:00",
  "9 Feb, 18:00",
];
