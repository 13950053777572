import { useMemo } from "react";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useSearchParams } from "react-router-dom";
import { convertTime, isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useGetMppEquipCardQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";

export const useEquipmentAvailabilityWidget = () => {
  const [url] = useSearchParams();
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { globalPortfolioName } = useAppSelector(monitoringDashboardSelector);
  const { liveButton } = useAppSelector(monitoringSharedSelector);

  const fromDate = useMemo(() => {
    return startDate ? convertTime(startDate, "YYYY.MM.DD") : undefined;
  }, [startDate]);

  const toDate = useMemo(() => {
    return endDate ? convertTime(endDate, "YYYY.MM.DD") : undefined;
  }, [endDate]);

  const {
    data: mppEquipCardData,
    isLoading: isMppEquipCardLoading,
    isFetching: isMppEquipCardFetching,
    error: mppEquipCardError,
  } = useGetMppEquipCardQuery(
    { plantId, portfolioName: globalPortfolioName, fromDate, toDate },
    {
      skip: isAnyItemFalsy([plantId, globalPortfolioName, fromDate, toDate]),
      pollingInterval: liveButton ? 60 * 1000 : 0,
    }
  );

  return {
    eALoading: isMppEquipCardLoading,
    eASecondLoading: isMppEquipCardFetching,
    eAData: mppEquipCardData?.data || null,
    eAError: Boolean(mppEquipCardError || mppEquipCardData?.error),
  };
};

export type EquipmentAvailabilityWidgetData = ReturnType<
  typeof useEquipmentAvailabilityWidget
>;
