import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryStatusAndPercentageQuery,
} from "../../BatteryMonitoring.api";
import { isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { useAppSelector } from "../../../../../store/rootStore";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useUrlParams } from "../../../../../shared/utils/hooks";

export const useBatteryStatusAndPercentage = () => {
  const { plantId } = useUrlParams({
    plantId: "",
  });

  const { liveButton } = useAppSelector(monitoringDashboardSelector);
  // TODO: kaz investigate why this is always live
  // TODO: kaz this is always live so we don't care about live button
  const { data, isFetching, isLoading, isUninitialized } =
    useGetBatteryStatusAndPercentageQuery(
      { plantId, equipmentName: DEFAULT_EQUIPMENT_NAME },
      {
        pollingInterval: liveButton ? 60 * 1000 : undefined,
        skip: isAnyItemFalsy([plantId]),
      }
    );

  return {
    data,
    isFetching,
    isLoading: isLoading || isUninitialized,
  };
};
