
import { useMemo } from "react";
import Widget from "../../../../components/Widget";
import Loader from "../../../../components/Loader";
import { useTopIssues } from "./useTopIssues";
import { useStyles } from "./styles";
import { Button } from "../../../../../shared/components";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../../../../../app/theme";

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
  definition?: any;
}

export const TopIssuesWidget = (props: Props) => {
  const classes = useStyles();
  const { data, loading, error } = useTopIssues(props);
  const navigate = useNavigate();
  const content = useMemo(
    () => (
      <>
        {data?.map((item, i) => {
          if (i > 4) return;
          return (
            <div className={classes.item}>
              <div className={classes.description}>{item.description}</div>

              <Button
                title="Create Incident"
                onClick={() => {
                  navigate(
                    "/cmms/incidents", {
                    state: {
                      createIncident: true,
                    },
                  });
                }}
                color={appTheme.main}
              />
            </div>
          );
        })}
      </>
    ),
    [data]
  );

  return (
    <Widget
      label={`Top ${data?.length > 4 ? 5 : data?.length} issues to Focus on:`}
      height={(data?.length * 100) - 20}
      size={12}
      styles={{ marginRight: 8 }}
    >
      {!data?.length || loading ? (
        <Loader
          loading={loading}
          height={300}
          error={error}
          errorTextSize={32}
        />
      ) : (
        content
      )}
    </Widget>
  );
};
