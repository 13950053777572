import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: theme.spacing(1),
        "@media (min-width: 900px) and (max-width: 959px)": {
            marginLeft: "56px",
        },
        padding: '32px',
        fontFamily: 'Inter!important',
    },
    parentWidth: {
        width: "880px",
        "@media (max-width: 1020px)": {
            width: "100%",
        },
    },
    heading: {
        color: '#0C1E33',
        fontSize: 24,
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '26.40px',
        wordWrap: 'break-word',
    },
    description: {
        color: '#0C1E33',
        fontSize: 15,
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '18px',
        wordWrap: 'break-word',
        paddingTop: "24px",
    },
    userSelect: {
        display: "flex",
        alignItems: "center",
        gap: "24px",
        paddingTop: "15px",
    },
    tenantSelect: {
        display: "flex",
        alignItems: "center",
        gap: "24px",
        paddingTop: "15px",
    },
    userText: {
        color: '#0C1E33',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        lineHeight: '17.60px',
        wordWrap: 'break-word',
    },
    tenantText: {
        color: '#0C1E33',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        lineHeight: '17.60px',
        wordWrap: 'break-word',
    },
    selector: {
        borderRadius: 5,
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        display: "flex",
        minWidth: "120px!important",
    },
    footer: {
        position: 'absolute',
        bottom: '15.5px',
        left: -12
    },
    flexItem: {
        display: "flex",
        gap: "24px",
    },
    accordionTitle: {
        color: "#0C1E33",
        fontSize: 16,
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        paddingBottom: "16px",
    },
    accordionSubtitle: {
        color: "#0C1E33",
        fontSize: 15,
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        lineHeight: 1.5,
    },

    logoSpacing: {
        padding: theme.spacing(2),
    },

    logoBackground: {
        backgroundColor: "#F7F7F7",
        borderRadius: 8,
        padding: theme.spacing(2),
    },
    logoHeader: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '17.6px',
        letterSpacing: '-0.02em',
        textAlign: 'left',
        paddingBottom: "16px"
    }

}));
