import React from "react";
import { Button } from "../../../../../shared/components";
import Loader from "../../../../components/Loader";
import { MultilineChartWidget } from "../../../../components/MultilineChartWidget";
import { colors } from "../../../MonitoringPageView/components/GraphsWidget/utils";
import { useStyles } from "./styles";
interface Props {
  title: string;
  data?: Array<any>;
  error?: string;
  loading?: boolean;
  height?: number | string;
  size?: any;
  definition?: any;
  styles?: any;
  type?: string;
  showYAxises?: boolean;
  yAxises: any;
  xAxises: any;
  setXAxises: any;
  showLabel?: boolean;
  downloadGraph?: () => void;
}

export const DataReportWidget: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { downloadGraph, height, data, xAxises, loading, error, yAxises } =
    props;

  return (
    <>
      {!data?.length || loading || error?.length ? (
        <Loader height={200} loading={loading} />
      ) : (
        <div className={classes.wrapper}>
          <MultilineChartWidget
            title={" "}
            customMenu={
              <Button
                marginRight="13px"
                title="Download"
                type="button"
                onClick={() => downloadGraph()}
                color="#0c1e33"
              />
            }
            colors={colors}
            data={data}
            xAxis={xAxises}
            yAxis={yAxises}
            loading={loading}
            height={height}
            type="spline"
          />
        </div>
      )}
    </>
  );
};
