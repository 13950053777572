import {
  BatteryData,
  TransformedBatteryData,
  HistoricalBatteryData,
  TransformedHistoricalBatteryData,
} from "../services/BattteryMonitoringDataTypes";
import { capitalizeFirstLetter } from "../../../../shared/utils/utils";

const getTimeDifferenceLabel = (date: string): string => {
  const currentDate = new Date();
  const updateDate = new Date(
    date.endsWith("Z") || date.includes("+") || date.includes("-")
      ? date
      : date + "Z"
  );
  let diffMs = currentDate.getTime() - updateDate.getTime();
  if (diffMs < 0) diffMs = 0;

  const diffMins = Math.floor(diffMs / 60000);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  } else if (diffHours > 0) {
    return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
  } else if (diffMins > 0) {
    return `${diffMins} minute${diffMins > 1 ? "s" : ""} ago`;
  } else {
    return "Just now";
  }
};

export const transformBatteryData = (
  data: BatteryData[]
): TransformedBatteryData[] => {
  return data.map((item) => {
    const dates = [
      item.capacity.date,
      item.power.date,
      item.stateOfCharge.date,
    ];
    const latestDate = new Date(
      Math.max.apply(
        null,
        dates.map((date) => new Date(date).getTime())
      )
    ).toISOString();
    return {
      plantId: item.plantId,
      batteryName: item.name,
      batteryDisplayName: item.displayName,
      capacity: `${item.capacity.value}`,
      alarms: 0,
      power: `${item.power.value}`,
      soc: `${item.stateOfCharge.value}`,
      state: capitalizeFirstLetter(item.state),
      lastUpdated: getTimeDifferenceLabel(latestDate),
    };
  });
};

export const transformHistoricalBatteryData = (
  data: HistoricalBatteryData[]
): TransformedHistoricalBatteryData[] => {
  return data.map((item) => ({
    plantId: item.plantId,
    batteryName: item.name,
    batteryDisplayName: item.displayName,
    capacity: `${item.capacity.value}`,
    energyCharged: `${item.energyCharged.value}`,
    energyDischarged: `${item.energyDischarged.value}`,
    maxTemperature: `${item.maxTemperature.value}`,
  }));
};
