import { useMemo, useState } from "react";
import { HeatmapChart, legendArr } from "../../../../components/HeatmapChart";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";
import { CustomLegend } from "./CustomLegend";
import { CustomMenu } from "./CustomMenu.component";
import { Types, useHeatmap } from "./useHeatmap";
interface Props {
  plantId: string;
  date: string;
}
export const HeatmapWidget: React.FC<Props> = (props) => {
  const { plantId, date } = props;
  const [type, setType] = useState(Types.All);
  const { data, xLabels, yLabels, loading, colors, error } = useHeatmap({
    plantId,
    date,
    type,
  });
  const hide = useMemo(() => !data?.length && loading, [data, loading]);
  return hide ? null : (
    <Widget
      label="String Specific Current (HeatMap)"
      customMenu={<CustomMenu activeType={type} setType={setType} />}
      height={(data?.length * 45) >= 650 ? 650 : (data?.length * 45)}
      size={12}
    >
      {loading || error !== "" ?
        <Loader loading={loading} height={300} error={error} errorTextSize={32} />
        :
        (
          <>
            <CustomLegend legendData={legendArr} />
            <HeatmapChart
              data={data}
              xLabels={xLabels}
              yLabels={yLabels}
              colors={colors}
            />
          </>
        )}
    </Widget>
  );
};
