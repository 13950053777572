import {
  convertTime,
  autoRefreshApi,
} from "./../../../../../shared/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { getLast24hrACPowerandIrrTrend } from "../../../../../features/Sidem/portfolios/plants/plantsActions";

interface Props {
  plantId: string;
  date: string;
  tilt: boolean;
}

interface Data {
  data: Array<any>;
  error: string;
  loading: boolean;
}

export const useACPowervsIrradiationWidget = ({
  plantId,
  date,
  tilt,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);

  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);
    const res: any = await dispatch(
      getLast24hrACPowerandIrrTrend(plantId, date)
    );
    const filData = res?.map((item) => {
      const timeLabel = convertTime(item.sensorTimeUtc, "HH:mm");
      return {
        sensor_time: timeLabel,
        "AC-Power": item.acPower,
        Irradiation: item.poaIrradiance,
      };
    });
    setData(filData);

    setLoading(false);
  };
  useEffect(() => {
    if (!date || !client) {
      setLoading(true);
      return;
    }

    setError("");
    setLoading(true);
    fetchData();

    const myInterval = autoRefreshApi(() => fetchData());
    return () => clearInterval(myInterval);
  }, [plantId, date, tilt, client]);

  return {
    data,
    error,
    loading,
  };
};
