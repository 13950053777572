import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react'
import { DateType, DropdownOption, SetDateType } from '../../../../components/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsSharedSelector } from '../../../../../features/Analytics/shared/analyticsSharedSlice';
import { setDateComparison, setGlobalDateAction, setGlobalLiveButtonAction, setGlobalPlantIdAction, setGlobalPortfolioNameAction } from '../../../../../features/Analytics/shared/analyticsSharedActions';
import { checkDate, convertTime } from '../../../../../shared/utils/utils';
import { useSearchParams } from 'react-router-dom';
import { useGetPortfoliosAndPlantsQuery } from '../../../../../features/Sidem/shared/sidemSharedApi';


interface Data {
    portfolio: DropdownOption[];
    plant: DropdownOption[];
    selectedPlant: DropdownOption[];
    selectedPortfolio: DropdownOption[];
    setSelectedPlant: Dispatch<SetStateAction<DropdownOption[]>>;
    date: DateType[];
    setDate: SetDateType;
    liveButton: boolean;
    checkLiveButton: (val: boolean) => void;
    onChangePortfolio: (val: any) => void;
    preSelectedPlant: any;
    preSelectedPortfolio: any;
}

export const useHeaderWidget = (): Data => {
    const dispatch = useDispatch();

    const [url, setUrl] = useSearchParams({ portfolioId: '', plantId: '', startDate: '', endDate: '' })
    const startDate = url.get("startDate")
    const endDate = url.get("endDate")
    const plantId = url.get("plantId")
    const portfolioId = url.get("portfolioId")

    const { globalDate, liveButton } = useSelector(analyticsSharedSelector);

    const [selectedPortfolio, setSelectedPortfolio] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState([]);
    const [preSelectedPlant, setPreSelectedPlant] = useState([]);
    const [preSelectedPortfolio, setPreSelectedPortfolio] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [plant, setPlant] = useState([]);
    const [tenantInfo, setTenantInfo] = useState([]);
    const [liveButtonState, setLiveButtonState] = useState(liveButton);
    const [date, setDate] = useState([{ startDate: checkDate(startDate, globalDate[0].startDate), endDate: checkDate(endDate, globalDate[0].endDate), key: "selection" }]);
    const {
        data: portfoliosAndPlantsData,
    } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });

    useEffect(() => {
        if (!portfoliosAndPlantsData) return;
        getPortfoliosInfo();
    }, [portfoliosAndPlantsData]);

    useEffect(() => {
        if (!selectedPortfolio.length) return;
        const json = JSON.stringify(selectedPortfolio);
        setUrl(prev => {
            prev.set('portfolioId', json); return prev
        }, { replace: true })
        getPlants(selectedPortfolio);
    }, [selectedPortfolio]);

    useEffect(() => {
        if (!selectedPlant.length) return;
        const json = JSON.stringify(selectedPlant);
        setUrl(prev => {
            prev.set('plantId', json); return prev
        }, { replace: true })
    }, [selectedPlant]);

    useEffect(() => {
        dispatch(setGlobalDateAction(date));
        // setDateComparison
        const startTime = convertTime(date[0].startDate, "YYYY.MM.DD");
        const endTime = convertTime(date[0].endDate, "YYYY.MM.DD");
        if (startTime !== endTime) {
            dispatch(setDateComparison(false));
        } else {
            dispatch(setDateComparison(true));
        }

        setUrl(prev => {
            prev.set('startDate', date[0].startDate.toString()); return prev
        }, { replace: true })
        setUrl(prev => {
            prev.set('endDate', date[0].endDate.toString()); return prev
        }, { replace: true })
    }, [date]);

    useLayoutEffect(() => {
        dispatch(setGlobalLiveButtonAction(liveButtonState));
    }, [liveButtonState]);

    useEffect(() => {
        const startTime = convertTime(!startDate ? date[0].startDate : startDate, "YYYY.MM.DD");
        const endTime = convertTime(!endDate ? date[0].endDate : endDate, "YYYY.MM.DD");
        const dateToday = convertTime(new Date(), "YYYY.MM.DD");
        if (
            startTime !== endTime ||
            (startTime !== dateToday && endTime !== dateToday)
        )
            setLiveButtonState(false);
    }, [liveButtonState, startDate, endDate]);

    useEffect(() => {
        const startTime = convertTime(!startDate ? date[0].startDate : startDate, "YYYY.MM.DD");
        const endTime = convertTime(!endDate ? date[0].endDate : endDate, "YYYY.MM.DD");
        const dateToday = convertTime(new Date(), "YYYY.MM.DD");

        if (startTime === dateToday && endTime === dateToday)
            setLiveButtonState(true);
    }, [startDate, endDate]);

    const checkLiveButton = (e) => {
        if (!liveButtonState) {
            setDate([
                {
                    endDate: new Date().toISOString(),
                    key: "selection",
                    startDate: new Date().toISOString(),
                },
            ]);
        }
        setLiveButtonState(e);
    };

    const getPortfoliosInfo = async () => {
        if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0) return;
        setTenantInfo(portfoliosAndPlantsData);
        getPortfolio(portfoliosAndPlantsData);
    };

    const onChangePortfolio = (value) => {
        const newPlants = [];
        setSelectedPortfolio(value);
        tenantInfo?.forEach((item) => {
            value?.forEach((el) => {
                if (item?.portfolioName === el.value) {
                    newPlants.push({
                        label: item?.plantName,
                        value: item?.plantId,
                    });
                }
            })
        });
        setPreSelectedPlant(newPlants?.map((item) => item.value))

        if (!selectedPortfolio.length)
            setSelectedPlant(newPlants);
    };

    const getPortfolio = async (data: any) => {
        const fil = [];
        let newPortfolio: Array<DropdownOption> | any[] = [];
        data?.filter((item) => {
            if (!fil.includes(item?.portfolioName)) {
                fil.push(item?.portfolioName);
                newPortfolio.push({
                    label: item?.portfolioName,
                    value: item?.portfolioName,
                });
            }
        });
        setPortfolio(newPortfolio);
        if (!portfolioId) {
            setPreSelectedPortfolio(newPortfolio.map((item) => item.value));
            setSelectedPortfolio(newPortfolio);
        }
        if (!selectedPortfolio.length && portfolioId)
            setPreSelectedPortfolio(JSON.parse(portfolioId)?.map((item) => item.value))
    };

    const getPlants = async (portfolio: any) => {
        const newPlants = [];
        tenantInfo?.forEach((item) => {
            return portfolio.forEach((el) => {
                if (el.label === item?.portfolioName) {
                    newPlants.push({
                        label: item?.plantName,
                        value: item?.plantId,
                    });
                }
            });
        });
        setPlant(newPlants);
        if (!plantId) {
            setPreSelectedPlant(newPlants.map((item) => item.value));
            setSelectedPlant(newPlants);
        }
        if (!selectedPlant.length && plantId) {
            setPreSelectedPlant(JSON.parse(plantId)?.map((item) => item.value));
        }
    };

    return {
        portfolio,
        plant,
        selectedPortfolio,
        selectedPlant,
        setSelectedPlant,
        date,
        setDate,
        liveButton,
        checkLiveButton,
        onChangePortfolio,
        preSelectedPlant,
        preSelectedPortfolio,
    };
}
