import { useEffect, useState } from "react";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Data {
  tenants: any;
  module: any;
  deleteModule: (moduleId: any, callback: any) => void;
  loading: any;
}

export const UseModule = (): Data => {

  const [tenants, setTenants] = useState(0);
  const [loading, setloading] = useState(null);
  const [module, setModule] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    getModule();
  }, []);

  const getModule = async () => {
    setloading(true);
    fetchAsync(`/Module`, "GET")
      .then((data: any) => {
        if (data) {
          let newModule = data?.map(item => ({
            ...item,
            id: item.moduleId
          }));
          setModule(newModule);

        }
        setloading(false);
      })
      .catch((e) => { console.error(e); setloading(false); });
  };

  const deleteModule = async (moduleId: any, callback: any) => {
    setloading(true);
    fetchAsync(`/Module?id=${moduleId}`, "DELETE")
      .then((data: any) => {
        getModule();
        setloading(false);
        callback(true);
      })
      .catch((e) => { console.error(e); setloading(false); callback(false) });
  };

  return {
    tenants,
    module,
    deleteModule,
    loading
  };

};
