// import { axGet } from "../../../store/axiosClient";
import * as MonitoringSharedSlice from "./monitoringSharedSlice";
// import { getMsalAccessToken } from "../../../store/authToken";
// import { SIDEM_SCOPE } from "../../../shared/utils/scope";
// import axios from "axios";

// const sidemApiUrl = `SkyfriSidemApi/v1`

export const setGlobalDateAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringSharedSlice.setGlobalDate(payload));
        return payload;
    };
};

export const setGlobalLiveButtonAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringSharedSlice.setLiveButton(payload));
        return payload;
    };
};

export const setDateComparison = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringSharedSlice.dateComparison(payload));
        return payload;
    };
};
export const setGlobalPortfolioNameAction = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringSharedSlice.getPortfolioName(payload));
        return payload;
    };
};