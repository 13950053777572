import React from 'react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core'
import { Equalizer, TrendingFlat, TrendingUp } from '@material-ui/icons';

import { useStyles } from './styles';
import { ChartTypes } from './interfaces';

interface ButtonsProps {
  chartType: ChartTypes;
  onChangeChartType: (type: ChartTypes) => void;
}

const buttons = [
  { icon: <Equalizer />, type: ChartTypes.BarChart, },
  { icon: <TrendingUp />, type: ChartTypes.LineChart, },
  { icon: <TrendingFlat />, type: ChartTypes.Bullet, }
];

export const CardsChartButtons: React.FC<ButtonsProps> = ({ chartType, onChangeChartType }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      {buttons?.map((item) => (
        <IconButton
          className={clsx(classes.chartIcon, { [classes.active]: chartType === item.type })}
          onClick={() => onChangeChartType(item.type)}
        >
          {item.icon}
        </IconButton>
      ))}
    </div>
  );
};
