import { useState } from "react";
import { useBetween } from "use-between";
import { timeFormatList } from "../../sidem/features/SettingsSidebar/utils";

// Make a custom hook with your future shared state
const useFormState = () => {
  const [viewDefinitions, setViewDefinitions] = useState(false);
  const [lightTheme, setLightTheme] = useState(false);
  const [automaticRefresh, setAutomaticRefresh] = useState(true);
  const [subsidiaryName, setSubsidiaryName] = useState("");
  const [activetenants, setActiveTenants] = useState("");
  const [timeLabel, setTimeLabel] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userTimeZone, setUserTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState(timeFormatList[0]);
  const [alarmNotification, setAlarmNotification] = useState(false);

  return {
    userTimeZone,
    setUserTimeZone,
    viewDefinitions,
    setViewDefinitions,
    lightTheme,
    setLightTheme,
    automaticRefresh,
    setAutomaticRefresh,
    subsidiaryName,
    setSubsidiaryName,
    activetenants,
    setActiveTenants,
    timeLabel,
    setTimeLabel,
    timeFormat,
    setTimeFormat,
    selectedOption,
    setSelectedOption,
    alarmNotification,
    setAlarmNotification,
  };
};

export const useSharedFormState = () => useBetween(useFormState);
