import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, MenuItem, Select, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import { ErrorMessage } from "../ErrorMessage";

export interface DropdownOption {
	label: string;
	value: any;
}

interface Props {
	value: any;
	options: Array<DropdownOption>;
	onChangeValue: (e: any) => void;
	label?: string;
	name?: string;
	width?: number | string;
	multiple?: boolean;
	showError?: boolean;
	errorMessage?: any;
	showAdd?: boolean;
	onAddNew?: (key: string) => void;
	disabled?: boolean;
	isCheckList?: boolean;
}

const useStyles = makeStyles((theme) => ({
	rootFirstSelect: {
		paddingLeft: "5px",
	},
	button: {
		minWidth: "100%",
		justifyContent: "start",
	},
}));

export const Dropdown: React.FC<Props> = ({
	value,
	onChangeValue,
	options,
	label,
	name = "",
	width,
	multiple,
	showError,
	errorMessage,
	showAdd,
	onAddNew,
	disabled,
	isCheckList,
}) => {
	const classes = useStyles();

	return (
		<FormControl sx={{ m: 0, minWidth: width ? width : 120 }}>
			{!label ? null : (
				<InputLabel id="label" style={{ fontSize: 14 }}>
					{label}
				</InputLabel>
			)}

			<Select
				value={value}
				onChange={(e: any) => onChangeValue(e.target.value)}
				name={name}
				multiple={multiple}
				classes={{ root: classes.rootFirstSelect }}
				disabled={disabled}
			>
				{/* {(!isCheckList) && <MenuItem value="">
					<em>None</em>
				</MenuItem>} */}
				{options?.map((item, idx) => (
					<MenuItem key={item.label + idx} value={item.value}>
						{item.label}
					</MenuItem>
				))}
				<div>
					{showAdd ? (
						<Button
							className={classes.button}
							onClick={() => onAddNew(name)}
							startIcon={<AddIcon />}
						>
							Add new
						</Button>
					) : (
						""
					)}
				</div>
			</Select>
			<ErrorMessage showError={showError} errorMessage={errorMessage} />
		</FormControl>
	);
};
