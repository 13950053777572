import React from "react";
import { MultilineChartWidget } from "../../../../components/MultilineChartWidget";
import { CustomTooltip } from "./CustomTooltip.component";
import { RenderCustomToggleLegendForWMS } from "./RenderCustomToggleLegendForWMS.component";
import { useWeatherStation } from "./useWeatherStation";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}
export const WeatherStationWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
}) => {
  const { data, loading, error, lines, setLines, yAxises } = useWeatherStation({
    plantId,
    date,
  });
  const hide = !loading && !data?.length;

  return hide ? null : (
    <MultilineChartWidget
      title="Weather Station"
      height={700}
      size={12}
      lines={lines}
      data={data}
      loading={loading}
      error={error}
      showYAxises={false}
      yAxises={null}
      tooltipContent={<CustomTooltip />}
      definition={definition}
      toggleLegend={true}
      renderCustomLegend={<RenderCustomToggleLegendForWMS />}
      setLines={setLines}
    />
  );
};