import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
import { plantsSlice } from "./plants/plantsSlice";

export interface IPortfolioState {
  gettingPortfolios?: boolean;
  portfoliosFailed?: boolean;
  portfoliosFailedMessage?: string;
  portfoliosSuccessful?: boolean;
  portfolios?: any[];

  gettingPortfolioCards?: boolean;
  portfolioCardsFailed?: boolean;
  portfolioCardsSuccessful?: boolean;
  portfolioCards?: any[];

  gettingPortfolio30days?: boolean;
  portfolio30daysFailed?: boolean;
  portfolio30daysSuccessful?: boolean;
  portfolioOverviewLast30?: any[];

  gettingPortfolio7days?: boolean;
  portfolio7daysFailed?: boolean;
  portfolio7daysSuccessful?: boolean;
  portfolioOverviewLast7?: any[];

  gettingPortfolioCardsToday?: boolean;
  portfolioCardsTodayFailed?: boolean;
  portfolioCardsTodaySuccessful?: boolean;

  gettingPortfolioCardsKPIsToday?: boolean;
  portfolioCardsKPIsTodayFailed?: boolean;
  portfolioCardsKPIsTodaySuccessful?: boolean;

  gettingPortfolioPopupKPIsToday?: boolean;
  portfolioPopupKPIsTodayFailed?: boolean;
  portfolioPopupKPIsTodaySuccessful?: boolean;

  date?: string;
}
const initialState: IPortfolioState = {
  gettingPortfolios: false,
  portfoliosFailed: false,
  portfoliosSuccessful: false,
  portfoliosFailedMessage: "",
  portfolios: [],

  gettingPortfolioCards: false,
  portfolioCardsFailed: false,
  portfolioCardsSuccessful: false,
  portfolioCards: [],

  gettingPortfolio30days: false,
  portfolio30daysFailed: false,
  portfolio30daysSuccessful: false,
  portfolioOverviewLast30: [],

  gettingPortfolio7days: false,
  portfolio7daysFailed: false,
  portfolio7daysSuccessful: false,
  portfolioOverviewLast7: [],

  gettingPortfolioCardsToday: false,
  portfolioCardsTodayFailed: false,
  portfolioCardsTodaySuccessful: false,

  gettingPortfolioCardsKPIsToday: false,
  portfolioCardsKPIsTodayFailed: false,
  portfolioCardsKPIsTodaySuccessful: false,

  gettingPortfolioPopupKPIsToday: false,
  portfolioPopupKPIsTodayFailed: false,
  portfolioPopupKPIsTodaySuccessful: false,

  date: null,
};
// name
// reducer
// actions
// caseReducers
export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    portfoliosStart: (state) => {
      return {
        ...state,
        gettingPortfolios: true,
      };
    },
    portfoliosSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingPortfolios: false,
        portfoliosSuccessful: true,
        portfolios: payload,
      };
    },
    portfoliosError: (state, { payload }) => {
      return {
        ...state,
        gettingPortfolios: false,
        portfoliosFailed: true,
        portfoliosFailedMessage: payload,
      };
    },
    // portfolio cards
    portfolioCardsStart: (state) => {
      return {
        ...state,
        gettingPortfolioCards: true,
      };
    },
    portfolioCardsSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingPortfolioCards: false,
        portfolioCardsSuccessful: true,
        portfolioCards: payload,
      };
    },
    portfolioCardsError: (state) => {
      return {
        ...state,
        gettingPortfolioCards: false,
        portfolioCardsFailed: true,
      };
    },
    // portfolio overview last 30 days
    portfolioLast30Start: (state) => {
      return {
        ...state,
        gettingPortfolio30days: true,
      };
    },
    portfolioLast30Success: (state, { payload }) => {
      return {
        ...state,
        gettingPortfolio30days: false,
        portfolio30daysSuccessful: true,
        portfolioOverviewLast30: payload,
      };
    },
    portfolioLast30Error: (state) => {
      return {
        ...state,
        gettingPortfolio30days: false,
        portfolio30daysFailed: true,
      };
    },
    // portfolio overview last 7 days
    portfolioLast7Start: (state) => {
      return {
        ...state,
        gettingPortfolio7days: true,
      };
    },
    portfolioLast7Success: (state, { payload }) => {
      return {
        ...state,
        gettingPortfolio7days: false,
        portfolio7daysSuccessful: true,
        portfolioOverviewLast7: payload,
      };
    },
    portfolioLast7Error: (state) => {
      return {
        ...state,
        gettingPortfolio7days: false,
        portfolio7daysFailed: true,
      };
    },
    // portfolio cards today
    portfolioCardsTodayStart: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: true,
      };
    },
    portfolioCardsTodaySuccess: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: false,
        portfolioCardsTodaySuccessful: true,
      };
    },
    portfolioCardsTodayError: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: false,
        portfolioCardsTodayFailed: true,
      };
    },
    // portfolio cards KPI today
    portfolioCardsKPITodayStart: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: true,
      };
    },
    portfolioCardsKPITodaySuccess: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: false,
        portfolioCardsTodaySuccessful: true,
      };
    },
    portfolioCardsKPITodayError: (state) => {
      return {
        ...state,
        gettingPortfolioCardsToday: false,
        portfolioCardsTodayFailed: true,
      };
    },

    // portfolio popup KPI today
    portfolioPopupKPIsTodayStart: (state) => {
      return {
        ...state,
        gettingPortfolioPopupKPIsToday: true,
      };
    },

    portfolioPopupKPIsTodaySuccess: (state) => {
      return {
        ...state,
        gettingPortfolioPopupKPIsToday: false,
        portfolioPopupKPIsTodaySuccessful: true,
      };
    },
    portfolioPopupKPIsTodayError: (state) => {
      return {
        ...state,
        gettingPortfolioPopupKPIsToday: false,
        portfolioPopupKPIsTodayFailed: true,
      };
    },

    setGlobalDateForCards: (state, { payload }) => {
      return {
        ...state,
        date: payload,
      };
    },
  },
});
// portfolio actions
export const {
  portfoliosStart,
  portfoliosSuccess,
  portfoliosError,

  portfolioCardsStart,
  portfolioCardsSuccess,
  portfolioCardsError,

  portfolioLast30Start,
  portfolioLast30Success,
  portfolioLast30Error,

  portfolioLast7Start,
  portfolioLast7Success,
  portfolioLast7Error,

  portfolioCardsTodayStart,
  portfolioCardsTodaySuccess,
  portfolioCardsTodayError,

  portfolioCardsKPITodayStart,
  portfolioCardsKPITodaySuccess,
  portfolioCardsKPITodayError,

  portfolioPopupKPIsTodayStart,
  portfolioPopupKPIsTodaySuccess,
  portfolioPopupKPIsTodayError,

  setGlobalDateForCards
} = portfolioSlice.actions;
// portfolio state
export const portfolioSelector = (state: RootState) =>
  state.Sidem.portfolio.portfolio;
// portfolio reducer
const portfolioReducer = combineReducers({
  portfolio: portfolioSlice.reducer,
  plants: plantsSlice.reducer,
});
export { portfolioReducer };