export interface Location {
  hash: string;
  pathname: string;
  search: string;
  state: string;
}

export interface PageItem {
  path: string;
  component: React.FC<any>;
  title?: string;
  params: any;
  getRedirectionPath?: any;
}

export interface MenuItem {
  tenant?: string;
  idx: string;
  Icon: any;
  DarkIcon?: any;
  title: string;
  navigation?: React.ReactNode;
  items: Array<PageItem>;
}

export enum Roles {
  MONITORING = "MONITORING",
  BATTERY_MONITORING = "BATTERY_MONITORING",
  SIDEM = "SIDEM",
  CMMS = "CMMS",
  BILLING = "BILLING",
  ONBOARDING = "ONBOARDING",
  COMMERCIAL = "COMMERCIAL",
  ANALYTICS = "ANALYTICS",
  OPERATIONS = "OPERATIONS",
}

export enum PagesRoles {
  SKYFRIDEMO = "SkyfriDemo",
}

export type RolesMap = {
  [key in Roles]: boolean;
};

export type Order = "asc" | "desc";
 