import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPortfolios } from "../../../features/Sidem/portfolios/portfolioActions";
import { Field } from "../../components/Fields";
import { fetchAsync } from "../../../shared/utils/apiService";

interface ChartItem {
  actGen: number;
  client: string;
  dailyBudGen: number;
  energyDelta: number;
  energyDeltaAvg: number;
  monthNumber: number;
  timeStamp: string;
}

const getFields = (item: any): Array<Field> => [
  {
    title: "Plants",
    value: item?.plants || 0,
  },
  {
    title: "Capacity DC",
    value: item?.totalDCMW?.toFixed(1) || 0,
    unit: "MWp",
  },
  {
    title: `Generation`,
    value:
      item?.actualGen.toString().length > 4
        ? (item?.actualGen / 1000)?.toFixed(1)
        : item?.actualGen?.toFixed(1),
    unit: item?.actualGen.toString().length > 4 ? "MWh" : "KWh",
  },
  {
    title: "PR delta",
    value: item.prDelta * 100 ? (item.prDelta * 100).toFixed(1) : 0,
    unit: "%",
  },
  {
    title: "Irradiation",
    value: item?.actualIrradiation?.toFixed(1),
    unit: "kWh/m²",
  },
  {
    title: "PR",
    value: item?.actualPR ? (item?.actualPR * 100)?.toFixed(1) : 0,
    unit: "%",
  },
  {
    title: "Availability",
    value: (item?.actualAvailability * 100)?.toFixed(1),
    unit: "%",
  },
  {
    title: "CUF/PLF",
    value:
      typeof item?.actualCuf === "number"
        ? (item?.actualCuf * 100)?.toFixed(1)
        : "N/A",
    unit: typeof item?.actualCuf === "number" ? "%" : "",
  },
  {
    title: "Grid Availability",
    value: (item?.gridAvailability * 100)?.toFixed(1) || 0,
    unit: "%",
  },
];

const sortByTimeStamp = (arr: Array<{ timeStamp: string }>): Array<any> =>
  arr.sort((a, b) => {
    const [date1] = a.timeStamp.split(" ");
    const [date2] = b.timeStamp.split(" ");
    const getTime = (str: string) => new Date(str)?.getTime();

    return getTime(date1) > getTime(date2) ? 1 : -1;
  });

interface HeaderData {
  fields: Array<Field>;
  barChartData: Array<any>;
  lineChart7Days: Array<any>;
  lineChart7DaysValue: number;
  productionFactorLineChartValue: number;
  productionFactorLineChartData: Array<any>;
  lineChart30Days: Array<any>;
  lineChart30DaysValue: number;
  imageUrl: string;
}

export const usePortfolioHeader = (
  format,
  portfolioId
): { data: HeaderData } => {
  const [fields, setFields] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChart7Days, setLineChart7Days] = useState([]);
  const [lineChart7DaysValue, setLineChart7DaysValue] = useState(0);
  const [lineChart30Days, setLineChart30Days] = useState([]);
  const [lineChart30DaysValue, setLineChart30DaysValue] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [productionFactorLineChartValue, setProductionFactorLineChartValue] =
    useState(0);
  const [productionFactorLineChartData, setProductionFactorLineChartData] =
    useState([]);

  const dispatch = useDispatch();
  const API_URL = `/SkyfriSidemApi/v1`

  useEffect(() => {
    if (!format) return;
    const getPortfolioData = async () => {
      const data: any = await dispatch(getPortfolios(format));
      if (!data) return;
      const currentPortfolio = data?.find(
        (item) => item.portfolioName === portfolioId
      );
      setFields(getFields(currentPortfolio));
      setImageUrl((currentPortfolio?.imageUrl || "").replace(/\s/g, ""));
    };
    getPortfolioData();
  }, [format, portfolioId]);

  const getDateLabel = (timeStamp: string) => {
    const [month, day] = timeStamp.split("/");
    return day + "/" + month;
  };

  useEffect(() => {
    fetchAsync(`${API_URL}/GetPortfolioOverviewGraphLast30Days`, "GET")
      .then((data: Array<ChartItem>) => {
        const new30DaysData = sortByTimeStamp(data)?.map((item) => ({
          label: getDateLabel(item.timeStamp),
          value1: item.energyDelta,
          value2: item.irradationDelta,
        }));
        const newLineValue = data[0]?.energyDeltaAvg;
        setLineChart30Days(new30DaysData);
        setLineChart30DaysValue(newLineValue);
      })
      .catch((e) => console.error(e));

    fetchAsync(`${API_URL}/GetPortfolioOverviewGraphsLast7Days`, "GET")
      .then((data: Array<ChartItem>) => {
        const sortedData = sortByTimeStamp(data);
        const newBarData = sortedData?.map((item) => ({
          label: getDateLabel(item.timeStamp),
          barValue:
            item.actGen?.toString().length > 4
              ? (parseInt(item.actGen) / 1000)?.toFixed(1)
              : parseInt(item?.actGen)?.toFixed(1),
          lineValue: item.actualIrradianceCum?.toFixed(1),
        }));
        setBarChartData(newBarData);

        const newLineData = sortedData?.map((item) => ({
          label: getDateLabel(item.timeStamp),
          value1: item.energyDelta,
          value2: item.irradationDelta,
        }));

        const newLineValue = data[0]?.energyDeltaAvg;

        setLineChart7Days(newLineData);
        setLineChart7DaysValue(newLineValue);

        const productionFactorLineData = sortedData?.map((item) => ({
          label: getDateLabel(item.timeStamp),
          value: item.productionFactor,
        }));

        const productionFactorLineValue = sortedData[0]?.productionFactorAvg;

        setProductionFactorLineChartValue(productionFactorLineValue);
        setProductionFactorLineChartData(productionFactorLineData);
      })
      .catch((e) => console.log(e));
  }, [format]);

  return {
    data: {
      fields,
      barChartData,
      lineChart7Days,
      lineChart7DaysValue,
      lineChart30Days,
      lineChart30DaysValue,
      productionFactorLineChartValue,
      productionFactorLineChartData,
      imageUrl,
    },
  };
};
