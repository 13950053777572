import { Container, Grid } from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import { Fallback } from "../../../app/Fallback";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";
import { useStatusWidget } from "./components/StatusWidget/useStatusWidget";
import { useActiveAlarmsWidget } from "./components/ActiveAlarmsWidget/useActiveAlarmsWidget";
import { useKpiWidget } from "./components/KpiWidget/useKpiWidget";
import { useMeteoWidget } from "./components/MeteoWidget/useMeteoWidget";
import { useEquipmentAvailabilityWidget } from "./components/EquipmentAvailabilityWidget/useEquipmentAvailabilityWidget";

const HeaderWidget = lazy(() =>
  import("./components/HeaderWidget").then((module) => ({
    default: module.HeaderWidget,
  }))
);
const StatusWidget = lazy(() =>
  import("./components/StatusWidget").then((module) => ({
    default: module.StatusWidget,
  }))
);
const KpiWidget = lazy(() =>
  import("./components/KpiWidget").then((module) => ({
    default: module.KpiWidget,
  }))
);
const EquipmentAvailabilityWidget = lazy(() =>
  import("./components/EquipmentAvailabilityWidget").then((module) => ({
    default: module.EquipmentAvailabilityWidget,
  }))
);
const MeteoWidget = lazy(() =>
  import("./components/MeteoWidget").then((module) => ({
    default: module.MeteoWidget,
  }))
);
const ActiveAlarmsWidget = lazy(() =>
  import("./components/ActiveAlarmsWidget").then((module) => ({
    default: module.ActiveAlarmsWidget,
  }))
);
const GraphsWidget = lazy(() =>
  import("./components/GraphsWidget").then((module) => ({
    default: module.GraphsWidget,
  }))
);

export const MonitoringPageView: React.FC<any> = () => {
  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setDate,
    liveButton,
    checkLiveButton,
    globalDate,
    onChangePortfolio,
    onChangePlant,
  } = useHeaderWidget();
  const {
    statusLoading,
    statusData,
    statusError,
    genData,
    DownloadData,
    downloadLoading,
    downloadError,
  } = useStatusWidget();
  const { search, onSearch, dataToSend, hours } = useActiveAlarmsWidget();
  const { kpiData, kpiError, kpiLoading, lastUpdated, kpiSecondLoading } =
    useKpiWidget();
  const { meteoLoading, meteoData, meteoError, meteoSecondLoading } =
    useMeteoWidget();
  const { eALoading, eAData, eAError, eASecondLoading } =
    useEquipmentAvailabilityWidget();
  return (
    <Suspense fallback={<Fallback />}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: "16px", paddingTop: "16px" }}
      >
        <HeaderWidget
          selectedPortfolio={selectedPortfolio}
          portfolio={portfolio}
          selectedPlant={selectedPlant}
          plant={plant}
          date={globalDate}
          setDate={setDate}
          liveButton={liveButton}
          handleChangeLiveButton={checkLiveButton}
          lastUpdated={lastUpdated}
          onChangePortfolio={onChangePortfolio}
          onChangePlant={onChangePlant}
          genData={genData}
          genLoading={statusLoading}
        />
        <StatusWidget
          plant={plant}
          selectedPlant={selectedPlant}
          liveButton={liveButton}
          date={globalDate}
          loading={statusLoading}
          data={statusData}
          genData={genData}
          genLoading={statusLoading}
          error={statusError}
          DownloadData={DownloadData}
          downloadLoading={downloadLoading}
          downloadError={downloadError}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={1}
          columns={18}
        >
          <Grid item xs={18}>
            <KpiWidget
              liveButton={liveButton}
              data={kpiData}
              loading={kpiLoading}
              error={kpiError}
              kpiSecondLoading={kpiSecondLoading}
            />
          </Grid>
          <Grid
            item
            xs={18}
            lg={14}
            xl={14}
            order={{ xs: 4, sm: 4, md: 4, lg: 2, xl: 2 }}
          >
            <GraphsWidget />
          </Grid>
          <Grid
            item
            xs={18}
            lg={4}
            xl={4}
            order={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}
          >
            <EquipmentAvailabilityWidget
              liveButton={liveButton}
              data={eAData}
              loading={eALoading}
              error={eAError}
              secondLoading={eASecondLoading}
            />
            <MeteoWidget
              liveButton={liveButton}
              data={meteoData}
              loading={meteoLoading}
              error={meteoError}
              secondLoading={meteoSecondLoading}
            />
            <ActiveAlarmsWidget
              search={search}
              onSearch={onSearch}
              data={dataToSend}
              liveButton={liveButton}
              hours={hours}
            />
          </Grid>
        </Grid>
      </Container>
    </Suspense>
  );
};
