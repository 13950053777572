
import { Field } from "../../components/Fields";
import { Widget } from "../../components/CardsPageWidget";

const getFields = (values: Array<number>): Array<Field> => [
  {
    title: "Plants",
    value: values[0],
  },
  {
    title: "Capacity DC",
    value: values[1],
    unit: "MWp",
  },
  {
    title: "Generation",
    value: values[2],
    unit: "kWh",
  },
  {
    title: "Irradiation",
    value: values[3],
    unit: "kWh/m²",
  },
  {
    title: "PR",
    value: values[4],
    unit: "%",
    // prevValue: 2 + '%'
  },
  {
    title: "Availability",
    value: values[5],
    growth: values[6] * 100,
    unit: "%",
  },
  {
    title: "CUF/PLF",
    value: values[7],
    // prevValue: values[8],
    unit: "%",
    growth: 3,
  },
];

const MtdFieldsValues = [[3, 4, 2, 4, 2, 2, 2 , 2, 3, 3], [3, 4, 2, 4, 2, 2, 2 , 2, 3, 3]];
const QtdFieldsValues = [[3, 4, 2, 4, 2, 2, 2 , 2, 3, 3], [3, 4, 2, 4, 2, 2, 2 , 2, 3, 3]];
const YtdFieldsValues = [[3, 4, 2, 4, 2, 2, 2 , 2, 3, 3], [3, 4, 2, 4, 2, 2, 2 , 2, 3, 3]];
const FYtdFieldsValues = [[3, 4, 2, 4, 2, 2, 2 , 2, 3, 3], [3, 4, 2, 4, 2, 2, 2 , 2, 3, 3]];

const getValuesArray = (format: string) => {
  switch(format) {
    case 'MTD': return MtdFieldsValues;
    case 'QTD': return QtdFieldsValues;
    case 'YTD': return YtdFieldsValues;
    case 'FYTD': return FYtdFieldsValues;
  }
}

export const getFakeWidgets = (format: string): Array<Widget> => [
  {
    path: 'Rooftop',
    profit: 23,
    actualResult: 23,
    expectedResult: 45,
    title: 'Rooftop',
    fields: getFields(getValuesArray(format)[0]),
    errors: 0,
  },
  {
    path: 'Ground Mount',
    profit: 23,
    actualResult: 23,
    expectedResult: 45,
    title: 'Ground Mount',
    fields: getFields(getValuesArray(format)[1]),
    errors: 0,
  }
];
