import { convertTime, isAnyItemFalsy } from "./../../../shared/utils/utils";
import { useAppSelector } from "./../../../store/rootStore";
import {
  DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryChargeDischargeQuery,
  useGetBatteryChargeExtremasQuery,
  useGetBatterySocExtremasQuery,
} from "./BatteryMonitoring.api";
import { monitoringDashboardSelector } from "../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useMemo } from "react";
import { useUrlParams } from "../../../shared/utils/hooks";
import { getUnit, getValue } from "./utils";

export const useGetBatteryChargeDischargeSocWidget = () => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const { liveButton } = useAppSelector(
    monitoringDashboardSelector
  );

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const {
    data: batteryChargeDischargeData,
    isLoading: isBatteryChargeDischargeLoading,
    isFetching: isBatteryChargeDischargeFetching,
    isError: isBatteryChargeDischargeError,
    isUninitialized: isBatteryChargeDischargeUninitialized,
  } = useGetBatteryChargeDischargeQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
      fromDate,
      toDate,
    },
    {
      skip: isAnyItemFalsy([
        fromDate,
        toDate,
        plantId,
        DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: batteryChargeExtremasData,
    isLoading: isBatteryChargeExtremasLoading,
    isFetching: isBatteryChargeExtremasFetching,
    isError: isBatteryChargeExtremasError,
    isUninitialized: isBatteryChargeExtremasUninitialized,
  } = useGetBatteryChargeExtremasQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
    },
    {
      skip: isAnyItemFalsy([fromDate, toDate, plantId, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: batterySocExtremasData,
    isLoading: isBatterySocExtremasLoading,
    isFetching: isBatterySocExtremasFetching,
    isUninitialized: isBatterySocExtremasUninitialized,
    isError: isBatterySocExtremasError,
  } = useGetBatterySocExtremasQuery(
    {
      fromDate,
      toDate,
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
    },
    {
      skip: isAnyItemFalsy([plantId, fromDate, toDate, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const isLoading = useMemo(() => {
    return (
      isBatteryChargeDischargeLoading ||
      isBatteryChargeDischargeUninitialized ||
      isBatteryChargeExtremasLoading ||
      isBatteryChargeExtremasUninitialized ||
      isBatterySocExtremasLoading ||
      isBatterySocExtremasUninitialized
    );
  }, [
    isBatteryChargeDischargeLoading,
    isBatteryChargeDischargeUninitialized,
    isBatteryChargeExtremasLoading,
    isBatteryChargeExtremasUninitialized,
    isBatterySocExtremasLoading,
    isBatterySocExtremasUninitialized,
  ]);

  const isFetching = useMemo(() => {
    return (
      isBatteryChargeDischargeFetching ||
      isBatteryChargeExtremasFetching ||
      isBatterySocExtremasFetching
    );
  }, [
    isBatteryChargeDischargeFetching,
    isBatteryChargeExtremasFetching,
    isBatterySocExtremasFetching,
  ]);

  const isError = useMemo(() => {
    return (
      isBatteryChargeDischargeError ||
      isBatteryChargeExtremasError ||
      isBatterySocExtremasError
    );
  }, [
    isBatteryChargeDischargeError,
    isBatteryChargeExtremasError,
    isBatterySocExtremasError,
  ]);

  const result = useMemo(() => {
    return {
      top: [
        {
          label: "Charged energy",
          value: getValue(batteryChargeDischargeData?.charged),
          unit: getUnit(batteryChargeDischargeData?.charged),
        },
        {
          label: "Discharged energy",
          value: getValue(batteryChargeDischargeData?.discharged),
          unit: getUnit(batteryChargeDischargeData?.discharged),
        },
      ],
      bottom: [
        {
          label: "Max charge active power",
          value: getValue(batteryChargeExtremasData?.maxCharge),
          unit: getUnit(batteryChargeExtremasData?.maxCharge),
        },
        {
          label: "Max discharge active power",
          value: getValue(batteryChargeExtremasData?.maxDischarge),
          unit: getUnit(batteryChargeExtremasData?.maxDischarge),
        },
        {
          label: "Max SoC",
          value: getValue(batterySocExtremasData?.maxSoc),
          unit: getUnit(batterySocExtremasData?.maxSoc),
        },
        {
          label: "Min SoC",
          value: getValue(batterySocExtremasData?.minSoc),
          unit: getUnit(batterySocExtremasData?.minSoc),
        },
      ],
    };
  }, [
    batteryChargeDischargeData,
    batteryChargeExtremasData,
    batterySocExtremasData,
  ]);

  return {
    data: result,
    isLoading,
    isFetching,
    isError,
  };
};

export type TemperatureWidgetData = ReturnType<
  typeof useGetBatteryChargeDischargeSocWidget
>;
