import React, { useMemo, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { getPageItem } from "../../shared/utils/pages";
import { PageItem } from "../../shared/interfaces";
import { SiteInfoNavigation } from "./SiteInfoNavigation.component";
import { Fallback } from "../../app/Fallback";

const PlantSiteInfo = lazy(() =>
  import("../pages/PlantSiteInfo/index").then((module) => ({
    default: module.PlantSiteInfo,
  }))
);

export enum SiteInfoPagesEnum {
  GeneralSiteInfo = "/siteInfo/general",
  PlantSiteInfo = "/siteInfo/plant",
}

type SiteInfoPagesMap = {
  [key in SiteInfoPagesEnum]?: PageItem;
};

const getSiteInfoPages = (
): SiteInfoPagesMap => ({
  [SiteInfoPagesEnum.PlantSiteInfo]: getPageItem(
    SiteInfoPagesEnum.PlantSiteInfo,
    PlantSiteInfo,
    "",
  ),
});

export const SiteInfoPages: React.FC<any> = () => {
  const pages: SiteInfoPagesMap = useMemo(() => {
    return getSiteInfoPages();
  }, []);

  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        {Object.values(pages).map(({ path, component: Component, params }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
    </Suspense>
  );
};

export const getSiteInfoMenuItem = (params: any): any => ({
  idx: "siteInfo",
  title: "",
  icon: "",
  path: SiteInfoPagesEnum.GeneralSiteInfo,
  component: SiteInfoNavigation,
  items: [],
});
