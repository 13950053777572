import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridSize, Tooltip } from '@material-ui/core';
import clsx, { ClassValue } from 'clsx';
import { useSharedFormState } from './../../../shared/utils/sharedStates';

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: 200,
    overflow: 'hidden',
    padding: '0 !important',
    marginBottom: theme.spacing(2),
  },

  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },

  chartLabel: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#707070',
  },

  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }
}));

interface WidgetProps {
  label?: string;
  size?: GridSize;
  children?: React.ReactNode;
  customMenu?: React.ReactNode;
  styles?: ClassValue;
  height?: number | string;
  definition?: any;
}

export const Widget: React.FC<WidgetProps> = (props: WidgetProps) => {
  const { label, children, customMenu, size = 12, styles = null, height = 400 } = props;
  const { viewDefinitions } = useSharedFormState();

  const classes = useStyles();
  return (

    <Grid item xs={12} sm={12} md={size} lg={size} className={classes.grid} style={{ maxHeight: height, height }}>
      <div className={clsx(classes.wrapper, styles)}>
        <div className={classes.header}>
          <Tooltip title={!viewDefinitions ? "" : props.definition?.[label]}>{label?.length ? <div className={classes.chartLabel}>{label}</div> : null}</Tooltip>
          {customMenu || null}
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </Grid>

  )
}
