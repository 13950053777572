import { useBatteryVoltageAndFrequencyWidget } from "../../useBatteryVoltageAndFrequency";
import { DataWithUnitWidget } from "../DataWithUnitWidget";

export const BatteryVoltageAndFrequencyWidget = () => {
  const {
    isLoading: isBatteryVoltageAndFrequencyLoading,
    isFetching: isBatteryVoltageAndFrequencyFetching,
    data: batteryVoltageAndFrequencyData,
    isError: isBatteryVoltageAndFrequencyError,
  } = useBatteryVoltageAndFrequencyWidget();

  return (
    <DataWithUnitWidget
      title={"Voltage, Current & Frequency (AC)"}
      options={batteryVoltageAndFrequencyData}
      optionsCount={4}
      isLoading={isBatteryVoltageAndFrequencyLoading}
    />
  );
};
