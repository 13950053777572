import { useEffect, useState } from "react";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Data {
  tenants: any;
}

export const UseModuleTenant = (): Data => {
  const [tenants, setTenants] = useState(0);
  const [loading, setloading] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = async () => {
    setloading(true);
    fetchAsync(`onboardingV2/v1/Tenant/GetTenants`, "GET")
      .then((data: any) => {
        if (data) {
          setTenants(data);
        }
        setloading(false);
      })
      .catch((e) => { console.error(e); setloading(false); });
  };

  return {
    tenants
  };

};
