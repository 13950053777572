import { useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { totalTimeAgo } from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useGetMostRecentAlertsByPlantQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useAppSelector } from "../../../../../store/rootStore";

const alarmLevels = ["Information", "Warning", "Error", "Critical"];

export const useActiveAlarmsWidget = () => {
  // const [url] = useSearchParams();
  // const plantId = url.get("plantId");

  // const { plantTimeZone } = useAppSelector(monitoringDashboardSelector);
  // const { liveButton } = useAppSelector(monitoringSharedSelector);

  // const [search, setSearch] = useState("");

  // const { data: mostRecentAlertsByPlantData } =
  //   useGetMostRecentAlertsByPlantQuery(
  //     { plantId, top: 10 },
  //     { skip: !plantId, pollingInterval: liveButton ? 60000 : undefined }
  //   );

  // const data = useMemo(
  //   () =>
  //     mostRecentAlertsByPlantData
  //       ?.filter(({ isActive }) => isActive)
  //       .map((item) => ({
  //         alarmName: item.description,
  //         alarmType: alarmLevels[item.alarmLevel],
  //         createdOn: item.createdOn,
  //         modifiedOn: item.modifiedOn,
  //       })) || [],
  //   [mostRecentAlertsByPlantData]
  // );

  // const createdOn = useMemo(
  //   () => data?.map((item) => item.createdOn) || [],
  //   [data]
  // );

  // const dataToSend = useMemo(
  //   () =>
  //     data?.filter((item) =>
  //       item.alarmName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  //     ) || [],
  //   [data, search]
  // );

  // const hours = useMemo(() => {
  //   if (!plantTimeZone || !createdOn.length) {
  //     return [];
  //   }

  //   return createdOn?.map((item) => totalTimeAgo(item, plantTimeZone)) || [];
  // }, [createdOn, plantTimeZone]);

  // const onSearch = (newSearch: string) => {
  //   setSearch(newSearch);
  // };

  return {
    search: null,
    onSearch: null,
    dataToSend: null,
    hours: null,
  };

};

export type UseActiveAlarmsWidgetData = ReturnType<
  typeof useActiveAlarmsWidget
>;
