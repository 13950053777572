import { useAppSelector } from "../../../../../store/rootStore";
import { useState, useMemo, useCallback } from "react";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import {
  convertTime,
  diffDateDays,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import {
  VALUE_TYPE,
  VALUE_FORMAT,
  getSeries,
  tooltipFormatter,
  getXCategories,
  getElementsCountAndFormat,
  DEFAULT_HEATMAP_TEMPERATURE_RANGE,
  transformData,
  getUnit,
  getAllStringNames,
} from "./utils";
import {
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTION_VALUE,
  HEATMAP_COLORS,
} from "../../utils";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryHeatmapStringsAverageCurrentQuery,
  useGetBatteryHeatmapStringsAverageTemperatureQuery,
  useGetBatteryHeatmapStringsAverageVoltageQuery,
  useGetBatteryHeatmapStringsMaxTemperatureQuery,
  useGetBatteryHeatmapStringsMaxVoltageQuery,
  useGetBatteryHeatmapStringsMinTemperatureQuery,
  useGetBatteryHeatmapStringsMinVoltageQuery,
} from "../../BatteryMonitoring.api";
import { useUrlParams } from "../../../../../shared/utils/hooks";

export const useBatteryHeatmapWidget = () => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const { liveButton } = useAppSelector(monitoringDashboardSelector);

  const [dropdownValue, setDropdownValue] = useState<DROPDOWN_OPTION_VALUE>(
    DROPDOWN_OPTION_VALUE.ONE_HOUR
  );

  const [valueType, setValueType] = useState<VALUE_TYPE>(
    VALUE_TYPE.TEMPERATURE
  );
  const [valueFormat, setValueFormat] = useState<VALUE_FORMAT>(
    VALUE_FORMAT.MAX
  );

  const daysDiff = useMemo(() => {
    return diffDateDays(startDate, endDate);
  }, [startDate, endDate]);

  const isValueFormatVisible = useMemo(() => {
    return valueType !== VALUE_TYPE.CURRENT;
  }, [valueType]);

  const {
    data: dataStringsAverageCurrent,
    isLoading: isLoadingStringsAverageCurrent,
    isFetching: isFetchingStringsAverageCurrent,
    isError: isErrorStringsAverageCurrent,
  } = useGetBatteryHeatmapStringsAverageCurrentQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsAverageTemperature,
    isLoading: isLoadingStringsAverageTemperature,
    isFetching: isFetchingStringsAverageTemperature,
    isError: isErrorStringsAverageTemperature,
  } = useGetBatteryHeatmapStringsAverageTemperatureQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsMinTemperature,
    isLoading: isLoadingStringsMinTemperature,
    isFetching: isFetchingStringsMinTemperature,
    isError: isErrorStringsMinTemperature,
  } = useGetBatteryHeatmapStringsMinTemperatureQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsMaxTemperature,
    isLoading: isLoadingStringsMaxTemperature,
    isFetching: isFetchingStringsMaxTemperature,
    isError: isErrorStringsMaxTemperature,
  } = useGetBatteryHeatmapStringsMaxTemperatureQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsAverageVoltage,
    isLoading: isLoadingStringsAverageVoltage,
    isFetching: isFetchingStringsAverageVoltage,
    isError: isErrorStringsAverageVoltage,
  } = useGetBatteryHeatmapStringsAverageVoltageQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsMinVoltage,
    isLoading: isLoadingStringsMinVoltage,
    isFetching: isFetchingStringsMinVoltage,
    isError: isErrorStringsMinVoltage,
  } = useGetBatteryHeatmapStringsMinVoltageQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataStringsMaxVoltage,
    isLoading: isLoadingStringsMaxVoltage,
    isFetching: isFetchingStringsMaxVoltage,
    isError: isErrorStringsMaxVoltage,
  } = useGetBatteryHeatmapStringsMaxVoltageQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
      granularity: dropdownValue.toString(),
    },
    {
      skip: isAnyItemFalsy([
        plantId,
        DEFAULT_EQUIPMENT_NAME,
        fromDate,
        toDate,
        dropdownValue.toString(),
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const selectedSeriesData = useMemo(() => {
    switch (valueType) {
      case VALUE_TYPE.VOLTAGE:
        switch (valueFormat) {
          case VALUE_FORMAT.MAX:
            return dataStringsMaxVoltage;
          case VALUE_FORMAT.AVG:
            return dataStringsAverageVoltage;
          case VALUE_FORMAT.MIN:
            return dataStringsMinVoltage;
        }
        break;
      case VALUE_TYPE.CURRENT:
        return dataStringsAverageCurrent;
      case VALUE_TYPE.TEMPERATURE:
        switch (valueFormat) {
          case VALUE_FORMAT.MAX:
            return dataStringsMaxTemperature;
          case VALUE_FORMAT.AVG:
            return dataStringsAverageTemperature;
          case VALUE_FORMAT.MIN:
            return dataStringsMinTemperature;
        }
    }
  }, [
    valueType,
    valueFormat,
    dataStringsMaxVoltage,
    dataStringsAverageVoltage,
    dataStringsMinVoltage,
    dataStringsAverageCurrent,
    dataStringsMaxTemperature,
    dataStringsAverageTemperature,
    dataStringsMinTemperature,
  ]);

  const areSeriesAvaialable = useMemo(() => {
    return Boolean(selectedSeriesData);
  }, [selectedSeriesData]);

  const elementsCountAndFormat = useMemo(() => {
    const { count, format } = getElementsCountAndFormat(
      dropdownValue,
      daysDiff
    );

    return {
      count: count || 0,
      format: format || "hour",
    };
  }, [dropdownValue, daysDiff]);

  const ranges = useMemo(() => {
    switch (valueType) {
      case VALUE_TYPE.TEMPERATURE:
        return DEFAULT_HEATMAP_TEMPERATURE_RANGE;
      case VALUE_TYPE.VOLTAGE:
        return null;
      case VALUE_TYPE.CURRENT:
        return null;
    }
  }, [valueType]);

  const unit = useMemo(() => {
    return selectedSeriesData ? getUnit(selectedSeriesData) : "";
  }, [selectedSeriesData]);

  const parsedSeries = useMemo(() => {
    if (!areSeriesAvaialable) {
      return [];
    }

    return getSeries(selectedSeriesData, unit, ranges);
  }, [selectedSeriesData, valueType, areSeriesAvaialable]);

  const xCategories = useMemo(() => {
    return areSeriesAvaialable
      ? getXCategories(
          elementsCountAndFormat.count,
          elementsCountAndFormat.format
        )
      : [];
  }, [areSeriesAvaialable, elementsCountAndFormat]);

  const yCategories = useMemo(() => {
    return (selectedSeriesData ? getAllStringNames(selectedSeriesData) : [])
      .map((item) => String(item).replace("_", " "))
      .map((item) => {
        const [_str, num] = item.split(" ");
        if (!isNaN(Number(num))) {
          return `${Number(num) + 1}`;
        } else {
          return item;
        }
      });
  }, [selectedSeriesData]);

  const formatter = useMemo(() => {
    return tooltipFormatter(valueType, elementsCountAndFormat.format, "string");
  }, [valueType, elementsCountAndFormat]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: "heatmap",
        plotBorderWidth: 0,
        borderWidth: 0,
      },
      plotOptions: {
        series: {
          states: {
            inactive: { enabled: false },
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "Battery pack parameters",
        align: "left",
        x: -4,
        y: 24 + 12,
        margin: 52,
        style: {
          fontWeight: 500,
          fontSize: "20px",
          fontFamily: "Inter",
        },
      },
      navigation: {
        buttonOptions: {
          y: 16,
          x: -16,
        },
      },

      xAxis: {
        title: null,
        categories: xCategories,
        gridLineWidth: 0,
      },

      yAxis: {
        categories: yCategories,
        title: {
          text: "String",
          margin: 16,
          y: 20,
          x: 8,
          style: {
            fontWeight: "regular",
            fontSize: "12px",
            lineHeight: "14px",
            fontFamily: "Inter",
          },
        },
        reversed: true,
        gridLineWidth: 0,
      },

      label: {},

      colors: HEATMAP_COLORS,

      legend: {
        x: -12,
        align: "left",
        layout: "horizontal",
        margin: 20,
        verticalAlign: "top",
        symbolRadius: 2,
        itemDistance: 32,
        itemStyle: {
          fontWeight: "regular",
        },
      },

      tooltip: {
        formatter: formatter,
      },
      series: parsedSeries,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  format: "{substr value 0 1}",
                },
              },
            },
          },
        ],
      },
    };
  }, [parsedSeries, xCategories, yCategories, formatter]);

  const dropdownOptions = useMemo(() => {
    // 1 day or live
    if (liveButton || daysDiff === 0) {
      setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_HOUR);
      return DROPDOWN_OPTIONS.slice(5, 6);
    }
    // for 2 days
    else if (daysDiff === 1) {
      setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_DAY);
      return DROPDOWN_OPTIONS.slice(5, 7);
    }
    // 3 - 30 days
    else if (daysDiff > 1 && daysDiff <= 29) {
      setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_DAY);
      return daysDiff > 6
        ? DROPDOWN_OPTIONS.slice(6, 8)
        : DROPDOWN_OPTIONS.slice(6, 7);
    }
    // 30 - 60 days
    else if (daysDiff > 29 && daysDiff <= 59) {
      setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_DAY);
      return DROPDOWN_OPTIONS.slice(6, 9);
    }
    // more than 60 days
    else if (daysDiff > 59) {
      setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_WEEK);
      return daysDiff > 365
        ? DROPDOWN_OPTIONS.slice(7, 10)
        : DROPDOWN_OPTIONS.slice(7, 9);
    }
    return DROPDOWN_OPTIONS;
  }, [liveButton, valueType, daysDiff, setDropdownValue]);

  const buttonGroups = useMemo(() => {
    return [
      {
        buttons: [
          {
            label: VALUE_TYPE.TEMPERATURE,
            onClick: () => {
              setValueType(VALUE_TYPE.TEMPERATURE);
            },
            active: valueType === VALUE_TYPE.TEMPERATURE,
          },
          {
            label: VALUE_TYPE.VOLTAGE,
            onClick: () => {
              setValueType(VALUE_TYPE.VOLTAGE);
            },
            active: valueType === VALUE_TYPE.VOLTAGE,
          },
          {
            label: VALUE_TYPE.CURRENT,
            onClick: () => {
              setValueType(VALUE_TYPE.CURRENT);
            },
            active: valueType === VALUE_TYPE.CURRENT,
          },
        ],
      },
      {
        // TODO: kaz investigate is it required to switch under the hood from any selected valueFormat to null
        buttons: isValueFormatVisible
          ? [
              {
                label: VALUE_FORMAT.MAX,
                onClick: () => {
                  setValueFormat(VALUE_FORMAT.MAX);
                },
                active: valueFormat === VALUE_FORMAT.MAX,
              },
              {
                label: VALUE_FORMAT.AVG,
                onClick: () => {
                  setValueFormat(VALUE_FORMAT.AVG);
                },
                active: valueFormat === VALUE_FORMAT.AVG,
              },
              {
                label: VALUE_FORMAT.MIN,
                onClick: () => {
                  setValueFormat(VALUE_FORMAT.MIN);
                },
                active: valueFormat === VALUE_FORMAT.MIN,
              },
            ]
          : [],
      },
    ];
  }, [valueType, valueFormat, daysDiff, isValueFormatVisible]);

  const onDropdownChange = useCallback((value: DROPDOWN_OPTION_VALUE) => {
    setDropdownValue(value);
  }, []);

  return {
    dropdownOptions: {
      value: dropdownValue,
      items: dropdownOptions,
      onChange: onDropdownChange,
    },
    buttonGroups,
    chartOptions,
  };
};
