import {Tooltip} from '@material-ui/core';
import { useStyles } from "./styles";
import {useSharedFormState} from './../../../shared/utils/sharedStates';

interface WidgetItemProps {
  children: React.ReactNode;
  minHeight?: number;
  title?: string;
  menu?: React.ReactNode;
  definition?:any;
}

export const WidgetItem = ({
  children,
  title,
  menu,
  minHeight,
  definition,
}: WidgetItemProps) => {
  const classes = useStyles();
  const showHeader = title?.length || menu;
  const { viewDefinitions } = useSharedFormState();

  return (
    <div className={classes.widgetItem} style={{ minHeight }}>
      {showHeader ? (
        <div className={classes.header}>
          {title?.length ? (
            <Tooltip title={!viewDefinitions ? "" : definition?.[title]}>
              <div className={classes.title}>{title}</div>
            </Tooltip>
          ) : null}

          {menu}
        </div>
      ) : null}
      {children}
    </div>
  );
};
