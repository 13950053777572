import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";

export interface IAlarmState {
  gettingAllAlarms?: boolean;
  alarmsFailed?: boolean;
  alarmsSuccessful?: boolean;
  alarms?: any[];

  gettingAlarmCategory?: boolean;
  alarmCategoryFailed?: boolean;
  alarmCategorySuccessful?: boolean;

  gettingPortfolios?: boolean;
  portfoliosFailed?: boolean;
  portfoliosSuccessful?: boolean;
}
const initialState: IAlarmState = {
  gettingAllAlarms: false,
  alarmsFailed: false,
  alarmsSuccessful: false,
  alarms: [],
  gettingAlarmCategory: false,
  alarmCategoryFailed: false,
  alarmCategorySuccessful: false,

  gettingPortfolios: false,
  portfoliosFailed: false,
  portfoliosSuccessful: false,
};
// name
// reducer
// actions
// caseReducers
export const alarmSlice = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    // get all alarms
    alarmsStart: (state) => {
      return {
        ...state,
        gettingAllAlarms: true,
      };
    },
    alarmsSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingAllAlarms: false,
        alarmsSuccessful: true,
        alarms: payload,
      };
    },
    alarmsError: (state) => {
      return {
        ...state,
        gettingAllAlarms: false,
        alarmsFailed: true,
      };
    },
    // get alarm category
    alarmCategoryStart: (state) => {
      return {
        ...state,
        gettingAlarmCategory: true,
      };
    },
    alarmCategorySuccess: (state) => {
      return {
        ...state,
        gettingAlarmCategory: false,
        alarmCategorySuccessful: true,
      };
    },
    alarmCategoryError: (state) => {
      return {
        ...state,
        gettingAlarmCategory: false,
        alarmCategoryFailed: true,
      };
    },
    // get portfolios and plants
    portfoliosStart: (state) => {
      return {
        ...state,
        gettingPortfolios: true,
      };
    },
    portfoliosSuccess: (state) => {
      return {
        ...state,
        gettingPortfolios: false,
        portfoliosSuccessful: true,
      };
    },
    portfoliosError: (state) => {
      return {
        ...state,
        gettingPortfolios: false,
        portfoliosFailed: true,
      };
    },
  },
});
// alarms actions
export const {
  alarmsStart,
  alarmsSuccess,
  alarmsError,
  alarmCategoryStart,
  alarmCategorySuccess,
  alarmCategoryError,
  portfoliosStart,
  portfoliosSuccess,
  portfoliosError,
} = alarmSlice.actions;
// alarms state
export const alarmsSelector = (state: RootState) => state.Sidem.alarm;
// alarms reducer
export const alarmsReducer = alarmSlice.reducer;
