import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(0.5, 3, 0.5, 3),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: `${theme.spacing(0, 0, 1, 1)}!important`,
    },
    kpiText: {
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "26.4px",
        paddingBottom: "6px",
    },
    loadingColor: {
        color: "#C7C8C9",
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "26.4px",
        paddingBottom: "6px",
    },
    kpiUnit: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22.4px",
        paddingLeft: "10px",
    },
    flexType: {
        display: 'flex',
        alignItems: 'center',
        gap: 6
    },
    kpiType: {
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "15.6px",
    },
    kpiTypeNoGrowth: {
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "15.6px",
        paddingTop: "3px",
    },
    Equal: {
        color: "#0C1E33",
        padding: "0px 5px 0px 5px",
        borderRadius: "4px",
        backgroundColor: "#E1E4E9",
    },
    EqualLabel: {
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "15.6px",
        paddingTop: "3px",
    },
    High: {
        padding: "0px 5px 0px 5px",
        borderRadius: "4px",
        backgroundColor: "rgba(121, 190, 170, 0.2)",
    },
    HighLabel: {
        color: "#49937D",
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "15.6px",
    },
    Low: {
        padding: "0px 5px 0px 5px",
        borderRadius: "4px",
        backgroundColor: "rgba(222, 80, 79, 0.2)",
    },
    LowLabel: {
        color: "#DE504F",
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: "15.6px",
    },
    growthIcon: {
        paddingLeft: "3px",
    },
    divider: {
        '@media (max-width: 988px)': {
            display: "none",
        }
    },

}));
