import React from "react";
import { Container } from "@mui/material";
import { HeaderWidget } from "./components/HeaderWidget";
import { ForecastWidget } from "./components/ForecastWidget/ForecastWidget.component";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";

export const AnalyticsForecasting: React.FC<any> = () => {
  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setSelectedPortfolio,
    setSelectedPlant,
    date,
    setDate,
    liveButton,
    checkLiveButton,
    onChangePortfolio,
    onChangePlant,
  } = useHeaderWidget();

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ padding: "16px", paddingTop: "16px" }}
    >
      <HeaderWidget
        selectedPortfolio={selectedPortfolio}
        portfolio={portfolio}
        setSelectedPortfolio={setSelectedPortfolio}
        selectedPlant={selectedPlant}
        plant={plant}
        setSelectedPlant={setSelectedPlant}
        liveButton={true} // demo
        handleChangeLiveButton={checkLiveButton}
        date={date}
        setDate={setDate}
        onChangePortfolio={onChangePortfolio}
        onChangePlant={onChangePlant}
      />
      <ForecastWidget />
    </Container>
  );
};
