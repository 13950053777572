import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Data {
  upsertTenant: (params: any, id: string, callback: any) => void;
  Subsidiary: any;
  Contacts: any;
  Company: any;
  module: any;
}

export const UseModuleTenantSave = (): Data => {

  const [Subsidiary, setSubsidiaries] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);
  const [module, setModule] = useState(null);

  useEffect(() => {
    getModule();
  }, [])

  const upsertTenant = async (params: any, id: string, callback: any) => {
    let requestType = "POST";

    if (id && id != "new") {
      params.tenantId = id;
      requestType = "PUT";
    }

    await fetchAsync(`/ModuleTenant`, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };


  const getModule = async () => {
    await fetchAsync(`/Module`, "GET")
      .then((data: any) => {
        if (data) {
          let newModule = data?.map(item => ({
            ...item,
            value: item.moduleId,
            label: item.modulName,
          }));
          setModule(newModule);

        }
      })
      .catch((e) => { console.error(e); });
  };

  return {
    upsertTenant,
    Subsidiary,
    Contacts,
    Company,
    module
  };
};
