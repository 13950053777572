import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MultilineChartWidget } from "../../../../components/MultilineChartWidget";
import { CustomTooltip } from "./CustomTooltip.componets";
import { useACPowervsIrradiationWidget } from "./useACPowervsIrradiationWidget";
import { lines, yAxises } from "./data";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(0),
  },
}));

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const ACPowervsIrradiationWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
}) => {
  const classes = useStyles();

  const [tilt, setTilt] = useState(true);

  const { data, error, loading } = useACPowervsIrradiationWidget({
    plantId,
    date,
    tilt,
  });
  const hide = !loading && !data?.length;

  return hide ? null : (
    <MultilineChartWidget
      title="AC Power Vs Irradiation"
      styles={classes.marginRight}
      lines={lines}
      showYAxises={false}
      yAxises={yAxises}
      data={data}
      loading={loading}
      error={error}
      height={600}
      size={12}
      tooltipContent={<CustomTooltip />}
      customMenu={null}
      definition={definition}
    />
  );
};
