import React from 'react'
import { Container } from '@mui/material'
import { useHeaderWidget } from './components/HeaderWidget/useHeaderWidget'
import { HeaderWidget } from './components/HeaderWidget'
import { SummaryWidget } from './components/SummaryWidget'
import { PlantsWidget } from './components/PlantsWidget'


export const AnalyticsDashboard: React.FC<any> = () => {
    const {
        portfolio,
        plant,
        selectedPortfolio,
        selectedPlant,
        setSelectedPlant,
        date,
        setDate,
        liveButton,
        checkLiveButton,
        onChangePortfolio,
        preSelectedPlant,
        preSelectedPortfolio,
    } = useHeaderWidget()
    return (
        <Container disableGutters maxWidth={false} sx={{ padding: "16px", paddingTop: "16px" }}>
            <HeaderWidget
                selectedPortfolio={selectedPortfolio}
                portfolio={portfolio}
                selectedPlant={selectedPlant}
                plant={plant}
                setSelectedPlant={setSelectedPlant}
                date={date}
                setDate={setDate}
                liveButton={liveButton}
                handleChangeLiveButton={checkLiveButton}
                onChangePortfolio={onChangePortfolio}
                preSelectedPlant={preSelectedPlant}
                preSelectedPortfolio={preSelectedPortfolio}
            />
            <SummaryWidget />
            <PlantsWidget />
        </Container>
    )
}
