import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { TextField } from '@material-ui/core';
import { Autocomplete } from "@mui/material";
import { timezoneObjects } from "../../components/Utils/timeZone";
import { ActionButtons } from "../../../../shared/components";
import { UsePlantSave } from "./usePlantSave";
import { plantFormSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { updatePlantReq } from '../../../../features/Onboarding/plants/plantActions';
import { insertPlantReq } from '../../../../features/Onboarding/plants/plantActions';
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete, } from "./../../components/Utils/utils";
import { useStyles } from "./../styles";

export const PlantSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { plantId } = useParams<{ plantId: string }>();
  const dispatch = useDispatch()
  const [loading, setloading] = useState(null);

  const {
    plant,
    upsert,
    oldPlantCode,
    oldPlantName,
    oldPlantTimeZone
  } = UsePlantSave({
    plantId,
    portfolioId,
    tenantId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { moduleId } = useParams<{ moduleId: string }>();

  const createNewPlant = plantId === "new";
  useEffect(() => {
    initializeValidationsObsolete(plantFormSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (plant || createNewPlant) {
      initializeValuesObsolete(
        plantFormSchema,
        createNewPlant,
        plant,
        (resp) => {
          const newResp = { ...resp, plantTimeZone: oldPlantTimeZone ? oldPlantTimeZone : '' }
          setInitialValues(newResp);
        }
      );
    }
  }, [plant]);

  const editPlant = async (values, callback: any) => {
    setloading(true)
    if (plantId === "new") {
      let fields = JSON.parse(JSON.stringify(values, null, 2));
      fields.PortfolioId = portfolioId;
      fields.TenantId = tenantId;
      fields.PlantCode = values.plantCode;
      fields.PlantName = values.plantName;
      fields.PlantTimeZone = values.platTimeZone;
      fields.ModuleId = moduleId;
      const res: any = await dispatch(insertPlantReq(fields))
      if (!res) return;
      callback(res);
    }
    else {
      let fields = JSON.parse(JSON.stringify(values, null, 2));
      fields.PortfolioId = portfolioId;
      fields.TenantId = tenantId;
      fields.PlantId = plantId;
      fields.PlantCode = oldPlantCode;
      fields.UpdatedPlantName = values.plantName;
      fields.UpdatedPlantCode = values.plantCode;
      fields.UpdatedPlantTimeZone = values.plantTimeZone;
      const res: any = await dispatch(updatePlantReq(fields))
      if (!res) return;
      callback(res);
    }

    setloading(false);
  };

  const onSubmitHandler = async (values: any) => {
    editPlant(values, (resp: any) => {
      if (resp[0]?.errorMessage) {
        setSeverity("error");
        setMessage("Plant not saved. " + resp[0].errorMessage);
        setIsOpen(true);
      }
      else {
        setSeverity("success");
        setMessage("Plant saved successfully");
        setIsOpen(true);
      }
      setTimeout(() => {
        let URI = `/onboarding/portfolio/${tenantId}/${moduleId}/${portfolioId}`;
        renderPage(URI);
      }, 2000);
    });
  };
  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  useEffect(() => {
    if (formik?.initialValues?.plantTimeZone) {
      setTimezone({ label: formik?.initialValues?.plantTimeZone || "", value: formik?.initialValues?.plantTimeZone || "" });
    } else {
      setTimezone({ label: "", value: "" });
    }
  }, [formik?.initialValues.plantTimeZone]);


  const renderPage = (URI) => {
    navigate(URI);
  };

  const [timezone, setTimezone] = useState({ label: formik.values.plantTimeZone, value: formik.values.plantTimeZone });

  const inputRenderer = (params) =>
  (
    <TextField
      className={classes.timeZone}
      {...params}
      variant={'outlined'}
    />
  );

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewPlant ? <>Add a new plant </> : <>Edit plant</>}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(plantFormSchema).map((x) => {
              return (
                <DynamicField
                  name={x}
                  data={plantFormSchema[x]}
                  formik={formik}
                />
              )
            })}
            <Typography style={{ fontWeight: "bold" }} className={classes.timeZoneLabel}>Plant Time Zone* </Typography>
            <Autocomplete
              className={classes.timeZoneSelect}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  height: "38px",
                  '&.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                    marginTop: "-10px",
                    marginLeft: "-7px",
                  }
                }
              }}
              options={timezoneObjects}
              value={timezone}
              onChange={(event, timeZone) => {
                if (timeZone === null) {
                  setTimezone(null);
                  formik.setFieldValue('plantTimeZone', null);
                } else {
                  setTimezone(timeZone);
                  formik.setFieldValue('plantTimeZone', timeZone?.value);// Clear the formik value
                }

              }}
              aria-required={true}
              renderInput={inputRenderer}
            />
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Back"
              onCancel={() => {
                let URI = `/onboarding/portfolio/${tenantId}/${moduleId}/${portfolioId}`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
