import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(2),
    marginRight: "0 !IMPORTANT",
    padding: theme.spacing(2),
    width: "100%",
    position: "relative",
  },
  appBar: {
    color: "black",
    margin: theme.spacing(2),
    backgroundColor: "#FFFFFF !important",
  },
  tabContainer: {
    alignItems: "center",
  },
  paramContainer: {
    // marginLeft: theme.spacing(1),
  },
  buttonStyle: {
    backgroundColor: "black",
    color: "white",
  },
  paramList: {
    overflow: "scroll",
    maxHeight: "710px",
    border: "1px solid black",
    padding: "1rem !important",
    background: "white",
  },
  btnGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
  btn: {
    padding: "1rem",
  },
  inputStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "0.5rem",
  },
  popupLabel: {
    paddingRight: "2em !IMPORTANT",
  },
  cellHeader: {
    backgroundColor: "#4473C3",
    color: "white !IMPORTANT",
    border: "1px solid white",
  },
  includeBtnGroup: {
    textAlign: "end",
    padding: "0 1rem",
  },
}));
