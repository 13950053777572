import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment";
import {
  autoRefreshApiTime,
  convertTime,
} from "../../../../../shared/utils/utils";
import { downloadDataGenPage, getGenerationPlantOverviewKPI } from "../../../../../features/Monitoring/monitoringGeneration/monitoringGenerationActions";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";

interface Props {
  selectedPortfolio: any;
  selectedPlant: any;
}
interface Data {
  search: string;
  onSearch: (newSearch: string) => void;
  plantOverviewLoading: boolean;
  plantOverviewData: any;
  POError: boolean;
  multiplePlants: boolean;
  userTimeZoneComp: boolean;
  DownloadData: (granularity: string,
    closeMenu: () => void,
    setOpenSnackbar: () => void,
    setSeverity: () => void,
    setMessage: () => void) => void;
  downloadLoading: boolean;
  downloadError: boolean;
}

export const useGenerationPlantOverview = ({
  selectedPortfolio,
  selectedPlant,
}: Props): Data => {
  const [url] = useSearchParams()
  const startDate = url.get("startDate")
  const endDate = url.get("endDate")
  const plantId = url.get("plantId")
  const portfolioId = url.get("portfolioId")

  const { liveButton } = useSelector(monitoringSharedSelector);

  const [search, setSearch] = useState("");
  const [plantOverviewLoading, setPlantOverviewLoading] = useState(true);
  const [plantOverviewData, setPlantOverviewData] = useState([]);
  const [data, setData] = useState([]); /* To keep the original data preserved */
  const [POError, setPOError] = useState(false);
  const [multiplePlants, setMultiplePlants] = useState(false);
  const [userTimeZoneComp, setUserTimeZoneComp] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const userTimezone = moment.tz.guess();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!plantId || !startDate || !endDate) return;
    setPlantOverviewLoading(true);
    getPlantOverviewData();
  }, [plantId, startDate, endDate]);

  useEffect(() => {
    if (!plantId) return;
    let myInterval;
    if (liveButton) {
      myInterval = autoRefreshApiTime(() => getPlantOverviewData(), 1);
    }
    if (!liveButton) {
      setPlantOverviewLoading(false);
    }
    return () => clearInterval(myInterval);
  }, [liveButton, plantId]);

  useEffect(() => {
    if (!search.length) {
      setPlantOverviewData(data);
      return;
    }
    applySearch();
  }, [search]);

  const onSearch = (newSearch: string) => {
    setSearch(newSearch);
  };

  const DownloadData = async (granularity, closeMenu, setOpenSnackbar, setSeverity, setMessage) => {
    setDownloadError(false)
    setDownloadLoading(true);
    const data: any = await dispatch(
      downloadDataGenPage(
        getSelectedString(JSON.parse(portfolioId), "label"),
        getSelectedString(JSON.parse(plantId), "value"),
        convertTime(startDate, "YYYY.MM.DD"),
        convertTime(endDate, "YYYY.MM.DD"),
        granularity
      )
    );
    if (!data) {
      setDownloadError(true)
      setDownloadLoading(false);
      setOpenSnackbar(true);
      setSeverity("error");
      setMessage("Couldn't export the report. Try again later.");
      return
    };

    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");
    link.href = url;
    let fileName = `MonitoringGenerationGraphs_${convertTime(startDate, "DD-MM-YYYY")}_${convertTime(endDate, "DD-MM-YYYY")}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    closeMenu();
    setDownloadLoading(false);
    setOpenSnackbar(true);
    setSeverity("success");
    setMessage("Your report is ready.");
  }

  const getSelectedString = (option, type) => {
    let optionString = "";
    if (!option) return "";
    if (option?.length === 1) {
      option.forEach((element) => {
        optionString = optionString + element[type];
      });
    }
    if (option?.length > 1) {
      option.forEach((element) => {
        optionString = `${element[type]},` + optionString;
      });
      optionString = optionString.substring(0, optionString?.length - 1);
    }
    return optionString;
  };
  const applySearch = () => {
    const dt = data.filter((item) => {
      if (
        item.plantName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        item.locationName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        return item;
      }
    });
    setPlantOverviewData(dt);
  };

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  const getPlantOverviewData = async () => {
    setPOError(false);
    let portfolioString = "";
    let plantString = "";
    portfolioString = getSelectedString(JSON.parse(portfolioId), "label");
    plantString = getSelectedString(JSON.parse(plantId), "value");
    const data: any = await dispatch(
      getGenerationPlantOverviewKPI(
        convertTime(startDate, "YYYY.MM.DD"),
        convertTime(endDate, "YYYY.MM.DD"),
        portfolioString,
        plantString
      )
    );
    if (!data?.values?.length) {
      setPOError(true);
      setPlantOverviewLoading(false);
      return;
    }
    setMultiplePlants(data?.multipleTimeZones);
    let plantTimezone = [];
    data?.values?.forEach((item) => {
      plantTimezone.push(item?.timeZone);
    });
    plantTimezone = removeDuplicates(plantTimezone);
    setUserTimeZoneComp(plantTimezone.every((item) => item === userTimezone));
    setPlantOverviewData(data.values);
    setData(data.values);
    setPlantOverviewLoading(false);
  };

  return {
    search,
    onSearch,
    POError,
    multiplePlants,
    userTimeZoneComp,
    plantOverviewLoading,
    plantOverviewData,
    DownloadData,
    downloadLoading,
    downloadError,
  };
};
