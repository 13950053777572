import { useFormik } from "formik";
import * as yup from "yup";

const phoneRegExp2 = /^[+][0-9.,$;]+$/;

export const GetFormik = (
  intialValues: any,
  validationSchema: any,
  onSubmitHandler: (values: any) => void
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: intialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });
};

export const initializeValidationsObsolete = (
  formSchema: any,
  callback: any
) => {
  let _formData: any = {};
  let _validationSchema: any = {};
  for (let key of Object.keys(formSchema)) {
    _formData[key] = "";
    if (formSchema[key].type === "text") {
      _validationSchema[key] = yup.string();
    } else if (formSchema[key].type === "email") {
      _validationSchema[key] = yup.string().email();
    } else if (formSchema[key].type === "number") {
      _validationSchema[key] = yup.number();
    } else if (formSchema[key].type === "contact") {
      _validationSchema[key] = yup
        .string()
        .min(
          5,
          "Phone number is not valid (example of a valid number: +47888888888)"
        )
        .max(
          15,
          "Phone number is not valid (example of a valid number: +47888888888)"
        )
        .matches(
          phoneRegExp2,
          "Please include country code with + (example of a valid number: +47888888888)"
        );
    } else if (formSchema[key].type === "dropdown") {
      if (formSchema[key]?.isMultiple === true) {
        _validationSchema[key] = yup.array().required("Required");
      } else {
        _validationSchema[key] = yup.string().required("Required").nullable();
      }
    } else if (formSchema[key].type === "autocomplete") {
      _validationSchema[key] = yup.string().required("Required").nullable();
    }
    if (formSchema[key].required && _validationSchema[key]) {
      _validationSchema[key] = _validationSchema[key].required(
        formSchema[key].requiredText
      );
    }
  }

  callback(yup.object().shape({ ..._validationSchema }));
};

export const initializeValuesObsolete = (
  formSchema: any,
  createNewSubsidiary: boolean,
  Subsidiary: any,
  callBack: any
) => {
  let initialValues: any = {};
  for (let key of Object.keys(formSchema)) {
    initialValues[key] = createNewSubsidiary ? "" : Subsidiary[key];
  }

  callBack(initialValues);
};

export const getfilters = (filterBy, search) => {
  let resp = {};
  filterBy?.map((item) => {
    resp[item] = [search];
  });
  return resp;
};
export const searchData = (search, data, filters) => {
  let filterBy = getfilters(filters, search);
  if (search == "") return data;

  return data.filter(function (o) {
    return Object.keys(filterBy).some(function (k) {
      return filterBy[k].some(function (f) {
        return o[k]?.toLowerCase().includes(f?.toLowerCase());
      });
    });
  });
};

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);
