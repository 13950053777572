import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const { sensor_time } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>Time: {sensor_time}</strong>
      </div>
      <div>
        <p
        >AC-Power: {`${payload[0]?.payload["AC-Power"] === null ?
          0
          :
          Math.abs(payload[0]?.payload["AC-Power"]) >= 1000 ?
            (payload[0]?.payload["AC-Power"] / 1000)?.toFixed(1) + " MW"
            :
            Math.abs(payload[0]?.payload["AC-Power"])?.toFixed(1) + " kW"}`}</p>
        Irradiation: {payload[0]?.payload["Irradiation"]?.toFixed(1)} W/m2
      </div>
    </TooltipWrapper>
  );

};
