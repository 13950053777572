import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import { Button } from "../Button";
import { appTheme } from "../../../app/theme";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 350,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(2),
    height: "fit-content",
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    wordWrap: "break-word",
  },

  header: {
    display: "flex",
    alignItems: "center",
  },

  headerTitle: {
    color: "#000000",
    fontWeight: 700,
    marginRight: theme.spacing(4),
  },

  checklist: {
    marginTop: theme.spacing(1),
    textOverflow: "ellipsis",
    borderRadius: 5,
    padding: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(154, 154, 154, 0.2)",
    },
  },

  active: {
    backgroundColor: "rgb(231, 241, 253)",
    "&:hover": {
      backgroundColor: "rgb(213, 232, 255)",
    }
  },
}));

export interface IMenuItem {
  title: string;
  data: any;
}

interface Props {
  menuItems: Array<IMenuItem>;
  active: IMenuItem;
  title: string;
  btnTitle: string;
  onBtnClick: () => void;
  onSelectItem: (item: IMenuItem) => void;
  customButton?: React.ReactNode;
  getCustomMenuItem?: (item: IMenuItem, isActive: boolean) => React.ReactNode;
}

export const MenuWithBtn: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    menuItems,
    active,
    title,
    btnTitle,
    onBtnClick,
    onSelectItem,
    customButton,
    getCustomMenuItem,
  } = props;

  const getItemsList = (items: Array<IMenuItem>, activeItem: IMenuItem) =>
    items?.map((item: IMenuItem) => {
      const isActive = activeItem?.title === item.title;

      if (getCustomMenuItem) return getCustomMenuItem(item, isActive);

      return (
        <div
          className={clsx(classes.checklist, {
            [classes.active]: active?.title === item.title,
          })}
          onClick={() => onSelectItem(item)}
        >
          {item.title}
        </div>
      );
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>{title}</div>
      </div>
      <br />
      {customButton || (
        <Button
          fullWidth
          outlined={false}
          type="button"
          title={btnTitle}
          color={appTheme.main}
          onClick={onBtnClick}
          startIcon={<AddIcon sx={{ color: "white" }} />}
        />
      )}
      <br />
      {getItemsList(menuItems, active)}
      <br />
    </div>
  );
};
