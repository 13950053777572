import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/rootReducers";

export interface IPortfolioState {
  gettingPlants?: boolean;
  plantsFailed?: boolean;
  plantsFailedMessage?: string;
  plantsSuccessful?: boolean;
  plants?: any[];

  gettingPlantKPI?: boolean;
  plantKPIFailed?: boolean;
  plantsKPISuccessful?: boolean;

  gettingPlantCoordinates?: boolean;
  plantCoordinatesFailed?: boolean;
  plantCoordinatesSuccessful?: boolean;

  gettingPlantProfileInfo?: boolean;
  plantProfileInfoFailed?: boolean;
  plantProfileInfoSuccessful?: boolean;

  gettingPerformanceRatio?: boolean;
  performanceRatioFailed?: boolean;
  performanceRatioSuccessful?: boolean;

  getting24hrACPowerandIrrTrend?: boolean;
  ACPowerandIrrTrendFailed?: boolean;
  ACPowerandIrrTrendSuccessful?: boolean;

  gettingSoilingLossInvReport?: boolean;
  SoilingLossInvReportFailed?: boolean;
  SoilingLossInvReportSuccessful?: boolean;

  gettingWaterfallPRLossReport?: boolean;
  WaterfallPRLossReportFailed?: boolean;
  WaterfallPRLossReportSuccessful?: boolean;

  gettingWaterfallEnergyLossReport?: boolean;
  WaterfallEnergyLossReportFailed?: boolean;
  WaterfallEnergyLossReportSuccessful?: boolean;

  gettingInverterHeatmap?: boolean;
  getInverterHeatmapFailed?: boolean;
  getInverterHeatmapSuccessful?: boolean;

  tabValue?: number;
}
const initialState: IPortfolioState = {
  gettingPlants: false,
  plantsFailed: false,
  plantsSuccessful: false,
  plants: [],

  gettingPlantKPI: false,
  plantKPIFailed: false,
  plantsKPISuccessful: false,

  gettingPlantCoordinates: false,
  plantCoordinatesFailed: false,
  plantCoordinatesSuccessful: false,

  gettingPlantProfileInfo: false,
  plantProfileInfoFailed: false,
  plantProfileInfoSuccessful: false,

  gettingPerformanceRatio: false,
  performanceRatioFailed: false,
  performanceRatioSuccessful: false,

  getting24hrACPowerandIrrTrend: false,
  ACPowerandIrrTrendFailed: false,
  ACPowerandIrrTrendSuccessful: false,

  gettingSoilingLossInvReport: false,
  SoilingLossInvReportFailed: false,
  SoilingLossInvReportSuccessful: false,

  gettingWaterfallPRLossReport: false,
  WaterfallPRLossReportFailed: false,
  WaterfallPRLossReportSuccessful: false,

  gettingWaterfallEnergyLossReport: false,
  WaterfallEnergyLossReportFailed: false,
  WaterfallEnergyLossReportSuccessful: false,

  gettingInverterHeatmap: false,
  getInverterHeatmapFailed: false,
  getInverterHeatmapSuccessful: false,

  tabValue: null,
};
// name
// reducer
// actions
// caseReducers
export const plantsSlice = createSlice({
  name: "plants",
  initialState,
  reducers: {
    plantsStart: (state) => {
      return {
        ...state,
        gettingPlants: true,
      };
    },
    plantsSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingPlants: false,
        plantsSuccessful: true,
        plants: payload,
      };
    },
    plantsError: (state, { payload }) => {
      return {
        ...state,
        gettingPlants: false,
        plantsFailed: true,
        plantsFailedMessage: payload,
      };
    },
    // active plant KPI
    plantKPIStart: (state) => {
      return {
        ...state,
        gettingPlantKPI: true,
      };
    },
    plantKPISuccess: (state, { payload }) => {
      return {
        ...state,
        gettingPlantKPI: false,
        plantsKPISuccessful: true,
      };
    },
    plantKPIError: (state, { payload }) => {
      return {
        ...state,
        gettingPlantKPI: false,
        plantKPIFailed: true,
      };
    },
    // plants image coordinates
    plantCoordinatesStart: (state) => {
      return {
        ...state,
        gettingPlantCoordinates: true,
      };
    },
    plantCoordinatesSuccess: (state) => {
      return {
        ...state,
        gettingPlantCoordinates: false,
        plantCoordinatesSuccessful: true,
      };
    },
    plantCoordinatesError: (state) => {
      return {
        ...state,
        gettingPlantCoordinates: false,
        plantCoordinatesFailed: true,
      };
    },
    // plant profile info
    plantProfileInfoStart: (state) => {
      return {
        ...state,
        gettingPlantProfileInfo: true,
      };
    },
    plantProfileInfoSuccess: (state) => {
      return {
        ...state,
        gettingPlantProfileInfo: false,
        plantProfileInfoSuccessful: true,
      };
    },
    plantProfileInfoError: (state) => {
      return {
        ...state,
        gettingPlantProfileInfo: false,
        plantProfileInfoFailed: true,
      };
    },
    // frame performance ratio
    performanceRatioStart: (state) => {
      return {
        ...state,
        gettingPerformanceRatio: true,
      };
    },
    performanceRatioSuccess: (state) => {
      return {
        ...state,
        gettingPerformanceRatio: false,
        performanceRatioSuccessful: true,
      };
    },
    performanceRatioError: (state) => {
      return {
        ...state,
        gettingPerformanceRatio: false,
        performanceRatioFailed: true,
      };
    },
    // ACPowerandIrrTrend
    ACPowerandIrrTrendStart: (state) => {
      return {
        ...state,
        getting24hrACPowerandIrrTrend: true,
      };
    },
    ACPowerandIrrTrendSuccess: (state) => {
      return {
        ...state,
        getting24hrACPowerandIrrTrend: false,
        ACPowerandIrrTrendSuccessful: true,
      };
    },
    ACPowerandIrrTrendError: (state) => {
      return {
        ...state,
        getting24hrACPowerandIrrTrend: false,
        ACPowerandIrrTrendFailed: true,
      };
    },
    SoilingLossInvReportStart: (state) => {
      return {
        ...state,
        gettingSoilingLossInvReport: true,
      };
    },
    SoilingLossInvReportSuccess: (state) => {
      return {
        ...state,
        gettingSoilingLossInvReport: false,
        SoilingLossInvReportSuccessful: true,
      };
    },
    SoilingLossInvReportError: (state) => {
      return {
        ...state,
        gettingSoilingLossInvReport: false,
        SoilingLossInvReportFailed: true,
      };
    },
    WaterfallPRLossReportStart: (state) => {
      return {
        ...state,
        gettingWaterfallPRLossReport: true,
      };
    },
    WaterfallPRLossReportSuccess: (state) => {
      return {
        ...state,
        gettingWaterfallPRLossReport: false,
        WaterfallPRLossReportSuccessful: true,
      };
    },
    WaterfallPRLossReportError: (state) => {
      return {
        ...state,
        gettingWaterfallPRLossReport: false,
        WaterfallPRLossReportFailed: true,
      };
    },

    WaterfallEnergyLossReportStart: (state) => {
      return {
        ...state,
        gettingWaterfallEnergyLossReport: true,
      };
    },
    WaterfallEnergyLossReportSuccess: (state) => {
      return {
        ...state,
        gettingWaterfallEnergyLossReport: false,
        WaterfallEnergyLossReportSuccessful: true,
      };
    },
    WaterfallEnergyLossReportError: (state) => {
      return {
        ...state,
        gettingWaterfallEnergyLossReport: false,
        WaterfallEnergyLossReportFailed: true,
      };
    },
    // InverterHeatmap
    InverterHeatmapStart: (state) => {
      return {
        ...state,
        gettingInverterHeatmap: true,
      };
    },
    InverterHeatmapSuccess: (state) => {
      return {
        ...state,
        gettingInverterHeatmap: false,
        getInverterHeatmapSuccessful: true,
      };
    },
    InverterHeatmapError: (state) => {
      return {
        ...state,
        gettingInverterHeatmap: false,
        getInverterHeatmapFailed: true,
      };
    },

    getTabValue: (state, { payload }) => {
      return {
        ...state,
        tabValue: payload,
      };
    },
  },
});
// plants actions
export const {
  plantsStart,
  plantsSuccess,
  plantsError,
  plantKPIStart,
  plantKPISuccess,
  plantKPIError,
  plantCoordinatesStart,
  plantCoordinatesSuccess,
  plantCoordinatesError,
  plantProfileInfoStart,
  plantProfileInfoSuccess,
  plantProfileInfoError,
  performanceRatioStart,
  performanceRatioSuccess,
  performanceRatioError,
  ACPowerandIrrTrendStart,
  ACPowerandIrrTrendSuccess,
  ACPowerandIrrTrendError,
  SoilingLossInvReportStart,
  SoilingLossInvReportSuccess,
  SoilingLossInvReportError,
  WaterfallPRLossReportStart,
  WaterfallPRLossReportSuccess,
  WaterfallPRLossReportError,
  WaterfallEnergyLossReportStart,
  WaterfallEnergyLossReportSuccess,
  WaterfallEnergyLossReportError,
  InverterHeatmapStart,
  InverterHeatmapSuccess,
  InverterHeatmapError,
  getTabValue,
} = plantsSlice.actions;
// plants state
export const plantsSelector = (state: RootState) => state.Sidem.portfolio.plants;
// plants reducer
export const plantsReducer = plantsSlice.reducer;
