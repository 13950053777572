import { useEffect, useMemo, useRef, useState } from "react";
import { useGetMostRecentAlertsByPlantQuery, useGetPlantImageCoordinatesQuery } from "../../../../features/Sidem/shared/sidemSharedApi";
import { PanelItem } from "../../../../features/Sidem/shared/sidemSharedApi.types";

interface Props {
  plantId: string;
  imageService: any;
}

export const usePanels = ({
  imageService,
  plantId,
}: Props) => {
  const [image, setImage] = useState(null);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const imageRef = useRef(null);
  const [imageX, imageY] = [0, 0];
  const [imageSize, setImageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  }); // required as state because Canvas is dependent on this width
  const [imageScaleRatio, setImageScaleRatio] = useState(
    image?.naturalWidth > 1480 ? 1480 / image?.naturalWidth : 1
  );

  const { data: plantImageCoordinatesData } = useGetPlantImageCoordinatesQuery(
    { plantId },
    { skip: !plantId }
  );

  const { data: alertsData } = useGetMostRecentAlertsByPlantQuery(
    { plantId, top: 10 },
    { skip: !plantId }
  );

  const imageUrl = useMemo(() => {
    return imageService(plantImageCoordinatesData?.[0]?.plantLayout) || '';
  }, [plantImageCoordinatesData])

  const panelsAndAlerts = useMemo(() => {
    const panelMap: {[key: string]: PanelItem} = (plantImageCoordinatesData || []).reduce(
      (res, cur) => {

        res[cur.nodeId] = {
          key: cur.nodeId,
          centerCoords: cur.centerCoords,
          equipCoords: cur.equipCoords,
          errors: [],
        };

        return res;
      },
      {}
    );

    const activeAlerts = alertsData?.filter((item) => item.isActive);

    activeAlerts?.forEach((item) => {
      const panel = panelMap[item.nodeId];

      if (panel?.errors) {
        panel.errors.push(item);
      }
    });

    return {
      panels: Object.values(panelMap),
      activeAlerts,
      alerts: alertsData
    }
  }, [alertsData, plantImageCoordinatesData])

  const loadImage = () => {
    const img = new window.Image();
    img.src = imageUrl;
    imageRef.current = img;
    imageRef.current.addEventListener("load", handleLoad);
  };

  const handleLoad = () => {
    setImage(imageRef.current);
  };

  useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current) {
        setSize(image, imageX);
        // setImageIsLoaded(true);
        imageRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  useEffect(() => {
    loadImage();
    // setImageIsLoaded(true);
  }, [imageUrl]);

  useEffect(() => {
    setSize(image, imageX);
  }, [image, imageX]);

  const setSize = (image, offset) => {
    let scaleRatio =
      image?.naturalWidth > 1480 ? 1480 / image?.naturalWidth : 1;
    const width = image?.naturalWidth * scaleRatio + offset * 2 || 0;
    const height = image?.naturalHeight * scaleRatio + offset * 2 || 0;
    const newPanels = panelsAndAlerts.panels;

    if (scaleRatio !== 1 && newPanels) {
      newPanels?.forEach((newPanel, index) => {
        newPanels[index].centerCoords = newPanel?.centerCoords?.map((x) =>
          Math.round(x * scaleRatio)
        );
        newPanels[index].equipCoords = newPanel?.equipCoords?.map((x) =>
          Math.round(x * scaleRatio)
        );
      });
    }

    setImageScaleRatio(scaleRatio);
    setImageSize({ width, height });
    setImageIsLoaded(true);
  };

  return {
    imageX,
    imageY,
    image,
    imageIsLoaded,
    activeAlerts: panelsAndAlerts.activeAlerts,
    imageSize,
    panels: panelsAndAlerts.panels,
  };
};

export type UsePanelsData = ReturnType<typeof usePanels>;