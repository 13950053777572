import { useBatteryPowerWidget } from "../../useBatteryPower";
import { DataWithUnitWidget } from "../DataWithUnitWidget";

export const BatteryPowerWidget = () => {
  const {
    isLoading: isBatteryPowerLoading,
    isFetching: isBatteryPowerFetching,
    data: batteryPowerData,
    isError: isBatteryPowerError,
  } = useBatteryPowerWidget();

  return (
    <DataWithUnitWidget
      title={"Power"}
      options={batteryPowerData}
      optionsCount={4}
      isLoading={isBatteryPowerLoading}
    />
  );
};
