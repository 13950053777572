import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  roleId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  teamRoles: any;
  deleteTeamRole: (param: any, callback: any) => void;
  addPermissions: (param: any, callback: any) => void;
  deletePermissions: (param: any, callback: any) => void;
}

export const UseRoleSave = ({ roleId }: Props): Data => {
  const [teamRoles, setTeamRoles] = useState(null);

  useEffect(() => {
    if (!roleId) return;
    getTeamRoles();
  }, [roleId])

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";

    if (id && id !== "new") {
      params.plantId = id;
      requestType = "PUT";
    }

    await fetchAsync(`/TeamRole`, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };


  const addPermissions = async (params: any, callback: any) => {
    let requestType = "POST";

    await fetchAsync(`/Permission/add`, requestType, params)
      .then((data: any) => {
        getTeamRoles();
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };

  const deletePermissions = async (params: any, callback: any) => {
    let requestType = "POST";

    await fetchAsync(`/Permission/remove`, requestType, params)
      .then((data: any) => {
        getTeamRoles();
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };


  const getTeamRoles = async () => {
    if (roleId === 'new') return;
    await fetchAsync(`/TeamRole?id=${roleId}`, "GET")
      .then((data: any) => {
        if (data) {

          setTeamRoles(data);
        }
      })
      .catch((e) => { console.error(e); });
  };

  const deleteTeamRole = async (params, callback: any) => {
    await fetchAsync(`/TeamRole/removeteam`, "Post", params)
      .then((data: any) => {
        getTeamRoles();
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };

  return {
    upsert,
    teamRoles,
    deleteTeamRole,
    addPermissions,
    deletePermissions
  };
};
