import { useState } from "react";
import { useDispatch } from "react-redux";
import { deletePermissionFromRoleRequest, getPermissionsByModuleNameRequest, getPermissionsForRoleRequest, getRoleInfoRequest, insertPermissionToRoleRequest } from "../../../../features/Onboarding/roles/permissionActions";
import { } from "./interfaces";

interface Data {
  getRoleInfo: (roleId: string, callback: any) => void;
  addPermission: (permissionId: string, roleId: string, callback: any) => void;
  removePermission: (permissionsToRoleId: string, callback: any) => void;
  getPermissionsForRoleAndModule: (roleId: string, moduleName: string, setRolePermissions: (perm) => void) => void;
  getPermissionsByModuleName: (moduleName: string, setModulePermissions: (perm) => void) => void;
}

export const useRoleEdit = (): Data => {
  const [loading, setloading] = useState(null);
  const dispatch = useDispatch();

  const getRoleInfo = async (roleId: string, setRoleName: any) => {
    setloading(true);
    const resp = await dispatch(getRoleInfoRequest(roleId));
    setRoleName(resp);
    setloading(false);
  };

  const addPermission = async (permissionId: string, roleId: string, callback: any) => {
    let body = {
      PermissionId: permissionId,
      RoleId: roleId
    };
    const resp = await dispatch(insertPermissionToRoleRequest(body));
    callback(resp);
  };

  const removePermission = async (permissionsToRoleId: string, callback: any) => {
    let body = {
      "permissionsToRoleId": permissionsToRoleId,
    };
    const resp = await dispatch(deletePermissionFromRoleRequest(body));
    callback(resp);
  };

  const getPermissionsForRoleAndModule = async (roleId: string, moduleName: string, setRolePermissions: any) => {
    setloading(true);
    const resp = await dispatch(getPermissionsForRoleRequest(roleId, moduleName));
    setRolePermissions(resp);
    setloading(false);
  };

  const getPermissionsByModuleName = async (moduleName: string, setModulePersmissions: any) => {
    setloading(true);
    const resp = await dispatch(getPermissionsByModuleNameRequest(moduleName));
    setModulePersmissions(resp);
    setloading(false);
  };

  return {
    getRoleInfo,
    addPermission,
    removePermission,
    getPermissionsForRoleAndModule,
    getPermissionsByModuleName,
  };
};
