import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePickerWithArrows } from "../../../shared/components";
import { getTabValue, plantsSelector } from "../../../features/Sidem/portfolios/plants/plantsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsync } from "../../../shared/utils/apiService";
import { convertTime } from "../../../shared/utils/utils";


const definition = {
  Budget:
    "It shows the expected generation for the day. This is taken from the PVSyst",
  Temperature:
    "Temperature loss is the loss due to the higher temperature than the PV module STC temperature",
  Irradiation:
    "Loss due to the lower irradiation than the expected irradiation",
  Tripping: "Loss due to the equipment breakdown is the tripping loss",
  "Grid outage": "Loss due to the grid outages during generation hours",
  "Cloud cover": `When solar radiation fall at pyranometer and PV area covered by cloud, Then It shows that lower generation at higher irradiation. This gap of irradiation 
  and generation is calculated as generation loss due to cloud cover. This is calculated by SIDEM algorithm`,
  Clipping: `When inverter AC power reach at its maximum rating, Its power not goes higher than the threshold with radiation goes up, 
  this is known as clipping. And generation loss due to this is call clipping loss. This is calculated by SIDEM algorithm`,
  Other: "This is sum of all unaccountable losses",
  Actual: "This is actual generation of the plant",
  "Real Time PR": "It shows the instantaneous PR of the plant",
  "Today`s PR": "It shows the PR for today",
  "Last 24 hours PR": "It shows the PR for last 24 hours",
  "Last 7 days PR": "It shows the PR for last 7 days",
  "Last 30 days PR": "It shows the PR for last 30 days",
  "Inverter Specific Energy Comparison":
    "Inverter Specific power is the ratio of inverter AC power and connected DC capacity at same inverter",
  "Specific CB current":
    "Specific current is the ratio of current and DC capacity",
  "Hourly PR and Irradiation trend %":
    "It showing the hourly PR, Irradiation, Module temperature and Ambient Temperature for Last 7 days ",
  "Pyranometer comparison":
    "It showing the actual irradiation for all pynometers",
  Auxiliary:
    "Loss due to the higher Auxiliary consumption as compare to budget value",
  Transformer:
    "Loss due to the higher Transformer & AC Ohmic loss as compare to budget value",
  Transmission: "",
  Soiling: "Generation Loss due soiling on PV modules",
};
interface WeatherItem {
  date: string;
  description: string;
  icon: string;
  id: number;
}

interface ProfileData {
  acCapacity: number;
  alerts: null;
  availGuaranteed: number;
  connectedDC: number;
  latitude: number;
  location: string;
  longitude: number;
  plantId: string; //number;
  plantName: string;
  prGuaranteed: number;
  todaysDate: string;
  weathers: Array<WeatherItem>;
  actualPR: number;
  invertersAv: number;
  invertersDown: number;
  setValue: (val: number) => void;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const usePlants = (plantId) => {
  const navigate = useNavigate();

  const [date, setDate] = useState(
    convertTime(new Date().toISOString(), "yyyy-MM-DD")
  );
  const [weatherData, setWeatherData] = useState([]);
  const [plantActiveKPI, setPlantActiveKPI] = useState([]);
  const [profileData, setProfileData] = useState({} as ProfileData);
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();

  const { tabValue } = useSelector(plantsSelector);

  const handleInfoClick = () => {
    navigate(`${window.location.hash.replace("#", "")}/information`);
  };


  const getDateComponent = () => {
    return (
      <DatePickerWithArrows
        date={date}
        setDate={setDate}
      />
    );
  };

  React.useEffect(() => {
    if (value === null) {
      if (tabValue === null) {
        setValue(0)
      }
      return;
    }
    dispatch(getTabValue(value))
  }, [value])

  const getDateReport = (newDate: any) => {
    fetchAsync(
      `SkyfriSidemApi/v1/GetWeatherReport?Date=${newDate}&plantId=${plantId}`,
      "GET"
    )
      .then((data: any) => {
        if (!data) return;

        const validData = data?.map((item) => ({
          ...item,
          dateTime: moment(item?.dateTime).format("DD-MM-YYYY"),
        }));

        const sortedData =
          validData ||
          validData.sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));
        setWeatherData(sortedData);
      })
      .catch((e) => setWeatherData([]));
  };

  const GetPlantActiveKPI = () => {
    fetchAsync(`SkyfriSidemApi/v1/GetPlantActiveKPI?plantId=${plantId}`, "GET")
      .then((data) => {
        if (!data) return;
        setPlantActiveKPI(data);
      })
      .catch((e) => console.log("error"));
  };
  return {
    handleInfoClick,
    getDateComponent,
    date,
    getDateReport,
    GetPlantActiveKPI,
    setProfileData,
    profileData,
    plantActiveKPI,
    weatherData,
    definition,
    setValue,
    tabValue,
  };
};
