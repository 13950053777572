import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({ active, payload, equipment }: { active?: boolean, payload?: Array<any>, equipment: string }) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const { sensor_time, values = [] } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>
          Time: {sensor_time}
        </strong>
      </div>

      <div>
        <strong>
          Lowest performing {equipment}
        </strong>
      </div>
      {values?.slice().sort((a, b) => b.value - a.value).map(item => (
        <div>{equipment} {item.key}: {item.value?.toFixed(1)} A/kWp</div>
      ))}
    </TooltipWrapper>
  )
};
