import { useState, useEffect } from "react";
import { useSharedFormState } from "../../../shared/utils/sharedStates";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Alarm {
  alarmCategoryCode: number;
  alarmCategoryName: string;
  alarmLevel: number;
  createdOn: string;
  description: string;
  displayName: string;
  isActive: boolean;
  modifiedOn: string;
  nodeId: string;
  plantId: string; //number;
}

const minute = 1000 * 60;
const requestUrl = `/SkyfriSidemApi/v1/GetMostRecentAlerts?top=10`;

export const useNotificationSidebar = () => {
  const [numberOfAlarms, setNumberOfAlarms] = useState(0);
  const [resolved, setResolved] = useState([] as Array<Alarm>);
  const [incidents, setIncidents] = useState([] as Array<Alarm>);
  const [alarms, setAlarms] = useState([]);
  const { automaticRefresh } = useSharedFormState();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const getAlarms = (interval?: number) => {
    return;
    setLoading(true);
    fetchAsync(requestUrl, "GET")
      .then((data) => {
        setLoading(false);
        const alarmsNum = data?.filter((item) => item.isActive)?.length;
        setNumberOfAlarms(alarmsNum);
        setAlarms(data);
      })
      .catch((e) => console.error(e));

    if (interval && automaticRefresh) {
      return setInterval(() => {
        getAlarms();
      }, interval);
    }
  };

  useEffect(() => {
    if (!alarms?.length) return;

    const newResolved = [];
    const newIncidents = [];

    alarms?.forEach((alarm: Alarm) => {
      const arr = alarm?.isActive ? newIncidents : newResolved;
      arr?.push(alarm);
    });

    setResolved(newResolved);
    setIncidents(newIncidents);
  }, [alarms]);

  // useEffect(() => {
  //   setLoading(true);
  //   const timeoutId = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  //   const interval = getAlarms(minute * 5);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     clearInterval(interval);
  //   }
  // }, []);

  return {
    loading,
    setLoading,
    alarms,
    setAlarms,
    numberOfAlarms,
    anchorEl,
    open,
    openNotifications,
    setAnchorEl,
    setOpen,
    setOpenNotifications,
  };
};
