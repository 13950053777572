import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@material-ui/core";
import WarningMessage from "../WarningMessage";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  loading: boolean;
  height?: number | string;
  error?: string;
  errorTextSize?: number;
}

export const Loader: React.FC<Props> = ({
  loading,
  height = 30,
  error,
  errorTextSize = 18,
}) => {
  const classes = useStyles();
  const show = loading || error?.length;

  return !show ? null : (
    <div className={classes.wrapper} style={{ height }}>
      {loading && !error ? <CircularProgress /> : null}

      {error?.length ? (
        <WarningMessage label={error} size={errorTextSize} />
      ) : null}
    </div>
  );
};
