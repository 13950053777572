import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ReactComponent as SELECTEDTAB } from "../../../../../../../../shared/icons/analytics/SELECTEDTAB.svg";
import { useStyles } from "./styles";

interface GranularityDropdownProps {
  selectedGranularity: string;
  setSelectedGranularity: (granularity: string) => void;
}

export const GranularityDropdown = (props: GranularityDropdownProps) => {
  const { setSelectedGranularity, selectedGranularity } = props;
  const [showOptions, setShowOptions] = useState(false);
  const classes = useStyles();

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleGranularitySelect = (granularity) => {
    setSelectedGranularity(granularity);
    setShowOptions(false);
  };
  return (
    <React.Fragment>
      <div
        onClick={handleToggleOptions}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <div
          style={{ fontWeight: 500, fontSize: "14px" }}
        >{`Granularity: ${selectedGranularity}`}</div>
        <IconButton>
          <ExpandMore />
        </IconButton>
      </div>
      {showOptions && (
        <div className={classes.dropdown}>
          <div
            style={{ marginTop: "10px" }}
            className={classes.options}
            onClick={() => handleGranularitySelect("15 min")}
          >
            <span
              style={{ width: "7px", display: "flex", alignItems: "center" }}
            >
              {selectedGranularity === "15 min" ? <SELECTEDTAB /> : null}
            </span>
            <span style={{ marginLeft: "1px" }}>15 min</span>
          </div>
          <div
            style={{}}
            className={classes.options}
            onClick={() => handleGranularitySelect("Hourly")}
          >
            <span
              style={{ width: "7px", display: "flex", alignItems: "center" }}
            >
              {selectedGranularity === "Hourly" ? <SELECTEDTAB /> : null}
            </span>
            <span style={{ marginLeft: "1px" }}>Hourly</span>
          </div>
          <div
            style={{ marginBottom: "10px" }}
            className={classes.options}
            onClick={() => handleGranularitySelect("Daily")}
          >
            <span
              style={{ width: "7px", display: "flex", alignItems: "center" }}
            >
              {selectedGranularity === "Daily" ? <SELECTEDTAB /> : null}
            </span>

            <span style={{ marginLeft: "1px" }}>Daily</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
