import { axGet } from "../../../store/axiosClient";
import * as InverterPerformanceSlice from "./inverterPerformanceSlice";
import { getBearerToken } from "../../../store/authToken";

const sidemApiUrl = `SkyfriSidemApi/v1`

export function getSelectedInverterKPI(fromDate, toDate, plantId, blockId, inverterId) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppInverterKPI?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}
export function getPeriodSelectedInverterKPI(fromDate, toDate, plantId, blockId, inverterId) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppInvKpiPeriod?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}

export function getSelectedInverterRawData(fromDate, toDate, plantId, blockId, inverterId, binSensortime) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppInvPageRawData?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}&binSensortime=${binSensortime}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}

export function getInverterPageSpecificEnergy(fromDate, toDate, plantId, sortType, sortBy) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppInvPageSpecificEnergy?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&sortCriteria=${sortType}&SortDirection=${sortBy}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}

export function getHeaderTextData(plantId) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppInvPageStatusBar?plantId=${plantId}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}


export const setGlobalDateAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(InverterPerformanceSlice.setGlobalDate(payload));
        return payload;
    };
};
export const setglobalSelectedInverterAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(InverterPerformanceSlice.setglobalSelectedInverter(payload));
        return payload;
    };
};
export const setglobalPlantIdAction = (payload: string | number) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(InverterPerformanceSlice.setglobalPlantId(payload));
        return payload;
    };
};

export const setDateComparisonAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(InverterPerformanceSlice.dateComparison(payload));
        return payload;
    };
};


