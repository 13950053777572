import React from "react";
import { usePRIrradiationWidget } from "./usePRAndIrradiationWidget";
import { CustomTooltip } from "./CustomTooltip.component";
import { makeStyles } from "@material-ui/core/styles";
import { MultilineChartWidget } from "../../../../components/MultilineChartWidget";
import { lines, yAxises } from "./data";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}
const useStyles = makeStyles((theme) => ({
  Widget: {
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));
export const IrradiationTrendWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
}) => {
  const { data, loading, error } = usePRIrradiationWidget({
    plantId,
    date,
  });
  const styles = useStyles();
  return (
    <MultilineChartWidget
      title="Last 7 Days Hourly PR and Irradiation trend"
      height={600}
      size={6}
      lines={lines}
      data={data}
      loading={loading}
      error={error}
      showYAxises={false}
      yAxises={yAxises}
      tooltipContent={<CustomTooltip />}
      definition={definition}
      styles={styles.Widget}
    />
  );
};
