import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GranularityDropdown } from "./components/GranularityDropdown/GranularityDropdown.component";
import { makeStyles } from "@material-ui/core/styles";
import {
  date_values,
  actual_production,
  production_forecast_minimum,
  production_forecast_maximum,
  hourly_dates,
  hourly_actual_production,
  hourly_production_forecast_minimum,
  hourly_production_forecast_maximum,
  daily_dates,
  daily_actual_production,
  daily_production_forecast_minimum,
  daily_production_forecast_maximum,
} from "./mock";
import { ReactComponent as HOURGLASS } from "../../../../../../shared/icons/analytics/HOURGLASS.svg";

const useStyles = makeStyles({
  customTableCell: {
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px !important",
  },
  customTableRow: {
    backgroundColor: "#E8ECF2",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    borderRight: "1px solid #CACFD7",
  },
});

export const ForecastTable: React.FC<any> = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedGranularity, setSelectedGranularity] = useState("15 min");
  const classes = useStyles();
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const renderTableRows = () => {
    if (selectedGranularity === "15 min") {
      return date_values.map((date, index) => (
        <TableRow
          key={index}
          sx={{
            borderBottom: "1px solid #CACFD7 !important",
          }}
        >
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {date}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {actual_production[index]}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {production_forecast_minimum[index]}
          </TableCell>
          <TableCell align="center" className={classes.customTableCell}>
            {production_forecast_maximum[index]}
          </TableCell>
        </TableRow>
      ));
    } else if (selectedGranularity === "Hourly") {
      return hourly_dates.map((date, index) => (
        <TableRow key={index} sx={{ borderBottom: "1px solid #CACFD7" }}>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {date}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {hourly_actual_production[index]}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {hourly_production_forecast_minimum[index]}
          </TableCell>
          <TableCell align="center" className={classes.customTableCell}>
            {hourly_production_forecast_maximum[index]}
          </TableCell>
        </TableRow>
      ));
    } else if (selectedGranularity === "Daily") {
      return daily_dates.map((date, index) => (
        <TableRow key={index} sx={{ borderBottom: "1px solid #CACFD7" }}>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {date}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {daily_actual_production[index] && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span
                  style={{
                    marginTop: "1px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <HOURGLASS />
                  {showTooltip && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#FFFFFF",
                        color: "#0C1E33",
                        padding: "5px",
                        borderRadius: "3px",
                        zIndex: 1000,
                        width: "400px",
                        boxShadow: "4px 6px 24px rgba(12, 30, 51, 0.2)",
                      }}
                    >
                      This value is preliminary as the day is still ongoing
                    </div>
                  )}
                </span>
                <span style={{ marginLeft: "3px" }}>
                  {daily_actual_production[index]}
                </span>
              </div>
            )}
          </TableCell>
          <TableCell
            align="center"
            className={classes.customTableCell}
            sx={{ borderRight: "1px solid #CACFD7" }}
          >
            {daily_production_forecast_minimum[index]}
          </TableCell>
          <TableCell align="center" className={classes.customTableCell}>
            {daily_production_forecast_maximum[index]}
          </TableCell>
        </TableRow>
      ));
    }
    return null;
  };
  return (
    <TableContainer
      sx={{
        maxHeight: "calc(100vh - 250px)",
        border: "1px solid #CACFD7",
        borderRadius: "8px",
        width: "100%",
        marginTop: "20px !important",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "60px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            padding: "24px",
            fontFamily: "Inter",
            fontSize: "16px",
            lineHeight: "17.6px",
          }}
        >
          Table view
        </div>
        <div style={{ padding: "12px" }}>
          <GranularityDropdown
            selectedGranularity={selectedGranularity}
            setSelectedGranularity={setSelectedGranularity}
          />
        </div>
      </div>
      <Table size="small" stickyHeader>
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            "& .MuiTableCell-root": {
              backgroundColor: " #E8ECF2",
              color: "black",
            },
          }}
        >
          <TableRow sx={{ borderTop: "1px solid #CACFD7 " }}>
            <TableCell
              align="center"
              colSpan={1}
              className={classes.customTableRow}
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              colSpan={1}
              className={classes.customTableRow}
            >
              Actual Production, kWh
            </TableCell>
            <TableCell
              align="center"
              colSpan={2}
              className={classes.customTableRow}
            >
              Production forecast, kWh
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="right"
              className={classes.customTableRow}
              sx={{ borderRight: "none" }}
            ></TableCell>
            <TableCell
              align="right"
              className={classes.customTableRow}
            ></TableCell>
            <TableCell align="center" className={classes.customTableRow}>
              Min
            </TableCell>
            <TableCell align="center" className={classes.customTableRow}>
              Max
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
