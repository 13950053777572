import React from "react";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../../app/theme";

export const useStyles = makeStyles((theme) => ({
  icons: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
  },

  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  activeIcon: {
    backgroundColor: "lightgray",
  },
}));

interface WeatherItem {
  dateTime: string;
  description: string;
  icon: string;
  id: number;
}

interface Props {
  weathers: Array<WeatherItem>;
  size?: number;
  selectedDate?: any;
  notHighlighted?: boolean;
}

export const WeathersList: React.FC<Props> = (props) => {
  const classes = useStyles();

  const defaultDate = moment(new Date()).format("DD-MM-YYYY");
  const {
    weathers = [],
    size = 50,
    selectedDate = defaultDate,
    notHighlighted,
  } = props;

  return (
    <div className={classes.icons}>
      {!weathers?.length ? "No record" : null}
      {weathers
        ?.sort((a, b) =>
          moment(a.dateTime, "DD-MM-YYYY") > moment(b.dateTime, "DD-MM-YYYY")
            ? 1
            : -1
        )
        ?.map((item, idx) => {
          return (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: appTheme.main,
                    color: "white",
                    "& .MuiTooltip-arrow": {
                      color: "common.white",
                    },
                  },
                },
              }}
              key={idx}
              title={`${item?.dateTime.split("T")[0]} \n ${item?.description}`}
            >
              <img
                style={{ width: size, height: size, borderRadius: size }}
                src={`/weather-images/${item?.icon}.svg`}
                alt=""
                className={clsx(classes.icon, {
                  [classes.activeIcon]:
                    item.dateTime?.slice(0, 2) === selectedDate.slice(0, 2) &&
                    !notHighlighted,
                })}
              />
            </Tooltip>
          );
        })}
    </div>
  );
};
