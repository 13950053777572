import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertTime } from "../../../../../shared/utils/utils";
import {
  getInverterPageSpecificEnergy,
  setglobalSelectedInverterAction,
} from "../../../../../features/Monitoring/inverterPerformance/inverterPerformanceActions";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { inverterPerformanceSelector } from "../../../../../features/Monitoring/inverterPerformance/inverterPerformanceSlice";


interface Data {
  sortType: string;
  inverterSpecificEnergyData: any[];
  xLabels: Array<string>;
  yLabels: Array<string>;
  popUpData: Array<Array<object>>;
  inverterSpecificEnergyLoading: boolean;
  onChangeSelectedInverter: any;
  onChangeSortBy: any;
  sortBy: string;
  onSortType: any;
  title: string;
  globalSelectedInverter: any;
}

export const useInverterSpecificEnergyWidget = (): Data => {
  const dispatch = useDispatch();
  const [url] = useSearchParams()
  const plantId = url.get("plantId")
  const startDate = url.get("startDate")
  const endDate = url.get("endDate")
  const { selectedInverterI } = useSelector(monitoringDashboardSelector);
  const { globalSelectedInverter } = useSelector(inverterPerformanceSelector);

  const [sortType, setSortType] = useState("Pr");
  const [sortBy, setSortBy] = useState("Asc");
  const [inverterSpecificEnergyData, setInverterSpecificEnergyData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [popUpData, setPopUpData] = useState([]);
  const [tempPopUpData, setTempPopUpData] = useState([]);
  const [inverterSpecificEnergyLoading, setLoading] = useState(true);
  const [title, setTitle] = useState("Inverter PR");


  useEffect(() => {
    onChangeSelectedInverter(selectedInverterI);
  }, [selectedInverterI]);
  const getSortType = (type) => {
    let valToReturn = "";
    switch (type) {
      case "InverterId":
        valToReturn = "actualPr";
        setTitle("Inverter performance");
        break;
      case "Pr":
        valToReturn = "actualPr";
        setTitle("Inverter PR");
        break;
      case "Yield":
        valToReturn = "specificEnergy";
        setTitle("Inverter specific energy");
        break;
      case "Downtime":
        valToReturn = "downtime";
        setTitle("Inverter downtime");
        break;
      default:
        break;
    }
    return valToReturn;
  };
  const checkScreenSize = () => {
    const screenSize = window.screen.width;
    if (screenSize < 1100 && screenSize > 900) return 8;
    if (screenSize < 900 && screenSize > 600) return 10;
    if (screenSize < 600) return 5;
    else return 20;
  };
  const transformData = async () => {
    setLoading(true);
    const data: any = await dispatch(
      getInverterPageSpecificEnergy(
        convertTime(startDate, "YYYY.MM.DD"),
        convertTime(endDate, "YYYY.MM.DD"),
        plantId,
        sortType,
        sortBy
      )
    );

    checkScreenSize();

    const tempData = data?.map(
      (item) => Math.round(item[getSortType(sortType)] * 100) / 100
    );
    const tempLabels = data?.map((item) => "Inverter " + item?.blockId + "." + item?.inverterId);

    const dataArr = [];
    while (tempData?.length) {
      dataArr.push(tempData?.splice(0, checkScreenSize()));
    }
    let labelArr = [];
    while (tempLabels?.length) {
      labelArr.push(tempLabels?.splice(0, checkScreenSize()));
    }
    let popUpArr = [];

    while (data?.length) {
      popUpArr.push(data?.splice(0, checkScreenSize()));
    }

    setInverterSpecificEnergyData(dataArr);
    setYLabels([""]);
    setXLabels(labelArr);
    if (!globalSelectedInverter) {
      setPopUpData(popUpArr);
    }
    setTempPopUpData(popUpArr);
    setLoading(false);
  };


  const onSelectedInverter = () => {
    const popUpArr = tempPopUpData?.map((item) => {
      return item?.map((element) => {
        if (
          globalSelectedInverter?.blockId === element?.blockId &&
          globalSelectedInverter?.inverterId === element?.inverterId
        ) {
          return {
            ...element,
            invStatus: "Selected",
            selected: true,
          };
        } else return { ...element, selected: false };
      });
    });
    setPopUpData(popUpArr);
  };

  const onChangeSelectedInverter = (item) => {
    dispatch(setglobalSelectedInverterAction(item));
  };
  const onChangeSortBy = () => {
    if (sortBy === "Asc") setSortBy("Desc");
    else setSortBy("Asc");
  };
  const onSortType = (item) => {
    setSortType(item);
  };

  useEffect(() => {
    if (!startDate || !endDate || !plantId || !sortType) return;
    transformData();
  }, [startDate, endDate, plantId, sortType, sortBy]);

  useEffect(() => {
    if (!startDate || !endDate || !plantId) return;
    onSelectedInverter();
  }, [globalSelectedInverter, tempPopUpData]);

  return {
    sortType,
    inverterSpecificEnergyData,
    xLabels,
    yLabels,
    popUpData,
    inverterSpecificEnergyLoading,
    onChangeSelectedInverter,
    globalSelectedInverter,
    onChangeSortBy,
    sortBy,
    onSortType,
    title,
  };
};
