import { label } from "../../../sidem/pages/PlantPage/features/RealtimePerformanceWidget/useRealtimePerformance"

export const overViewScreenOptions = [{
    label: "Version 1.0",
    value: "1",
    subtitle: "Production, Import & Export"
},
{
    label: "Version 2.0",
    value: "2",
    subtitle: "Production only"

},
{
    label: "Version 3.0",
    value: "3",
    subtitle: "Production only in stacked bar chart"
},
{
    label: "Version 4.0",
    value: "4",
    subtitle: "(US) Production, Import & Export, Self-sustainability, CO₂ emissions, houses powered, trees planted and miles driven"
},
{
    label: "Version 5.0",
    value: "5",
    subtitle: "(US) Production, CO₂ emissions, houses powered, trees planted and miles driven"
},
]

export const energyScreenOptions = [
    {
        label: "Version 1.0",
        value: "1",
        subtitle: 'Production, Import & Export'
    },
    {
        label: "Version 2.0",
        value: "2",
        subtitle: 'Production only'
    },
    {
        label: "Version 3.0",
        value: "3",
        subtitle: '(US) Production, Import & Export, Self-sustainability, CO₂ emissions, houses powered, trees planted and miles driven'
    },
    {
        label: "Version 4.0",
        value: "4",
        subtitle: '(US) Production, CO₂ emissions, houses powered, trees planted and miles driven'
    }
]

export const energyScreenOptionsParent = [
    {
        label: "Multiple versions",
        value: "Multiple versions",
        disabled: true
    },
    {
        label: "Version 1.0",
        value: "1",
        subtitle: 'Production, Import & Export'
    },
    {
        label: "Version 2.0",
        value: "2",
        subtitle: 'Production only'
    },
    {
        label: "Version 3.0",
        value: "3",
        subtitle: '(US) Production, Import & Export, Self-sustainability, CO₂ emissions, houses powered, trees planted and miles driven'
    },
    {
        label: "Version 4.0",
        value: "4",
        subtitle: '(US) Production, CO₂ emissions, houses powered, trees planted and miles driven'
    }
]


export const powerIrrScreenOptions = [
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const powerIrrScreenOptionsParent = [
    {
        label: "Multiple versions",
        value: "Multiple versions",
        disabled: true
    },
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const siteOverviewScreenOptionsParent = [
    {
        label: "Multiple versions",
        value: "Multiple versions",
        disabled: true
    },
    {
        label: "Version 1.0",
        value: "1"
    },
]


export const siteOverviewScreenOptions = [
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const siteMonthlyScreenOptions = [
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const siteMonthlyScreenOptionsParent = [
    {
        label: "Multiple versions",
        value: "Multiple versions",
        disabled: true
    },
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const siteYearlyScreenOptions = [
    {
        label: "Version 1.0",
        value: "1"
    },
]

export const siteYearlyScreenOptionsParent = [
    {
        label: "Multiple versions",
        value: "Multiple versions",
        disabled: true
    },
    {
        label: "Version 1.0",
        value: "1"
    },
]