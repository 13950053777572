import { IconButton } from "@material-ui/core";
import { useStyles } from "./styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface TooltipProps { 
    block: string;
    inv: string;
    soilingLoss: number;
}

export const InverterSoilingTooltip = ({ block, inv, soilingLoss }: TooltipProps) => {
    const classes = useStyles();
  
    return (
      <div>
        <div className={classes.tooltipHeader}>
          <div>
            Block {block}, Inverter {inv}
          </div>
          <IconButton style={{ color: "white" }}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        <div className={classes.customTooltip}>
          <div>
            Soiling loss: <b>{soilingLoss}</b>
          </div>
        </div>
      </div>
    )
  }
