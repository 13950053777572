import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { InverterKPIArgs, InverterKPIResponse, InverterKPITransformedResponse } from "./inverterPerformanceApi.types";
import { convertTotalTimeAgo, getQueryParams } from "../../../shared/utils/utils";
import { getBearerToken } from "../../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1/`;

// All of the api's which are in monitoring dashboard pages
// TODO: kaz move this to sidemSharedAPI

const transformInverterKPIResponse = (response: InverterKPIResponse) => {
  return {
    ...response,
    totalDowntime:
      response?.totalDowntime !== undefined
        ? convertTotalTimeAgo(response?.totalDowntime).split(" ")[0]
        : "-",
    totalDownTimeUnit:
      response?.totalDowntime !== undefined
        ? convertTotalTimeAgo(response?.totalDowntime).split(" ")[1]
        : "",
  };
};

export const monitoringInverterPerformanceApi = createApi({
  reducerPath: "monitoringInverterPerformanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}${sidemApiUrl}`,
    prepareHeaders: async (headers) => {
      const token = await getBearerToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
        headers.set("Content-Type", "text/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInverterKPI: builder.query<
      InverterKPITransformedResponse,
      InverterKPIArgs
    >({
      query: ({ plantId, fromDate, toDate }) => ({
        url: `GetMppInvpageKPI${getQueryParams({ plantId, fromDate, toDate })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformInverterKPIResponse,
    }),
  }),
});

export const { useGetInverterKPIQuery } = monitoringInverterPerformanceApi;
