import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router";
import { useSharedFormState } from "../../../shared/utils/sharedStates";
import { fetchAsync } from "../../../shared/utils/apiService";
import { deleteTokenFromLocalStorage } from "../../../store/authToken";
interface Tenant {
  isActive: boolean;
  tenantId: string;
  tenantName: string;
}
interface Data {
  tenants: Array<Tenant>;
  setTenants: any;
  anchorEl: any;
  setAnchorEl: any;
  anchorE2: any;
  setAnchorE2: any;
  children: any;
  setChildren: any;
  openUserProfile: boolean;
  setOpenUserProfile: any;
  accounts: any;
  loading: boolean;
  getInitials: () => string;
  onSwitchTenant: (item: Tenant) => void;
  logOut: () => void;
  downloadURI: (uri, name) => void;
}

export const useProfile = (): Data => {
  const [tenants, setTenants] = useState([] as any);
  const { instance, accounts } = useMsal();

  const id = (accounts?.[0]?.idTokenClaims as any)?.extension_userid;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorE2, setAnchorE2] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const { activetenants, setActiveTenants } = useSharedFormState();
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  useEffect(() => {
    const handleClickOutsideUserProfile = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        setOpenUserProfile(false);
        setAnchorEl(null);
      }
    };
    if (openUserProfile) {
      document.addEventListener("click", handleClickOutsideUserProfile);
    }
    return () => {
      document.removeEventListener("click", handleClickOutsideUserProfile);
    };
  }, [openUserProfile, anchorEl]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    fetchAsync(`user/tenant?userId=${id}`, "GET")
      .then((data: Array<Tenant>) => {

        if (!data?.length) {
          return;
        }

        setTenants(data);
        let activeTenant = data.find((x) => x?.isActive === true);
        setActiveTenants(activeTenant?.tenantName);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [id]);

  const logOut = () => {
    deleteTokenFromLocalStorage()
    instance.logoutRedirect()
      .catch((e) => console.log(e)
      );
  };

  const getInitials = (): string => {
    const fullName = accounts[0]?.name;
    if (!fullName?.length) return "";
    const [name = "", surname = ""] = fullName?.split(" ");
    return (name[0] || "") + (surname[0] || "");
  };

  const onSwitchTenant = (item: Tenant) => {
    const body = {
      userId: id,
      tenantId: item.tenantId,
    };

    fetchAsync(`user/settenant`, "POST", body)
      .then((status) => {
        if (status) {
          logOut();
        }
      })
      .catch((e) => console.error(e));
  };

  const closeChildMenu = () => setAnchorE2(null);
  const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.href = uri;
    link.download = name;
    link.target = "_blank";
    link.click();
    closeChildMenu();
  };
  return {
    getInitials,
    onSwitchTenant,
    logOut,
    downloadURI,
    accounts,
    openUserProfile,
    setOpenUserProfile,
    tenants,
    setTenants,
    anchorEl,
    setAnchorEl,
    anchorE2,
    setAnchorE2,
    children,
    setChildren,
    loading,
  };
};
