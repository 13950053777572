import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  widgetItem: {
    display: "flex",
    flexDirection: "column",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 14,
    // textTransform: "capitalize",
    color: "#707070",
  },
}));
