// TODO: kaz there is function timeAgo in global utils, but it shows incorrect data.
// investigate it, for now just use this guy

import { useEffect, useRef, useState } from "react";
import { BatteryItemObject } from "./BatteryMonitoring.api.types";
import { DataWithUnitWidgetOption } from "./components/DataWithUnitWidget/DataWithUnitWidget.types";

export const timeAgo = (date: Date): string => {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return "just now";
  } else if (minutes === 1) {
    return "1 min ago";
  } else if (minutes < 60) {
    return `${minutes} mins ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else if (days < 30) {
    return `${days} days ago`;
  } else if (months === 1) {
    return "1 month ago";
  } else if (months < 12) {
    return `${months} months ago`;
  } else if (years === 1) {
    return "1 year ago";
  } else {
    return `${years} years ago`;
  }
};

export const useGetTimeAgo = (date: Date | null, condition: unknown) => {
  const [timeString, setTimeString] = useState<string>("");

  const intervalRef = useRef<number | undefined>();

  useEffect(() => {
    if (date) {
      setTimeString(timeAgo(date));

      intervalRef.current = window.setInterval(() => {
        setTimeString(timeAgo(date));
      }, 60 * 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [date, condition]);

  return timeString;
};

export enum CHART_COLORS {
  SOC_LINE = "#01579B",
  CHARGED_ENERGY = "#B8CE5B",
  DISCHARGED_ENERGY = "#97CBFD",
  PRIMARY_DARK = "#0C1E33",
  PRIMARY_LIGHT = "#97CEFD",
  SECONDARY = "#34A114",
  FIRST_STOP = "rgba(151,206,253,0.23)",
  SECOND_STOP = "rgba(52,161,20,0.23)",
  CRITICALLY_LOW = "#115F9A",
  LOW = "#26BB9F",
  OPTIMAL = "#A6D75B",
  HIGH = "#FFEA30",
  CRITICALLY_HIGH = "#FF8730",
  ACTIVE_POWER = "#26BB9F",
  APPARENT_POWER = "#A6C232",
  REACTIVE_POWER = "#EB338C",
  POWER_FACTOR = "#01579B",
  CURRENT = "#EB338C",
  VOLTAGE_LL = "#26BB9F",
  VOLTAGE_LN = "#26BB9F",
  FREQUENCY = "#01579B",
}

export const HEATMAP_COLORS = [
  CHART_COLORS.CRITICALLY_LOW,
  CHART_COLORS.LOW,
  CHART_COLORS.OPTIMAL,
  CHART_COLORS.HIGH,
  CHART_COLORS.CRITICALLY_HIGH,
];

export enum DROPDOWN_OPTION_VALUE {
  RAW_DATA = 0,
  ONE_MIN = 1,
  FIVE_MIN = 2,
  FIFTEEN_MIN = 3,
  THIRTY_MIN = 4,
  ONE_HOUR = 5,
  ONE_DAY = 6,
  ONE_WEEK = 7,
  ONE_MONTH = 8,
  ONE_YEAR = 9,
}

export const DROPDOWN_OPTIONS: {
  label: string;
  value: DROPDOWN_OPTION_VALUE;
}[] = [
  {
    label: "Raw data",
    value: DROPDOWN_OPTION_VALUE.RAW_DATA,
  },
  {
    label: "1 min",
    value: DROPDOWN_OPTION_VALUE.ONE_MIN,
  },
  {
    label: "5 min",
    value: DROPDOWN_OPTION_VALUE.FIVE_MIN,
  },
  {
    label: "15 min",
    value: DROPDOWN_OPTION_VALUE.FIFTEEN_MIN,
  },
  {
    label: "30 min",
    value: DROPDOWN_OPTION_VALUE.THIRTY_MIN,
  },
  {
    label: "1 h",
    value: DROPDOWN_OPTION_VALUE.ONE_HOUR,
  },
  {
    label: "1 day",
    value: DROPDOWN_OPTION_VALUE.ONE_DAY,
  },
  {
    label: "1 week",
    value: DROPDOWN_OPTION_VALUE.ONE_WEEK,
  },
  {
    label: "1 month",
    value: DROPDOWN_OPTION_VALUE.ONE_MONTH,
  },
  {
    label: "1 year",
    value: DROPDOWN_OPTION_VALUE.ONE_YEAR,
  },
];

export function getPinpointShape(x: number, y: number) {
  const multiplier = 1.4;

  return [
    "M",
    x + 3.72554 * multiplier,
    y + 3.5072 * multiplier,
    "C",
    x + 5.91469 * multiplier,
    y + 1.40203 * multiplier,
    x + 8.88047 * multiplier,
    y + 0.222168 * multiplier,
    x + 11.9695 * multiplier,
    y + 0.222168 * multiplier,
    "C",
    x + 15.0585 * multiplier,
    y + 0.222168 * multiplier,
    x + 18.0242 * multiplier,
    y + 1.40207 * multiplier,
    x + 20.2133 * multiplier,
    y + 3.50722 * multiplier,
    "C",
    x + 22.403 * multiplier,
    y + 5.61291 * multiplier,
    x + 23.6361 * multiplier,
    y + 8.47217 * multiplier,
    x + 23.6361 * multiplier,
    y + 11.4569 * multiplier,
    "C",
    x + 23.6361 * multiplier,
    y + 15.3086 * multiplier,
    x + 21.9086 * multiplier,
    y + 18.7395 * multiplier,
    x + 19.9244 * multiplier,
    y + 21.4219 * multiplier,
    "C",
    x + 17.9329 * multiplier,
    y + 24.1143 * multiplier,
    x + 15.596 * multiplier,
    y + 26.1708 * multiplier,
    x + 14.1722 * multiplier,
    y + 27.3043 * multiplier,
    "L",
    x + 14.0985 * multiplier,
    y + 27.3633 * multiplier,
    "C",
    x + 13.7763 * multiplier,
    y + 27.6219 * multiplier,
    x + 13.3559 * multiplier,
    y + 27.9592 * multiplier,
    x + 12.7599 * multiplier,
    y + 28.1223 * multiplier,
    "C",
    x + 12.2736 * multiplier,
    y + 28.2554 * multiplier,
    x + 11.665 * multiplier,
    y + 28.2554 * multiplier,
    x + 11.1788 * multiplier,
    y + 28.1223 * multiplier,
    "C",
    x + 10.5831 * multiplier,
    y + 27.9593 * multiplier,
    x + 10.1628 * multiplier,
    y + 27.622 * multiplier,
    x + 9.84148 * multiplier,
    y + 27.364 * multiplier,
    "L",
    x + 9.76685 * multiplier,
    y + 27.3043 * multiplier,
    "C",
    x + 8.34312 * multiplier,
    y + 26.1708 * multiplier,
    x + 6.00617 * multiplier,
    y + 24.1143 * multiplier,
    x + 4.01457 * multiplier,
    y + 21.4219 * multiplier,
    "C",
    x + 2.03033 * multiplier,
    y + 18.7395 * multiplier,
    x + 0.302734 * multiplier,
    y + 15.3086 * multiplier,
    x + 0.302734 * multiplier,
    y + 11.4569 * multiplier,
    "C",
    x + 0.302734 * multiplier,
    y + 8.4722 * multiplier,
    x + 1.53586 * multiplier,
    y + 5.61289 * multiplier,
    x + 3.72554 * multiplier,
    y + 3.5072 * multiplier,
    "Z",
  ];
}

export const getGranularityMs = (granularity: number) => {
  switch (granularity) {
    case DROPDOWN_OPTION_VALUE.RAW_DATA:
      return 1000;
    case DROPDOWN_OPTION_VALUE.ONE_MIN:
      return 60 * 1000;
    case DROPDOWN_OPTION_VALUE.FIVE_MIN:
      return 5 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.FIFTEEN_MIN:
      return 15 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.THIRTY_MIN:
      return 30 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.ONE_HOUR:
      return 1 * 60 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.ONE_DAY:
      return 24 * 60 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.ONE_WEEK:
      return 7 * 24 * 60 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.ONE_MONTH:
      return 30 * 24 * 60 * 60 * 1000;
    case DROPDOWN_OPTION_VALUE.ONE_YEAR:
      return 365 * 24 * 60 * 60 * 1000;
    default:
      return 1000;
  }
};

export const fillGapsBetweenTimestamps = (
  fromDate: string,
  toDate: string,
  granularity: number
) => {
  // TODO: kaz to verify - if raw data, display hourly data
  const granularityMs =
    granularity === DROPDOWN_OPTION_VALUE.RAW_DATA
      ? getGranularityMs(DROPDOWN_OPTION_VALUE.ONE_HOUR)
      : getGranularityMs(granularity);

  const fromDateObj = new Date(fromDate);
  fromDateObj.setHours(0, 0, 0, 0);
  const toDateObj = new Date(toDate);
  toDateObj.setHours(23, 59, 59, 999);
  const newDates = [];

  let currentDate = fromDateObj;

  while (currentDate <= toDateObj) {
    newDates.push(currentDate.toISOString());
    currentDate = new Date(currentDate.getTime() + granularityMs);
  }

  return newDates;
};

export const getStep = (granularity: number, items: unknown[]) => {
  switch (granularity) {
    case DROPDOWN_OPTION_VALUE.RAW_DATA:
      // make sure that there will be no more than 30 labels for visibility
      return Math.ceil(items.length / 30);
    case DROPDOWN_OPTION_VALUE.ONE_MIN:
      return 60;
    case DROPDOWN_OPTION_VALUE.FIVE_MIN:
      return 12;
    case DROPDOWN_OPTION_VALUE.FIFTEEN_MIN:
      return 4;
    case DROPDOWN_OPTION_VALUE.THIRTY_MIN:
      return 2;
    case DROPDOWN_OPTION_VALUE.ONE_HOUR:
      return 1;
    case DROPDOWN_OPTION_VALUE.ONE_DAY:
      return 1;
    case DROPDOWN_OPTION_VALUE.ONE_WEEK:
      return 1;
    case DROPDOWN_OPTION_VALUE.ONE_MONTH:
      return 1;
    case DROPDOWN_OPTION_VALUE.ONE_YEAR:
      return 1;
    default:
      return 1;
  }
};

export function findClosestItem<T>(arr: T[], target: number, key: keyof T) {
  let closestResult = null;
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i][key];

    if (Number(item) <= target) {
      closestResult = arr[i];
    } else {
      break;
    }
  }
  return closestResult;
}

export function findClosestItemInMap<T>(map: Map<number, T>, target: number): T | null {
  if (map.has(target)) {
    return map.get(target) || null;
  }

  let closestResult: T | null = null;
  for (const [key, value] of map.entries()) {
    if (key <= target) {
      closestResult = value;
    } else {
      break;
    }
  }
  return closestResult;
}

export const getValue = (
  item: BatteryItemObject | DataWithUnitWidgetOption | undefined,
  decimalCount: number = 1
) => {
  if (!item?.value && item?.value !== 0) {
    return "N/A";
  } else {
    if (!decimalCount) {
      return item.value;
    } else {
      return Number(item.value).toFixed(decimalCount);
    }
  }
};

export const getUnit = (item: BatteryItemObject | undefined) => {
  return item?.unit || "";
};
