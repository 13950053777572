import React from "react";
import { styled } from "@mui/material/styles";

const Div = styled("div")(() => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "26px",
  lineHeight: "42px",
  textTransform: "capitalize",
}));

interface Props {
  label?: string;
}

export const PageTitle: React.FC<Props> = ({ label }) => {
  return <Div>{label}</Div>;
};
