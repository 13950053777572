import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { convertTime, convertUnitForGraphs } from "../../../shared/utils/utils";
if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}
NoDataToDisplay(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/full-screen")(Highcharts);
require("highcharts/modules/map")(Highcharts);

interface Props {
  panning?: boolean;
  panningType?: "xy" | "x" | "y";
  zoomType?: "xy" | "x" | "y" | "none";
  tooltip?: boolean;
  exporting?: boolean;
  title: string;
  titleAlignment?: "left" | "center" | "right";
  xAxis: {};
  yAxis: any[];
  colors: string[];
  data: any[];
  height?: string | number;
  type?: string;
  connectNulls?: boolean;
  loading?: boolean;
  customLegend?: boolean;
}

export const LineChart = (props: Props) => {
  const {
    panning = true,
    tooltip = true,
    exporting = true,
    title,
    titleAlignment = "left",
    panningType = "x",
    xAxis,
    yAxis,
    colors,
    data,
    zoomType = "x",
    height = "",
    type = "spline",
    connectNulls = false,
    loading,
    customLegend = false,
  } = props;

  const checkLegend = (legendVal) => {
    if (!customLegend) return legendVal;

    return convertTime(legendVal, "HH:mm");
  };
  const options = {
    chart: {
      events: {
        load() {
          if (loading) {
            this.showLoading();
          } else {
            this.hideLoading();
          }
        },
        redraw() {
          if (loading) {
            this.showLoading();
          } else {
            this.hideLoading();
          }
        },
      },
      boost: {
        useGPUTranslations: true,
      },
      type: type,
      zoomType: zoomType,
      panning: {
        enabled: panning,
        type: panningType,
      },
      panKey: "shift",
      // height: height,
      style: {
        fontFamily: "Inter",
        fontWeight: 500,
      },
    },
    loading: {
      showDuration: 0, // Show the loader immediately
      hideDuration: 1000, // Hide the loader after 1 second (adjust as needed)
      labelStyle: {
        fontSize: "16px",
      },
    },
    // empty chart message
    tooltip: {
      enabled: tooltip,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          const pointColor = `
                    <tspan style="font-size:20px; color: ${point.color}; fill: ${point.color};">●</tspan>&nbsp`;
          return (
            s +
            '<div style="display:flex;"> <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; paddingBottom:8px;">' +
            pointColor +
            point.series.name +
            "</div> " +
            '<div style="display:grid; width:100%;"><div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">&nbsp;&nbsp;&nbsp;' +
            convertUnitForGraphs(
              point.y,
              point.series.options.tooltip.valueSuffix
            ) +
            "</div></div><br/></div> "
          );
        }, '<span style="font-size:15px; font-weight:bold">' +
          checkLegend(this.x) +
          "<hr></span>");
      },
      shared: true,
      useHTML: true,
      borderWidth: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      align: titleAlignment,
      style: {
        fontWeight: 500,
        fontFamily: "Inter",
        fontSize: "20px",
      },
    },
    lang: {
      noData: "No data is available in the chart",
    },
    noData: {
      style: {
        fontWeight: 500,
        fontSize: "15px",
        color: "#9F9F9F",
      },
      position: {
        x: 0,
        y: 0,
        align: "center",
        verticalAlign: "middle",
      },
    },
    xAxis: xAxis,
    yAxis: yAxis,
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 3,
        },
        lineWidth: 1,
        pointPlacement: "on",
        connectNulls: connectNulls,
      },
    },
    exporting: {
      enabled: exporting,
      chartOptions: {
        title: {
          text: title,
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              separator: true,
            },
            {
              textKey: "printChart",
              onclick: function () {
                this.print();
              },
            },
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            {
              text: "Download XLS",
              onclick() {
                this.downloadXLS();
              },
            },
            {
              text: "Download CSV",
              onclick() {
                this.downloadCSV();
              },
            },
          ],
        },
      },
    },
    colors: colors,
    series: data,
  };
  return (
    <div>
      {" "}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ height: height }}
      />{" "}
    </div>
  );
};
