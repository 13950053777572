import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import CardsPageWidget, {
  Widget,
  WidgetConfig,
} from "../../components/CardsPageWidget";
import Loader from "../../components/Loader";
import { useStyles } from "./styles";
import {
  showOptions,
  formatTypes,
  fakeFormatTypes,
  sortByOptions,
} from "./utils";
import { useCards } from "./useCards";
import {
  CardsChartButtons,
  CardsCharts,
  ChartTypes,
} from "../../components/CardsCharts";
import { Header } from "../../components/PortfolioHeader";
import { OverviewHeader } from "../../../shared/components/Overview";
import { OverviewDropdown } from "../../components/OverviewDropdown";
import { MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo } from "../../../features/Sidem/client/clientActions";
import { clientSelector } from "../../../features/Sidem/client/clientSlice";

interface HeaderItem {
  title: string;
  currentValue?: any;
  prevValue?: any;
}

interface ClientInfo {
  clientLogo: string;
  clientName: string;
  portfolioImages: string;
  pr: number;
  budget_PR: number;

  revenue: number;
  portfolios: number;
}

const getHeaderItems = (info: ClientInfo): Array<HeaderItem> => [
  {
    title: "PR",
    currentValue: (info?.pr * 100)?.toFixed(1) + "%" || "",
    prevValue: (info?.budget_PR * 100)?.toFixed(1) + "%" || "",
  },
  {
    title: "Portfolios",
    currentValue: info?.portfolios,
  },
];

export const Clientpage: React.FC<any> = ({
  imageService
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [show, setShow] = useState(showOptions[0]);
  const [fakeFormat, setFakeFormat] = useState(fakeFormatTypes[0].value);
  const [clientInfo, setClientInfo] = useState({} as ClientInfo);
  const headerItems = getHeaderItems(clientInfo);
  const { client } = useSelector(clientSelector);


  const {
    loading,
    data,
    error,
    onGoToPage,
    updateWidgetChart,
    getCards,
    arrangeData,
    openPortfolioModal,
    setOpenPortfolioModal,
    portfolioPopupData,
    plantTitle,
    setPlantTitle,
    popupLoading,
    popupError,
    setFormat,
    date
  } = useCards({ show, fakeFormat });

  const getConfig = (widget: Widget): WidgetConfig => ({
    customMenu: (
      <CardsChartButtons
        chartType={widget.chartType}
        onChangeChartType={(type: ChartTypes) => {
          updateWidgetChart(widget, type);
        }}
      />
    ),
    chartComponent: (
      <CardsCharts
        showChart={true}
        chartType={widget.chartType}
        data={widget?.chartData}
      />
    ),
  });

  const definition = {
    Plants: "It shows the number plants connected to that portfolio",
    "Capacity DC": "It shows the total connected DC capacity of the portfolio",
    Generation:
      "It shows the actual generation of the portfolio and here we can also see the generation delta% from the expected generation for the portfolio",
    Irradiation:
      "It shows the actual irradiation of the portfolio and here we can also see the irradiation delta% from the expected irradiation for the portfolio",
    PR: "It shows the actual PR and budget PR for the portfolio",
    Availability:
      "It shows the actual equipment availability of the portfolio and here we can also see the availability delta% from the expected availability for the portfolio",
    "CUF/PLF": "It shows the actual CUF and budget CUF for the portfolio",
    Alert: "It shows the total alert for the portfolio",
    "Generation Delta":
      "It shows the generation delta from the expected generation",
    "Grid Availability": "Loss due to the grid outages during generation hours",
  };

  const getData = async () => {
    let data: any = client;
    if (!client) {
      data = await dispatch(getClientInfo());
    }
    setClientInfo(data);
  };

  const onChangeFormat = (e) => {
    const newValue = e.target.value;
    setFormat(newValue);
    getCards();
  };

  const formatDropdown = () => {
    if (!formatTypes?.length) return null;
    return (
      <div className={classes.selector}>
        <div className={classes.label}>Period</div>
        <Select
          variant={"outlined"}
          value={date}
          className={classes.select}
          onChange={onChangeFormat}
        >
          {formatTypes?.map((item, idx) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  useEffect(() => {
    getData();
    if (!data) {
      getCards();
    }
  }, []);

  return (
    <>
      <Header headerDropdown={formatDropdown} title={clientInfo?.clientName} />
      <OverviewHeader
        items={headerItems}
        title="Overview"
        dropdown={
          <OverviewDropdown
            items={headerItems}
            definition={definition}
            colsA={4}
          />
        }
      />
      <Grid container spacing={2} className={classes.pageWrapper}>
        <Loader
          loading={loading}
          height={300}
          error={error}
          errorTextSize={32}
        />
        {!loading && data?.length ? (
          <div className={classes.widgets}>
            {data.map((item) => {
              const nfields = item.fields.map((field) => {
                if (field.title === "Generation") {
                  const val = field.value.toString().split(" ");
                  const nVal =
                    val[0]?.toString().length > 4
                      ? (parseInt(val[0]) / 1000)?.toFixed(1)
                      : parseInt(val[0])?.toFixed(1);
                  const nfield = {
                    ...field,
                    value: nVal,
                  };
                  return nfield;
                }
                return field;
              });
              const nData: Widget = {
                ...item,
                fields: nfields,
              };
              const fields = arrangeData(item);
              return (
                <CardsPageWidget
                  format={date}
                  formatTypes={formatTypes}
                  onSelectFormat={setFormat}
                  imageService={imageService}
                  key={item.title}
                  widget={nData}
                  onClick={onGoToPage(item)}
                  config={getConfig(item)}
                  definition={definition}
                  item={fields}
                  type={"PortfolioPage"}
                  openPortfolioModal={openPortfolioModal}
                  setOpenPortfolioModal={setOpenPortfolioModal}
                  portfolioPopupData={portfolioPopupData}
                  plantTitle={plantTitle}
                  setPlantTitle={setPlantTitle}
                  popupLoading={popupLoading}
                  popupError={popupError}
                />
              );
            })}
          </div>
        ) : null}
      </Grid>
    </>
  );
};
