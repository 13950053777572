import { getBearerToken } from "../../../store/authToken";
import { axGet } from "../../../store/axiosClient";
import * as MonitoringGenerationSlice from "./monitoringGenerationSlice";
import axios from "axios";

const sidemApiUrl = `SkyfriSidemApi/v1`;

export function getGenerationPlantOverviewKPI(fromDate, toDate, portfolio, plant) {
    return async () => {
        try {
            const token = await getBearerToken();
            const data: any = await axGet(
                `${sidemApiUrl}/GetMppGenPagePlantOverview?fromDate=${fromDate}&toDate=${toDate}&portfoliosName=${portfolio}&plantsId=${plant}`,
                token
            );
            return data;
        } catch (error) {
            return error.response.data
        }
    };
}

export function downloadDataGenPage(portfolio, plant, fromDate, toDate, binSensorTime) {
    return async () => {
        try {
            const token = await getBearerToken();
            const BASE_API_URL = process.env.REACT_APP_API_URL
                ? process.env.REACT_APP_API_URL
                : "https://skifryfpel-dev-apimngment.azure-api.net/";
            const data: any = await axios({
                method: "get",
                url:
                    BASE_API_URL +
                    sidemApiUrl +
                    `/GetMppPlantOverviewReport?portfolioName=${portfolio}&plantId=${plant}&fromDate=${fromDate}&toDate=${toDate}&binSensortime=${binSensorTime}`,
                responseType: "blob",
                headers: {
                    authorization: "Bearer " + token,
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                    responseType: "blob",
                },
            });
            return data.data;
        } catch (error) {
            return null
        }
    };
}



//Global States
export const setDateComparison = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringGenerationSlice.dateComparison(payload));
        return payload;
    };
};

export const setGlobalDateAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringGenerationSlice.setGlobalDate(payload));
        return payload;
    };
};

export const setGlobalLiveButtonAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringGenerationSlice.setLiveButton(payload));
        return payload;
    };
};

export const setGlobalPlantIdAction = (payload: any[]) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringGenerationSlice.getPlantId(payload));
        return payload;
    };
};

export const setGlobalPortfolioNameAction = (payload: any[]) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringGenerationSlice.getPortfolioName(payload));
        return payload;
    };
};
