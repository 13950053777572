import React, { useState } from "react";
import { Box, OutlinedInput } from "@mui/material";
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    border: "1px solid lightgray",
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    paddingLeft: theme.spacing(1.5),
    padding: "4px",
  },

  input: {
    flex: 1,
    textAlign: "left",
    fontSize: "14px !important",
  },
}));

interface Props {
  onChange: (val: string) => void;
  value?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  width?: number | string;
  onKeyUp?: (val: string) => void;
  onCustomInput?: (val: string) => void;
  minWidth?: number | string;
}

export const Input: React.FC<Props> = ({ minWidth, placeholder, value, icon, width = 400, onChange, onKeyUp, onCustomInput }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const onInput = (e) => {
    setSearch(e.target.value);

    if (onCustomInput) {
      onCustomInput(e.target.value);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onChange(search);
    }
  };

  return (
    <Box
      sx={{
        minWidth: minWidth,
        width: width,
        maxWidth: '100%',
      }}
    >
      <OutlinedInput
        fullWidth
        value={value || search}
        onInput={onInput}
        onBlur={() => onChange(search)}
        onKeyUp={() => typeof onKeyUp === 'function' && onKeyUp(search)}
        onKeyDown={onKeyDown}
        classes={{ input: classes.input }}
        placeholder={placeholder}
        startAdornment={icon}
      />
    </Box>
  );
};
