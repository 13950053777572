import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '../ErrorMessage';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export interface DropdownOption {
  label: string;
  value: string | number;
}

interface Props {
  options: Array<DropdownOption>;
  onChangeValue: (e: any) => void;
  width?: number;
  showError?: boolean;
  errorMessage?: string;
  value?: any;
}

const useStyles = makeStyles((theme) => ({
  rootFirstSelect: {
    paddingLeft: "5px"
  },
  button: {
    minWidth: "100%",
    justifyContent: "start"
  }
}));


export const AutoComplete: React.FC<Props> = ({ options, onChangeValue, width, showError, errorMessage, value }) => {
  const classes = useStyles();
  return (
    <FormControl sx={{ m: 0, minWidth: width ? width : 120 }}>
      <Autocomplete
        value={value}
        onChange={(e: any) => onChangeValue(e.target.value)}
        options={options}
        getOptionLabel={(option: DropdownOption) => option.label}
        getOptionKey={(option: DropdownOption) => option.value}
        renderInput={(params) => (
          <TextField {...params} variant="standard" />
        )}
      />

      <ErrorMessage
        showError={showError}
        errorMessage={errorMessage}
      />
    </FormControl>
  )
}
