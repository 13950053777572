import { Tooltip, Legend, ComposedChart, Area, XAxis, ResponsiveContainer } from 'recharts';
import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";
import { RenderCustomLegend } from "../../../../components/CustomChartLegend";

import { CustomTooltip } from "./CustomTooltip.componets";
import { Values } from "./useExpectedGeneration";

interface Props {
  data: Array<any>;
  width: string;
}

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: any };
  format?: string;
}

const CustomizedAxisTick = ({ x, y, payload }: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-45)"
    >
      {payload.value}
    </text>
  </g>
);

export const Chart: React.FC<Props> = ({ data, width }) => (
  <ResponsiveContainer width={width} height={500}>
    <ComposedChart data={data}>
      <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: "none" }} />
      <Legend iconType="square" content={RenderCustomLegend} />
      <XAxis dataKey="time" height={60} tick={<CustomizedAxisTick />} />
      <Area
        type="monotone"
        dataKey={Values.ActualGeneration}
        stroke={GRAPH_COLORS.normal}
        fillOpacity={0.5}
        fill={GRAPH_COLORS.normal}
      />
      <Area
        type="monotone"
        dataKey={Values.TheoreticalGeneration}
        stroke={GRAPH_COLORS.purple}
        fillOpacity={0.4}
        fill={GRAPH_COLORS.purple}
      />
    </ComposedChart>

  </ResponsiveContainer>
);
