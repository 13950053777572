import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";

export interface ClientState {
  gettingClient?: boolean;
  clientFailed?: boolean;
  clientFailedMessage?: string;
  clientSuccessful?: boolean;
  client?: any;
}

const initialState: ClientState = {
  gettingClient: false,
  clientFailed: false,
  clientSuccessful: false,
  client: null,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clientStart: (state) => {
      return {
        ...state,
        gettingClient: true,
      };
    },
    clientSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingClient: false,
        clientSuccessful: true,
        client: payload,
      };
    },
    clientError: (state, { payload }) => {
      return {
        ...state,
        gettingClient: false,
        clientFailed: true,
        clientFailedMessage: payload,
      };
    },
  },
});

export const { clientStart, clientSuccess, clientError } = clientSlice.actions;

export const clientSelector = (state: RootState) => state.Sidem.client;
export const clientReducer = clientSlice.reducer;
