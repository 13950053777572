import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100% !important",
    width: "450px",
    maxHeight: "100vh !important",
    cursor: "default",
  },
  alarmTime: {
    fontSize: "13px",
    fontWeight: 500,
    color: "rgb(158, 165, 174)",
    lineHeight: "14.3px",
    fontFamily: "Inter",
  },
  timeZone: {
    marginTop: "10px !important",
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alarmWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alarm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  statusLabel: {
    color: " #1572DF",
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "14.4px",
    marginLeft: "2px",
  },
  divider: {
    paddingLeft: "5px !important",
    paddingRight: "10px !important",
  },
  selector: {
    borderRadius: 5,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },
  portfoliosAndPlantsLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14.4px",
    color: "rgb(158, 165, 174)",
  },

  heading: {
    color: "black",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wrapperMobile: {
    height: "100%",
    width: "auto",
    maxHeight: "100vh",
    padding: "10px 10px",
    margin: "0 auto",
  },

  header: {
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: 700,
    color: "#0C1E33",
  },
  resolvedLabel: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14.4px",
    color: "#008D5A",
  },

  portfoliosAndPlants: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px !important",
  },
  noAlarms: {
    backgroundColor: "green",
    display: "flex ",
    alignItems: "center ",
    justifyContent: "center ",
    fontSize: 20,
    height: "98vh",
    // height: "calc(100vh - 200px)",
  },
  comment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  link: {
    color: "#5C7199",
    fontSize: "12px",
    fontWeight: "bolder",
    textDecoration: "underline",
    cursor: "pointer",
  },
  padding: {
    padding: "11px 20px 11px 20px",
  },
  button: {
    borderRadius: "6px",
    width: 410,
    height: 40,
    "&:hover": {
      border: "0px !important",
      cursor: "pointer !important",
      backgroundColor: "#1672df !important", // Set the background color on hover
    },
  },

  alarmLabel: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "15.4px",
    marginLeft: "-1px",
  },
  alarmDescription: {
    color: "#0C1E33",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "14.3px",
    maxWidth: "290px",
    whiteSpace: "initial",
  },
  filterLabel: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "14.4px",
    color: "#0C1E33",
    padding: "12px",
  },
  filterOption: {
    padding: "10px",
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "14.4px",
    color: "#0C1E33",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E8ECF2 ",
    },
  },
  displayName: {
    color: "#0C1E33",
    marginLeft: "-1px",
  },
  filter: {
    padding: "4px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
      borderRadius: "4px",
      backgroundColor: "#E8ECF2 ",
    },
  },
}));
