import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Divider } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { Box, Tab, Tabs } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import { setSelectedModuleNameAndId } from "../../../../features/Onboarding/portfolios/portfolioAction";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "./../styles";
import { useTenantSave } from "./useTenantSave";
import { formSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { CustomTable } from "../../../../shared/components/Table/Table.component";
import Loader from "../../components/shared/Loader";
import { searchData } from "./../../components/Utils/utils";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { CustomiseDropDown } from "./CustomiseDropDown.component";
import {
  GetFormik,
  initializeValidationsObsolete,
  initializeValuesObsolete,
} from "./../../components/Utils/utils";
import { ControlPoint } from "@mui/icons-material";
import { useGetPortoliosByModuleIdQuery } from "../../../../features/Onboarding/onBoardingApi";
import {
  Search,
  Button,
  Modal,
  Checkbox,
} from "../../../../shared/components";
import { selectSelectedModule } from "../../../../features/Onboarding/portfolios/portfolioSlice";
import {
  portfolioViewHeaderCell,
  userTenantViewHeaderCell,
  teameViewHeaderCell,
  moduleFilters,
  portfolioFilters,
  userTenantFilters,
  teamFilters,
  rolePermissionFilters,
  RoleViewHeaderCell,
} from "./interfaces";
import { Button2 } from "../../../../shared/components/Button2/Button2.component";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface Option {
  value: string;
  label: string;
  id: string;
  tenantId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ height: "65vh" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export const TenantSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();

  const {
    upsertTenant,
    tenant,
    moduleTenant,
    deleteModuleTenants,
    portfolio,
    deletePortfolio,
    userTenant,
    deleteUserTenant,
    loading,
    upsertModuleTenant,
    team,
    deleteTeam,
    deleteRole,
    roles,
    upsertPermissions,
    module,
    setModule,
    moduleOptions,
    setModuleOptions,
  } = useTenantSave({
    tenantId
  });

  const { selectedModule } = useSelector(selectSelectedModule);
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [actionType, setActionType] = useState("");
  const [moduleSearch, setModuleSearch] = useState("");
  const [moduleFilteredData, setModuleFilteredData] = useState([]);
  const [portfolioSearch, setPortfolioSearch] = useState("");
  const [portfolioFilteredData, setPortfolioFilteredData] = useState([]);
  const [userTenantSearch, setUserTenantSearch] = useState("");
  const [userTenantFilteredData, setUserTenantFilteredData] = useState([]);
  const [teamFilteredData, setTeamFilteredData] = useState([]);
  const [teamSearch, setTeamSearch] = useState("");
  const [roleFilteredData, setRoleFilteredData] = useState([]);
  const [roleSearch, setRoleSearch] = useState("");
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedModuleId, setSelectedModuleId] = useState("");
  const [selectedTenantId, setSelectedTenantId] = useState("");
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const createNewTenant = tenantId === "new";
  const {
    data: portfolios,
    isLoading: isLoadingPortfolios,
    refetch: portfoliosRefetch,
  } = useGetPortoliosByModuleIdQuery(
    { tenantId: selectedTenantId, moduleId: selectedModuleId },
    {
      skip: !selectedModuleId || !selectedTenantId,
    }
  );

  useEffect(() => {
    if (!module) return;
    portfoliosRefetch();
  }, [selectedModuleId, selectedTenantId]);

  const getParam = (name) => {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || 0;
  };

  useEffect(() => {
    var index = Number(getParam("index"));
    setValue(index || 0);
  }, []);

  useEffect(() => {
    initializeValidationsObsolete(formSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (!userTenant) return;
    if (userTenantSearch && userTenantSearch !== "") {
      setUserTenantFilteredData(
        searchData(userTenantSearch, userTenant, userTenantFilters)
      );
    } else {
      setUserTenantFilteredData(userTenant);
    }
  }, [userTenantSearch, userTenant]);

  useEffect(() => {
    if (!userTenant) return;
    if (roleSearch && roleSearch !== "") {
      setRoleFilteredData(searchData(roleSearch, roles, rolePermissionFilters));
    } else {
      setRoleFilteredData(roles);
    }
  }, [roleSearch, roles]);

  useEffect(() => {
    if (!userTenant) return;
    if (teamSearch && teamSearch !== "") {
      setTeamFilteredData(searchData(teamSearch, team, teamFilters));
    } else {
      setTeamFilteredData(team);
    }
  }, [teamSearch, team]);

  useEffect(() => {
    if (!portfolios) return;
    const updatedPortfolios = portfolios.map((portfolio) => ({
      ...portfolio,
      id: portfolio?.portfolioId,
      module: moduleName,
    }));
    if (portfolioSearch && portfolioSearch !== "") {
      setPortfolioFilteredData(
        searchData(portfolioSearch, updatedPortfolios, portfolioFilters)
      );
    } else {
      setPortfolioFilteredData(updatedPortfolios);
    }
  }, [portfolioSearch, portfolios]);

  useEffect(() => {
    if (!moduleTenant) return;
    if (moduleSearch && moduleSearch !== "") {
      setModuleFilteredData(
        searchData(moduleSearch, moduleTenant, moduleFilters)
      );
    } else {
      setModuleFilteredData(moduleTenant);
    }
  }, [moduleSearch, moduleTenant]);

  useEffect(() => {
    if (tenant || createNewTenant) {
      initializeValuesObsolete(formSchema, createNewTenant, tenant, (resp) => {
        setInitialValues(resp);
      });
    }
  }, [tenant]);

  const handleCheckboxClick = (resp, roleInfo) => {
    let obj = {
      teamRoleId: roleInfo?.id,
      permissionKey: resp?.name,
    };
    upsertPermissions(obj, () => { });
  };

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    if (createNewTenant) {
      upsertTenant(fields, tenantId, () => {
        let URI = "/onboarding/tenant";
        renderPage(URI);
      });
    } else {
      upsertTenant(fields, tenantId, () => {
        let URI = "/onboarding/tenant";
        renderPage(URI);
      });
    }
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const searchHandler = (newSearch: string, type: string) => {
    if (type === "module") {
      setModuleSearch(newSearch);
    } else if (type === "portfolio") {
      setPortfolioSearch(newSearch);
    } else if (type === "team") {
      setTeamSearch(newSearch);
    } else if (type === "role") {
      setRoleSearch(newSearch);
    } else {
      setUserTenantSearch(newSearch);
    }
  };

  const handleChangeModule = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule(event.target.value as string);
  };

  const handleCheckboxChange = (item) => {
    if (item?.moduleTenantId !== "") {
      setActionType("Module");
      setSelectedId(item?.moduleTenantId);
      setOpenDeleteModal(true);
    } else {
      let Obj = {
        ModuleId: item?.moduleId,
        TenantId: tenantId,
      };
      upsertModuleTenant(Obj, (resp) => {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true);
      });
    }
  };

  const handleOnChangeModule = (selectedOption: Option | null) => {
    const {
      value: moduleValue,
      label: moduleName,
      id: moduleId,
      tenantId: tenantId,
    } = selectedOption;
    setModule(moduleValue);
    setModuleName(moduleName);
    setSelectedModuleId(moduleId);
    setSelectedTenantId(tenantId);
    dispatch(setSelectedModuleNameAndId({ moduleName, moduleId }));
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2 size={40} type="tertiary" onClick={onCloseDeleteModal}>
                  Cancel
                </Button2>
              </div>
              <div>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    switch (actionType) {
                      case "Module":
                        deleteModuleTenants(selectedId, tenantId, (resp) => {
                          if (resp?.ok === false) {
                            setSeverity("error");
                            setMessage("Operation unsuccessfull");
                            setIsOpen(true);
                          } else {
                            setSeverity("success");
                            setMessage("Record deleted successfully");
                            setIsOpen(true);
                          }
                        });
                        break;
                      case "Portfolio":
                        deletePortfolio(selectedId, (resp) => {
                          if (resp?.status !== 200) {
                            setSeverity("error");
                            setMessage(
                              "Operation unsuccessfull. Portfolio not deleted."
                            );
                            setIsOpen(true);
                          } else {
                            setSeverity("success");
                            setMessage("Portfolio deleted successfully");
                            portfoliosRefetch();
                          }
                        });
                        break;
                      case "UserTenant":
                        deleteUserTenant(selectedId, (resp) => {
                          if (resp?.ok === false) {
                            setSeverity("error");
                            setMessage("Operation unsuccessfull");
                            setIsOpen(true);
                          } else {
                            setSeverity("success");
                            setMessage("Record deleted successfully");
                            setIsOpen(true);
                          }
                        });
                        break;
                      case "team":
                        deleteTeam(selectedId, (resp) => {
                          if (resp?.ok === false) {
                            setSeverity("error");
                            setMessage("Operation unsuccessfull");
                            setIsOpen(true);
                          } else {
                            setSeverity("success");
                            setMessage("Record deleted successfully");
                            setIsOpen(true);
                          }
                        });
                        break;
                      case "role":
                        deleteRole(selectedId, (resp) => {
                          if (resp?.ok === false) {
                            setSeverity("error");
                            setMessage("Operation unsuccessfull");
                            setIsOpen(true);
                          } else {
                            setSeverity("success");
                            setMessage("Record deleted successfully");
                            setIsOpen(true);
                          }
                        });
                        break;
                      default:
                        break;
                    }
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
          </>
        }
      />
      <div className={classes.pageLabel}>
        {createNewTenant ? <>Add new tenant </> : <>Edit tenant</>}
      </div>
      {loading ? (
        <Loader loading={loading} height={100} error={""} errorTextSize={32} />
      ) : (
        // Module, Portflio, users, Teams, Role Permission
        <Card style={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <div className={classes.formElementsWrapper}>
              {Object.keys(formSchema).map((x) => (
                <DynamicField
                  name={x}
                  data={formSchema[x]}
                  formik={formik}
                />
              ))}
            </div>
          </form>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "white",
            }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="">
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span> Module</span>
                    <Tooltip
                      title="Modules enabled for this tenant"
                      className={`${classes.floatRight}`}
                    >
                      <IconButton color="primary" component="span">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span> Portfolio</span>
                    <Tooltip
                      title="Portfolios assigned to this tenant"
                      className={`${classes.floatRight}`}
                    >
                      <IconButton color="primary" component="span">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span> Tenant Users</span>
                    <Tooltip
                      title="Users assinged to this tenant"
                      className={`${classes.floatRight}`}
                    >
                      <IconButton color="primary" component="span">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span> Teams</span>
                    <Tooltip
                      title="Teams assigned to this tenant"
                      className={`${classes.floatRight}`}
                    >
                      <IconButton color="primary" component="span">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            {createNewTenant ? (
              <></>
            ) : (
              <>
                {moduleTenant?.map((item) => (
                  <div>
                    <Checkbox
                      checked={item?.isChecked}
                      label={item?.moduleName}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </div>
                ))}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {createNewTenant ? (
              <></>
            ) : (
              <>
                <div className={classes.floatRight}>
                  <Button2
                    size={40}
                    disabled={
                      moduleName === "" ||
                        moduleName === "Commercial" ||
                        moduleName === "Monitoring" ||
                        moduleName === "Sidem"
                        ? true
                        : false
                    }
                    type="primary"
                    onClick={() =>
                      renderPage(
                        `/onboarding/portfolio/${tenantId}/${selectedModuleId}/new`
                      )
                    }
                    iconRight={<ControlPoint sx={{ fontSize: "24px" }} />}
                  >
                    Add
                  </Button2>
                </div>

                <Tooltip
                  title="Click here to add portfolio"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "600px", display: "flex" }}>
                  <CustomiseDropDown
                    disableUnderline={false}
                    showInputLabel={true}
                    value={module}
                    onChange={(selectedOption) =>
                      handleOnChangeModule(selectedOption)
                    }
                    options={moduleOptions}
                  />
                  <span style={{ marginLeft: "20px" }}>
                    <Search
                      placeholder="Search for portfolio"
                      value={portfolioSearch}
                      onChange={(search) => searchHandler(search, "portfolio")}
                      onKeyUp={(search) => searchHandler(search, "portfolio")}
                    />
                  </span>
                </div>
                <br />
                {isLoadingPortfolios ? (
                  <Loader
                    loading={isLoadingPortfolios}
                    height={300}
                    error={""}
                    errorTextSize={32}
                  />
                ) : (
                  <CustomTable
                    tableType={`/portfolio/${tenantId}/${selectedModuleId}`}
                    headerCells={portfolioViewHeaderCell}
                    data={portfolioFilteredData}
                    maxHeight={450}
                    module={"/onboarding"}
                    isDoubleClickActive={true}
                    onDeleteClick={(id) => {
                      setActionType("Portfolio");
                      setSelectedId(id);
                      setOpenDeleteModal(true);
                    }}
                  />
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {createNewTenant ? (
              <></>
            ) : (
              <>
                <div className={classes.floatRight}>
                  <Button2
                    size={40}
                    type="primary"
                    onClick={() =>
                      renderPage(`/onboarding/usertenant/${tenantId}/new`)
                    }
                    iconRight={<ControlPoint sx={{ fontSize: "24px" }} />}
                  >
                    Add
                  </Button2>
                </div>
                <Tooltip
                  title="Click here to assign user to this tenant"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "400px" }}>
                  <Search
                    placeholder="Search for users assinged to this tenant"
                    value={portfolioSearch}
                    onChange={(search) => searchHandler(search, "usertenant")}
                    onKeyUp={(search) => searchHandler(search, "usertenant")}
                  />
                </div>
                <br />
                <CustomTable
                  headerColor={"#858E99"}
                  tableType={`/usertenant/${tenantId}`}
                  module={"/onboarding"}
                  headerCells={userTenantViewHeaderCell}
                  data={userTenantFilteredData}
                  isDoubleClickActive={true}
                  maxHeight={"55vh"}
                  onDeleteClick={(id) => {
                    setActionType("UserTenant");
                    setSelectedId(id);
                    setOpenDeleteModal(true);
                  }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {createNewTenant ? (
              <></>
            ) : (
              <>
                <div className={classes.floatRight}>
                  <Button2
                    size={40}
                    type="primary"
                    onClick={() =>
                      renderPage(`/onboarding/team/${tenantId}/new`)
                    }
                    iconRight={<ControlPoint sx={{ fontSize: "24px" }} />}
                  >
                    Add
                  </Button2>
                </div>
                <Tooltip
                  title="Click here to add team"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "400px" }}>
                  <Search
                    placeholder="Search for team"
                    value={portfolioSearch}
                    onChange={(search) => searchHandler(search, "team")}
                    onKeyUp={(search) => searchHandler(search, "team")}
                  />
                </div>
                <br />
                <CustomTable
                  tableType={`/team/${tenantId}`}
                  module={"/onboarding"}
                  headerCells={teameViewHeaderCell}
                  data={teamFilteredData}
                  isDoubleClickActive={true}
                  maxHeight={450}
                  onDeleteClick={(id) => {
                    setActionType("team");
                    setSelectedId(id);
                    setOpenDeleteModal(true);
                  }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {createNewTenant ? (
              <></>
            ) : (
              <>
                <Button
                  color={"rgb(43, 41, 88)"}
                  type="button"
                  title={"Add"}
                  showIcon={true}
                  className={classes.floatRight}
                  onClick={() =>
                    renderPage(`/onboarding/roles/${tenantId}/new`)
                  }
                >
                  Add
                </Button>
                <Tooltip
                  title="Click here to add role"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "400px" }}>
                  <Search
                    placeholder="Search for role"
                    value={roleSearch}
                    onChange={(search) => searchHandler(search, "role")}
                    onKeyUp={(search) => searchHandler(search, "role")}
                  />
                </div>
                <br />
                <CustomTable
                  tableType={`/roles/${tenantId}`}
                  module={"/onboarding"}
                  headerCells={RoleViewHeaderCell}
                  data={roleFilteredData}
                  isDoubleClickActive={true}
                  maxHeight={450}
                  handleCheckboxClick={(resp, roleInfo) =>
                    handleCheckboxClick(resp, roleInfo)
                  }
                  onDeleteClick={(id) => {
                    setActionType("role");
                    setSelectedId(id);
                    setOpenDeleteModal(true);
                  }}
                />
              </>
            )}
          </TabPanel>
          <Divider />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div>
                <Button2
                  size={40}
                  type="tertiary"
                  onClick={() => {
                    let URI = "/onboarding/tenant";
                    renderPage(URI);
                  }}
                >
                  Back
                </Button2>
              </div>
              <div style={{ marginLeft: "12px" }}>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    formik.validateForm();
                    return formik.submitForm();
                  }}
                >
                  Save
                </Button2>
              </div>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  );
};
