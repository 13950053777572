import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
// analyticsDashboard
export interface IAnalyticsDashboardState {
    plantId?: [{
        label: string;
        value: string;
    }];
    portfolio: [{
        label: string;
        value: string;
    }];
}

const initialState: IAnalyticsDashboardState = {
    plantId: null,
    portfolio: null,
};

// name
// reducer
// actions
// caseReducers

export const analyticsDashboardSlice = createSlice({
    name: "analyticsDashboard",
    initialState,
    reducers: {
        setPlantId: (state, { payload }) => {
            return {
                ...state,
                plantId: payload,
            };
        },
        setPortfolio: (state, { payload }) => {
            return {
                ...state,
                portfolio: payload,
            };
        }
    },
});

// analyticsDashboard actions
export const {
    setPlantId,
    setPortfolio,
} = analyticsDashboardSlice.actions;

// analyticsDashboard state

export const analyticsDashboardSelector = (state: RootState) =>
    state.Analytics.analyticsDashboard;

// analyticsDashboard reducer

export const analyticsDashboardReducer = analyticsDashboardSlice.reducer;