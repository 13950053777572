export const weatherIcons = [
  "night",
  "night",
  "night",
  "night",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy-day-3",
  "cloudy-day-3",
  "day",
  "day",
  "cloudy-day-3",
  "cloudy-day-3",
  "day",
  "day",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy",
  "cloudy",
  "cloudy-night-3",
  "cloudy-night-3",
  "night",
  "night",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "rainy-6",
  "rainy-6",
  "cloudy-day-3",
  "cloudy-day-3",
  "day",
  "day",
  "day",
  "day",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "rainy-6",
  "rainy-6",
  "rainy-6",
  "rainy-6",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-night-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "cloudy-day-3",
  "rainy-6",
  "rainy-6",
  "cloudy-night-3",
  "cloudy-night-3",
];

export const categories = [
  "15 Feb, 00:00",
  "15 Feb, 01:00",
  "15 Feb, 02:00",
  "15 Feb, 03:00",
  "15 Feb, 04:00",
  "15 Feb, 05:00",
  "15 Feb, 06:00",
  "15 Feb, 07:00",
  "15 Feb, 08:00",
  "15 Feb, 09:00",
  "15 Feb, 10:00",
  "15 Feb, 11:00",
  "15 Feb, 12:00",
  "15 Feb, 13:00",
  "15 Feb, 14:00",
  "15 Feb, 15:00",
  "15 Feb, 16:00",
  "15 Feb, 17:00",
  "15 Feb, 18:00",
  "15 Feb, 19:00",
  "15 Feb, 20:00",
  "15 Feb, 21:00",
  "15 Feb, 22:00",
  "15 Feb, 23:00",
  "16 Feb, 00:00",
  "16 Feb, 01:00",
  "16 Feb, 02:00",
  "16 Feb, 03:00",
  "16 Feb, 04:00",
  "16 Feb, 05:00",
  "16 Feb, 06:00",
  "16 Feb, 07:00",
  "16 Feb, 08:00",
  "16 Feb, 09:00",
  "16 Feb, 10:00",
  "16 Feb, 11:00",
  "16 Feb, 12:00",
  "16 Feb, 13:00",
  "16 Feb, 14:00",
  "16 Feb, 15:00",
  "16 Feb, 16:00",
  "16 Feb, 17:00",
  "16 Feb, 18:00",
  "16 Feb, 19:00",
  "16 Feb, 20:00",
  "16 Feb, 21:00",
  "16 Feb, 22:00",
  "16 Feb, 23:00",
  "17 Feb, 00:00",
  "17 Feb, 01:00",
  "17 Feb, 02:00",
  "17 Feb, 03:00",
  "17 Feb, 04:00",
  "17 Feb, 05:00",
  "17 Feb, 06:00",
  "17 Feb, 07:00",
  "17 Feb, 08:00",
  "17 Feb, 09:00",
  "17 Feb, 10:00",
  "17 Feb, 11:00",
  "17 Feb, 12:00",
  "17 Feb, 13:00",
  "17 Feb, 14:00",
  "17 Feb, 15:00",
  "17 Feb, 16:00",
  "17 Feb, 17:00",
  "17 Feb, 18:00",
  "17 Feb, 19:00",
  "17 Feb, 20:00",
  "17 Feb, 21:00",
  "17 Feb, 22:00",
  "17 Feb, 23:00",
];

export const actualProduction = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.47, 27.12, 64.58, 145.23, 0.0, 0.0, 0.0,
];

export const powerMeasured = [
  0,
  0,
  0,
  0,
  0,
  0.47,
  27.12,
  64.58,
  145.23,
  237.95,
  253.74,
  { y: 275.11, marker: { enabled: true, radius: 4, fillColor: "#0C1E33" } },
];

export const powerExpected = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 25.1, 70.05, 135.87, 230.54, 279.11, 285.42,
  245.98, 250.2, 300.97, 301.05, 305.28, 250.34, 238.77, 140.88, 34.57, 7.44,
  0.54, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.49, 16.74, 43.06, 103.77, 260.91,
  287.25, 281.98, 286.01, 248.21, 188.7, 160.83, 86.7, 121.93, 40.72, 11.09,
  1.82, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 32.07, 70.76,
  109.91, 124.33, 163.24, 130.0, 124.84, 117.98, 181.86, 107.71, 57.8, 32.94,
  0.0, 0.0,
];

export const maximumArray = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 27.61, 77.06, 149.46, 253.59, 307.03, 313.96,
  270.58, 275.22, 331.07, 331.16, 335.81, 275.37, 262.65, 154.97, 38.03, 8.19,
  0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.64, 18.41, 47.36, 114.15, 287.0,
  315.98, 310.18, 314.61, 273.03, 207.57, 176.92, 95.37, 134.12, 44.8, 12.2,
  2.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 41.69, 91.99,
  142.88, 161.63, 212.21, 169.0, 162.3, 153.37, 236.42, 140.02, 75.15, 42.82,
  0.0, 0.0, 0.0,
];
export const minimumArray = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 22.59, 63.05, 122.28, 207.49, 251.2, 256.88,
  221.38, 225.18, 270.87, 270.95, 274.75, 225.31, 214.9, 126.79, 31.11, 6.7,
  0.49, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.34, 15.07, 38.75, 93.39, 234.81,
  258.53, 253.78, 257.41, 223.39, 169.83, 144.75, 78.03, 109.74, 36.65, 9.98,
  1.63, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 22.45, 49.53,
  76.94, 87.03, 114.27, 91.0, 87.39, 82.58, 127.3, 75.4, 40.46, 23.06, 0.0, 0.0,
  0.0,
];
