import React from "react";
import { useStyles } from "./styles";

export const CustomLegend = ({ legendData }) => {
    const classes = useStyles();
    return (
        <div>
            <ul className={classes.legendArea}>
                {legendData?.map((item) => (
                    <li className={classes.legendItem}>
                        <svg
                            className="recharts-surface"
                            width="14"
                            height="14"
                            viewBox="0 0 32 32"
                            version="1.1"
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginRight: "4px",
                                borderRadius: 4
                            }}
                            stroke={item.color}
                        >
                            <rect
                                x="0"
                                y="0"
                                width="30"
                                height="30"
                                style={{ fill: item.color }}
                            />
                        </svg>
                        <span style={{ color: "#0C1E33", fontWeight: 400 }}>
                            {item.name}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
