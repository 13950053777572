import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
// InverterPerformance
export interface IInverterPerformanceState {
    gettingPortfolios?: boolean;
    portfoliosFailed?: boolean;
    portfoliosSuccessful?: boolean;
    globalDate?: {
        startDate: Date;
        endDate: Date;
        key: string;
    }[];
    globalSelectedInverter?: any;
    globalPlantId?: number | string;
    dateEqual?: boolean;
}

const initialState: IInverterPerformanceState = {
    gettingPortfolios: false,
    portfoliosFailed: false,
    portfoliosSuccessful: false,

    globalDate: [{
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    }],
    globalSelectedInverter: null,
    globalPlantId: null,
    dateEqual: true,
};

// name
// reducer
// actions
// caseReducers

export const inverterPerformanceSlice = createSlice({
    name: "inverterperformance",
    initialState,
    reducers: {
        portfoliosStart: (state) => {
            return {
                ...state,
                gettingPortfolios: true,
            };
        },
        portfoliosSuccess: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosSuccessful: true,
            };
        },
        portfoliosError: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosFailed: true,
            };
        },
        setGlobalDate: (state, { payload }) => {
            return {
                ...state,
                globalDate: payload,
            };
        },
        setglobalSelectedInverter: (state, { payload }) => {
            return {
                ...state,
                globalSelectedInverter: payload,
            };
        },
        setglobalPlantId: (state, { payload }) => {
            return {
                ...state,
                globalPlantId: payload,
            };
        },
        dateComparison: (state, { payload }) => {
            return {
                ...state,
                dateEqual: payload,
            };
        },
    },
});

// InverterPerformance actions
export const {
    portfoliosStart,
    portfoliosSuccess,
    portfoliosError,
    setGlobalDate,
    setglobalSelectedInverter,
    setglobalPlantId,
    dateComparison,
} = inverterPerformanceSlice.actions;

// InverterPerformance state

export const inverterPerformanceSelector = (state: RootState) =>
    state.Monitoring.inverterPerformance;

// InverterPerformance reducer

export const inverterPerformanceReducer = inverterPerformanceSlice.reducer;