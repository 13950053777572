import { getBearerToken } from "../../../store/authToken";
import { axDelete, axGet, axPost } from "../../../store/axiosClient";

export function getRoleInfoRequest(roleId: string) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axGet(`OnboardingV2/v1/Roles/GetRoleById?roleId=${roleId}`, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function insertPermissionToRoleRequest(body: any) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axPost(`OnboardingV2/v1/PermissionsToRole/AddPermissionsForRole`, body, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function deletePermissionFromRoleRequest(body: any) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axDelete(`OnboardingV2/v1/PermissionsToRole/RemovePermissionsForRole`, body, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function getPermissionsForRoleRequest(roleId: string, moduleName: string) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axGet(`OnboardingV2/v1/PermissionsToRole/GetPermissionsForRoleAndModule?roleId=${roleId}&moduleName=${moduleName}`, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function getPermissionsByModuleNameRequest(moduleName: string) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axGet(`OnboardingV2/v1/Permissions/GetPermissionsByModuleName?moduleName=${moduleName}`, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};
