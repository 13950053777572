import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useStyles } from './styles';

interface Props {
    options: Array<{ label: string, value: string }>;
    onChange: any;
    value: string;
    disableUnderline?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
    showInputLabel?: boolean;
    endAdornment?: any;
    inputProps?: any;
    width?: string;
}

export const CustomiseDropDown = (props: Props) => {
    const { options, onChange, value, disableUnderline, variant, showInputLabel, endAdornment, inputProps, width } = props;

    const classes = useStyles();

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = options.find((option) => option.value === selectedValue);
        onChange(selectedOption);
    };

    return (
        <FormControl >
            {showInputLabel && <InputLabel id="demo-simple-select-label" style={{ marginTop: "-10px" }}>Module</InputLabel>}
            <Select

                inputProps={inputProps}
                disableUnderline={disableUnderline}
                variant={variant}
                label="Module"
                style={{ width: width || "150px", height: "35px" }}
                sx={{
                    '& .MuiSelect-select': {
                        fontSize: '16px',
                        color: '#000000',
                        fontWeight: 600,
                    },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || ""}
                onChange={handleSelectChange}
            >
                {options?.map
                    ((option) => (
                        <MenuItem key={option.value} value={option.value} style={{ fontSize: "15px", fontWeight: 500 }}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
