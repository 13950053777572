import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  loading: {},

  button40: {
    width: "85px",
    height: "40px",
    borderRadius: "6px",
    padding: "11px, 20px, 11px, 20px",
    color: "#0C1E33",
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "Inter",
  },

  button32: {
    width: "74px",
    height: "32px",
    borderRadius: "6px",
    border: "1px solid #E1E4E9",
    padding: "8px, 16px, 8px, 16px",
    color: "#0C1E33",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Inter",
  },

  buttonprimary: {
    Display: "flex",
    color: "#FFFFFF",
    fontSize: "15px",
    backgroundColor: "#0C1E33",
    "&:hover": {
      backgroundColor: "#1572DF",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#E1E4E9",
    },
  },

  iconOnlyprimary40: {
    width: "40px",
    height: "40px",
    padding: "11px",
    backgroundColor: "#0C1E33",
    "&:hover": {
      backgroundColor: "#1572DF",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#E1E4E9",
    },
  },
  iconOnlyprimary32: {
    width: "32px",
    height: "32px",
    padding: "8px",
    backgroundColor: "#0C1E33",
    "&:hover": {
      backgroundColor: "#1572DF",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#E1E4E9",
    },
  },
  iconOnlysecondary40: {
    width: "40px",
    height: "40px",
    padding: "11px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#EEF1F6",
      // color: "red",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
      border: "1px solid #CACFD7",
    },
  },
  iconOnlysecondary32: {
    width: "32px",
    height: "32px",
    padding: "8px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#EEF1F6",
      // color: "red",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
      border: "1px solid #CACFD7",
    },
  },
  iconOnlytertiary32: {
    width: "32px",
    height: "32px",
    padding: "8px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#EEF1F6",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
    },
  },
  iconOnlytertiary40: {
    width: "40px",
    height: "40px",
    padding: "11px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#EEF1F6",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
    },
  },

  buttonsecondary: {
    border: "1px solid  #E1E4E9",
    color: "#0C1E33",
    fontSize: "15px",
    backgroundColor: "#FFFFFF",

    "&:hover": {
      backgroundColor: "#EEF1F6",
      // color: "red",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
      border: "1px solid #CACFD7",
    },
  },

  buttontertiary: {
    color: "#0C1E33",
    fontSize: "15px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#EEF1F6",
    },
    "&:focus": {
      outline: "1px solid #0C1E33",
    },
    "&:disabled": {
      backgroundColor: "#EEF1F6",
    },
  },
  iconLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
