import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "../styles";
import { UseModuleTenantSave } from "./useModuleTenantSave";
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete } from '../../components/Utils/utils'
import { formSchemaMpduleTenant } from "./metaData";
import { DynamicField } from '../../components/shared/DynamicFields/DynamicField';
import { Snackbar } from './../../../../shared/components/Snackbar'

export const ModuleTenantSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { moduleTenantId } = useParams<{ moduleTenantId: string }>();
  const { Subsidiary, upsertTenant, module } = UseModuleTenantSave();

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const createNewModuleTenant = moduleTenantId === "new";

  useEffect(() => {
    if (module)
      formSchemaMpduleTenant.ModulId.options = module;
  }, [module]);

  useEffect(() => {
    initializeValidationsObsolete(formSchemaMpduleTenant, (resp) => {
      setValidationSchema(resp);
    });
  }, [module]);

  useEffect(() => {
    if (moduleTenantId || createNewModuleTenant) {
      initializeValuesObsolete(formSchemaMpduleTenant, createNewModuleTenant, Subsidiary, (resp) => {
        setInitialValues(resp);
      });
    }
  }, [Subsidiary]);


  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.TenantId = tenantId;
    fields.ModuleId = fields.ModulId;
    upsertTenant(fields, moduleTenantId, (resp) => {
      if (resp?.ok == false) {
        setSeverity("error");
        setMessage("Operation unsuccessfull");
        setIsOpen(true)
      } else {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true)
      }

      setTimeout(() => {
        let URI = `/onboarding/tenant/${tenantId}`;
        renderPage(URI);
      }, 2000);

    })

  }

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />

      <div className={classes.pageLabel}>
        {createNewModuleTenant ? (
          <>Add new module tenant </>
        ) : (
          <>Edit module tenant</>
        )}
      </div>


      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {
              Object.keys(formSchemaMpduleTenant).map(x => <DynamicField name={x} data={formSchemaMpduleTenant[x]} formik={formik} />)
            }
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save module tenant"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Cancel"
              onCancel={() => {
                let URI = `/onboarding/tenant/${tenantId}`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
