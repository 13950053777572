import React from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    helpWrapper: {
        display: "flex",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "16px",
        margin: "16px",
        height: "calc(100vh - 84px)",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    heading: {
        padding: "10px 0 6px 0",
        fontSize: "20px",
    },
    subHeading: {
        width: "250px",
        margin: "0 auto",
        color: "#858E99",
    },
    logoutLink: {
        textDecoration: "none",
        color: "#1572DF",
        padding: "16px 0 0 0",
        margin: "0 auto",
        width: "fit-content",
        borderRadius: "0px",
        transition: "all 0.05s",
        position: "relative",
        borderBottom: "2px solid transparent",
        "&:hover": {
            borderBottom: "2px solid #1572df",
        }
    }
}));

export const Error404Page = () => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.helpWrapper}>
                <div>
                    <img alt="Skyfri logo with error" src="/icons/skyfri-logo-error.svg" />
                </div>
                <div className={classes.heading}>You didn't break the internet, but we can't find what you are looking for.</div>
            </div>
        </div>
    );
};
