import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import { EnergyChart } from "./components/EnergyChart/EnergyChart.component";

export const EnergyWidget = () => {
  return (
    <Box
      style={{
        marginTop: "20px",
        width: "100%",
        border: "1px solid #CACFD7",
        borderRadius: "8px",
        height: "460px",
      }}
    >
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={12}>
          <EnergyChart />
        </Grid>
      </Grid>
    </Box>
  );
};
