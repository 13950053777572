import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import { getUnitForWMS } from "./utils";

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const values = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>Time: {values.sensor_time} </strong>
      </div>
      <div>
        {
          payload.map(item => (
            <div key={item.dataKey}>
              {
                item.strokeOpacity === 0 ?
                  null
                  :
                  `${item.dataKey} : ${item.value?.toFixed(1)} ${getUnitForWMS(item.dataKey)}`
              }
            </div>
          ))
        }
      </div>
    </TooltipWrapper>
  );
};
