import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    padding: 30,
  },

  pageLabel: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
  },

  formElementsWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  form: {
    flex: 1,
    flexDirection: "column",
    minWidth: 300,
    maxWidth: 500,
  },

  inputWrapper: {
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },

  subLabel: {
    fontWeight: 400,
  },
  m: {
    margin: "5px",
  },
}));
