import React, { useState, useEffect } from "react";
import { UseModule } from "./useModule";
import { Card } from "@material-ui/core";
import Loader from "../../components/shared/Loader";
import { Divider } from "@mui/material";
import {
  Search,
  MenuWithBtn,
  IMenuItem,
  ConfirmationModal,
} from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import { searchData } from "./../components/Utils/utils";
import { Snackbar } from "./../../../shared/components/Snackbar";
import { Button2 } from "../../../shared/components/Button2/Button2.component";

import { useStyles } from "./styles";
import {
  Modal,
  Button,
  Table,
  ActionButtons,
} from "../../../shared/components";
import {
  moduleViewHeaderCell,
  moduleViewTableType,
  filters,
} from "./components/interfaces";
import { useNavigate } from "react-router-dom";

export const Module: React.FC = () => {
  const classes = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { tenants, module, deleteModule, loading } = UseModule();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");

  useEffect(() => {
    if (!module) return;
    if (search && search != "") {
      setFilteredData(searchData(search, module, filters));
    } else {
      setFilteredData(module);
    }
  }, [search, module]);

  const deleteClick = (id) => {
    setSelectedId(id);
    setOpenDeleteModal(true);
  };

  const onNewUser = () => {
    navigate(`/onboarding/module/new`);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>Modules</div>

      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />

      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2 size={40} type="tertiary" onClick={onCloseDeleteModal}>
                  Cancel
                </Button2>
              </div>
              <div>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    deleteModule(selectedId, (resp) => {
                      if (resp) {
                        setSeverity("success");
                        setMessage("Record deleted successfully");
                        setIsOpen(true);
                      } else {
                        setSeverity("error");
                        setMessage("Operation  unsuccessful");
                        setIsOpen(true);
                      }
                    });
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
            {/* <ActionButtons
              saveTitle={"Delete"}
              saveButtonType="submit"
              onSave={() => {
                deleteModule(selectedId, (resp) => {

                  if (resp) {
                    setSeverity("success")
                    setMessage("Record deleted successfully");
                    setIsOpen(true);
                  } else {
                    setSeverity("error")
                    setMessage("Operation  unsuccessful");
                    setIsOpen(true);
                  }

                });
                setOpenDeleteModal(false);
              }}
              cancelTitle="Cancel"
              onCancel={onCloseDeleteModal}
            /> */}
          </>
        }
      />

      <Card style={{ padding: "20px" }}>
        <MenuWithBtn
          menuItems={[]}
          active={undefined}
          title={"Module"}
          btnTitle={"New module"}
          onBtnClick={() => onNewUser()}
          onSelectItem={(item: IMenuItem): void => { }}
        />
        <br />
        {loading ? (
          <>
            <CustomTable
              tableType={moduleViewTableType}
              headerCells={moduleViewHeaderCell}
              data={[]}
              onDeleteClick={(id) => deleteClick(id)}
            />
            <Loader
              loading={loading}
              height={300}
              error={""}
              errorTextSize={32}
            />
          </>
        ) : (
          <>
            <div style={{ maxWidth: "400px" }}>
              <Search
                placeholder="Search for module"
                value={search}
                onChange={searchHandler}
                onKeyUp={searchHandler}
              />
            </div>
            <br />
            <CustomTable
              maxHeight={400}
              tableType={moduleViewTableType}
              headerCells={moduleViewHeaderCell}
              data={filteredData}
              onDeleteClick={(id) => deleteClick(id)}
            />
          </>
        )}
      </Card>
    </div>
  );
};
