import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  portfolioId: string;
  plantId: string;
  tenantId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  plant: any;
  oldPlantCode?: string;
  oldPlantTimeZone?: string;
  oldPlantName?: string;
}

export const UsePlantSave = ({ portfolioId, plantId }: Props): Data => {

  const [plant, setPlant] = useState(null);
  const [oldPlantCode, setOldPlantCode] = useState("");
  const [oldPlantName, setOldPlantName] = useState("");
  const [oldPlantTimeZone, setOldPlantTimeZone] = useState("");

  useEffect(() => {
    if (plantId == 'new') return;
    getPlant();
  }, [])

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";
    if (id && id != "new") {
      params.plantId = id;
      requestType = "PUT";
    }
    await fetchAsync(`onboardingV2/v1/Plant/AddPlant`, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };


  const getPlant = async () => {
    await fetchAsync(`onboardingV2/v1/Plant/GetPlants?portfolioId=${portfolioId}`, "GET")
      .then((data: any) => {
        if (data) {
          let currentPlant = data.find(x => x.plantId == plantId);
          setOldPlantCode(currentPlant.plantCode)
          setOldPlantName(currentPlant.plantName)
          setOldPlantTimeZone(currentPlant.plantTimeZone)
          setPlant(currentPlant);
        }
      })
      .catch((e) => { console.error(e); });
  };

  return {
    upsert,
    plant,
    oldPlantCode,
    oldPlantName,
    oldPlantTimeZone
  };
};
