import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { getPageItem } from "../../shared/utils/pages";
import { MenuItem, PageItem, Roles } from "../../shared/interfaces";
import { MonitoringNavigation } from "./MonitoringNavigation.component";
import { CrossEquipmentComparison } from "./CrossEquipmentComparison";
import { ReactComponent as MONITORING_LIGHT } from "../../shared/icons/sidebar/light/MONITORING_LIGHT.svg";
import { ReactComponent as MONITORING_BLACK } from "../../shared/icons/sidebar/dark/MONITORING_BLACK.svg";
import { MonitoringDashboard } from "./MonitoringDashboard";
import { MonitoringHeaderProvider } from "./MonitoringHeaderContext";
import { MonitoringPageView } from "./MonitoringPageView";
import { InverterPerformance } from "./InverterPerformance";
import { MonitoringGeneration } from "./MonitoringGeneration";
import { BatteryMonitoring } from "./BatteryMonitoring";
import { BatteryDetails } from "./BatteryDetails";

export enum MonitoringPagesEnum {
  MonitoringDashboard = "/monitoring/my-assets",
  MonitoringGeneration = "/monitoring/monitoring-generation",
  CrossEquipmentComparison = "/monitoring/data-explorer",
  MonitoringPageView = "/monitoring/my-assets/plant",
  MonitoringInverterPerformance = "/monitoring/monitoring-inverter-performance/plant",
  BatteryMonitoring = "/monitoring/monitoring-dashboard/battery-monitoring",
  BatteryDetails = "/monitoring/monitoring-dashboard/battery-monitoring/details",
}

type MonitoringPagesMap = {
  [key in MonitoringPagesEnum]?: PageItem;
};

const getPages = (BatteryMonitoringRole: boolean): MonitoringPagesMap => {
  const pages = {
    [MonitoringPagesEnum.MonitoringDashboard]: getPageItem(
      MonitoringPagesEnum.MonitoringDashboard,
      MonitoringDashboard,
      ""
    ),
    [MonitoringPagesEnum.MonitoringGeneration]: getPageItem(
      MonitoringPagesEnum.MonitoringGeneration,
      MonitoringGeneration,
      ""
    ),
    [MonitoringPagesEnum.CrossEquipmentComparison]: getPageItem(
      MonitoringPagesEnum.CrossEquipmentComparison,
      CrossEquipmentComparison,
      ""
    ),
    [MonitoringPagesEnum.MonitoringPageView]: getPageItem(
      MonitoringPagesEnum.MonitoringPageView,
      MonitoringPageView,
      ""
    ),
    [MonitoringPagesEnum.MonitoringInverterPerformance]: getPageItem(
      MonitoringPagesEnum.MonitoringInverterPerformance,
      InverterPerformance,
      ""
    ),
  };

  if (BatteryMonitoringRole) {
    (pages[MonitoringPagesEnum.BatteryMonitoring] = getPageItem(
      MonitoringPagesEnum.BatteryMonitoring,
      BatteryMonitoring,
      ""
    )),
      (pages[MonitoringPagesEnum.BatteryDetails] = getPageItem(
        MonitoringPagesEnum.BatteryDetails,
        BatteryDetails,
        ""
      ));
  }

  return pages;
};

export const MonitoringPages: React.FC<any> = ({
  tenant,
  SkyfriDemoRole,
  BatteryMonitoringRole,
}) => {
  const pages: MonitoringPagesMap = useMemo(() => {
    return getPages(BatteryMonitoringRole);
  }, [BatteryMonitoringRole]);

  return (
    <>
      <MonitoringHeaderProvider>
        <Routes>
          {Object.values(pages).map(
            ({ path, component: Component, params }) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    <Component
                      {...params}
                      SkyfriDemoRole={SkyfriDemoRole}
                      BatteryMonitoringRole={BatteryMonitoringRole}
                      tenant={tenant}
                    />
                  </>
                }
              />
            )
          )}
        </Routes>
      </MonitoringHeaderProvider>
    </>
  );
};

export const getMonitoringMenuItem = (): MenuItem => {
  const items = [
    {
      path: MonitoringPagesEnum.MonitoringDashboard,
      component: MonitoringDashboard,
      title: "My Assets",
      params: {},
    },
    {
      path: MonitoringPagesEnum.MonitoringGeneration,
      component: MonitoringGeneration,
      title: "Generation",
      params: {},
    },
    {
      path: MonitoringPagesEnum.CrossEquipmentComparison,
      component: CrossEquipmentComparison,
      title: "Data Explorer",
      params: {},
    },
    {
      path: MonitoringPagesEnum.MonitoringInverterPerformance,
      component: InverterPerformance,
      title: "Inverter Performance",
      params: {},
    },
  ];

  return {
    idx: Roles.MONITORING,
    title: "MONITORING",
    Icon: MONITORING_LIGHT,
    DarkIcon: MONITORING_BLACK,
    navigation: <MonitoringNavigation />,
    items,
  };
};
