import React, { useMemo } from "react";
import { CustomCombinedChart } from "../../../../components/CustomCombinedChart";
import { useBatteryControlChartWidget } from "./useBatteryControlChartWidget";

interface Props {
  height: number;
}

export const BatteryControlChartWidget: React.FC<Props> = (props: Props) => {
  const {
    plantId,
    startDate,
    endDate,
    liveButton,
    globalDate,
    buttonGroups,
    chartOptions,
  } = useBatteryControlChartWidget();

  const { height } = props;

  const _chartOptions = useMemo(() => {
    if (!chartOptions || !chartOptions.chart) {
      return chartOptions;
    }
    const updatedOptions = { ...chartOptions };

    updatedOptions.chart.height = `${height}px`;
    return updatedOptions;
  }, [chartOptions, height]);

  return (
    <CustomCombinedChart
      dynamicSeries={true}
      menu={{
        dropdown: null,
        buttonGroups: buttonGroups,
      }}
      chartOptions={_chartOptions}
    />
  );
};
