import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "../Button";
import { appTheme } from "../../../app/theme";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    borderTop: "1px solid lightgray",
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  actionButtonsWithPaddingBottom: {
    borderTop: "1px solid lightgray",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  actionButtonsWithoutBorder: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  actionButtonsWithoutBorderNoSpacing: {
    display: "flex",
    alignItems: "center",
  },
  discardButton: {
    marginLeft: "49px !important",
    paddingLeft: "39px !important",
    paddingRight: "37px !important"
  },
  saveButton: {
    marginLeft: "15px !important"
  }
}));

interface ModalProps {
  saveTitle: string;
  saveButtonType?: "button" | "reset" | "submit";
  saveButtonColor?: string;
  onSave: (id: any) => void;
  cancelTitle: string;
  cancelButtonType?: "button" | "reset" | "submit";
  cancelButtonColor?: string;
  hideCancelButton?: boolean;
  onCancel: () => void;
  startIcon?: any;
  justifyContent?: any;
  marginLeft?: any;
  onDiscard?: any;
  discardTitle?: any;
  styleDiscardButton?: boolean;
  styleSaveButton?: boolean;
  borderTop?: boolean;
  marginTop?: any;
  actionButtonsWithPaddingBottom?: boolean;
  actionButtonsWithoutBorderNoSpacing?: boolean;
}

export const ActionButtons: React.FC<ModalProps> = (props) => {
  const {
    saveTitle,
    onSave,
    saveButtonColor = appTheme.main,
    cancelTitle,
    onCancel,
    cancelButtonColor = appTheme.main,
    saveButtonType = "button",
    cancelButtonType = "button",
    startIcon,
    justifyContent,
    marginLeft,
    marginTop,
    onDiscard,
    discardTitle,
    styleDiscardButton,
    styleSaveButton,
    borderTop = true,
    actionButtonsWithPaddingBottom,
    actionButtonsWithoutBorderNoSpacing = false,
  } = props;
  const classes = useStyles();

  return (
    <div className={borderTop ? actionButtonsWithPaddingBottom ? classes.actionButtonsWithPaddingBottom : classes.actionButtons : actionButtonsWithoutBorderNoSpacing ? classes.actionButtonsWithoutBorderNoSpacing : classes.actionButtonsWithoutBorder} style={{ justifyContent: justifyContent ? justifyContent : 'flex-start', marginLeft: marginLeft, marginTop: marginTop || 'theme.spacing(3)' }} >
      {props?.hideCancelButton ? null : (
        <Button
          title={cancelTitle}
          outlined
          noBorder
          color={cancelButtonColor}
          onClick={onCancel}
          type={cancelButtonType}
        />
      )}
      <div style={{ marginLeft: 16 }} />
      {discardTitle && <Button
        className={clsx({
          [classes.discardButton]: styleDiscardButton,
        })}
        startIcon={startIcon}
        title={discardTitle}
        color={saveButtonColor}
        onClick={onDiscard}
        type={saveButtonType}
      />}
      <Button
        className={clsx({
          [classes.saveButton]: styleSaveButton,
        })}
        startIcon={startIcon}
        title={saveTitle}
        color={saveButtonColor}
        onClick={onSave}
        type={saveButtonType}
      />

    </div>
  );
};
