import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    margin: 0,
    padding: theme.spacing(2),
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },

  widgets: {
    display: "grid",
    minWidth: "540px",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  selector: {
    borderRadius: 5,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },

  select: {
    width: "200px",
    "&::before": {
      display: "none",
    },

    "&::after": {
      display: "none",
    },
  },
  label: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#707070",
    marginBottom: theme.spacing(0.5),
  },
}));
