import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  widgetWrapper: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    gridColumnGap: theme.spacing(3),
    height: "100%",
    maxHeight: "390px",
    padding: theme.spacing(1),
  },

  fieldsWrapper: {
    display: "grid",
    gridTemplateRows: "1fr 4fr",
  },

  chartWrapper: {
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    overflow: "hidden",
  },

  itemTitle: {
    textAlign: "center",
    fontSize: 16,
    padding: theme.spacing(1),
  },

  menuLabel: {
    fontSize: 14,
    color: "#70BEAB",
  },

  negativeLabel: {
    fontSize: 14,
    color: "#9F9F9F",
  },
}));
