import { useSelector } from "react-redux";
import {
  autoRefreshApi,
  convertTimeZone,
  get24Hours,
} from "./../../../../../shared/utils/utils";
import { useEffect, useState } from "react";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { fetchAsync } from "../../../../../shared/utils/apiService";

export enum Values {
  ActualGeneration = "Actual Generation",
  TheoreticalGeneration = "Theoretical Generation",
  ActualIrradiation = "Actual Irradiation",
}

interface Item {
  actualEnergy: number;
  actualIrradiance: number;
  clientName: string;
  plantId: string;
  theoreticalEnergy: number;
  timeStamp: string;
}

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: Array<any>;
  error: string;
  loading: boolean;
}

export const useExpectedGeneration = ({
  plantId,
  date,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }
    setLoading(true);

    const initialData = new Array(24).fill(null).reduce((res, item, idx) => {
      const time = get24Hours(client?.timeZone, "HH:mm", idx);
      res[time] = { time };
      return res;
    }, {});

    const map = data?.reduce((res, curr) => {
      const timeLabel = convertTimeZone(
        client?.timeZone,
        "HH:mm",
        curr.timeStamp
      );
      if (!res[timeLabel]) return res;
      res[timeLabel] = {
        ...res[timeLabel],
        [Values.ActualGeneration]: curr.actualEnergy,
        [Values.TheoreticalGeneration]: curr.theoreticalEnergy,
        [Values.ActualIrradiation]: curr.actualIrradiance,
      };
      return res;
    }, initialData);

    const sortedData = Object.keys(map)
      ?.sort()
      ?.map((item) => map[item]);

    setData(sortedData);
    setLoading(false);
  };

  const getTheoreticalEnergyGraphData = () => {
    fetchAsync(`SkyfriSidemApi/v1/GetTheoreticalEnergyGraph?`, "GET", {
      PlantId: plantId,
      Date: date,
    })
      .then((data: Array<Item>) => {
        setError("");
        transformData(data);
      })
      .catch(setError);
  };
  useEffect(() => {
    if (!client || !date) {
      setLoading(true);
      return;
    }
    setLoading(true);
    getTheoreticalEnergyGraphData();

    const myInterval = autoRefreshApi(() => getTheoreticalEnergyGraphData());
    return () => clearInterval(myInterval);
  }, [client, plantId, date]);

  return {
    data,
    error,
    loading,
  };
};
