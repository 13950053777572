import React from "react";
import clsx from "clsx";
import { Skeleton } from "@mui/material";
import { ReactComponent as CHECKED } from "../../../shared/icons/app/CHECKED.svg";
import { ReactComponent as ACTIVE_ELLIPSES } from "../../../shared/icons/app/ACTIVE_ELLIPSES.svg";
import { ReactComponent as SELF_RESOLVED } from "../../../shared/icons/app/SELF_RESOLVED.svg";
import { ReactComponent as SIDEMENU } from "../../../shared/icons/app/SIDEMENU.svg";
import { ReactComponent as ACKNOWLEDGE } from "../../../shared/icons/app/ACKNOWLEDGE.svg";
import { ReactComponent as MESSAGE } from "../../../shared/icons/app/MESSAGE.svg";
import { ReactComponent as ERROR } from "../../../shared/icons/app/ERROR.svg";
import { CustomPortfolioIcon } from "../../../shared/icons/dropdown/PORTFOLIO_BLACK.component";
import { ReactComponent as FILTERALARMS } from "../../../shared/icons/app/FILTERALARMS.svg";
import { CustomPlantIcon } from "../../../shared/icons/dropdown/PLANT_BLACK.component";
import { useNavigate } from "react-router-dom";
import { useNotificationsList } from "./useNotificationsList";
import { Grid } from "@mui/material";
import { Button } from "../../../shared/components";
import { MultipleTimeZone } from "../../../shared/components/TimeZone/MultipleTimeZone.component";
import { NotificationsHeader } from "./NotificationsHeader.component";
import { Divider } from "@material-ui/core";
import { timeAgo, statusMenu, typeMenu, sortMenu } from "./utils";
import { EmptyAlarms } from "./EmptyAlarms.component";
import { useStyles } from "./styles";

export const NotificationsList: React.FC<any> = ({
  alarms,
  setAlarms,
  close,
  isMobile,
  loading,
  setLoading,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const {
    multipleTimeZone,
    selectedPlant,
    selectedPortfolio,
    setSelectedPlant,
    portfolio,
    plant,
    onChangePortfolio,
    loadingAlarms,
    setLoadingAlarms,
    filteredAlarms,
    setFilteredAlarms,
    setOpenFilterMenu,
    openFilterMenu,
    filterMenuList,
    setFilterMenuList,
    selectedStatus,
    setSelectedStatus,
    selectedTypes,
    setSelectedTypes,
    selectedSortOrder,
    setSelectedSortOrder,
    portfoliosAndPlantsData,
  } = useNotificationsList({ alarms, setAlarms });

  const renderAlarmView = () => {
    close();
    navigate("/sidem/alarm-overview");
  };

  const renderSkeleton = () => {
    return (
      <>
        <Grid
          id="list"
          container
          spacing={0}
          style={{
            width: "450px !important",
          }}
        >
          {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]?.map((item, idx) => (
            <div>
              <Grid container sx={{ width: "100%", padding: "16px" }} key={idx}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={72}
                    height={14}
                    style={{ marginBottom: 10, borderRadius: 4 }}
                  />
                  <Grid container spacing={1} className={classes.comment}>
                    <Skeleton
                      variant="rectangular"
                      width={72}
                      height={14}
                      style={{ marginBottom: 10, borderRadius: 4 }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "0px" }}>
                  <Skeleton
                    width={420}
                    height={40}
                    style={{ borderRadius: 4 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.alarmWrapper}
                  style={{ marginTop: "7px" }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={172}
                    height={14}
                    style={{ borderRadius: 4 }}
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "0 0" }} />
            </div>
          ))}
        </Grid>
      </>
    );
  };

  const getPortfolio = (id) => {
    // const plant = selectedPlant?.find((item) => item?.value === id);
    const plant = portfoliosAndPlantsData?.find((item) => item?.plantId === id);
    return plant?.portfolioName;
  };
  const getPlant = (id) => {
    const plant = portfoliosAndPlantsData?.find((item) => item?.plantId === id);
    // const plant = selectedPlant?.find((item) => item?.value === id);
    // return plant?.label;
    return plant?.plantName;
  };

  const renderAlarms = () => {
    return (
      <Grid
        id="list"
        container
        spacing={0}
        style={{
          width: "450px !important",
        }}
      >
        {filteredAlarms?.map((item: any, idx: any) => (
          <div>
            <Grid
              container
              sx={{
                width: "100%",
                padding: "16px",
                backgroundColor: !item?.isActive ? "#F0F8F4" : "white",
              }}
              key={idx}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {item?.isActive ? (
                  <Grid container spacing={2} className={classes.status}>
                    <Grid item style={{ marginLeft: "4px" }}>
                      <ACTIVE_ELLIPSES />
                    </Grid>
                    <Grid item style={{ marginLeft: "-11px" }}>
                      <span className={classes.statusLabel}>Active</span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} className={classes.status}>
                    <Grid item style={{ marginLeft: "0px" }}>
                      <SELF_RESOLVED />
                    </Grid>
                    <Grid
                      item
                      style={{ marginLeft: "-12px", marginBottom: "5px" }}
                    >
                      <span className={classes.resolvedLabel}>
                        Self-resolved
                      </span>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={2} className={classes.comment}>
                  {/* <Grid item>
                      <MESSAGE />
                    </Grid>
                    <Grid item>
                      <ACKNOWLEDGE />
                    </Grid>
                    <Grid item>
                      <SIDEMENU />
                    </Grid> */}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: "0px", marginLeft: "0px" }}
              >
                <Grid container spacing={1} className={classes.alarm}>
                  <Grid item>
                    <ERROR />
                  </Grid>
                  <Grid item className={classes.alarmLabel}>
                    <span className={classes.displayName}>
                      {item?.displayName}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.alarmWrapper}
                style={{ marginTop: "4px" }}
              >
                <Grid container spacing={0} className={classes.alarmWrapper}>
                  <Grid
                    item
                    className={classes.alarmDescription}
                    style={{ marginLeft: "25px" }}
                  >
                    {item?.description}
                  </Grid>
                  <Grid item className={classes.alarmTime}>
                    {timeAgo(item?.createdOn)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <Grid container spacing={1} className={classes.alarm}>
                  <Grid item>
                    <CustomPortfolioIcon height={14} width={14} />
                  </Grid>
                  <Grid item className={classes.portfoliosAndPlantsLabel}>
                    {getPortfolio(item?.plantId)}
                  </Grid>
                  <Grid item>
                    <CustomPlantIcon height={14} width={14} />
                  </Grid>
                  <Grid item className={classes.portfoliosAndPlantsLabel}>
                    {getPlant(item?.plantId)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ margin: "0 0" }} />
          </div>
        ))}
      </Grid>
    );
  };

  const renderContent = () => {
    if (loading || loadingAlarms) return renderSkeleton();
    else if (filteredAlarms?.length > 0) return renderAlarms();
    else return <EmptyAlarms />;
  };

  const handleFilterOption = () => {
    setOpenFilterMenu((prev) => !prev);
  };

  // const handleOptionClick = async (categoryId, optionId) => {
  //   setFilterMenuList((prevFilterMenu) => {
  //     return prevFilterMenu.map((category) => {
  //       if (category.categoryId === categoryId) {
  //         return {
  //           ...category,
  //           options: category.options.map((option) => ({
  //             ...option,
  //             checked: option.id === optionId ? !option.checked : false,
  //           })),
  //         };
  //       }
  //       return category;
  //     });
  //   });
  // };

  const handleStatusClick = (id: any) => {
    setSelectedStatus(id === selectedStatus ? null : id);
  };

  const handleTypeClick = (id: any) => {
    setSelectedTypes(id === selectedTypes ? null : id);
  };
  const handleSortOrderClick = (id: any) => {
    setSelectedSortOrder(id === selectedSortOrder ? null : id);
  };

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.wrapperMobile]: isMobile,
      })}
      style={{ backgroundColor: "white", position: "relative" }}
    >
      <Grid container id="header" style={{ padding: "16px" }}>
        <Grid item xs={12} className={classes.heading}>
          <div>Pending alarms</div>
          <div className={classes.filter}>
            <span onClick={handleFilterOption}>
              <FILTERALARMS />
            </span>

            {openFilterMenu && (
              <div
                style={{
                  right: isMobile && "28px",
                  position: "absolute",
                  top: "22px",
                  left: !isMobile && "28px",
                  width: "197px",
                  height: isMobile ? "420px" : "389px",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  zIndex: 100,
                }}
              >
                <Grid container spacing={0} style={{ marginBottom: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    className={classes.filterLabel}
                    style={{ marginBottom: "-10px" }}
                  >
                    Statuses
                  </Grid>
                  {statusMenu?.map((item, idx) => (
                    <Grid
                      className={classes.filterOption}
                      container
                      spacing={0}
                      key={item?.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "7px",
                      }}
                      onClick={() => handleStatusClick(item?.id)}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexDirection: "row",
                        }}
                      >
                        {selectedStatus === item?.id && <CHECKED />}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {item?.label}
                      </Grid>
                    </Grid>
                    // </Grid>
                  ))}
                </Grid>
                <Divider style={{ margin: "10 0" }} />
                <Grid container spacing={0} style={{ marginBottom: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    className={classes.filterLabel}
                    style={{ marginBottom: "-10px" }}
                  >
                    Types
                  </Grid>
                  {typeMenu?.map((item, idx) => (
                    <Grid
                      className={classes.filterOption}
                      container
                      spacing={0}
                      key={item?.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "7px",
                      }}
                      onClick={() => handleTypeClick(item?.id)}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexDirection: "row",
                        }}
                      >
                        {selectedTypes === item?.id && <CHECKED />}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {item?.label}
                      </Grid>
                    </Grid>
                    // </Grid>
                  ))}
                </Grid>
                <Divider style={{ margin: "10 0" }} />
                <Grid container spacing={0} style={{ marginBottom: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    className={classes.filterLabel}
                    style={{ marginBottom: "-10px" }}
                  >
                    Sort order
                  </Grid>
                  {sortMenu?.map((item, idx) => (
                    <Grid
                      className={classes.filterOption}
                      container
                      spacing={0}
                      key={item?.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "7px",
                      }}
                      onClick={() => handleSortOrderClick(item?.id)}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexDirection: "row",
                        }}
                      >
                        {selectedSortOrder === item?.id && <CHECKED />}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {item.label}
                      </Grid>
                    </Grid>
                    // </Grid>
                  ))}
                </Grid>

                {/* {filterMenuList?.map((category, idx) => (
                  <Grid
                    container
                    spacing={0}
                    key={category?.categoryId}
                    style={{
                      borderBottom:
                        idx < filterMenuList.length - 1 && "1px solid #E1E4E9",
                    }}
                  >
                    <Grid item xs={12} className={classes.filterLabel}>
                      {category?.category}
                    </Grid>
                    {category?.options?.map((option) => (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          key={option?.id}
                          onClick={() =>
                            handleOptionClick(category?.categoryId, option?.id)
                          }
                        >
                          <Grid
                            container
                            spacing={0}
                            style={{
                              padding: "6px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={2}
                              display={"flex"}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              {option?.checked && <CHECKED />}
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              display={"flex"}
                              className={classes.filterOption}
                            >
                              {option?.label}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                ))} */}
              </div>
            )}
          </div>
        </Grid>
        {/* <Grid item xs={12} className={classes.portfoliosAndPlants}>
          {loading ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="rectangular"
                width={420}
                height={26}
                style={{ marginBottom: 10 }}
              />
            </div>
          ) : (
            <NotificationsHeader
              multipleTimeZone={multipleTimeZone}
              selectedPlant={selectedPlant}
              selectedPortfolio={selectedPortfolio}
              setSelectedPlant={setSelectedPlant}
              portfolio={portfolio}
              plant={plant}
              onChangePortfolio={onChangePortfolio}
            />
          )}
        </Grid> */}

        {/* <Grid item xs={12} className={classes.timeZone}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={420}
              height={18}
            />
          ) : (
            multipleTimeZone && <MultipleTimeZone />
          )}
        </Grid> */}
      </Grid>
      <Divider style={{ margin: "0 0" }} />
      <div
        style={{
          height: isMobile ? "auto" : "calc(100vh - 60px)",
          // height: isMobile ? "auto" : "calc(100vh - 270px)",
          overflowY: "auto",
          display: filteredAlarms?.length === 0 ? "flex" : "",
          alignItems: filteredAlarms?.length === 0 ? "center" : "",
        }}
      >
        {renderContent()}
      </div>
      {/* 
      <Grid
        style={{
          width: "450px",
          height: "20px",
          borderTop: "1px solid  #E1E4E9",
        }}
      > */}
      {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            // marginTop: "5px",
          }}
        >
          <Button
            title="Alarm overview"
            color="#0C1E33"
            onClick={() => renderAlarmView()}
            className={classes.button}
          />
        </div> */}
      {/* </Grid> */}
    </div>
  );
};
