import { AlertItem } from "../../../features/Sidem/shared/sidemSharedApi.types";

enum AlertsLevels {
  Information = 0,
  Warning = 1,
  Error = 2,
  Critical = 3,
}

interface Alarm {
  alarmCategoryCode: number;
  alarmCategoryName: string;
  alarmLevel: number;
  createdOn: string;
  description: string;
  displayName: string;
  isActive: boolean;
  modifiedOn: string;
  nodeId: string;
  plantId: string; //number;
}

const alertsColor = {
  [AlertsLevels.Information]: 'rgba(121, 190, 170, 1)', // green
  [AlertsLevels.Warning]: 'rgba(254, 174, 0, 1)', // orange
  [AlertsLevels.Error]: 'rgba(128, 128, 128, 1)', // grey
  [AlertsLevels.Critical]: 'rgba(222, 80, 79, 1)', // red
};

export const getAlertColor = (alarm: AlertItem, isNonActive = false): string =>
  isNonActive ? alertsColor[AlertsLevels?.Information] : alertsColor[alarm.alarmLevel];

const alertsColorForAlarms = {
  [AlertsLevels.Information]: 'rgba(121, 190, 170, 0.2)', // green
  [AlertsLevels.Warning]: 'rgba(254, 174, 0, 0.2)', // orange
  [AlertsLevels.Error]: 'rgba(128, 128, 128, 0.2)', // grey
  [AlertsLevels.Critical]: 'rgba(222, 80, 79, 0.2)', // red
};

export const getAlertColorForAlarms = (alarm: Alarm, isNonActive = false): string =>
  isNonActive ? alertsColorForAlarms[AlertsLevels?.Information] : alertsColorForAlarms[alarm.alarmLevel];  
