import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  tenantId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  team: any;
}

export const UseRoleTeamSave = ({
  tenantId,
}: Props): Data => {
  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (!tenantId) return;
    getTeam();
  }, [tenantId]);

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";
    params.tenantId = tenantId;
    if (id && id !== "new") {
      params.plantId = id;
      requestType = "PUT";
    }

    await fetchAsync(`/TeamRole/addteam`, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  const getTeam = async () => {
    await fetchAsync(`onboardingV2/v1/Teams/GetTeamsByTenantId?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newTeam = data?.map((item) => ({
            ...item,
            value: item.teamId,
            label: item.teamName,
          }));

          setTeam(newTeam);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return {
    upsert,
    team,
  };
};
