
export const barChartData = [
  {date: '2022-02-28T17:01:00', label: '4.14', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '5.15', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '5.17', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '10.38', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '5.18', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '8.30', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '10.36', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '9.34', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '8.29', value: 0.23},
  {date: '2022-02-28T17:01:00', label: '3.10', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '8.28', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '4.11', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '4.13', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '4.12', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '5.16', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '9.32', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '9.33', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '10.37', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '10.35', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '9.31', value: 0.23},
   {date: '2022-02-28T17:01:00', label: '2.6', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '3.9', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '2.4', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '1.2', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '8.27', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '1.1', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '2.3', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '2.5', value: 0.24},
   {date: '2022-02-28T17:01:00', label: '3.8', value: 0.24},
];

export const lineChartData = [
  {label: '21/2', value: 0.05},
  {label: '22/2', value: 0.05},
  {label: '23/2', value: 0.05},
  {label: '24/2', value: 0.04},
  {label: '25/2', value: 0.05},
  {label: '26/2', value: 0.05},
  {label: '27/2', value: 0.06},
];
