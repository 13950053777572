import React from "react";
import { Card } from "@material-ui/core";
import { MenuWithBtn, IMenuItem, } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import { useStyles } from "./styles";
import { userTenantViewHeaderCell, userTenantViewTableType } from './components/interfaces'
import { useNavigate } from "react-router-dom";

export const UserTenant: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onNewUser = () => {
    navigate(`/onboarding/usertenant/new`);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>User tenant</div>

      <Card style={{ padding: "20px" }}>
        <MenuWithBtn
          menuItems={[]}
          active={undefined}
          title={"User tenant"}
          btnTitle={"New user tenant"}
          onBtnClick={() => onNewUser()}
          onSelectItem={(item: IMenuItem): void => {
          }}
        />
        <br />
        <CustomTable
          tableType={userTenantViewTableType}
          headerCells={userTenantViewHeaderCell}
          data={null}
          onDeleteClick={(id) => { }}
        />
      </Card>

    </div>
  );
};

