import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({ active, payload, format }: { active?: boolean, payload?: Array<any>, format: any }) => {
  if (!active)
    return null;

  const { name, label } = payload[0]?.payload;

  const value = label;
  const isBigger = Math.abs(value) > 1000;
  const displayValue = isBigger ? (value / 1000) : value;
  const formatLabel = isBigger ? 'MWh' : 'KWh';

  return (
    <TooltipWrapper>
      <div>
        <strong>{name}: </strong> {parseFloat(displayValue)?.toFixed(2)}{" "}
        {formatLabel}{" "}
      </div>
    </TooltipWrapper>
  );
};
