import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Divider } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { useStyles } from "../styles";
import { useRoleEdit } from "./useRoleEdit";
import { Snackbar } from "../../../components/shared/Snackbar";
import { Snackbar as Toastr } from "../../../../shared/components/Snackbar";
import { Dropdown, Typography } from "../../../../shared/components";
import { Checkbox as CustomCheck } from "../../../../shared/components";
import { Button2 } from "../../../../shared/components/Button2/Button2.component";

interface Props {
  type: string;
  callback: any;
}

export const RoleEditPage: React.FC<Props> = ({
  type,
  callback,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { roleId } = useParams<{ roleId: string }>();

  const {
    getRoleInfo,
    getPermissionsForRoleAndModule,
    getPermissionsByModuleName,
    addPermission,
    removePermission,
  } = useRoleEdit();

  const moduleNameOptions = [
    { label: "ANALYTICS", value: "analytics" },
    { label: "OPERATIONS", value: "operations" },
    { label: "BILLING", value: "billing" },
    { label: "COMMERCIAL", value: "commercial" },
    { label: "SkyfriAdmin", value: "SkyfriAdmin" },
    { label: "MONITORING", value: "monitoring" },
    { label: "LOBBYSCREEN", value: "lobbyscreen" },
  ];

  const [roleInfo, setRoleinfo] = useState({
    displayName: "",
    description: "",
  });
  const [moduleName, setModuleName] = useState(null);
  const [modulePermissions, setModulePermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [toastrSeverity, setToastrSeverity] = React.useState("error");
  const [isToastrOpen, setIsToastrOpen] = React.useState(false);
  const [toastrMessage, setToastrMessage] = React.useState("");

  const createNewUser = roleId === "new";

  useEffect(() => {
    getRoleInfo(roleId, setRoleinfo);
  }, [roleId]);

  useEffect(() => {
    if (moduleName) {
      getPermissionsByModuleName(moduleName, setModulePermissions);
      getPermissionsForRoleAndModule(roleId, moduleName, setRolePermissions);
    }
  }, [roleId, moduleName]);

  const onSubmitHandler = () => {
    setTimeout(() => {
      navigate("/onboarding/role");
    }, 1000);
  };
  const onModuleChangeHandler = (val: string) => {
    setModuleName(val);
  };
  const isChecked = (permissionId: string) => {
    const permission = rolePermissions.find(
      (permission) => permission.permissionId === permissionId
    );
    return permission ? true : false;
  };

  const handleCheck = (permissionId: string) => {
    let permission = rolePermissions.find((permission) => {
      if (permission.permissionId === permissionId) {
        return permission.permissionToRoleId;
      }
      return null;
    });

    if (permission) {
      removePermission(permission.permissionToRoleId, (resp) => {
        if (resp.status !== 200) {
          setToastrSeverity("error");
          setToastrMessage("Could not save in database");
          setIsToastrOpen(true);

          setTimeout(() => {
            navigate("/onboarding/role");
          }, 2000);
          return;
        } else {
          setToastrSeverity("info");
          setToastrMessage("Permission removed from the role");
          setIsToastrOpen(true);
          getPermissionsForRoleAndModule(
            roleId,
            moduleName,
            setRolePermissions
          );
        }
      });
    } else {
      addPermission(permissionId, roleId, (resp) => {
        if (resp.status !== 200) {
          setToastrSeverity("error");
          setToastrMessage("Could not save in database");
          setIsToastrOpen(true);

          setTimeout(() => {
            navigate("/onboarding/role");
          }, 2000);
          return;
        } else {
          setToastrSeverity("info");
          setToastrMessage("Permission added to the role");
          setIsToastrOpen(true);
          getPermissionsForRoleAndModule(
            roleId,
            moduleName,
            setRolePermissions
          );
        }
      });
    }
  };

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        type={type}
        redirectURI="/onboarding/role"
        handleClose={() => setIsOpen(false)}
        callback={() => callback()}
      />
      <Toastr
        severity={toastrSeverity}
        open={isToastrOpen}
        message={toastrMessage}
        handleClose={() => setIsToastrOpen(false)}
      />
      {type === "modal" ? (
        <></>
      ) : (
        <div className={classes.pageLabel}>
          {createNewUser ? (
            <>Add new role </>
          ) : (
            <>Edit permissions for role: {roleInfo.displayName} </>
          )}
        </div>
      )}
      <Card style={{ padding: "20px" }}>
        <h3>Role description: {roleInfo.description}</h3>
        <br></br>

        <Typography label="Choose module name">
          <Dropdown
            value={moduleName}
            options={moduleNameOptions}
            onChangeValue={onModuleChangeHandler}
          />
        </Typography>
        <Grid item container direction="row" spacing={2}>
          {modulePermissions.map((modulePermission, index) => (
            <Grid
              key={`${modulePermission.permissionName + index}`}
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <CustomCheck
                key={modulePermission.permissionId}
                checked={isChecked(modulePermission.permissionId)}
                label={modulePermission.permissionName}
                onChange={() => handleCheck(modulePermission.permissionId)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ pointerEvents: isOpen ? "none" : "auto" }}
        >
          <Grid item xs={12}>
            <Divider style={{ marginTop: "20px" }} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "4px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <Button2
                size={40}
                type={"tertiary"}
                onClick={() => {
                  if (type === "modal") {
                    callback();
                  }
                  let URI = "/onboarding/role";
                  renderPage(URI);
                }}
              >
                Back
              </Button2>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <Button2
                size={40}
                type={"primary"}
                onClick={() => {
                  onSubmitHandler();
                }}
              >
                Done
              </Button2>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
