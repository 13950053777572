import { combineReducers } from "@reduxjs/toolkit";
import { crossEquimentComparisonReducer } from './crossEquimentComparison/crossEquimentComparisonSlice'
import { monitoringDashboardReducer } from './monitoringDashboard/monitoringDashboardSlice';
import { inverterPerformanceReducer } from "./inverterPerformance/inverterPerformanceSlice";
import { monitoringGenerationReducer } from "./monitoringGeneration/monitoringGenerationSlice";
import { monitoringSharedReducer } from "./shared/monitoringSharedSlice";

const MonitoringReducer = combineReducers({
    crossEquimentComparison: crossEquimentComparisonReducer,
    MonitoringDashboard: monitoringDashboardReducer,
    inverterPerformance: inverterPerformanceReducer,
    MonitoringGeneration: monitoringGenerationReducer,
    MonitoringShared: monitoringSharedReducer,
});

export type AppState = ReturnType<typeof MonitoringReducer>;

export default MonitoringReducer;
