import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "../Input";
import { InputAdornment } from "@mui/material";

interface Props {
  value: string;
  placeholder?: string;
  onChange: (val: string) => void;
  onKeyUp?: (val: string) => void;
  width?: number | string;
  minWidth?: number | string;
}

export const Search: React.FC<Props> = ({
  placeholder,
  onChange,
  onKeyUp,
  width,
  minWidth,
}) => (
  <Input
    placeholder={placeholder}
    width={width}
    minWidth={minWidth}
    icon={
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    }
    onChange={onChange}
    onKeyUp={onKeyUp}
  />
);
