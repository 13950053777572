import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Cell,
  Tooltip,
  YAxis,
  Label,
} from "recharts";
import { GRAPH_COLORS } from "../../../shared/utils/graphColors";

export interface SimpleBarChartItem {
  label: string;
  value: number;
  expectedValue?: number;
}

interface SimpleBarChartProps {
  data: Array<SimpleBarChartItem>;
  normalLevel: number;
  formatLabel: string;
  maxHeight?: number;
  tooltipContent?: any;
  xAxis?: boolean;
  yAxis?: boolean;
  normal?: boolean;
  customContent?: React.ReactNode;
  onClick?: (item: SimpleBarChartItem) => void;
  ticks?: number[];
  domain?: number[] | any;
  showLabel?: boolean;
  label?: string;
  showCustomYAxis?: boolean;
}

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: any };
  format?: string;
}

const CustomizedAxisTick = ({ x, y, payload }: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-45)"
    >
      {payload.value}
    </text>
  </g>
);

const CustomizedYAxis = ({
  x,
  y,
  payload,
  format,
}: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    {
      format.length ?
        <text y={15} fontSize={12} textAnchor="end" fill="#666">
          {format}
        </text>
        :
        <text y={0} fontSize={12} textAnchor="end" fill="#666">
          {payload?.value > 1
            ? parseInt(payload.value) + `%`
            : payload.value?.toFixed(2)}
        </text>
    }
  </g>
);

export const SimpleBarChart: React.FC<SimpleBarChartProps> = ({
  data,
  normalLevel,
  onClick = null,
  xAxis = true,
  yAxis = false,
  customContent = null,
  normal = false,
  formatLabel,
  tooltipContent,
  maxHeight = 100,
  ticks,
  domain,
  showLabel,
  label,
  showCustomYAxis = true
}: SimpleBarChartProps) => (
  <ResponsiveContainer width="100%" height={`${maxHeight}%`}>
    <BarChart width={150} height={40} data={data}>
      {tooltipContent && <Tooltip content={tooltipContent} wrapperStyle={{ outline: "none" }} />}
      {customContent}
      {xAxis && (
        <XAxis dataKey="label" height={50} tick={<CustomizedAxisTick />} />
      )}
      {yAxis && (
        <YAxis
          ticks={ticks && ticks}
          domain={domain && domain}
          tick={showCustomYAxis ?
            <CustomizedYAxis format={formatLabel} />
            :
            { fill: "rgb(63 63 63)" }
          }
          key={label}
        >
          {showLabel ? (
            <Label
              position={'outside'}
              dx={-13}
              style={{
                fontSize: "100%",
                fill: "rgb(63 63 63)",
              }}
              angle={-90}
              value={label}
            />
          ) : null}
        </YAxis>
      )}
      <Bar dataKey="value" fill="#70BEAB" height={100}>
        {data.map((item, index) => (
          <Cell
            key={index}
            onClick={(e) => typeof onClick === "function" && onClick(item)}
            fill={
              (!item.expectedValue && item.value >= normalLevel) ||
                item?.value >= item.expectedValue
                ? GRAPH_COLORS.success
                : GRAPH_COLORS.error
            }
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);
