import React from "react";
import { Card } from "@material-ui/core";
import { MenuWithBtn, IMenuItem } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import { useStyles } from "./styles";

import { plantViewHeaderCell, plantViewTableType } from './components/interfaces'
import { useNavigate } from "react-router-dom";

export const Plant: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onNewUser = () => {
    navigate(`/onboarding/plant/new`);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>Plant</div>

      <Card style={{ padding: "20px" }}>
        <MenuWithBtn
          menuItems={[]}
          active={undefined}
          title={"Module"}
          btnTitle={"New plant"}
          onBtnClick={() => onNewUser()}
          onSelectItem={(item: IMenuItem): void => {
          }}
        />
        <br />
        <CustomTable
          tableType={plantViewTableType}
          headerCells={plantViewHeaderCell}
          data={null}
          onDeleteClick={(id) => { }}
        />
      </Card>

    </div>
  );
};

