import React from "react";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import { Values } from "./useExpectedGeneration";

const getValue = (label: string): string => {
  const value = parseFloat(label);
  const isBigger = Math.abs(value) > 1000;
  const displayValue = isBigger ? (value / 1000) : value;
  const formatLabel = isBigger ? 'MW' : 'KW';
  return displayValue?.toFixed(1) + ' ' + formatLabel;
}

export const CustomTooltip = ({ active, payload }: { active?: boolean, payload?: Array<any> }) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const data = payload[0]?.payload || {};

  return (
    <TooltipWrapper>
      <div>
        <strong>
          Time: {data?.time}
        </strong>
      </div>

      <div>{Values.ActualGeneration}: {getValue(data[Values.ActualGeneration])}</div>
      <div>{Values.TheoreticalGeneration}: {getValue(data[Values.TheoreticalGeneration])}</div>
      <div>{Values.ActualIrradiation}: {data[Values.ActualIrradiation]?.toFixed(1)} W/m2</div>
    </TooltipWrapper>
  )
};
