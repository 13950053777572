import { LineChart, Line, ResponsiveContainer, Tooltip } from 'recharts';

export interface SimpleLineChartItem {
  name: string;
  [key: string]: any;
}

interface SimpleLineChartProps {
  label: string;
  data: Array<SimpleLineChartItem>;
  tooltipContent?: any;
}

export const SimpleLineChart = ({
  data,
  label,
  tooltipContent,
}: SimpleLineChartProps) => (
  <ResponsiveContainer width="100%" height="100%" minHeight="30px">
    <LineChart width={300} height={60} data={data}>
      {tooltipContent && <Tooltip content={tooltipContent} wrapperStyle={{ outline: "none" }} />}
      <Line type="monotone" dataKey={label} stroke="#015E94" strokeWidth={2} />
    </LineChart>
  </ResponsiveContainer>
);
