import React from 'react';
import Radio from '@mui/material/Radio';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(4)
  },

  label: {
    marginLeft: theme.spacing(0),
    
  }
}));

interface Props {
  checked: boolean;
  value: any;
  onChange: (val: string) => void;
  label?: string;
  customLabel?: React.ReactNode;
  default?:any;
}

export const RadioButton: React.FC<Props> = (props) => {
  const { checked, value, onChange, label, customLabel } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Radio
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        value={value}
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
      />

      {customLabel || <div className={classes.label}>{label || ''}</div>}
    </div>
  );
};
