import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { TextField, InputAdornment, Badge, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ListItemIcon, ListSubheader } from "@material-ui/core";
import { useStyles } from "./styles";
import { DropdownOption } from "../Dropdown";
import { CustomizedCheckbox } from "../CustomCheckBox";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIcon from "@mui/material/SvgIcon";

interface Props {
  value?: any;
  options: Array<DropdownOption>;
  width?: number | string;
  Icon?: any;
  label: string;
  selected?: any;
  setSelected: any;
  marginLeft?: string;
  variant?: "standard" | "outlined" | "filled";
  padding?: string;
  preSelected?: boolean;
  preSelectedValue?: any;
}

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;

export const MultiSelectorDropdown: React.FC<Props> = ({
  options,
  label,
  width,
  Icon = null,
  selected,
  setSelected,
  marginLeft,
  variant,
  padding,
  preSelected,
  preSelectedValue,
}) => {
  const [searchText, setSearchText] = React.useState("");

  const classes = useStyles();

  React.useEffect(() => {
    if (preSelectedValue) {
      const filteredOptions = options.filter((option) =>
        preSelectedValue?.includes(option.value)
      );
      setSelected(filteredOptions);
    } else {
      setSelected(options);
    }
  }, [options, preSelectedValue]);

  const renderValues = (selected) => {
    if (selected?.length > 0) {
      if (isAllSelected)
        return (
          <span
            className={padding ? null : classes.paddingRight}
            style={padding ? { padding: padding } : null}
          >
            {" "}
            All {label}
          </span>
        );
      else if (selected.length === 1)
        return (
          <span
            className={padding ? null : classes.paddingRight}
            style={padding ? { padding: padding } : null}
          >
            {selected?.map((item) => item.label)}
          </span>
        );
      else
        return (
          <span
            className={padding ? null : classes.paddingRight}
            style={padding ? { padding: padding } : null}
          >
            {" "}
            Multiple {label}
          </span>
        );
    }
    if (selected?.length === 0)
      return (
        <span
          className={padding ? null : classes.paddingRight}
          style={padding ? { padding: padding } : null}
        >
          {" "}
          Select {label}
        </span>
      );
  };

  const isAllSelected =
    options.length > 0 && selected?.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(
        selected?.length === options.length ? [] : options
      ); /* options.map(item => item.value) */
      return;
    }
    setSelected(value);
  };

  const containsText = (text, searchText) =>
    text.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = React.useMemo(
    () => options?.filter((option) => containsText(option, searchText)),
    [searchText, options]
  );
  return (
    <FormControl sx={{ display: "flex", minWidth: width ? width : 140 }}>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <Select
          style={{ marginLeft: marginLeft || 0 }}
          className={classes.select}
          multiple
          placeholder={label}
          displayEmpty
          value={selected}
          onChange={handleChange}
          renderValue={(selected) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {!Icon ? null : (
                  <SvgIcon color="primary" className={classes.svgIcon}>
                    <Icon className={classes.icon} />
                  </SvgIcon>
                )}
                {renderValues(selected)}
              </Box>
            );
          }}
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
              },
            },
            autoFocus: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            disablePortal: true,
          }}
          sx={{ cursor: "pointer", fontWeight: 500 }}
          disableUnderline={true}
          variant={variant || "standard"}
          inputProps={{
            disableUnderline: true,
          }}
          onClose={() => setSearchText("")}
        >
          <ListSubheader className={classes.subHeader}>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents auto selecting item while typing (default Select behavior)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem
            value="all"
            key={"all"}
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <CustomizedCheckbox checked={isAllSelected} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select all"
            />
          </MenuItem>
          <Divider
            light
            sx={{ marginBottom: "0px!important", marginTop: "0px!important" }}
          />
          {displayedOptions.map((item, i) => (
            <MenuItem
              key={i}
              value={item as any}
              sx={{
                backgroundColor: "rgba(0,0,0,0)!important",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05 )!important",
                },
              }}
            >
              <CustomizedCheckbox
                checked={selected
                  ?.map((item) => item.value)
                  .includes(item.value)}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        <Badge
          badgeContent={selected?.length}
          classes={{ badge: classes.customBadge }}
        ></Badge>
      </div>
    </FormControl>
  );
};
