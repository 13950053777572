import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { DateRange, SelectorDropdown } from '../../../../../shared/components'
import { useStyles } from "./styles";
import { ReactComponent as PLANT_BLACK } from '../../../../../shared/icons/dropdown/PLANT_BLACK.svg';
import { CustomPortfolioIcon } from '../../../../../shared/icons/dropdown/PORTFOLIO_BLACK.component';



interface Props {
    selectedPortfolio: string;
    portfolio: Array<any>;
    setSelectedPortfolio: any;
    selectedPlant: string;
    plant: Array<any>;
    setSelectedPlant: any;
    aggregation: any;
    setAggregation: any;
    aggregationTypes: any;
    date: any;
    setDate: any;
    onChangePlant: (val: string | number) => void;
    onChangePortfolio: (val: string | number) => void;
}

export const HeaderWidget: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {
        selectedPortfolio,
        setAggregation,
        aggregation,
        portfolio,
        setSelectedPortfolio,
        selectedPlant,
        plant,
        setSelectedPlant,
        aggregationTypes,
        date,
        setDate,
        onChangePlant,
        onChangePortfolio,
    } = props;

    const onChangeAggregation = (e) => {
        const newValue = e.target.value;
        setAggregation(newValue);
    };
    const onChangeDate = (item) => {
        setDate([item.selection])
    };

    const getPortfolioIcon = props => {
        return <CustomPortfolioIcon height={22} width={22} />;
    }

    return (
        <div className={classes.wrapper}>
            <Grid
                container
                spacing={1}
                alignItems={"center"}>
                <Grid item xs={6}>
                    <FormControl className={classes.selector}>
                        <SelectorDropdown
                            Icon={getPortfolioIcon}
                            value={selectedPortfolio}
                            options={portfolio}
                            onChangeValue={(filterValue: string): void =>
                                onChangePortfolio(filterValue)
                            }
                        />
                    </FormControl>
                    <FormControl className={classes.selector}>
                        <SelectorDropdown
                            Icon={PLANT_BLACK}
                            value={selectedPlant}
                            options={plant}
                            onChangeValue={(filterValue: string): void => {
                                onChangePlant(filterValue)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item justifyContent="flex-end" xs={6}>
                    <Grid
                        container
                        justifyContent="flex-end"
                    >
                        <div className={classes.selector}>
                            <FormControl sx={{ m: 1, }} size="small">
                                <InputLabel id="demo-simple-select-label">Aggregation</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    sx={{ width: "150px", maxWidth: "150px" }}
                                    displayEmpty
                                    variant={"outlined"}
                                    label="Aggregation"
                                    value={aggregation}
                                    className={classes.select}
                                    onChange={onChangeAggregation}
                                >
                                    {aggregationTypes?.map((item, idx) => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.selector}>
                            <DateRange date={date} onChange={onChangeDate} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}
