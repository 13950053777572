import React, { useState } from "react";
import { Grid, ListItem } from "@material-ui/core";
import { ListItemText } from "@mui/material";
import { Checkbox } from "../../../../../shared/components";
import { List } from "../../../../../shared/components/List";
import { useStyles } from "../../styles";

interface ParameterProps {
  addToSheet: any;
  params: any;
  setParams: any;
  getButton: (title: string, handleClick: any) => JSX.Element;
}
export const ParameterContainer: React.FC<any> = ({
  addToSheet,
  getButton,
  params,
  setParams,
}: ParameterProps) => {
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const handleChangeParams = (i: any, e: any, name: any) => {
    let newFormValues = [...params];
    newFormValues[i]["value"] = e;
    setParams(newFormValues);
  };
  const handleChangeUnits = (pid: any, cid: any, e: any, name: string) => {
    let newFormValues = [...params];
    newFormValues[pid].units[cid]["value"] = e;
    setParams(newFormValues);
  };
  const handleSelectAll = () => {
    setAllSelected(!allSelected);
    const selected = !allSelected;
    const selectParams = params.map((param) => {
      return {
        ...param,
        value: selected ? true : false,
      };
    });
    setParams(selectParams);
  };
  const classes = useStyles();
  return (
    <div>
      <div className={classes.paramList}>
        <List>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={7} md={8} sm={8}>
                <ListItemText primary="Parameters" />
              </Grid>
              <Grid item xs={5} md={4} sm={4}>
                <ListItemText primary="Select Unit" />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Checkbox
              checked={allSelected}
              label={"Select All"}
              onChange={handleSelectAll}
            />
          </ListItem>
          {params?.length > 0 &&
            params.map((item: any, key: any) => {
              return (
                <ListItem key={key}>
                  <Grid container spacing={2}>
                    <Grid item xs={7} md={8} sm={8}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox
                          checked={item.value}
                          label={item.title}
                          onChange={(e) =>
                            handleChangeParams(key, e, item.title)
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={5} md={4} sm={4}>
                      {item.units.length > 0 &&
                        item.units.map((unit, unitIndex) => (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            key={unitIndex}
                          >
                            <Checkbox
                              checked={unit.value}
                              label={unit.title}
                              onChange={(e) =>
                                handleChangeUnits(key, unitIndex, e, unit.title)
                              }
                            />
                          </div>
                        ))}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </List>
      </div>
      <div style={{ textAlign: "end", marginTop: "1em" }}>
        {getButton("Add to template", () => addToSheet(params, "param"))}
      </div>
    </div>
  );
};
