import React from 'react'
import {
  Bar,
  ComposedChart,
  XAxis,
  ResponsiveContainer,
  Line,
  Tooltip,
  YAxis,
  LabelList,
  Legend,
} from "recharts";

import {
  BarAndLineComposedChartProps,
  CustomizedAxisTickProps,
} from "./BarAndLineComposedChart.interfaces";

const CustomizedAxisTick = ({ x, y, payload }: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="middle"
      fill="#666"
      transform="rotate(-0)"
    >
      {payload.value}
    </text>
  </g>
);

const CustomizedYAxis = ({
  x,
  y,
  payload,
  format,
}: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text y={0} fontSize={12} textAnchor="end" fill="#666">
      {payload?.value > 1 ? parseInt(payload.value) : payload.value?.toFixed(2)}
    </text>
    <text y={15} fontSize={12} textAnchor="end" fill="#666">
      {format}
    </text>
  </g>
);

export const BarAndLineComposedChart: React.FC<BarAndLineComposedChartProps> = ({
  data,
  normalLevel,
  formatLabel,
  tooltipContent,
  maxHeight = 100,
  strokeWidth = 2,
  name = '',
  yAxises = [
    { yAxisId: "axis", orientation: "left" },
    { yAxisId: "axis", orientation: "left" },
  ],
}: BarAndLineComposedChartProps) => (
  <ResponsiveContainer width="100%" height={`${maxHeight}%`}>
    <ComposedChart data={data}>
      <Tooltip content={tooltipContent} />
      <XAxis dataKey="label" height={50} tick={<CustomizedAxisTick />} />
      <Legend />
      <YAxis
        tick={<CustomizedYAxis format={formatLabel} />}
        yAxisId="barAxis"
        type="number"
        dataKey="barValue"
        orientation="left"
        unit="kWh"
        stroke="#8884d8"
        domain={[0, (dataMax) => dataMax * 1.1]}
        hide
      />
      <YAxis
        tick={<CustomizedYAxis format={formatLabel} />}
        yAxisId="lineAxis"
        type="number"
        dataKey="lineValue"
        unit="kWh/m²"
        orientation="right"
        stroke="#82ca9d"
        domain={[0, "auto"]} //correct max value according to correct values (dataMax + {something})
        hide
      />

      <Bar
        name={name}
        dataKey="barValue"
        fill="#015E94"
        yAxisId="barAxis"
      >
        <LabelList dataKey="barValue" position="top" fill={"#707070"} />
      </Bar>
      <Line
        yAxisId="lineAxis"
        type="monotone"
        dataKey="lineValue"
        stroke="#e3823a"
        strokeWidth={strokeWidth}
        name="Irradiation (KWh/ m2)"
      />
    </ComposedChart>
  </ResponsiveContainer>
);
