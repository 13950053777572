import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Divider } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { useStyles } from "./../styles";
import { UsePortfolioSave } from "./usePortfolioSave";
import {
  GetFormik,
  initializeValidationsObsolete,
  initializeValuesObsolete,
} from "./../../components/Utils/utils";
import { portfolioformSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { CustomTable } from "../../../../shared/components/Table/Table.component";
import { Search, Button, Modal, } from "../../../../shared/components";
import { plantViewHeaderCell, plantViewTableType, plantFilters } from "./interfaces";
import Loader from "../../components/shared/Loader";
import { searchData } from "./../../components/Utils/utils";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { Box, Tab, Tabs } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { selectSelectedModule } from "../../../../features/Onboarding/portfolios/portfolioSlice";
import { Button2 } from "../../../../shared/components/Button2/Button2.component";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight: "45vh" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const PortfolioSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { selectedModule } = useSelector(selectSelectedModule);
  const { moduleId } = useParams<{ moduleId: string }>();

  const { upsert, portfolio, Plant, deletePlant, loading } = UsePortfolioSave({
    portfolioId,
    tenantId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = React.useState(0);

  const createNewPortfolio = portfolioId === "new";

  useEffect(() => {
    initializeValidationsObsolete(portfolioformSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (!Plant) return;
    if (search && search !== "") {
      setFilteredData(searchData(search, Plant, plantFilters));
    } else {
      setFilteredData(Plant);
    }
  }, [search, Plant]);

  useEffect(() => {
    if (portfolio || createNewPortfolio) {
      initializeValuesObsolete(
        portfolioformSchema,
        createNewPortfolio,
        portfolio,
        (resp) => {
          setInitialValues(resp);
        }
      );
    }
  }, [portfolio]);

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.TenantId = tenantId;
    if (createNewPortfolio) {
      upsert(
        fields,
        portfolioId,
        (resp) => {
          let URI = `/onboarding/portfolio/${tenantId}/${moduleId}/${resp?.portfolioId}`;
          renderPage(URI);
        },
        selectedModule,
        moduleId
      );
      setSeverity("success");
      setMessage("Record added successfully");
    } else {
      upsert(
        fields,
        portfolioId,
        () => {
          let URI = `/onboarding/tenant/${tenantId}?index=1`;
          renderPage(URI);
        },
        null,
        null
      );
      setSeverity("success");
      setMessage("Record added successfully");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const deleteClick = (id) => {
    setSelectedId(id);
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewPortfolio ? <>Add new portfolio </> : <>Edit portfolio</>}
      </div>

      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2 size={40} type="tertiary" onClick={onCloseDeleteModal}>
                  Cancel
                </Button2>
              </div>
              <div style={{ marginLeft: "8px" }}>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    deletePlant(selectedId, (resp) => {
                      if (resp?.ok === false) {
                        setSeverity("error");
                        setMessage("Operation unsuccessfull");
                        setIsOpen(true);
                      } else {
                        setSeverity("success");
                        setMessage("Record deleted successfully");
                        setIsOpen(true);
                      }
                    });
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
          </>
        }
      />
      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(portfolioformSchema).map((x) => (
              <DynamicField
                name={x}
                data={portfolioformSchema[x]}
                formik={formik}
              />
            ))}
          </div>
        </form>

        <>
          {createNewPortfolio ? (
            <> </>
          ) : (
            <>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  backgroundColor: "white",
                }}
              >
                <Tabs value={value} onChange={handleChange} aria-label="">
                  <Tab
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span> Plant</span>
                        <Tooltip
                          title="Plants assigned to this portfolio"
                          className={`${classes.floatRight}`}
                        >
                          <IconButton color="primary" component="span">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    }
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Button
                  color={"rgb(43, 41, 88)"}
                  type="button"
                  title={"Add plant"}
                  showIcon={true}
                  className={classes.floatRight}
                  onClick={() =>
                    renderPage(
                      `/onboarding/plant/${tenantId}/${moduleId}/${portfolioId}/new`
                    )
                  }
                >
                  Add
                </Button>
                <Tooltip
                  title="Click here to add plant"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                {loading ? (
                  <>
                    <CustomTable
                      tableType={plantViewTableType}
                      headerCells={plantViewHeaderCell}
                      data={[]}
                      onDeleteClick={(id) => deleteClick(id)}
                    />
                    <Loader
                      loading={loading}
                      height={450}
                      error={""}
                      errorTextSize={32}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ maxWidth: "400px" }}>
                      <Search
                        placeholder="Search for plant"
                        value={search}
                        onChange={searchHandler}
                        onKeyUp={searchHandler}
                      />
                    </div>
                    <br />
                    <CustomTable
                      tableType={`/${plantViewTableType}/${tenantId}/${moduleId}/${portfolioId}`}
                      module="/onboarding"
                      maxHeight={450}
                      headerCells={plantViewHeaderCell}
                      data={filteredData}
                      onDeleteClick={(id) => deleteClick(id)}
                    />
                  </>
                )}
              </TabPanel>
            </>
          )}
        </>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px" }} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <Button2
                size={40}
                type="tertiary"
                onClick={() => {
                  let URI = `/onboarding/tenant/${tenantId}?index=1`;
                  renderPage(URI);
                }}
              >
                Back
              </Button2>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <Button2
                size={40}
                type="primary"
                onClick={() => {
                  formik.validateForm();
                  return formik.submitForm();
                }}
              >
                Save
              </Button2>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
