import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface CustomizedLabelProps {
  x?: number;
  y?: number;
  stroke?: string;
  value?: any;
}

const CustomizedLabel = ({ x, y, stroke, value }: CustomizedLabelProps) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: any };
}

const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
}: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-15)"
    >
      {payload.value}
    </text>
  </g>
);

export interface IYAxis {
  yAxisId: string;
  orientation: "right" | "left";
  domain?: [any, any];
}

export interface ILine {
  key: string;
  stroke: string;
  yAxisId?: string;
}

interface MultiLineChartProps {
  label: string;
  data: Array<any>;
  lines: any;
  yAxises?: Array<IYAxis>;
  tooltipContent?: any;
  minHeight?: string;
  showLegend?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showDots?: boolean;
  strokeWidth?: number;
}
const CustomizedDot = (props) => {
  const { cx, cy, r, stroke, payload, value } = props;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={r}
      stroke={value > 0 ? "#015E94" : "#e3823a"}
      strokeWidth={1}
      fill={value > 0 ? "#015E94" : "#e3823a"}
    />
  );
};
export const DottedLineChart = ({
  data,
  label,
  tooltipContent,
  yAxises = [{ yAxisId: "axis", orientation: "left" }],
  minHeight = "100px",
  showLegend = true,
  showXAxis = true,
  showYAxis = true,
  showDots = false,
  strokeWidth = 1,
  lines,
}: MultiLineChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={minHeight}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        {showXAxis && (
          <XAxis dataKey={label} height={80} tick={<CustomizedAxisTick />} />
        )}
        {showXAxis && (
          <XAxis dataKey={label} height={80} tick={<CustomizedAxisTick />} />
        )}
        {yAxises?.map((item) => (
          <YAxis
            padding={{ top: 20 }}
            key={item.yAxisId}
            yAxisId={item.yAxisId}
            orientation={item.orientation}
            domain={item?.domain}
            hide={!showYAxis}
          />
        ))}
        <Tooltip content={tooltipContent} />
        {showLegend && <Legend />}
        {lines.map((item, idx) => {
          return (
            <Line
              key={idx + item.key}
              yAxisId={item?.yAxisId || yAxises[0]?.yAxisId}
              type="monotone"
              dataKey={item.key}
              stroke={item.stroke}
              strokeWidth={strokeWidth}
              dot={<CustomizedDot />}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
