import React from 'react'
import { EquipmentsWidget } from './components/EquipmentsWidget'
import { useCrossEquipmentComparison } from './useCrossEquipmentComparison'
import { Container } from '@mui/material'
import { HeaderWidget } from './components/HeaderWidget'
import { aggregationTypes } from './utils'
import { DataReportWidget } from './components/DataReportWidget';


export const CrossEquipmentComparison: React.FC<any> = () => {
  const {
    data,
    plant,
    portfolio,
    selectedPlant,
    selectedPortfolio,
    aggregation,
    date,
    setSelectedPortfolio,
    setSelectedPlant,
    setAggregation,
    setDate,
    inverter,
    setInverter,
    mfm,
    setMfm,
    wms,
    setWms,
    xAxises,
    setXAxises,
    yAxises,
    getCrossData,
    equipmentLoading,
    graphDataLoading,
    downloadGraph,
    showAlert,
    onChangePortfolio,
    onChangePlant,
  } = useCrossEquipmentComparison();

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: "16px" }}>
      <HeaderWidget
        selectedPortfolio={selectedPortfolio}
        portfolio={portfolio}
        setSelectedPortfolio={setSelectedPortfolio}
        selectedPlant={selectedPlant}
        plant={plant}
        setSelectedPlant={setSelectedPlant}
        setAggregation={setAggregation}
        aggregation={aggregation}
        aggregationTypes={aggregationTypes}
        date={date}
        setDate={setDate}
        onChangePortfolio={onChangePortfolio}
        onChangePlant={onChangePlant}
      />

      <EquipmentsWidget
        loading={equipmentLoading}
        title={"Data explorer"}
        height={"100%"}
        mfm={mfm}
        setMfm={setMfm}
        inverter={inverter}
        setInverter={setInverter}
        wms={wms}
        setWms={setWms}
        getCrossData={getCrossData}
        showAlert={showAlert}
      />

      <DataReportWidget
        title={"Data Report"}
        height={"86vh"}
        data={data}
        showYAxises={false}
        yAxises={yAxises}
        setXAxises={setXAxises}
        xAxises={xAxises}
        showLabel={true}
        size={12}
        loading={graphDataLoading}
        downloadGraph={downloadGraph}
      />
    </Container>
  )
}
