import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBearerToken } from "../../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1`;

// All of the api's which are in monitoring dashboard pages

export const monitoringDashboardApi = createApi({
  reducerPath: "monitoringDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}`,
    prepareHeaders: async (headers) => {
      const token = getBearerToken();
      if (token) {
        headers.set("authorization", `Bearer ${await token}`);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
        headers.set("Content-Type", "text/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMapCoordinates: builder.query({
      query: ({ plantId, portfolioName }) => ({
        url: `${sidemApiUrl}/GetMppPlantMapCoordinates?portfoliosName=${portfolioName}&plantsId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getMapData: builder.query({
      query: ({ plantId, portfolioName, fromDate, toDate }) => ({
        url: `${sidemApiUrl}/GetMppMapOverviewKPI?fromDate=${fromDate}&toDate=${toDate}&portfoliosName=${portfolioName}&plantsId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
  }),
});

export const { useGetMapCoordinatesQuery, useGetMapDataQuery } =
  monitoringDashboardApi;
