import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    subtitle: {
        display: "flex",
        padding: theme.spacing(1.2, 1, 1.2, 1),
        "&:hover": {
            cursor: "pointer",
        },
    },
    arrow: {
        paddingTop: theme.spacing(1.2),
    },
}));