import { useEffect, useState } from "react";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Data {
  user: any;
  deleteUsers: (id: any, objectId: any, callback: any) => void;
  loading: any;
}

export const useUser = (): Data => {
  const [user, setUser] = useState(null);
  const [loading, setloading] = useState(null);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/User/GetAllUsers`, "GET")
      .then((data: any) => {
        if (data) {
          let newUser = data?.map((item) => ({
            ...item,
            id: item.userId,
          }));

          setUser(newUser);
        }
        setloading(false);
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const deleteUsers = async (id: any, objectId: any, callback: any) => {
    setloading(true);
    const body = {
      ObjectId: objectId,
    };
    fetchAsync(`onboardingV2/v1/User/DeleteUserAdb2C`, "DELETE", body)
      .then((data: any) => {
        getUsers();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
        callback(e);
      });
    setloading(false);
  };

  return {
    user,
    deleteUsers,
    loading,
  };
};
