import { isAnyItemFalsy } from "./../../../shared/utils/utils";
import { useAppSelector } from "./../../../store/rootStore";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryVoltageAndFrequencyQuery,
} from "./BatteryMonitoring.api";
import { monitoringDashboardSelector } from "../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useUrlParams } from "../../../shared/utils/hooks";

export const useBatteryVoltageAndFrequencyWidget = () => {
  const { plantId } = useUrlParams({
    plantId: "",
  });

  const { liveButton } = useAppSelector(monitoringDashboardSelector);

  const { data, isLoading, isFetching, isError, isUninitialized } =
    useGetBatteryVoltageAndFrequencyQuery(
      {
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
      },
      {
        skip: isAnyItemFalsy([plantId, DEFAULT_EQUIPMENT_NAME]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

  const result = data
    ? [
        {
          label: "Current",
          value: data.current.value?.toFixed(1) || "N/A",
          unit: data.current.unit || "",
        },
        {
          label: "Voltage LL",
          value: data.voltage_ll.value?.toFixed(1) || "N/A",
          unit: data.voltage_ll.unit || "",
        },
        {
          label: "Voltage LN",
          value: data.voltage_ln.value?.toFixed(1) || "N/A",
          unit: data.voltage_ln.unit || "",
        },
        {
          label: "Frequency",
          value: data.frequency.value?.toFixed(1) || "N/A",
          unit: data.frequency.unit || "",
        },
      ]
    : undefined;

  return {
    data: result,
    isLoading: isLoading || isUninitialized,
    isFetching,
    isError,
  };
};

export type StatusWidgetData = ReturnType<
  typeof useBatteryVoltageAndFrequencyWidget
>;
