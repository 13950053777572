import { getBearerToken } from "../../../store/authToken";
import { axDelete } from "../../../store/axiosClient";
import { setSelectModule } from "./portfolioSlice";

export function deletePortfolioReq(body: any) {
  return async (dispatch: (arg0: any) => void) => {
    try {
      const token = await getBearerToken();
      const data: any = await axDelete(
        `OnboardingV2/v1/Portfolio/DeletePortfolio`,
        body,
        token
      );
      return data;
    } catch (error) {
      return error.response;
    }
  };
}

export const setSelectedModuleNameAndId =
  (payload) => (dispatch: (arg0: any) => void) => {
    dispatch(setSelectModule(payload));
  };
