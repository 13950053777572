import { useEffect, useState } from "react";
import { autoRefreshApi } from "../../../../../shared/utils/utils";
import {
  MIN_COLOR,
  getBackground,
  MAX_COLOR,
} from "../../../../components/HeatmapChart";
import { fetchAsync } from "../../../../../shared/utils/apiService";

export enum Types {
  Thirty = "30",
  Fifteen = "15",
  All = "all",
}

interface CustomItem {
  value: number;
  defaultColor: string;
  isWorst15: boolean;
  isWorst30: boolean;
}

interface InvertersMap {
  key: string;
  map: { [key: string]: CustomItem };
}

interface Item {
  worst15: boolean;
  worst30: boolean;
  clientName: string;
  inverterName: string;
  plantId: string;
  specificPower: number;
  stringName: string;
}

interface Props {
  type: Types;
  plantId: string;
  date: string;
}

interface Data {
  data: Array<Array<number>>;
  error: string;
  loading: boolean;
  colors: Array<Array<string>>;
  xLabels: Array<string>;
  yLabels: Array<string>;
}

export const useHeatmap = ({
  plantId,
  date,
  type,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [customData, setCustomData] = useState([] as Array<Array<CustomItem>>);
  const [colors, setColors] = useState([] as Array<Array<string>>);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      setLoading(false);
      return;
    }
    if (data.every((item) => isNaN(item.specificPower))) {
      setError("No data");
      setLoading(false);
      return;
    }
    setError("");
    setLoading(true);
    const invertersMap: InvertersMap = data.reduce((res, curr, idx) => {
      const currLabel = curr.inverterName.includes("Inverter")
        ? curr.inverterName.replace("Inverter", "Inv")
        : curr.inverterName;
      const currentItem: CustomItem = {
        value: parseFloat(curr.specificPower?.toString()),
        defaultColor: MIN_COLOR,
        isWorst15: curr.worst15,
        isWorst30: curr.worst30,
      };
      if (res[currLabel]) {
        res[currLabel].map[curr?.stringName] = currentItem;
      } else {
        res[currLabel] = {
          key: currLabel,
          map: {
            [curr?.stringName]: currentItem,
          },
        };
      }
      return res;
    }, {} as InvertersMap);

    const newXLabels = Object.keys(invertersMap)
      .map((item) => item)
      .sort(function (a, b) {
        let split_a = a.split("-")[1];
        let value_a = split_a.split(".");
        let split_b = b.split("-")[1];
        let value_b = split_b.split(".");

        return (
          parseInt(value_a.shift()) - parseInt(value_b.shift()) ||
          parseInt(value_a.shift()) - parseInt(value_b.shift()) ||
          parseInt(value_a.shift()) - parseInt(value_b.shift())
        );
      });

    const newYLabels = Object.keys(invertersMap[newXLabels[0]]?.map)
      .map((item) => item)
      .sort(
        (a, b) =>
          parseInt(a.includes("-") ? a.split("-")[1] : a.split("CB")[1]) -
          parseInt(b.includes("-") ? b.split("-")[1] : b.split("CB")[1])
      );

    const newCustomData = [];

    newYLabels?.forEach((label) => {
      const row = newXLabels?.map((item) => {
        if (typeof invertersMap[item]?.map[label] === "undefined") {
          return {
            defaultColor: "#015E9433",
            isWorst15: false,
            isWorst30: false,
            value: "N/A",
          };
        } else {
          return invertersMap[item]?.map[label];
        }
      });
      newCustomData?.push(row);
    });
    let heatmapData = [];
    newCustomData?.map((item) => {
      const dt = item.map((item) => {
        if (item) {
          return Math.round(item.value * 100) / 100;
        }
      });

      if (typeof dt !== "undefined") {
        heatmapData.push(dt);
      }
    });

    setXLabels(newXLabels);
    setYLabels(newYLabels);
    setData(heatmapData);

    for (let i = 0; i < newXLabels?.length; i++) {
      let maxValue = 0;
      let minValue = 1000000000;
      const arr: Array<CustomItem> = Object.values(
        invertersMap[newXLabels[i]].map
      );
      arr?.forEach((item) => {
        maxValue = maxValue > item.value ? maxValue : item.value;
        minValue = minValue < item.value ? minValue : item.value;
      });

      for (let j = 0; j < newYLabels?.length; j++) {
        newCustomData[j][i].defaultColor = getBackground(
          maxValue,
          minValue,
          newCustomData[j][i].value
        );
      }
    }
    setCustomData(newCustomData);
    setError("");
    setLoading(false);
  };
  const GetCBorStingSpecificCurrent = () => {
    fetchAsync(`SkyfriSidemApi/v1/GetCBorStingSpecificCurrent?`, "GET", {
      plantId,
      date,
    })
      .then((data: Array<Item>) => {
        setError("");
        setLoading(false);
        transformData(data);
      })
      .catch(setError);
  };

  useEffect(() => {
    if (!date || !plantId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    GetCBorStingSpecificCurrent();

    const myInterval = autoRefreshApi(() => GetCBorStingSpecificCurrent());
    return () => clearInterval(myInterval);
  }, [plantId, date]);

  useEffect(() => {
    if (!customData?.length || !type) return;

    switch (type) {
      case Types.All:
        return setColors(
          customData?.map((arr) => arr?.map((item) => item.defaultColor))
        );
      case Types.Thirty:
        return setColors(
          customData?.map((arr) =>
            arr?.map((item) => (item?.isWorst30 ? MAX_COLOR : MIN_COLOR))
          )
        );
      case Types.Fifteen:
        return setColors(
          customData?.map((arr) =>
            arr?.map((item) => (item?.isWorst15 ? MAX_COLOR : MIN_COLOR))
          )
        );
    }
  }, [customData, type]);

  return {
    data,
    xLabels,
    yLabels,
    colors,
    error,
    loading,
  };
};
