import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
// MonitoringShared
export interface IMonitoringSharedState {
    globalDate?: {
        startDate: Date | string;
        endDate: Date | string;
        key: string;
    }[];
    dateEqual?: boolean;
    liveButton?: boolean;
    globalPortfolioName?: string;
}

const initialState: IMonitoringSharedState = {
    globalDate: [{
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        key: "selection",
    }],
    dateEqual: null,
    liveButton: true,
    globalPortfolioName: null,
};

// name
// reducer
// actions
// caseReducers

export const monitoringSharedSlice = createSlice({
    name: "monitoringshared",
    initialState,
    reducers: {
        setGlobalDate: (state, { payload }) => {
            return {
                ...state,
                globalDate: payload,
            };
        },
        dateComparison: (state, { payload }) => {
            return {
                ...state,
                dateEqual: payload,
            };
        },
        setLiveButton: (state, { payload }) => {
            return {
                ...state,
                liveButton: payload,
            };
        },
        getPortfolioName: (state, { payload }) => {
            return {
                ...state,
                globalPortfolioName: payload,
            };
        },
    },
});

// MonitoringShared actions

export const {
    setGlobalDate,
    dateComparison,
    setLiveButton,
    getPortfolioName,
} = monitoringSharedSlice.actions;

// MonitoringShared state

export const monitoringSharedSelector = (state: RootState) =>
    state.Monitoring.MonitoringShared;

// MonitoringShared reducer

export const monitoringSharedReducer = monitoringSharedSlice.reducer;