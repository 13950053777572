import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
// MonitoringDashboard
export interface IDashboardPageState {
    gettingPortfolios?: boolean;
    portfoliosFailed?: boolean;
    portfoliosSuccessful?: boolean;

    gettingOverview?: boolean;
    overviewFailed?: boolean;
    overviewSuccessful?: boolean;
    overview?: any[];

    gettingPlantOverview?: boolean;
    plantOverviewFailed?: boolean;
    plantOverviewSuccessful?: boolean;
    plantOverview?: any[];

    globalDate?: {
        startDate: Date;
        endDate: Date;
        key: string;
    }[];
    dateEqual?: boolean;
    liveButton?: boolean;

    globalPlantId?: string | number;
    globalPortfolioName?: string;

    plantTimeZone?: string;

    selectedInverterI?: {
        blockId: string | number,
        inverterId: string | number,
        portfolio: string,
    };

    mapFullScreen?: boolean;
}

const initialState: IDashboardPageState = {
    gettingPortfolios: false,
    portfoliosFailed: false,
    portfoliosSuccessful: false,

    gettingOverview: false,
    overviewFailed: false,
    overviewSuccessful: false,
    overview: [],

    gettingPlantOverview: false,
    plantOverviewFailed: false,
    plantOverviewSuccessful: false,
    plantOverview: [],

    globalDate: [{
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    }],
    liveButton: true,

    globalPlantId: null,

    globalPortfolioName: null,

    dateEqual: true,

    plantTimeZone: null,

    selectedInverterI: null,
};
// name
// reducer
// actions
// caseReducers

export const monitoringDashboardSlice = createSlice({
    name: "monitoringdashboard",
    initialState,
    reducers: {
        portfoliosStart: (state) => {
            return {
                ...state,
                gettingPortfolios: true,
            };
        },
        portfoliosSuccess: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosSuccessful: true,
            };
        },
        portfoliosError: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosFailed: true,
            };
        },
        overviewStart: (state) => {
            return {
                ...state,
                gettingOverview: true,
            };
        },
        overviewSuccess: (state, { payload }) => {
            return {
                ...state,
                gettingOverview: false,
                overviewSuccessful: true,
                overview: payload,
            };
        },
        overviewError: (state) => {
            return {
                ...state,
                gettingOverview: false,
                overviewFailed: true,
            };
        },
        setGlobalDate: (state, { payload }) => {
            return {
                ...state,
                globalDate: payload,
            };
        },
        setLiveButton: (state, { payload }) => {
            return {
                ...state,
                liveButton: payload,
            };
        },
        plantOverviewStart: (state) => {
            return {
                ...state,
                gettingPortfolios: true,
            };
        },
        plantOverviewSuccess: (state, { payload }) => {
            return {
                ...state,
                gettingPortfolios: false,
                plantOverviewSuccessful: true,
                plantOverview: payload,
            };
        },
        plantOverviewError: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                plantOverviewFailed: true,
            };
        },
        getPlantId: (state, { payload }) => {
            return {
                ...state,
                globalPlantId: payload,
            };
        },
        dateComparison: (state, { payload }) => {
            return {
                ...state,
                dateEqual: payload,
            };
        },
        getPortfolioName: (state, { payload }) => {
            return {
                ...state,
                globalPortfolioName: payload,
            };
        },
        getPlantTimeZone: (state, { payload }) => {
            return {
                ...state,
                plantTimeZone: payload,
            };
        },
        getSelectedInverter: (state, { payload }) => {
            return {
                ...state,
                selectedInverterI: payload,
            };
        },
    },
});

// MonitoringDashboard actions
export const {
    portfoliosStart,
    portfoliosSuccess,
    portfoliosError,
    overviewStart,
    overviewSuccess,
    overviewError,
    plantOverviewStart,
    plantOverviewSuccess,
    plantOverviewError,
    setGlobalDate,
    setLiveButton,
    getPlantId,
    dateComparison,
    getPortfolioName,
    getPlantTimeZone,
    getSelectedInverter,
} = monitoringDashboardSlice.actions;

// MonitoringDashboard state

export const monitoringDashboardSelector = (state: RootState) =>
    state.Monitoring.MonitoringDashboard;

// MonitoringDashboard reducer

export const monitoringDashboardReducer = monitoringDashboardSlice.reducer;