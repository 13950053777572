import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Grid, Tab, Tabs } from "@mui/material";
import { useStyles } from "../../styles";
import { SheetTable } from "./SheetTable.component";
import { Checkbox } from "../../../../../shared/components";

interface TabsProps {
  setCurTab: any;
  sheetHeaders: any;
  addToSheet: any;
  getButton: (title: string, handleClick: any) => JSX.Element;
}
export const TabsContainer: React.FC<any> = ({
  setCurTab,
  sheetHeaders,
  getButton,
  addToSheet
}: TabsProps) => {
  const [reportTabs, setReportTabs] = useState([
    {
      key: 1,
      id: 1,
    },
  ]);
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, value) => {
    setTabValue(value);
    setCurTab(value);
  };

  const addTab = () => {
    let id = reportTabs[reportTabs.length - 1].id + 1;
    setReportTabs([...reportTabs, { key: id, id: id }]);
  };
  const deleteTab = (e) => {
    e.stopPropagation();

    if (reportTabs.length === 1) {
      return;
    }
    let tabId = parseInt(e.target.id);
    let tabIDIndex = 0;

    let tabs = reportTabs.filter((value, index) => {
      if (value.id === tabId) {
        tabIDIndex = index;
      }
      return value.id !== tabId;
    });
    // @ts-ignore
    let curValue = parseInt(tabValue);
    if (curValue === tabId) {
      if (tabIDIndex === 0) {
        curValue = reportTabs[tabIDIndex + 1].id;
      } else {
        curValue = reportTabs[tabIDIndex - 1].id;
      }
    }
    setTabValue(curValue.toString());
    setReportTabs(tabs);
  };
  const classes = useStyles();
  const [includeValues, setIncludeValues] = useState({
    mtd: false,
    qtd: false,
    ytd: false,
  });
  const handleChangeValue = (name: string, e: any) => {
    setIncludeValues({ ...includeValues, [name]: e });
  };
  return (
    <div>
      <TabContext value={tabValue}>
        <AppBar position="static" className={classes.appBar}>
          <Grid container className={classes.tabContainer}>
            <Grid item xl={10} lg={10} md={10} sm={11} xs={11}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: { background: "#4473C3" },
                }}
              >
                {reportTabs.map((tab: any) => (
                  <Tab
                    key={tab.key.toString()}
                    value={tab.id}
                    label={`Sheet ${tab.id}`}
                    icon={<Close id={tab.id} onClick={deleteTab} />}
                    iconPosition="end"
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1}>
              {getButton("Add new Sheet", addTab)}
            </Grid>
          </Grid>
        </AppBar>

        <div className={classes.includeBtnGroup}>
          <Checkbox
            checked={includeValues.mtd}
            label="Include MTD"
            onChange={(e) => handleChangeValue("mtd", e)}
          />
          <Checkbox
            checked={includeValues.qtd}
            label="Include QTD"
            onChange={(e) => handleChangeValue("qtd", e)}
          />
          <Checkbox
            checked={includeValues.ytd}
            label="Include YTD"
            onChange={(e) => handleChangeValue("ytd", e)}
          />
          {getButton("Add to template", () => addToSheet(includeValues, "other"))}

        </div>
        <TabPanel value={tabValue}>
          {sheetHeaders.map((sheet) => {
            if (sheet.sheet !== tabValue) return;
            return <SheetTable cells={sheet.cells} />;
          })}
        </TabPanel>
      </TabContext>
    </div>
  );
};
