import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useSearchParams } from "react-router-dom";
import { convertTime, isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useGetMppMeteoCardQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useMemo } from "react";

export const useMeteoWidget = () => {
  const [url] = useSearchParams();

  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { globalPortfolioName } = useAppSelector(monitoringDashboardSelector);

  const { liveButton } = useAppSelector(monitoringSharedSelector);

  const fromDate = useMemo(() => {
    return startDate ? convertTime(startDate, "YYYY.MM.DD") : undefined;
  }, [startDate]);

  const toDate = useMemo(() => {
    return endDate ? convertTime(endDate, "YYYY.MM.DD") : undefined;
  }, [endDate]);

  const {
    data: mppMeteoCardData,
    isFetching: isMppMeteoCardFetching,
    isLoading: isMppMeteoCardLoading,
    error: mppMeteoCardError,
  } = useGetMppMeteoCardQuery(
    {
      plantId,
      fromDate,
      toDate,
      portfolioName: globalPortfolioName,
    },
    {
      pollingInterval: liveButton ? 60 * 1000 : undefined,
      skip: isAnyItemFalsy([plantId, fromDate, toDate, globalPortfolioName]),
    }
  );

  return {
    meteoLoading: isMppMeteoCardLoading,
    meteoData: mppMeteoCardData ? mppMeteoCardData?.data : null,
    meteoError: Boolean(mppMeteoCardData?.error || mppMeteoCardError),
    meteoSecondLoading: isMppMeteoCardFetching,
  };
};

export type MeteoWidgetData = ReturnType<typeof useMeteoWidget>;
