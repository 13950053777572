import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  markerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    fontSize: 16,
    fontWeight: 1000,
    paddingTop: 3,

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  },
  tooltip: {
    borderRadius: 15,
  },

  error: {
    backgroundColor: '#FF5500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    padding: theme.spacing(1.4, 0.6),
    borderRadius: 500,
    height: 20,
    fontSize: 12

  },

  button: {
    borderRadius: 5,
    fontWeight: 550,
    fontSize: 15,
    padding: theme.spacing(2.2, 1),
    color: '#0C1E33',
    backgroundColor: '#E4F2EE',
  },

  warningButton: {
    backgroundColor: '#C94B4B',
  },
  location: {
    fontWeight: 400,
    fontSize: 13,
    padding: theme.spacing(2, 2)
  },

  containerTop: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto auto auto auto auto",
    gap: 6,
    padding: theme.spacing(0, 0.5),
  },
  columTitle: {
    gridColumn: "1/ span 6",
  },
  columnError: {
    gridColumn: "7/ span 2",
    paddingTop: theme.spacing(0.5),
  },
  columnPR: {
    gridColumn: "10/ span 3",
    gridRow: "1 / 3",
  },
  columnLocation: {
    gridColumn: "1/ span 2",
  },
  columnLocationName: {
    fontWeight: 400,
    fontSize: 13,
    gridColumn: "3/ span 7",
  },

  containerBottom: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto auto auto auto auto",
    gap: 6,
    padding: theme.spacing(0, 0.5),
  },

  columnLeft: {
    gridColumn: "1/ span 6",
    fontWeight: 400,
    fontSize: 14,
    paddingTop: theme.spacing(1.5)

  },

  columnRight: {
    display: "flex",
    gridColumn: "7/ span 6",
    fontWeight: 550,
    fontSize: 15,
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1.5)

  },

  weatherLeft: {
    gridColumn: "1/ span 6",
    fontWeight: 400,
    fontSize: 14,
    paddingTop: theme.spacing(1.5)
  },
  weatherRight: {
    display: "flex",
    gridColumn: "7/ span 6",
    fontWeight: 550,
    fontSize: 15,
    justifyContent: "flex-end",

  },
  hrLine: {
    opacity: 0.2,
    color: "#0C1E33",
    border: "none",
    height: "2px",
    backgroundColor: "#0C1E33",
  }
}));