import { useMemo } from "react";
import { MultiAxisChartWidget } from "../../../../components/MultiAxisChartWidget";
import { CustomTooltip } from "./CustomTooltip.component";
import { yAxises } from "./data";
import { useInverterCustom } from "./useInverterCustom";

interface Props {
  plantId: string;
  inverterId: string;
  blockId: string;
  date: string;
}

export const InverterCustomWidget: React.FC<Props> = (props) => {
  const { data, loading, error, lines, setLines } = useInverterCustom(props);

  const hide = useMemo(() => !data?.length && loading, [data, lines, loading]);

  return hide ? null : (
    <MultiAxisChartWidget
      title={"Inverter Custom Graph"}
      height={500}
      size={12}
      lines={lines}
      data={data}
      loading={loading}
      error={error}
      showYAxises={true}
      yAxises={yAxises}
      definition={null}
      toggleLegend={true}
      setLines={setLines}
      tooltipContent={<CustomTooltip />}
    />
  );
};
