import { useUrlParams } from "../../../../../shared/utils/hooks";
import {
  GLOBAL_COLORS,
  daysOfWeek,
  diffDateDays,
  findLastIndex,
  getShortenedNumber,
  months,
} from "../../../../../shared/utils/utils";
import { BatteryControlTableData } from "../../../BatteryDetails/components/BatteryControlTable/BatteryControlTable.types";
import {
  BATTERY_CONTROL_STATUS,
  ENERGY_PROVIDERS,
} from "../../BatteryMonitoring.api.types";
import {
  DROPDOWN_OPTION_VALUE,
  DROPDOWN_OPTIONS,
  getGranularityMs,
} from "../../utils";
import {
  GroupedPeriod,
  ProviderSerie,
} from "./BatteryControlChartWidget.types";

export const batteryIconSvg = `<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.5" width="18" height="11" rx="2" fill="#8FCEA1"/>
<rect x="18" y="3.5" width="2" height="5" fill="#8FCEA1"/>
<path d="M9.05328 4.05966L12.4299 9.05966C12.6158 9.33499 12.9264 9.5 13.2586 9.5H15C15.5523 9.5 16 9.05228 16 8.5V3.5C16 2.94772 15.5523 2.5 15 2.5H9.882C9.0806 2.5 8.60477 3.39552 9.05328 4.05966Z" fill="white"/>
</svg>
`;

export function getBatteryControlGranularity(fromDate: string, toDate: string) {
  const daysDiff = diffDateDays(fromDate, toDate);
  if (daysDiff <= 1) {
    return DROPDOWN_OPTION_VALUE.ONE_HOUR;
  } else if (daysDiff <= 29) {
    return DROPDOWN_OPTION_VALUE.ONE_DAY;
  } else {
    return DROPDOWN_OPTION_VALUE.ONE_MONTH;
  }
}

export const fillGapsBetweenDate = (
  obj: { [key: string]: ProviderSerie },
  periods: { startDate: string; endDate: string }[]
): { [key: string]: ProviderSerie } => {
  let maxX = periods.length;

  const result = {};

  const currentDate = new Date();

  for (let providerEnum in obj) {
    const newValues: ProviderSerie["data"] = new Array(maxX)
      .fill(null)
      .map((_, i) => {
        const startDate = new Date(periods[i].startDate);
        const endDate = new Date(periods[i].endDate);

        if (endDate > currentDate) {
          return null;
        } else {
          return {
            x: startDate.toISOString(),
            index: i,
            y: null,
            color: obj[providerEnum].color,
            name: obj[providerEnum].name,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          };
        }
      });

    obj[providerEnum].data.forEach((point) => {
      newValues[point.index] = point;
    });

    result[providerEnum] = { ...obj[providerEnum], data: newValues };
  }

  const newObj: { [providerEnum: string]: ProviderSerie["data"] } = {};

  for (const providerEnum in result) {
    const items: ProviderSerie["data"] = result[providerEnum].data;
    if (!newObj[providerEnum]) {
      newObj[providerEnum] = [];
    }
    items.forEach((item, index) => {
      newObj[providerEnum].push(item);
      if (
        (item.y !== null && items[index + 1] && items[index + 1].y === null) ||
        (index === items.length - 1 && item.y !== null)
      ) {
        newObj[providerEnum].push({
          ...item,
          x: new Date(new Date(item.endDate).getTime() - 1).toISOString(),
        });
      }
    });
  }

  for (const providerEnum in newObj) {
    newObj[providerEnum] = newObj[providerEnum].sort(
      (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
    );
  }

  for (const providerEnum in result) {
    result[providerEnum].data = newObj[providerEnum];
  }

  return result;
};

export const getColorForProvider = (provider: string): string => {
  const providerColors: { [key: string]: string } = {
    [ENERGY_PROVIDERS.Skyfri]: CHART_COLORS.SKYFRI,
    [ENERGY_PROVIDERS.Ebox]: CHART_COLORS.EBOX,
    [ENERGY_PROVIDERS.AmpereCloud]: CHART_COLORS.AMPERE_CLOUD,
    [ENERGY_PROVIDERS["Multiple sources"]]: CHART_COLORS.MULTIPLE_SOURCES,
  };
  return providerColors[provider] || "black";
};

function roundDown(date: Date, intervalMs: number) {
  return new Date(Math.floor(date.getTime() / intervalMs) * intervalMs);
}

export const groupByGranularity = (
  arr: BatteryControlTableData[],
  startDate: string,
  endDate: string
) => {
  const granularity = getBatteryControlGranularity(startDate, endDate);
  // TODO: kaz group by 1 hour always?
  // const granularityMs = BATTERY_CONTROL_GRANULARITY_MS[granularity];
  const currentDate = new Date();

  const granularityMs = getGranularityMs(DROPDOWN_OPTION_VALUE.ONE_HOUR);

  const startDateCurrentTimezone = new Date(
    new Date(startDate).setHours(0, 0, 0, 0)
  );

  let endDateCurrentTimezone = new Date(
    new Date(endDate).setHours(23, 59, 59, 999)
  );

  if (endDateCurrentTimezone > currentDate) {
    endDateCurrentTimezone = currentDate;
  }

  const mappedToCurrentTimezone = arr.map((obj) => {
    return {
      ...obj,
      time: new Date(obj.date),
    };
  });

  let grouped: GroupedPeriod[] = new Array(
    Math.ceil(
      (endDateCurrentTimezone.getTime() - startDateCurrentTimezone.getTime()) /
        granularityMs
    )
  )
    .fill(null)
    .map((_, i) => {
      const start = new Date(
        startDateCurrentTimezone.getTime() + i * granularityMs
      );

      const end = new Date(
        startDateCurrentTimezone.getTime() + (i + 1) * granularityMs - 1
      );

      const startISO = start.toISOString();

      const endISO = end.toISOString();

      const day = String(
        start.getDate() < 10 ? `0${start.getDate()}` : start.getDate()
      );

      const month = months[start.getMonth()];

      const year = String(start.getFullYear());

      const startHours =
        start.getHours() < 10 ? `0${start.getHours()}` : start.getHours();

      const startMinutes =
        start.getMinutes() < 10 ? `0${start.getMinutes()}` : start.getMinutes();

      const endHours =
        end.getHours() < 10 ? `0${end.getHours()}` : end.getHours();

      const endMinutes =
        end.getMinutes() < 10 ? `0${end.getMinutes()}` : end.getMinutes();

      return {
        day,
        month,
        year,
        time: `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`,
        startDate: startISO,
        endDate: endISO,
        areProvidersReal: true,
        providers: {},
      };
    });

  mappedToCurrentTimezone.forEach((obj) => {
    const time = obj.time.getTime();
    const provider = obj.source;

    const dateIndex = grouped.findIndex((date) => {
      return (
        time >= new Date(date.startDate).getTime() &&
        time < new Date(date.endDate).getTime()
      );
    });

    if (dateIndex !== -1) {
      if (!grouped[dateIndex]) {
        return;
      }

      if (!grouped[dateIndex].providers[provider]) {
        grouped[dateIndex].providers[provider] = [];
      }

      grouped[dateIndex].providers[provider].push({
        ...obj,
        time: obj.date.toISOString(),
        source: provider,
      });
    }
  });

  let prevProviders = {};

  grouped = grouped.map((item) => {
    const providers = Object.values(item.providers);

    if (providers.length > 0) {
      prevProviders = item.providers;
    }

    const result = {
      ...item,
      providers: providers.length > 0 ? item.providers : prevProviders,
      // flag to investigate if there are any providers in the period or are we using previous one
      areProvidersReal: providers.length !== 0,
    };

    return result;
  });

  return grouped;
};

export const MIN_SIGNALS_TO_DISPLAY_PINPOINT = 15;

export enum CHART_COLORS {
  SOC = "#01579B",
  TIMESTAMP = "#5A5D6C",
  SKYFRI = "#3969E5",
  EBOX = "#EB338C",
  AMPERE_CLOUD = "#343434",
  MULTIPLE_SOURCES = "#26BB9F",
}

export const getXAxisCategories = (
  value: string,
  items: {
    x?: number;
    [key: string]: any;
  }[],
  granularity: number
) => {
  const date = new Date(Number(value));

  let dayIndex = date.getDay() - 1;

  if (dayIndex === -1) {
    // in js days of week starts from sunday, this array starts from monday.
    dayIndex = daysOfWeek.length - 1;
  }

  const monthIndex = date.getMonth();

  const dayNumber = date.getDate();

  const year = date.getFullYear();
  const dayString = daysOfWeek[dayIndex];
  const monthString = months[monthIndex];
  const dayShort = dayString.substring(0, 3);
  const monthShort = monthString.substring(0, 3);

  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  const periodsCount = items.length;

  let isSameDay = true;

  if (granularity === DROPDOWN_OPTION_VALUE.RAW_DATA) {
    const datesWithX = items.filter((item) => item.x);
    const dates = datesWithX.map((item) => new Date(item.x).getDate());

    if (dates.length > 1) {
      isSameDay = new Set(dates).size === 1;
    }
  }

  switch (granularity) {
    case DROPDOWN_OPTION_VALUE.RAW_DATA:
      return periodsCount > 24 && !isSameDay
        ? `${dayShort} ${hour}:${minute}`
        : `${hour}:${minute}`;
    case DROPDOWN_OPTION_VALUE.ONE_MIN:
      return periodsCount > 1440
        ? `${dayShort} ${hour}:${minute}`
        : `${hour}:${minute}`;
    case DROPDOWN_OPTION_VALUE.FIVE_MIN:
      return periodsCount > 288
        ? `${dayShort} ${hour}:${minute}`
        : `${hour}:${minute}`;
    case DROPDOWN_OPTION_VALUE.FIFTEEN_MIN:
      return periodsCount > 96
        ? `${dayShort} ${hour}:${minute}`
        : `${hour}:${minute}`;
    case DROPDOWN_OPTION_VALUE.THIRTY_MIN:
      return periodsCount > 48
        ? `${dayShort} ${hour}:${minute}`
        : `${hour}:${minute}`;
    case DROPDOWN_OPTION_VALUE.ONE_HOUR:
      return periodsCount > 24 ? `${dayShort} ${hour}:00` : `${hour}:00`;
    case DROPDOWN_OPTION_VALUE.ONE_DAY:
      return periodsCount > 7 ? `${dayShort} ${dayNumber}` : dayShort;
    case DROPDOWN_OPTION_VALUE.ONE_WEEK:
      return periodsCount > 4 ? `${dayShort} ${dayNumber}` : dayShort;
    case DROPDOWN_OPTION_VALUE.ONE_MONTH:
      return periodsCount > 12 ? `${monthShort} ${year}` : monthShort;
    case DROPDOWN_OPTION_VALUE.ONE_YEAR:
      return year.toString();
    default:
      return date.toISOString();
  }
};

export function tooltipFormatter(batteryControlDataGrouped: GroupedPeriod[]) {
  return function (s) {
    const x = this.x;

    let selectedItem = batteryControlDataGrouped.find(
      (period) => new Date(period.startDate).getTime() === new Date(x).getTime()
    );

    if (!selectedItem) {
      selectedItem = batteryControlDataGrouped.find(
        (period) =>
          new Date(period.endDate).getTime() === new Date(x + 1).getTime()
      );
    }

    if (!selectedItem.areProvidersReal) {
      const selectedItemIndex = batteryControlDataGrouped.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(selectedItem)
      );

      if (selectedItemIndex === -1) {
        return;
      }

      const range = batteryControlDataGrouped.slice(0, selectedItemIndex + 1);

      // custom implementation of findLastIndex because of weak support of array.prototype function
      const prevItemWithRealProvidersIndex = findLastIndex(
        range,
        (item) => item.areProvidersReal
      );

      if (prevItemWithRealProvidersIndex === -1) {
        return;
      }

      selectedItem = batteryControlDataGrouped[prevItemWithRealProvidersIndex];
    }

    if (!selectedItem) {
      return;
    }

    let name =
      Object.keys(selectedItem.providers).length > 1
        ? ENERGY_PROVIDERS[ENERGY_PROVIDERS["Multiple sources"]]
        : ENERGY_PROVIDERS[Object.keys(selectedItem.providers)[0]];

    const getValues = () => {
      const isMultipleSourceInSpecifiedRange =
        name === ENERGY_PROVIDERS[ENERGY_PROVIDERS["Multiple sources"]];

      if (isMultipleSourceInSpecifiedRange) {
        return Object.entries(selectedItem.providers)
          .map(([provider, values]) => {
            const pointColor = `
            <tspan style="font-size:20px; color: ${getColorForProvider(
              provider
            )}; fill: ${getColorForProvider(provider)};">■</tspan>&nbsp`;
            const providerName = ENERGY_PROVIDERS[provider] || "Unknown";
            const signalsCount = Array.isArray(values) ? values.length : 0;
            return `${pointColor} <b>${providerName}:</b> ${signalsCount} <span style="color:${
              CHART_COLORS.TIMESTAMP
            }">${signalsCount === 1 ? "signal" : "signals"}</span>`;
          })
          .join("<br/>");
      } else {
        const color = getColorForProvider(
          Object.keys(selectedItem.providers)[0]
        );

        const pointColor = `<tspan style="font-size:20px; color: ${color}; fill: ${color};">■</tspan>&nbsp`;

        const providerName = name;

        const signalsCount =
          selectedItem?.providers &&
          Array.isArray(Object.values(selectedItem.providers)[0])
            ? Object.values(selectedItem.providers)[0]?.length || 0
            : 0;

        let signalTypeAndValue: string | null = null;

        if (signalsCount === 1) {
          const signalTypeKey = Object.values(selectedItem.providers)[0][0]
            .type;

          if (signalTypeKey) {
            signalTypeAndValue = BATTERY_CONTROL_STATUS[signalTypeKey];
          }
        }

        return `${pointColor} <b>${providerName}:</b> ${
          signalTypeAndValue || signalsCount
        } ${
          signalTypeAndValue
            ? ""
            : `<span style="color:${CHART_COLORS.TIMESTAMP}">${
                signalsCount === 1 ? "signal" : "signals"
              }</span>`
        }`;
      }
    };

    const isMoreThanOneSignalInRange =
      Object.entries(selectedItem.providers).reduce(
        (acc, [_, values]) => (acc += values.length),
        0
      ) > 1;

    const monthString = selectedItem?.month?.substring(0, 3) || null;

    const date = `${selectedItem.day} ${monthString} ${selectedItem.year}`;
    const timeRange = selectedItem.time;

    let singleItemTimestamp = null;

    if (!isMoreThanOneSignalInRange) {
      const providers = Object.values(selectedItem.providers);

      const d = new Date(providers[0][0]?.time);
      const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      const month =
        d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const monthString = months[Number(month) - 1]?.substring(0, 3);
      const year = d.getFullYear();
      const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
      const minutes =
        d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
      const seconds =
        d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
      singleItemTimestamp = `${day} ${monthString} ${year} / ${hours}:${minutes}:${seconds}`;
    }

    const singleItemHeader = `<span style="font-size:10px; font-weight:400;"><span style="color: ${CHART_COLORS.TIMESTAMP};"> ${singleItemTimestamp} </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${batteryIconSvg} ${this.y}% <br/> </span>`;

    const multipleItemsHeader = `<span style="font-size:10px; font-weight:400;">${date} / <span style="color: ${CHART_COLORS.TIMESTAMP};"> ${timeRange} </span> <br/> </span>`;

    const header = isMoreThanOneSignalInRange
      ? multipleItemsHeader
      : singleItemHeader;

    return `<div style="padding: 10px; border-radius:8px;">${
      header + getValues()
    }</div>`;
  };
}
