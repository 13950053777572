import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    position: "absolute",
    right: 10,
    bottom: 10,
    display: "flex",
  },

  chartIcon: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    padding: theme.spacing(1),

    "&:hover": {
      cursor: "pointer",
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
    },

    "&:last-child": {
      marginRight: 0,
    },
  },

  active: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
  },

  chart: {
    height: 50,
    display: "flex",
    alignItems: "center",
    maxWidth: 800,
    overflow: "hidden",
    padding: theme.spacing(0, 0.5),
    boxShadow: "0px 3px 3px #EFF0F0",
  },
}));
