import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    locationName: {
        weight: 500,
        fontSize: "14px",
    },
    heading: {
        fontSize: "15px",
        lineHeight: "15.6px",
        fontWeight: 400,
        padding: "2px 5px 2px 5px",
        display: "flex",
        alignItems: "center",
        gap: "3px",
        maxWidth: "200px",
    },
    timeZoneClockBg: {
        backgroundColor: "#DEEFFE",
        borderRadius: "4px",
        padding: "4px",
        display: "flex",
    },
    loactionBg: {
        backgroundColor: "rgba(255,255,255,0)",
        borderRadius: "4px",
        padding: "4px",
        display: "flex",
    },
    Equal: {
        padding: "2px 5px 2px 5px",
        fontSize: "15px",
        lineHeight: "16.8px",
        width: "fit-content",
    },
    EqualText: {
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "15.6px",
    },
    EqualTextUnit: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "14.4px",
        paddingLeft: "3px",
    },
    noData: {
        backgroundColor: "#EAEAEA",
        padding: "2px 5px 2px 5px",
        borderRadius: "4px",
        width: "fit-content",
    },
    noDataText: {
        color: "#AEAEAE",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "15.6px",
    },
    Low: {
        backgroundColor: "#FCEEEE",
        padding: "2px 5px 2px 5px",
        borderRadius: "4px",
        width: "fit-content",
    },
    LowText: {
        color: "#DE504F",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "15.6px",
    },
    LowTextUnit: {
        color: "rgba(222, 80, 79, 0.7)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "14.4px",
        paddingLeft: "3px",
    },
    High: {
        backgroundColor: "#E4F2EE",
        padding: "2px 5px 2px 5px",
        borderRadius: "4px",
        width: "fit-content",
    },
    HighText: {
        color: "#49937D",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "15.6px",
    },
    HighTextUnit: {
        color: "rgba(73, 147, 125, 0.7)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "14.4px",
        paddingLeft: "3px",
    },
    tableWidth: {
        minWidth: "140px",
        maxWidth: "140px",
    },
    flexType: {
        minWidth: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
    },
    lastUpdated: {
        backgroundColor: "#E8ECF2",
        padding: "2px 5px 2px 5px",
        borderRadius: "4px",
        width: "fit-content",
        fontSize: "15px",
        fontWeight: 400,
    },

}));