import axios from "axios";
import { getBearerToken } from "../../store/authToken";


const lobbyScreenUrl = `SkyfriSidemApi/v1/LobbyScreen`

export const getLobbyLogo = async (logoType: string, userId: string, tenantId: string) => {
    try {
        const token = await getBearerToken();
        const BASE_API_URL = process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : "https://skifryfpel-dev-apimngment.azure-api.net/";
        const response: any = await axios({
            method: "GET",
            url: BASE_API_URL + `${lobbyScreenUrl}/GetLobbyScreenLogo?logoType=${logoType}&userId=${userId}&tenantId=${tenantId}`,
            responseType: "blob",
            headers: {
                authorization: "Bearer " + token,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                responseType: "blob",
            },
        })
        return response;
    } catch (error) {
        console.error('Error importing logo:', error);
    }
};