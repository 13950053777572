import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  waterfallMenu: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  widget: {
    marginRight: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  dates: {
    display: "flex",
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  item: {
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 30,
    padding: theme.spacing(1, 3),
    marginLeft: theme.spacing(2),
    boxShadow: "0px 0px 3px #e0e0e0",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
  },

  active: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },

  chartContainer: {
    display: "flex",
    flexDirection: "column",
  },

  barTabsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },

  barTabButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: 30,
    padding: theme.spacing(1, 3),
    margin: "0 3px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
  },

  barTabButtonSelected: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
}));
