import React from 'react';

interface SvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

export const ReloadSVG = (props: SvgProps) => {
    const { height = "16", width = "16", fill = '#0C1E33' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.65 2.34902C12.02 0.719015 9.71002 -0.220984 7.17002 0.0390157C3.50002 0.409016 0.480021 3.38902 0.0700213 7.05902C-0.479979 11.909 3.27002 15.999 8.00002 15.999C11.19 15.999 13.93 14.129 15.21 11.439C15.53 10.769 15.05 9.99902 14.31 9.99902C13.94 9.99902 13.59 10.199 13.43 10.529C12.3 12.959 9.59002 14.499 6.63002 13.839C4.41002 13.349 2.62002 11.539 2.15002 9.31902C1.31002 5.43902 4.26002 1.99902 8.00002 1.99902C9.66002 1.99902 11.14 2.68902 12.22 3.77902L10.71 5.28902C10.08 5.91902 10.52 6.99902 11.41 6.99902H15C15.55 6.99902 16 6.54902 16 5.99902V2.40902C16 1.51902 14.92 1.06902 14.29 1.69902L13.65 2.34902Z" fill={fill} />
        </svg>
    );
};

