import { combineReducers } from "@reduxjs/toolkit";
import { portfolioReducer } from "../Onboarding/portfolios/portfolioSlice";

const OnboardingReducer = combineReducers({
  portfolio: portfolioReducer,
});

export type AppState = ReturnType<typeof OnboardingReducer>;

export default OnboardingReducer;
