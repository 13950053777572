import { useEffect, useState } from "react";
import { ILine } from "../../../../components/MultiLineChart";
import { convertTimeZone } from "../../../../../shared/utils/utils";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { fetchAsync } from "../../../../../shared/utils/apiService";

enum Lines {
  InternalTemperature2 = "Inverter interior temperature 2",
  InternalTemperature1 = "Inverter interior temperature 1",
  AvgTemperature = "Average Temeperature",
}

const ln: Array<ILine> = [
  {
    key: Lines.AvgTemperature,
    stroke: "#E6B333",
  },
  {
    key: Lines.InternalTemperature1,
    stroke: "#3366E6",
  },
  {
    key: Lines.InternalTemperature2,
    stroke: "#999966",
  },
];

interface Item {
  actualAmbTemp: number;
  date: string;
  sensorTime: string;
  heatSinkTemp: number;
  interiorTemp1: number;
  interiorTemp2: number;
  number: number;
  plantId: string;
  tiltType: string;
}

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
}

interface Data {
  data: Array<any>;
  lines: Array<ILine>;
  error: string;
  loading: boolean;
}

export const useInverterTemperature = ({
  plantId,
  date,
  blockId,
  inverterId,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { client } = useSelector(clientSelector);
  const [lines, setlines] = useState([]);

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }

    const newData = data?.map((item) => {
      let itemObj = {};
      let values = [];
      let lns = [];
      if (item.actualAmbTemp !== null) {
        itemObj[Lines.AvgTemperature] = item.actualAmbTemp;
        values.push({ key: Lines.AvgTemperature, value: item.actualAmbTemp });
        lns.push(ln[0]);
      }
      if (item.interiorTemp1 !== null) {
        itemObj[Lines.InternalTemperature1] = item.interiorTemp1;
        values.push({
          key: Lines.InternalTemperature1,
          value: item.interiorTemp1,
        });
        lns.push(ln[1]);
      }
      if (item.interiorTemp2 !== null) {
        itemObj[Lines.InternalTemperature2] = item.interiorTemp2;
        values.push({
          key: Lines.InternalTemperature2,
          value: item.interiorTemp2,
        });
        lns.push(ln[2]);
      }
      setlines(lns);
      return {
        sensor_time: convertTimeZone(
          client?.timeZone,
          "HH:mm",
          item.sensorTime
        ),
        ...itemObj,
        values: values,
      };
    });
    setData(newData);
    setLoading(false);
  };

  useEffect(() => {
    if (!plantId || !date) return;

    setError("");
    setLoading(true);
    fetchAsync(
      `SkyfriSidemApi/v1/GetInverterInteriorTemperatureGraph1h?Date=${date}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}`,
      "GET"
    )
      .then((data: Array<Item>) => {
        transformData(data);
      })
      .catch(setError);
  }, [plantId, date, blockId, inverterId]);

  return {
    data,
    lines,
    error,
    loading,
  };
};
