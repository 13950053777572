import React, { useState } from "react";
import { Checkbox, TextField } from "@mui/material";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Props {
  value?: any;
  optionsData: any[];
  label: string;
  placeholder: string;
  handleChange?: (event?: any, value?: any) => void;
}
export const AutocompleteCheckbox = (props: Props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const allSelected = props?.optionsData?.length === selectedOptions?.length;
  const handleToggleOption = selectedOptions =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = option => `${option.label}`;
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(props?.optionsData);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find(option => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = props?.optionsData?.filter(el => el.value !== "select-all");
        return props.handleChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return props.handleChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions()
      props.handleChange(selectedOptions);
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = params => (
    <TextField sx={{
      "& .MuiInputLabel-root": {
        top: "8px",
      },
      "& .MuiInputLabel-shrink": {
        top: "0px",
      }
    }} {...params} label={props?.label} />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={2}
      options={props?.optionsData}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ label: "Select all", value: "select-all" }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};
