import { Cell } from "../../../../shared/components/Table";

const x1: Cell = {
  key: "roleDisplayName",
  label: "Role Name",
  align: "left",
  background:"#EFF0F0"
};

const x2: Cell = {
  key: "roleDescription",
  label: "Role Description",
  align: "left",
  background:"#EFF0F0"
};

const x3: Cell = {
  key: "edit",
  label: "",
  align: "right",
  background:"#EFF0F0"
};

export const roleViewHeaderCell = [x1,x2,x3];
export const roleViewTableType = "role";
export const filters=["roleName", ]