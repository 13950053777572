import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { SidemPagesEnum } from "../../sidem";
import { getPlantProfileInfo } from "../../features/Sidem/portfolios/plants/plantsActions";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { getClientInfo } from "../../features/Sidem/client/clientActions";
import { useNavigate, useLocation } from "react-router-dom";

interface ClientInfo {
  clientLogo: string;
  clientName: string;
  portfolioImages: string;
  pr: number;
  budget_PR: number;
  revenue: number;
  portfolios: number;
  timeZone: string;
}

export const SidemNavigation: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [clientInfo, setClientInfo] = useState({} as ClientInfo);
  const [portfolioName, setPortfolioName] = useState("");
  const [plantName, setPlantName] = useState("");
  const [plantId, setPlantId] = useState(null);
  const [inverterId, setInverterId] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();

  const goTo = (url: SidemPagesEnum) => {
    switch (url) {
      case SidemPagesEnum.PortfolioPage:
        return navigate(`/sidem/portfolios/${portfolioName}`);
      case SidemPagesEnum.PlantPage:
        return navigate(
          `/sidem/portfolios/${portfolioName}/plant/${plantId}`
        );
      case SidemPagesEnum.InverterPage:
        return navigate(
          `/sidem/portfolios/${portfolioName}/plant/${plantId}/block/${blockId}/inverter/${inverterId}`
        );
    }
  };

  const getNavigationDetails = async () => {
    const pathname = location?.pathname;
    const url = pathname?.split("/");

    const portfolioId = url[3]?.replace(/[%0-9]/g, ' ');
    const plantId = url[5];
    const blockId = url[7];
    const inverterId = url[9];

    if (!portfolioId) {
      setInverterId(null);
      setBlockId(null);
      setPlantName("");
      setPlantId(null);
      return setPortfolioName("");
    }
    setPortfolioName(portfolioId);

    if (!plantId) {
      setBlockId(null);
      setInverterId(null);
      setPlantName("");
      setPlantId(null);
      return;
    }
    setPlantId(plantId);

    const data: any = await dispatch(getPlantProfileInfo(plantId));
    if (data) {
      setPlantName(data?.plantName);
    }

    if (!inverterId || !blockId) {
      setInverterId(null);
      setBlockId(null);
      return;
    }
    setInverterId(inverterId);
    setBlockId(blockId);
  };

  useEffect(() => {
    if (!location) return;

    getNavigationDetails();
  }, [location]);

  const getData = async () => {
    const data: any = await dispatch(getClientInfo());
    setClientInfo(data);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes("/portfolios")) {
      setTitle(`Portfolio overview`);
      return;
    }

    if (location?.pathname?.includes("/alarm-overview")) {
      setTitle(`Alarm overview`);
      return;
    }

    if (location?.pathname?.includes("/report")) {
      setTitle(`Reports`);
      return;
    }
    //if (location?.pathname?.includes("/custom-reports")) {
    //  setTitle(`Custom report`);
    //  return;
    //}
  }, [location]);

  return (
    <>
      <Typography
        className={classes.subtitle}
        variant="body1"
        noWrap
        onClick={() =>
          location?.pathname?.includes("/portfolios")
            ? navigate('/analytics/analytics-dashboard')
            : null
        }
      >
        {!portfolioName?.length ? title : clientInfo.clientName}
      </Typography>
      {!portfolioName?.length ? null : (
        <>
          <div className={classes.arrow}>
            <ArrowRightAltIcon />
          </div>
          <Typography
            className={classes.subtitle}
            variant="body1"
            noWrap
          // onClick={() => goTo(SidemPagesEnum.PortfolioPage)}
          >
            {portfolioName}
          </Typography>
        </>
      )}

      {!plantName?.length ? null : (
        <>
          <div className={classes.arrow}>
            <ArrowRightAltIcon />
          </div>
          <Typography
            className={classes.subtitle}
            variant="body1"
            noWrap
            onClick={() => goTo(SidemPagesEnum.PlantPage)}
          >
            {plantName}
          </Typography>
        </>
      )}

      {!inverterId || !blockId ? null : (
        <>
          <div className={classes.arrow}>
            <ArrowRightAltIcon />
          </div>
          <Typography
            className={classes.subtitle}
            variant="body1"
            noWrap
            onClick={() => goTo(SidemPagesEnum.InverterPage)}
          >
            Block-{blockId} Inverter-{inverterId}
          </Typography>
        </>
      )}
    </>
  );
};
