import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPortfolioCardsPlantSpecific,
  getPortfolioCardsPlantSpecificToday,
} from "../../../features/Sidem/portfolios/portfolioActions";
import { portfolioSelector, setGlobalDateForCards } from "../../../features/Sidem/portfolios/portfolioSlice";
import { getDateForMaps } from "../../../shared/utils/utils";
import {
  barChartData,
  ChartTypes,
  lineChartData,
} from "../../components/CardsCharts";
import { Widget } from "../../components/CardsPageWidget";
import { Field } from "../../components/Fields";
import { formatTypes } from "../ClientPage/utils";
import { useSharedFormState } from "./../../../shared/utils/sharedStates";

interface PlantItem {
  actualAvailability: number;
  actualCUF: number;
  actualGen: number;
  actualIrradiation: number;
  actualPR: number;
  availabilityDelta: number;
  budgetAvailabilty: number;
  budgetCUF: number;
  budgetGen: number;
  budgetIrradiation: number;
  budgetPR: number;
  client: string;
  energyDelta: number;
  genDifference: number;
  plantImages: string;
  plantLayout: string;
  gridAvailability: number;
  irradationDelta: number;
  latitude: number;
  longitude: number;
  numberofAlarms: number;
  plantId: number;
  plant_Name: string;
  prDelta: number;
  totalACMW: number;
  invertersAv: number;
  invertersDown: number;
  totalDCMW: number;
  actualPower: number;
  locationName: string;
  weatherDescription: string;
  weatherIcon: string;
  weatherId: string;
  weatherMain: string;
  weatherReport: string;
  date: string;
  tempCorrectedPr: number;
}

const getFields = (item: PlantItem): Array<Field> => [
  {
    title: "Capacity",
    value: item.totalACMW?.toFixed(1),
    unit: "MW ac",
  },
  {
    title: `Generation`,
    value:
      item.actualGen.toString().length > 4
        ? ` ${(item.actualGen / 1000)?.toFixed(1)}`
        : ` ${item.actualGen?.toFixed(1)}`,
    unit: item.actualGen.toString().length > 4 ? "MWh" : "KWh",
    growth: item.energyDelta * 100,
  },
  {
    title: "PR",
    value: (item.actualPR * 100)?.toFixed(1),
    growth: item.prDelta * 100,
    unit: "%",
  },
  {
    title: "Irradiation",
    value: `${item.actualIrradiation?.toFixed(1)}`,
    unit: "kWh/m²",
    growth: item.irradationDelta * 100,
  },
  {
    title: "Availability",
    value: (item.actualAvailability * 100)?.toFixed(1),
    unit: "%",
    growth: item.availabilityDelta * 100,
  },
  {
    title: "CUF/PLF",
    value:
      typeof item.actualCUF === "number"
        ? (item.actualCUF * 100)?.toFixed(1)
        : "N/A",
    unit: typeof item.actualCUF === "number" ? "%" : "",
    growth: Number(((item?.actualCUF - item?.budgetCUF) * 100).toFixed(2)),
  },
  {
    title: "Grid Availability",
    value: (item.gridAvailability * 100)?.toFixed(1) || 0,
    unit: "%",
  },
  {
    title: "Active Power",
    value: Number(item?.actualPower).toFixed(0),
    unit: "KW"
  },
  {
    title: "TCPR",
    value: Number(item?.tempCorrectedPr * 100)?.toFixed(1),
    unit: "%",
  },
];

const getWidgets = (items: Array<PlantItem>): Array<Widget> =>
  items.map((item) => ({
    profit: parseInt(item.genDifference?.toString()),
    prDelta: item?.prDelta,
    cufDelta: (item?.actualCUF - item?.budgetCUF).toFixed(2),
    imageUrl: (item.plantImages || "").replace(/\s+/g, ""),
    latitude: item.latitude,
    longitude: item.longitude,
    path: item.plantId?.toString(),
    actualResult: item.actualPR * 100,
    expectedResult: item.budgetPR * 100,
    chartType: ChartTypes.Bullet,
    chartData: {
      actualResult: item.actualPR * 100,
      expectedResult: item.budgetPR * 100,
    },
    workingInverters: item.invertersAv - item.invertersDown,
    totalInverters: item.invertersAv,
    title: item.plant_Name,
    fields: getFields(item),
    errors: item.numberofAlarms,
    location: item.locationName,
    weathers: [{
      description: item.weatherDescription,
      icon: item.weatherIcon,
      id: item.weatherId,
      main: item.weatherMain,
      report: item.weatherReport,
      dateTime: getDateForMaps(item?.date),
    }]
  }));

const minute = 1000 * 60;

export const usePortfolioWidgets = (
  portfolioName,
  setLoading
) => {
  const [widgets, setWidgets] = useState([]);
  const [format, setFormat] = useState("");

  const { automaticRefresh } = useSharedFormState();
  const dispatch = useDispatch();
  const { date } = useSelector(portfolioSelector);

  useEffect(() => {
    if (!format) {
      if (!date) {
        setFormat(formatTypes[0].value)
      }
      return;
    }
    dispatch(setGlobalDateForCards(format))
  }, [format, date])

  const getCards = async (interval?: number) => {
    setLoading(true);
    let cards: any;
    if (date === "Today") {
      cards = await dispatch(
        getPortfolioCardsPlantSpecificToday(portfolioName)
      );
    } else {
      cards = await dispatch(
        getPortfolioCardsPlantSpecific(date, portfolioName)
      );
    }
    if (cards) {
      setWidgets(getWidgets(cards));
    }
    setLoading(false);

    if (interval && automaticRefresh) {
      return setInterval(() => {
        getCards();
      }, interval);
    }
  };

  useEffect(() => {
    if (!date) return;
    let interval;
    const getData = async () => {
      interval = await getCards(minute * 5);
    };
    getData();
    return () => {
      clearInterval(interval);
    };
  }, [date, portfolioName]);

  const getChartOptions = (widget: Widget, chartType: ChartTypes) => {
    switch (chartType) {
      case ChartTypes.Bullet:
        return {
          chartType: ChartTypes.Bullet,
          chartData: {
            actualResult: widget.actualResult,
            expectedResult: widget.expectedResult,
          },
        };
      case ChartTypes.BarChart:
        return {
          chartType: ChartTypes.BarChart,
          chartData: {
            values: barChartData,
            level: 0,
          },
        };
      case ChartTypes.LineChart:
        return {
          chartType: ChartTypes.LineChart,
          chartData: {
            values: lineChartData,
          },
        };
    }
  };

  const updateWidgetChart = (widget: Widget, chartType: ChartTypes) => {
    setWidgets(
      widgets?.map((item: Widget) => {
        if (item.path === widget?.path) {
          return {
            ...widget,
            ...getChartOptions(widget, chartType),
          };
        }

        return item;
      })
    );
  };

  return {
    widgets,
    updateWidgetChart,
    date,
    setFormat,
  };
};
