import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import { GLOBAL_COLORS } from "../../../shared/utils/utils";

interface Button {
  label: string;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

interface Props {
  buttons: Button[];
}

const getButtonStyle = (button: Button) => {
  return {
    height: "30px",
    backgroundColor: button.active
      ? GLOBAL_COLORS.LIGHT_PRIMARY_ACCENT
      : GLOBAL_COLORS.WHITE,
    color: button.active ? GLOBAL_COLORS.WHITE : GLOBAL_COLORS.BLACK,
    "&:hover": {
      backgroundColor: button.active
        ? GLOBAL_COLORS.LIGHT_PRIMARY_ACCENT
        : GLOBAL_COLORS.LIGHTGREY,
      color: button.active ? GLOBAL_COLORS.WHITE : GLOBAL_COLORS.BLACK,
    },
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: "4px"
  };
};

export const ChartGroupButton: React.FC<Props> = ({ buttons }) => {
  return (
    <ButtonGroup
      variant="contained"
      aria-label="Chart top menu"
      sx={{
        boxShadow: "none",
        border:
          buttons.length > 1
            ? `1px solid ${GLOBAL_COLORS.LIGHT_PRIMARY_ACCENT}`
            : "none",
        borderRadius: "6px",
      }}
    >
      {buttons.map((button) => {
        const buttonStyle = getButtonStyle(button);
        return (
          <Button
            key={button.label}
            onClick={button.onClick}
            style={{
              textTransform: "none",
            }}
            color={"primary"}
            sx={
              button.style ? { ...buttonStyle, ...button.style } : buttonStyle
            }
            size={"small"}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
