import React, { useState } from "react";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import { Field, NewFields } from "../Fields";
import { ChartTypes } from "../CardsCharts";
import { useStyles } from "./styles";
import { Grid } from "@mui/material";
import { ReactComponent as LIST } from '../../../shared/icons/sidem/LIST.svg';
import { ReactComponent as LOCATION } from '../../../shared/icons/sidem/LOCATION.svg';
import { PortfolioPopup } from "../PortfolioPopup";
import { PlantPopup } from "../PlantPopup";


export interface Widget {
  profit: number;
  title: string;
  fields: Array<Field>;
  imageUrl?: string;
  latitude?: number;
  longitude?: number;
  path?: string;
  pr?: string;
  workingInverters?: number;
  totalInverters?: number;
  actualResult?: number;
  expectedResult?: number;
  chartType?: ChartTypes;
  chartData?: any;
  errors?: number;
  location?: string;
  weathers?: any;
}

export interface WidgetConfig {
  showTitle?: boolean;
  chartComponent?: React.ReactNode;
  customMenu?: React.ReactNode;
  customFooter?: React.ReactNode;
  titleRightPart?: React.ReactNode;
}

interface WidgetProps {
  format: string;
  formatTypes: Array<{ label: string; value: string }>;
  onSelectFormat: (value: string) => void;
  widget: Widget;
  imageService: (url: string) => string;
  onClick?: () => void;
  config?: WidgetConfig;
  definition?: any;
  item?: any;
  weatherFeature?: any;
  type?: string;
  openPortfolioModal?: boolean;
  setOpenPortfolioModal?: (arg: boolean) => void;
  portfolioPopupData?: any;
  plantTitle?: string;
  setPlantTitle?: any;
  popupLoading?: boolean;
  popupError?: string;
  fieldCount?: number;
}

export const CardsPageWidget: React.FC<WidgetProps> = (props: WidgetProps) => {
  const classes = useStyles();

  const [openPlantModal, setOpenPlantModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");





  const {
    widget,
    // config,
    imageService,
    // onClick = () => { },
    // format,
    // formatTypes,
    // onSelectFormat,
    weatherFeature,
    item,
    type,
    openPortfolioModal,
    setOpenPortfolioModal,
    portfolioPopupData,
    plantTitle,
    setPlantTitle,
    popupLoading,
    popupError,
    fieldCount,
  } = props;
  const { title, errors, profit, fields, imageUrl, location } = widget;

  const prevent = (fn, defaultOnly) => {
    return (e, ...params) => {
      e && e.preventDefault()
      !defaultOnly && e && e.stopPropagation()
      fn(e, ...params)
    }
  }

  return (
    <div className={clsx(classes.wrapper, { [classes.errorWrapper]: errors })}>

      <PortfolioPopup
        isOpen={openPortfolioModal}
        title="Plants summary"
        onClose={() => {
          setPlantTitle("")
          setOpenPortfolioModal(false)
        }}
        height={"auto"}
        width={"60%"}
        portfolioPopupData={portfolioPopupData}
        plantTitle={plantTitle}
        popupLoading={popupLoading}
        error={popupError}
      />
      <PlantPopup
        isOpen={openPlantModal}
        title={modalTitle}
        onClose={() => setOpenPlantModal(false)}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.image}
        onClick={() => props.onClick()}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${imageService(imageUrl)})`,
          backgroundSize: "cover",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <Grid item xs={6} style={{ padding: "14px 14px 0px 14px" }}>
          <div className={classes.title}>{title}</div>
        </Grid>
        <Grid item xs={4} style={{ paddingRight: "24px", marginTop: "20px" }}>
          {
            type === "PortfolioPage" ?
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton className={classes.plantSummary}
                  aria-label="Plants summary"
                  size="small"
                  component="span"
                  onClick={prevent(() => {
                    setOpenPortfolioModal(true)
                    setPlantTitle(title)
                  }, "")}
                >
                  <LIST />
                  <span className={classes.buttonText}>Plants summary</span>
                </IconButton>
              </Grid>
              :
              null
            // <>
            //   <Grid
            //     container
            //     direction="row"
            //     justifyContent="flex-end"
            //     alignItems="center"
            //     style={{ paddingTop: "5px" }}
            //   >
            //     <IconButton className={classes.issuesButton}
            //       aria-label="Issues"
            //       size="small"
            //       component="span"
            //       onClick={prevent(() => {
            //         setOpenPlantModal(true)
            //         setModalTitle("Issues")
            //       }, "")}
            //     >
            //       <span className={classes.issues}>
            //         <Badge badgeContent={5} color="error" />
            //       </span>
            //       <span className={classes.buttonText}>Issues</span>
            //     </IconButton>
            //   </Grid>
            // </>
          }
        </Grid>
        {/* TODO */}
        {/* {
          type === "PortfolioPage" ?
            null
            :
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ paddingTop: "28px" }}
              >
                <span className={classes.mapview}>  <LOCATION /><span> {location}</span></span>
              </Grid>
            </Grid>
        } */}

      </Grid>
      <div className={classes.content} onClick={() => props.onClick()}>
        <NewFields
          fields={item}
          definition={props.definition}
          weathers={weatherFeature}
          fieldCount={fieldCount}
        />
      </div>
    </div>
  );
};
