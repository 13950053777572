import { Grid } from "@material-ui/core";
import React from "react";
import { PageTitle } from "../../../shared/base/PageTitle/PageTitle.component";
import { useStyles } from "./styles";

const Header: React.FC<any> = ({ title, headerDropdown, InfoBtn }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.title}>
          <div className={classes.headerTitle}>
            <PageTitle label={title || ""} />
          </div>
          <div className={classes.downPart}>
            {headerDropdown && headerDropdown()}
          </div>
          {InfoBtn && InfoBtn()}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12}>
          <hr className={classes.line} />
        </Grid> */}
      </Grid>
    </div>
  );
};

export { Header };
