import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({ active, payload }: { active?: boolean, payload?: Array<any> }) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const { name } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>{name}</strong>
      </div>
      {
        payload?.map(item => {
          return (
            <div>
              {item.name}: {item.value?.toFixed(1)} (°C)
            </div>
          )
        })
      }
    </TooltipWrapper>
  );
};
