import React, { useCallback, useRef, useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import { Badge, Box } from "@material-ui/core";
import NotificationsList from "../NotificationsList";
import { ReactComponent as ALARM } from "../../../shared/icons/app/ALARM.svg";
import { ReactComponent as TRANSPARENT_STRIPE } from "../../../shared/icons/app/TRANSPARENT_STRIPE.svg";
import { ReactComponent as STRIPE } from "../../../shared/icons/app/STRIPE.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNotificationSidebar } from "./useNotificationSidebar";
import { useStyles } from "./styles";
import clsx from "clsx";

export const NotificationsSidebar: React.FC<any> = ({
  drawerOpen,
  active,
  setActive,
  isMobile,
}) => {
  const classes = useStyles();

  const {
    alarms,
    numberOfAlarms,
    openNotifications,
    setAnchorEl,
    setOpen,
    setOpenNotifications,
    loading,
    setAlarms,
    setLoading,
  } = useNotificationSidebar();

  const notificationRef = useRef(null);
  const isAllowed = () => {
    if (window.location.href.includes("sidem")) return true;
    return false;
  };

  const closeNotificationsList = () => {
    setOpenNotifications(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside the notification list and trigger element
      if (
        openNotifications &&
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        closeNotificationsList();
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openNotifications]);

  const handleNotificationsClick = useCallback((event) => {
    setOpenNotifications((openUserProfile) => !openUserProfile);
    setAnchorEl(event.currentTarget);
    setActive(!active);
  }, []);

  return (
    <>
      <div
        ref={notificationRef}
        id="globalNotifications"
        className={clsx(
          classes.notifications,

          {
            [classes.notificationsOpen]: drawerOpen,
          },
          {
            [classes.notificationsActive]:
              openNotifications && active && !isMobile,
          }
        )}
        onClick={handleNotificationsClick}
      >
        <div style={{ height: 56, display: "flex" }}>
          <span>
            {!isMobile && openNotifications ? (
              <STRIPE />
            ) : (
              <TRANSPARENT_STRIPE />
            )}
          </span>

          <span
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Badge
              badgeContent={numberOfAlarms}
              color="error"
              className={classes.badge}
            >
              <ALARM />
            </Badge>
          </span>
        </div>
        {!isMobile && drawerOpen && (
          <span className={classes.menuTitle}>Notifications</span>
        )}
        {isMobile && openNotifications && (
          <SwipeableDrawer
            anchor={"top"}
            sx={{ top: "100px" }}
            open={openNotifications}
            onClose={() => setOpenNotifications(false)}
            onOpen={() => setOpenNotifications(true)}
          >
            <Box
              sx={{
                height: "100vh",
                marginTop: "55px",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              role="presentation"
            >
              <NotificationsList
                loading={loading}
                setLoading={setLoading}
                isMobile={isMobile}
                alarms={alarms}
                setAlarms={setAlarms}
                close={() => setOpen(false)}
              />
            </Box>
          </SwipeableDrawer>
        )}
        {!isMobile && openNotifications && (
          <div
            style={{
              bottom: !isMobile && "-168px",
              left: drawerOpen ? "230px" : "57px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              position: "absolute",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <NotificationsList
              alarms={alarms}
              setAlarms={setAlarms}
              close={() => setOpen(false)}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};
