import { useSearchParams } from "react-router-dom";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useGetMppStatusBarQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { useAppSelector } from "../../../../../store/rootStore";

export const useStatusWidget = () => {
  const [url] = useSearchParams();
  const plantId = url.get("plantId");

  const { globalPortfolioName } = useAppSelector(monitoringSharedSelector);

  const {
    data: mppStatusBarData,
    isLoading: isMppStatusBarFetching,
    isError: isMppStatusBarError,
  } = useGetMppStatusBarQuery(
    { plantId, portfolioName: globalPortfolioName },
    {
      skip: isAnyItemFalsy([plantId, globalPortfolioName]),
    }
  );

  return {
    statusLoading: isMppStatusBarFetching,
    statusData: mppStatusBarData ? mppStatusBarData.statusData : null,
    statusError: Boolean(
      mppStatusBarData
        ? mppStatusBarData.statusError
        : false || isMppStatusBarError
    ),
  };
};

export type StatusWidgetData = ReturnType<typeof useStatusWidget>;
