import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";

interface ButtonProps {
  size?: 40 | 32;
  type?: "primary" | "secondary" | "tertiary";
  state?: "default" | "hover" | "focused" | "disabled";
  onClick?: any;
  children?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  icon?: React.ReactNode;
  width?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingIcon?: React.ReactNode;
}

export const Button2: React.FC<ButtonProps> = ({
  size = 40,
  type = "primary",
  state = "default",
  onClick = () => {},
  children,
  iconLeft,
  icon,
  iconRight,
  width,
  disabled,
  loading = false,
  loadingIcon,
}) => {
  const classes = useStyles(); // Retrieve the generated class names
  const iconOnly = !children && (iconLeft || iconRight);
  const classNames = [
    classes.button,
    classes[`button${size}`], // Use dynamic class name based on size
    classes[`button${type}`], // Use dynamic class name based on type and state
    icon && classes[`iconOnly${type}${size}`],
    iconLeft && classes.iconLeft,
    iconRight && classes.iconRight,
  ].join(" ");

  return (
    <button
      style={{ width }}
      className={`${classNames} ${loading ? classes.loading : ""}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? ( // Show spinner if loading is true
        <>{loadingIcon || <div>Loading...</div>}</>
      ) : (
        <>
          {iconLeft && (
            <span className={classes.iconLeft} style={{ marginRight: "10px" }}>
              {iconLeft}
            </span>
          )}
          {icon && <span>{icon}</span>}
          {children && <span>{children}</span>}
          {iconRight && (
            <span className={classes.iconRight} style={{ marginLeft: "10px" }}>
              {iconRight}
            </span>
          )}
        </>
      )}
    </button>
  );
};
