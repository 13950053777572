import { legendArr, heatMapColors } from "../../../../components/HeatmapChart";
import { InverterHeatmapChart } from "../../../../components/InverterHeatmapChart";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";
import { CustomLegend } from "./CustomLegend";
import { useHeatmap } from "./useInverterHeatmap";
interface Props {
  plantId: string;
  date: string;
}
export const InverterHeatmapWidget: React.FC<Props> = (props) => {
  const { plantId, date } = props;
  const { data, xLabels, yLabels, loading, error, popUpData } = useHeatmap({
    plantId,
    date,
  });
  const showInverterHeatmap = () => {
    return (
      <>
        {
          data.map((item, idx) => (
            <div style={{ padding: "10px" }}>
              <InverterHeatmapChart
                popUpData={popUpData[idx]}
                data={[data[idx]]}
                xLabels={xLabels[idx]}
                yLabels={yLabels}
                heatMapColors={heatMapColors}
                date={date}
              />
            </div>
          ))
        }
      </>
    )
  }
  return (
    <Widget
      label="Inverter Heatmap"
      height={"auto"}
    >
      <Loader loading={loading} height={"auto"} error={error} errorTextSize={32} />
      {!data?.length || !popUpData.length || !xLabels?.length || !yLabels?.length || loading ? null : (
        <>
          <CustomLegend legendData={legendArr} />
          {
            showInverterHeatmap()
          }
        </>
      )}
    </Widget>
  );
};
