import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";

import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { UseModuleSave } from "./useModuleSave";
import {
  GetFormik,
  initializeValidationsObsolete,
  initializeValuesObsolete,
} from "./../../components/Utils/utils";
import { formSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";

interface Props {
  subsidiaryId: string;
  type: string;
  callback: any;
}

export const ModuleSavePage: React.FC<Props> = ({
  type,
  callback,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { moduleId } = useParams<{ moduleId: string }>();

  const { Subsidiary, Contacts, upsert } = UseModuleSave({
    moduleId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const createNewModule = moduleId === "new";

  useEffect(() => {
    initializeValidationsObsolete(formSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (moduleId || createNewModule) {
      initializeValuesObsolete(
        formSchema,
        createNewModule,
        Subsidiary,
        (resp) => {
          setInitialValues(resp);
        }
      );
    }
  }, [Subsidiary]);

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    upsert(fields, moduleId, (resp) => {
      if (resp) {
        setSeverity("success")
        setMessage("Record added successfully");
        setIsOpen(true);
      } else {
        setSeverity("error")
        setMessage("Operation  unsuccessful");
        setIsOpen(true);
      }
      setTimeout(() => {
        renderPage(`/onboarding/module`);
      }, 2000);
    });
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewModule ? <>Add new module </> : <>Edit module</>}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(formSchema).map((x) => (
              <DynamicField name={x} data={formSchema[x]} formik={formik} />
            ))}
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save module"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Cancel"
              onCancel={() => {
                let URI = "/onboarding/module";
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
