import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CardsView from "./CardsView";
import MapView from "./MapView";
import { usePortfolioWidgets } from "./usePortfolioWidgets";
import {
  PortfolioHeaderData,
  usePortfolioHeader,
} from "../../features/PortfolioHeaderData";
import { Box, Tab, Tabs } from "@mui/material";
import { Header } from "../../components/PortfolioHeader";
import { OverviewHeader } from "../../../shared/components/Overview";
import { OverviewDropdown } from "../../components/OverviewDropdown";
import { useStyles } from "./styles";
import { Select } from "@material-ui/core";
import { MenuItem } from "@mui/material";

const definition = {
  Capacity: "It shows the Plant AC and DC Capcity",
  "Generation (kWh)":
    "It shows the actual irradiation | expected irradiation and irradiation delta for the plant",
  "Irradiation (kWh/m²)":
    "It shows the actual irradiation | expected irradiation and irradiation delta for the plant",
  Availability:
    "It shows the actual availability | expected availability and availability delta for the plant",
  "CUF/PLF": "It shows the actual CUF | expected CUF",
  Alert: "It shows the number of alert related to that plant",
  "Generation Delta":
    "It shows the generation delta from the expected generation",
  PR: "It shows the actual PR for the plant",
  Inverters:
    "It shows the number of inverter operational and number of inverter installed in that plant",
  "Generation last 7D":
    "It shows the bar chart of last 7 days generation and line chart of last 7 days irradiation for the portfolio",
  "Production factor":
    "Production factor is the diffence between generation delta and irradiation delta",
  "Generation & Irradiation delta":
    "Generation delta and irradiation delta is the % difference between the actaul and expected numbers of generation and irradiation respectively",
  "Last 7 days": "",
  "Last 30 days": "",
  "Grid Availability": "Loss due to the grid outages during generation hours",
};
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export interface Format {
  label: string;
  value: string;
}

const formatTypes: Array<Format> = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "Last 7 days", value: "Last7days" },
  { label: "MTD", value: "MTD" },
  { label: "QTD", value: "QTD" },
  { label: "YTD", value: "YTD" },
  { label: "FYTD", value: "FYTD" },
];

const PortfolioPage: React.FC<any> = ({ imageService }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const { widgets, updateWidgetChart, date, setFormat } = usePortfolioWidgets(
    portfolioId,
    setLoading
  );
  const { data } = usePortfolioHeader(date, portfolioId);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const getCustom = () => (
    <div>
      <div className={classes.title}>{portfolioId}</div>
      <div className={classes.labels}>
        <div className={classes.item}></div>
        <div className={classes.item} style={{ marginLeft: 16 }}></div>
      </div>
    </div>
  );
  const onChangeFormat = (e) => {
    const newValue = e.target.value;
    setFormat(newValue);
  };

  const formatDropdown = () => {
    if (!formatTypes?.length) return null;
    return (
      <div className={classes.selector}>
        <div className={classes.label}>Period</div>
        <Select
          variant={"outlined"}
          value={date}
          className={classes.select}
          onChange={onChangeFormat}
        >
          {formatTypes?.map((item, idx) => (
            <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Header headerDropdown={formatDropdown} title={portfolioId} />
        <OverviewHeader
          title="Overview"
          dropdown={
            <OverviewDropdown
              colsA={4}
              colsB={8}
              items={data.fields}
              fieldCount={7}
              children={
                <PortfolioHeaderData
                  {...data}
                  customComponentAboveFields={getCustom()}
                  definition={definition}
                />
              }
            />
          }
        />
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "white",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Cards View" {...a11yProps(0)} />
            <Tab label="Maps View" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CardsView
            error={error}
            loading={loading}
            imageService={imageService}
            portfolioName={portfolioId}
            headerData={data}
            widgets={widgets}
            format={date}
            formatTypes={formatTypes}
            setFormat={setFormat}
            updateWidgetChart={updateWidgetChart}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapView widgets={widgets} />
        </TabPanel>
      </Box>
    </>
  );
};

export default PortfolioPage;
