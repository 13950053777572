import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { getBearerToken } from "../../store/authToken";
import { axDelete, axGet, axPatch, axPost } from "../../store/axiosClient";

export async function fetchAsync(route: string, method: string, body: any = null): Promise<any> {
  const headers = new Headers();
  const bearer = "Bearer " + await getBearerToken();
  let path = route;
  headers.append("Authorization", bearer);
  headers.append("Ocp-Apim-Subscription-Key", process.env.REACT_APP_API_MKEY);

  const options: RequestInit = {
    method,
    headers,
  };

  let isformDate = body instanceof FormData;

  if (!isformDate) {
    headers.append("Content-Type", "text/json");
  }
  if (method !== "GET") {
    options.body = isformDate ? body : JSON.stringify(body);
  } else {
    if (body) {
      path = route + new URLSearchParams(body);
    }
  }

  const baseApiUrl = process.env.REACT_APP_API_URL;
  return fetch(baseApiUrl + path, options)
    .then((response) => {
      let contentType = response.headers.get("content-type");

      if (contentType === "text/csv") {
        return response.text();
      }

      if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        return response.blob();

      if (response?.ok == false) {
        return response;
      }

      return response.json();
    })
    .catch((error) => console.log(error));
}

export function axGetBase<P, T extends string = string>(url, startFunc: ActionCreatorWithoutPayload, successFunc: ActionCreatorWithPayload<P, T>, errorFunc: ActionCreatorWithPayload<P, T>) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(startFunc);
    try {
      const token = await getBearerToken();
      const data: any = await axGet(url, token);
      dispatch(successFunc(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(errorFunc(error));
      return error.response.data;
    }
  }
}

export function axPostBase<P, T extends string = string>(url, obj: any, startFunc: ActionCreatorWithoutPayload, successFunc: ActionCreatorWithPayload<P, T>, errorFunc: ActionCreatorWithPayload<P, T>) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(startFunc);
    try {
      const token = await getBearerToken();
      const data: any = await axPost(url, obj, token);
      dispatch(successFunc(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(errorFunc(error));
      return error.response.data;
    }
  }
}

export function axUpdateBase<P, T extends string = string>(url, obj: any, startFunc: ActionCreatorWithoutPayload, successFunc: ActionCreatorWithPayload<P, T>, errorFunc: ActionCreatorWithPayload<P, T>) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(startFunc);
    try {
      const token = await getBearerToken();
      const data: any = await axPatch(url, obj, token);
      dispatch(successFunc(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(errorFunc(error));
      return error.response.data;
    }
  }
}

export function axDeleteBase<P, T extends string = string>(url, startFunc: ActionCreatorWithoutPayload, successFunc: ActionCreatorWithPayload<P, T>, errorFunc: ActionCreatorWithPayload<P, T>) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(startFunc);
    try {
      const token = await getBearerToken();
      const data: any = await axDelete(url, token);
      dispatch(successFunc(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(errorFunc(error));
      return error.response.data;
    }
  }
}

export const urlEncodeObjectAsQueryParams = (obj) => {
  return Object.keys(obj)
    .map(function (key) {
      return key + "=" + obj[key];
    })
    .join("&");
};
