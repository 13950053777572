import { OnOffWidgetProps } from "./OnOffWidget.types";
import { useStyles } from "./styles";

export const OnOffWidget = ({
  label,
  status,
  backgroundColor,
  dotColor,
}: OnOffWidgetProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {label && <p className={classes.label}>{label}</p>}
      <div className={`${classes.statusWrapper}`} style={{ backgroundColor }}>
        <span
          className={`${classes.dot}`}
          style={{ backgroundColor: dotColor }}
        ></span>
        <p className={classes.status}>{status}</p>
      </div>
    </div>
  );
};
