import React from 'react';
import { Snackbar as ReactSnackbar } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  open: boolean;
  message: string;
  handleClose: () => void;
  vertical?: string;
  horizontal?: string;
  severity?: string;
}
const useStyles = makeStyles(() => ({
  warning: {
    background: '#ED6C02'
  },
  success: {
    background: '#2E7D32'
  },
  info: {
    background: '#0288D1'
  },
  error: {
    background: '#dc3545'
  }
})
);

export const Snackbar: React.FC<Props> = (props) => {
  const classes = useStyles();

  const getAnchorOrigin = () => {
    return props.horizontal && props.horizontal == "left" ? "left" : props.horizontal && props.horizontal == "right" ? "right" : "center";
  }
  return (
    <ReactSnackbar
      anchorOrigin={{
        vertical: props.vertical && props.vertical == "top" ? "top" : "bottom",
        horizontal: getAnchorOrigin()
      }}
      open={props.open}
      onClose={props.handleClose}
      autoHideDuration={2000}
      message={props.message}
      ContentProps={{
        classes: {
          root: props.severity === "success" ? classes.success : props.severity === "info" ? classes.info : props.severity === "error" ? classes.error : classes.warning
        }
      }}
    />
  )

}


