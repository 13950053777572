import { useMemo } from "react";
import Loader from "../../components/Loader";
import Widget from "../../components/Widget";
import { useInverterSoiling } from "./useInverterSoiling";
import { CustomLegend } from "./CustomLegend";
import { getBlockAndInvFromString, legendArr } from "./utils";
import { DownloadMenu } from "./DownloadMenu.component";
import { useNavigate, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts";
import TreemapModule from 'highcharts/modules/treemap';
import { InverterSoilingTooltip } from "./InverterSoilingTooltip.component";

TreemapModule(Highcharts);

interface Props {
  plantId: string;
  date: string;
}

type ExtendedHighchartPoint = Highcharts.Point & { equipmentName: string, soilingMain: number };

export const InverterSoilingWidget: React.FC<Props> = (props) => {
  const { plantId, date } = props;
  const { data, loading, error } = useInverterSoiling({
    plantId,
    date,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const chartOptions: Highcharts.Options = useMemo(() => {
    if (!data || data?.length === 0) {
      return {} as Highcharts.Options;
    }

    const maxValue = Math.max(...data.map((item) => item.soilingMain))

    const seriesData = data.map((item) => {
      const originalValue = item.soilingMain;
      // these calculations are only for displaying lower value properly and to prevent it from being almost invisible
      const resizedValue = maxValue !== 0 && (maxValue / 3) > originalValue ? maxValue / 3 : originalValue;

      return {
        name: item.equipmentName,
        originalValue: originalValue,
        value: resizedValue,
        equipmentName: item.equipmentName,
        soilingMain: item.soilingMain,
        color: item.fillColor
      }
    })

    return {
      chart: {
        type: 'treemap',
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              brightness: 0.2,
            }
          },
        }
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        borderWidth: 0,
        padding: 0,
        shadow: false,
        useHTML: true,
        formatter: function () {
          const newData = this.point as ExtendedHighchartPoint;

          const { block, inv } = getBlockAndInvFromString(newData.equipmentName)

          return renderToString(
            <InverterSoilingTooltip block={block} inv={inv} soilingLoss={newData.soilingMain} />
          )
        }
      },
      series: [{
        data: seriesData,
        type: 'treemap',
        cursor: 'pointer',
        layoutAlgorithm: 'strip',
        layoutStartingDirection: 'vertical',
        events: {
          click: function (event) {
            const newData = event.point as ExtendedHighchartPoint;
            const { block, inv } = getBlockAndInvFromString(newData.equipmentName)

            navigate(
              `${location.pathname}/block/${block}/inverter/${inv}`, {
              state: { date },
            })
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            const width = this.point.shapeArgs.width;
            const name = this.point.name
            const fontSize = Math.ceil(width / name.length) + 1;

            return `<span style="font-size: ${fontSize}px">${name}</span>`
          },
          style: {
            color: '#000000',
            textOutline: 'none',
          },
        },
      }],
    }
  }, [data])

  const hide = useMemo(() => !data?.length && loading, [data, loading]);

  return hide ? null : (
    <Widget label="Soiling Loss" height={500} size={12} customMenu={<DownloadMenu plantId={plantId} />}>
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />
      {chartOptions.series !== undefined ? (
        <div style={{ color: "black" }}>
          <HighchartsReact highcharts={Highcharts} style={{ height: '300px' }} options={chartOptions} />
          <CustomLegend legendData={legendArr} />
        </div>
      ) : (
        ""
      )}
    </Widget>
  );
};