import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  fields: {
    display: "grid",
    alignItems: "center",
    marginTop: "1em",
  },

  oneColumn: {
    gridTemplateColumns: "1fr",
  },

  field: {
    flex: 0.5,
    height: "5rem",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  fieldBottom: {
    borderBottom: "1px solid #E1E4E9",
  },

  fieldRight: {
    height: "5rem",
    borderLeft: "1px solid #E1E4E9",
  },

  fieldLabel: {
    fontWeight: 400,
    fontSize: 13,
    color: "#0C1E33",
    marginRight: theme.spacing(0.5),
  },

  oneColumnFieldLabel: {
    width: "50%",
  },

  fieldValue: {
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // width: "max-content",
    maxWidth: "inherit",
  },

  growth: {
    marginLeft: theme.spacing(0.5),
    color: "#79BEAA",
    fontWeight: 500,
    fontSize: 20,
    display: "flex",
    alignItems: "center",
  },

  growthIcon: {
    fontSize: "0.8em !important",
    marginBottom: 2,
  },

  negativeGrowth: {
    color: "#DE504F",
  },

  divider: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    height: 10,
    marginLeft: theme.spacing(0.5),
  },

  prevValue: {
    color: "#C94B4B",
  },
  value: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  prevvalue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginRight: theme.spacing(3),
  },
  unit: {
    fontWeight: 400,
    fontSize: 13,
    color: "#858E98",
    marginLeft: theme.spacing(0.5),
  },
  info: {
    fontWeight: 500,
    fontSize: 20,
    color: "#0C1E33",
  }
}));
