import {
  getSelectedString,
} from "../../../../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetAnalyticsSummaryCardProductionQuery } from "../../../../../../../features/Sidem/shared/sidemSharedApi";

export const useProductionFactor7D = () => {
  const [url] = useSearchParams();
  const plantIdUrl = url.get("plantId");
  const portfolioIdUrl = url.get("portfolioId");

  const portfolioName = portfolioIdUrl ? getSelectedString(JSON.parse(portfolioIdUrl), "label") : undefined;
  const plantId = plantIdUrl ? getSelectedString(JSON.parse(plantIdUrl), "value") : undefined;

  const { data, isFetching, error } = useGetAnalyticsSummaryCardProductionQuery(
    { portfolioName, plantId, period: 7 },
    { skip: !portfolioName || !plantId }
  );

  return {
    productionFactorData: data?.data,
    xAxis: data?.xAxis,
    yAxis: data?.yAxis,
    loading: isFetching,
    error: Boolean(error),
  };
};
