import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useBatteryStatusAndPercentage } from "./useBatteryStatusAndPercentage";
import { Battery } from "./Battery";
import { Divider, Skeleton } from "@mui/material";
import {
  BatteryStatuses,
  BatteryStatusesLabels,
} from "../../BatteryMonitoring.api.types";
import { useMemo } from "react";
import { OnOffWidget } from "../OnOffWidget";
import { batteryStatusBgColors, batteryStatusDotsColors } from "./constants";
import { getUnit, getValue } from "../../utils";

export const BatteryStatusAndPercentage = () => {
  const { data, isLoading } = useBatteryStatusAndPercentage();
  const classes = useStyles();

  const status = useMemo(() => {
    return (data?.status?.value || data?.status?.value === 0) &&
      (BatteryStatuses[Number(data.status.value) - 1] as keyof BatteryStatuses)
      ? (BatteryStatuses[
          Number(data.status.value) - 1
        ] as keyof BatteryStatuses)
      : BatteryStatuses.NO_DATA;
  }, [data]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.heading}>
          Battery
        </Typography>
        {isLoading ? (
          <Skeleton width="95px" height="25px" />
        ) : (
          <OnOffWidget
            backgroundColor={
              batteryStatusBgColors[status as keyof typeof BatteryStatuses]
            }
            dotColor={
              batteryStatusDotsColors[status as keyof typeof BatteryStatuses]
            }
            status={
              BatteryStatusesLabels[status as keyof typeof BatteryStatuses]
            }
          />
        )}
      </div>

      <div className={classes.body}>
        <Typography variant="h2" className={classes.percentage}>
          {isLoading ? (
            <Skeleton variant="rounded" width={100} height={35} />
          ) : (
            `${getValue(data?.stateOfCharge)}${getUnit(data?.stateOfCharge)}`
          )}
        </Typography>

        <div className={classes.batteryWrapper}>
          {data?.stateOfCharge ? (
            <Battery chargeLevel={data?.stateOfCharge?.value || 0} />
          ) : (
            <Skeleton variant="rounded" width={135} height={65} />
          )}
        </div>
      </div>

      <Divider sx={{ margin: "12px 0" }} />

      <div className={classes.footer}>
        <span className={classes.soh}>State of health</span>
        <span className={classes.sohValue}>
          {isLoading ? (
            <Skeleton variant="rounded" width={34} height={16} />
          ) : (
            `${getValue(data?.stateOfHealth)}${getUnit(data?.stateOfHealth)}`
          )}
        </span>
      </div>
    </div>
  );
};
