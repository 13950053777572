import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomTableHead } from '../../../shared/components/Table/TableHead.component';
import { Cell } from '../../../shared/components';
import { Box, TableContainer } from '@mui/material';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

interface PaginationProps {
    options: Array<number>;
    totalRecord?: number;
    onChange: (page: number, rowsPerPage: number) => void;
}

interface TableProps {
    module?: string;
    tableType?: string;
    headerCells: Array<Cell>;
    data: Array<any>;
    getCustomRow?: (row: any) => React.ReactNode;
    getCollapsedContent?: (data: any) => React.ReactNode;
    isOpen?: boolean;
    paginationProps?: PaginationProps;
    onDeleteClick?: (id: any) => void;
    onViewMoreClick?: (id: any) => void;
    maxHeight?: number | string;
    minWidth?: number | string;
    onUpdateField?: (id: any, key: string, value: string) => void;
    isDoubleClickActive?: boolean;
    isActionAllow?: boolean;
    handleCheckboxClick?: (data: any, roleInfo: any) => void;
    height?: string | number;
    paddingLeft?: string | number;
    width?: string | number;
    headerColor?: string;
    disableCheckList?: boolean;
    headerBgColor?: string;
    loading: boolean;

}

function Row(props: { loading, row, getCustomRow: (row: any) => React.ReactNode, headerCells: Array<Cell>, getCollapsedContent?: any }) {
    const { row, getCustomRow, getCollapsedContent, loading } = props;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const classes = useStyles();

    React.useEffect(() => {
        if (loading)
            setOpen(false)
    }, [loading])

    return (
        <>
            <TableRow
                hover
                sx={{
                    "& > *": {
                        borderBottom: null, cursor: "pointer", borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    '&:last-child td, &:last-child th': { borderBottom: 0, },
                    "td:last-child,td:first-child": { borderRight: 0 },
                }}
                className={classes.portfolioRow}
                onClick={(event) => { loading ? null : setOpen(!open) }}
            >
                <TableCell sx={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>

                    {
                        !row?.plant?.length || !row?.plant || loading ?
                            null
                            :
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                    }
                </TableCell>
                {getCustomRow(row)}
            </TableRow>

            {/* Dropdown */}
            {
                !row?.plant?.length || !row?.plant ?
                    null
                    :
                    row?.plant?.map((item) =>
                        <TableRow
                            sx={{ "& > *": { cursor: "pointer" }, '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                            onClick={() => navigate(`/sidem/portfolios/${item?.portfolioName}/plant/${item?.plantId}`)}>
                            <TableCell sx={{ borderBottom: 0 }} style={{ padding: 0 }} colSpan={13}>
                                <Collapse in={open} unmountOnExit>
                                    <Table
                                        sx={{
                                            minWidth: 750,
                                        }}
                                        aria-labelledby="tableTitle"
                                    >
                                        <TableBody>
                                            <TableRow
                                                sx={{ "& > *": { cursor: "pointer", borderRight: "1px solid rgba(224, 224, 224, 1)" }, "td:last-child,td:first-child": { borderRight: 0 } }}
                                                className={classes.plantRow}
                                            >
                                                <TableCell sx={{ minWidth: "40px", width: "40px", maxWidth: "40px" }}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                    >
                                                        {/* <ChevronRightRoundedIcon /> */}
                                                    </IconButton>
                                                </TableCell>
                                                {getCollapsedContent(item)}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )
            }
        </ >
    );
}

export const TableDropdown: React.FC<TableProps> = (props) => {
    const {
        maxHeight = null,
        headerCells,
        data,
        getCustomRow,
        getCollapsedContent,
        height = null,
        headerColor,
        headerBgColor,
        loading,
    } = props;

    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight, height, paddingBottom: "24px" }}>
                <Table aria-label="collapsible table"
                    sx={{
                        minWidth: 750,
                        "& .MuiTableCell-root": {
                            fontFamily: [
                                'Inter',
                                'Roboto',
                                'sans-serif'
                            ].join(',')
                        }
                    }}
                    aria-labelledby="tableTitle"
                    size={"medium"}>
                    <CustomTableHead
                        cells={headerCells}
                        headerColor={headerColor}
                        headerBgColor={headerBgColor}
                        paddingY={0}
                        fontWeight={400}
                        borderRight={true}
                    />
                    <TableBody>
                        {Array.isArray(data) ? (
                            data?.map((row) => (
                                <Row loading={loading} row={row} getCustomRow={getCustomRow} headerCells={headerCells} getCollapsedContent={getCollapsedContent} />
                            ))
                        ) :
                            <></>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    );
}
