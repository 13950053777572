import { getBearerToken } from "../../../store/authToken";
import * as MonitoringDashboardSlice from "./monitoringDashboardSlice";
import axios from "axios";
// import axios from "axios";

const sidemApiUrl = `SkyfriSidemApi/v1`;

export function downloadAllGraphsData(portfolio, plant, fromDate, toDate, binSensorTime) {
    return async () => {
        try {
            const token = await getBearerToken();
            const BASE_API_URL = process.env.REACT_APP_API_URL
                ? process.env.REACT_APP_API_URL
                : "https://skifryfpel-dev-apimngment.azure-api.net/";
            const data: any = await axios({
                method: "get",
                url:
                    BASE_API_URL +
                    sidemApiUrl +
                    `/GetMppReport?portfolioName=${portfolio}&plantId=${plant}&fromDate=${fromDate}&toDate=${toDate}&binSensortime=${binSensorTime}`,
                responseType: "blob",
                headers: {
                    authorization: "Bearer " + token,
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
                    responseType: "blob",
                },
            });
            return data.data;
        } catch (error) {
            return null
        }
    };
}


export const setGlobalDateAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.setGlobalDate(payload));
        return payload;
    };
};

export const setGlobalLiveButtonAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.setLiveButton(payload));
        return payload;
    };
};

export const setGlobalPlantIdAction = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.getPlantId(payload));
        return payload;
    };
};

export const setDateComparison = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.dateComparison(payload));
        return payload;
    };
};

export const setGlobalPortfolioNameAction = (payload: string) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.getPortfolioName(payload));
        return payload;
    };
};

export const setglobalSelectedInverterI = (payload: any) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(MonitoringDashboardSlice.getSelectedInverter(payload));
        return payload;
    };
};