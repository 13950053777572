import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  ListItemText,
} from "@mui/material";
import { Search } from "../../../../../shared/components";
import { useBatteryControlTableWidget } from "./useBatteryControlTableWidget";
import { getBatteryControlTableDisplayDateFormat, sources } from "./utils";
import { useStyles } from "./styles";
// import { DownloadDialogBox } from "../../../../../shared/components/DownloadDialogBox";
import { useDataManipulation, useDownload, useFilters, usePage } from "./hooks";
import Loader from "../../../../components/Loader";
import { ENERGY_PROVIDERS } from "../../../BatteryMonitoring/BatteryMonitoring.api.types";
import { getColorForProvider } from "../../../BatteryMonitoring/components/BatteryControlChartWidget/utils";

export const BatteryControlTable: React.FC = () => {
  const classes = useStyles();

  const {
    dataBatteryControl,
    dataBatteryControlCount,
    dataBatteryControlCountNotExecuted,
    handleNotExecutedOnlyChange,
    handlePageChange,
    handlePageReset,
    handleRequestSort,
    handleSearchChange,
    handleSourceFilterChange,
    isLoadingBatteryControl,
    notExecutedOnly,
    order,
    orderBy,
    pageNumber,
    pageSize,
    parsedData,
    renderValue,
    search,
    setPageSize,
    sortedData,
    sourceFilter,
  } = useBatteryControlTableWidget();

  const mappedSources = useMemo(() => {
    return [...sources]
    .sort((a, b) => a.localeCompare(b))
    .map((source) => {
        return (
          <MenuItem key={source} value={source}>
            <Checkbox checked={sourceFilter.includes(source)} />
            <ListItemText
              primary={`${ENERGY_PROVIDERS[source]} (${
                dataBatteryControlCount?.[source] || 0
              })`}
            />
          </MenuItem>
        );
      });
  }, [sources, parsedData, sourceFilter, dataBatteryControlCount]);

  const showAllSourcesOption = useMemo(() => {
    return sources.length > 1 ? (
      <MenuItem value="Show all">
        <Checkbox checked={sourceFilter.length === sources.length} />
        <ListItemText primary="Show all" />
      </MenuItem>
    ) : null;
  }, [sources, sourceFilter]);

  const displayData = useMemo(() => {
    return sortedData.map((row) => (
      <TableRow
        key={`${row.date.toISOString()}-${row.source}-${row.signal}-${
          row.executed
        }-${row.soc}`}
        className={classes.tableHead}
      >
        <TableCell>
          {getBatteryControlTableDisplayDateFormat(row.date)}
        </TableCell>
        <TableCell>
          <div className={classes.sourceCell}>
            <div
              className={classes.sourceSquare}
              style={{
                backgroundColor: getColorForProvider(row.source),
              }}
            ></div>
            <span>{ENERGY_PROVIDERS[row.source]}</span>
          </div>
        </TableCell>
        <TableCell>{row.soc}</TableCell>
        <TableCell>
          {row.signal} {row.value} kW
        </TableCell>
        <TableCell>{row.executed ? "Yes" : "No"}</TableCell>
      </TableRow>
    ));
  }, [sortedData]);

  const headerSourcesList = useMemo(() => {
    return sources.map((source) => {
      return (
        <div className={`${classes.sourceCell} ${classes.titleSourceCell}`}>
          <div
            className={classes.sourceSquare}
            style={{
              backgroundColor: getColorForProvider(source),
            }}
          ></div>
          <span>
            <span className={classes.titleSourceItemName}>
              {ENERGY_PROVIDERS[source]}
            </span>{" "}
            ({dataBatteryControlCount?.[source] || 0})
          </span>
        </div>
      );
    });
  }, [sources, dataBatteryControlCount]);

  const notExecutedOnlyLabel = useMemo(() => {
    return (
      <span className={classes.notExecutedOnlyLabel}>
        Not executed only ({dataBatteryControlCountNotExecuted?.Total || 0})
      </span>
    );
  }, [dataBatteryControlCountNotExecuted]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleWithCount}>
          <h3 className={classes.title}>Signals</h3>
          <span className={classes.itemsCount}>
            {dataBatteryControlCount?.Total !== undefined
              ? dataBatteryControlCount?.Total
              : ""}
          </span>
        </div>

        <div className={classes.titleSourcesWrapper}>{headerSourcesList}</div>
      </div>
      <div className={classes.providerDropdownWrapper}>
        <FormControl className={classes.providerDropdownFormControl}>
          <InputLabel shrink={true} id="source-label">
            Source
          </InputLabel>
          <Select
            notched={true}
            variant="outlined"
            labelId="source-label"
            id="source-dropdown"
            displayEmpty={true}
            label="Source"
            multiple
            value={sourceFilter}
            onChange={handleSourceFilterChange}
            renderValue={renderValue}
          >
            {showAllSourcesOption}
            {mappedSources}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={notExecutedOnly}
              onChange={(e) => handleNotExecutedOnlyChange(e.target.checked)}
              color="primary"
            />
          }
          label={notExecutedOnlyLabel}
        />
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell colSpan={5} classes={{ head: classes.head }}>
                <div className={classes.searchWrapper}>
                  <Search
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchChange}
                    onKeyUp={handleSearchChange}
                  />

                  <div className={classes.pageWrapper}>
                    <TablePagination
                      component="div"
                      count={dataBatteryControlCount?.Total || 0}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50]}
                      page={pageNumber}
                      onPageChange={(_, val) => handlePageChange(val)}
                      onRowsPerPageChange={(e) => {
                        setPageSize(Number(e.target.value));
                        handlePageReset();
                      }}
                      className={classes.tablePagination}
                      classes={{
                        toolbar: classes.toolbar,
                      }}
                    />
                    {/* TODO: kaz uncomment it when backend is ready */}
                    {/* <DownloadDialogBox
                      DownloadData={handleDownload}
                      loading={downloadLoading}
                      error={downloadError}
                    /> */}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "date"}
                  direction={orderBy === "date" ? order : "asc"}
                  onClick={() => handleRequestSort("date")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Source</TableCell>
              <TableCell>SoC</TableCell>
              <TableCell>Signal</TableCell>
              <TableCell>Executed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData}

            {isLoadingBatteryControl ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Loader loading={true} />
                </TableCell>
              </TableRow>
            ) : null}

            {!isLoadingBatteryControl && displayData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Loader error="No data" />
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
