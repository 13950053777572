import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  itemLabel: {
    marginLeft: "8px",
  },
  itemWrapper: {
    display: "flex",
    padding: "0px 8px",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    height: 32,
    marginBottom: theme.spacing(1.5),
    marginLeft: "18px",
    marginRight: "18px",
    border: "1px solid #bebebe",
    fontWeight: 500,
    borderRadius: "4px",
    color: "#0C1E33",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#f7f7f7",
    },
  },
  menuItemMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    height: 32,
    marginBottom: theme.spacing(1.5),
    marginLeft: "10px",
    marginRight: "10px",
    border: "1px solid #bebebe",
    fontWeight: 500,
    borderRadius: "4px",
    color: "#0C1E33",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "#f7f7f7",
    },
  },
  userItem: {
    display: "flex",
    alignItems: "center",
  },
  userProfileOpen: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  userProfileActive: {
    cursor: "pointer",
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    "&:hover": {
      cursor: "pointer",
      // backgroundColor: "rgba(151, 206, 253, 0.2) !important",
    },
  },
  userProfile: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      borderRadius: "0px",
    },
  },

  initials: {
    display: "flex",
    marginLeft: "9px",
    alignItems: "center",
  },
  initialsMobile: {
    width: 56,
    justifyContent: "space-around",
    alignItems: "center",
    display: "flex",
    height: 56,
  },

  bgdrawerOpen: {
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.2)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  onOpenPopper: {
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  userLogo: {
    backgroundColor: theme.palette.info.main,
    width: 35,
    height: 35,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.info.light,
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
  },

  header: {
    padding: theme.spacing(0.5),
  },
  mobileHeader: {
    margin: "19px 20px 19px 20px !important",
    padding: theme.spacing(0.5),
  },
  mobileSection: {
    margin: "-27px 9px 9px !important",
  },
  section: {
    margin: "8px 20px 16px !important",
  },
  switch: {
    color: "#858E99",
    cursor: "pointer",
    fontWeight: 500,
    "&:hover": {
      color: "#73AAEC",
    },
  },
  current: {
    color: "#1572DF",
    fontWeight: 500,
  },
  support: {
    margin: "0px 20px 0px !important",
  },
  mobileSupport: {
    margin: "0px 2px 0px !important",
  },
  button: {
    margin: "4px 20px 18px !important",
  },
  mobileButton: {
    margin: "8px 28px 9px !important",
  },
  userInitials: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: 13,
    opacity: "100%",
    width: 32,
    height: 32,
    lineHeight: "15.6px",
    borderRadius: 30,
    position: "relative",
    border: "1px solid #42556A",
  },
  userContainer: {
    background: "#e8ecf2",
    borderRadius: "8px",
    padding: "10px",
    margin: 4,
  },
  userName: {
    fontSize: 16,
    color: "#0C1E33",
    fontWeight: 500,
  },
  userEmail: {
    fontSize: 14,
    color: "#474747",
    fontWeight: 400,
    marginTop: "-2px",
    overflow: "hidden ",
    width: "200px !important",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis !important",
  },
  tenantWrapper: {
    padding: "4px 0px",
  },
  switchWrapper: {
    fontSize: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },

  tenantName: {
    maxWidth: "200px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 15,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  currentTenant: {
    color: "#70B371",
    backgroundColor: "#70B3712B",
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "4px",
  },
  menuTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: "13px",
    width: "145px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  logOut: {
    width: "100%",
    padding: "8px 20px",
    background: "#F8DCDC80",
    color: "#DE504F",
    "&:hover": {
      background: "#f8dcdce5",
    },
  },
  logOutMobile: {
    width: "100%",
    padding: "8px 8px",
    background: "#F8DCDC80",
    color: "#DE504F",
    "&:hover": {
      background: "#f8dcdce5",
    },
  },
}));
