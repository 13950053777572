import React from 'react';
import moment from 'moment';
import { Dropdown } from '../../../../shared/components';
import { useStyles } from "./styles";
interface Props {
  activeAlarms?: any;
  totalAlarms?: any;
  alarmGenerationlossToday?: any;
  alarmGenerationlossTotal?: any;
  status?: any;
  setStatus: (name: string) => void;
  equipment?: any;
  equipmentId?: any;
  setEquipment: (name: string) => void;
  category?: any;
  setCategory: (name: string) => void;
  categoryId?: any;
  equipmentList?: any;
  alarmType?: any;
  alarmTypeId?: any;
  setAlarmTypeId: (name: string) => void;
  filter?: any;
  dateFrom?: string;
  dateTo?: string;
}
export const Filters: React.FC<Props> = ({ filter, dateFrom, dateTo, activeAlarms, totalAlarms, alarmGenerationlossToday, alarmGenerationlossTotal, status, setStatus, equipmentId, equipment, setEquipment, equipmentList, category, setCategory, categoryId, alarmType, alarmTypeId, setAlarmTypeId }) => {
  const classes = useStyles();
  const getFilter = () => {
    const date = filter === "Custom"
      ? `(from ${moment(dateFrom).format(
        "DD/MM/YYYY"
      )} to ${moment(dateTo).format("DD/MM/YYYY")})`
      : filter?.toLowerCase()
    return date
  }
  return (
    <>
      <div className={classes.InlineFilters}>
        <span className={classes.Heading}>Alarms:&nbsp;</span><span> {activeAlarms} active </span>&nbsp;<span> {`(${totalAlarms} ${getFilter()})`} </span>
        <div style={{ marginRight: 16 }} />
        <span className={classes.Heading}>Generation loss:&nbsp;</span>
        <span>
          {Math.abs(alarmGenerationlossToday) > 1000 ?
            `${(Math.abs(alarmGenerationlossToday) / 1000)?.toFixed(2)} MWh`
            :
            `${Math.abs(alarmGenerationlossToday)?.toFixed(2)} kWh`
          }
        </span>
        &nbsp;
        <span>
          {Math.abs(alarmGenerationlossTotal) > 1000 ?
            `(${(Math.abs(alarmGenerationlossTotal) / 1000)?.toFixed(2)} MWh ${getFilter()})`
            :
            `(${Math.abs(alarmGenerationlossTotal)?.toFixed(2)} kWh ${getFilter()})`
          }
        </span>
      </div>
      <div className={classes.filters}>
        <Dropdown
          label={"Alarm type"}
          value={alarmTypeId}
          options={alarmType}
          onChangeValue={(filterValue: string): void => {
            setAlarmTypeId(filterValue)
          }}
        />
        <div style={{ marginRight: 16 }} />
        <Dropdown
          label={"Equipment Category"}
          value={categoryId}
          options={category}
          onChangeValue={(filterValue: string): void => {
            setCategory(filterValue);
          }}
        />
        <div style={{ marginRight: 16 }} />
        <Dropdown
          label={"Equipment"}
          value={equipmentId}
          options={equipment}
          onChangeValue={(filterValue: string): void => {
            setEquipment(filterValue);
          }}
        />
        <div style={{ marginRight: 16 }} />
        <Dropdown
          label={"Status"}
          value={status}
          options={[
            { label: "Active", value: "Active" },
            { label: "Resolved", value: "Resolved" },
          ]}
          onChangeValue={(filterValue: string): void => {
            setStatus(filterValue);
          }}
        />
        <div style={{ marginRight: 16 }} />
      </div>
    </>
  );
}