import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { useUserSave } from "./useUserSave";
import {
  GetFormik,
  initializeValidationsObsolete,
  initializeValuesObsolete,
} from "./../../components/Utils/utils";
import { formSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../components/shared/Snackbar";
import { Snackbar as Toastr } from "./../../../../shared/components/Snackbar";

interface Props {
  type: string;
  callback: any;
}

export const UserSavePage: React.FC<Props> = ({
  type,
  callback,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();

  const { user, upsert } = useUserSave({
    userId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");
  const [toastrSeverity, setToastrSeverity] = React.useState("error");
  const [isToastrOpen, setIsToastrOpen] = React.useState(false);
  const [toastrMessage, setToastrMessage] = React.useState("");

  const createNewUser = userId === "new";

  useEffect(() => {
    initializeValidationsObsolete(formSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (user || createNewUser) {
      initializeValuesObsolete(formSchema, createNewUser, user, (resp) => {
        setInitialValues(resp);
      });
    }
  }, [user]);

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    upsert(fields, userId, (resp) => {
      if (!resp.email || !resp.password) {
        setToastrSeverity("error");
        setToastrMessage("Could not save in database, User already exist");
        setIsToastrOpen(true);
        setTimeout(() => {
          navigate("/onboarding/user");
        }, 2000);
        return;
      }
      setMessage(`Email# ${resp.email} \n Password# ${resp.password}`);
      setIsOpen(true);
    });
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        type={type}
        redirectURI="/onboarding/user"
        handleClose={() => setIsOpen(false)}
        callback={() => callback()}
      />
      <Toastr
        severity={toastrSeverity}
        open={isToastrOpen}
        message={toastrMessage}
        handleClose={() => setIsToastrOpen(false)}
      />
      {type == "modal" ? (
        <></>
      ) : (
        <div className={classes.pageLabel}>
          {createNewUser ? <>Add new user </> : <>Edit user</>}
        </div>
      )}

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(formSchema).map((x) => (
              <DynamicField
                name={x}
                data={formSchema[x]}
                formik={formik}
                isEdit={!createNewUser}
              />
            ))}
          </div>
        </form>

        <Grid
          container
          spacing={2}
          style={{ pointerEvents: isOpen ? "none" : "auto" }}
        >
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Back"
              onCancel={() => {
                if (type == "modal") {
                  callback();
                }
                let URI = "/onboarding/user";
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
