import React from "react";
import { Route, Routes } from "react-router-dom";
import { getPageItem } from "../../shared/utils/pages";
import { MenuItem, PageItem, Roles } from "../../shared/interfaces";
import {
  Tenant,
  TenantSavePage,
  ModuleTenantSavePage,
  PortfolioSavePage,
  UserTenantSavePage,
  PlantSavePage,
  UserRoleSavePage,
} from "./Tenant";
import { User, UserSavePage } from "./User";
import { Role, RoleEditPage } from "./Role";
import { UserTenant } from "./UserTenant";
import { UserRole } from "./UserRole";
import { ModuleSavePage, Module } from "./Module";
import { ModuleTenant } from "./ModuleTenant";
import { Plant } from "./Plant";
import { TeamSavePage } from "./Tenant";
import { TeamMemberSavePage } from "./Tenant";
import { RoleSavePage } from "./Tenant/components/RoleSavePage.component";
import { RoleTeamSavePage } from "./Tenant/components/RoleTeamSavePage.component";
import { OnboardingNavigation } from "./OnboardingNavigation.component";
import { TeamPlantSavePage } from "./Tenant/components/TeamPlantSavePage.component";
import { ReactComponent as ADMIN_LIGHT } from "../../shared/icons/sidebar/light/ADMIN_LIGHT.svg";
import { ReactComponent as ONBOARDING_BLACK } from "../../shared/icons/sidebar/dark/ONBOARDING_BLACK.svg";
import { LobbyScreen } from "./LobbyScreen";
import { fetchAsync } from "../../shared/utils/apiService";

export enum onBoardingPagesEnum {
  TenantPage = "/onboarding/tenant",
  TenantSavePage = "/onboarding/tenant/:tenantId",
  UserPage = "/onboarding/user",
  UserSavePage = "/onboarding/user/:userId",
  UserTenantPage = "/onboarding/usertenant",
  UserTenantSavePage = "/onboarding/usertenant/:tenantId/:usertenantId",
  UserRole = "/onboarding/userrole",
  UserRoleSavePage = "/onboarding/userrole/:tenantId/:usertenantId/:userroleId",
  ModulePage = "/onboarding/module",
  ModuleSavePage = "/onboarding/module/:moduleId",
  ModuleTenant = "/onboarding/moduletenant",
  ModuleTenantSavePage = "/onboarding/moduletenant/:tenantId/:moduleTenantId",
  Portfolio = "/onboarding/portfolio",
  PortfolioSavePage = "/onboarding/portfolio/:tenantId/:moduleId/:portfolioId",
  Plant = "/onboarding/plant",
  PlantSavePage = "/onboarding/plant/:tenantId/:moduleId/:portfolioId/:plantId",
  TeamSavePage = "/onboarding/team/:tenantId/:teamId",
  TeamMemberSavePage = "/onboarding/teamMember/:tenantId/:teamId/:teamMemberId",
  TeamPlantSavePage = "/onboarding/teamPlant/:tenantId/:teamId/:teamPlantId",
  RoleSavePage = "/onboarding/roles/:tenantId/:roleId",
  RoleTeamSavePage = "/onboarding/roleTeam/:tenantId/:roleId/:roleTeamId",
  RolePage = "/onboarding/role",
  RoleEditPage = "/onboarding/role/:roleId",
  LobbyScreenPage = "/onboarding/lobbyScreen",
}

export const onboardingPages: Array<PageItem> = [
  getPageItem(onBoardingPagesEnum.TenantPage, Tenant, "Tenants"),
  getPageItem(onBoardingPagesEnum.TenantSavePage, TenantSavePage, null),
  getPageItem(onBoardingPagesEnum.UserPage, User, "Users"),
  getPageItem(onBoardingPagesEnum.UserSavePage, UserSavePage, null),
  getPageItem(onBoardingPagesEnum.UserTenantPage, UserTenant, null),
  getPageItem(onBoardingPagesEnum.UserTenantSavePage, UserTenantSavePage, null),
  getPageItem(onBoardingPagesEnum.UserRole, UserRole, null),
  getPageItem(onBoardingPagesEnum.UserRoleSavePage, UserRoleSavePage, null),
  getPageItem(onBoardingPagesEnum.ModulePage, Module, null),
  getPageItem(onBoardingPagesEnum.ModuleSavePage, ModuleSavePage, null),
  getPageItem(onBoardingPagesEnum.ModuleTenant, ModuleTenant, null),
  getPageItem(onBoardingPagesEnum.TeamMemberSavePage, TeamMemberSavePage, null),
  getPageItem(
    onBoardingPagesEnum.ModuleTenantSavePage,
    ModuleTenantSavePage,
    null
  ),
  getPageItem(onBoardingPagesEnum.PortfolioSavePage, PortfolioSavePage, null),
  getPageItem(onBoardingPagesEnum.Plant, Plant, null),
  getPageItem(onBoardingPagesEnum.PlantSavePage, PlantSavePage, null),
  getPageItem(onBoardingPagesEnum.TeamSavePage, TeamSavePage, null),
  getPageItem(onBoardingPagesEnum.RoleSavePage, RoleSavePage, null),
  getPageItem(onBoardingPagesEnum.RoleTeamSavePage, RoleTeamSavePage, null),
  getPageItem(onBoardingPagesEnum.TeamPlantSavePage, TeamPlantSavePage, null),
  getPageItem(onBoardingPagesEnum.RolePage, Role, "Roles"),
  getPageItem(onBoardingPagesEnum.RoleEditPage, RoleEditPage, null),
  getPageItem(onBoardingPagesEnum.LobbyScreenPage, LobbyScreen, "Lobby screen"),
];

export const OnboardingPages: React.FC = () => {
  return (
    <Routes>
      {onboardingPages.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={<Component />}
        />
      ))}
    </Routes>
  );
};

export const getOnboardingMenuItem = (): MenuItem => ({
  idx: Roles.ONBOARDING,
  title: "ADMIN",
  navigation: <OnboardingNavigation />,
  Icon: ADMIN_LIGHT,
  DarkIcon: ONBOARDING_BLACK,
  items: onboardingPages,
});
