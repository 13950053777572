import * as React from 'react';
import List from '@mui/material/List';
interface ListProps {
  children?: any;
}
export const ListComponent = ({ children }: ListProps) => {
  return (
    <List sx={{ width: '100%', maxWidth: 650, bgcolor: 'background.paper' }}>
      {children && children}
    </List>
  );
}
