import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBearerToken } from "../../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1`

// All of the api's which are shared between monitoring pages

export const monitoringSharedApi = createApi({
    reducerPath: "monitoringSharedApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: async (headers) => {
            const token = getBearerToken();
            if (token) {
                headers.set("authorization", `Bearer ${await token}`);
                headers.set(
                    "Ocp-Apim-Subscription-Key",
                    process.env.REACT_APP_API_MKEY
                );
                headers.set("Content-Type", "text/json");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
    }),
});

export const { } = monitoringSharedApi;