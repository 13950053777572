import React from "react";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ErrorMessage } from "../ErrorMessage";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface Props {
	value: any;
	onChange: (newValue: Date | null) => void;
	disabled?: boolean;
	label?: string;
	name?: string;
	showError?: boolean;
	errorMessage?: string;
	minDate?: Date;
	maxDate?: Date;
	ShowMonth?: boolean;
	inHeight?: string;
	inWidth?: string;
	placeholder?: string;
	width?: string;
}

export const Datepicker: React.FC<Props> = ({
	value,
	onChange,
	placeholder,
	label,
	disabled,
	showError,
	errorMessage,
	minDate,
	maxDate,
	ShowMonth,
	inHeight = "100%",
	inWidth = "100%",
	width,
}) => (
	<LocalizationProvider dateAdapter={AdapterMoment}>
		<DesktopDatePicker
			label={label}
			inputFormat={ShowMonth ? "MM/yyyy" : "dd/MM/yyyy"}
			value={value || null}
			disabled={disabled}
			views={ShowMonth ? ["year", "month"] : ["year", "month", "day"]}
			onChange={onChange}
			renderInput={(params) => (
				<TextField
					{...params}
					sx={{
						"& .MuiInputBase-input": {
							height: inHeight,
							width: inWidth,
						},
						width: width || "auto",
						backgroundColor: disabled ? "#E1E4E9" : "",
					}}
					inputProps={{
						...params.inputProps,
						placeholder: placeholder ? placeholder : "dd/mm/yyyy",

					}}
				/>
			)}
			minDate={minDate}
			maxDate={maxDate}
		/>
		<ErrorMessage showError={showError} errorMessage={errorMessage} />
	</LocalizationProvider>
);
