import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  customTooltip: {
    padding: "30rem",
    backgroundColor: "blue",
  },
  legendArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    marginRight: "10px",
    alignItems: "center",
  },
  legendLine: {
    height: "1px",
    width: "15px",
    border: "none",
    marginRight: "10px",
  },
}));
