import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    top: 64,
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      top: 64,
    },
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 2.0, 0),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    border: "1px solid #d1d3d4b5",
  },
  downPart: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1.8em",
    justifyContent: "flex-start",
    padding: theme.spacing(1.2, 2, 0, 2),
    paddingLeft: 0,
  },
  headerItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(3),
  },
}));
