import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    margin: 0,
    padding: theme.spacing(3),
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },

  widgets: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  inverterHeader: {
    width: "100%",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.info.light,
    display: "flex",
    justifyContent: "space-between",

  },
  headerText: {
    textAlign: "center",
    fontSize: 18,
    // padding: theme.spacing(1),
    fontWeight: 500,
  },
}));
