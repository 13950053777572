import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    width: 170,
    height: 35,
    backgroundColor: "#E8ECF2",
  },
  inputLabel: {
    fontSize: 12,
    color: "green",
  },
}));
