import React from "react";
import Widget from "../Widget";
import Loader from "../Loader";
import { LineChart } from "../LineChart";

interface Props {
  colors: any;
  data: any;
  xAxis: any;
  yAxis: any;
  type: any;
  title: any;
  loading: any;
  height: any;
  customMenu: any;
  size?: any;
}

export const MultilineChartWidget: React.FC<Props> = (props: Props) => {
  const {
    title,
    data,
    yAxis,
    xAxis,
    loading,
    height,
    size = 12,
    type,
    customMenu,
    colors
  } = props;
  return (
    <Widget
      label={title}
      customMenu={customMenu}
      height={height}
      size={size}
    >
      <Loader loading={loading} height={300} />
      {!data?.length || loading ? null : (
        <LineChart
          title=''
          colors={colors}
          data={data}
          xAxis={xAxis}
          yAxis={yAxis}
          type={type}
          height={'auto'}
        />
      )}
    </Widget>
  );
};
