import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { UseTeamPlantSave } from "./useTeamPlantSave";
import { GetFormik, initializeValidationsObsolete } from "./../../components/Utils/utils";
import { formSchemaTeamPlant } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { useStyles } from "./../styles";

export const TeamPlantSavePage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { tenantId } = useParams<{ tenantId: string }>();
    const { teamId } = useParams<{ teamId: string }>();
    const { teamPlantId } = useParams<{ teamPlantId: string }>();
    const [validationSchema, setValidationSchema] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [severity, setSeverity] = useState("error");
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [portfolioId, setPortfolioId] = useState("");

    const createNewPlantTeamMember = teamPlantId === "new";

    const { plant, portfolio, upsert } = UseTeamPlantSave({
        tenantId,
        teamId,
        portfolioId
    });

    useEffect(() => {
        if (portfolio) formSchemaTeamPlant.portfolioId.options = portfolio;
        if (plant) formSchemaTeamPlant.plantId.options = plant;
    }, [portfolio, plant]);

    useEffect(() => {
        initializeValidationsObsolete(formSchemaTeamPlant, (resp) => {
            setValidationSchema(resp);
        });
    }, [portfolio, plant]);

    const onSubmitHandler = (values) => {
        let fields = JSON.parse(JSON.stringify(values, null, 2));
        fields.tenantId = tenantId;
        upsert(fields, teamId, (resp) => {
            if (resp?.ok === false) {
                setSeverity("error");
                setMessage("Operation unsuccessfull");
                setIsOpen(true);
            } else {
                setSeverity("success");
                setMessage("Record added successfully");
                setIsOpen(true);
            }
            setTimeout(() => {
                let URI = `/onboarding/team/${tenantId}/${teamId}?index=1`;
                renderPage(URI);
            }, 2000);
        });
    };

    const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

    const renderPage = (URI) => {
        navigate(URI);
    };

    const onhandleChange = (val, name) => {
        switch (name) {
            case 'portfolioId':
                setPortfolioId(val);
                break;
            default:
                break;
        }
    }
    return (
        <div className={classes.pageWrapper}>
            <Snackbar
                severity={severity}
                open={isOpen}
                message={message}
                handleClose={() => setIsOpen(false)}
            />
            <div className={classes.pageLabel}>
                {createNewPlantTeamMember ? (
                    <>Add new team plant </>
                ) : (
                    <>Edit team plant</>
                )}
            </div>

            <Card style={{ padding: "20px" }}>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <div className={classes.formElementsWrapper}>
                        {Object.keys(formSchemaTeamPlant).map((x) => (
                            <DynamicField
                                name={x}
                                data={formSchemaTeamPlant[x]}
                                formik={formik}
                                handleChange={(val) => onhandleChange(val, x)}
                            />
                        ))}
                    </div>
                </form>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ActionButtons
                            saveTitle="Save team plant"
                            onSave={() => {
                                formik.validateForm();
                                return formik.submitForm();
                            }}
                            saveButtonType="submit"
                            cancelTitle="Back"
                            onCancel={() => {
                                let URI = `/onboarding/team/${tenantId}/${teamId}?index=1`;
                                renderPage(URI);
                            }}
                        />
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};
