import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Divider } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons, Modal } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { UseTeamSave } from "./useTeamSave";
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete } from "./../../components/Utils/utils";
import { formSchemaTeam } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { CustomTable } from "../../../../shared/components/Table/Table.component";
import { searchData } from "./../../components/Utils/utils";
import { Search, Button } from "../../../../shared/components";
import { TeamMembersViewHeaderCell, teamMemberFilters, TeamPlantsViewHeaderCell, teamPlantFilters, } from "./interfaces";
import { Box, Tab, Tabs } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { Button2 } from "../../../../shared/components/Button2/Button2.component";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight: "45vh" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const TeamSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { teamId } = useParams<{ teamId: string }>();

  const {
    team,
    teamMember,
    upsert,
    deleteTeamMember,
    teamPlant,
    deleteTeamPlant,
  } = UseTeamSave({
    teamId,
    tenantId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [teamPlantDeleteId, setTeamPlantDeleteId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [searchTeamPlant, setSearchTeamPlant] = useState("");
  const [filteredTeamPlantData, setFilteredTeamPlantData] = useState([]);
  const [openTeamPlantDeleteModal, setOpenTeamPlantDeleteModal] =
    useState(false);
  const [plantId, setPlantId] = useState("");

  const createNewTeam = teamId === "new";

  useEffect(() => {
    if (!teamMember) return;
    if (search && search !== "") {
      setFilteredData(searchData(search, teamMember, teamMemberFilters));
    } else {
      setFilteredData(teamMember);
    }
  }, [search, teamMember]);

  useEffect(() => {
    if (!teamPlant) return;
    if (searchTeamPlant && searchTeamPlant !== "") {
      setFilteredTeamPlantData(
        searchData(searchTeamPlant, teamPlant, teamPlantFilters)
      );
    } else {
      setFilteredTeamPlantData(teamPlant);
    }
  }, [searchTeamPlant, teamPlant]);

  useEffect(() => {
    initializeValidationsObsolete(formSchemaTeam, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (team || createNewTeam) {
      initializeValuesObsolete(formSchemaTeam, createNewTeam, team, (resp) => {
        setInitialValues(resp);
      });
    }
  }, [teamId]);

  const getParam = (name) => {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || 0;
  };

  useEffect(() => {
    var index = Number(getParam("index"));
    setValue(index || 0);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.tenantId = tenantId;
    if (createNewTeam) {
      upsert(fields, teamId, (resp) => {
        setPlantId(resp.teamId);
        let URI = `/onboarding/team/${tenantId}/${resp.teamId}`;
        renderPage(URI);
      });
      setSeverity("success");
      setMessage("Record added successfully");
    } else {
      upsert(fields, teamId, (resp) => {
        let URI = `/onboarding/team/${tenantId}/${resp.teamId}`;
        renderPage(URI);
      });
      setSeverity("success");
      setMessage("Record added successfully");
    }
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };
  const searchTeamPlantHandler = (newSearch: string) => {
    setSearchTeamPlant(newSearch);
  };
  const handleOnDelete = (id: any) => {
    setDeleteId(id);
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onCloseTeamPlantDeleteModal = () => {
    setOpenTeamPlantDeleteModal(false);
  };

  const handleOnDeleteTeamPlant = (id: any) => {
    setTeamPlantDeleteId(id);
    setOpenTeamPlantDeleteModal(true);
  };

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2
                  size={40}
                  type="tertiary"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </Button2>
              </div>
              <div style={{ marginLeft: "8px" }}>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    deleteTeamMember(deleteId, (resp) => {
                      if (resp?.ok === false) {
                        setSeverity("error");
                        setMessage("Operation unsuccessfull");
                        setIsOpen(true);
                      } else {
                        setSeverity("success");
                        setMessage("Record deleted successfully");
                        setIsOpen(true);
                      }
                    });
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
          </>
        }
      />
      <Modal
        isOpen={openTeamPlantDeleteModal}
        title={"Delete"}
        onClose={onCloseTeamPlantDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <ActionButtons
              saveTitle={"Delete"}
              saveButtonType="submit"
              onSave={() => {
                deleteTeamPlant(teamPlantDeleteId, (resp) => {
                  if (resp?.ok === false) {
                    setSeverity("error");
                    setMessage("Operation unsuccessfull");
                    setIsOpen(true);
                  } else {
                    setSeverity("success");
                    setMessage("Record deleted successfully");
                    setIsOpen(true);
                  }
                });
                setOpenTeamPlantDeleteModal(false);
              }}
              cancelTitle="Cancel"
              onCancel={() => setOpenDeleteModal(false)}
            />
          </>
        }
      />
      <div className={classes.pageLabel}>
        {createNewTeam ? <>Add new team </> : <>Edit team</>}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {createNewTeam ? (
              Object.keys(formSchemaTeam).map((x) => (
                <DynamicField
                  name={x}
                  data={formSchemaTeam[x]}
                  formik={formik}
                  isEdit={!createNewTeam}
                />
              ))
            ) : (
              <p>
                <b>Team name:</b> {` ${team?.teamName || ""}`}
              </p>
            )}
          </div>
        </form>

        {createNewTeam ? (
          <> </>
        ) : (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <Tabs value={value} onChange={handleChange} aria-label="">
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span> Team members</span>
                      <Tooltip
                        title="Add team members"
                        className={`${classes.floatRight}`}
                      >
                        <IconButton color="primary" component="span">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  {...a11yProps(0)}
                />

                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span> Plants assigned to a team</span>
                      <Tooltip
                        title="Add teams to plant"
                        className={`${classes.floatRight}`}
                      >
                        <IconButton color="primary" component="span">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            {/* Add team member */}

            <TabPanel value={value} index={0}>
              <>
                <Button
                  color={"rgb(43, 41, 88)"}
                  type="button"
                  title={"Add"}
                  showIcon={true}
                  className={classes.floatRight}
                  onClick={() =>
                    renderPage(
                      `/Onboarding/teamMember/${tenantId}/${teamId}/new`
                    )
                  }
                >
                  Add
                </Button>

                <Tooltip
                  title="Click here to add team"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "400px" }}>
                  <Search
                    placeholder="Search for Team members"
                    value={search}
                    onChange={searchHandler}
                    onKeyUp={searchHandler}
                  />
                </div>
                <br />
                <CustomTable
                  tableType={`/teamMember/${tenantId}/${teamId}`}
                  module="/Onboarding"
                  headerCells={TeamMembersViewHeaderCell}
                  data={filteredData}
                  maxHeight={250}
                  onDeleteClick={(id) => handleOnDelete(id)}
                />
              </>
            </TabPanel>

            {/* Add team plant */}
            <TabPanel value={value} index={1}>
              <>
                <Button
                  color={"rgb(43, 41, 88)"}
                  type="button"
                  title={"Add"}
                  showIcon={true}
                  className={classes.floatRight}
                  onClick={() =>
                    renderPage(
                      `/Onboarding/teamPlant/${tenantId}/${teamId}/new`
                    )
                  }
                >
                  Add
                </Button>
                <Tooltip
                  title="Click here to add a team to plant"
                  className={`${classes.floatRight}`}
                >
                  <IconButton color="primary" component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <div>&nbsp;</div>
                <div style={{ maxWidth: "400px" }}>
                  <Search
                    placeholder="Search for Team plants"
                    value={searchTeamPlant}
                    onChange={searchTeamPlantHandler}
                    onKeyUp={searchTeamPlantHandler}
                  />
                </div>
                <br />
                <CustomTable
                  tableType={`/teamPlant/${tenantId}/${teamId}`}
                  module="/Onboarding"
                  headerCells={TeamPlantsViewHeaderCell}
                  data={filteredTeamPlantData}
                  maxHeight={250}
                  onDeleteClick={(id) => handleOnDeleteTeamPlant(id)}
                />
              </>
            </TabPanel>
          </>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px" }} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <Button2
                size={40}
                type="tertiary"
                onClick={() => {
                  let URI = `/onboarding/tenant/${tenantId}?index=3`;
                  renderPage(URI);
                }}
              >
                Back
              </Button2>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <Button2
                size={40}
                type="primary"
                onClick={() => {
                  if (createNewTeam === false) {
                    let URI = `/onboarding/tenant/${tenantId}?index=3`;
                    renderPage(URI);
                    return;
                  }
                  formik.validateForm();
                  return formik.submitForm();
                }}
              >
                Save
              </Button2>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
