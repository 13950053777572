import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { useUserRoleSave } from "./useUserRoleSave";
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete } from './../../components/Utils/utils'
import { UserRoleformSchema } from "./metaData";
import { DynamicField } from './../../components/shared/DynamicFields/DynamicField';

interface Props {
  subsidiaryId: string;
  type: string;
  callback: any;
}

export const UserRoleSavePage: React.FC<Props> = ({ type, callback }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userroleId } = useParams<{ userroleId: string }>();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { usertenantId } = useParams<{ usertenantId: string }>();

  const { Subsidiary, upsert, role } = useUserRoleSave();
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const createNewUserRole = userroleId === "new";


  useEffect(() => {
    initializeValidationsObsolete(UserRoleformSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, [role]);

  useEffect(() => {
    if (role)
      UserRoleformSchema.roleId.options = role;
  }, [role]);

  useEffect(() => {
    if (userroleId || createNewUserRole) {
      initializeValuesObsolete(UserRoleformSchema, createNewUserRole, Subsidiary, (resp) => {
        setInitialValues(resp);
      });
    }
  }, [Subsidiary, role]);


  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.tenantUserId = usertenantId;
    upsert(fields, usertenantId, () => {
      let URI = `/onboarding/usertenant/${tenantId}/${usertenantId}`;
      renderPage(URI);

    })
  }

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      {
        type == "modal" ? <></> :
          <div className={classes.pageLabel}>
            {createNewUserRole ? (
              <>Add new user role </>
            ) : (
              <>Edit user role</>
            )}
          </div>
      }

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {
              Object.keys(UserRoleformSchema).map(x => <DynamicField name={x} data={UserRoleformSchema[x]} formik={formik} />)
            }
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save user role"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Cancel"
              onCancel={() => {

                let URI = `/onboarding/usertenant/${tenantId}/${usertenantId}`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
