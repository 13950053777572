import { useEffect, useState } from "react";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Data {
  tenants: any;
  loading: any;
}

export const useTenant = (): Data => {

  const [tenants, setTenants] = useState(0);
  const [loading, setloading] = useState(null);
  const [error, setError] = useState('');


  useEffect(() => {
    getTenants();
  }, []);



  const getTenants = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/Tenant/GetTenants`, "GET")
      .then((data: any) => {
        if (data) {
          var newTenant = data?.map(item => ({
            ...item,
            id: item.tenantId,
          }));
          setTenants(newTenant);
        }
        setloading(false);
      })
      .catch((e) => { console.error(e); setloading(false); });
  };

  return {
    tenants,
    loading
  };
};
