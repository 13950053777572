import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  blockId: number;
  inverterId: number;
  stringId: number;
  stringUnderPerformance: boolean;
  sumAvgSpecificCurrent: number;
  sumMaxSpecificCurrent: number;
  sumMinSpecificCurrent: number;
  sumSpecificCurrent: number;
  timeStamp: string;
}

interface Data {
  data: Array<any>;
  error: string;
  loading: boolean;
}

interface Props {
  blockId: string;
  inverterId: string;
  plantId: string;
  date: string;
}

export const useInverterEfficiency = ({ blockId, inverterId, plantId, date }: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError('No data');
      return setLoading(false);
    }

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!inverterId || !blockId)
      return;

    setError('');
    setLoading(true);

    fetchAsync(`SkyfriSidemApi/v1/GetInverterLoadvsPercentage?date=${date}&blockid=${blockId}&inverterid=${inverterId}&PlantId=${plantId}`, 'GET')
      .then((data: any) => {
        transformData(data)
      })
      .catch(e => setError(e));
  }, [blockId, inverterId, plantId, date]);

  return {
    data,
    error,
    loading,
  };
};
