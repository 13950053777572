import React, { useState, useEffect } from "react";
import { useUser } from "./useUser";
import { Card, Divider } from "@material-ui/core";
import {
  Search,
  Modal,
} from "../../../shared/components";

import { CustomTable } from "../../../shared/components/Table/Table.component";
import Loader from "../../components/shared/Loader";
import { useStyles } from "./styles";
import { searchData } from "./../components/Utils/utils";
import { Snackbar } from "./../../../shared/components/Snackbar";
import {
  userViewHeaderCell,
  userViewTableType,
  filters,
} from "./components/interfaces";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { Button2 } from "../../../shared/components/Button2/Button2.component";

export const User: React.FC = () => {
  const classes = useStyles();
  const { user, deleteUsers, loading } = useUser();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    if (search && search != "") {
      setFilteredData(searchData(search, user, filters));
    } else {
      setFilteredData(user);
    }
  }, [search, user]);

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };

  const onNewUser = () => {
    navigate(`/onboarding/user/new`);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteClick = (id: any) => {
    const selectedUser = user.filter((eachUser) => eachUser.id === id);
    const objectId = selectedUser?.[0]["objectId"];
    setSelectedId(id);
    setSelectedObjectId(objectId);
    setOpenDeleteModal(true);
  };

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>Users </div>

      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "6px" }} />
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Button2
                  size={40}
                  type={"tertiary"}
                  onClick={onCloseDeleteModal}
                >
                  Cancel
                </Button2>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Button2
                  size={40}
                  type={"primary"}
                  onClick={() => {
                    deleteUsers(selectedId, selectedObjectId, (resp) => {
                      setSearch("");
                      if (resp?.ok == false) {
                        setSeverity("error");
                        setMessage("Operation unsuccessfull");
                        setIsOpen(true);
                      } else {
                        setSeverity("success");
                        setMessage("Record deleted successfully");
                        setIsOpen(true);
                      }
                    });
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
            {/* <ActionButtons
              saveTitle={"Delete"}
              saveButtonType="submit"
              onSave={() => {
                deleteUsers(selectedId, selectedObjectId, (resp) => {
                  setSearch("");
                  if (resp?.ok == false) {
                    setSeverity("error");
                    setMessage("Operation unsuccessfull");
                    setIsOpen(true);
                  } else {
                    setSeverity("success");
                    setMessage("Record deleted successfully");
                    setIsOpen(true);
                  }
                });
                setOpenDeleteModal(false);
              }}
              cancelTitle="Cancel"
              onCancel={onCloseDeleteModal}
            /> */}
          </>
        }
      />

      <Card style={{ padding: "20px" }}>
        <div style={{ float: "right" }}>
          <Button2 size={40} type={"primary"} onClick={() => onNewUser()}>
            Add
          </Button2>
        </div>
        {/* <Button
          color={"rgb(43, 41, 88)"}
          type="button"
          title={"Add"}
          className={`${classes.floatRight} ${classes.marginBottom}`}
          onClick={() => onNewUser()}
        >
          New user
        </Button> */}
        <Tooltip
          title="Click here to add user"
          className={`${classes.floatRight}`}
        >
          <IconButton color="primary" component="span">
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <br />
        {loading ? (
          <>
            <CustomTable
              tableType={userViewTableType}
              headerCells={userViewHeaderCell}
              data={[]}
              onDeleteClick={(id) => deleteClick(id)}
            />
            <Loader
              loading={loading}
              height={300}
              error={""}
              errorTextSize={32}
            />
          </>
        ) : (
          <>
            <div style={{ maxWidth: "400px" }}>
              <Search
                placeholder="Search for a user"
                value={search}
                onChange={searchHandler}
                onKeyUp={searchHandler}
              />
            </div>
            <br />
            <CustomTable
              module={"/onboarding/"}
              tableType={userViewTableType}
              maxHeight={"76vh"}
              headerCells={userViewHeaderCell}
              data={filteredData}
              onDeleteClick={(id) => deleteClick(id)}
            />
          </>
        )}
      </Card>
    </div>
  );
};
