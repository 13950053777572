import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(0.5, 2, 0.5, 3),
        borderRadius: theme.spacing(1),
    },
    selector: {
        borderRadius: 5,
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        display: "flex",
    },
    label: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "14px",
        color: "#707070",
        marginBottom: theme.spacing(0.5),
    },
    select: {
        width: "200px",
        "&::before": {
            display: "none",
        },
        "&::after": {
            display: "none",
        },
    },
    marginRight: {
        marginRight: "10px",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    boxStyle: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    flexType: {
        alignItems: "center",
        '@media (min-width: 1200px)': {
            justifyContent: "flex-end",
        }
    },
    lastUpdated: {
        borderRadius: theme.spacing(0.5),
        backgroundColor: "#E8ECF2",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "14.4px",
        padding: "4px 8px",
        order: 3,
    },
    liveButton: {
        paddingRight: "16px",
        alignSelf: 'center',
        order: -1,
        '@media (max-width: 1199px)': {
            order: "2",
        },
    },
    paddingRight: {
        paddingRight: "16px"
    },
    paddingLeft: {
        paddingLeft: "5px"
    },
    switchMobile: {
        display: "flex",
    },
    timeZones: {
        borderRadius: theme.spacing(0.5),
        backgroundColor: "#DEEFFE",
        color: "#2D5374",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "14.4px",
        padding: "4px 8px",
        order: 3,
        '@media (max-width: 608px)': {
            marginTop:"10px"
        }
    },
    divider: {
        '@media (max-width: 1279px)': {
            display: "none",
        }
    },
    horizontalDivier:{
        paddingTop:"12px !important",
        '@media (min-width: 1200px)': {
            display: "none",
        },
        '@media (min-width: 473px)':{
            paddingBottom:"3px !important",
        },
    },
    firstColumn:{
        '@media (max-width: 1209px)': { '&.MuiGrid-item': { paddingTop: "9px", } } 
    },
}));
