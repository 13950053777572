import { useEffect, useState } from "react";
import { DropdownOption } from "../../../../../shared/components";
import { autoRefreshApi } from "../../../../../shared/utils/utils";
import { fetchAsync } from "../../../../../shared/utils/apiService";

const formatTypes: Array<DropdownOption> = [
  { label: "PR", value: "%" },
  { label: "Generation", value: "kWh" },
];

interface BEData {
  label: number;
  name: string;
  value: Array<number>;
}

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  formatTypes: Array<DropdownOption>;
  setFormat: (val: any) => void;
  onClick: (e) => void;
  filterValue: string;
  profileData: any;
  loading: boolean;
  error: string;
  format: string;
  data: Array<any>;
}

export const useLossWaterfall = ({
  plantId,
  date,
}: Props): Data => {
  //add to the header of the page (info of the plant)
  const profileData = JSON.parse(localStorage.getItem("profileData") || "{}");

  const [data, setData] = useState({} as any); //WaterfallChartData;
  const [format, setFormat] = useState(formatTypes[0].value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const getWaterfallChartRequest = (filterValue = "") => {
    const url =
      format === "%" ? "GetWaterfallPRLoss" : "GetWaterfallEnergyLoss";

    setLoading(true);
    setError("");
    fetchAsync(`SkyfriSidemApi/v1/${url}?`, "GET", {
      Date: date,
      plantId,
      tracker: filterValue,
    })
      .then((data: Array<BEData>) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => setError(e));
  };

  useEffect(() => {
    if (!date) return;
    setLoading(true);
    getWaterfallChartRequest(filterValue);

    const myInterval = autoRefreshApi(() =>
      getWaterfallChartRequest(filterValue)
    );
    return () => clearInterval(myInterval);
  }, [format, date]);

  useEffect(() => {
    setFilterValue(profileData?.hasTracker ? "all" : "");
  }, [profileData?.hasTracker]);

  const onClick = (event) => {
    const filterValue = event.currentTarget.getAttribute("name");
    setFilterValue(filterValue);
    getWaterfallChartRequest(filterValue);
  };

  return {
    formatTypes,
    setFormat,
    onClick,
    filterValue,
    profileData,
    loading,
    error,
    format,
    data,
  };
};
