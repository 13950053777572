import { makeStyles, Theme } from "@material-ui/core/styles";
import { GLOBAL_COLORS } from "../../../../../shared/utils/utils";

export const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      padding: theme.spacing(3, 3, 3, 3),
      borderRadius: theme.spacing(1),
      marginTop: "0",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    body: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    percentage: {
      fontWeight: "bold",
      fontSize: "48px",
      lineHeight: "52.8px",
    },
    batteryStatus: {},
    heading: {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "22px",
      margin: 0,
      marginBottom: "42px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    optionsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    options: {
      display: "flex",
      gap: "32px",
      flexWrap: "wrap",
      "&.top": {
        flexDirection: "row",
        alignItems: "center",
      },
      "&.bottom": {
        flexDirection: "column",
        alignItems: "start",
        gap: "8px",
      },
    },
    option: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      "&:not(:first-child)": {
        "&::before": {
          content: '""',
          display: "block",
          height: "100%",
          position: "absolute",
          top: 0,
          left: "-16px",
          width: "1px",
          backgroundColor: GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40,
        },
      },
      "&.bottom": {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        paddingBottom: "8px",
        "&:not(:last-child)": {
          borderBottom: `1px solid ${GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40}`,
        },
      },
    },
    optionValueWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    optionValue: {
      fontSize: "28px",
      lineHeight: "30.8px",
      fontWeight: 500,
      margin: 0,
    },
    optionUnit: {
      fontSize: "14px",
      lineHeight: "22.4px",
      fontWeight: 500,
      margin: 0,
    },
    optionBottomValueWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "8px",
    },
    optionBottomValue: {
      fontSize: "13px",
      lineHeight: "15.6px",
      fontWeight: 500,
      margin: 0,
    },
    optionBottomUnit: {
      fontSize: "13px",
      lineHeight: "15.6px",
      fontWeight: 500,
      margin: 0,
    },
    optionLabel: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "15.6px",
      margin: 0,
    },
    values: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "17.6px",
    },
    type: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16.8px",
    },
    loadingColor: {
      color: "#C7C8C9",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "16px",
    },
    batteryWrapper: {
      display: "flex",
      flexDirection: "column",
      background: "none",
    },
    batterySoh: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "10px",
    },
    loadersTopWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
    }
  };
});
