import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  Subsidiary: any;
  Contacts: any;
  Company: any;
  role: any;
}

export const useUserRoleSave = (): Data => {
  const [Subsidiary, setSubsidiaries] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    getRole();
  }, [])

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";
    await fetchAsync(`/UserRole`, requestType, params)
      .then((data: any) => {
        callback();
      })
      .catch((e) => console.error(e));
  };

  const getRole = async () => {
    await fetchAsync(`/Role`, "GET")
      .then((data: any) => {
        if (data) {
          let newRole = data?.map(item => ({
            ...item,
            value: item.roleId,
            label: item.roleName,
          }));
          setRole(newRole);
        }
      })
      .catch((e) => { console.error(e); });
  };

  return {
    upsert,
    Subsidiary,
    Contacts,
    Company,
    role
  };
};
