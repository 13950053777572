import { ThunkAction } from "redux-thunk";
import { configureStore, Action } from "@reduxjs/toolkit";
import { onBoardingApi } from "../features/Onboarding/onBoardingApi";
import { plantSiteInfoApi } from "../features/siteInfo/PlantSiteInfo/plantSiteInfoApi";
import rootReducer, { RootState } from "./rootReducers";
import { monitoringSharedApi } from "../features/Monitoring/shared/monitoringSharedApi";
import { monitoringDashboardApi } from "../features/Monitoring/monitoringDashboard/monitoringDashboardApi";
import { monitoringInverterPerformanceApi } from "../features/Monitoring/inverterPerformance/inverterPerformanceApi";
import { sidemSharedApi } from "../features/Sidem/shared/sidemSharedApi";
import { batteryMonitoringApi } from "../monitoring/pages/MonitoringDashboard/services/BatteryMonitoringApi";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { batteryOverviewApi } from "../monitoring/pages/BatteryMonitoring/BatteryMonitoring.api";

const rootStore = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(onBoardingApi.middleware)
      .concat(plantSiteInfoApi.middleware)
      .concat(monitoringSharedApi.middleware)
      .concat(monitoringDashboardApi.middleware)
      .concat(monitoringInverterPerformanceApi.middleware)
      .concat(sidemSharedApi.middleware)
      .concat(batteryMonitoringApi.middleware)
      .concat(batteryOverviewApi.middleware),
});

export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootStore;
