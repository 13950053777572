import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@mui/material";
import { useStyles } from "../styles";
interface PopupProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  fullScreen: any;
  setSaveValues: any;
  saveValues: any;
  setTitle: any;
}
export const SaveTemplatePopup: React.FC<any> = ({
  open,
  handleClose,
  fullScreen,
  handleSave,
  saveValues,
  setSaveValues,
  setTitle,
}: PopupProps) => {
  const handleChange = (val, e) => {
    setSaveValues({ ...saveValues, [val]: e.target.value });
  };
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={"md"}
    >
      <DialogTitle id="responsive-dialog-title"></DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.inputStyle}>
            <InputLabel htmlFor="Name" className={classes.popupLabel}>
              Template name
            </InputLabel>
            <TextField
              id="name"
              value={saveValues.name}
              onChange={(e) => {
                handleChange("name", e);
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className={classes.inputStyle}>
            <InputLabel htmlFor="Save" className={classes.popupLabel}>
              Save for
            </InputLabel>
            <TextField
              id="save"
              value={saveValues.for}
              onChange={(e) => handleChange("for", e)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSave();
            handleClose();
          }}
          autoFocus
        >
          Save
        </Button>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
