import React from "react";
import { Grid } from "@material-ui/core";
import RealtimePerformanceWidget from "../features/RealtimePerformanceWidget";
import LossWaterfallWidget from "../features/LossWaterfallWidget";
import ExpectedGenerationWidget from "../features/ExpectedGenerationWidget";
import HourlyPRAndIrradiationTrend from "../features/HourlyPRAndIrradiationTrend";
import ACPowervsIrradiationWidget from "../features/ACPowervsIrradiationWidget";
import { WeatherStationWidget } from "../features/WeatherStationWidget";
import { useDispatch, useSelector } from "react-redux";
import { clientSelector } from "../../../../features/Sidem/client/clientSlice";
import { getClientInfo } from "../../../../features/Sidem/client/clientActions";
interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const PlantPerformance: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { client } = useSelector(clientSelector);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let data: any = client;
    if (!client) {
      data = await dispatch(getClientInfo());
    }
  };
  return (
    <Grid container spacing={2}>
      <RealtimePerformanceWidget {...props} />

      <LossWaterfallWidget {...props} />

      <ExpectedGenerationWidget {...props} />

      <HourlyPRAndIrradiationTrend {...props} />

      <ACPowervsIrradiationWidget {...props} />

      <WeatherStationWidget {...props} />
    </Grid>
  )
};
