import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  tenantId: string;
  teamId: string;
  teamMemberId: string
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  userTenant: any;
}

export const UseTeamMemberSave = ({ tenantId, teamId }: Props): Data => {

  const [userTenant, setUserTenant] = useState(null);

  const fetchAll = async () => {
    await getUserTenant();
  };

  useEffect(() => {
    if (teamId === "new") return;
    fetchAll();
  }, []);

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";
    params.teamId = id;
    await fetchAsync(`onboardingV2/v1/TeamMembers/AddUserToTeam`, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };

  const getUserTenant = async () => {
    await fetchAsync(`onboardingV2/v1/TenantUsers/GetTenantUsers?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newUserTenant = data?.map(item => ({
            ...item,
            id: item.userId,
            value: item.tenantUserId,
            label: `${item.userName}`
          }));

          setUserTenant(newUserTenant);
        }
      })
      .catch((e) => { console.error(e); });
  };

  return {
    upsert,
    userTenant,
  };
};
