import React from 'react'
import { Tooltip as Coretooltip } from "@material-ui/core";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { useSharedFormState } from "./../../../shared/utils/sharedStates";
import { GRAPH_COLORS } from "../../../shared/utils/graphColors";

export interface WaterfallConfig {
  progressColor?: string;
  downgradeColor?: string;
}

export interface WaterfallItem {
  label: string;
  value: number;
}

interface ChartItem {
  label: string;
  val1: number;
  val2: number;
}

export interface WaterfallData {
  maxValue: number;
  maxLabel: string;
  initialValue: number;
  initialLabel: string;
  finalLabel: string;
  items: Array<WaterfallItem>;
}

interface WaterfallChartProps {
  data: any;
  formatLabel: string;
  config?: WaterfallConfig;
  tooltipContent?: any;
  definition?: any;
}

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: any };
  format?: string;
  definition?: any;
  viewAllowed?: boolean;
}

const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
  definition,
  viewAllowed,
}: CustomizedAxisTickProps) => (
  <Coretooltip title={!viewAllowed ? "" : definition?.[payload.value]}>
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-20)"
      >
        {payload.value}
      </text>
    </g>
  </Coretooltip>
);

const CustomizedYAxis = ({
  x,
  y,
  stroke,
  payload,
  format,
}: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} textAnchor="end" fill="#666">
      {payload.value}
    </text>
  </g>
);

export const WaterfallChart: React.FC<WaterfallChartProps> = (
  props: WaterfallChartProps
) => {
  const { config, data, formatLabel, tooltipContent } = props;

  const {
    progressColor = GRAPH_COLORS.normal,
    downgradeColor = GRAPH_COLORS.error,
  } = config || {};
  const convertToInt = (val: number) => val;

  const { viewDefinitions } = useSharedFormState();

  const createChartItem = (
    label: string,
    val1: number,
    val2 = 0
  ): ChartItem => ({ label, val1, val2 });

  const calculateChartItems = (data: WaterfallData): Array<ChartItem> => {
    const {
      maxValue,
      maxLabel,
      initialValue,
      initialLabel,
      finalLabel,
      items,
    } = data;
    let finalValue = initialValue;

    const chartItems: Array<ChartItem> = items.map((item) => {
      const val2 = convertToInt(finalValue);
      finalValue += item.value;

      return {
        label: item.label,
        val1: convertToInt(item.value),
        val2,
      };
    });

    return [
      createChartItem(initialLabel, convertToInt(initialValue)),
      ...chartItems,
      createChartItem(finalLabel, convertToInt(finalValue)),
    ];
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value, formatLabel, color = "#000000" } = props;
    const isBigger = Math.abs(parseInt(value)) > 1000;

    const displayValue = isBigger ? (value / 1000).toFixed(2) : value;
    const label = isBigger ? "MWh" : formatLabel;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 10}
          fill={color}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {parseFloat(displayValue)}
          {label}
        </text>
      </g>
    );
  };

  const chartOptions = data;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={chartOptions}
        margin={{ left: 10, bottom: 10 }}
      >
        <XAxis
          dataKey="name"
          interval={0}
          height={70}
          tick={
            <CustomizedAxisTick
              definition={props.definition}
              viewAllowed={viewDefinitions}
            />
          }
        />
        <YAxis
          padding={{ top: 20 }}
          tick={<CustomizedYAxis format={formatLabel} />}
          hide
        />
        <Tooltip content={tooltipContent} wrapperStyle={{ outline: "none" }} />
        {/* <Legend iconType="square" content={RenderCustomLegend} /> */}
        <Bar dataKey="value" fill="transparent">
          <LabelList
            dataKey="label"
            content={(props) =>
              renderCustomizedLabel({ ...props, formatLabel, color: "black" })
            }
          />
          {chartOptions.map((item, index) => {
            if (item.label <= 0)
              return <Cell key={index} fill={downgradeColor} />;
            return <Cell key={index} fill={progressColor} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
