import {convertUnitForGraphs} from "./utils";

export interface ChartOptionsProps {
    alignThresholds?: boolean;
    panning?: boolean;
    tooltip?: boolean;
    exporting?: boolean;
    title: string;
    titleAlignment?: string;
    panningType?: string;
    xAxis: any;
    yAxis: any;
    colors: string[];
    data: any[];
    zoomType?: string;
    height?: string;
    tooltipString?: string;
    tooltipFormatter?: (s: string, point: any) => string | false;
    plotOptions?: any;
    loading: boolean;
};

export const chartOptions = (props: ChartOptionsProps) => {
    const {
        alignThresholds = true,
        panning = true,
        tooltip = true,
        exporting = true,
        title,
        titleAlignment = "left",
        panningType = "x",
        xAxis,
        yAxis,
        colors,
        data,
        zoomType = "x",
        height = 'auto',
        tooltipString = '',
        tooltipFormatter,
        plotOptions,
        loading,
    } = props;

    return {
        chart: {
            alignThresholds: alignThresholds,
            zoomType: zoomType,
            panning: {
                enabled: panning,
                type: panningType,
            },
            panKey: "shift",
            style: {
                fontFamily: 'Inter',
                fontWeight: 500,
            },
            events: {
                load() {
                    if (loading) {
                        this.showLoading();
                    } else {
                        this.hideLoading();
                    }
                },
                redraw() {
                    if (loading) {
                        this.showLoading();
                    } else {
                        this.hideLoading();
                    }
                },
            },
        },
        loading: {
            showDuration: 0, // Show the loader immediately
            hideDuration: 1000, // Hide the loader after 1 second (adjust as needed)
            labelStyle: {
                fontSize: '16px',
            },
        },
        tooltip: {
            enabled: tooltip,
            formatter: function () {
                return this.points?.reduce(tooltipFormatter ? tooltipFormatter : function (s, point) {
                    const pointColor = `
                    <tspan style="font-size:20px; color: ${point.color}; fill: ${point.color};">●</tspan>&nbsp`;
                    return s +
                      '<div style="display:flex;"> <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; paddingBottom:8px;">' + pointColor + point.series.name + '</div> ' +
                      '<div style="display:grid; width:100%;"><div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">&nbsp;&nbsp;&nbsp;' + convertUnitForGraphs(point.y, point.series.options.tooltip.valueSuffix) + '</div></div><br/></div> ';
                }, '<span style="font-size:15px; font-weight:bold">' + tooltipString + " " + this.x + '<hr></span>');
            },
            shared: true,
            useHTML: true,
            borderWidth: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        credits: {
            enabled: false
        },
        title: {
            text: title,
            align: titleAlignment,
            style: {
                fontWeight: 500,
                fontSize: '20px',
                fontFamily: 'Inter',
            },
        },
        lang: {
            noData: 'No data is available in the chart'
        },
        noData: {
            style: {
                fontWeight: 500,
                fontSize: '15px',
                color: '#9F9F9F'
            },
            position: {
                "x": 0,
                "y": 0,
                "align": "center",
                "verticalAlign": "middle"
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        plotOptions: plotOptions,
        exporting: {
            enabled: exporting,
            chartOptions: {
                title: {
                    text: title
                },
            },
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen",
                        {
                            separator: true
                        },
                        {
                            textKey: 'printChart',
                            onclick: function () {
                                this.print();
                            }
                        },
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        {
                            text: "Download XLS",
                            onclick() {
                                this.downloadXLS();
                            }
                        },
                        {
                            text: "Download CSV",
                            onclick() {
                                this.downloadCSV();
                            }
                        },
                    ]
                }
            }
        },
        colors: colors,
        series: data,
    };
};
