import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: "100%",
    },
  },
};

interface Props {
  options: Array<string>;
  handleChange: (value: any) => void;
  value: any;
}

export const RenderMultiSelect = (props: Props) => {
  const { options, handleChange, value } = props;

  const theme = useTheme();

  const onChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    handleChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl fullWidth style={{ marginTop: "20px" }}>
        <InputLabel
          id="demo-multiple-name-label"
          style={{ fontSize: "16px", marginBottom: "10px !important" }}
        >
          Role *
        </InputLabel>
        <Select
          required
          fullWidth
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          renderValue={(selected: any) => {
            if (selected.length <= 3) {
              return selected?.join(", ");
            } else {
              return (
                <>
                  <span>
                    {selected.slice(0, 3).join(", ")} +{selected.length - 3}
                  </span>
                </>
              );
            }
          }}
          value={value}
          onChange={onChange}
          input={<OutlinedInput label="Role*" />}
          MenuProps={MenuProps}
        >
          {options?.map((item, idx) => (
            <MenuItem
              key={item["roleId"]}
              value={item["roleDisplayName"]}
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {item["roleDisplayName"]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
