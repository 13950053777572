import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBearerToken } from "../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const lobbyApiUrl = `SkyfriSidemApi/v1/LobbyScreen`
const lobbyScreenColors = `SkyfriSidemApi/v1/LobbyScreenColors`
const sidemApiUrl = `SkyfriSidemApi/v1`;

export const onBoardingApi = createApi({
    reducerPath: "onBoardingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const accessToken = getBearerToken();
            const token = "Bearer " + localStorage.getItem("token");
            if (token) {
                headers.set("authorization", token);
                headers.set(
                    "Ocp-Apim-Subscription-Key",
                    process.env.REACT_APP_API_MKEY
                );
            }
            return headers;
        },
    }),
    tagTypes: ["Image", "Colors", "Tenant"],

    endpoints: (builder) => ({
        getPortoliosByModuleId: builder.query({
            query: ({ tenantId, moduleId }) => ({
                url: `onboardingV2/V1/Portfolio/GetPortfolios?tenantId=${tenantId}&moduleId=${moduleId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getModulesBuTenantId: builder.query({
            query: ({ tenantId }) => ({
                url: `/onboardingV2/v1/ModuleTenant/GetModulesForTenant?tenantId=${tenantId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getPlantsToUser: builder.query({
            query: ({ tenantId, userId, moduleId }) => ({
                url: `/onboardingV2/v1/PlantsToUser/PlantsForUser?tenantId=${tenantId}&userId=${userId}&moduleId=${moduleId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
            providesTags: ["Tenant"],
        }),
        postPlantsByTenantAndModuleId: builder.mutation({
            query: ({ data }) => ({
                url: `/onboardingV2/v1/PlantsToUser/ChangePlantsForUser`,
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "text/json",
                },
            }),
            invalidatesTags: ["Tenant"],
        }),
        getPlantsByTenantAndModuleId: builder.query({
            query: ({ tenantId, moduleId }) => ({
                url: `/onboardingV2/v1/Plant/GetPlantByModule?tenantId=${tenantId}&moduleId=${moduleId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getAllTenants: builder.query<any, void>({
            query: () => "onboardingV2/v1/Tenant/GetTenants",
        }),

        getTenantUsers: builder.query({
            query: ({ tenantId }) => ({
                url: `onboardingV2/v1/TenantUsers/GetTenantUsers?tenantId=${tenantId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getLobbyScreenPagePreferences: builder.query({
            query: ({ userId, tenantId }) => ({
                url: `${lobbyApiUrl}/GetLobbyScreenPagePreferences?tenantId=${tenantId}&userId=${userId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        addLobbyScreenPagePreferences: builder.mutation({
            query: ({ data }) => ({
                url: `${lobbyApiUrl}/AddLobbyScreenPagePreferences`,
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getLobbyLogo: builder.query({
            query: ({ logoType, userId, tenantId }) => ({
                url: `${lobbyApiUrl}/GetLobbyScreenLogo?logoType=${logoType}&userId=${userId}&tenantId=${tenantId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        addLogo: builder.mutation({
            query: ({ data }) => ({
                url: `${lobbyApiUrl}/AddLobbyScreenLogo`,
                method: "POST",
                body: data,
            }),
        }),
        deleteLogo: builder.mutation({
            query: ({ logoType, userId, tenantId }) => ({
                url: `${lobbyApiUrl}/DeleteLobbyScreenLogo?userId=${userId}&tenantId=${tenantId}&logoType=${logoType}`,
                method: "DELETE",
            }),
        }),
        getSiteImages: builder.query({
            query: ({ tenantId, plantId }) => ({
                url: `${sidemApiUrl}/LobbyScreenPlantImages/GetLobbyScreenPlantImage?plantId=${plantId}&tenantId=${tenantId}&compressed=true&imageQuality=1`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
            providesTags: ["Image"],
        }),
        addImage: builder.mutation({
            query: ({ data }) => ({
                url: `${sidemApiUrl}/LobbyScreenPlantImages/AddLobbyScreenPlantImage`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Image"],
        }),
        deleteImage: builder.mutation({
            query: ({ tenantId, plantId }) => ({
                url: `${sidemApiUrl}/LobbyScreenPlantImages/DeleteLobbyScreenPlantImage?plantId=${plantId}&tenantId=${tenantId}`,
                method: "POST",
            }),
            invalidatesTags: ["Image"],
        }),
        getTenantColors: builder.query({
            query: ({ tenantId }) => ({
                url: `${lobbyScreenColors}/GetLobbyScreenColors?tenantId=${tenantId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
        }),
        getUsersColors: builder.query({
            query: ({ tenantId, userId }) => ({
                url: `${lobbyScreenColors}/GetLobbyScreenColors?tenantId=${tenantId}&userId=${userId}`,
                method: "GET",
                headers: {
                    "Content-Type": "text/json",
                },
            }),
            providesTags: ["Colors"],
        }),
        addTenantColors: builder.mutation({
            query: ({ data, tenantId }) => ({
                url: `${lobbyScreenColors}/AddLobbyScreenColors?tenantId=${tenantId}`,
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "text/json",
                },
            }),
            invalidatesTags: ["Colors"],
        }),
    }),
});


export const {
    useGetPortoliosByModuleIdQuery,
    useGetModulesBuTenantIdQuery,
    useGetPlantsByTenantAndModuleIdQuery,
    useGetPlantsToUserQuery,
    usePostPlantsByTenantAndModuleIdMutation,
    useGetAllTenantsQuery,
    useGetTenantUsersQuery,
    useGetLobbyScreenPagePreferencesQuery,
    useAddLobbyScreenPagePreferencesMutation,
    useGetLobbyLogoQuery,
    useAddLogoMutation,
    useDeleteLogoMutation,
    useGetSiteImagesQuery,
    useAddImageMutation,
    useDeleteImageMutation,
    useGetTenantColorsQuery,
    useAddTenantColorsMutation,
    useGetUsersColorsQuery,
} = onBoardingApi;