import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({ active, payload }: { active?: boolean, payload?: Array<any> }) => {
  if (!active || !payload?.length)
    return null;

  const data = payload?.[0]?.payload;

  return (
    <TooltipWrapper>
      <div>Efficiency: {(Number(data.eficiency)*100)?.toFixed(1)} %</div>
      <div>Load: {data?.acload} % </div>
    </TooltipWrapper>
  )
}

// .toFixed(1)