import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

interface Props {
  checked: boolean;
  label: string;
  onChange: (val: boolean) => void;
  disabled?: boolean;
}

export const CustomCheckbox: React.FC<Props> = (props) => {
  const { checked, label, onChange, disabled } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(e: any) => onChange(!checked)}
        />
      }
      label={label}
    />
  );
};
