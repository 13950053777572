export const formSchema = {
  firstName: {
    type: "text",
    label: "First Name*",
    required: true,
    requiredText: "First name is required",
  },
  lastName: {
    type: "text",
    label: "Last name*",
    required: true,
    requiredText: "Last name is required",
  },
  email: {
    type: "email",
    label: "Email*",
    required: true,
    requiredText: "Email is required",
  },
  jobTitle: {
    type: "text",
    label: "Job title*",
    required: true,
    requiredText: "Job title is required",
  }
};
