import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { LatLngExpression } from 'leaflet';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MapContainer, Marker, TileLayer, Tooltip, ZoomControl } from 'react-leaflet';
import { Widget } from '../../../../components/CardsPageWidget';
import { useStyles } from './styles';
import { mapSchema } from '../utils';
import WeatherList from '../../../../components/WeatherList';
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import L from 'leaflet';
import { ReactComponent as LOCATION_BLACK } from '../../../../../shared/icons/sidem/LOCATION_BLACK.svg';
import { ReactComponent as BELL } from '../../../../../shared/icons/sidem/BELL.svg';

const myIcon = new L.Icon({
  iconUrl: require('../../../../../shared/icons/sidem/LOCATION_POINTER_PNG.png'),
  iconRetinaUrl: require('../../../../../shared/icons/sidem/LOCATION_POINTER_PNG.png'),
  iconSize: [25, 30],
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

const CustomMarker: React.FC<{ item: Widget; level: number; }> = ({ item, level }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getTitleNameValue = (fields, title) => {
    const field = fields.find(element => element?.title === title)
    return (
      <>
        <div className={classes.columnLeft}>
          {field?.title}
        </div>
        <div className={classes.columnRight}> {field?.value} {field?.unit}</div>
      </>
    );
  }

  return (
    <Marker icon={myIcon} position={[item?.latitude || 0, item?.longitude || 0]}>
      <Tooltip direction="bottom" offset={[1, 17]} opacity={1} permanent className={classes.tooltip}>
        <div className={classes.markerWrapper}>

          <Accordion sx={{
            boxShadow: "none",
          }}
            disableGutters
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{
                pointerEvents: "auto"
              }} />}
              aria-controls="panel1a-content"
              id="overview"
              sx={{
                pointerEvents: "none",
                padding: "0",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                }
              }}
            >
              <div className={classes.containerTop}>

                <div className={classes.columTitle}>
                  <div className={classes.title}>
                    {item?.title}
                  </div>
                </div>

                <div className={classes.columnError}>
                  <div className={classes.error}>
                    {item?.errors}
                    <span style={{ paddingLeft: "3px", paddingTop: "3px" }}>
                      <BELL />
                    </span>
                  </div>
                </div>

                <div className={classes.columnPR}>
                  <div className={clsx(classes.button, { [classes.warningButton]: item?.actualResult < level })}>
                    {item?.actualResult?.toFixed(2)} %
                  </div>
                </div>

                <div className={classes.columnLocation}>
                  <LOCATION_BLACK />
                </div>

                <div className={classes.columnLocationName}>
                  {item?.location}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <hr className={classes.hrLine} />
              <div className={classes.containerBottom}>
                {mapSchema["mapOptions"].map(element => (
                  <>
                    {getTitleNameValue(item?.fields, element)}
                  </>
                ))}

                <div className={classes.columnLeft}>
                  Inverter status
                </div>
                <div className={classes.columnRight}>
                  {item?.workingInverters} / {item?.totalInverters}
                </div>
                <div className={classes.weatherLeft}>
                  Today's Weather
                </div>
                <div className={classes.weatherRight}>
                  <WeatherList
                    notHighlighted={true}
                    weathers={item?.weathers}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

      </Tooltip>
    </Marker>
  )
};
const SimpleMarker: React.FC<{ item: Widget; level: number; }> = ({ item, level }) => {
  return (
    <Marker icon={myIcon} position={[item?.latitude || 0, item?.longitude || 0]} />
  )
};
interface Props {
  widgets: Array<Widget>;
  height?: string;
  miniMap?: boolean;
  zoom?: number
}

export const MapLeaflet: React.FC<Props> = ({ widgets, height = "100", miniMap = false, zoom = 4 }) => {
  const [showWidgets, setShowWidgets] = useState(false);
  const [zoomControls, setZoomControls] = useState(false);
  useEffect(() => {
    if (!miniMap)
      setZoomControls(true)
  }, [])

  const center: LatLngExpression = [widgets[0]?.latitude || 0, widgets[0]?.longitude || 0];

  const widgetsFunc = () => (
    widgets.map((item) => (
      <CustomMarker key={item?.title} item={item} level={70} />
    ))
  )

  const nonWidgetsFunc = () => (
    widgets.map((item) => (
      <SimpleMarker key={item?.title} item={item} level={70} />
    ))
  )

  const handleFullscreen = () => {
    setShowWidgets(true)
    if (miniMap) {
      setZoomControls(true)
    }
  }

  const handleExitFullscreen = () => {
    setShowWidgets(false)
    if (miniMap) {
      setZoomControls(false)
    }
  }

  return (
    <MapContainer
      style={{ height: `calc(${height}vh - 140px)`, width: "100%" }}
      center={center}
      zoom={zoom}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Fullscreen
        eventHandlers={{
          enterFullscreen: () => handleFullscreen(),
          exitFullscreen: () => handleExitFullscreen(),
        }}
        position={"topright"}
      />
      {
        !miniMap ?
          widgetsFunc()
          :
          showWidgets ?
            widgetsFunc()
            :
            nonWidgetsFunc()
      }
      {
        zoomControls ?
          <ZoomControl position="topright" />
          :
          null
      }
    </MapContainer>
  );
}
