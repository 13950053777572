import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const values = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>Time: {values.sensor_time} </strong>
      </div>
      <div>Hourly PR: {values["PR"]} %</div>
      <div>Irradiation: {values["Irradiation"]} kWh/m²</div>
    </TooltipWrapper>
  );
};
