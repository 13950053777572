import React from "react";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import { ForecastTable } from "./ForecastTable";
import { PowerWidget } from "./components/PowerWidget/PowerWidget.component";
import { EnergyWidget } from "./components/EnergyWidget/EnergyWidget.component";

export const ForecastWidget: React.FC<any> = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={12}>
          <div className={classes.title}>Forecasting</div>
        </Grid>
        <Grid item xs={12}>
          <EnergyWidget />
        </Grid>
        <Grid item xs={12}>
          <PowerWidget />
        </Grid>
        <Grid item xs={12}>
          <ForecastTable />
        </Grid>
      </Grid>
    </div>
  );
};
