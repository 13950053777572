import { months } from "../../../../../shared/utils/utils";
import { ENERGY_PROVIDERS } from "../../../BatteryMonitoring/BatteryMonitoring.api.types";

export const getBatteryControlTableDisplayDateFormat = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

  return `${day} ${months[month]} ${year} / ${hours}:${minutes}:${seconds}`;
};

export const sources: string[] = [
  ENERGY_PROVIDERS.Skyfri,
  ENERGY_PROVIDERS.Ebox,
  ENERGY_PROVIDERS.AmpereCloud,
].map((i) => String(i));

export const ROW_HEIGHT = "50px";

