import React, { useMemo } from "react";

import { MultilineChartWidget } from "../../../../components/MultilineChartWidget";

import { CustomTooltip } from "./CustomTooltip.component";
import { useInverterTemperature } from "./useInverterTemperature";

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
  definition?: any;
}

export const InverterInternalTempWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
  blockId,
  inverterId,
}) => {
  const { data, lines, error, loading } = useInverterTemperature({
    plantId,
    date,
    blockId,
    inverterId,
  });

  const hide = useMemo(() => !data?.length && loading, [data, loading]);

  return hide ? null : (
    <MultilineChartWidget
      title="Inverter Internal temperature (°C)"
      lines={lines}
      data={data}
      loading={loading}
      size={6}
      error={error}
      height={500}
      tooltipContent={<CustomTooltip />}
      definition={definition}
    />
  );
};
