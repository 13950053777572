import React, { useState } from "react";
import posthog from "posthog-js";
import { ReactComponent as COOKIE } from "../../src/shared/icons/auth/COOKIE.svg";
import { Button } from "../shared/components";

export const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="overlay">
          <div className="cookie-container">
            <div className="cookie-body">
              <div className="cookie-subContainer">
                <span className="cookie-icon">
                  <COOKIE />
                </span>
                <h3 className="cookie-header">We use cookies</h3>
              </div>
              <p className="cookie-section">
                Skyfri uses cookies to offer you a better experience. See
                <span className="cookie-link-text">
                  <a
                    href={"https://skyfri.com/privacy/"}
                    target="_blank"
                    className="cookie-link"
                  >
                    Cookie Notice
                  </a>
                </span>
                for details.
              </p>

              <div className="cookie-buttons">
                <Button
                  className="cookie-button"
                  color={"rgba(12, 30, 51, 1)"}
                  outlined={false}
                  type="button"
                  margin={"10px"}
                  title={"Opt out"}
                  marginLeft={"0px"}
                  marginRight={"0px"}
                  width={"151px"}
                  onClick={declineCookies}
                />
                <Button
                  className="cookie-button"
                  color={"rgba(12, 30, 51, 1)"}
                  outlined={false}
                  margin={"10px"}
                  width={"151px"}
                  marginLeft={"10px"}
                  type="button"
                  title={"Accept"}
                  onClick={acceptCookies}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
