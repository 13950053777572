export const GRAPH_COLORS = {
  success: "#2D826B",
  error: "#BC4F73",
  normal: "#015E94",
  neutral: "#D7D7D7",
  blue: "#007BC2",
  gray: "#E5E5E5",
  darkGray: "#616161",
  purple: "#A275C8",
  orange: "#DB5507",
  appBlue: "#0C1E33",
  white: "#FFFFFF",
  black: "#000000"
};
