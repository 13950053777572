import { Cell } from "../../../../../shared/components";
// import { Fields } from "../../../../components/interfaces";
import {
  MppKpiAlarm,
  PlantStatus,
} from "../../../../../features/Sidem/shared/sidemSharedApi.types";
import { ReactComponent as BELL_BLACK } from "../../../../../shared/icons/sidem/BELL_BLACK.svg";
import { ReactComponent as BELL_GRAY } from "../../../../../shared/icons/sidem/BELL_GRAY.svg";
import { ReactComponent as BELL } from "../../../../../shared/icons/sidem/BELL.svg";
import { ReactComponent as ERROR_WHITE } from "../../../../../shared/icons/sidem/ERROR_WHITE.svg";
import { ReactComponent as Table } from "../../../../../shared/icons/monitoring/dashboard/VIEW_LIST.svg";
import { ReactComponent as Map } from "../../../../../shared/icons/monitoring/dashboard/MAP.svg";
import TABLEVIEW from "../../../../../shared/icons/monitoring/dashboard/TABLEVIEW.svg";
import MAP from "../../../../../shared/icons/monitoring/dashboard/MAP.svg";
import MAP_WHITE from "../../../../../shared/icons/monitoring/dashboard/MAP_WHITE.svg";
import VIEW_LIST from "../../../../../shared/icons/monitoring/dashboard/VIEW_LIST.svg";
import TREEMAP from "../../../../../shared/icons/monitoring/dashboard/TREEMAP.svg";
import CAPACITY_GREY from "../../../../../shared/icons/monitoring/dashboard/CAPACITY_GREY.svg";
import ALARM_GREY from "../../../../../shared/icons/app/ALARM_GREY.svg";
import VIEWLIST from "../../../../../shared/icons/monitoring/dashboard/VIEWLIST.svg";
import CALENDAR from "../../../../../shared/icons/monitoring/dashboard/CALENDAR.svg";
import VIEW_CALENDAR from "../../../../../shared/icons/monitoring/dashboard/VIEW_CALENDAR.svg";
import { GLOBAL_COLORS } from "../../../../../shared/utils/utils";
import React from "react";

export const Conditions = {
  TECHNICALVIEW_LIVE: "TECHNICALVIEW_LIVE",
  TECHNICALVIEW_NONLIVE: "TECHNICALVIEW_NONLIVE",
  PRVIEW_LIVE: "PRVIEW_LIVE",
  PRVIEW_NONLIVE: "PRVIEW_NONLIVE",
};

const PVFields = {
  PV: "pvAssets",
  Capacity: "capacity",
  Alarms: "alarms",
  Inverters: "inverters",
  AP: "activePower",
  Co2Saved: "co2saved",
  TreesPlanted: "treesPlanted",
  Irradiance: "irradiance",
  Generation: "generation",
  Insolation: "insolation",
  PR: "pr",
  PlantAvailability: "plantAvailability",
  GridAvailability: "gridAvailability",
  LastUpdated: "lastUpdated",
  ViewMore: "viewMore",
};

const BESSFields = {
  Batteries: "batteries",
  Capacity: "capacity",
  Alarms: "alarms",
  AP: "activePower",
  SOC: "soc",
  Service: "service",
  State: "state",
  Control: "control",
  LastUpdated: "lastUpdated",
  EnergyCharged: "energyCharged",
  EnergyDischarged: "energyDischarged",
  Availability: "availability",
  MaxTemperature: "maxTemperature",
};

const PVdescriptions = {
  technicalViewLive: {
    pvAssets: "The name of the asset.",
    capacity: "Asset DC capacity.",
    alarms: "Number of active alarms.",
    inverters:
      "Number of currently running inverters vs total inverters installed of listed assets.",
    activePower: "The total active power generated by the asset.",
    irradiance:
      "Global Horizontal Irradiance - the amount of terrestrial irradiance falling on a horizontal surface.",
    lastUpdated: "Last time when the data were refreshed on the dashboard.",
  },
  technicalViewNotLive: {
    pvAssets: "The name of the asset.",
    capacity: "Asset DC capacity.",
    generation: "Energy generated during the selected period by the asset.",
    insolation:
      "Solar energy provided to the horizontal surface over a selected time. The value is calculated based on GHI reading. This value represents the total energy provided in the specific geographical location for horizontal surface and should not be used to calculate site efficiency.",
  },
  environmentalViewNotLive: {
    pvAssets: "The name of the asset.",
    capacity: "Asset DC capacity.",
    generation: "Energy generated during the selected period by the asset.",
    co2saved:
      "The amount of CO₂ emissions reduced by using solar energy in this period",
    treesPlanted:
      "The number of urban tree seedlings needed to absorb the same CO₂ saved",
  },
  prViewLive: {
    pvAssets: "The name of the asset.",
    capacity: "The total DC capacity of listed assets.",
    pr: "The performance ratio (PR) is a measure of how energy efficient and reliable a power plant is.",
    lastUpdated: "Last time an update was received from the plant.",
  },
  prViewNotLive: {
    pvAssets: "The name of the asset.",
    capacity: "The total DC capacity of listed assets.",
    pr: "The performance ratio (PR) is a measure of how energy efficient and reliable a power plant is.",
  },
};

const createCell = (
  key: string,
  label: string,
  align: "left" | "right" | "center",
  definition: string,
  getValueComponent = null
): Cell => ({ key, label, align, definition, getValueComponent });

const headerCells = [
  createCell(PVFields.PV, "PV assets", "left", ""),
  createCell(PVFields.Capacity, "Capacity", "left", ""),
  createCell(PVFields.Alarms, "Alarms", "left", ""),
  createCell(PVFields.Inverters, "Inverters", "left", ""),
  createCell(PVFields.AP, "Power", "left", ""),
  createCell(PVFields.Irradiance, "Irradiance", "left", ""),
  createCell(PVFields.Generation, "Generation", "left", ""),
  createCell(PVFields.Co2Saved, "CO₂ saved", "left", ""),
  createCell(PVFields.TreesPlanted, "Equivalent trees planted", "left", ""),
  createCell(PVFields.Insolation, "Insolation", "left", ""),
  createCell(PVFields.PR, "PR", "left", ""),
  createCell(PVFields.PlantAvailability, "Plant Availability", "left", ""),
  createCell(PVFields.GridAvailability, "Grid Availability", "left", ""),
  createCell(PVFields.LastUpdated, "Last Updated", "left", ""),
  createCell(PVFields.ViewMore, "", "left", ""),
];

export const getPVHeaderCells = (
  liveButton,
  selectedTab,
  selectedButtonView,
  view
) => {
  if (selectedTab === 0 && selectedButtonView === "Table") {
    let filterKeys, descKey;

    if (view === "Technical view") {
      if (liveButton) {
        filterKeys = [
          "pvAssets",
          "capacity",
          "alarms",
          "inverters",
          "activePower",
          "irradiance",
          "lastUpdated",
        ];
        descKey = "technicalViewLive";
      } else {
        filterKeys = ["pvAssets", "capacity", "generation", "insolation"];
        descKey = "technicalViewNotLive";
      }
    } else if (view === "Environmental view") {
      filterKeys = [
        "pvAssets",
        "capacity",
        "generation",
        "co2saved",
        "treesPlanted",
      ];
      descKey = "environmentalViewNotLive";
    } else if (view === "PR view") {
      if (liveButton) {
        filterKeys = ["pvAssets", "capacity", "pr", "lastUpdated"];
        descKey = "prViewLive";
      } else {
        filterKeys = ["pvAssets", "capacity", "pr"];
        descKey = "prViewNotLive";
      }
    }

    const filteredHeaderCells = headerCells?.filter((cell) =>
      filterKeys?.includes(cell.key)
    );
    filteredHeaderCells.forEach((cell) => {
      cell.definition = PVdescriptions[descKey][cell.key];
    });
    return filteredHeaderCells;
  }

  return headerCells;
};

const BESSHeaderCells = {
  technicalViewLive: [
    createCell("batteries", "Batteries", "left", ""),
    createCell("bessCapacity", "Capacity", "left", ""),
    createCell("bessAlarms", "Alarms", "left", ""),
    createCell("bessPower", "Power", "left", ""),
    createCell("soc", "SOC", "left", ""),
    // createCell("service", "Service", "left", ""),// Not in the response
    createCell("state", "State", "left", ""),
    // createCell("control", "Control", "left", ""),// Not in the response
    createCell("besslastUpdated", "Last Updated", "left", ""),
  ],
  technicalViewNotLive: [
    createCell("batteries", "Batteries", "left", ""),
    createCell("bessCapacity", "Capacity", "left", ""),
    createCell("energyCharged", "Energy Charged", "left", ""),
    createCell("energyDischarged", "Energy Discharged", "left", ""),
    // createCell("availability", "Availability", "left", ""), // Not in the response
    createCell("maxTemperature", "Max Temperature", "left", ""),
  ],
};

export const getBESSHeaderCells = (
  liveButton,
  selectedTab,
  selectedButtonView,
  view
) => {
  if (selectedTab !== 1 || selectedButtonView !== "Table") return [];

  if (view === "Technical view") {
    return liveButton
      ? BESSHeaderCells.technicalViewLive
      : BESSHeaderCells.technicalViewNotLive;
  }

  return [];
};

export enum VIEW {
  TABLE = "Table view",
  MAP = "Map view",
}

export enum VIEW_OPTIONS {
  TABLE = "Table view",
  MAP = "Map view",
}
interface ViewOption {
  label: VIEW_OPTIONS;
  icon: React.ReactNode;
}

export const viewOptions = [
  {
    label: VIEW_OPTIONS.TABLE,
    icon: Table,
  },
  {
    label: VIEW_OPTIONS.MAP,
    icon: Map,
  },
];
export const TreeMapViewOptions: string[] = ["Counties", "O&M", "EPC"];

export const checkAlarmState = (
  plantStatus: PlantStatus | "",
  numberOfAlarms: number
) => {
  let alarmToSend: MppKpiAlarm = {
    textColor: GLOBAL_COLORS.WHITE,
    opacity: 1,
    numberOfAlarms: "Error",
    backGroundColor: GLOBAL_COLORS.LIGHT_ALARMS_ERROR,
    icon: ERROR_WHITE,
  };
  if (plantStatus === "On" && !numberOfAlarms) {
    //Plant is ON, no alarms
    alarmToSend = {
      textColor: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY,
      opacity: 0.5,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40,
      icon: BELL_GRAY,
    };
  } else if (plantStatus === "On" && numberOfAlarms >= 0) {
    //Plant is ON with alarms
    alarmToSend = {
      textColor: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY,
      opacity: 1,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40,
      icon: BELL_BLACK,
    };
  } else if (plantStatus === "Off" && !numberOfAlarms) {
    //Plant is OFF, no alarms
    alarmToSend = {
      textColor: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY,
      opacity: 0.5,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40,
      icon: BELL_GRAY,
    };
  } else if (plantStatus === "Off" && numberOfAlarms >= 0) {
    //Plant is OFF with alarms
    alarmToSend = {
      textColor: GLOBAL_COLORS.WHITE,
      opacity: 1,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: GLOBAL_COLORS.LIGHT_ALARMS_CRITICAL,
      icon: BELL,
    };
  }
  return alarmToSend;
};

export const checkCommunication = (comm: PlantStatus) => {
  let commToSend = "gray";
  switch (comm) {
    case "On":
      commToSend = "green";
      break;
    case "Off":
      commToSend = "red";
      break;
    default:
      commToSend = "gray";
      break;
  }
  return commToSend;
};

export const tabLabels: string[] = ["PV Assets", "BESS"];

export const pvItems = [
  {
    icon: CAPACITY_GREY,
    label: "Capacity",
    value: "25.68 MWp",
  },
  {
    icon: CAPACITY_GREY,
    label: "Generation today",
    value: "13.6 MWh",
  },
  {
    icon: CAPACITY_GREY,
    label: "Inverters",
    value: "51/52",
  },
  {
    icon: ALARM_GREY,
    label: "Alarms",
    value: "99+",
  },
];

export const BessItems = [
  {
    icon: CAPACITY_GREY,
    label: "Capacity",
    value: "10.5 MWh",
  },

  {
    icon: ALARM_GREY,
    label: "Alarms",
    value: "2",
  },
];
export interface BessItem {
  label: string;
  value: any;
}

export const Bess: BessItem[] = [
  {
    label: "PowerVault Energy",
    value: "1",
  },
  {
    label: "EnergyGuard Solutions",
    value: "2",
  },
  {
    label: "ChargeSwift Technologies",
    value: "3",
  },
];

interface Button {
  label: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
}
export const buttons: Button[] = [
  { label: "Table", icon: VIEW_LIST, selectedIcon: VIEWLIST },
  { label: "Map", icon: MAP, selectedIcon: MAP_WHITE },
  { label: "Tree Map", icon: CALENDAR, selectedIcon: VIEW_CALENDAR },
];
