import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(0, 2, 2, 0),
    width: "100%",
    position: "relative",
    boxShadow: "0px 2px 8px 0px rgb(12 30 51 / 20%)",
    transition: "box-shadow 0.3s",
    "&:hover" : {
      boxShadow: "2px 6px 24px 0px rgb(12 30 51 / 20%)",
    },
  },

  errorWrapper: {
    // border: '2px solid #C94B4B'
  },

  error: {
    position: "absolute",
    right: -12,
    top: -12,
    color: "#FFFFFF",
    textAlign: "center",
    paddingTop: 2,
    width: 24,
    height: 24,
    zIndex: 1,
    borderRadius: "50%",
    backgroundColor: "#C94B4B",
  },

  image: {
    height: 150,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },

  profit: {
    position: "absolute",
    borderRadius: 5,
    top: 10,
    right: 10,
    color: "#FFFFFF",
    backgroundColor: "#70BEAB",
    padding: theme.spacing(1, 2),
  },

  negativeProfit: {
    backgroundColor: "#C94B4B",
  },

  selector: {
    position: "absolute",
    borderRadius: 5,
    top: 10,
    left: 10,
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },

  select: {
    "&::before": {
      display: "none",
    },

    "&::after": {
      display: "none",
    },

    border: "none",
  },

  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    cursor: "pointer"
  },

  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 22,
    fontWeight: 500,
    padding: theme.spacing(1, 1, 0, 1),
    color: "#FFFFFF",
    lineHeight: "120%",
  },
  cardNavigation: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
    padding: "1rem",
    color: "white",
    height: "45px",
    cursor: "pointer"
  },
  plantSummary: {
    color: "#0C1E33 !important",
    backgroundColor: "#E8ECF2",
    borderRadius: "3px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#CACFD7"
    }
  },
  buttonText: {
    fontWeight: 500,
    fontSize: 15,
    paddingLeft: "8px",
  },


  mapview: {
    color: "#FFFFFF!important",
    fontWeight: 400,
    fontSize: 15,
    padding: theme.spacing(0, 2.5, 0, 0),
  },
  issues: {
    margin: theme.spacing(0, 1),
  },
  issuesButton: {
    color: "#0C1E33!important",
    backgroundColor: "#E8ECF2",
    borderRadius: "3px",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#E8ECF2"
    }
  },
}));