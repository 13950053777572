import { ReactComponent as BELL_BLACK } from "../../../../../shared/icons/sidem/BELL_BLACK.svg";
import { ReactComponent as BELL_GRAY } from "../../../../../shared/icons/sidem/BELL_GRAY.svg";
import { ReactComponent as BELL } from "../../../../../shared/icons/sidem/BELL.svg";
import { ReactComponent as ERROR_WHITE } from "../../../../../shared/icons/sidem/ERROR_WHITE.svg";
import { PlantAlarm } from "./PlantWidget.types";
import { PlantStatus } from "../../../../../features/Sidem/shared/sidemSharedApi.types";

export const getAlarmState = (comm: PlantStatus, numberOfAlarms: number) => {
  let alarmToSend: PlantAlarm = {
    textColor: "#FFFFFF",
    opacity: 1,
    numberOfAlarms: "Error",
    backGroundColor: "#808080",
    icon: ERROR_WHITE,
  };

  if (comm === "On" && !numberOfAlarms) {
    //Plant is ON, no alarms
    alarmToSend = {
      textColor: "#0C1E33",
      opacity: 0.5,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: "#E1E4E9",
      icon: BELL_GRAY,
    };
  } else if (comm === "On" && numberOfAlarms >= 0) {
    //Plant is ON with alarms
    alarmToSend = {
      textColor: "#0C1E33",
      opacity: 1,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: "#E1E4E9",
      icon: BELL_BLACK,
    };
  } else if (comm === "Off" && !numberOfAlarms) {
    //Plant is OFF, no alarms
    alarmToSend = {
      textColor: "#0C1E33",
      opacity: 0.5,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: "#E1E4E9",
      icon: BELL_GRAY,
    };
  } else if (comm === "Off" && numberOfAlarms >= 0) {
    //Plant is OFF with alarms
    alarmToSend = {
      textColor: "#FFFFFF",
      opacity: 1,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: "#DE504F",
      icon: BELL,
    };
  }
  return alarmToSend;
};

export const getAlarmStateForPortfolios = (numberOfAlarms: number) => {
  let alarmToSend: PlantAlarm = {
    textColor: "#0C1E33",
    opacity: 0.5,
    numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
    backGroundColor: "#E1E4E9",
    icon: BELL_GRAY,
  };

  if (numberOfAlarms >= 0) {
    //Plant is ON with alarms
    alarmToSend = {
      textColor: "#0C1E33",
      opacity: 1,
      numberOfAlarms: !numberOfAlarms ? 0 : numberOfAlarms,
      backGroundColor: "#E1E4E9",
      icon: BELL_BLACK,
    };
  }

  return alarmToSend;
};
