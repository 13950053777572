import React from "react";
import Stack from "@mui/material/Stack";
import { Snackbar as ReactSnackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  open: boolean;
  message: string;
  handleClose: () => void;
  vertical?: string;
  horizontal?: string;
  severity?: string;
  redirectURI?: string;
  type?: string;
  callback?: any;
}
const useStyles = makeStyles(() => ({
  warning: {
    background: "#ED6C02",
  },
  success: {
    background: "#2E7D32",
  },
  info: {
    background: "#0288D1",
  },
  error: {
    background: "#dc3545",
  },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snackbar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.type == "modal") {
      props.callback()
    } else {
      props.handleClose();
      navigate(props.redirectURI);
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <ReactSnackbar
        open={props.open}
        onClose={() => {
          handleClose();
        }}
      >
        <Alert
          onClose={() => handleClose()}
          severity="success"
          sx={{ width: "100%" }}
        >
          <h2>Note:please save this information,you will not be able to retrieve this information again. </h2>
          {props.message}
        </Alert>
      </ReactSnackbar>
    </Stack>
  );
};
