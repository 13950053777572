import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 0, 0, 0),
        borderRadius: theme.spacing(1),
        marginTop: "8px",
    },
}));