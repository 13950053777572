import React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ErrorMessage } from "../ErrorMessage";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';



export interface DropdownOption {
	label: string;
	value: any;
}

interface Props {
	value: any;
	data: Array<DropdownOption>;
	onChangeValue: (e: any) => void;
	label?: string;
	name?: string;
	width?: number | string;
	multiple?: boolean;
	showError?: boolean;
	errorMessage?: string;
	showAdd?: boolean;
	onAddNew?: (key: string) => void;
	disabled?: boolean;
	inHeight?: string;
	required?: boolean;
	expandIcon?: boolean;
}

export const OutlinedDropdown: React.FC<Props> = ({
	value,
	onChangeValue,
	data,
	label,
	name = "",
	width,
	multiple,
	showError,
	errorMessage,
	showAdd,
	onAddNew,
	disabled,
	inHeight = "50px",
	expandIcon
}) => {
	const [open, setOpen] = React.useState(false);
	return (
		<FormControl sx={{ minWidth: width || 200 }}>
			<TextField
				value={value}
				onChange={(e: any) => onChangeValue(e.target.value)}
				name={name}
				disabled={disabled}
				label={label}
				required
				InputProps={{
					style: { height: inHeight, backgroundColor: disabled ? "#E1E4E9" : "" },
				}}
				select
				SelectProps={
					expandIcon ? {
						IconComponent: () => open ? <ExpandLessRoundedIcon style={{ color: "#757575", marginRight: "10px" }} /> : <ExpandMoreRoundedIcon style={{ color: "#757575", marginRight: "10px" }} />,
						onOpen: () => setOpen(true),
						onClose: () => setOpen(false),
					}
						: null}
			>
				{data.length > 0 &&
					data?.map((item, index) => {
						return (
							<MenuItem key={item.label + index} value={item.value}>
								<span style={{ paddingLeft: 5 }}>{item.label}</span>
							</MenuItem>
						);
					})}
			</TextField>
			<ErrorMessage showError={showError} errorMessage={errorMessage} />
		</FormControl>
	);
};
