import { useMemo, useState } from "react";
import HeatMap from "react-heatmap-grid";
import Popup, { Sides } from "../Popup"
import { Box, Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import { ArrowUpwardSharp, ArrowDownwardSharp } from "@mui/icons-material";
import clsx from "clsx";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Button } from "../../../shared/components";
import { useNavigate, useLocation } from "react-router-dom";
interface Props {
  data: any;
  xLabels: any;
  yLabels?: Array<string>;
  popUpData?: any;
  heatMapColors?: object;
  date?: string | Date;
}
export const InverterHeatmapChart: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [xIndex, setXIndex] = useState("");
  const { data, xLabels, yLabels, popUpData, heatMapColors, date } = props;
  const anchorOrigin: any = { vertical: Sides.bottom, horizontal: Sides.center };
  const transformOrigin: any = { vertical: Sides.top, horizontal: Sides.center };
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const xLabelsVisibility = useMemo(
    () => xLabels?.map((item, idx) => idx + 1),
    [xLabels]
  );
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const openMenu = (event?: any) => {
    setAnchorEl(event?.currentTarget);
  };
  const getInverterNumber = (inverter, type) => {
    let split = inverter.split('-')[1]
    split = split.split('.')

    if (type === "inv") {
      return split[1]
    }
    if (type === "block") {
      return split[0]
    }
  }
  const showPopup = () => {
    const item = popUpData?.[xIndex];
    return (
      <Box sx={{ width: 300 }} className={classes.containerPadding}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <div className={classes.title}>
              {item?.equipmentName}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <div className={classes.closeButton} onClick={closeMenu}>
                <CloseIcon />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr className={classes.line} />
          </Grid>

          {/* KPI's */}

          <Grid item xs={6} className={classes.kpiName}>
            PR
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={clsx(classes.growth, classes.kpi, {
                [classes.negativeGrowth]: item?.actualPR < 0,
              })}
            >
              {
                item?.actualPR !== 0 ?
                  item?.actualPR < 0 ? (
                    <ArrowDownwardSharp className={classes.growthIcon} />
                  ) : (
                    <ArrowUpwardSharp className={classes.growthIcon} />
                  )
                  :
                  null}
              {item?.actualPR !== 0 ? `${item?.actualPR?.toFixed(1)}%` : null}
            </Grid>
          </Grid>
          <Grid item xs={8}>
            Specific Power <span className={classes.lightText}>{`(kWh/kWp)`}</span>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.kpi}
            >
              {item?.specificPower?.toFixed(1)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            AC power <span className={classes.lightText}>{`(kW)`}</span>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.kpi}
            >
              {item?.acPower?.toFixed(1)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            Today's kWh
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.kpi}
            >
              {item?.todayEnergy?.toFixed(1)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            PR delta
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={clsx(classes.growth, classes.kpi, {
                [classes.negativeGrowth]: item?.pR_Delta < 0,
              })}
            >
              {
                item?.pR_Delta !== 0 ?
                  item?.pR_Delta < 0 ? (
                    <ArrowDownwardSharp className={classes.growthIcon} />
                  ) : (
                    <ArrowUpwardSharp className={classes.growthIcon} />
                  )
                  :
                  null}
              {item?.pR_Delta !== 0 ? `${(item?.pR_Delta * 100)?.toFixed(1)}%` : null}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            Total <span className={classes.lightText}>{`${(item?.totalEnergy >= 1000 ? "(MWh)" : "(kWh)")}`}</span>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.kpi}
            >
              {
                item?.totalEnergy >= 1000 ?
                  (item?.totalEnergy / 1000).toFixed(1)
                  :
                  item?.totalEnergy?.toFixed(1)
              }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Button
                title="Inverter drilldown"
                outlined={false}
                color="#0C1E33"
                onClick={() =>
                  navigate(`${location.pathname}/block/${getInverterNumber(item.equipmentName, "block")}/inverter/${getInverterNumber(item.equipmentName, "inv")}`,
                    {
                      state: { date },
                    })
                }
                endIcon={<ChevronRightRoundedIcon />}
              >
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
  return (
    <div style={{ fontSize: "13px", overflow: "auto", position: "relative" }}>
      <Popup
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {showPopup()}
      </Popup>
      <HeatMap
        xLabels={xLabels}
        yLabels={yLabels}
        xLabelsLocation={"top"}
        xLabelsVisibility={xLabelsVisibility}
        xLabelWidth={60}
        yLabelWidth={0}
        yLabelTextAlign="left"
        data={data}
        squares={false}
        height={32}
        width={64}
        onClick={(xIndex) => {
          setXIndex(xIndex)
        }}
        cellStyle={(background, value, min, max, data, x, y) => ({
          background: heatMapColors[popUpData[x].severity]
          ,
          fontSize: "11.5px",
          color: "black",
          cursor: "default !IMPORTANT",
        })}
        cellRender={(value) =>
          value &&
          <>
            <div style={{ width: "auto", cursor: "pointer" }}
              onClick={openMenu} >
              {value?.toFixed(2)}
            </div>
          </>
        }
        tooltip={false}
      />
    </div>
  );
};
