import React, { useMemo } from "react";
import { Button } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";


interface Props {
  className?: string;
  width?: string;
  title: string;
  onClick: (id: any) => void;
  outlined?: boolean;
  color?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  noBorder?: boolean;
  margin?: string;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  padding?: string;
  disable?: boolean;
  showIcon?: boolean;
  fullWidth?: boolean;
  height?: string;
  borderRadius?: string;
  type?: "button" | "reset" | "submit";
  fontSize?: string | number;
  textColor?: string;
  borderColor?: string;
}

export const CustomButton: React.FC<Props> = (props) => {
  const {
    showIcon,
    title,
    onClick,
    outlined,
    color,
    startIcon,
    endIcon,
    noBorder,
    margin,
    marginRight,
    marginBottom,
    marginLeft,
    padding,
    type = "button",
    className = "",
    disable = false,
    fullWidth = false,
    height,
    width,
    borderRadius,
    fontSize,
    textColor,
    borderColor
  } = props;
  const style: any = useMemo(
    () => ({
      textColor: textColor ? textColor : "",
      backgroundColor: outlined ? null : color,
      color: outlined ? color : "#FFFFFF",
      textTransform: "none",
      border: noBorder ? "none" : `1px solid ${borderColor || color}`,
      borderRadius: borderRadius ? borderRadius : "",
      marginBottom: marginBottom ? marginBottom : margin,
      marginRight: marginRight ? marginRight : margin,
      marginLeft: marginLeft ? marginLeft : margin,
      padding: padding ? padding : "",
      height: height ? height : "",
      width: width ? width : "",
      className: className ? className : "",
      fontSize: fontSize ? fontSize : "",
      fontFamily: "Inter",
    }),
    [outlined, color, noBorder, margin, borderColor]
  );
  return (
    <Button
      fullWidth={fullWidth}
      disabled={disable}
      className={className}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      type={type}
    >
      {title}
      {showIcon ? <ControlPointIcon /> : ""}
    </Button>
  );
};