import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import { tooltipUnits } from "./data";

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const values = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>Time: {values.sensor_time} </strong>
      </div>
      <div>
        {Object.keys(values).map((key, i) => {
          if (key.includes("time") || !values[key]) return;
          const item = tooltipUnits?.filter((item) => item.key === key)[0];
          return (
            <span>
              {`${key} : ${Number(values[key]) > 1000
                  ? (Number(values[key]) / 1000).toFixed(2) +
                  " " +
                  item?.secondUnit
                  : Number(values[key]).toFixed(2) + " " + item?.unit
                } `}
              <br />
            </span>
          );
        })}
      </div>
    </TooltipWrapper>
  );
};
