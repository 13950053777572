import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { } from "./interfaces";
import { deletePlantReq } from "../../../../features/Onboarding/plants/plantActions";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  portfolioId: string;
  tenantId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any, module?: any, moduleId?: any) => void;
  Subsidiary: any;
  Contacts: any;
  Company: any;
  portfolio: any;
  Plant: any;
  deletePlant: (id: any, callback: any) => void;
  loading: any;
}

export const UsePortfolioSave = ({
  portfolioId,
  tenantId,
}: Props): Data => {
  const dispatch = useDispatch();
  const [Subsidiary, setSubsidiaries] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);
  const [Plant, setPlant] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setloading] = useState(null);

  const fetchAll = async () => {
    await getPortfolio();
    await getPlant();
  };

  useEffect(() => {
    if (!portfolioId) return;
    fetchAll();
  }, [portfolioId])


  const upsert = async (params: any, id: string, callback: any, module?: any, moduleId?: any) => {
    let requestType;
    if (id === "new") {
      requestType = "POST";
      const getParams = () => {
        const updatedParams = {
          ...params,
          ModuleId: moduleId,
        }
        return updatedParams;

      }
      await fetchAsync(`onboardingV2/v1/Portfolio/AddPortfolio`, requestType, getParams())
        .then((data: any) => {
          callback(data);
        })
        .catch((e) => {
          console.error(e);
          callback(e);
        });
    }
    if (id && id !== "new") {
      const newParams = {
        ...params,
        portfolioId: id,

      };
      requestType = "PUT";
      await fetchAsync(
        `onboardingV2/v1/Portfolio/UpdatePortfolio`,
        requestType,
        newParams
      )
        .then((data: any) => {
          callback(data);
        })
        .catch((e) => {
          console.error(e);
          callback(e);
        });
    }
  };

  const getPortfolio = async () => {
    setloading(true);
    await fetchAsync(
      `onboardingV2/V1/Portfolio/GetPortfolios?tenantId=${tenantId}`,
      "GET"
    )
      .then((data: any) => {
        if (data) {
          let newPortfolio = data?.map((item) => ({
            ...item,
            id: item.portfolioId,
          }));
          let currentPortfolio = newPortfolio.find((x) => x.id == portfolioId);
          setPortfolio(currentPortfolio);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getPlant = async () => {
    if (portfolioId !== "new") {
      setloading(true);
      await fetchAsync(
        `onboardingV2/v1/Plant/GetPlants?portfolioId=${portfolioId}`,
        "GET"
      )
        .then((data: any) => {
          if (data) {
            let newPlant = data?.map((item) => ({
              ...item,
              id: item.plantId,
            }));
            setPlant(newPlant);
            setloading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setloading(false);
        });
    }
  };

  const deletePlant = async (id, callback: any) => {
    setloading(true);
    const body = Plant.filter((item) => id === item.plantId);
    const bodyToSend = {
      plantId: body[0].plantId,
      plantCode: body[0].plantCode,
      plantTimeZone: body[0].plantTimeZone,
      tenantId: tenantId,
    };
    const res: any = await dispatch(deletePlantReq(bodyToSend));
    if (!res) return;
    callback(res);
    getPlant();
    setloading(false);
  };

  return {
    Subsidiary,
    Contacts,
    Company,
    upsert,
    portfolio,
    Plant,
    deletePlant,
    loading,
  };
};
