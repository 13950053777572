//

import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { CustomTableHead } from "./TableHead.component";
import { CustomTableRow } from "./TableRow.component";
import { useSharedFormState } from "../../utils/sharedStates";
import { Cell } from "./interfaces";

interface PaginationProps {
  options: Array<number>;
  totalRecord?: number;
  onChange: (page: number, rowsPerPage: number) => void;
}

interface TableProps {
  id?: string;
  module?: string;
  tableType?: string;
  headerCells: any;
  data: Array<any>;
  getCustomRow?: (row: any) => React.ReactNode;
  getCollapsedContent?: (data: any) => React.ReactNode;
  isOpen?: boolean;
  paginationProps?: PaginationProps;
  onDeleteClick?: (id: any) => void;
  onViewMoreClick?: (id: any) => void;
  maxHeight?: number | string;
  minWidth?: number | string;
  onUpdateField?: (id: any, key: string, value: string) => void;
  isDoubleClickActive?: boolean;
  isActionAllow?: boolean;
  handleCheckboxClick?: (data: any, roleInfo: any) => void;
  height?: string | number;
  paddingLeft?: string | number;
  width?: string | number;
  headerBgColor?: string;
  headerColor?: string;
  headerFontSize?: string;
  disableCheckList?: boolean;
  sortable?: boolean;
  customSort?: (
    a: any,
    b: any,
    orderBy: string,
    order: "asc" | "desc"
  ) => number;
}

const useStyles = makeStyles({
  disableTable: {
    pointerEvents: "none",
  },
});

export const CustomTable: React.FC<TableProps> = (props) => {
  const {
    id,
    sortable,
    handleCheckboxClick,
    module,
    tableType,
    maxHeight = null,
    headerCells,
    data,
    getCustomRow,
    getCollapsedContent,
    isOpen,
    paginationProps,
    onDeleteClick,
    onViewMoreClick,
    onUpdateField,
    minWidth,
    isDoubleClickActive,
    isActionAllow,
    height = null,
    paddingLeft,
    width,
    disableCheckList,
    headerBgColor,
    headerColor,
    customSort,
    headerFontSize,
  } = props;

  const classes = useStyles();
  const { options = [], onChange } = paginationProps || {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(options[0]);
  const { timeLabel, userTimeZone } = useSharedFormState();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string | null>(headerCells[0].id);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChange(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onChange(0, newRowsPerPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy) {
      return customSort ? customSort(a, b, orderBy, order) : 0;
    }
    return 0;
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={
            id === "myAssets"
              ? {
                  height: `${window?.innerHeight - 300}px`,
                  position: "relative",
                }
              : { maxHeight, height }
          }
        >
          <Table
            className={clsx({ [classes.disableTable]: disableCheckList })}
            stickyHeader={id === "myAssets" ? true : !!maxHeight}
            sx={{
              minWidth: minWidth || 750,
              "& .MuiTableRow-root:hover": {
                backgroundColor: "rgba(0,0,0,.04)",
              },
              "& .MuiTableCell-root": {
                fontFamily: ["Inter"].join(","),
                lineHeight: "1.2rem",
                // fontSize: "16px",
                fontSize: headerFontSize || "16px",
              },
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <CustomTableHead
              cells={headerCells}
              collapseCells={Boolean(getCollapsedContent)}
              headerBgColor={headerBgColor}
              headerColor={headerColor}
              sortable={sortable}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {Array.isArray(sortedData) ? (
                sortedData?.map((row, idx) =>
                  getCustomRow ? (
                    getCustomRow(row)
                  ) : (
                    <CustomTableRow
                      userTimeZone={userTimeZone}
                      timeLabel={timeLabel}
                      handleCheckboxClick={(resp, roleInfo) =>
                        handleCheckboxClick(resp, roleInfo)
                      }
                      isActionAllow={isActionAllow}
                      module={module}
                      isDoubleClickActive={isDoubleClickActive}
                      key={row?.id}
                      tableType={tableType}
                      data={row == null ? "No data available" : row}
                      columns={headerCells}
                      isOpen={isOpen}
                      hiddenContent={getCollapsedContent?.(row)}
                      onDeleteClick={(id) => onDeleteClick(id)}
                      onViewMoreClick={onViewMoreClick}
                      onUpdateField={(id, key, value) =>
                        onUpdateField(id, key, value)
                      }
                    />
                  )
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!paginationProps ? null : (
          <TablePagination
            rowsPerPageOptions={options}
            component="div"
            count={paginationProps.totalRecord | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};
