import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    textColor: {
        "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused ": {
            color: "white !important",
        }
    },
    // .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused 
}));
