import React from 'react';

interface SvgProps {
  height?: number | string;
  width?: number | string;
}

export const CustomDeleteSVG = (props: SvgProps) => {
  const { height, width } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 7.25V11.375M6 7.25L6 11.375M1.5 4.25H13.5M12 4.25V12.35C12 13.1901 12.0002 13.6102 11.8367 13.931C11.6929 14.2133 11.4631 14.4427 11.1808 14.5865C10.86 14.75 10.4402 14.75 9.60015 14.75H5.40015C4.56007 14.75 4.13972 14.75 3.81885 14.5865C3.5366 14.4427 3.3073 14.2133 3.16349 13.931C3 13.6102 3 13.1901 3 12.35V4.25H12ZM10.5 4.25H4.5C4.5 3.55109 4.5 2.45162 4.61418 2.17596C4.76642 1.80842 5.05824 1.51642 5.42578 1.36418C5.70144 1.25 6.05109 1.25 6.75 1.25H8.25C8.94891 1.25 9.29837 1.25 9.57403 1.36418C9.94157 1.51642 10.2335 1.80842 10.3857 2.17596C10.4999 2.45162 10.5 3.55109 10.5 4.25Z" stroke="#DE504F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

