import React from "react";
import { useNavigate } from "react-router-dom";
import { UseModuleTenant } from './useModuleTenant';
import { Card } from "@material-ui/core";
import { MenuWithBtn, IMenuItem } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import { useStyles } from "./styles";
import { moduleTenantViewHeaderCell, moduleTenantViewTableType } from './components/interfaces'

export const ModuleTenant: React.FC = () => {

  const classes = useStyles();

  const { tenants } = UseModuleTenant();

  const navigate = useNavigate();

  const onNewUser = () => {
    navigate(`/onboarding/moduletenant/new`);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>Module tenant</div>

      <Card style={{ padding: "20px" }}>
        <MenuWithBtn
          menuItems={[]}
          active={undefined}
          title={"Module tenant"}
          btnTitle={"New module tenant"}
          onBtnClick={() => onNewUser()}
          onSelectItem={(item: IMenuItem): void => {
          }}
        />
        <br />
        <CustomTable
          tableType={moduleTenantViewTableType}
          headerCells={moduleTenantViewHeaderCell}
          data={null}
          onDeleteClick={(id) => { }}
        />
      </Card>

    </div>
  );
};

