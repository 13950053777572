import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  tenantId: string;
  teamId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  team: any;
  teamMember: any;
  deleteTeamMember: (teamMemberId: any, callback: any) => void;
  deleteTeamPlant: (teamMemberId: any, callback: any) => void;
  teamPlant: any;
}

export const UseTeamSave = ({ tenantId, teamId }: Props): Data => {
  const [team, setTeam] = useState(null);
  const [teamMember, setTeamMember] = useState(null);
  const [teamPlant, setTeamPlant] = useState(null);

  const fetchAll = async () => {
    await getTeam();
    await getTeamMember();
    await getTeamPlants();
  };

  useEffect(() => {
    if (teamId === "new") return;
    fetchAll();
  }, []);

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType;
    if (id && id !== "new") {
      // update
      const newParams = {
        ...params,
        teamId: id,
      }
      requestType = "PUT";
      await fetchAsync(`onboardingV2/v1/Teams/UpdateTeam`, requestType, newParams)
        .then((data: any) => {
          callback(data);
        })
        .catch((e) => {
          console.error(e);
          callback(e);
        });
    }
    if (id === "new") {
      // add
      requestType = "POST";
      await fetchAsync(`onboardingV2/v1/Teams/AddTeam`, requestType, params)
        .then((data: any) => {
          callback(data);
        })
        .catch((e) => {
          console.error(e);
          callback(e);
        });
    }

    if (id && id !== "new") {
      const newParams = {
        ...params,
        portfolioId: id,
      }
      requestType = "PUT";
      await fetchAsync(`onboardingV2/v1/Portfolio/UpdatePortfolio`, requestType, newParams)
        .then((data: any) => {
          callback(data);
        })
        .catch((e) => {
          console.error(e);
          callback(e);
        });
    }
  };

  const getTeam = async () => {
    await fetchAsync(`onboardingV2/v1/Teams/GetTeamsByTenantId?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let currentTeam = data.find((x) => x.teamId === teamId);
          setTeam(currentTeam);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // UPDATED TEAM MEMEBER API
  const getTeamMember = async () => {
    await fetchAsync(`onboardingV2/v1/TeamMembers/GetTeamMembersByTeamId?teamId=${teamId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newTeamMember = data?.map((item) => ({
            ...item,
            id: item.teamMemberId,
          }));
          setTeamMember(newTeamMember);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getTeamPlants = async () => {
    await fetchAsync(`onboardingV2/v1/PlantsToTeam/GetPlantsAssignedToTeam?teamId=${teamId}`, "GET")
      .then((data: any) => {

        let newTeamPlant = data?.map((item) => ({
          ...item,
          id: item.teamPlantId,
        }));
        setTeamPlant(newTeamPlant || []);

      })
      .catch((e) => {
        console.error(e);
      });
  };

  const deleteTeamMember = async (teamMemberId: any, callback: any) => {
    const body = {
      teamMemberId: `${teamMemberId}`,
      teamId: `${teamId}`,
    };
    await fetchAsync(`onboardingV2/v1/TeamMembers/RemoveUserFromTeam`, "DELETE", body)
      .then((data: any) => {
        getTeamMember();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };


  const deleteTeamPlant = async (teamPlantId: any, callback: any) => {
    const plantId = teamPlant.find(item => item.teamPlantId
      === teamPlantId)?.plantId;
    const body = {
      teamId: `${teamId}`,
      plantId: `${plantId}`,
      teamPlantId: `${teamPlantId}`,
    };

    await fetchAsync(`onboardingV2/v1/PlantsToTeam/RemovePlantFromTeam`, "DELETE", body)
      .then((data: any) => {
        getTeamPlants();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  return {
    upsert,
    team,
    teamMember,
    deleteTeamMember,
    teamPlant,
    deleteTeamPlant
  };
};
