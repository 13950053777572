import React, { useState } from 'react'
import { Grid, Box } from '@material-ui/core';
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Typography, Datepicker, Dropdown } from '../../../shared/components';
import { GRAPH_COLORS } from "../../../shared/utils/graphColors";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Popup, { Sides } from "../../components/Popup";
import { useStyles } from "./styles";
import { formSchemaFormatType } from "./metaData";
import { getDateForDownloadGraph } from '../../../shared/utils/utils';
import { GetWaterfallPRLossReport, GetWaterfallEnergyLossReport } from '../../../features/Sidem/portfolios/plants/plantsActions';
import moment from 'moment';
import { useDispatch } from 'react-redux';


interface DropdownProps {
    key: string;
    options: Array<any>;
    label: string;
    value: any;
    onChange?: (val: any) => void;
}

interface MenuProps {
    chartType?: string;
    lossWaterFallType?: string;
    plantId?: string;
}
enum Fields {
    StartDate = "FromDate",
    EndDate = "ToDate",
    Granularity = "Granularity",
    Format = "Format"
}


export const DownloadMenu: React.FC<MenuProps> = (props: MenuProps) => {
    const { chartType, lossWaterFallType, plantId } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [formatType, setFormatType] = useState("");
    const [granularity, setGranularity] = useState("");
    const dispatch = useDispatch();


    const anchorOrigin: any = { vertical: Sides.top, horizontal: Sides.right };
    const transformOrigin: any = { vertical: Sides.top, horizontal: Sides.right };
    const classes = useStyles();

    const validationSchema = yup.object({
        [Fields.Granularity]: yup.string().required("Granularity is required").nullable(true),
        [Fields.Format]: yup.string().required("Format is required").nullable(true),
        [Fields.StartDate]: yup.string().required("Date is required"),
        [Fields.EndDate]: yup.string().required("Date is required"),
    });

    const downloadLossWaterFallTypePR = async (FromDate, ToDate, Format, plantId) => {
        const data: any = await dispatch(
            GetWaterfallPRLossReport(
                getDateForDownloadGraph(FromDate),
                getDateForDownloadGraph(ToDate),
                plantId,
                Format
            )
        );
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = `WaterfallPRLossReport_${moment(FromDate).format(
            "DD-MM-YYYY"
        )}_${moment(ToDate).format("DD-MM-YYYY")}.${Format}`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    const downloadLossWaterFallTypeGen = async (FromDate, ToDate, Format, plantId) => {
        const data: any = await dispatch(
            GetWaterfallEnergyLossReport(
                getDateForDownloadGraph(FromDate),
                getDateForDownloadGraph(ToDate),
                plantId,
                Format
            )
        );
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = `WaterfallEnergyLossReport_${moment(FromDate).format(
            "DD-MM-YYYY"
        )}_${moment(ToDate).format("DD-MM-YYYY")}.${Format}`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    const formik = useFormik({
        initialValues: {
            [Fields.Granularity]: granularity,
            [Fields.Format]: formatType,
            [Fields.StartDate]: "",
            [Fields.EndDate]: "",
        },
        validationSchema,
        onSubmit: (values) => {
            switch (chartType) {
                case "LossWaterFallPlant": {
                    if (lossWaterFallType === "%") {
                        downloadLossWaterFallTypePR(values.FromDate, values.ToDate, values.Format, plantId)
                    }
                    if (lossWaterFallType === "kWh") {
                        downloadLossWaterFallTypeGen(values.FromDate, values.ToDate, values.Format, plantId)
                    }
                    break;
                }
                //TODO
                // case "TheoreticalGenerationGraph":
                //     {
                //         console.log(values, `values TheoreticalGenerationGraph`);
                //         break;
                //     }
                default:
                    break;
            }
            closeMenu()
        },
    });

    const closeMenu = () => {
        setAnchorEl(null)
        formik.setFieldValue(`${[Fields.StartDate]}`, "")
        formik.setFieldTouched(`${[Fields.StartDate]}`, false)

        formik.setFieldTouched(`${[Fields.EndDate]}`, false)
        formik.setFieldValue(`${[Fields.EndDate]}`, "")

        formik.setFieldTouched(`${[Fields.Granularity]}`, false)
        formik.setFieldValue(`${[Fields.Granularity]}`, "")
        setGranularity("")

        formik.setFieldTouched(`${[Fields.Format]}`, false)
        formik.setFieldValue(`${[Fields.Format]}`, "")
        setFormatType("")

    };
    const openMenu = (event?: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event?.currentTarget);
    }

    const renderDropdown = ({ key, label, options, value }: DropdownProps) => (
        <Typography label={label}>
            <Dropdown
                value={value}
                options={options}
                onChangeValue={(val: any) => {
                    if (key === "Format")
                        setFormatType(val)
                    if (key === "Granularity")
                        setGranularity(val)
                    formik.setFieldValue(key, val)
                }}
                showError={formik?.touched[key] && Boolean(formik.errors[key])}
                errorMessage={formik.errors[key]}
            />
        </Typography>
    );

    const renderDateSelector = (
        key: string,
        label: string,
        minDateKey
    ) => (
        <Typography label={label}>
            <Datepicker
                value={formik.values[key]}
                onChange={(val) => formik.setFieldValue(key, val)}
                showError={formik?.touched[key] && Boolean(formik.errors[key])}
                errorMessage={formik.errors[key]}
            />
        </Typography>
    );

    return (
        <div className={classes.menuWrapper}>
            <Button
                className={classes.button}
                title="Download"
                startIcon={<FileDownloadOutlinedIcon />}
                color={GRAPH_COLORS.appBlue}
                onClick={openMenu}
            />
            <Popup
                anchorEl={anchorEl}
                onClose={closeMenu}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <form>
                    <Box sx={{ width: 370 }} className={classes.containerPadding}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <div className={classes.title}>Download Data </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <div className={classes.closeButton} onClick={closeMenu}>
                                        <CloseIcon />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={classes.FromDate}>
                                    {renderDateSelector(
                                        Fields.StartDate,
                                        "From*",
                                        Fields.StartDate
                                    )}
                                </div>

                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.ToDate}>
                                    {renderDateSelector(
                                        Fields.EndDate,
                                        "To*",
                                        Fields.EndDate
                                    )}
                                </div>

                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.Granularity}>
                                    {renderDropdown({
                                        key: "Granularity",
                                        label: "Granularity",
                                        value: granularity,
                                        options: formSchemaFormatType["GranularityOptions"],
                                    })}
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.Format}>
                                    {renderDropdown({
                                        key: "Format",
                                        label: "Format",
                                        value: formatType,
                                        options: formSchemaFormatType["FormatOptions"],
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                className={classes.cancelButton}
                                title="Cancel"
                                color={GRAPH_COLORS.black}
                                noBorder={true}
                                outlined={true}
                                onClick={closeMenu}
                            />
                            <Button
                                className={classes.button}
                                title="Download"
                                startIcon={<FileDownloadOutlinedIcon />}
                                color={GRAPH_COLORS.appBlue}
                                onClick={() => {
                                    formik.submitForm()
                                }}
                            />
                        </Grid>
                    </Box>
                </form>
            </Popup>
        </div>
    );
};
