import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GLOBAL_COLORS,
  convertTime,
  convertUnitForGraphs,
  getHoursAndMinutesFromISO,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import {
  DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryChargeDischargeHistoricalQuery,
  useGetBatteryChargeDischargePowerQuery,
  useGetBatterySocHistoricalQuery,
} from "../../BatteryMonitoring.api";
import {
  BatteryChargeDischargeHistoricalTransformedResponse,
  BatteryChargeDischargePowerResponse,
  BatterySocHistoricalResponse,
} from "../../BatteryMonitoring.api.types";
import { chartOptions } from "../../../../../shared/utils/chartOptions";
import { SCREEN } from "./utils";
import { ChargeDischargeChartWidgetData } from "./ChargeDischargeChartWidget.types";
import {
  CHART_COLORS,
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTION_VALUE,
  fillGapsBetweenTimestamps,
  findClosestItem,
  findClosestItemInMap,
  getStep,
} from "../../utils";
import { YAxis } from "../../../../../analytics/components/interfaces";
import { useUrlParams } from "../../../../../shared/utils/hooks";
import { getXAxisCategories } from "../BatteryControlChartWidget/utils";

export const useChargeDischargeChartWidget =
  (): ChargeDischargeChartWidgetData => {
    const [powerChartLegendVisibility, setPowerChartLegendVisibility] =
      useState({
        charge: true,
        discharge: true,
        soc: true,
      });

    const { plantId, startDate, endDate } = useUrlParams({
      plantId: "",
      startDate: "",
      endDate: "",
    });

    const fromDate = useMemo(() => {
      return convertTime(startDate, "YYYY.MM.DD");
    }, [startDate]);

    const toDate = useMemo(() => {
      return convertTime(endDate, "YYYY.MM.DD");
    }, [endDate]);

    const { liveButton, globalDate } = useAppSelector(
      monitoringDashboardSelector
    );

    const [dropdownValue, setDropdownValue] = useState<DROPDOWN_OPTION_VALUE>(
      DROPDOWN_OPTION_VALUE.RAW_DATA
    );

    const [buttonValue, setButtonValue] = useState<string>(SCREEN.ENERGY);
    const [options, setOptions] = useState({
      title: { text: "" },
      lang: {
        noData: "",
      },
    });

    const {
      data: dataPower,
      isLoading: isLoadingPower,
      isFetching: isFetchingPower,
      isError: isErrorPower,
    } = useGetBatteryChargeDischargePowerQuery(
      {
        fromDate,
        toDate,
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        granularity: dropdownValue.toString(),
      },
      {
        skip: isAnyItemFalsy([
          fromDate,
          toDate,
          plantId,
          DEFAULT_EQUIPMENT_NAME,
          dropdownValue.toString(),
          buttonValue === SCREEN.POWER,
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const {
      data: dataSocHistorical,
      isLoading: isLoadingSocHistorical,
      isFetching: isFetchingSocHistorical,
      isError: isErrorSocHistorical,
    } = useGetBatterySocHistoricalQuery(
      {
        fromDate,
        toDate,
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        granularity: dropdownValue.toString(),
      },
      {
        skip: isAnyItemFalsy([
          fromDate,
          toDate,
          plantId,
          DEFAULT_EQUIPMENT_NAME,
          dropdownValue.toString(),
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const {
      data: dataEnergy,
      isLoading: isLoadingEnergy,
      isFetching: isFetchingEnergy,
      isError: isErrorEnergy,
    } = useGetBatteryChargeDischargeHistoricalQuery(
      {
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
        fromDate,
        toDate,
        granularity: dropdownValue.toString(),
      },
      {
        skip: isAnyItemFalsy([
          fromDate,
          toDate,
          plantId,
          DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME,
          dropdownValue.toString(),
          buttonValue === SCREEN.ENERGY,
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const isLoading = useMemo(() => {
      if (buttonValue === SCREEN.POWER) {
        return isLoadingPower || isLoadingSocHistorical;
      } else {
        return isLoadingEnergy;
      }
    }, [buttonValue, isLoadingPower, isLoadingSocHistorical, isLoadingEnergy]);

    const isFetching = useMemo(() => {
      if (buttonValue === SCREEN.POWER) {
        return isFetchingPower || isFetchingSocHistorical;
      } else {
        return isFetchingEnergy;
      }
    }, [
      buttonValue,
      isFetchingPower,
      isFetchingSocHistorical,
      isFetchingEnergy,
    ]);

    const labelsPositioner = useCallback(() => {
      const result = fillGapsBetweenTimestamps(
        startDate,
        endDate,
        Number(dropdownValue)
      ).map((date) => Date.parse(String(date)));
      return result;
    }, [startDate, endDate, dropdownValue]);

    const getPowerChart = useCallback(
      (
        data: BatteryChargeDischargePowerResponse,
        dataSoc: BatterySocHistoricalResponse
      ) => {
        const combinedData = (data?.items || [])
          .map((item) => ({
            x: Date.parse(String(item.date)),
            y: item.value,
            color:
              item.value < 0
                ? CHART_COLORS.CHARGED_ENERGY
                : CHART_COLORS.DISCHARGED_ENERGY,
            name: item.value < 0 ? "Charge" : "Discharge",
            unit: item.unit,
          }))
          .filter(
            (item) =>
              (item.name === "Charge" && powerChartLegendVisibility.charge) ||
              (item.name === "Discharge" &&
                powerChartLegendVisibility.discharge)
          );

        const socData = powerChartLegendVisibility.soc
          ? (dataSoc?.items || []).map((item) => ({
              x: Date.parse(String(item.date)),
              y: item.value,
              color: CHART_COLORS.SOC_LINE,
            }))
          : [];

        const xAxis = {
          endOnTick: false,
          showLastLabel: true,
          startOnTick: false,
          type: "datetime",
          tickLength: 0,
          crosshair: {
            width: 1,
            color: GLOBAL_COLORS.DATA_VIZ_GRAY_30,
          },
          labels: {
            formatter() {
              return getXAxisCategories(
                this.value,
                data?.items || [],
                Number(dropdownValue)
              );
            },
          },
        };

        const yAxis = [
          {
            visible: true,
            opposite: false,
            allowDecimals: true,
            type: "linear",
            gridLineWidth: 0,
            title: { text: "kW" },
            threshold: 0,
            startOnTick: false,
            endOnTick: false,
          },
          {
            labels: { format: "{text}%" },
            visible: true,
            opposite: true,
            allowDecimals: false,
            type: "linear",
            gridLineWidth: 0,
            title: { text: "" },
            min: 0,
            max: 100,
            height: "50%",
            startOnTick: false,
            endOnTick: false,
          },
        ];

        const socDataMap = new Map(socData.map((item) => [item.x, item]));
        const combinedDataMap = new Map(
          combinedData.map((item) => [item.x, item])
        );

        const tooltipFormatter = (s, point) => {
          const hour = new Date(point.x).getHours();
          const minute = new Date(point.x).getMinutes();

          const hourString = hour < 10 ? `0${hour}` : hour;
          const minuteString = minute < 10 ? `0${minute}` : minute;

          const closestSoc = findClosestItemInMap(socDataMap, point.x);
          const closestChargeDischarge = findClosestItemInMap(
            combinedDataMap,
            point.x
          );
          // TODO: kaz make sure that closestChargeDischarge exists
          const pointColor = `
              <tspan style="font-size:20px; color: ${closestChargeDischarge?.color}; fill: ${closestChargeDischarge?.color};">●</tspan>&nbsp`;

          const chargeDischargeValue =
            pointColor +
            closestChargeDischarge?.name +
            ":" +
            "</div> " +
            '<div style="display:grid; width:100%;"><div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">&nbsp;&nbsp;&nbsp;' +
            closestChargeDischarge?.y +
            " " +
            closestChargeDischarge?.unit +
            "</div></div><br/></div> ";

          const closestSocValue =
            '<div style="display:flex;"> <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; paddingBottom:8px;">' +
            `
              <tspan style="font-size:20px; color: ${closestSoc?.color}; fill: ${closestSoc?.color};">●</tspan>&nbsp` +
            "SoC:" +
            "</div> " +
            '<div style="display:grid; width:100%;"><div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">&nbsp;&nbsp;&nbsp;' +
            closestSoc?.y +
            " " +
            "%" +
            "</div></div><br/></div> ";

          return (
            `<span style="font-size:15px; font-weight:bold"> ${hourString}:${minuteString}<hr></span>` +
            '<div style="display:flex;"> <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; paddingBottom:8px;">' +
            `${closestChargeDischarge ? chargeDischargeValue : ""}` +
            `${closestSoc ? closestSocValue : ""}`
          );
        };

        const series = [
          {
            name: "Charge",
            color: CHART_COLORS.CHARGED_ENERGY,
            showInLegend: true,
            type: "area",
            data: [],
          },
          {
            name: "Discharge",
            color: CHART_COLORS.DISCHARGED_ENERGY,
            showInLegend: true,
            type: "area",
            data: [],
          },
          {
            name: "combined",
            data: combinedData,
            tooltip: {
              valueSuffix: "kW",
              shared: true,
            },
            yAxis: 0,
            type: "area",
            showInLegend: false,
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "rgba(151,206,253, 0.23)"],
                [0.5, "rgba(151,206,253, 0.1)"],
                [1, "#ffffff"],
              ],
            },
            negativeColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ffffff"],
                [0.5, "rgba(52,161,20,0.1)"],
                [1, "rgba(52,161,20,0.23)"],
              ],
            },
          },
          {
            name: "SoC",
            data: socData,
            enableMouseTracking: true,
            tooltip: {
              valueSuffix: "%",
              shared: true,
            },
            yAxis: 1,
            type: "line",
            color: CHART_COLORS.SOC_LINE,
          },
        ];

        const plotOptions = {
          series: {
            turboThreshold: 0,
            connectNulls: true,
            events: {
              legendItemClick() {
                const isVisible = this.visible;
                setPowerChartLegendVisibility((prev) => ({
                  ...prev,
                  [this.name.toLowerCase()]: !isVisible,
                }));
              },
            },
          },
          line: {
            marker: { enabled: false },
          },
          area: {
            marker: { enabled: false },
          },
        };

        return {
          xAxis,
          yAxis,
          tooltipFormatter,
          series,
          plotOptions,
        };
      },
      [
        dataPower,
        dataSocHistorical,
        labelsPositioner,
        dropdownValue,
        startDate,
        endDate,
        plantId,
        fromDate,
        toDate,
        powerChartLegendVisibility,
      ]
    );

    const getEnergyChart = useCallback(
      (data: BatteryChargeDischargeHistoricalTransformedResponse) => {
        const chargeData =
          data?.charge?.map((item) => {
            const date = Date.parse(String(item.date));
            return {
              x: date,
              y: Math.abs(item.value) * -1,
              color: CHART_COLORS.CHARGED_ENERGY,
            };
          }) || [];

        const dischargeData =
          data?.discharge?.map((item) => {
            const date = Date.parse(String(item.date));
            return {
              x: date,
              y: Math.abs(item.value),
              color: CHART_COLORS.DISCHARGED_ENERGY,
            };
          }) || [];

        const xAxis = {
          endOnTick: false,
          showLastLabel: true,
          startOnTick: false,
          tickLength: 0,
          type: "datetime",
          crosshair: {
            width: 1,
            color: GLOBAL_COLORS.DATA_VIZ_GRAY_30,
          },
          labels: {
            formatter: function () {
              const result = getXAxisCategories(
                this.value,
                chargeData || [],
                Number(dropdownValue)
              );

              return result;
            },
          },
        };

        const yAxis = [
          {
            visible: true,
            opposite: false,
            allowDecimals: true,
            type: "linear",
            gridLineWidth: 0,
            title: {
              text: "kWh",
            },
            threshold: 0,
            startOnTick: false,
            endOnTick: false,
          },
        ];

        const series = [
          {
            name: "Charged energy",
            data: chargeData,
            tooltip: {
              valueSuffix: data.chargeUnit || "",
              shared: true,
            },
            yAxis: 0,
            type: "column",
            events: {},
            color: CHART_COLORS.CHARGED_ENERGY,
            negativeColor: CHART_COLORS.CHARGED_ENERGY,
            pointPlacement: 0,
          },
          {
            name: "Discharged energy",
            data: dischargeData,
            tooltip: {
              valueSuffix: data.dischargeUnit || "",
              shared: true,
            },
            yAxis: 0,
            type: "column",
            events: {},
            color: CHART_COLORS.DISCHARGED_ENERGY,
            negativeColor: CHART_COLORS.DISCHARGED_ENERGY,
            pointPlacement: 0,
          },
        ];

        const plotOptions = {
          series: {
            connectNulls: true,
          },
          line: {
            marker: {
              enabled: false,
            },
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        };

        const tooltipFormatter = function (s, point) {
          const series = point.point.series.xAxis.series;
          const hour = new Date(point.x).getHours();
          const minute = new Date(point.x).getMinutes();

          const hourString = hour < 10 ? `0${hour}` : hour;
          const minuteString = minute < 10 ? `0${minute}` : minute;

          const mappedValues = series
            .map((serie) => {
              const value = serie.data.find((item) => item.x === point.x)?.y;
              if (!value && value !== 0) {
                return "";
              }
              const name = serie.name;
              const suffix = serie.userOptions.tooltip.valueSuffix;

              const pointColor = `
  <tspan style="font-size:20px; color: ${serie.color}; fill: ${serie.color};">●</tspan>&nbsp`;

              return (
                '<div style="display:flex;"> <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; paddingBottom:8px;">' +
                pointColor +
                name +
                "</div> " +
                '<div style="display:grid; width:100%;"><div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">&nbsp;&nbsp;&nbsp;' +
                value +
                " " +
                suffix +
                "</div></div><br/></div> "
              );
            })
            .join("");

          return (
            `<span style="font-size:15px; font-weight:bold"> ${hourString}:${minuteString}<hr></span>` +
            mappedValues
          );
        };

        return {
          xAxis,
          yAxis,
          tooltipFormatter,
          series,
          plotOptions,
        };
      },
      [
        labelsPositioner,
        dropdownValue,
        startDate,
        endDate,
        plantId,
        fromDate,
        toDate,
      ]
    );

    useEffect(() => {
      const isPowerTabSelected = buttonValue === SCREEN.POWER;

      if (
        (isPowerTabSelected && !dataPower) ||
        (!isPowerTabSelected && !dataEnergy)
      ) {
        return;
      }

      const loading = isPowerTabSelected ? isLoadingPower : isLoadingEnergy;
      const title = "Charge, Discharge & SOC";
      const colors = [
        CHART_COLORS.PRIMARY_DARK,
        CHART_COLORS.PRIMARY_LIGHT,
        CHART_COLORS.SECONDARY,
      ];

      const { xAxis, yAxis, tooltipFormatter, series, plotOptions } =
        isPowerTabSelected
          ? getPowerChart(dataPower, dataSocHistorical)
          : getEnergyChart(dataEnergy);

      const options = {
        ...chartOptions({
          title: title,
          colors: colors,
          loading: loading,
          xAxis: xAxis,
          yAxis: yAxis,
          tooltipFormatter: tooltipFormatter,
          plotOptions: plotOptions,
          data: series,
        }),
      };

      let overloadedYAxis: YAxis[] = yAxis ? [...yAxis] : yAxis;

      if (overloadedYAxis[0]) {
        const updatedY0 = {
          ...overloadedYAxis[0],
          gridLineColor: GLOBAL_COLORS.BORDER_LIGHT_SECONDARY,
          gridLineWidth: isPowerTabSelected ? 0 : 1,
          labels: {
            style: { fontFamily: "Inter" },
          },
        };

        overloadedYAxis[0] = updatedY0;
      }

      const newOptions = {
        ...options,
        chart: {
          borderRadius: 8,
          height: "400px",
          zooming: { type: "x" },
          events: {
            load() {
              if (isLoading || isFetching) {
                this.showLoading();
              } else {
                this.hideLoading();
              }
            },
            redraw() {
              if (isLoading || isFetching) {
                this.showLoading();
              } else {
                this.hideLoading();
              }
            },
          },
        },
        loading: {
          showDuration: 0,
          hideDuration: 1000,
          labelStyle: {
            fontSize: "16px",
          },
        },
        legend: {
          itemStyle: {
            fontWeight: "regular",
            fontFamily: "Inter",
          },
        },
        xAxis: xAxis,
        yAxis: overloadedYAxis,
        styledMode: true,
        title: {
          ...options.title,
          x: -4,
          y: 24 + 12,
          margin: 52,
          fontFamily: "Inter",
        },
      };

      setOptions(newOptions);
    }, [
      dataSocHistorical,
      dataPower,
      dataEnergy,
      dropdownValue,
      buttonValue,
      isLoadingPower,
      isLoadingEnergy,
      getPowerChart,
      getEnergyChart,
      isLoading,
      isFetching,
    ]);

    const diffDateDays = useMemo(() => {
      return Math.floor(
        (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
    }, [startDate, endDate]);

    useEffect(() => {
      setButtonValue(liveButton ? SCREEN.POWER : SCREEN.ENERGY);
    }, [liveButton]);

    useEffect(() => {
      setButtonValue(diffDateDays < 1 ? SCREEN.POWER : SCREEN.ENERGY);
    }, [diffDateDays]);

    const onDropdownChange = useCallback((value: DROPDOWN_OPTION_VALUE) => {
      setDropdownValue(value);
    }, []);

    const dropdownOptions = useMemo(() => {
      const isPower = buttonValue === SCREEN.POWER;

      if (liveButton) {
        setDropdownValue(
          isPower
            ? DROPDOWN_OPTION_VALUE.ONE_MIN
            : DROPDOWN_OPTION_VALUE.FIFTEEN_MIN
        );
        return isPower
          ? DROPDOWN_OPTIONS.slice(0, 2)
          : DROPDOWN_OPTIONS.slice(1, 6);
      } else {
        if (diffDateDays < 1) {
          //  one day without live mode
          setDropdownValue(
            isPower
              ? DROPDOWN_OPTION_VALUE.ONE_MIN
              : DROPDOWN_OPTION_VALUE.FIFTEEN_MIN
          );
          return isPower
            ? DROPDOWN_OPTIONS.slice(0, 2)
            : DROPDOWN_OPTIONS.slice(1, 7);
        } else if (diffDateDays >= 1 && diffDateDays < 6) {
          // 2 days or more, but less than a week
          setDropdownValue(
            isPower
              ? DROPDOWN_OPTION_VALUE.ONE_MIN
              : DROPDOWN_OPTION_VALUE.FIFTEEN_MIN
          );
          return isPower
            ? DROPDOWN_OPTIONS.slice(1, 2)
            : DROPDOWN_OPTIONS.slice(3, 7);
        } else if (diffDateDays >= 6) {
          // more than week
          setDropdownValue(DROPDOWN_OPTION_VALUE.ONE_DAY);
          return DROPDOWN_OPTIONS.slice(6, 10);
        }
        return DROPDOWN_OPTIONS;
      }
    }, [liveButton, buttonValue, diffDateDays]);

    const buttonGroups = useMemo(() => {
      return [
        {
          buttons: [
            {
              label: SCREEN.POWER,
              onClick: () => {
                setButtonValue(SCREEN.POWER);
              },
              active: buttonValue === SCREEN.POWER,
              disabled: diffDateDays >= 6,
            },
            {
              label: SCREEN.ENERGY,
              onClick: () => {
                setButtonValue(SCREEN.ENERGY);
              },
              active: buttonValue === SCREEN.ENERGY,
            },
          ],
        },
      ];
    }, [buttonValue, diffDateDays]);

    return {
      plantId,
      startDate,
      endDate,
      liveButton,
      globalDate,
      dropdownOptions: {
        value: dropdownValue,
        items: dropdownOptions,
        onChange: onDropdownChange,
      },
      buttonGroups,
      chartOptions: options,
      dataPower,
      dataEnergy,
    };
  };
