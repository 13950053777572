import React, { useEffect, useState } from "react";
import { Group, Line } from "react-konva";
import { Html } from "react-konva-utils";

import { getAlertColor, LayoutAlarmNotification } from "../AlarmNotification";
import { PanelItem } from "../../../features/Sidem/shared/sidemSharedApi.types";

interface AlertItem {
  alarmCategoryCode: number;
  alarmCategoryName: string;
  alarmLevel: number;
  createdOn: string;
  description: string;
  displayName: string;
  isActive: boolean;
  modifiedOn: string;
  nodeId: string;
  plantId: string; //number;
}

interface Panel {
  key: string;
  centerCoords: Array<number>;
  equipCoords: Array<number>;
  errors: Array<AlertItem>;
}

interface Props {
  imageX: number;
  imageY: number;
  panel: PanelItem;
  timeZone?: string;
}

export const LayoutPanel: React.FC<Props> = ({ imageX, imageY, panel, timeZone }) => {
  const [showAlert, setShowAlert] = useState(false);
  const circleRadius = 7;

  const style = {
    left: imageX + panel.centerCoords[0] - circleRadius + "px",
    top: imageY + panel.centerCoords[1] - circleRadius + "px",
    width: showAlert ? "250px" : "20px",
    zIndex: showAlert ? 100 : 20,
  };
  const closeAlert = () => setShowAlert(false);

  useEffect(() => {
    window.addEventListener("click", closeAlert);

    return () => {
      window.removeEventListener("click", closeAlert);
    };
  }, []);

  const dot = {
    width: circleRadius + "px",
    height: circleRadius + "px",
    padding: circleRadius + "px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: circleRadius + "px",
    color: "white",
    cursor: "pointer",
  };

  const onDotClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAlert(true);
  };

  const color = panel?.errors?.length
    ? getAlertColor(panel?.errors[0])
    : "#007BC2";

  return (
    <Group>
      <Line
        x={imageX}
        y={imageY}
        points={panel.equipCoords}
        closed
        stroke={"black"}
        strokeWidth={2}
        fill={color}
        opacity={0.4}
        fillEnabled={true}
      />

      {!panel.errors?.length ? null : (
        <Html divProps={{ style }}>
          {!showAlert ? (
            <div
              style={{ ...dot, backgroundColor: color }}
              onClick={onDotClick}
            ></div>
          ) : (
            <LayoutAlarmNotification alarm={panel.errors[0]} timeZone={timeZone} />
          )}
        </Html>
      )}
    </Group>
  );
};
