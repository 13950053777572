import { useSearchParams } from "react-router-dom";

export const useUrlParams = <T extends { [key: string]: string }>(
  params: T
): { [K in keyof T]: string | null } => {
  const [url] = useSearchParams(params);

  return Object.keys(params).reduce((acc, key) => {
    (acc as any)[key] = url.get(key);
    return acc;
  }, {} as { [K in keyof T]: string | null });
};
