import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  loader: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    opacity: 0.2,
  },
}));
