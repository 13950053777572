import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import { Button, Typography } from "../../../../shared/components";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useNavigate } from "react-router-dom";
interface props {
  data: any;
  selectedIndex: any;
  customReportsData: any;
  selectedName?: string;
  setSelectedIndex: (index) => void;
  setSelectedName?: (name) => void;
  setTemplateId?: (id) => void;
  DeleteCustomReport: (id: any) => void;
  UpdateCustomReport: (obj: any) => void;
}

export const Template: React.FC<props> = ({
  data,
  selectedIndex,
  customReportsData,
  selectedName,
  setSelectedName,
  setSelectedIndex,
  UpdateCustomReport,
  DeleteCustomReport,
  setTemplateId,
}) => {
  useEffect(() => {
    if (selectedName?.length === 0) setSelectedName(data[selectedIndex]?.name);
  }, [selectedName]);

  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const handleListItemClick = (item, index) => {
    setIsCustomSelected(false);
    setSelectedName(item?.name);
    setSelectedIndex(index);
    setTemplateId(0);
  };

  const handleCustomListItemClick = (item, index) => {
    setIsCustomSelected(true);
    setSelectedName(item?.title);
    setTemplateId(item?.templateId);
    setSelectedIndex(index);
  };

  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Typography label="General" />
      <List component="nav" aria-label="main mailbox folders">
        {data.map((item, index) => (
          <ListItemButton
            key={index}
            selected={selectedIndex === index && !isCustomSelected}
            onClick={(event) => handleListItemClick(item, index)}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={item?.name} />
          </ListItemButton>
        ))}
      </List>
      <Typography label="Custom" />
      <List component="nav" aria-label="main mailbox folders">
        {customReportsData?.length > 0 &&
          customReportsData.map((item, index) => (
            <ListItemButton
              key={index}
              selected={selectedIndex === index && isCustomSelected}
              onClick={(event) => handleCustomListItemClick(item, index)}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <ListItemText primary={item.title} />
                <div>
                  <IconButton onClick={() => UpdateCustomReport(item)}>
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteCustomReport(item.templateId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </ListItemButton>
          ))}
      </List>
      <Button
        fullWidth
        color={"#0C1E33"}
        type="button"
        title={"Create Custom Report"}
        onClick={() => {
          navigate("/sidem/custom-reports");
        }}
        startIcon={<AddIcon />}
      />
    </Box>
  );
};
