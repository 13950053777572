import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertTime,
  getHoursAndMinutesFromISO,
  GLOBAL_COLORS,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryVoltageAndFrequencyHistoricalQuery,
} from "../../BatteryMonitoring.api";
import { BatteryVoltageAndFrequencyHistoricalResponse } from "../../BatteryMonitoring.api.types";
import { chartOptions } from "../../../../../shared/utils/chartOptions";
import { VoltageChartWidgetData } from "./VoltageChartWidget.types";
import {
  CHART_COLORS,
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTION_VALUE,
} from "../../utils";
import { useUrlParams } from "../../../../../shared/utils/hooks";
import { getXAxisCategories } from "../BatteryControlChartWidget/utils";

export const useVoltageChartWidget = (): VoltageChartWidgetData => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const { liveButton, globalDate } = useAppSelector(
    monitoringDashboardSelector
  );

  const [dropdownValue, setDropdownValue] = useState<DROPDOWN_OPTION_VALUE>(
    DROPDOWN_OPTION_VALUE.ONE_MIN
  );

  const [options, setOptions] = useState({
    title: { text: "" },
    lang: {
      noData: "",
    },
  });

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const { data, isLoading, isFetching, isError } =
    useGetBatteryVoltageAndFrequencyHistoricalQuery(
      {
        fromDate,
        toDate,
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        granularity: dropdownValue.toString(),
      },
      {
        skip: isAnyItemFalsy([
          fromDate,
          toDate,
          plantId,
          DEFAULT_EQUIPMENT_NAME,
          dropdownValue.toString(),
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

  const getChart = useCallback(
    (data: BatteryVoltageAndFrequencyHistoricalResponse) => {
      const current = [];
      const voltage_ll = [];
      const voltage_ln = [];
      const frequency = [];

      data?.items.forEach((item) => {
        const date = Date.parse(
          String(
            item?.timestamp ||
              item?.current?.date ||
              item?.frequency?.date ||
              item?.voltage_ll?.date ||
              item?.voltage_ln?.date
          )
        );

        current.push({
          x: date,
          y: item?.current?.value || null,
          color: CHART_COLORS.CURRENT,
          unit: item?.current?.unit || "",
        });
        voltage_ll.push({
          x: date,
          y: item?.voltage_ll?.value || null,
          color: CHART_COLORS.VOLTAGE_LL,
          unit: item?.voltage_ll?.unit || "",
        });
        voltage_ln.push({
          x: date,
          y: item?.voltage_ln?.value || null,
          color: CHART_COLORS.VOLTAGE_LN,
          unit: item?.voltage_ln?.unit || "",
        });
        frequency.push({
          x: date,
          y: item?.frequency?.value || null,
          color: CHART_COLORS.FREQUENCY,
          unit: item?.frequency?.unit || "",
        });
      });

      const xAxis = {
        endOnTick: true,
        showLastLabel: false,
        startOnTick: false,
        type: "datetime",
        tickLength: 0,
        crosshair: {
          width: 1,
          color: GLOBAL_COLORS.DATA_VIZ_GRAY_30,
        },
        labels: {
          formatter: function () {
            const result = getXAxisCategories(
              this.value,
              data?.items || [],
              Number(dropdownValue)
            );
            return result;
          },
        },
      };

      const yAxis = [
        // voltage - ll & ln
        {
          visible: true,
          opposite: false,
          allowDecimals: true,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: "V",
          },
          threshold: 0,
          startOnTick: false,
          endOnTick: false
        },

        // current
        {
          visible: true,
          // left: true,
          opposite: true,
          allowDecimals: true,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: "A",
          },
          threshold: 0,
          startOnTick: false,
          endOnTick: false
        },

        // frequency - right side
        {
          labels: {
            format: "{text}",
          },
          visible: true,
          opposite: true,
          allowDecimals: false,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: "Hz",
          },
          startOnTick: false,
          endOnTick: false,
          min: 48,
          max: 52
        },
      ];

      const currentUnit = current.find((item) => item.unit)?.unit || "";
      const voltage_llUnit = voltage_ll.find((item) => item.unit)?.unit || "";
      const voltage_lnUnit = voltage_ln.find((item) => item.unit)?.unit || "";
      const frequencyUnit = frequency.find((item) => item.unit)?.unit || "";

      const series = [
        {
          name: "Voltage LN",
          data: voltage_ln,
          tooltip: {
            valueSuffix: voltage_lnUnit,
          },
          yAxis: 0,
          type: "line",
          events: {},
          cursor: "pointer",
          color: CHART_COLORS.VOLTAGE_LN,
          dashStyle: "dash",
          zIndex: 2,
        },
        {
          name: "Voltage LL",
          data: voltage_ll,
          tooltip: {
            valueSuffix: voltage_llUnit,
          },
          yAxis: 0,
          type: "line",
          events: {},
          cursor: "pointer",
          color: CHART_COLORS.VOLTAGE_LL,
          zIndex: 2,
        },
        {
          name: "Current",
          data: current,
          tooltip: {
            valueSuffix: currentUnit,
          },
          yAxis: 1,
          type: "line",
          events: {},
          cursor: "pointer",
          color: CHART_COLORS.CURRENT,
          zIndex: 2,
        },
        {
          name: "Frequency",
          data: frequency,
          tooltip: {
            valueSuffix: frequencyUnit,
          },
          yAxis: 2,
          type: "line",
          events: {},
          cursor: "pointer",
          color: CHART_COLORS.FREQUENCY,
          zIndex: 1,
        },
      ];

      const plotOptions = {
        series: { turboThreshold: 0, connectNulls: true },
        line: {
          marker: {
            enabled: false,
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      };

      const tooltipFormatter = function (s, point) {
        const date = new Date(point.x);
        const hour = date.getHours();
        const minute = date.getMinutes();

        const hourString = hour < 10 ? `0${hour}` : hour;
        const minuteString = minute < 10 ? `0${minute}` : minute;

        const series = point.series.chart.series;

        const mappedValues = series.reduce((acc, serie) => {
          const dataPoint = serie.data.find((item) => item.x === point.x);

          if (dataPoint && (dataPoint.y || dataPoint.y === 0)) {
            const { name, color } = serie;
            const { valueSuffix = "" } = serie.userOptions.tooltip;

            acc += `
              <div style="display:flex;">
                <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; padding-bottom:8px;">
                  <tspan style="font-size:20px; color: ${color}; fill: ${color};">●</tspan>&nbsp;${name}
                </div>
                <div style="display:grid; width:100%;">
                  <div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">
                    &nbsp;&nbsp;&nbsp;${dataPoint.y} ${valueSuffix}
                  </div>
                </div>
                <br/>
              </div>
            `;
          }
          return acc;
        }, "");

        return `
          <span style="font-size:15px; font-weight:bold">${hourString}:${minuteString}<hr></span>
          ${mappedValues}
        `;
      };

      return {
        isLoading,
        isFetching,
        xAxis,
        yAxis,
        tooltipFormatter: tooltipFormatter,
        series,
        plotOptions,
      };
    },
    []
  );

  useEffect(() => {
    const title = "Voltage, Current & Frequency (AC)";

    const { xAxis, yAxis, tooltipFormatter, series, plotOptions } =
      getChart(data);

    const options = {
      ...chartOptions({
        title: title,
        colors: [],
        loading: isLoading,
        xAxis: xAxis,
        yAxis: yAxis,
        tooltipFormatter: tooltipFormatter,
        plotOptions: plotOptions,
        data: series,
      }),
    };

    const newOptions = {
      ...options,
      chart: {
        borderRadius: 8,
        height: "400px",
        zooming: { type: "x" },
        events: {
          load() {
            if (isLoading || isFetching) {
              this.showLoading();
            } else {
              this.hideLoading();
            }
          },
          redraw() {
            if (isLoading || isFetching) {
              this.showLoading();
            } else {
              this.hideLoading();
            }
          },
        },
      },
      loading: {
        showDuration: 0,
        hideDuration: 1000,
        labelStyle: {
          fontSize: "16px",
        },
      },
      legend: {
        itemStyle: {
          fontWeight: "regular",
          fontFamily: "Inter",
        },
      },
      xAxis: xAxis,
      yAxis: yAxis,
      styledMode: true,
      title: {
        ...options.title,
        x: -4,
        y: 24 + 12,
        margin: 52,
        fontFamily: "Inter",
      },
      navigation: {
        buttonOptions: {
          y: 16,
          x: -16,
        },
      },
    };

    setOptions(newOptions);
  }, [data, isLoading, isFetching, dropdownValue, getChart]);

  const onDropdownChange = useCallback((value: DROPDOWN_OPTION_VALUE) => {
    setDropdownValue(value);
  }, []);

  return {
    plantId,
    startDate,
    endDate,
    liveButton,
    globalDate,
    dropdownOptions: null,
    // TODO: kaz uncomment in future when other granularities than 1 minute will appear
    //  {
    //       value: dropdownValue,
    //       items: DROPDOWN_OPTIONS.slice(1, 2),
    //       onChange: onDropdownChange,
    //     },
    buttonGroups: [],
    chartOptions: options,
    data,
  };
};
