import { Cell } from "../../../../shared/components/Table";

const x1: Cell = {
  key: "equipName",
  label: "Inverter",
  align: "left",
};

const x2: Cell = {
  key: "generationloss",
  label: "Gen. loss(kWh)",
  align: "left",
};

const x3: Cell = {
  key: "mtbf",
  label: "MTBF",
  align: "left",
};

const x4: Cell = {
  key: "mttr",
  label: "MTTR",
  align: "left",
};


export const trippingViewHeaderCell = [x1, x2, x3, x4];

const x5: Cell = {
  key: "equipName",
  label: "Inverter",
  align: "left",
};

const x6: Cell = {
  key: "generationloss",
  label: "Gen. loss(kWh)",
  align: "left",
};

const x7: Cell = {
  key: "since",
  label: "Underp.. since",
  isDate: true,
  align: "left",
};

export const underperformingViewHeaderCell = [x5, x6, x7];


const x8: Cell = {
  key: "createdOn",
  label: "Alarm raised",
  isDate: true,
  align: "left",
};

const x9: Cell = {
  key: "alarmCategoryName",
  label: "Alarm type",
  align: "left",
};

const x10: Cell = {
  key: "description",
  label: "Description",
  align: "left",
};

const x11: Cell = {
  key: "equipmentCategory",
  label: "Equipment category",
  align: "left",
};


const x12: Cell = {
  key: "nodeDisplayName",
  label: "Equipment",
  align: "left",
};

const x13: Cell = {
  key: "status",
  label: "Status",
  align: "left",
};

const x14: Cell = {
  key: "modifiedOn",
  label: "Resolved",
  align: "left",
};

// const x15 : Cell = {
//   key: "AccidentCreated",
//   label: "Incident created",
//   align: "left",
// };

const x15: Cell = {
  key: "alarmGenerationLoss",
  label: "Approx. generation loss",
  align: "left",
};


export const accidentViewHeaderCell = [x8, x9, x10, x11, x12, x13, x14, x15];



export interface DropdownOption {
  label: string;
  value: any;
}