import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  description: string;
  equipmentName: string;
  fieldX: string;
  fieldY: string;
  issuesList: string;
  numberOfAlarms: number;
  priority: number;
}

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
  definition?: any;
}

interface Data {
  error: string;
  loading: boolean;
  data: Array<Item>;
}

export const useTopIssues = (props: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const { date, plantId, inverterId, blockId } = props;

    if (!date || !plantId || !inverterId)
      return;

    setLoading(true);
    setError('');
    fetchAsync(`SkyfriSidemApi/v1/GetTop5Issues?`, 'GET', { date, plantId, inverterId, blockId })
      .then((data: Array<Item>) => {
        const newData = data?.filter(item => item.description?.length > 0);
        setData(newData);
        setLoading(false);

        if (!newData?.length) {
          setError('No data');
        }
      })
      .catch(e => setError(e));
  }, [props]);

  return {
    data,
    loading,
    error
  }
};
