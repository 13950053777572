import { toast } from "sonner"
import { CustomCloseSVG } from "../../icons/cmms/CLOSE.component"
import { ReactComponent as ERROR_SVG } from "../../icons/app/TOAST_ERROR.svg"
import { ReactComponent as SUCCESS_SVG } from "../../icons/app/TOAST_SUCCESS.svg"
import { ReactComponent as WARNING_SVG } from "../../icons/app/TOAST_WARNING.svg"

import './toaster.css'
import { ReloadSVG } from "../../icons/app/RELOAD.component"

export const ToastMessage = (message: string, type: "success" | "error" | "warning", refreshButton?: boolean) => {
    switch (type) {
        case "success":
            return toast.custom(
                (t) => (
                    <div className="toast success" >
                        <div className="icon">
                            <SUCCESS_SVG height={20} width={20} />
                        </div>
                        <p className="description successDescription"> {message} </p>
                        <button className="headlessClose" onClick={() => toast.dismiss(t)}>
                            <CustomCloseSVG fill="#3F5D40" width={19} height={19} />
                        </button>
                    </div>
                )
            );
        case "error":
            return toast.custom(
                (t) => (
                    <div className="toast error">
                        <div className="icon">
                            <ERROR_SVG height={20} width={20} />
                        </div>
                        <p className="description errorDescription" > {message} </p>
                        {
                            refreshButton && (
                                <button className="refreshButton" onClick={() => window.location.reload()}>
                                    <ReloadSVG fill="#B71C1C" />
                                </button>
                            )
                        }
                        <button className="headlessClose" onClick={() => toast.dismiss(t)}>
                            <CustomCloseSVG fill="#B71C1C" width={19} height={19} />
                        </button>
                    </div>
                )
            );
        case "warning":
            return toast.custom(
                (t) => (
                    <div className="toast warning">
                        <div className="icon">
                            <WARNING_SVG height={20} width={20} />
                        </div>
                        <p className="description warningDescription" > {message} </p>
                        {
                            refreshButton && (
                                <button className="refreshButton" onClick={() => window.location.reload()}>
                                    <ReloadSVG fill="#663C00" />
                                </button>
                            )
                        }
                        <button className="headlessClose" onClick={() => toast.dismiss(t)}>
                            <CustomCloseSVG fill="#663C00" width={19} height={19} />
                        </button>
                    </div>
                )
            );
    }

}
