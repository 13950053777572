import { makeStyles } from "@material-ui/core/styles";
import { GLOBAL_COLORS } from "../../../../../shared/utils/utils";
import { ROW_HEIGHT } from "./utils";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "32px 24px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  head: {
    padding: "8px 16px !important",
  },
  toolbar: {
    minHeight: "20px !important",
    paddingLeft: "0 !important",
  },
  sourceCell: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  sourceSquare: {
    width: "12px",
    height: "12px",
  },
  titleWrapper: {
    margin: "0",
    display: "flex",
    alignItems: "center",
    gap: "32px",
  },
  title: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    margin: "0",
    color: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY,
  },
  itemsCount: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    color: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY_WITH_OPACITY,
  },
  titleSourcesWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
  },
  titleWithCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
  },

  titleSourceCell: {
    position: "relative",
    "&:not(:last-child)": {
      "&::after": {
        width: "1px",
        height: "21px",
        backgroundColor: "rgba(0,0,0,0.12)",
        content: '" "',
        top: 0,
        left: 0,
      },
    },
  },
  titleSourceItemName: {
    color: GLOBAL_COLORS.LIGHT_PRIMARY_PRIMARY_WITH_OPACITY,
  },
  providerDropdownWrapper: { display: "flex", gap: "16px", marginTop: "16px" },
  providerDropdownFormControl: { marginRight: 16, width: "180px" },
  notExecutedOnlyLabel: { fontWeight: 500 },
  tableContainer: {
    borderRadius: '8px',
    border: `1px solid ${GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40}`,
  },
  tableHead: {
    height: ROW_HEIGHT,
    maxHeight: ROW_HEIGHT,
  },
  searchWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tablePagination: { marginLeft: 16, minHeight: "0 !important" },
}));
