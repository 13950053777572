export const plantInformation = {
  clientName: "Client name",
  siteName: "Site name",
  siteDetail: {
    siteCode: "Site code",
    lattitude: "lattitude",
    longitude: "longitude",
    postcode: "postcode",
    tilt: "tilt",
    tariff: "tariff",
    dcCapacity: "dcCapacity",
    PR: "PR",
    degration: "degration",
    timeZone: "timeZone",
    Azimuth: "Azimuth",
    acCapacity: "acCapacity",
    Availability: "Availability",
  },
  Offtaker: "Offtaker",
  SPV: {
    name: "Name",
    address: "Address",
    type: "Type",
    manufacturer: "Manufacturer",
    ratio: "Ratio",
    capacity: "Capacity",
    number: "Number",
  },
  inventers: {
    name: "Name",
    address: "Address",
    type: "Type",
    manufacturer: "Manufacturer",
    ratio: "Ratio",
    capacity: "Capacity",
    number: "Number",
  },
  modules: {
    type: "Type",
    manufacturer: "Manufacturer",
    area: "area",
    capacity: "Capacity",
    number: "Number",
  },
  combiner: {
    manufacturer: "Manufacturer",
    number: "Number",
    model: "Model",
  },
  transformer: {
    main: {
      manufacturer: "Manufacturer",
      number: "Number",
      model: "Model",
      rated: "Rated",
    },
    auxiliary: {
      manufacturer: "Manufacturer",
      number: "Number",
      model: "Model",
      rated: "Rated",
    },
  },
  pyranometers: {
    type: "Type",
    model: "Model",
    manufacturer: "Manufacturer",
  },
  o_m: {
    name: "Name",
    startDate: "startDate",
    duration: "Duration",
    contactPerson: "contactPerson",
  },
  updateInformation: {
    date: "Date",
    time: "Time",
    editedBy: "EditedBy",
  },
};
