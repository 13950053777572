import React, { useState, useCallback, useEffect } from "react";
import { currencyList, timeFormatList } from "./utils";
import { useSharedFormState } from "../../../shared/utils/sharedStates";
import moment from "moment";
import { useGetAlarmNotificationQuery, usePostAlarmNotificationMutation } from "../../../features/Sidem/shared/sidemSharedApi";
interface Data {
  alarmNotification: boolean;
  setAlarmNotification: any;
  currency: string;
  setCurrency: any;
  timeZone: string;
  setTimeZone: any;
  timeFormat: string;
  setTimeFormat: any;
  openDropdown: boolean;
  setOpenDropdown: any;
  isDropdownOpen: boolean;
  setIsDropdownOpen: any;
  anchorEl: any;
  setAnchorEl: any;
  openSettings: boolean;
  setOpenSettings: any;
  searchText: string;
  setSearchText: any;
  viewDefinitions: any;
  setViewDefinitions: any;
  lightTheme: any;
  setLightTheme: any;
  automaticRefresh: any;
  setAutomaticRefresh: any;
  timeLabel: any;
  setTimeLabel: any;
  selectedOption: any;
  setSelectedOption: any;
  userTimeZone: any;
  setUserTimeZone: any;
}
export const useGlobalSettings = (): Data => {
  const [currency, setCurrency] = useState(currencyList[0]);
  const [timeZone, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState(timeFormatList[0]);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [searchText, setSearchText] = useState("");
  const {
    viewDefinitions,
    setViewDefinitions,
    lightTheme,
    setLightTheme,
    automaticRefresh,
    setAutomaticRefresh,
    timeLabel,
    setTimeLabel,
    selectedOption,
    setSelectedOption,
    userTimeZone,
    setUserTimeZone,
    alarmNotification,
    setAlarmNotification,
  } = useSharedFormState();

  const { data: userOptedIn, refetch: refetchAlarm } =
    useGetAlarmNotificationQuery();

  useEffect(() => {
    const handlePageReload = () => {
      refetchAlarm();
    };
    window.addEventListener("beforeunload", handlePageReload);
    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []);

  useEffect(() => {
    if (userOptedIn?.["userIsOptedIn"] === true) {
      setAlarmNotification(true);
    }
  }, [userOptedIn]);

  const [postAlarmNotification] = usePostAlarmNotificationMutation();

  useEffect(() => {
    if (alarmNotification === true) {
      const payload = {
        AlarmOptIn: true,
      };
      const payloadString = JSON.stringify(payload);
      postAlarmNotification({ data: payloadString });
    } else {
      const payload = {
        AlarmOptIn: false,
      };
      const payloadString = JSON.stringify(payload);
      postAlarmNotification({ data: payloadString });
    }
  }, [alarmNotification]);

  const findUserTimeZone = (option) => {
    const timeZone = option?.utc[0];
    return timeZone;
  };
  useEffect(() => {
    if (selectedOption === null) {
      setUserTimeZone(moment.tz.guess());
    } else {
      const globalUserSetting = findUserTimeZone(selectedOption);
      setUserTimeZone(globalUserSetting);
    }
  }, [selectedOption]);

  useEffect(() => {
    const handleClickOutsideGlobalSettings = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        setOpenSettings(false);
        setAnchorEl(null);
      }
    };
    if (openSettings) {
      document.addEventListener("click", handleClickOutsideGlobalSettings);
    }
    return () => {
      document.removeEventListener("click", handleClickOutsideGlobalSettings);
    };
  }, [openSettings, anchorEl]);

  return {
    currency,
    setCurrency,
    timeZone,
    setTimeZone,
    timeFormat,
    setTimeFormat,
    openDropdown,
    setOpenDropdown,
    isDropdownOpen,
    setIsDropdownOpen,
    anchorEl,
    setAnchorEl,
    openSettings,
    setOpenSettings,
    searchText,
    setSearchText,
    viewDefinitions,
    setViewDefinitions,
    lightTheme,
    setLightTheme,
    automaticRefresh,
    setAutomaticRefresh,
    timeLabel,
    alarmNotification,
    setAlarmNotification,
    setTimeLabel,
    selectedOption,
    setSelectedOption,
    userTimeZone,
    setUserTimeZone,
  };
};
