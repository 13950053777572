import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  disabledDiv: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  select: {
    width: "200px",
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "#E8ECF2",
      borderRadius: "5px",
      transition: "0.1s ease background-color",
    },
  },
  boxStyle: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));
