import React, { useEffect, useState } from "react";
import {
  weatherIcons,
  categories,
  actualProduction,
  powerMeasured,
  powerExpected,
  maximumArray,
  minimumArray,
} from "./mock";

export const useEnergyChart = () => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    const options = {
      chart: {
        type: "column",
      },

      navigation: {
        buttonOptions: {
          align: "right",
          marginBottom: 10,
        },
      },

      credits: {
        enabled: false,
      },
      title: {
        margin: 30,
        text: "Energy",
        align: "left",
        style: {
          fontSize: "16px",
          fontFamily: "Inter",
          fontWeight: 500,
          lineHeight: "17.6px",
          color: "#0C1E33",
        },
      },
      xAxis: [
        {
          type: "category",
          zoomEnabled: true,
          labels: {
            rotation: 0,
            step: 6,
            align: "center",
            style: {
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "12px",
              color: "#0C1E33",
              fontFamily: "Inter",
            },
          },
          lineWidth: 1,
          gridLineWidth: 1,
          categories: categories,
          tickmarkPlacement: "on",
        },
        {
          type: "category",
          tickInterval: 2,

          labels: {
            rotation: 0,
            align: "center", // Align labels at the center
            useHTML: true,
            formatter: function () {
              return `
              <div style="display: flex; flex-direction: column; align-items: center;">
              <img src=../static/${weatherIcons?.[this.pos]}.svg
                style="width: 28px; height: 28px; margin-bottom: 5px;" />
            </div>    
              `;
            },
          },
          lineWidth: 0,
          opposite: true,
          linkedTo: 0,
        },
      ],
      yAxis: {
        visible: true,
        title: {
          text: "kWh",
          textAlign: "right",
          rotation: 0,
          x: 24,
          y: -136,
          style: {
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            color: "#0C1E33",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            lineHeight: "12px",
            color: "#0C1E33",
          },
        },
        showLastLabel: false,
        min: 0,
        max: 450,
        tickInterval: 50,
        lineWidth: 0,
        gridLineWidth: 0,
      },

      legend: {
        shadow: false,
        enabled: true,
        layout: "horizontal",
        align: "left",
        verticalAlign: "bottom",
        itemStyle: {
          fontSize: "12px",
          fontWeight: 500,
          fontFamily: "Inter",
          lineHeight: "12px",
          color: "#0C1E33",
        },
      },
      tooltip: {
        crosshairs: true,
        enabled: true,
        formatter: function () {
          return this.points.reduce(function (s, point) {
            const pointColor = `<tspan style="font-size:20px; color: ${point.color}; fill: ${point.color};">●</tspan>&nbsp`;
            const pointSeriesName =
              point.series.name === "Energy production"
                ? "Production"
                : "Expected";
            const value =
              point.series.name === "Energy production"
                ? `<span style="font-size:12px; font-weight:bold; display:flex; align-items: baseline;font-family:Inter;">${point.point.y.toFixed(
                    1
                  )} kWh</span>`
                : point.series.name === "Energy expected (range)"
                ? `<span style="font-size:12px; font-weight:bold;display:flex; align-items: baseline;font-family:Inter">
                   ${point.point.low.toFixed(1)} - 
                   ${point.point.y.toFixed(1)} kWh
                   </span>`
                : "";

            return (
              s +
              '<div style="display:flex; align-items: baseline; justify-content: space-between; width:200px; flex-direction:row;">' +
              '<span style="font-size:13px; font-weight:400;display:flex;align-items: baseline;font-family:Inter;">' +
              pointColor +
              pointSeriesName +
              "</span>" +
              '<span style="font-size:12px; font-weight:500;">' +
              value +
              "</span>" +
              "</div>"
            );
          }, '<span style="font-size:15px; font-weight:bold;font-family:Inter">' +
            this.x +
            "<hr></span>");
        },

        shared: true,
        useHTML: true,
        borderWidth: 0,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      },

      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Energy production",
          type: "column",
          data: actualProduction,
          color: "rgba(12, 30, 51, 1)",
          legendIndex: 0,
          showInLegend: true,
          pointPadding: 0,
          pointPlacement: 0,
        },
        {
          name: "Energy expected (range)",
          color: " rgba(151, 206, 253, 0.5)",
          data: maximumArray.map((maximum, index) => ({
            y: maximum,
            low: minimumArray[index],
          })),
          pointPadding: 0,
          pointPlacement: 0,
        },
      ],
    };
    setOptions(options);
  }, []);

  return {
    options,
  };
};
