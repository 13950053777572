import React from 'react'
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as BELL_BLACK } from '../../../../../shared/icons/sidem/BELL_BLACK.svg';
import { AnalyticsAccordion } from './components/Accordion';
import { Chip } from '@mui/material';
import { useSummaryWidget } from './useSummaryWidget';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button } from '../../../../../shared/components';

export const SummaryWidget = () => {
    const { summaryData, toggleAcordion, expand } = useSummaryWidget();
    const classes = useStyles();
    return (
        <>
            <div className={classes.wrapper}>
                <Grid
                    container
                    spacing={2}
                    alignItems={"center"}
                >
                    <Grid item xs={6}>
                        <Grid
                            container
                            spacing={1}
                            alignItems={"center"}
                        >
                            <Grid item xs={"auto"} className={classes.heading}>
                                Summary
                            </Grid>
                            <Grid item xs={"auto"} className={classes.portfolios}>
                                ({summaryData?.numberOfPortfolios} portfolios)
                            </Grid>
                            <Grid item xs={"auto"}>
                                <Chip
                                    size='small'
                                    sx={{
                                        '& .MuiChip-label': { opacity: 1 },
                                        backgroundColor: "#E1E4E9",
                                        color: "#0C1E33",
                                        fontFamily: [
                                            'Inter',
                                            'Roboto',
                                            'sans-serif'
                                        ].join(',')
                                    }}
                                    label={summaryData?.numberOfAlarms}
                                    icon={<BELL_BLACK />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            spacing={1}
                            alignItems={"center"}
                            justifyContent='flex-end'
                        >
                            <Button
                                title={expand ?
                                    'Hide charts'
                                    :
                                    'Show charts'}
                                onClick={() => toggleAcordion()}
                                endIcon={
                                    expand ?
                                        <ExpandLessRoundedIcon />
                                        :
                                        <ExpandMoreRoundedIcon />
                                }
                                color='#0C1E33'
                                noBorder
                                outlined
                                className={classes.accordionExpand}
                                fontSize='15px'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <AnalyticsAccordion />
        </>
    )
}
