import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as GREEN_ARROW_UP } from '../../../../../../../shared/icons/analytics/GREEN_ARROW_UP.svg';
import { ReactComponent as RED_ARROW_DOWN } from '../../../../../../../shared/icons/analytics/RED_ARROW_DOWN.svg';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { Divider, Skeleton } from '@mui/material';
import { GenerationLast7DWidget } from '../GenerationLast7DWidget';
import { ProductionFactor7DWidget } from '../ProductionFactor7DWidget';
import { GenerationIrradiationDelta7D } from '../GenerationIrradiationDelta7D';
import { GenerationIrradiationDelta30D } from '../GenerationIrradiationDelta30D';
import { useAccordion } from './useAccordion';
import { convertkWToMW, kWhOrMWh } from '../../../../../../../shared/utils/utils';
import Loader from '../../../../../../../monitoring/components/Loader';

export const AnalyticsAccordion = () => {
    const classes = useStyles();

    const {
        accordionData,
        accordionLoading,
        accordionExpand,
        accordionSecondLoading,
        accordionError,
    } = useAccordion();

    const showDelta = (value: number, expected: string) => {
        return (
            <>
                <div className={classes[expected]}>
                    <span className={classes[`${expected}Label`]}>
                        {value}%
                    </span>
                    <span className={classes.growthIcon}>
                        {
                            expected === "Equal" ?
                                null
                                :
                                expected === "Low" ?
                                    < RED_ARROW_DOWN />
                                    :
                                    < GREEN_ARROW_UP />
                        }
                    </span>
                </div>
            </>)
    }

    return (
        <div className={classes.wrapper}>
            <Accordion expanded={accordionExpand} elevation={0}  >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ cursor: "default", padding: 0 }}
                >
                    {
                        accordionError ?
                            <Loader error={"No data"} height={200} errorTextSize={24} />
                            :
                            <Grid container spacing={5}>
                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" sx={{ marginBottom: "4px" }} animation="pulse" height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {accordionData?.kpiResponse?.dcCapacityMW}
                                                <span className={classes.kpiUnit}>
                                                    MWp
                                                </span>
                                            </div>
                                    }
                                    <div className={classes.kpiTypeNoGrowth}>
                                        Capacity
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} style={{ marginBottom: "21px" }} />
                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" animation="pulse" sx={{ marginBottom: "4px" }} height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {convertkWToMW(accordionData?.kpiResponse?.actGen)?.toFixed(1)}
                                                <span className={classes.kpiUnit}>
                                                    {kWhOrMWh(accordionData?.kpiResponse?.actGen)}
                                                </span>
                                            </div>
                                    }

                                    <div className={classes.flexType}>
                                        <div className={classes.kpiType}>
                                            Gen. delta
                                        </div>
                                        {
                                            accordionLoading ?
                                                null :
                                                showDelta(accordionData?.kpiResponse?.genDelta, accordionData?.kpiResponse?.genDeltaFlag)
                                        }
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} style={{ marginBottom: "21px" }} />

                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" animation="pulse" sx={{ marginBottom: "4px" }} height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {accordionData?.kpiResponse?.insolation}
                                                <span className={classes.kpiUnit}>
                                                    kWh/m²
                                                </span>
                                            </div>
                                    }

                                    <div className={classes.flexType}>
                                        <div className={classes.kpiType}>
                                            Insolation
                                        </div>
                                        {
                                            accordionLoading ?
                                                null
                                                :
                                                showDelta(accordionData?.kpiResponse?.insolationDelta, accordionData?.kpiResponse?.insolationDeltaFlag)
                                        }
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} style={{ marginBottom: "21px" }} />

                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" animation="pulse" sx={{ marginBottom: "4px" }} height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {accordionData?.kpiResponse?.actPR}%
                                                <span className={classes.kpiUnit}>
                                                </span>
                                            </div>
                                    }

                                    <div className={classes.flexType}>
                                        <div className={classes.kpiType}>
                                            PR
                                        </div>
                                        {
                                            accordionLoading ?
                                                null
                                                :
                                                showDelta(accordionData?.kpiResponse?.prDelta, accordionData?.kpiResponse?.prDeltaFlag)}
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} style={{ marginBottom: "21px" }} />

                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" animation="pulse" sx={{ marginBottom: "4px" }} height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {accordionData?.availabilityResponse?.actAvailability}%
                                                <span className={classes.kpiUnit}>
                                                </span>
                                            </div>
                                    }

                                    <div className={classes.flexType}>
                                        <div className={classes.kpiType}>
                                            Plant avail.
                                        </div>
                                        {
                                            accordionLoading ?
                                                null
                                                :
                                                showDelta(accordionData?.availabilityResponse?.availabilityDelta, accordionData?.availabilityResponse?.availabilityFlag)}
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} style={{ marginBottom: "21px" }} />

                                <Grid item xs={6} sm={3} md={"auto"}>
                                    {
                                        accordionLoading && accordionSecondLoading ?
                                            <Skeleton variant="rounded" animation="pulse" height={35} width={80} />
                                            :
                                            <div className={!accordionSecondLoading ? classes.kpiText : classes.loadingColor}>
                                                {accordionData?.availabilityResponse?.gridAvailability}%
                                                <span className={classes.kpiUnit}>
                                                </span>
                                            </div>
                                    }

                                    <div className={classes.kpiTypeNoGrowth}>
                                        Grid avail.
                                    </div>
                                </Grid>
                            </Grid>
                    }

                </AccordionSummary>

                <AccordionDetails style={{ padding: 0, paddingBottom: "16px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} style={{ paddingInline: 0 }}>
                            <GenerationLast7DWidget />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <ProductionFactor7DWidget />
                                </Grid>
                                <Grid item xs={12}>
                                    <GenerationIrradiationDelta7D />
                                    <GenerationIrradiationDelta30D />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div >
    )
}
