import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons, Search, Button } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { UseRoleSave } from "./useRoleSave";
import { searchData } from "./../../components/Utils/utils";
import {
  GetFormik,
  initializeValidationsObsolete,
  initializeValuesObsolete,
} from "./../../components/Utils/utils";
import { roleFormSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";
import { Box, Tab, Tabs } from "@mui/material";
import { CustomTable } from "../../../../shared/components/Table/Table.component";
import {
  PermissionViewHeaderCell,
  TeamViewHeaderCell,
  permissionFilters,
  teamRoleFilters,
} from "./interfaces";

interface Props {
  subsidiaryId: string;
  type: string;
  callback: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight: "45vh" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const RoleSavePage: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { roleId } = useParams<{ roleId: string }>();

  const {
    upsert,
    teamRoles,
    deleteTeamRole,
    addPermissions,
    deletePermissions,
  } = UseRoleSave({ roleId });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [teamFilteredData, setTeamFilteredData] = React.useState([]);
  const [permissionSearch, setPermissionSearch] = React.useState("");
  const [permissionFilteredData, setPermissionFilteredData] = React.useState(
    []
  );

  const createNewRole = roleId === "new";

  useEffect(() => {
    initializeValidationsObsolete(roleFormSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (!teamRoles?.teams) return;
    if (search && search !== "") {
      setTeamFilteredData(
        searchData(search, teamRoles?.teams, teamRoleFilters)
      );
    } else {
      setTeamFilteredData(teamRoles?.teams);
    }
  }, [search, teamRoles?.teams]);

  useEffect(() => {
    if (!teamRoles?.permissions) return;
    if (permissionSearch && permissionSearch !== "") {
      setPermissionFilteredData(
        searchData(permissionSearch, teamRoles?.permissions, permissionFilters)
      );
    } else {
      setPermissionFilteredData(teamRoles?.permissions);
    }
  }, [permissionSearch, teamRoles?.permissions]);

  useEffect(() => {
    if (createNewRole) {
      initializeValuesObsolete(roleFormSchema, createNewRole, [], (resp) => {
        setInitialValues(resp);
      });
    }
  }, []);

  const searchHandler = (newSearch: string, type: string) => {
    if (type === "permissions") {
      setPermissionSearch(newSearch);
    } else {
      setSearch(newSearch);
    }
  };

  const handleCheckboxClick = (data, data2) => {
    var obj = {
      TeamRoleId: data?.teamRoleId,
      PermissionKey: data?.permissionKey,
    };

    if (data.hasAccess === false) {
      addPermissions(obj, (resp) => {
        if (resp?.ok === false) {
          setSeverity("error");
          setMessage("Operation unsuccessfull");
          setIsOpen(true);
        } else {
          setSeverity("success");
          setMessage("Record added successfully");
          setIsOpen(true);
        }
      });
    } else {
      deletePermissions(obj, (resp) => {
        if (resp?.ok === false) {
          setSeverity("error");
          setMessage("Operation unsuccessfull");
          setIsOpen(true);
        } else {
          setSeverity("success");
          setMessage("Record removed successfully");
          setIsOpen(true);
        }
      });
    }
  };

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.tenantId = tenantId;
    upsert(fields, roleId, (resp) => {
      if (resp?.ok === false) {
        setSeverity("error");
        setMessage("Operation unsuccessfull");
        setIsOpen(true);
      } else {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true);
      }
      setTimeout(() => {
        let URI = `/onboarding/roles/${tenantId}/${resp?.id}`;
        renderPage(URI);
      }, 2000);
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const handleDelete = (id) => {
    let obj = {
      teamId: id,
      teamRoleId: roleId,
    };
    deleteTeamRole(obj, (resp) => {
      if (resp?.ok === false) {
        setSeverity("error");
        setMessage("Operation unsuccessfull");
        setIsOpen(true);
      } else {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true);
      }
    });
  };

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewRole ? <>Add new role </> : <>Edit role</>}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          {createNewRole ? (
            <div className={classes.formElementsWrapper}>
              {Object.keys(roleFormSchema).map((x) => (
                <DynamicField
                  name={x}
                  data={roleFormSchema[x]}
                  formik={formik}
                />
              ))}
            </div>
          ) : (
            <p>
              <b>Role name:</b> {teamRoles?.name || ""}
            </p>
          )}
        </form>

        {createNewRole ? (
          <></>
        ) : (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "white",
              }}
            >
              <Tabs value={value} onChange={handleChange} aria-label="">
                <Tab label="Teams" {...a11yProps(0)} />
                <Tab label="Permissions" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Button
                color={"rgb(43, 41, 88)"}
                type="button"
                title={"Add Team"}
                className={classes.floatRight}
                onClick={() =>
                  renderPage(`/onboarding/roleTeam/${tenantId}/${roleId}/new`)
                }
              >
                Add plant
              </Button>
              <div style={{ maxWidth: "400px" }}>
                <Search
                  placeholder="Search for team"
                  value={search}
                  onChange={(search) => searchHandler(search, "team")}
                  onKeyUp={(search) => searchHandler(search, "team")}
                />
              </div>
              <br />
              <CustomTable
                tableType={`/`}
                module="/onboarding"
                headerCells={TeamViewHeaderCell}
                data={teamFilteredData || []}
                maxHeight={250}
                onDeleteClick={(id) => handleDelete(id)}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ maxWidth: "400px" }}>
                <Search
                  placeholder="Search for permissions"
                  value={permissionSearch}
                  onChange={(search) => searchHandler(search, "permissions")}
                  onKeyUp={(search) => searchHandler(search, "permissions")}
                />
              </div>
              <br />
              <CustomTable
                tableType={`/`}
                module="/onboarding"
                maxHeight={250}
                headerCells={PermissionViewHeaderCell}
                data={permissionFilteredData || []}
                onDeleteClick={(id) => () => { }}
                handleCheckboxClick={(data1, data2) =>
                  handleCheckboxClick(data1, data2)
                }
              />
            </TabPanel>
          </>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save"
              onSave={() => {
                if (!createNewRole) {
                  let URI = `/onboarding/tenant/${tenantId}?index=4`;
                  renderPage(URI);
                }
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Back"
              onCancel={() => {
                let URI = `/onboarding/tenant/${tenantId}?index=4`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
