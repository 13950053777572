import { PublicClientApplication } from "@azure/msal-browser";
import { LOCALSTORAGEKEYS } from "../app/constants/constant";

export function getBearerTokenFromLocalStorage() {
  getBearerToken();
  return localStorage.getItem(LOCALSTORAGEKEYS.TOKEN);
}

let accessTokenPromiseInProgress: Promise<string> | null = null;

export async function getBearerToken(): Promise<string> {
  if (!accessTokenPromiseInProgress) {
    accessTokenPromiseInProgress = getMsalAccessToken([getScope()]);
  }
  const token = await accessTokenPromiseInProgress;
  accessTokenPromiseInProgress = null;
  return token;
}

const getMsalAccessToken = async (scopes: string[]): Promise<string> => {
  const pca = new PublicClientApplication(authConfig());
  const accounts = pca.getAllAccounts();
  const account = accounts[0];
  const b2b = localStorage.getItem(LOCALSTORAGEKEYS.B2B);
  return await pca.acquireTokenSilent({ scopes: scopes, account }).then(async resp => {
    if (resp?.idToken && b2b === "true") {
      var token = await enhanceToken(resp.idToken);
      localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, token);
      return token;
    }

    if (resp?.accessToken && b2b !== "true") {
      localStorage.setItem(LOCALSTORAGEKEYS.TOKEN, resp?.accessToken);
      return resp.accessToken;
    }
  });
};

async function enhanceToken(token: string) {
  const apiUrl = process.env.REACT_APP_B2B_ENHANCE_FUNCTION_URL;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      mode: 'no-cors'
    },
  }
  return await fetch(apiUrl, options).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  })
    .then(data => { return data })
    .catch(error => { console.error('Error:', error); return "" });;
}


export function authConfig(): any {
  const tenantSpecificConfig = localStorage.getItem(LOCALSTORAGEKEYS.B2B) === "true" ? b2b_config : b2c_config;
  return { ...baseConfig, ...tenantSpecificConfig };
};

export function getScope(id: number = 1): string {
  if (localStorage.getItem(LOCALSTORAGEKEYS.B2B) === "true")
    return id === 1 ? process.env.REACT_APP_SCOPE1_B2B : process.env.REACT_APP_SCOPE2_B2B;
  else
    return id === 1 ? process.env.REACT_APP_SCOPE1_B2C : process.env.REACT_APP_SCOPE2_B2C;
}

export function deleteTokenFromLocalStorage() {
  localStorage.removeItem(LOCALSTORAGEKEYS.TOKEN);
}

const b2c_config: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_B2C,
    authority: process.env.REACT_APP_AUTHORITY_B2C,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    validateAuthority: false,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY_B2C],
  },
  scope: [process.env.REACT_APP_SCOPE1_B2B, process.env.REACT_APP_SCOPE2_B2B],
}

const b2b_config: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_B2B,
    authority: process.env.REACT_APP_AUTHORITY_B2B,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    validateAuthority: false,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY_B2B],
  },
  scope: [process.env.REACT_APP_SCOPE1_B2C, process.env.REACT_APP_SCOPE2_B2C],
}

const baseConfig: any = {
  cache: {
    cacheLocation: process.env.REACT_APP_SESSION_STORAGE,
    storeAuthStateInCookie: "false",
  },
  apiURL: process.env.REACT_APP_API_URL,
  apimKey: process.env.REACT_APP_API_MKEY,
  imageURL: process.env.REACT_APP_IMAGE_URL,
  imageToken: process.env.REACT_APP_IMAGE_TOKEN,
}

export function CheckSsoLoginChange(b2b: string) {
  if (b2b === "true") {
    ClearApplicationSiteDate(b2b);
    window.location.href = window.location.origin;
  } else {
    if (b2b === "false") {
      ClearApplicationSiteDate(b2b);
      window.location.href = window.location.origin;
    }
  }
}

function ClearApplicationSiteDate(b2b: string) {
  sessionStorage.clear();
  localStorage.clear();
  localStorage.setItem(LOCALSTORAGEKEYS.B2B, b2b);
  deleteCookie("msal.interaction.status");
}

function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getEmailFromToken(tokenContent: any): string {
  if (!tokenContent) {
    console.log("Token content was null");
    return null;
  }

  if (tokenContent.emails && tokenContent.emails.length > 0) {
    return tokenContent.emails[0];
  }

  if (tokenContent.email) {
    return tokenContent.email;
  }

  return tokenContent.preferred_username
}