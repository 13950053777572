import React, { useState, useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import { Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useStyles } from "./styles";
import { MonitoringPagesEnum } from "./index";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useGetPortfoliosAndPlantsQuery } from "../../features/Sidem/shared/sidemSharedApi";


export const MonitoringNavigation: React.FC<any> = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        data: portfoliosAndPlantsData,
    } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });
    const [url] = useSearchParams()
    const plantId = url.get("plantId")

    // const [clientInfo, setClientInfo] = useState({} as ClientInfo);
    const [plantName, setPlantName] = useState("");
    const [title, setTitle] = useState("");


    const goTo = (url: MonitoringPagesEnum) => {
        switch (url) {
            case MonitoringPagesEnum.MonitoringDashboard:
                return navigate(MonitoringPagesEnum.MonitoringDashboard);
            case MonitoringPagesEnum.MonitoringPageView:
                return navigate(`/monitoring/monitoring-dashboard/plant?plantId=${plantId}`);

        }
    };

    const getNavigationDetails = async () => {
        const pathname = location?.pathname;
        const url = pathname?.split("/");

        if (portfoliosAndPlantsData) {
            // @ts-ignore
            setPlantName(portfoliosAndPlantsData.filter(item => item?.plantId === Number(plantId))[0]?.plantName);
        }
    };


    useEffect(() => {
        if (!location) return;

        getNavigationDetails();
    }, [location, portfoliosAndPlantsData]);

    // TO BE USED
    // const getData = async () => {
    //     const data: any = await dispatch(getClientInfo());
    //     setClientInfo(data);
    // };
    // useEffect(() => {
    //     getData();
    // }, []);

    useEffect(() => {
        if (location?.pathname?.includes("/monitoring-dashboard")) {
            setTitle(`Monitoring Dashboard`);
            return;
        }
        if (location?.pathname?.includes("/monitoring-generation")) {
            setTitle(`Monitoring Generation`);
            return;
        }
        if (location?.pathname?.includes("/data-explorer")) {
            setTitle(`Data Explorer`);
            return;
        }
        if (location?.pathname?.includes("/monitoring-inverter-performance")) {
            setTitle(`Monitoring`);
            return;
        }
    }, [location]);

    return (
        <>
            <Typography
                className={classes.subtitle}
                variant="body1"
                noWrap
                onClick={() =>
                    location?.pathname?.includes("/monitoring-dashboard/plant")
                        ||
                        location?.pathname?.includes("/monitoring-inverter-performance")
                        ? goTo(MonitoringPagesEnum.MonitoringDashboard)
                        : null
                }
            >
                {title}
            </Typography>
            {!plantName?.length ? null : (
                <>
                    <div className={classes.arrow}>
                        <ArrowRightAltIcon />
                    </div>
                    <Typography
                        className={classes.subtitle}
                        variant="body1"
                        noWrap
                        onClick={() => goTo(MonitoringPagesEnum.MonitoringPageView)}
                    >
                        {plantName}
                    </Typography>
                </>
            )}
            {
                location?.pathname?.includes("/monitoring-inverter-performance") ?
                    <>
                        <div className={classes.arrow}>
                            <ArrowRightAltIcon />
                        </div>
                        <Typography
                            className={classes.subtitle}
                            variant="body1"
                            noWrap
                        >
                            Inverters
                        </Typography>
                    </>
                    :
                    null
            }
        </>
    );
};
