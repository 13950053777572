import { useMemo } from "react";
import { HeatmapChart, legendArr } from "../../../../components/HeatmapChart";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";
import { CustomLegend } from "../../../PlantPage/features/HeatmapWidget/CustomLegend";
import { useHeatmap } from "./useHeatmap";
interface Props {
  plantId: string;
  inverterId: string;
  blockId: string;
  date: string;
}
export const HeatmapWidget: React.FC<Props> = (props) => {
  const { data, xLabels, yLabels, loading, error } = useHeatmap(props);
  const hide = useMemo(() => !data?.length && loading, [data, loading]);
  return hide ? null : (
    <Widget label="String Specific Current (HeatMap)" height={500} size={6}>

      {!data?.length || !xLabels?.length || !yLabels?.length ?
        <Loader loading={loading} height={300} error={error} errorTextSize={32} />
        : (
          <>
            <CustomLegend legendData={legendArr} />
            <HeatmapChart data={data} xLabels={xLabels} yLabels={yLabels} />
          </>
        )}
    </Widget>
  );
};
