import React from 'react'
import { FormControl, FormControlLabel, Grid } from '@mui/material'
import { AntSwitch, DateRange, MultiSelectorDropdown } from '../../../../../shared/components'
import { useStyles } from "./styles";
import { ReactComponent as PORTFOLIO_BLACK } from '../../../../../shared/icons/dropdown/PORTFOLIO_BLACK.svg';
import { ReactComponent as PLANT_BLACK } from '../../../../../shared/icons/dropdown/PLANT_BLACK.svg';
import { ReactComponent as TIMEZONE_CLOCK } from '../../../../../shared/icons/app/TIMEZONE_CLOCK.svg';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { analyticsSharedSelector } from '../../../../../features/Analytics/shared/analyticsSharedSlice';

interface Props {
    selectedPortfolio: any;
    portfolio: Array<any>;
    selectedPlant: any;
    plant: Array<any>;
    setSelectedPlant: any;
    date: any;
    setDate: any;
    liveButton?: boolean;
    handleChangeLiveButton: any;
    onChangePortfolio: (val: any) => void;
    preSelectedPlant: any;
    preSelectedPortfolio: any;
}

export const HeaderWidget: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { multiplePlants } = useSelector(analyticsSharedSelector)

    const {
        selectedPortfolio,
        portfolio,
        selectedPlant,
        plant,
        setSelectedPlant,
        date,
        setDate,
        liveButton,
        handleChangeLiveButton,
        onChangePortfolio,
        preSelectedPlant,
        preSelectedPortfolio,
    } = props;
    const onChangeDate = (item) => {
        setDate([item.selection])
    };
    return (
        <div className={classes.wrapper}>
            <Grid
                container
                spacing={2}
                alignItems={"center"}>
                <Grid item sm={12} md={12} lg={6} xl={6} sx={{ paddingTop: 0, paddingLeft: 0 }} >
                    <Grid
                        container
                        alignItems={"center"}
                        justifyContent={'flex-start'}
                    >
                        <Grid className={classes.firstColumn} item xs={'auto'} >
                            <Grid
                                container
                                alignItems={"center"}
                                justifyContent={'space-between'}
                            >
                                <FormControl className={classes.selector} style={{ paddingRight: '10px' }}>
                                    <MultiSelectorDropdown
                                        Icon={PORTFOLIO_BLACK}
                                        value={selectedPortfolio}
                                        options={portfolio}
                                        selected={selectedPortfolio}
                                        preSelectedValue={preSelectedPortfolio}
                                        setSelected={(filterValues) => onChangePortfolio(filterValues)}
                                        label={"portfolios"}
                                    />
                                </FormControl>
                                <FormControl className={classes.selector}>
                                    <Divider orientation="vertical" variant={"middle"} flexItem style={{ height: "21px" }} />
                                </FormControl>
                                <FormControl className={classes.selector} style={{ paddingLeft: '10px', paddingRight: "10px" }}>
                                    <MultiSelectorDropdown
                                        Icon={PLANT_BLACK}
                                        value={selectedPlant}
                                        options={plant}
                                        selected={selectedPlant}
                                        preSelectedValue={preSelectedPlant}
                                        setSelected={setSelectedPlant}
                                        label={"plants"}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={'auto'} md={'auto'} className={classes.firstColumn} >
                            {
                                multiplePlants ?
                                    <div className={classes.timeZones} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div>
                                            <TIMEZONE_CLOCK />&nbsp;
                                        </div>
                                        <div style={{ color: "#2D5374" }} >
                                            Multiple time zones
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.horizontalDivier}>
                    <Divider orientation="horizontal" flexItem />
                </Grid>
                <Grid item sm={12} md={12} lg={6} xl={6} sx={{ '@media (max-width: 1199px)': { '&.MuiGrid-item': { paddingTop: "4px", }, }, }}>
                    <Grid
                        container
                        className={classes.flexType}
                    >
                        <Grid item className={classes.switchMobile}>
                            <FormControlLabel control={<AntSwitch sx={{ m: 1 }} defaultChecked checked={liveButton} onChange={(e) => handleChangeLiveButton(e.target.checked)} />} label="Live" />
                        </Grid>
                        <Grid item className={classes.selector}>
                            <DateRange date={date} onChange={onChangeDate} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}
