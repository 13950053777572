import React, { useMemo } from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import SimpleBarChart, {
  SimpleBarChartItem,
} from "../../../../components/SimpleBarChart";
import Widget from "../../../../components/Widget";
import Loader from "../../../../components/Loader";

import { useStyles } from "./styles";
import { CustomTooltip } from "./CustomTooltip.component";
import { usePowerComparison } from "./usePowerComparison";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const PowerComparisonWidget: React.FC<Props> = ({
  plantId,
  date,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, level, error, loading, profileData, filterValue, onClick } =
    usePowerComparison({ plantId, date });

  const getButton = (name: string, label: string) => (
    <Button
      className={clsx(
        classes.barTabButton,
        filterValue === name && classes.barTabButtonSelected
      )}
      variant="contained"
      name={name}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {label}
    </Button>
  );

  const trackerButtons = () => (
    <div className={classes.barTabsContainer}>
      {getButton("tracker", "Tracker")}
      {getButton("nonTracker", "Non-tracker")}
      {getButton("all", "All")}
    </div>
  );

  const onGoToDrillDownPage = (item: SimpleBarChartItem) => {
    const [block, inverter] = item.label?.split(".");
    navigate(
      `${location.pathname}/block/${block}/inverter/${inverter}`, {
      state: { date },
    });
  };

  const getChart = (data, level) => (
    <SimpleBarChart
      yAxis
      data={data}
      onClick={onGoToDrillDownPage}
      normalLevel={level}
      formatLabel=""
      domain={[0, (dataMax => Math.abs(dataMax + 0.6).toFixed(0))]}
      tooltipContent={<CustomTooltip />}
      normal={true}
      showLabel
      showCustomYAxis={false}
      label={"kW/kWp"}
    />
  );

  const content = useMemo(
    () =>
      !profileData?.hasTracker ? (
        getChart(data, level)
      ) : (
        <>
          {getChart(data, level)}
          {trackerButtons()}
        </>
      ),
    [profileData, data, level]
  );

  return (
    <Widget
      label="Inverter Specific Energy Comparison"
      height={600}
      size={6}
    // styles={classes.marginRight}
    >
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />

      {!data?.length || loading || error?.length ? null : content}
    </Widget>
  );
};
