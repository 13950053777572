import React from 'react';
import { IconButton, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as UNION } from '../../../shared/icons/sidem/UNION.svg';
import { Grid } from '@material-ui/core';
import { ArrowUpwardSharp, ArrowDownwardSharp } from "@mui/icons-material";
import { useStyles } from "./styles";
import { Fields } from './utils';
import clsx from 'clsx';
import { ReactComponent as BELL } from '../../../shared/icons/sidem/BELL.svg';
import { ReactComponent as LOCATION_BLACK } from '../../../shared/icons/sidem/LOCATION_BLACK.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from '../Loader';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    titleComponent?: React.ReactNode;
    content?: React.ReactNode;
    actions?: React.ReactNode;
    width?: string;
    height?: string;
    portfolioPopupData: any;
    plantTitle?: string;
    popupLoading?: boolean;
    error?: string;
}

export const PortfolioPopup: React.FC<ModalProps> = (props) => {
    const classes = useStyles();
    const { isOpen, onClose, title, width, height, portfolioPopupData, plantTitle, popupLoading, error } = props;
    //correction of this after the api is made correctly
    const data = portfolioPopupData?.filter(item => item?.portfolioName === plantTitle)

    const renderKPI = (item, sign, unit) => {
        return (
            <>
                {(item || item === 0) && (
                    <div className={classes.fieldsText}>
                        {
                            item !== 0 ? `${item?.toFixed(1)}${sign}` : null}
                        {
                            item !== 0 ?
                                item < 0 ? (
                                    <ArrowDownwardSharp className={clsx(classes.growth, classes.growthIcon, {
                                        [classes.negativeGrowth]: item < 0,
                                    })} />
                                ) : (
                                    <ArrowUpwardSharp className={clsx(classes.growth, classes.growthIcon, {
                                        [classes.negativeGrowth]: item < 0,
                                    })} />
                                )
                                :
                                null}
                    </div>
                )}
                <div className={classes.mutedText}>
                    {`${unit}`}
                </div>
            </>
        )
    }
    const renderFields = (item, idx) => {

        return (
          <>
            <TableRow
              key={item?.plantName}
              sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
            >
              <TableCell
                className={classes.tablefieldcell}
                component="th"
                size="medium"
                sx={{ maxWidth: "50px", padding: "8px 16px 8px 16px" }}
              >
                <span
                  className={classes.fieldsText}
                  style={{ paddingRight: "5px" }}
                >
                  {item?.plantName}
                </span>
                <span>
                  <div
                    className={
                      item?.numberOfAlarms === 0
                        ? classes.zeroNotification
                        : classes.notification
                    }
                  >
                    <span style={{ paddingRight: "5px" }}>
                      {item?.numberOfAlarms}
                    </span>
                    <BELL />
                  </div>
                </span>
                <div className={classes.location}>
                  <LOCATION_BLACK /> {item?.locationName}
                </div>
              </TableCell>

              <TableCell
                className={classes.tablefieldcell}
                component="th"
                size="medium"
                sx={{ maxWidth: "35px", padding: "8px 16px 8px 16px" }}
              >
                {renderKPI(
                  item?.actualGen > 1000
                    ? item?.actualGen / 1000
                    : item?.actualGen,
                  "",
                  item?.actualGen > 1000 ? "(MWh)" : "(kWh)"
                )}
              </TableCell>

              <TableCell
                className={classes.tablefieldcell}
                component="th"
                size="medium"
                sx={{ maxWidth: "35px", padding: "8px 16px 8px 16px" }}
              >
                {renderKPI(item?.actualIrradiation, "", "(kWh/m²)")}
              </TableCell>

              <TableCell
                className={classes.tablefieldcell}
                component="th"
                size="medium"
                sx={{ maxWidth: "35px", padding: "8px 16px 8px 16px" }}
              >
                {renderKPI(item?.actualPR * 100, "%", "")}
              </TableCell>

              <TableCell
                className={classes.tablefieldcell}
                component="th"
                size="medium"
                sx={{ maxWidth: "35px", padding: "8px 16px 8px 16px" }}
              >
                {renderKPI(item?.actualAvailability * 100, "%", "")}
              </TableCell>

              <TableCell
                component="th"
                size="medium"
                sx={{ maxWidth: "35px", padding: "8px 16px 8px 16px" }}
              >
                <div className={classes.fieldsText}>
                  {item?.invertersRunning === null ? 0 : item?.invertersRunning}
                  /{item?.invertersInstalled}
                </div>
                <div className={classes.mutedText}>{`(run/inst)`}</div>
              </TableCell>
            </TableRow>
          </>
        );
    }

    const renderHeader = () => {
        return (
            Fields?.map((item, idx) => {
                const borderCenter = idx < Fields?.length - 1;
                return (
                    <>
                        <TableCell className={borderCenter ? classes.tabelheadcell : null} size="medium" sx={{ borderBottom: "none", maxWidth: "0px", padding: "8px 16px 8px 16px" }}>{item}</TableCell>
                    </>
                )
            }
            )
        )
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
              if(reason === 'backdropClick') {
                  onClose();
              }
          }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <>
                <Box style={{ maxWidth: width, width, maxHeight: height, height }}>
                    <div className={classes.modalContent} style={{ overflow: "auto" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={10}>
                                <div className={classes.modalTitle}>{title}</div>
                            </Grid>
                            <Grid item xs={2} direction="row" justifyContent="flex-end">
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <IconButton size='medium' type="submit" sx={{ p: '10px' }} aria-label="search" onClick={onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "5px" }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <span className={classes.mount}><UNION /> <span style={{ padding: "2px 4px 0px 4px", }}> {plantTitle} </span> </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr className={classes.hrLine} />
                        {
                            popupLoading || error?.length ?
                                <Loader
                                    loading={popupLoading}
                                    height={"auto"}
                                    error={error}
                                    errorTextSize={32}
                                />
                                :
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead className={classes.tabelheadbg}>
                                            <TableRow>
                                                {
                                                    renderHeader()
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((row, idx) =>
                                                <>{renderFields(row, idx)}</>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        }


                    </div>
                </Box>
            </>
        </Modal>
    );
}
