import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBearerTokenFromLocalStorage } from "../../../store/authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";

export const plantSiteInfoApi = createApi({
  reducerPath: "plantSiteInfoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = "Bearer " + getBearerTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", token);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPortfoliosAndPlants: builder.query<[], void>({
      query: () => ({
        url: `SkyfriSidemApi/v1/GetPortfoliosAndPlants`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getPlantSiteInfo: builder.query({
      query: ({ tenantId }) => ({
        url: `SkyfriSidemApi/v1/QueryTwin/ReadDtPlantInfo?tenantID=${tenantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getInvertersInfo: builder.query({
      query: ({ tenantId, plantId }) => ({
        url: `SkyfriSidemApi/v1//QueryTwin/ReadDtInverters?tenantID=${tenantId}&plantId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getICROGPanelInfo: builder.query({
      query: ({ tenantId, plantId }) => ({
        url: `SkyfriSidemApi/v1/QueryTwin/ReadDtICROGPanel?tenantID=${tenantId}&plantId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getMCRIncomerPanelInfo: builder.query({
      query: ({ tenantId, plantId }) => ({
        url: `SkyfriSidemApi/v1/QueryTwin/ReadDtMCRIncomerPanel?tenantID=${tenantId}&plantId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getStringInfo: builder.query({
      query: ({ tenantId, plantId }) => ({
        url: `SkyfriSidemApi/v1/QueryTwin/ReadDtString?tenantID=${tenantId}&plantId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getWMSInfo: builder.query({
      query: ({ tenantId, plantId }) => ({
        url: `SkyfriSidemApi/v1/QueryTwin/ReadDtWms?tenantID=${tenantId}&plantId=${plantId}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
  }),
});

export const {
  useGetPortfoliosAndPlantsQuery,
  useGetPlantSiteInfoQuery,
  useGetInvertersInfoQuery,
  useGetICROGPanelInfoQuery,
  useGetMCRIncomerPanelInfoQuery,
  useGetStringInfoQuery,
  useGetWMSInfoQuery,
} = plantSiteInfoApi;
