import React from "react";
import { CustomTooltip } from "./CustomTooltip.component";
import { useInverterTemperature } from "./useInverterTemperature";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";
import { MultilineChartWidgetForNames } from "../../../../components/MultilineChartWidgetForNames";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const InverterTemperatureWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
}) => {
  const { data, lines, error, loading } = useInverterTemperature({
    plantId,
    date,
  });
  const useStyles = makeStyles((theme) => ({
    Widget: {
      marginRight: theme.spacing(2),

      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
      },
    },
  }));
  const classes = useStyles();
  return (
    <>
      {
        loading || error !== "" ?
          <Widget label="Inverter Internal temperature (°C)" size={12} height={300}>
            <Loader loading={loading} height={300} error={error} errorTextSize={32} />
          </Widget>

          :
          <MultilineChartWidgetForNames
            title="Inverter Internal temperature (°C)"
            lines={lines}
            data={data}
            loading={loading}
            error={error}
            height={500}
            size={12}
            tooltipContent={<CustomTooltip />}
            definition={definition}
            styles={classes.Widget}
          />
      }
    </>
  );
};
