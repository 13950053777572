import { ChartTypes } from './../../components/CardsCharts/interfaces';

import { Field } from "../../components/Fields";
import { Widget } from "../../components/CardsPageWidget";
import { DropdownOption } from "../../../shared/components";

interface ClientItem {
  actualAvailability: number;
  actualCuf: number;
  actualGen: number;
  actualIrradiation: number;
  actualPR: number;
  availabilityDelta: number;
  budgetAvailabilty: number;
  budgetCuf: number;
  budgetGen: number;
  budgetIrradiation: number;
  budgetPR: number;
  client: string;
  energyDelta: number;
  genDifference: number;
  imageUrl: string;
  irradationDelta: number;
  gridAvailability: number;
  numberofAlarms: number;
  plants: number;
  prDelta: number;
  portfolioName: string;
  totalDCMW: number;
}

const getFields = (item: ClientItem): Array<Field> => [
  {
    title: "Plants",
    value: item.plants,
  },
  {
    title: "Capacity DC",
    value: item.totalDCMW?.toFixed(1) || 0,
    unit: "MWp",
  },
  {
    title: "Generation",
    value:
      item.actualGen.toString().length > 4
        ? ` ${(item.actualGen / 1000)?.toFixed(1)}`
        : ` ${item.actualGen?.toFixed(1)}`,
    growth: item.energyDelta * 100,
    unit: item.actualGen.toString().length > 4 ? "MWh" : "KWh",
  },
  {
    title: "Irradiation",
    value: item.actualIrradiation?.toFixed(1),
    growth: item.irradationDelta * 100,
    unit: "kWh/m²",
  },
  {
    title: "PR",
    value: (item.actualPR * 100)?.toFixed(1),
    // prevValue: (item.budgetPR * 100)?.toFixed(1) + '%'
    growth: item.prDelta * 100,
    unit: "%",
  },
  {
    title: "Availability",
    value: (item.actualAvailability * 100)?.toFixed(1),
    growth: item.availabilityDelta * 100,
    unit: "%",
  },
  {
    title: "CUF/PLF",
    value:
      typeof item.actualCuf === "number"
        ? (item.actualCuf * 100)?.toFixed(1)
        : "N/A",
    unit: typeof item.actualCuf === "number" ? "%" : "",
    growth: Number(((item?.actualCuf - item?.budgetCuf) * 100).toFixed(2)),
    // prevValue: typeof item.budgetCuf === 'number' ? (item.budgetCuf * 100)?.toFixed(1) + '%' : 'N/A'
  },
  {
    title: "Grid Availability",
    value: (item.gridAvailability * 100)?.toFixed(1) || 0,
    unit: "%",
  },
];

export const getWidgets = (items: Array<ClientItem>): Array<Widget> =>
  items.map((item) => ({
    path: item.portfolioName,
    profit: parseInt(item.genDifference?.toFixed(1)),
    prDelta: item?.prDelta,
    cufDelta: (item?.actualCuf - item?.budgetCuf).toFixed(2),
    imageUrl: (item.imageUrl || "").replace(/\s/g, ""),
    actualResult: item.actualPR * 100,
    expectedResult: item.budgetPR * 100,
    chartType: ChartTypes.Bullet,
    chartData: {
      actualResult: item.actualPR * 100,
      expectedResult: item.budgetPR * 100,
    },
    title: item.portfolioName,
    fields: getFields(item),
    errors: item.numberofAlarms,
  }));

export const sortByOptions = [
  'Performance',
  'Revenue',
  'Issues',
  'Date created',
];

export const showOptions = [
  'Performance',
  'Financial information',
];

export const formatTypes: Array<DropdownOption> = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Last 7 days', value: 'Last7days' },
  { label: 'MTD', value: 'MTD' },
  { label: 'QTD', value: 'QTD' },
  { label: 'YTD', value: 'YTD' },
  { label: 'FYTD', value: 'FYTD' },
];

export const fakeFormatTypes: Array<DropdownOption> = [
  { label: 'MTD', value: 'MTD' },
  { label: 'QTD', value: 'QTD' },
  { label: 'YTD', value: 'YTD' },
  { label: 'FYTD', value: 'FYTD' },
];

export const aggregationTypes: Array<DropdownOption> = [
  { label: 'No Aggregation', value: 'No Aggregation' },
  // { label: '1 min', value: '1m' },
  { label: '5 min', value: '5m' },
  { label: '30 min', value: '30m' },
  { label: '1 hr', value: '1h' },

];
