import { useEffect, useLayoutEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { SelectorDropdown } from '../../../../shared/components'
import { useStyles } from '../styles';
import { areAllSame, changeNullToString } from '../../../../shared/utils/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CustomAccordionProps {
    title: string;
    dropdownOptions: {
        label: string;
        value: string;
    }[];
    dropdownOptionsParent: {
        label: string;
        value: string;
    }[];
    plants: any;
    initialCheckedState: boolean[];
    initialDropdownValues: string[];
    setDropDownSate: (value: string[]) => void;
    setCheckedState: (value: boolean[]) => void;
    optionSubtitle?: boolean;
    dropdownDisabled?: boolean;
    dropdownVals?: string[];
}

export const CustomAccordion = (props: CustomAccordionProps) => {
    const {
        title,
        dropdownOptions,
        plants,
        initialCheckedState,
        initialDropdownValues,
        dropdownOptionsParent,
        setCheckedState,
        setDropDownSate,
        optionSubtitle = false,
        dropdownVals,
    } = props;

    const [expanded, setExpanded] = useState(false);
    const [checked, setChecked] = useState(initialCheckedState);
    const [dropdownValues, setDropdownValues] = useState<string[]>(initialDropdownValues);
    const [dropdownValuesParent, setDropdownValuesParent] = useState<string>("");
    const classes = useStyles();

    useLayoutEffect(() => {
        if (!dropdownVals || !dropdownVals?.length) return;
        setChecked(initialCheckedState);
        setDropdownValues(changeNullToString(dropdownVals, "1"));
    }, [plants])

    useEffect(() => {
        if (!dropdownValues?.length) return;
        if (!areAllSame(dropdownValues))
            setDropdownValuesParent("Multiple versions");
        else
            setDropdownValuesParent(dropdownValues?.[0]);
    }, [dropdownValues])

    useEffect(() => {
        if (!dropdownValues?.length) return;
        setDropDownSate(dropdownValues);
    }, [dropdownValues])

    useEffect(() => {
        if (!checked?.length) return;
        setCheckedState(checked);
    }, [checked])

    const handleToggleAccordion = () => {
        setExpanded(!expanded);
    };

    const handleChangeParent = (event) => {
        const updatedCheckedState = Array(plants?.length).fill(event.target.checked);
        setChecked(updatedCheckedState);
    };

    const handleChangeChild = (index) => (event) => {
        const updatedCheckedState = [...checked];
        updatedCheckedState[index] = event.target.checked;
        setChecked(updatedCheckedState);
    };

    const handleChangeDropdown = (childIndex: number) => (selectedValue: string) => {
        const updatedDropdownValues = [...dropdownValues];
        updatedDropdownValues[childIndex] = selectedValue;
        setDropdownValues(updatedDropdownValues);
    };

    const handleChangeDropdownParent = (selectedValue: string) => {
        if (selectedValue === "Multiple versions") return;
        const updatedCheckedState = [];
        for (let i = 0; i < (plants ? plants.length : 0); i++) {
            if (checked[i] === true)
                updatedCheckedState.push(selectedValue);
            else
                updatedCheckedState.push(dropdownValues[i]);
        }
        setDropdownValuesParent(selectedValue);
        setDropdownValues(updatedCheckedState);
    };

    return (
        <Accordion elevation={0} expanded={expanded} sx={{
            backgroundColor: "transparent",
        }}>
            <AccordionSummary
                style={{ cursor: "default", }}
                expandIcon={<ExpandMoreIcon onClick={handleToggleAccordion} style={{ cursor: 'pointer' }} />}
                onClick={(event) => event.stopPropagation()} // Prevent the click event from bubbling up to the parent Accordion
            >
                <Grid container onClick={(event) => event.stopPropagation()} >
                    <Grid item xs={4} sx={{ display: "flex" }} alignItems={"center"}>
                        <div className={classes.accordionSubtitle}>
                            {title}
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div>
                            <FormControlLabel
                                label={<Typography sx={{ fontFamily: "Inter", fontSize: "15px", fontWeight: 500 }}>All sites</Typography>}
                                control={
                                    <Checkbox
                                        checked={checked?.every((value) => value)}
                                        indeterminate={!checked?.every((value) => value) && checked?.some((value) => value)}
                                        onChange={handleChangeParent}
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <SelectorDropdown
                                value={dropdownValuesParent}
                                options={dropdownOptionsParent}
                                onChangeValue={handleChangeDropdownParent}
                                marginRight="0px"
                                border
                                minWidth='180px'
                                verticalAnchorOrigin={"bottom"}
                                horizontalAnchorOrigin={"center"}
                                verticalTransformOrigin={"top"}
                                horizontalTransformOrigin={"center"}
                                optionSubtitle={optionSubtitle}
                                disabled={checked?.every((value) => value === false)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "69px",
                }}>
                <Grid container>
                    <Grid item xs={4} alignItems={"center"}>
                        &nbsp;
                    </Grid>

                    <Grid item xs={8}>
                        <div>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {plants?.map((plant, index) => (
                                    <Grid container sx={{ paddingY: "10px", borderTop: "1px solid #E1E4E9", }}>
                                        <Grid item xs={8} sx={{ paddingLeft: "10px" }}>
                                            <FormControlLabel
                                                key={index}
                                                label={<Typography sx={{ fontFamily: "Inter", fontSize: "15px", fontWeight: 500 }}>{plant?.plantName}</Typography>}
                                                control={<Checkbox checked={checked?.[index]} onChange={handleChangeChild(index)} />}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{ display: "flex", alignItems: "center", fontFamily: "Inter" }}>
                                            {
                                                <SelectorDropdown
                                                    key={index}
                                                    options={dropdownOptions}
                                                    value={dropdownValues[index]}
                                                    onChangeValue={handleChangeDropdown(index)}
                                                    marginRight="0px"
                                                    border
                                                    minWidth='180px'
                                                    verticalAnchorOrigin={"bottom"}
                                                    horizontalAnchorOrigin={"center"}
                                                    verticalTransformOrigin={"top"}
                                                    horizontalTransformOrigin={"center"}
                                                    optionSubtitle={optionSubtitle}
                                                    disabled={checked[index] === false || checked[index] === null}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion >
    )
}
