import axios from "axios";
import { errorReducer } from "../features/Notifications/errorActions";
import * as errorSlice from "../features/Notifications/errorSlice";
import rootStore from "./rootStore";
import { getBearerToken } from "./authToken";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";
// Axios GET Request
export const axGet = async (url: string, accessToken?: string) => {
  const token = await getBearerToken();
  try {
    const res = await axios({
      method: "get",
      url: BASE_API_URL + url,
      headers: {
        authorization: accessToken ? "Bearer " + accessToken : token,
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
        "Content-Type": "text/json",
      },
    });
    rootStore.dispatch(errorSlice.resetError());
    return res.data;
  } catch (error) {
    console.error(error);
    rootStore.dispatch(
      errorReducer(error.response.status, error.response.data.error)
    );
    throw error;
  }
};

// Axios POST Request
export const axPost = async (url, data, accessToken?: string) => {
  const token = await getBearerToken();
  // if (!token) return;
  // path = route + new URLSearchParams(body);
  try {
    const res = await axios({
      method: "post",
      url: BASE_API_URL + url,
      headers: {
        authorization: accessToken ? "Bearer " + accessToken : token,
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
        "Content-Type": "text/json",
      },
      data,
      withCredentials: true,
    });
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
// Axios PATCH Request
export const axPatch = async (url, data, accessToken?: string) => {
  const token = await getBearerToken();

  const res = await axios({
    method: "put",
    url: BASE_API_URL + url,
    headers: {
      authorization: accessToken ? "Bearer " + accessToken : token,
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
      "Content-Type": "text/json",
    },
    data,
    withCredentials: true,
  });
  return res;
};
// Axios DELETE Request
export const axDelete = async (url, data, accessToken?: string) => {
  const token = await getBearerToken();
  // if (!token) return;
  const res = await axios({
    method: "delete",
    url: BASE_API_URL + url,
    headers: {
      authorization: accessToken ? "Bearer " + accessToken : token,
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
      "Content-Type": "text/json",
    },
    data,
    withCredentials: true,
  });
  return res;
};
