import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dropdown: {
    backgroundColor: "white",
    position: "absolute",
    zIndex: 9999,
    width: "150px",
    height: "100px",
    boxShadow: "4px 6px 24px rgba(12, 30, 51, 0.2)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    cursor: "pointer",
  },
  options: {
    width: "100%",
    height: "27px",
    display: "flex",
    alignItems: "center",
    marginleft: "-9px !important",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "rgba(12, 30, 51, 1)",
    padding: "4px 0 0 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(232, 236, 242, 1)",
    },
  },
  optionLabel: {
    marginLeft: "5px",
  },
}));
