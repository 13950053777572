import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { GRAPH_COLORS } from "../../../shared/utils/graphColors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: theme.spacing(2.7),
    marginBottom: theme.spacing(1),
    width: "100%",
    position: "relative",
  },

  line1: {
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(1.25),
    left: 0,
    height: theme.spacing(0.5),
  },

  line3: {
    position: "absolute",
    top: theme.spacing(0.5),
    left: "0",
    height: theme.spacing(3),
    background: GRAPH_COLORS.neutral,
    width: "100%",
    opacity: 0.8,
  },

  line4: {
    position: "absolute",
    top: 4,
    height: theme.spacing(3),
  },
}));

interface CandleChartProps {
  actualResult: number;
  expectedResult: number;
}

const CandleChart: React.FC<CandleChartProps> = (props: CandleChartProps) => {
  const classes = useStyles();

  const { actualResult, expectedResult } = props;
  const diff = actualResult - expectedResult;

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            color: "black",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "& .MuiTooltip-arrow": {
              color: "common.white",
            },
          },
        },
      }}
      title={
        <span>
          <b>Actual PR</b>:{actualResult.toFixed(2)}% <br />
          <b>Expected PR</b>:{expectedResult.toFixed(2)}%
        </span>
      }
      placement="top-end"
    >
      <div className={classes.wrapper}>
        <div
          className={classes.line1}
          style={{ width: `${Math.min(actualResult, 100)}%` }}
        />
        <div className={classes.line3} />
        <div
          className={classes.line4}
          style={{
            background: diff > 0 ? GRAPH_COLORS.success : GRAPH_COLORS.error,
            right: `${100 - (actualResult > expectedResult ? actualResult : expectedResult)}%`,
            width: `${Math.abs(diff)}%`,
          }}
        />
      </div>
    </Tooltip>
  );
};

export default CandleChart;
