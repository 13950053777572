import {
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { getBearerToken } from "../../../store/authToken";
import {
  AlarmNotificationResponse,
  AnalyticsPlantsCardArgs,
  AnalyticsPlantsCardResponse,
  AnalyticsPlantsCardTransformedResponse,
  AnalyticsSummaryCardGenerationArgs,
  AnalyticsSummaryCardGenerationResponse,
  AnalyticsSummaryCardGenerationTransformedResponse,
  AnalyticsSummaryCardProductionArgs,
  AnalyticsSummaryCardProductionResponse,
  AnalyticsSummaryCardProductionTransformedResponse,
  AnalyticsSummaryIrradDeltaArgs,
  AnalyticsSummaryIrradDeltaResponse,
  AnalyticsSummaryIrradDeltaTransformedResponse,
  ClickEvent,
  GenerationGraphDataArgs,
  GenerationGraphDataResponse,
  GenerationGraphDataTransformedResponse,
  GenerationGraphPeriodDataArgs,
  GenerationGraphPeriodDataResponse,
  GenerationGraphPeriodDataTransformedResponse,
  ImageCoordinatesArgs,
  ImageCoordinatesResponse,
  InverterKPIGraphArgs,
  InverterKPIGraphData,
  InverterKPIGraphResponse,
  InverterKPIGraphTransformedResponse,
  KpiData,
  MostRecentAlertsByPlantsArgs,
  MostRecentAlertsByPlantsResponse,
  MppAcPowervsIrradiationArgs,
  MppAcPowervsIrradiationResponse,
  MppEnergyVsIrradiationArgs,
  MppEnergyVsIrradiationResponse,
  MppEquipCardArgs,
  MppEquipCardResponse,
  MppGenPageKpiArgs,
  MppGenPageKpiResponse,
  MppGenPageKpiTransformedResponse,
  MppInverterSpecificEnergyHeatMapArgs,
  MppInverterSpecificEnergyHeatMapResponse,
  MppInverterSpecificEnergyvsPRArgs,
  MppInverterSpecificEnergyvsPRResponse,
  MppInverterSpecificPowerHeatmapArgs,
  MppInverterSpecificPowerHeatmapResponse,
  MppIrradianceComparisonArgs,
  MppIrradianceComparisonResponse,
  MppIsolationComparisonArgs,
  MppIsolationComparisonResponse,
  MppIsolationComparisonTransformedResponse,
  MppIsolationComparisonTransformedResponseData,
  MppKPIArgs,
  MppKPIResponse,
  MppKpiAlarm,
  MppMeteoCardArgs,
  MppMeteoCardResponse,
  MppPlantOverviewKPIArgs,
  MppPlantOverviewKPIResponse,
  MppStatusBarArgs,
  MppStatusBarResponse,
  OverviewKPIArgs,
  OverviewKPIResponse,
  PlantCapacityArgs,
  PlantCapacityResponse,
  PlantProfileArgs,
  PlantProfileResponse,
  PlantStatus,
  PortfolioAlarmsCountArgs,
  PortfoliosAndPlantsArgs,
  PortfoliosAndPlantsResponse,
  SummaryDropdownArgs,
  SummaryDropdownRespone,
  heatMapColors,
} from "./sidemSharedApi.types";
import {
  GLOBAL_COLORS,
  addZeroToTimeIfMissing,
  convertTime,
  convertkWToMW,
  getQueryParams,
  isAnyObjectValueNull,
  kWOrMW,
  kWhOrMWh,
  minsAgo,
  totalTimeAgo,
} from "../../../shared/utils/utils";
import { XAxis, YAxis } from "../../../analytics/components/interfaces";
import {
  getAlarmState,
  getAlarmStateForPortfolios,
} from "../../../analytics/pages/AnalyticsDashboard/components/PlantsWidget/PlantWidget.utils";
import { setMultiplePlantsAction } from "../../Analytics/shared/analyticsSharedActions";
import {
  plantsError,
  plantsStart,
  plantsSuccess,
} from "../portfolios/plants/plantsSlice";
import { colors } from "../../../monitoring/pages/MonitoringPageView/components/GraphsWidget/utils";
import { getPlantTimeZone } from "../../Monitoring/monitoringDashboard/monitoringDashboardSlice";
import {
  checkAlarmState,
  checkCommunication,
} from "../../../monitoring/pages/MonitoringDashboard/components/AssetsOverview/utils";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1/`;

const removeDuplicates = (arr: unknown[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

const transformIrradDeltaResponse = (
  response: AnalyticsSummaryIrradDeltaResponse[]
): any => {
  const yAxis = [
    {
      //--- Primary yAxis
      visible: false,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "%",
      },
    },
  ];

  const xLabels = [];
  const energyDelta: number[] = [];
  const irradationDelta: number[] = [];

  response.forEach((item) => {
    xLabels.push(convertTime(item?.sensorTimeLocal, "D MMM"));
    energyDelta.push(item?.genDelta);
    irradationDelta.push(item?.irradDelta);
  });

  const xAxis: XAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
    visible: false,
  };

  const data = [
    {
      name: "Energy Delta",
      data: energyDelta,
      yAxis: 0,
      tooltip: {
        valueSuffix: "%",
      },
    },
    {
      name: "Irradation Delta",
      data: irradationDelta,
      yAxis: 0,
      tooltip: {
        valueSuffix: "%",
      },
    },
  ];

  return {
    generationIrradiationData: data,
    xAxis: xAxis,
    yAxis: yAxis,
  };
};

const transformSummaryCardGenerationResponse = (
  response: AnalyticsSummaryCardGenerationResponse[]
) => {
  const yAxis = [
    {
      //--- Primary yAxis
      visible: false,
      opposite: false,
      allowDecimals: true,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "",
      },
    },
    {
      visible: false,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "",
      },
    },
  ];

  const xLabels = [];
  const generation = [];
  const irradation = [];

  response?.forEach((item) => {
    xLabels.push(convertTime(item?.sensorTimeLocal, "D MMM"));
    generation.push(item?.generation);
    //   TODO: kaz shouldn't it be parseFloat?
    irradation.push(parseInt(item?.insolation));
  });

  const xAxis: XAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
    visible: false,
  };

  const data = [
    {
      name: "Generation",
      data: generation,
      yAxis: 0,
      tooltip: {
        valueSuffix: "kWh",
      },
      type: "column",
    },
    {
      name: "Irradation",
      data: irradation,
      yAxis: 1,
      tooltip: {
        valueSuffix: "kWh/m²",
      },
      type: "line",
    },
  ];

  return {
    data,
    xAxis,
    yAxis,
  };
};

const transformSummaryCardProductionResponse = (
  response: AnalyticsSummaryCardProductionResponse[]
) => {
  const yAxis = [
    {
      //--- Primary yAxis
      visible: false,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "%",
      },
    },
  ];

  const xLabels = [];
  const productionFactor = [];

  response?.forEach((item) => {
    xLabels.push(convertTime(item?.sensorTimeLocal, "D MMM"));
    productionFactor.push(item?.prodFactor);
  });

  const xAxis: XAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
    visible: false,
  };

  const data = [
    {
      name: "Production factor",
      data: productionFactor,
      yAxis: 0,
      tooltip: {
        valueSuffix: "%",
      },
    },
  ];

  return {
    data,
    xAxis,
    yAxis,
  };
};

const transformGetInverterKPIGraphResponse = (
  response: InverterKPIGraphResponse
) => {
  const screenSize = window.screen.width;

  const xLabels = [];
  const efficiency = [];
  const generation = [];
  const inverterYield = [];
  const prData = [];
  const downTime = [];

  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: screenSize <= 425 ? false : true,
      opposite: false,
      allowDecimals: true,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh/kWp",
      },
    },
    {
      //--- Primary yAxis
      visible: screenSize <= 425 ? false : true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh",
      },
    },
    {
      labels: {
        format: "{text}%",
      },
      // min: 0,
      visible: screenSize <= 425 ? false : true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "%",
      },
    },
    {
      //--- Primary yAxis
      visible: screenSize <= 425 ? false : true,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "min",
      },
    },
  ];

  response?.forEach((item) => {
    xLabels.push(`Inverter ${item?.blockId}.${item?.inverterId}`);
    efficiency.push(item?.efficiency);
    generation.push({
      y: item?.generation,
      color: item?.downtime > 0 ? GLOBAL_COLORS.CHART_ERROR_BAR : colors[0],
    });
    inverterYield.push(item?.yield);
    prData.push(item?.prValue);
    downTime.push(item?.downtime);
  });

  const xAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };
  const data: InverterKPIGraphData[] = [
    {
      name: "Generation",
      data: generation,
      tooltip: {
        valueSuffix: "kWh",
      },
      type: "column",
      yAxis: 1,
    },
    {
      yAxis: 1,
      name: "Gen down",
      color: GLOBAL_COLORS.CHART_ERROR_BAR,
      tooltip: {
        valueSuffix: "min",
      },
      marker: {
        enabled: true,
        radius: 9,
      },
      lineWidth: 0,
    },
    {
      name: "Yield ",
      data: inverterYield,
      tooltip: {
        valueSuffix: "kWh/kWp",
      },
      type: "line",
      yAxis: 0,
    },
    {
      name: "PR",
      data: prData,
      yAxis: 2,
      tooltip: {
        valueSuffix: "%",
      },
      type: "line",
    },
    {
      name: "Efficiency",
      data: efficiency,
      yAxis: 2,
      tooltip: {
        valueSuffix: "%",
      },
      type: "line",
    },
    {
      name: "Downtime",
      data: downTime,
      yAxis: 3,
      tooltip: {
        valueSuffix: "min",
      },
      type: "line",
    },
  ];

  const transformedResponse: InverterKPIGraphTransformedResponse = {
    data,
    xAxis,
    yAxis,
  };

  return transformedResponse;
};

const transformAnalyticsPlantsCardResponse = (
  response: AnalyticsPlantsCardResponse
) => {
  const data = response?.plantData?.map((item) => {
    return {
      ...item,
      alarms: getAlarmStateForPortfolios(item?.numberOfAlarms),
      plant: item?.plant?.map((plant) => {
        return {
          ...plant,
          alarms: getAlarmState(plant?.plantStatus, plant?.numberOfAlarms),
          lastUpdated: totalTimeAgo(plant?.lastUpdated, plant?.timeZone),
        };
      }),
    };
  });

  return {
    multipleTimeZones: response?.multipleTimeZones,
    data,
  };
};

const transformGenerationGraphResponse = (
  response: GenerationGraphDataResponse | GenerationGraphPeriodDataResponse,
  _meta: FetchBaseQueryMeta,
  args: GenerationGraphDataArgs | GenerationGraphPeriodDataArgs
) => {
  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "",
      },
    },
  ];

  const xLabels = [];
  const consumption = [];
  const gridExport = [];
  const gridImport = [];
  const pvGen = [];

  response?.forEach((item) => {
    xLabels.push(
      args.dateEqual
        ? convertTime(item?.sensorTimeLocal, "HH:mm")
        : convertTime(item?.sensorTimeLocal, "DD MMM")
    );

    consumption.push(item?.consumption);
    gridExport.push(item.gridExport);
    gridImport.push(item.gridImport);
    pvGen.push(item.pvGen);
  });

  const xAxis: XAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  const data = [
    {
      name: "PV generation",
      data: pvGen,
      tooltip: {
        valueSuffix: "kWh",
      },
    },
    {
      name: "Consumption",
      data: consumption,
      tooltip: {
        valueSuffix: "kWh",
      },
    },
    {
      name: "Grid export",
      data: gridExport,
      tooltip: {
        valueSuffix: "kWh",
      },
    },
    {
      name: "Grid import",
      data: gridImport,
      tooltip: {
        valueSuffix: "kWh",
      },
    },
  ];

  return {
    data,
    xAxis,
    yAxis,
  };
};

const transformMppGenPageKpiResponse = (
  response: MppGenPageKpiResponse
): MppGenPageKpiTransformedResponse => {
  const data = response[0] ? response[0] : null;
  const missingData = data ? isAnyObjectValueNull(data) : false;
  const lastUpdated = data ? minsAgo(data?.lastUpdated, data?.timeZone) : 0;

  const error = data === null || !response?.length;

  const result: MppGenPageKpiTransformedResponse = {
    data,
    missingData,
    lastUpdated,
    error,
  };

  return result;
};

const transformMppIsolationComparisonResponse = (
  response: MppIsolationComparisonResponse
) => {
  const yAxis = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh/m²",
      },
    },
  ];

  let equipNames = [];
  let times = [];
  let paramNames = [];

  response?.forEach((item) => {
    times.push(convertTime(item?.date, "D MMM"));
    equipNames.push(item?.equipmentName);
    paramNames.push(item?.variable);
  });

  times = removeDuplicates(times);
  equipNames = removeDuplicates(equipNames);
  paramNames = removeDuplicates(paramNames);

  const xAxis: XAxis = {
    categories: times,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  let data: MppIsolationComparisonTransformedResponseData[] = [];

  equipNames?.forEach((equipment) => {
    paramNames?.forEach((parameter) => {
      data.push({
        name: `${equipment} ${parameter}`,
        data: response
          ?.map((item) => {
            if (equipment === item.equipmentName && parameter === item.variable)
              return item?.value;
          })
          .filter(function (element) {
            return element !== undefined;
          }),
        yAxis: 0,
        tooltip: {
          valueSuffix: "kWh/m²",
        },
      });
    });
  });

  data = data.filter((item) => !item.data.every((el) => el === null));

  return {
    data,
    xAxis,
    yAxis,
  };
};

const transformMppEnergyVsIrradiationResponse = (
  response: MppEnergyVsIrradiationResponse
) => {
  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh",
      },
    },
    {
      visible: true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh/m²",
      },
    },
  ];

  const xAxis: XAxis = {
    categories: response?.map((item) => convertTime(item?.date, "D MMM")),
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  const data = [
    {
      name: "Energy",
      data: response?.map((item) => item?.generation),
      tooltip: {
        valueSuffix: "kWh",
      },
      type: "column",
    },
    {
      name: "Insolation",
      data: response?.map((item) => item?.irradiation),
      yAxis: 1,
      tooltip: {
        valueSuffix: "kWh/m²",
      },
      type: "line",
    },
  ];
  return {
    data,
    xAxis,
    yAxis,
  };
};

export type MppEnergyVsIrradiationTransformedResponse = ReturnType<
  typeof transformMppEnergyVsIrradiationResponse
>;

const transformMppInverterSpecificEnergyvsPRResponse = (
  response: MppInverterSpecificEnergyvsPRResponse,
  _meta: FetchBaseQueryMeta,
  args: MppInverterSpecificEnergyvsPRArgs
) => {
  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: false,
      allowDecimals: true,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kWh/kWp",
      },
    },
    {
      labels: {
        format: "{text}%", // The % is literally a percentage unit
      },
      visible: true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "",
      },
    },
  ];

  const xLabels = [];
  const energyData = [];
  const prData = [];

  response?.forEach((item) => {
    xLabels.push(`${item?.blockId}.${item?.inverterId}`);
    energyData.push({
      y: item?.specificEnergy,
      color: item?.isUnderperforming
        ? GLOBAL_COLORS.CHART_ERROR_BAR
        : colors[0],
    });
    prData.push(item?.inverterPR);
  });

  const xAxis: XAxis = {
    categories: xLabels,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  const data = [
    {
      name: "Inverter specific energy",
      data: energyData,
      tooltip: {
        valueSuffix: "kWh/kWp",
      },
      yAxis: 0,
      type: "column",
      events: {
        click: function (event: ClickEvent) {
          args.onClickEventSet(event);
        },
      },
      cursor: "pointer",
    },
    {
      name: "Underperform. specific energy",
      color: GLOBAL_COLORS.CHART_ERROR_BAR,
      yAxis: 0,
      marker: {
        enabled: true,
        radius: 9,
      },
      lineWidth: 0,
    },
    {
      name: "Inverter PR",
      data: prData,
      yAxis: 1,
      tooltip: {
        valueSuffix: "%",
      },
      type: "line",
    },
  ];

  return {
    xAxis,
    yAxis,
    data,
  };
};

export type MppInverterSpecificEnergyvsPRTransformedResponse = ReturnType<
  typeof transformMppInverterSpecificEnergyvsPRResponse
>;

const transformMppStatusBarResponse = (response: MppStatusBarResponse) => {
  return {
    statusError: !response.length,
    statusData: response[0] || null,
    genData: {
      acCapacityMW: response[0]?.acCapacityMW,
      dcCapacityMWp: response[0]?.dcCapacityMWp,
      locationName: response[0]?.locationName,
      timeZone: response[0]?.timeZone,
    },
  };
};

export type MppStatusBarTransformedResponse = ReturnType<
  typeof transformMppStatusBarResponse
>;

const transformMppInverterSpecificEnergyHeatMapResponse = (
  response: MppInverterSpecificEnergyHeatMapResponse
) => {
  let yLabels = [];
  let times = [];
  const data = [];

  response?.forEach((item, i) => {
    data.push(
      item?.map((element, j) => {
        times.push(convertTime(element?.date, "D MMM"));
        yLabels.push(`${element?.blockId}.${element?.inverterId}`);
        return {
          x: i,
          y: j,
          value: element.specificEnergy,
          name: `${element?.blockId}.${element?.inverterId}`,
          type: "Specific energy",
          color: heatMapColors[element?.severity],
        };
      })
    );
  });

  times = removeDuplicates(times);
  yLabels = removeDuplicates(yLabels);

  const yAxis: YAxis = {
    //--- Primary yAxis
    categories: yLabels,
    visible: true,
    opposite: false,
    allowDecimals: false,
    type: "linear",
    gridLineWidth: 0,
    title: {
      text: "",
    },
    labels: {
      format: "{value}",
      style: {
        color: "black",
      },
    },
  };

  const xAxis: XAxis = {
    categories: times,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  const result = {
    data: data.flat(),
    xAxis,
    yAxis,
  };

  return result;
};

export type MppInverterSpecificEnergyHeatMapTransformedResponse = ReturnType<
  typeof transformMppInverterSpecificEnergyHeatMapResponse
>;

const transformMppInverterSpecificPowerHeatmapResponse = (
  response: MppInverterSpecificPowerHeatmapResponse
) => {
  let yLabels = [];
  let times = [];
  const data = [];

  response?.forEach((item, i) => {
    data.push(
      item?.map((element, j) => {
        times.push(convertTime(element?.sensorTime, "HH:mm"));
        yLabels.push(`${element?.blockId}.${element?.inverterId}`);
        return {
          x: i,
          y: j,
          value: element.specificPower,
          name: `${element?.blockId}.${element?.inverterId}`,
          type: "Specific power",
          color: heatMapColors[element?.severity],
        };
      })
    );
  });

  times = removeDuplicates(times);
  yLabels = removeDuplicates(yLabels);

  const yAxis: YAxis = {
    //--- Primary yAxis
    categories: yLabels,
    visible: true,
    opposite: false,
    allowDecimals: false,
    type: "linear",
    gridLineWidth: 0,
    title: {
      text: "",
    },
    labels: {
      format: "{value}",
      style: {
        color: "black",
      },
    },
  };

  const xAxis: XAxis = {
    categories: times,
    endOnTick: true,
    showLastLabel: true,
    startOnTick: false,
  };

  return {
    data,
    xAxis,
    yAxis,
  };
};

export type MppInverterSpecificPowerHeatmapTransformedResponse = ReturnType<
  typeof transformMppInverterSpecificPowerHeatmapResponse
>;

const transformMppKpiResponse = (
  response: MppKPIResponse,
  _meta: FetchBaseQueryMeta,
  args: MppKPIArgs
) => {
  const kpiData: KpiData = {
    clientName: response[0]?.clientName,
    portfolioName: response[0]?.portfolioName,
    plantId: response[0]?.plantId,
    actualGen:
      !!response[0]?.actualGen !== null
        ? convertkWToMW(Number(response[0]?.actualGen))?.toFixed(1)
        : "-",
    actualGenUnit:
      response[0]?.actualGen !== null
        ? kWhOrMWh(Number(response[0]?.actualGen))
        : null,
    actualIrradiation:
      response[0]?.actualIrradiation !== null
        ? Number(response[0]?.actualIrradiation).toFixed(1)
        : "-",
    actualIrradiationUnit:
      response[0]?.actualIrradiation !== null ? "kWh/m²" : null,
    actualPR:
      response[0]?.actualPR !== null
        ? (Number(response[0]?.actualPR) * 100)?.toFixed(1)
        : "-",
    actualPRUnit: response[0]?.actualPR !== null ? "%" : null,
    actualAvailability:
      response[0]?.actualAvailability !== null
        ? Number(response[0]?.actualAvailability) * 100 === 100
          ? Number(response[0]?.actualAvailability) * 100
          : (Number(response[0]?.actualAvailability) * 100).toFixed(1)
        : "-",
    actualAvailabilityUnit:
      response[0]?.actualAvailability !== null ? "%" : null,
    gridAvailability:
      response[0]?.gridAvailability !== null
        ? Number(response[0]?.gridAvailability) * 100 === 100
          ? Number(response[0]?.gridAvailability) * 100
          : (Number(response[0]?.gridAvailability) * 100)?.toFixed(1)
        : "-",
    gridAvailabilityUnit: response[0]?.gridAvailability !== null ? "%" : null,
    irradianceLive:
      response[0]?.irradianceLive !== null
        ? Number(response[0]?.irradianceLive).toFixed(1)
        : "-",
    irradianceLiveUnit: response[0]?.irradianceLive !== null ? "W/m²" : null,
    acPowerlive:
      response[0]?.acPowerlive !== null
        ? convertkWToMW(Number(response[0]?.acPowerlive))?.toFixed(1)
        : "-",
    acPowerliveUnit:
      response[0]?.acPowerlive !== null
        ? kWOrMW(Number(response[0]?.acPowerlive))
        : null,
    lastUpdated: response[0]?.lastUpdated,
  };

  const lastUpdated =
    kpiData?.lastUpdated && args.plantTimeZone
      ? totalTimeAgo(kpiData?.lastUpdated, args.plantTimeZone)
      : 0;

  return {
    error: !response?.length,
    kpiData,
    lastUpdated,
  };
};

export type MppKPITransformedResponse = ReturnType<
  typeof transformMppKpiResponse
>;

const transformMppMeteoCardResponse = (response: MppMeteoCardResponse) => {
  const data = {
    clientName: response[0]?.clientName,
    plantId: response[0]?.plantId,
    ambTemp:
      response[0]?.ambTemp !== null ? response[0]?.ambTemp?.toFixed(0) : "-",
    ambTempUnit: response[0]?.ambTemp !== null ? "°C" : null,
    moduleTemp:
      response[0]?.moduleTemp !== null
        ? response[0]?.moduleTemp?.toFixed(0)
        : "-",
    moduleTempUnit: response[0]?.moduleTemp !== null ? "°C" : null,
    raifallCumm:
      response[0]?.raifallCumm !== null
        ? response[0]?.raifallCumm?.toFixed(0)
        : "-",
    raifallCummUnit: response[0]?.raifallCumm !== null ? "%" : null,
    windDirection:
      response[0]?.windDirection !== null
        ? response[0]?.windDirection?.toFixed(0)
        : "-",
    windDirectionUnit: response[0]?.windDirection !== null ? "°" : null,
    windSpeed:
      response[0]?.windSpeed !== null
        ? response[0]?.windSpeed?.toFixed(0)
        : "-",
    windSpeedUnit: response[0]?.windSpeed !== null ? "m/s" : null,
  };
  return {
    data,
    error: !response?.length,
  };
};

export type MppMeteoCardTransformedResponse = ReturnType<
  typeof transformMppMeteoCardResponse
>;

const transformMppPlantOverviewKPIResponse = (
  response: MppPlantOverviewKPIResponse
) => {
  let POError = Boolean(!response?.values?.length);
  let multiplePlants = Boolean(response?.multipleTimeZones);

  const plantData = response?.values?.map((item) => {
    return {
      ...item,
      alarms: checkAlarmState(item?.plantStatus, item?.numberOfAlarms),
      comms: checkCommunication(item?.plantStatus),
      lastUpdated: totalTimeAgo(item?.lastUpdated, item?.timeZone),
      lastUpdatedExact: convertTime(
        item?.lastUpdated,
        "MMM DD, YYYY, HH:mm:ss"
      ),
    };
  });

  return {
    POError,
    multiplePlants,
    plantData,
  };
};

export type MppPlantOverviewKPITransformedResponse = ReturnType<
  typeof transformMppPlantOverviewKPIResponse
>;

const transformPlantCapacityResponse = (response: PlantCapacityResponse) => {
  return response;
};

export type PlantCapacityTransformedResponse = ReturnType<
  typeof transformPlantCapacityResponse
>;

const transformMppEquipCardResponse = (response: MppEquipCardResponse) => {
  return {
    data: {
      clientName: response[0]?.clientName,
      plantId: response[0]?.plantId,
      inverterAvailability:
        response[0].inverterAvailability !== null
          ? response[0].inverterAvailability
          : "-",
      inverterAvailabilityUnit:
        response[0].inverterAvailability !== null ? "%" : null,
      scbAvailability:
        response[0]?.scbAvailability !== null
          ? response[0]?.scbAvailability
          : "-",
      scbAvailabilityUnit: response[0]?.scbAvailability !== null ? "%" : null,
      transformerAvailability:
        response[0]?.transformerAvailability !== null
          ? response[0]?.transformerAvailability
          : "-",
    },
    error: !response?.length,
  };
};

export type MppEquipCardTransformedResponse = ReturnType<
  typeof transformMppEquipCardResponse
>;

const transformMppAcPowervsIrradiationResponse = (
  response: MppAcPowervsIrradiationResponse
) => {
  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "kW",
      },
    },
    {
      visible: true,
      opposite: true,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "W/m²",
      },
    },
  ];

  const xLabels = [];
  const dataPower = [];
  const dataIrradiance = [];

  response?.forEach((item) => {
    xLabels.push(item?.sensorTimeLocal);
    dataPower.push(item?.acPower);
    dataIrradiance.push(item?.poaIrradiance);
  });

  const xAxis: XAxis = {
    type: "category",
    categories: xLabels,
    tickPositioner: function () {
      let tick = Math.floor(this.dataMin);
      const positions = [],
        increment = Math.ceil((this.dataMax - this.dataMin) / 12);
      if (
        this.dataMax !== null &&
        this.dataMin !== null &&
        !this.chart.resetZoomButton
      ) {
        for (tick; tick - increment <= this.dataMax; tick += increment) {
          positions.push(tick);
        }
        return positions;
      }
    },
    labels: {
      rotation: -45,
      formatter: function () {
        const date = new Date(this.value);
        if (this.chart.resetZoomButton !== undefined) {
          return (
            addZeroToTimeIfMissing(date.getHours()) +
            ":" +
            addZeroToTimeIfMissing(date.getMinutes())
          );
        }
        return (
          addZeroToTimeIfMissing(date.getHours()) +
          ":" +
          addZeroToTimeIfMissing(date.getMinutes())
        );
      },
    },
  };

  const data = [
    {
      name: "AC Power",
      data: dataPower,
      yAxis: 0,
      tooltip: {
        valueSuffix: "kW",
      },
      connectNulls: false,
      dashStyle: "solid",
      color: colors[0],
    },
    {
      name: "AC Power",
      data: dataPower,
      yAxis: 0,
      tooltip: {
        valueSuffix: "kW",
      },
      enableMouseTracking: false,
      connectNulls: true,
      dashStyle: "LongDash",
      linkedTo: ":previous",
      color: colors[0],
    },
    {
      name: "Irradiance (POA)",
      data: dataIrradiance,
      yAxis: 1,
      tooltip: {
        valueSuffix: "W/m²",
      },
      connectNulls: false,
      dashStyle: "solid",
      color: colors[1],
    },
    {
      name: "Irradiance",
      data: dataIrradiance,
      yAxis: 1,
      tooltip: {
        valueSuffix: "W/m²",
      },
      enableMouseTracking: false,
      connectNulls: true,
      dashStyle: "LongDash",
      linkedTo: ":previous",
      color: colors[1],
    },
  ];

  return {
    xAxis,
    yAxis,
    data,
  };
};

export type MppAcPowervsIrradiationTransformedResponse = ReturnType<
  typeof transformMppAcPowervsIrradiationResponse
>;

const transformMppIrradianceComparisonResponse = (
  response: MppIrradianceComparisonResponse
) => {
  const yAxis: YAxis[] = [
    {
      //--- Primary yAxis
      visible: true,
      opposite: false,
      allowDecimals: false,
      type: "linear",
      gridLineWidth: 0,
      title: {
        text: "W/m²",
      },
    },
  ];

  let times = [];
  let dataToSend = [];
  let tempData = [];
  let equipmentNames = [];

  response?.forEach((element) => {
    element.values.forEach((item) => {
      times.push(item?.sensorTime);
      item?.parameters.forEach((para) => {
        equipmentNames.push(`${element.equipmentName} ${para.parameterName}`);
        tempData.push({
          val: para.value,
          name: `${element.equipmentName} ${para.parameterName}`,
        });
      });
    });
  });

  equipmentNames = removeDuplicates(equipmentNames);

  equipmentNames?.forEach((item) => {
    dataToSend.push({
      name: item,
      data: tempData
        .map((element) => {
          if (element.name === item) return element.val;
        })
        .filter((el) => {
          return el !== undefined;
        }),
      yAxis: 0,
      tooltip: {
        valueSuffix: "W/m²",
      },
    });
  });

  times = removeDuplicates(times);

  let newDataToSend = [];

  dataToSend?.forEach((item) => {
    if (item.data.some((el) => el !== null)) newDataToSend.push(item);
  });

  const data = [];

  newDataToSend?.forEach((item, i) => {
    data.push(
      {
        ...item,
        connectNulls: false,
        dashStyle: "solid",
        color: colors[i],
      },
      {
        ...item,
        enableMouseTracking: false,
        connectNulls: true,
        dashStyle: "LongDash",
        linkedTo: ":previous",
        color: colors[i],
      }
    );
  });

  const xAxis: XAxis = {
    type: "category",
    categories: times.sort(),
    tickPositioner: function () {
      let tick = Math.floor(this.dataMin);
      const positions = [],
        increment = Math.ceil((this.dataMax - this.dataMin) / 12);
      if (
        this.dataMax !== null &&
        this.dataMin !== null &&
        !this.chart.resetZoomButton
      ) {
        for (tick; tick - increment <= this.dataMax; tick += increment) {
          positions.push(tick);
        }
        return positions;
      }
    },
    labels: {
      rotation: -45,
      formatter: function () {
        const date = new Date(this.value);
        if (this.chart.resetZoomButton !== undefined) {
          return (
            addZeroToTimeIfMissing(date.getHours()) +
            ":" +
            addZeroToTimeIfMissing(date.getMinutes())
          );
        }
        return (
          addZeroToTimeIfMissing(date.getHours()) +
          ":" +
          addZeroToTimeIfMissing(date.getMinutes())
        );
      },
    },
  };

  return {
    data,
    xAxis,
    yAxis,
  };
};

export type MppIrradianceComparisonTransformedResponse = ReturnType<
  typeof transformMppIrradianceComparisonResponse
>;

export const sidemSharedApi = createApi({
  reducerPath: "sidemSharedApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}${sidemApiUrl}`,
    prepareHeaders: async (headers) => {
      const token = await getBearerToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
        headers.set("Content-Type", "text/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPortfoliosAndPlants: builder.query<PortfoliosAndPlantsResponse, PortfoliosAndPlantsArgs>({
      query: ({ accessType }) => `GetPortfoliosAndPlants${getQueryParams({ accessType })}`,
    }),
    getAnalyticsSummaryCardGenKPI: builder.query<
      SummaryDropdownRespone,
      SummaryDropdownArgs
    >({
      query: ({ plantId, portfolioName, fromDate, toDate }) => ({
        url: `GetAnalyticsSummaryCardGenKPI${getQueryParams({
          plantId,
          portfolioName,
          fromDate,
          toDate,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getAnalyticsSummaryCardGenIrradDelta: builder.query<
      AnalyticsSummaryIrradDeltaTransformedResponse,
      AnalyticsSummaryIrradDeltaArgs
    >({
      query: ({ portfolioName, plantId, period }) => ({
        url: `GetAnalyticsSummaryCardGenIrradDelta${period}D${getQueryParams({
          plantId,
          portfolioName,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformIrradDeltaResponse,
    }),
    getAnalyticsSummaryCardGeneration: builder.query<
      AnalyticsSummaryCardGenerationTransformedResponse,
      AnalyticsSummaryCardGenerationArgs
    >({
      query: ({ portfolioName, plantId, period }) => ({
        url: `GetAnalyticsSummaryCardGeneration${period}D${getQueryParams({
          plantId,
          portfolioName,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformSummaryCardGenerationResponse,
    }),
    getAnalyticsSummaryCardProduction: builder.query<
      AnalyticsSummaryCardProductionTransformedResponse,
      AnalyticsSummaryCardProductionArgs
    >({
      query: ({ portfolioName, plantId, period }) => ({
        url: `GetAnalyticsSummaryCardProduction${period}D${getQueryParams({
          portfolioName,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformSummaryCardProductionResponse,
    }),
    getPortfolioAlarmsCount: builder.query<number, PortfolioAlarmsCountArgs>({
      query: ({ portfolioName }) => ({
        url: `GetPortfolioAlarmsCount${getQueryParams({ portfolioName })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getAnalyticsPlantsCard: builder.query<
      AnalyticsPlantsCardTransformedResponse,
      AnalyticsPlantsCardArgs
    >({
      query: ({ fromDate, toDate, plantId, portfolioName }) => ({
        url: `GetAnalyticsPlantsCard${getQueryParams({
          fromDate,
          toDate,
          plantId,
          portfolioName,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformAnalyticsPlantsCardResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          dispatch(setMultiplePlantsAction(data?.data?.multipleTimeZones));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getPlantProfile: builder.query<PlantProfileResponse, PlantProfileArgs>({
      query: ({ plantId }) => ({
        url: `GetProfileInfo${getQueryParams({ plantId })}`,
        method: "GET",
        cache: "no-cache",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(plantsStart());
        try {
          const data = await queryFulfilled;
          dispatch(plantsSuccess(data?.data));
        } catch (e) {
          dispatch(plantsError(e));
        }
      },
    }),
    getInverterKPIGraph: builder.query<
      InverterKPIGraphTransformedResponse,
      InverterKPIGraphArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppInvPageKpiGraph${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformGetInverterKPIGraphResponse,
    }),
    getPlantImageCoordinates: builder.query<
      ImageCoordinatesResponse,
      ImageCoordinatesArgs
    >({
      query: ({ plantId }) => ({
        url: `GetImageCoordinates${getQueryParams({
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getMostRecentAlertsByPlant: builder.query<
      MostRecentAlertsByPlantsResponse,
      MostRecentAlertsByPlantsArgs
    >({
      query: ({ plantId, top }) => ({
        url: `GetMostRecentAlertsByPlant${getQueryParams({
          plantId,
          top: top ? top : 10,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getOverviewKPI: builder.query<OverviewKPIResponse, OverviewKPIArgs>({
      query: ({ fromDate, toDate, portfoliosName, plantsId }) => ({
        url: `GetMppOverviewKPI${getQueryParams({
          fromDate,
          toDate,
          portfoliosName,
          plantsId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getAlarmNotification: builder.query<AlarmNotificationResponse, void>({
      query: () => `AlarmMailOptIn/IsUserOptedIn`,
    }),
    postAlarmNotification: builder.mutation({
      query: ({ data }) => ({
        url: `AlarmMailOptIn/AdjustOptIn`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "text/json",
        },
      }),
    }),
    getMppGenPageKpi: builder.query<
      MppGenPageKpiTransformedResponse,
      MppGenPageKpiArgs
    >({
      query: ({ fromDate, toDate, portfoliosName, plantsId }) =>
        `GetMppGenPageKpi${getQueryParams({
          fromDate,
          toDate,
          portfoliosName,
          plantsId,
        })}`,
      transformResponse: transformMppGenPageKpiResponse,
    }),
    getGenerationGraphData: builder.query<
      GenerationGraphDataTransformedResponse,
      GenerationGraphDataArgs
    >({
      query: ({ fromDate, portfoliosName, plantsId }) => ({
        url: `GetMppGenPageGenGraph${getQueryParams({
          fromDate,
          portfoliosName,
          plantsId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformGenerationGraphResponse,
    }),
    getGenerationGraphPeriodData: builder.query<
      GenerationGraphPeriodDataTransformedResponse,
      GenerationGraphPeriodDataArgs
    >({
      query: ({ fromDate, toDate, portfoliosName, plantsId }) => ({
        url: `GetMppGenPageGraphPeriod${getQueryParams({
          fromDate,
          toDate,
          portfoliosName,
          plantsId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformGenerationGraphResponse,
    }),
    getMppIsolationComparison: builder.query<
      MppIsolationComparisonTransformedResponse,
      MppIsolationComparisonArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppIsolationComparison${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppIsolationComparisonResponse,
    }),
    getMppEnergyVsIrradiation: builder.query<
      MppEnergyVsIrradiationTransformedResponse,
      MppEnergyVsIrradiationArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppEnergyVsIrradiation${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppEnergyVsIrradiationResponse,
    }),
    getMppStatusBar: builder.query<
      MppStatusBarTransformedResponse,
      MppStatusBarArgs
    >({
      query: ({ portfolioName, plantId }) => ({
        url: `GetMppStatusBar${getQueryParams({
          portfolioName,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),

      transformResponse: transformMppStatusBarResponse,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          const timeZone =
            response?.data?.statusData?.timeZone || "Europe/Oslo";
          dispatch(getPlantTimeZone(timeZone));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getMppInverterSpecificEnergyvsPR: builder.query<
      MppInverterSpecificEnergyvsPRTransformedResponse,
      MppInverterSpecificEnergyvsPRArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppInverterSpecificEnergyvsPR${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppInverterSpecificEnergyvsPRResponse,
    }),
    getInverterSpecificEnergyHeatmap: builder.query<
      MppInverterSpecificEnergyHeatMapTransformedResponse,
      MppInverterSpecificEnergyHeatMapArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppInverterSpecificEnergyHeatMap${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppInverterSpecificEnergyHeatMapResponse,
    }),
    getMppInverterSpecificPowerHeatmap: builder.query<
      MppInverterSpecificPowerHeatmapTransformedResponse,
      MppInverterSpecificPowerHeatmapArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppInverterSpecificPowerHeatmap${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppInverterSpecificPowerHeatmapResponse,
    }),
    getMppKPI: builder.query<MppKPITransformedResponse, MppKPIArgs>({
      query: ({ fromDate, toDate, portfolioName, plantId }) => ({
        url: `GetMppKPI${getQueryParams({
          fromDate,
          toDate,
          portfolioName,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppKpiResponse,
    }),
    getMppMeteoCard: builder.query<
      MppMeteoCardTransformedResponse,
      MppMeteoCardArgs
    >({
      query: ({ fromDate, toDate, portfolioName, plantId }) => ({
        url: `GetMppMeteoCard${getQueryParams({
          fromDate,
          toDate,
          portfolioName,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppMeteoCardResponse,
    }),
    getMppPlantOverviewKPI: builder.query<
      MppPlantOverviewKPITransformedResponse,
      MppPlantOverviewKPIArgs
    >({
      query: ({ fromDate, toDate, portfoliosName, plantsId }) => ({
        url: `GetMppPlantOverviewKPI${getQueryParams({
          fromDate,
          toDate,
          portfoliosName,
          plantsId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppPlantOverviewKPIResponse,
    }),
    getPlantCapacityData: builder.query<
      PlantCapacityTransformedResponse,
      PlantCapacityArgs
    >({
      query: ({ plantsId, portfoliosName }) => ({
        url: `GetMppPlantCapacity${getQueryParams({
          portfoliosName,
          plantsId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
    }),
    getMppEquipCard: builder.query<
      MppEquipCardTransformedResponse,
      MppEquipCardArgs
    >({
      query: ({ fromDate, toDate, portfolioName, plantId }) => ({
        url: `GetMppEquipCard${getQueryParams({
          fromDate,
          toDate,
          portfolioName,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppEquipCardResponse,
    }),
    getMppAcPowervsIrradiation: builder.query<
      MppAcPowervsIrradiationTransformedResponse,
      MppAcPowervsIrradiationArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppAcPowervsIrradiation${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppAcPowervsIrradiationResponse,
    }),
    getMppIrradianceComparison: builder.query<
      MppIrradianceComparisonTransformedResponse,
      MppIrradianceComparisonArgs
    >({
      query: ({ fromDate, toDate, plantId }) => ({
        url: `GetMppIrradianceComparison${getQueryParams({
          fromDate,
          toDate,
          plantId,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: transformMppIrradianceComparisonResponse,
    }),
  }),
});

//GetMppIrradianceComparison

export const {
  useGetPortfoliosAndPlantsQuery,
  useGetAnalyticsSummaryCardGenKPIQuery,
  useGetAnalyticsSummaryCardGenIrradDeltaQuery,
  useGetAnalyticsSummaryCardGenerationQuery,
  useGetAnalyticsSummaryCardProductionQuery,
  useGetPortfolioAlarmsCountQuery,
  useGetAnalyticsPlantsCardQuery,
  useGetPlantProfileQuery,
  useGetInverterKPIGraphQuery,
  useGetPlantImageCoordinatesQuery,
  useGetMostRecentAlertsByPlantQuery,
  useGetOverviewKPIQuery,
  useGetAlarmNotificationQuery,
  usePostAlarmNotificationMutation,
  useGetMppGenPageKpiQuery,
  useGetGenerationGraphDataQuery,
  useGetGenerationGraphPeriodDataQuery,
  useGetMppIsolationComparisonQuery,
  useGetMppEnergyVsIrradiationQuery,
  useGetMppStatusBarQuery,
  useGetMppInverterSpecificEnergyvsPRQuery,
  useGetInverterSpecificEnergyHeatmapQuery,
  useGetMppInverterSpecificPowerHeatmapQuery,
  useGetMppKPIQuery,
  useGetMppMeteoCardQuery,
  useGetMppPlantOverviewKPIQuery,
  useGetPlantCapacityDataQuery,
  useGetMppEquipCardQuery,
  useGetMppAcPowervsIrradiationQuery,
  useGetMppIrradianceComparisonQuery,
} = sidemSharedApi;
