export const graphLines: any = [
  {
    key: "R Phase Current",
    stroke: "red",
    yAxisId: "RPhaseCurrent",
    strokeOpacity: 1,
    index: 0,
  },
  {
    key: "Y Phase Current",
    stroke: "#81dcf2",
    yAxisId: "YPhaseCurrent",
    strokeOpacity: 1,
    index: 1,
  },
  {
    key: "B Phase Current",
    stroke: "#f8b072",
    yAxisId: "BPhaseCurrent",
    strokeOpacity: 1,
    index: 2,
  },

  {
    key: "DC Voltage",
    stroke: "#a874bf",
    yAxisId: "DCVoltage",
    strokeOpacity: 1,
    index: 3,
  },
  {
    key: "DC Power",
    stroke: "#65b165",
    yAxisId: "DCPower",
    strokeOpacity: 1,
    index: 4,
  },
  {
    key: "AC Frequency",
    stroke: "#7eeadf",
    yAxisId: "ACFrequency",
    strokeOpacity: 1,
    index: 5,
  },
  {
    orientation: "right",
    key: "Total Energy",
    stroke: "#36aea7",
    yAxisId: "TotalEnergy",
    strokeOpacity: 1,
    index: 6,
  },
  {
    key: "DC Current",
    stroke: "#4292b2",
    yAxisId: "DCCurrent",
    strokeOpacity: 1,
    index: 7,
  },

  {
    key: "AC Power",
    stroke: "#8a70ff",
    yAxisId: "ACPower",
    strokeOpacity: 1,
    index: 8,
  },
  {
    orientation: "left",
    key: "Todays Energy",
    stroke: "#5e82bc",
    yAxisId: "TodaysEnergy",
    strokeOpacity: 1,
    index: 9,
  },
  {
    orientation: "left",
    key: "POA",
    stroke: "#5a43bc",
    yAxisId: "POA",
    strokeOpacity: 1,
    index: 10,
  },
  {
    orientation: "right",

    key: "Inverter Efficiency",
    stroke: "#f8b072",
    yAxisId: "InverterEfficiency",
    strokeOpacity: 1,
    index: 11,
  },
];
export const yAxises: any = [
  {
    orientation: "right",
    domain: [0, 0.6],
    yAxisId: "RPhaseCurrent",
    stroke: "red",
  },
  {
    orientation: "right",
    domain: [0, 0.6],
    yAxisId: "YPhaseCurrent",
    hide: true,
    stroke: "#81dcf2",
  },
  {
    orientation: "right",
    domain: [0, 0.6],
    yAxisId: "BPhaseCurrent",
    hide: true,
    stroke: "#f8b072",
  },
  {
    orientation: "left",
    domain: [0, 0.6],
    yAxisId: "DCVoltage",
    stroke: "#a874bf",
  },
  {
    orientation: "left",
    domain: [0, 0.6],
    yAxisId: "DCPower",
    stroke: "#65b165",
  },
  {
    orientation: "left",
    domain: [45,55],
    yAxisId: "ACFrequency",
    stroke: "#7eeadf",
  },
  {
    orientation: "right",
    domain: [0, 100],
    yAxisId: "TotalEnergy",
    stroke: "#36aea7",
  },
  {
    orientation: "right",
    domain: [0, 0.6],
    yAxisId: "DCCurrent",
    hide: true,
    stroke: "#4292b2",
  },
  {
    orientation: "left",
    domain: [0, 0.6],
    yAxisId: "ACPower",
    hide: true,
    stroke: "#8a70ff",
  },
  {
    orientation: "left",
    domain: [0, 0.6],
    yAxisId: "TodaysEnergy",
    hide: true,
    stroke: "#5e82bc",
  },
  {
    orientation: "right",
    domain: [0, 1200],
    yAxisId: "POA",
    stroke: "#5a43bc",
  },
  {
    orientation: "right",
    domain: [0, 100],
    yAxisId: "InverterEfficiency",
    stroke: "#f8b072",
  },
];
export const tooltipUnits: any = [
  {
    key: "R Phase Current",
    unit: "A",
    secondUnit: "KA",
  },
  {
    key: "Y Phase Current",
    unit: "A",
    secondUnit: "KA",
  },
  {
    key: "B Phase Current",
    unit: "A",
    secondUnit: "KA",
  },

  {
    key: "DC Voltage",
    unit: "V",
    secondUnit: "kV",
  },
  {
    key: "DC Power",
    unit: "KW",
    secondUnit: "MW",
  },
  {
    key: "AC Frequency",
    unit: "Hz",
    secondUnit: "Hz",
  },
  {
    key: "Total Energy",
    unit: "kWh",
    secondUnit: "MWh",
  },
  {
    key: "DC Current",
    unit: "A",
    secondUnit: "kA",
  },

  {
    key: "AC Power",
    unit: "KW",
    secondUnit: "MW",
  },

  {
    key: "Todays Energy",
    unit: "kWh",
    secondUnit: "MWh",
  },
  {
    key: "POA",
    unit: "W/M2",
    secondUnit: "W/M2",
  },
  {
    key: "Inverter Efficiency",
    unit: "%",
    secondUnit: "%",
  },
];
// frequecy dc power voltage

// current total poa effiency