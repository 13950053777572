import React from "react";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({
  active,
  payload,
  format,
}: {
  active?: boolean;
  payload?: Array<any>;
  format: any;
}) => {
  if (!active) return null;

  const value = payload[0]?.payload?.label;
  const isBigger = Math.abs(value) > 1000;
  const displayValue = isBigger ? (value / 1000).toFixed(2) : value;
  const formatLabel = isBigger ? "MWh" : format;

  return (
    <TooltipWrapper>
      <div>
        <strong>{payload[0]?.payload?.name}: </strong>{" "}
        {parseFloat(displayValue)} {formatLabel}{" "}
      </div>
    </TooltipWrapper>
  );
};