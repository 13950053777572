import React from "react";
import { useStyles } from "../../styles";

interface ChartWrapperProps {
  children: React.ReactNode | JSX.Element;
}

export const ChartWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.chartWrapper}>{children}</div>;
};
