import React, { useState } from 'react'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { TextField } from "@mui/material";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface Props {
    date: Date | null | string;
    setDate: (date) => void;
}

export const DatePickerWithArrows: React.FC<Props> = ({
    date,
    setDate,
}) => {
    const [open, setOpen] = useState(false);

    const checkDate = () => {
        if (moment(date).format("yyyy-MM-DD") === moment().format("yyyy-MM-DD"))
            return true;
        else
            return false;
    }
    const changeDateButton = (format: string) => {
        if (format === "subtract")
            setDate(moment(date).subtract(1, 'days').format("yyyy-MM-DD"))
        if (format === "add")
            setDate(moment(date).add(1, 'days').format("yyyy-MM-DD"))
    }
    const onChangeDate = (item) => {
        setDate(moment(item).format("yyyy-MM-DD"));
    };
    return (
        <div style={{ display: 'flex', alignItems: "center" }}>
            <ArrowBackIosRoundedIcon style={{ cursor: "pointer" }} onClick={() => changeDateButton("subtract")} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    label={'Date'}
                    inputFormat={"dd-MM-yyyy"}
                    value={date}
                    views={["month", "year", "day"]}
                    onChange={(item) => onChangeDate(item)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onClick={(e) => setOpen(true)}
                            sx={{
                                "& .MuiInputBase-input": {
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer"
                                },
                                width: "160px",
                            }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/MM/yyyy",
                            }}
                        />
                    )}
                    maxDate={moment().format("yyyy-MM-DD")}
                />
            </LocalizationProvider>
            <ArrowForwardIosRoundedIcon
                style={{ cursor: `${checkDate() ? "initial" : "pointer"}`, color: `${checkDate() ? "#dddddd" : "black"} ` }}
                onClick={() => checkDate() ? null : changeDateButton("add")} />
        </div>
    )
}
