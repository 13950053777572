import React from 'react'
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import { Search } from '../../../../../shared/components';
import { headerCells } from '../../../../components/utils';
import { CustomHiddenRow } from './CustomHiddenRow/CustomHiddenRow.component';
import { CustomRow } from './CutomRow/CustomRow.component';
import { usePlants } from './usePlantWidget';
import { TableDropdown } from '../../../../components/TableDropdown';
import Loader from '../../../../../monitoring/components/Loader';

export const PlantsWidget = () => {
    const classes = useStyles();

    const {
        plantsData,
        search,
        onSearch,
        plantsLoading,
        plantError,

    } = usePlants();

    return (
        <div className={classes.wrapper}>
            <Grid
                container
                spacing={2}
                alignItems={"center"}
            >
                <Grid item xs={12} sm={6} className={classes.heading}>
                    Plants
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.searchFlex}>
                        <div className={classes.innerSearch}>
                            <Search
                                placeholder="Search plant"
                                value={search}
                                onChange={onSearch}
                                onKeyUp={onSearch}
                                width={"auto"}
                                minWidth={"300px"}
                            />
                        </div>

                    </div>
                </Grid>

                <Grid item xs={12}>
                    {
                        plantError ?
                            <Loader error={"No data"} height={200} errorTextSize={24} />
                            :
                            <TableDropdown
                                headerCells={headerCells}
                                data={plantsData}
                                headerBgColor={"#FFFFFF"}
                                headerColor={'black'}
                                loading={plantsLoading}
                                getCustomRow={(item: any) => (
                                    <>
                                        {
                                            <CustomRow
                                                row={item}
                                                headerCells={headerCells}
                                                plantsLoading={plantsLoading}
                                            />
                                        }
                                    </>
                                )}
                                getCollapsedContent={(item: any) => (
                                    <>
                                        {
                                            plantsLoading ?
                                                null
                                                :
                                                <CustomHiddenRow
                                                    row={item}
                                                    headerCells={headerCells}
                                                />
                                        }
                                    </>
                                )}
                            />
                    }

                </Grid>
            </Grid>
        </div>
    )
}
