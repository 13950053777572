import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomTemplate,
  downloadCustomTemplate,
  getReportColumns,
  updateCustomTemplate,
} from "../../../features/Sidem/reports/reportActions";
import { reportSelector } from "../../../features/Sidem/reports/reportSlice";

interface Props {
  sheetHeaders: any;
}
export const useReports = ({ sheetHeaders }: Props) => {
  const [loading, setloading] = useState(null);
  const [columns, setColumns] = useState([]);
  const [params, setParams] = useState<any[]>([]);
  const [title, setTitle] = useState<string>("");
  const [templateId, setTemplateId] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<any>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    downloadReportFailed,
    downloadReportSuccessful,
    createCustomReportSuccessful,
    createCustomReportFailed,
  } = useSelector(reportSelector);

  const GetAllColumns = async () => {
    let cols = [];
    const data: any = await dispatch(getReportColumns());
    setloading(false);
    if (!data || data == undefined) return;
    const newData = data.map((dt) => ({
      title: dt.columnName,
      value: false,
      units: [{ title: dt.columnUnit, value: false }],
    }));
    cols = data;
    setParams(newData);
    setColumns(data);
    return cols;
  };
  const InsertTemplate = async (templateObj) => {
    await dispatch(createCustomTemplate(templateObj));
    setloading(false);
  };

  const processData = (sheetHeaders) => {
    const sheetData = [];
    sheetHeaders.map((sheet, i) => {
      let arr = [];
      sheet.cells.map((cell) => {
        Object.values(columns).map((col) => {
          if (col.columnName.toString() === cell) {
            arr.push(col.columnId);
          }
        });
      });
      sheetData.push({
        sheetName: "Sheet " + i + 1,
        includeMTD: sheet.cells.includes("mtd") ? true : false,
        includeQTD: sheet.cells.includes("qtd") ? true : false,
        includeYTD: sheet.cells.includes("ytd") ? true : false,
        Columns: arr,
      });
    });
    const tempObj = {
      title: title,
      sheets: sheetData,
    };
    return tempObj;
  };

  const UpdateCustomReport = async (obj: any) => {
    await dispatch(updateCustomTemplate({ ...obj, id: templateId }));
    setloading(false);
  };
  const handleClickUpdate = () => {
    const updatedSheet = processData(sheetHeaders);
    UpdateCustomReport(updatedSheet);
  };
  const handleClickAdd = () => {
    if (!title || title === "") {
      setSeverity("error");
      setMessage("Template Title is missing!");
      setIsOpen(true);
      return;
    }
    const tempObj = processData(sheetHeaders);
    if (!tempObj) return;
    InsertTemplate(tempObj);
  };

  const DownloadTemplate = async () => {
    const data: any = await dispatch(downloadCustomTemplate(templateId));
    if (!data || data == undefined) return;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    let fileName = `Report-${title}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };
  useMemo(() => {
    if (downloadReportSuccessful) {
      setSeverity("success");
      setMessage("Template Downloaded successfully!");
      setIsOpen(true);
    }
    if (downloadReportFailed) {
      setSeverity("error");
      setMessage("An error Occurred!");
      setIsOpen(true);
    }
    if (createCustomReportSuccessful) {
      setSeverity("success");
      setMessage("Template Created successfully!");
      setIsOpen(true);
    }
    if (createCustomReportFailed) {
      setSeverity("error");
      setMessage("Error creating Template!");
      setIsOpen(true);
    }
  }, [downloadReportSuccessful, downloadReportFailed]);

  return {
    GetAllColumns,
    processData,
    params,
    setParams,
    setTitle,
    setTemplateId,
    columns,
    handleClickUpdate,
    handleClickAdd,
    DownloadTemplate,
    message,
    severity,
    isOpen,
    setIsOpen,
  };
};
