import { makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 220;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    background: "#eff0f0",
  },

  bottomMenu: {
    padding: "9px",
  },
  userMenu: {
    padding: "9px",
    marginBottom: "10px",
  },
  userActive: {
    cursor: "pointer",
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    borderLeft: "3px solid rgba(151, 206, 253, 0.1)",
  },
  menuItemActive: {
    cursor: "pointer",
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(151, 206, 253, 0.2)!important",
    },
  },
  menuItemActiveMobile: {
    cursor: "pointer",
    backgroundColor: "rgba(151, 206, 253, 0.1)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(151, 206, 253, 0.2)!important",
    },
  },
  accordionDetailsRoot: {
    padding: "0px 10px 10px 10px !important",
    // marginLeft: "50px !important",
  },

  subMenu: {
    color: "#858E99",
    padding: "5px 0px",
    fontSize: "15px",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
      color: "#FFFFFF",
    },
  },

  accordionSummaryContent: {
    margin: "0px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(151, 206, 253, 0.2)!important",
    },
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    borderBottom: "1px solid #32373E",
  },
  drawerTopMenuFlex: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  drawerBottomMenuFlex: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  // onHover: {
  //   opacity: "50%",
  //   "&:hover": {
  //     opacity: "100%",
  //   },
  // },

  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
    marginLeft: -18,
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: -10,
    },
  },

  menuButtonActive: {
    backgroundColor: "#99999954 !important",
  },

  hide: {
    display: "none",
  },

  title: {
    display: "block",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
    "&:hover": {
      cursor: "pointer",
    },
  },

  drawer: {
    width: drawerWidth,
    f6exShrink: 0,
    whiteSpace: "nowrap",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.drawer,
  },

  drawerOpen: {
    color: "white",
    background: "#0c1e33",
    overflowY: "unset",
    width: drawerWidth,
  },

  drawerClose: {
    overflowY: "unset",
    background: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: "hidden",
    // width: "56px",
    // [theme.breakpoints.down("sm")]: {
    //   width: theme.spacing(0),
    // },
  },
  userMenuOpen: {
    display: "flex",
    alignItems: "center",
  },
  menuTitle: {
    fontSize: 17,
    fontWeight: 500,
  },
  list: {
    marginTop: 64,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  menuItem: {
    display: "flex",
    flexDirection: "row",
    position: "relative",

    // margin: "8px 4px 1px 8px",
    // color: "#FFFFFF",
    // borderRadius: 5,

    alignItems: "center",
    // justifyContent: "flex-start",
    // padding: 12,
    // marginTop: "10px",
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },

  menuItemMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  expandedAccordion: {
    margin: "0px !important",
  },
  accordionSummaryRoot: {
    minHeight: "0px !important",
  },

  menuItemTitle: {
    // marginLeft: theme.spacing(1.2),
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },

  menuItemTitleMobile: {
    fontSize: 17,
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },

  activeMenuItem: {
    "&:active": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
      borderLeft: "3px solid rgba(151, 206, 253, 0.7)",
    },
  },
  menuButtonMobile: {
    height: "56px",
    width: "56px",
  },
  menuButtonMobileOpen: {
    backgroundColor: "rgba(151, 206, 253, 0.1) !important",
    cursor: "pointer",
    borderRadius: "0px",
  },
  contentWrapper: {
    width: "calc(100% - 48px)",
    height: "fit-content",
    backgroundColor: "#E1E4E7",
    minHeight: "100%",
    marginLeft: "59px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
      marginLeft: 0,
      width: "100%",
    },
  },
  mainMenu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainMenuWide: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  extendedWrapper: {
    height: "100%",
  },
  scrollOverflow: {
    overflow: "scroll",
  },
  scrollOverflowX: {
    overflowX: "scroll",
  },
  marginNone: {
    marginLeft: 0,
  },
  logoWrapper: {
    display: "flex",
    padding: "0px",
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  logo: {
    padding: "0px",
  },
  onHover: {
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  logoTitle: {
    color: "#FFFFFF",
    lineHeight: "0px",
    alignSelf: "center",
    marginTop: "3px",
  },
  menuLogo: {
    padding: "0px",
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  menuIcon: {
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  topMenuFlex: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomMenuFlex: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
    [theme.breakpoints.down("md")]: {
      minHeight: 48,
    },
  },
  menu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  userMenuClose: {},
  flexItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuPopup: {
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(3.3),
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  collapseMenuTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#CACFD7 !important",
  },
  menuBorder: {
    width: "100%",
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
  },
  Popup: {
    position: "absolute",
    top: "-28px",
    // padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },

  navbar: {},
  menuPopupTitle: {
    fontSize: 15,
    fontWeight: 400,
    opacity: "100%",
    padding: "4px 10px 4px 8px !important",
    marginLeft: "-25px",
    marginTop: "4px",
  },

  padding: {
    padding: theme.spacing(2),
  },

  icon: {
    width: "80%",
    marginLeft: "28px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "30px",
  },

  menuPopupItem: {
    marginBottom: theme.spacing(0.75),
    fontSize: 15,
    fontWeight: 400,
    opacity: "50%",
    paddingLeft: "30px",
    paddingTop: "6px",
    marginLeft: "-25px",
    marginTop: "-6px",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
      opacity: "100%",
    },
    "&:last-child": {
      marginBottom: 0,
    },
    "&:first-child": {
      marginTop: -10,
    },
  },

  activeItem: {
    color: "#ffffff",
    opacity: "100%",
    backgroundColor: "#304051",
    padding: "5px 3px 5px 10px",
    borderRadius: "5px",
    marginLeft: "-10px",
  },
}));
