import {IYAxis} from '../../components/BarAndLineComposedChart/BarAndLineComposedChart.interfaces';

export const yAxises: Array<IYAxis> = [
  {
    yAxisId: 'Irradiation',
    orientation: 'left',
  },
  {
    yAxisId: 'PR',
    orientation: 'left',
  },
];

export const multilineChartLines = [
  {
    key: "value1",
    stroke: "#015E94",
  },
  {
    key: "value2",
    stroke: "#e3823a",
  },
];
