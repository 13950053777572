import { getBearerToken } from "../../../store/authToken";
import { axDelete, axPost, axPatch } from "../../../store/axiosClient";

export function deletePlantReq(body: any) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axDelete(`OnboardingV2/v1/Plant/DeletePlant`, body, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function insertPlantReq(body: any) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axPost(`onboardingV2/v1/Plant/AddPlant`, body, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};

export function updatePlantReq(body: any) {
	return async (dispatch: (arg0: any) => void) => {
		try {
			const token = await getBearerToken();
			const data: any = await axPatch(`onboardingV2/v1/Plant/UpdatePlant`, body, token);
			return data;
		} catch (error) {
			return error.response.data;
		}
	};
};
