import React, { lazy, Suspense } from "react";
import { Container } from "@mui/material";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";
import { Fallback } from "../../../app/Fallback";

const HeaderWidget = lazy(() =>
  import("./components/HeaderWidget/").then((module) => ({
    default: module.HeaderWidget,
  }))
);
const AssestsOverview = lazy(() =>
  import("./components/AssetsOverview").then((module) => ({
    default: module.AssestsOverview,
  }))
);

export const MonitoringDashboard: React.FC<any> = ({
  tenant,
  SkyfriDemoRole,
  BatteryMonitoringRole,
}) => {
  const {
    date,
    setDate,
    liveButton,
    checkLiveButton,
    selectedButtonView,
    setSelectedButtonView,
    view,
    setView,
    selectedTab,
    setSelectedTab,
  } = useHeaderWidget();

  return (
    <Suspense fallback={<Fallback />}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: "16px", paddingTop: "16px" }}
      >
        <HeaderWidget
          selectedTab={selectedTab}
          view={view}
          selectedButtonView={selectedButtonView}
          date={date}
          setDate={setDate}
          liveButton={liveButton}
          handleChangeLiveButton={checkLiveButton}
        />
        <AssestsOverview
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          view={view}
          setView={setView}
          selectedButtonView={selectedButtonView}
          setSelectedButtonView={setSelectedButtonView}
          tenant={tenant}
          BatteryMonitoringRole={BatteryMonitoringRole}
          SkyfriDemoRole={SkyfriDemoRole}
          liveButton={liveButton}
        />
      </Container>
    </Suspense>
  );
};
