import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { RenderCustomLegend } from "../CustomChartLegend";
import { RenderCustomToggleLegend } from "../CustomToggleChartLegend";

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: { value: any };
}

const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
}: CustomizedAxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-15)"
    >
      {payload.value}
    </text>
  </g>
);

export interface IYAxis {
  yAxisId: string;
  orientation: "right" | "left";
  domain?: [any, any];
  stroke?: string;
}

export interface ILine {
  key: string;
  stroke: string;
  yAxisId?: string;
  strokeOpacity?: number;
  orientation?: "right" | "left";
}

interface MultiLineChartProps {
  label: string;
  lines: Array<ILine>;
  data: Array<any>;
  yAxises?: Array<IYAxis>;
  tooltipContent?: any;
  minHeight?: string;
  showLegend?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showDots?: boolean;
  strokeWidth?: number;
  toggleLegend?: boolean;
  setLines?: any;
  showLabel?: boolean;
  axisLine?: boolean;
  renderCustomLegend?: any;
}
export const MultiLineChart = ({
  data,
  lines,
  label,
  tooltipContent,
  yAxises = [{ yAxisId: "axis", orientation: "left" }],
  minHeight = "100px",
  showLegend = true,
  showXAxis = true,
  showYAxis = true,
  showDots = false,
  strokeWidth = 1,
  toggleLegend,
  setLines,
  showLabel,
  axisLine = true,
  renderCustomLegend,
}: MultiLineChartProps) => {
  useEffect(() => { }, [lines]);
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={minHeight}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        {showXAxis && (
          <XAxis dataKey={label} height={80} tick={<CustomizedAxisTick />} />
        )}
        {showXAxis && (
          <XAxis dataKey={label} height={80} tick={<CustomizedAxisTick />} />
        )}
        {yAxises?.map((item) => (
          <YAxis
            padding={{ top: 20 }}
            key={item.yAxisId}
            yAxisId={item.yAxisId}
            orientation={item.orientation}
            domain={item?.domain}
            hide={!showYAxis}
            axisLine={axisLine}
            tick={{ fill: item?.stroke }}
            tickLine={{ stroke: item?.stroke }}
          >
            {showLabel ? (
              <Label
                position={'insideTop'}
                dx={item?.orientation === "left" ? 10 : -10}
                dy={-10}
                style={{
                  textAnchor: "middle",
                  fontSize: "100%",
                  fill: item?.stroke ? item?.stroke : "rgb(63 63 63)",
                }}
                angle={0}
                value={item?.yAxisId}
              />
            ) : null}
          </YAxis>
        ))}
        <Tooltip content={tooltipContent} wrapperStyle={{ outline: "none" }} />
        {showLegend && (
          <Legend
            wrapperStyle={{ height: "fit-content", maxHeight: "150px", overflowY: "scroll" }}
            iconType="square"
            content={
              renderCustomLegend
                ? React.cloneElement(renderCustomLegend, {
                  setLines,
                  lines,
                })
                : toggleLegend
                  ? React.cloneElement(<RenderCustomToggleLegend />, {
                    setLines,
                    lines,
                  })
                  : RenderCustomLegend
            }
          />
        )}
        {lines.map((item, idx) => {
          return (
            <Line
              key={idx + item.key}
              yAxisId={item?.yAxisId || yAxises[0]?.yAxisId}
              type="monotone"
              dataKey={item.key}
              stroke={item.stroke}
              strokeWidth={strokeWidth}
              dot={showDots}
              strokeOpacity={item.strokeOpacity}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
