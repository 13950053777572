import React from "react";
import clsx from "clsx";
import {
  AppBar,
  CssBaseline,
  IconButton,
  Drawer,
  Toolbar,
} from "@material-ui/core";
import { ReactComponent as UPARROW } from "../../shared/icons/app/UPARROW.svg";
import { ReactComponent as DOWNARROW } from "../../shared/icons/app/DOWNARROW.svg";
import Accordion from "@material-ui/core/Accordion";
import { Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { NotificationsSidebar } from "../sideBar/NotificationsSidebar/NotificationsSidebar.component";
import { Box as BoxMUI } from "@mui/material";
import { ReactComponent as TRANSPARENT_STRIPE } from "../../shared/icons/app/TRANSPARENT_STRIPE.svg";
import { ReactComponent as HAMBURGER } from "../../shared/icons/app/HAMBURGER.svg";
import { ReactComponent as STRIPE } from "../../shared/icons/app/STRIPE.svg";
import { ReactComponent as SKYFRI_LOGO } from "../../shared/icons/app/SKYFRI_LOGO.svg";
import { ReactComponent as SKYFRI_LOGO_TEXT } from "../../shared/icons/sidebar/light/SKYFRI_LOGO_TEXT.svg";
import { MenuItem, RolesMap } from "../../shared/interfaces";
import { UserProfile } from "../sideBar/UserProfile/UserProfile.component";
import { GlobalSettings } from "../sideBar/GlobalSettings/GlobalSettings.component";
import { Menu } from "../sideBar/Menu/Menu.component";
import { useStyles } from "./styles";
import { ErrorFallback } from "../ErrorFallback";
import { toTitleCase } from "./utils";
import { useNavigation } from "./useNavigation";

interface Props {
  children?: any;
  roles: RolesMap;
  tenant: string;
}

export const Navigation: React.FC<Props> = ({ children, roles, tenant, }) => {
  const classes = useStyles();

  const {
    isMobile,
    onError,
    drawerOpen,
    setDrawerOpen,
    showMenu,
    setShowMenu,
    showSidebar,
    menu,
    hoveredItem,
    setHoveredItem,
    currentModule,
    setCurrentModule,
    activeMenuItem,
    setActiveMenuItem,
    previousMenuItem,
    setPreviousMenuItem,
    expanded,
    setExpanded,
    setAnchorEl,
    setMenuItemClicked,
    selectedAccordion,
    setSelectedAccordion,
    active,
    setActive,
    goHome,
    topMenuRef,
    navigate,
  } = useNavigation({ roles, tenant });

  const openMenu = (event?: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event?.currentTarget);

  const closeMenu = () => {
    setAnchorEl(null);
    setActiveMenuItem(previousMenuItem);
    setDrawerOpen(false);
  };

  const openSubItem = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  };

  const handleHover = (e, item: MenuItem) => {
    e.preventDefault();
    setPreviousMenuItem(activeMenuItem);
    setActiveMenuItem(item);
    setHoveredItem(item);
    if (!item?.items?.length) {
      goHome();
    }
  };

  const handleMouseLeave = (e) => {
    setActiveMenuItem(previousMenuItem);
    setHoveredItem(null);
  };

  const handleSubItemClick = (e, path: any) => {
    if (window.location.pathname === path) return;
    e.stopPropagation();
    setShowMenu(false);
    openSubItem(path);
    setHoveredItem(null);
  };

  const onClickItem = (e, item: MenuItem) => {
    setCurrentModule(item?.idx);
    setMenuItemClicked({
      id: item.idx,
      clicked: true,
    });
    setActiveMenuItem(previousMenuItem);
    setHoveredItem(null);
    if (window.location.pathname !== item["items"]?.[0]?.path)
      navigate(item["items"]?.[0]?.path);
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleAccordionClick = (idx) => {
    setSelectedAccordion(selectedAccordion === idx ? null : idx);
    setExpanded(!expanded);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Backdrop open={drawerOpen} onClick={closeMenu} sx={{ zIndex: 1001 }} />
      {!isMobile ? (
        <></>
      ) : (
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.navbar, {
            [classes.appBarShift]: drawerOpen,
          })}
        >
          <Toolbar
            style={{
              minHeight: "48px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "-4px",
              }}
            >
              <IconButton
                className={clsx(classes.menuButtonMobile, {
                  [classes.menuButtonMobileOpen]: showMenu,
                })}
                edge="start"
                color="inherit"
                aria-label="open menu"
                onClick={handleMenuClick}
              >
                <HAMBURGER />
              </IconButton>
              {showMenu && (
                <SwipeableDrawer
                  anchor={"top"}
                  sx={{ top: "100px" }}
                  open={showMenu}
                  onClose={handleMenuClick}
                  onOpen={handleMenuClick}
                >
                  <BoxMUI
                    sx={{
                      height: "100vh",
                      marginTop: "50px",
                      backgroundColor: "#0C1E33",
                      color: "#FFFFFF",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    role="presentation"
                  >
                    {menu?.map((item: MenuItem) => {
                      const { Icon, idx, title, items } = item;
                      const isAccordionOpen = selectedAccordion === idx;
                      const filteredItems = items?.filter(
                        (subItem) =>
                          subItem.title !== null && subItem.title !== ""
                      );

                      return (
                        <>
                          <Accordion
                            key={item.idx + item.title}
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              display: "flex",
                              borderBottom: "1px solid  #E1E4E9",
                              backgroundColor: "#0C1E33",
                            }}
                            expanded={isAccordionOpen}
                            className={clsx(
                              classes.menuItemMobile,
                              "menu-item",
                              {
                                [classes.expandedAccordion]: isAccordionOpen,
                              }
                            )}
                            onClick={() => handleAccordionClick(idx)}
                          >
                            <AccordionSummary
                              aria-controls={`panel${item.idx}-content`}
                              id={`panel${item.idx}-header`}
                              classes={{
                                content: classes.accordionSummaryContent,
                                root: classes.accordionSummaryRoot,
                              }}
                              style={{
                                margin: 0,
                                padding: 0,
                                height: "56px !important",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                container
                                sx={{
                                  height: 56,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid item>
                                    {isAccordionOpen ? (
                                      <STRIPE />
                                    ) : (
                                      <TRANSPARENT_STRIPE />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Icon />
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.menuItemTitleMobile}
                                  >
                                    {toTitleCase(title)}
                                  </Grid>
                                </div>
                                <div>
                                  <Grid item sx={{ marginRight: "30px" }}>
                                    {expanded ? <UPARROW /> : <DOWNARROW />}
                                  </Grid>
                                </div>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  marginLeft: "45px",
                                }}
                              >
                                {filteredItems?.map((subItem) => {
                                  return (
                                    <Grid
                                      item
                                      className={classes.subMenu}
                                      key={subItem?.path}
                                      onClick={(e) =>
                                        handleSubItemClick(e, subItem?.path)
                                      }
                                    >
                                      {subItem?.title}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    })}
                  </BoxMUI>
                </SwipeableDrawer>
              )}
              <img
                alt="Skyfri-logo"
                src="/images/Skyfri_logo_full.svg"
                onClick={goHome}
                className={classes.title}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <NotificationsSidebar
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
                isMobile={isMobile}
              />
              <GlobalSettings
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
                isMobile={isMobile}
              />
              <UserProfile
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
                isMobile={isMobile}
              />
            </div>
          </Toolbar>
        </AppBar>
      )}

      {isMobile ? (
        <></>
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          style={{ overflowY: "hidden" }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          <div
            id="mainMenu"
            className={classes.mainMenu}
            style={{ width: drawerOpen ? "219px" : "56px" }}
          >
            <div
              id="topMenu"
              ref={topMenuRef}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className={clsx(
                { [classes.topMenuFlex]: !drawerOpen },
                { [classes.drawerTopMenuFlex]: drawerOpen }
              )}
            >
              <div
                style={{ width: "100%" }}
                className={clsx(
                  { [classes.userMenuOpen]: drawerOpen },
                  classes.onHover
                )}
              >
                <div>
                  <IconButton onClick={goHome} className={classes.logo}>
                    <SKYFRI_LOGO />
                  </IconButton>
                </div>
                {drawerOpen && (
                  <div className={classes.logoTitle}>
                    <SKYFRI_LOGO_TEXT />
                  </div>
                )}
              </div>
              {menu?.map((item: MenuItem) => {
                const { Icon, idx, title, items } = item;
                const filteredItems = item.items?.filter(
                  (subItem) => subItem.title !== null && subItem.title !== ""
                );

                return (
                  <>
                    <div
                      key={idx + title}
                      style={{
                        width: "100%",
                        height: 56,
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={clsx(classes.menuItem, "menu-item", {
                        [classes.menuItemActive]: idx === currentModule,
                      })}
                      onClick={(e) => onClickItem(e, item)}
                      onMouseEnter={(e) => handleHover(e, item)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div style={{ height: 56 }}>
                        <span>
                          {idx === currentModule ? (
                            <STRIPE />
                          ) : (
                            <TRANSPARENT_STRIPE />
                          )}
                        </span>
                        <span>
                          <Icon style={{ position: "relative", top: "-3px" }} />
                        </span>
                      </div>
                      {drawerOpen && (
                        <span
                          className={classes.menuItemTitle}
                          style={{ height: 56 }}
                        >
                          {toTitleCase(title)}
                        </span>
                      )}
                      {hoveredItem === item && (
                        <BoxMUI
                          style={{
                            borderRadius: "6px",
                            left: drawerOpen ? "229px" : "65px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                            position: "absolute",
                            width: "fit-content",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <div className={classes.Popup}>
                            {!drawerOpen && (
                              <div className={clsx(classes.menuPopupTitle, {})}>
                                <span className={classes.icon}>
                                  <Icon style={{ marginLeft: "-5px" }} />
                                  <span
                                    style={{
                                      marginLeft: "-8px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {toTitleCase(title)}
                                  </span>
                                </span>
                              </div>
                            )}
                            {!drawerOpen && (
                              <div
                                style={{
                                  borderBottom:
                                    "1px solid rgba(255, 255, 255, 0.2)",
                                }}
                              ></div>
                            )}
                            <div className={classes.padding}>
                              {filteredItems?.map((item) => (
                                <div
                                  key={item.path}
                                  className={clsx(classes.menuPopupItem, {})}
                                  onClick={(e) =>
                                    handleSubItemClick(e, item?.path)
                                  }
                                >
                                  {item?.title}
                                </div>
                              ))}
                            </div>
                          </div>
                        </BoxMUI>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div
              id="bottomMenu"
              className={clsx(
                { [classes.bottomMenuFlex]: !drawerOpen },
                { [classes.drawerBottomMenuFlex]: drawerOpen }
              )}
            >
              <NotificationsSidebar
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
              />
              <GlobalSettings
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
              />
              <UserProfile
                drawerOpen={drawerOpen}
                active={active}
                setActive={setActive}
              />
              <Menu
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                active={active}
                setActive={setActive}
              />
            </div>
          </div>
        </Drawer>
      )}
      <main
        className={clsx(
          classes.contentWrapper,
          {
            [`${classes.extendedWrapper} ${classes.scrollOverflow}`]:
              drawerOpen && showSidebar,
          },
          {
            [`${classes.scrollOverflowX}`]: !drawerOpen,
          }
        )}
      >
        {onError ? <ErrorFallback /> : children}
      </main>
    </div>
  );
};
