import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useStyles } from "./styles";
import { fetchAsync } from "../../shared/utils/apiService";


export const OnboardingNavigation: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [tenant, setTenant] = useState("");

  const getTenants = async () => {
    await fetchAsync(`onboardingV2/v1/Tenant/GetTenants`, "GET")
      .then((data: any) => {
        if (data) {
          var newTenant = data?.map((item) => ({
            ...item,
            id: item.tenantId,
          }));
          let currentTenant = newTenant.find((x) => x.id === tenantId);
          setTenant(currentTenant?.tenantName || "");
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (!tenantId) return;
    getTenants();
  }, [tenantId]);

  useEffect(() => {
    const pathname = location?.pathname;
    const url = pathname?.split("/");
    setTenantId(url[3]);
    getTitle();
  }, [location]);

  const getTitle = () => {
    setTitle(location?.pathname?.split("/")[2]);
  };

  return (
    <>
      <Typography className={classes.subtitle} variant="body1" noWrap>
        {title}
      </Typography>
      {tenantId && tenant !== "" ? (
        <>
          <div className={classes.arrow}>
            <ArrowRightAltIcon />
          </div>
          <Typography
            className={classes.subtitle}
            variant="body1"
            noWrap
            onClick={() => { }}
          >
            {tenant}
          </Typography>
        </>
      ) : (
        ""
      )}
    </>
  );
};
