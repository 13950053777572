import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomTooltip = ({ active, payload }: { active?: boolean, payload?: Array<any> }) => {
  if (!active || !payload || !payload[0]?.payload) return null;

  const { sensor_time, values = [] } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>
        <strong>{sensor_time}</strong>
      </div>
      {values?.map((item) => {
        if (!item?.value) return;
        return (
          <div>
            {item.key}: {item.value?.toFixed(1)} (°C)
          </div>
        );
      })}
    </TooltipWrapper>
  );
};
