import { useSearchParams } from "react-router-dom";
import { convertTime } from "../../../../../shared/utils/utils";
import { useGetInverterKPIQuery } from "../../../../../features/Monitoring/inverterPerformance/inverterPerformanceApi";
import { useGetInverterKPIGraphQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";

export const useInverterKPIWidget = () => {
  const [url] = useSearchParams();
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const {
    data: kpiDataApi,
    isError: isInverterKPIError,
    isLoading: isInverterKPILoading,
  } = useGetInverterKPIQuery(
    {
      plantId: plantId,
      fromDate: convertTime(startDate, "YYYY.MM.DD"),
      toDate: convertTime(endDate, "YYYY.MM.DD"),
    },
    { skip: !startDate || !endDate || !plantId }
  );

  const { data: graphData, isFetching: isGraphDataFetching } =
    useGetInverterKPIGraphQuery(
      {
        plantId: plantId,
        fromDate: convertTime(startDate, "YYYY.MM.DD"),
        toDate: convertTime(endDate, "YYYY.MM.DD"),
      },
      { skip: !startDate || !endDate || !plantId }
    );

  return {
    KPIData: kpiDataApi || null,
    KPIGraphData: graphData?.data || null,
    xAxis: graphData?.xAxis || {},
    yAxis: graphData?.yAxis || [],
    isInverterKPIError,
    isInverterKPILoading,
    inverterGraphLoading: isGraphDataFetching,
  };
};

export type InverterKPIWidgetData = ReturnType<typeof useInverterKPIWidget>;
