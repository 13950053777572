import { useAppSelector } from "../../../../../store/rootStore";

import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { convertTime, isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { useCallback, useMemo, useState } from "react";
import { useUrlParams } from "../../../../../shared/utils/hooks";
import { useDataManipulation, useFilters, usePage } from "./hooks";
import { sources } from "./utils";
import { BatteryControlTableData } from "./BatteryControlTable.types";
import { Order } from "../../../../../shared/interfaces";
import { capitalizeFirstLetter } from "../../../../../onBoarding/pages/components/Utils/utils";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatterySocHistoricalQuery,
  useGetBatterySteeringSignalsCountQuery,
  useGetBatterySteeringSignalsQuery,
} from "../../../BatteryMonitoring/BatteryMonitoring.api";
import { ENERGY_PROVIDERS } from "../../../BatteryMonitoring/BatteryMonitoring.api.types";

export const useBatteryControlTableWidget = () => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const { liveButton, globalDate } = useAppSelector(
    monitoringDashboardSelector
  );

  const { pageNumber, handlePageReset, handlePageChange } = usePage();

  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof BatteryControlTableData>("date");

  const handleRequestSort = useCallback(
    (property: keyof BatteryControlTableData) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      handlePageReset();
    },
    [handlePageReset, order, orderBy]
  );

  // TODO: kaz uncomment it when backend'll be ready
  // const { downloadError, downloadLoading, handleDownload } = useDownload();

  const {
    search,
    sourceFilter,
    notExecutedOnly,
    debouncedSearch,
    handleSourceFilterChange,
    handleNotExecutedOnlyChange,
    handleSearchChange,
  } = useFilters(handlePageReset);

  const {
    data: dataSoc,
    isLoading: isLoadingSoc,
    isFetching: isFetchingSoc,
    isError: isErrorSoc,
    isUninitialized: isUninitializedSoc,
  } = useGetBatterySocHistoricalQuery(
    {
      fromDate,
      toDate: convertTime(endDate, "YYYY.MM.DD"),
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      // TODO: kaz implement dropdownValue?
      granularity: "0",
    },
    {
      skip: isAnyItemFalsy([
        startDate,
        endDate,
        plantId,
        DEFAULT_EQUIPMENT_NAME,
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const source = useMemo(() => {
    return sourceFilter.length > 0
      ? `${sourceFilter.join("&source=")}`
      : undefined;
  }, [sourceFilter]);

  const {
    data: dataBatteryControl,
    isLoading: isLoadingBatteryControl,
    isFetching: isFetchingBatteryControl,
    isUninitialized: isUninitializedBatteryControl,
    isError: isErrorBatteryControl,
  } = useGetBatterySteeringSignalsQuery(
    {
      plantId,
      fromDate,
      toDate,
      search,
      pageSize,
      pageNumber,
      source,
      notExecutedOnly: notExecutedOnly ? "true" : "false",
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      sort: capitalizeFirstLetter(order) as "Asc" | "Desc",
    },
    {
      skip: isAnyItemFalsy([plantId, fromDate, toDate, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataBatteryControlCount,
    isLoading: isLoadingBatteryControlCount,
    isFetching: isFetchingBatteryControlCount,
    isUninitialized: isUninitializedBatteryControlCount,
    isError: isErrorBatteryControlCount,
  } = useGetBatterySteeringSignalsCountQuery(
    {
      plantId,
      fromDate,
      toDate,
      search,
      source,
      notExecutedOnly: notExecutedOnly ? "true" : "false",
      equipmentName: DEFAULT_EQUIPMENT_NAME,
    },
    {
      skip: isAnyItemFalsy([plantId, fromDate, toDate, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataBatteryControlCountNotExecuted,
    isLoading: isLoadingBatteryControlCountNotExecuted,
    isFetching: isFetchingBatteryControlCountNotExecuted,
    isUninitialized: isUninitializedBatteryControlCountNotExecuted,
    isError: isErrorBatteryControlCountNotExecuted,
  } = useGetBatterySteeringSignalsCountQuery(
    {
      plantId,
      fromDate,
      toDate,
      search,
      source,
      notExecutedOnly: 'true',
      equipmentName: DEFAULT_EQUIPMENT_NAME,
    },
    {
      skip: isAnyItemFalsy([plantId, fromDate, toDate, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const { parsedData, sortedData } = useDataManipulation(
    dataSoc,
    dataBatteryControl,
    orderBy,
    order
  );

  const renderValue = useCallback(
    (selected: string[]) => {
      if (sources.length > 1) {
        if (
          selected.includes("Show all") ||
          selected.length === sources.length ||
          selected.length === 0
        ) {
          return `Show all (${dataBatteryControlCount?.Total || 0})`;
        } else if (selected.length > 1) {
          return `${selected.length} selected`;
        } else {
          return `${ENERGY_PROVIDERS[selected[0]]} (${
            dataBatteryControlCount?.[selected[0]] || 0
          })`;
        }
      } else {
        return sources[0];
      }
    },
    [sources, dataBatteryControl, dataBatteryControlCount]
  );

  return {
    dataBatteryControl,
    dataBatteryControlCount,
    dataBatteryControlCountNotExecuted,
    dataSoc,
    endDate,
    globalDate,
    handleNotExecutedOnlyChange,
    handlePageChange,
    handlePageReset,
    handleRequestSort,
    handleSearchChange,
    handleSourceFilterChange,
    isLoadingBatteryControl:
      isFetchingBatteryControl ||
      isFetchingBatteryControlCount ||
      isFetchingBatteryControlCountNotExecuted ||
      isLoadingBatteryControl ||
      isLoadingBatteryControlCount ||
      isLoadingBatteryControlCountNotExecuted ||
      isUninitializedBatteryControl ||
      isUninitializedBatteryControlCount ||
      isUninitializedBatteryControlCountNotExecuted ||
      isFetchingSoc ||
      isLoadingSoc ||
      isUninitializedSoc,
    liveButton,
    notExecutedOnly,
    order,
    orderBy,
    pageNumber,
    pageSize,
    parsedData,
    plantId,
    renderValue,
    search,
    setPageSize,
    sortedData,
    sourceFilter,
    startDate,
  };
};
