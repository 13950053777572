import React, { useMemo } from "react";
import clsx from "clsx";

import WaterfallChart from "../../../../components/WaterfallChart";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";

import { CustomTooltip } from "./CustomTooltip.component";
import { useLossWaterfall } from "./useLossWaterfall";
import { useStyles } from "./styles";

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
  definition?: any;
}

export const LossWaterfallWidget: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { formatTypes, setFormat, loading, error, format, data } =
    useLossWaterfall(props);

  const getWaterfallMenu = (active: string) => (
    <div className={classes.waterfallMenu}>
      {formatTypes.map((item) => (
        <div
          key={item.label}
          className={clsx(classes.item, {
            [classes.active]: item.value === active,
          })}
          onClick={() => {
            setFormat(item.value);
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );

  const content = useMemo(
    () => (
      <WaterfallChart
        data={data}
        formatLabel={"KWh"}
        definition={props.definition}
        tooltipContent={<CustomTooltip format={format} />}
      />
    ),
    [data, format, props.definition]
  );

  return (
    <Widget
      label="Loss Waterfall"
      height={600}
      size={6}
      styles={classes.widget}
      customMenu={getWaterfallMenu(format)}
    >
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />
      {data?.length > 0 && !loading && content}
    </Widget>
  );
};
