import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: "white",
    paddingTop: "8px",
  },
  icon: {
    padding: "2px",
    zIndex: 3,
    pointerEvents: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      borderRadius: "4px",
    },
  },
  customBadge: {
    backgroundColor: "rgba(225,228,233,0.7)",
    pointerEvents: "none",
    right: "46px !important",
    "@media (max-width: 500px)": {
      right: "40px !important",
    },
  },
  paddingRight: {
    padding: "0px 45px 0px 5px",

    '@media (max-width: 500px)': {
      maxWidth: "50px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    '@media (max-width: 360px)': {
      maxWidth: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

  },
  select: {
    fontWeight: 500,
    paddingLeft: "0px",
    fontSize: "16px",
    cursor: "pointer",
    "& .MuiSelect-multiple": {
      "&:focus": {
          backgroundColor: "transparent",
      },
    },
  },
  svgIcon: {
    position: "relative",
    top: "2px",
    left: "4px",
    cursor: "pointer",
  },

}));
