import React from 'react'
import { makeStyles } from '@material-ui/core';

import { SinglelineChartWidget } from '../../../../components/SinglelineChart';

import { CustomTooltip } from './CustomTooltip.componets';
import { useInverterEfficiency } from './useInverterEfficiency';

interface Props {
  plantId: string;
  inverterId: string;
  blockId: string;
  date: string;
  definition?: any;
}

const useStyles = makeStyles(theme => ({
  widget: {
    marginRight: theme.spacing(2)
  }
}))

export const InverterEfficiencyWidget: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { data, error, loading } = useInverterEfficiency(props);

  return (
    <SinglelineChartWidget
      styles={classes.widget}
      title={'Inverter efficiency & Load'}
      data={data}
      size={6}
      loading={loading}
      error={error}
      height={500}
      XAxisKey="acload"
      YAxisKey="eficiency"
      tooltipContent={<CustomTooltip payload={data} />}
      definition={props.definition}
    />
  )
}
