import { TooltipWrapper } from "../../../../components/TooltipWrapper";

export const CustomMultiLineTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload?.[0]?.payload) return null;

  const { label, value1, value2 } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>Date: {label}</div>
      <div>Energy Delta: {(value1 * 100).toFixed(1)}%</div>
      <div>Irradiation Delta: {(value2 * 100).toFixed(1)}%</div>
    </TooltipWrapper>
  );
};

export const CustomLineTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload?.[0]?.payload) return null;

  const { label, value } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>Date: {label}</div>
      <div>Production Factor: {(value * 100).toFixed(1)}%</div>
    </TooltipWrapper>
  );
};

export const CustomBarTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  if (!active || !payload?.[0]?.payload) return null;

  const { label, barValue, lineValue } = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div>Date: {label}</div>
      <div>Actual PR: {barValue} %</div>
      <div>Actual Irradiation: {lineValue} kWh/m²</div>
    </TooltipWrapper>
  );
};