import { BatteryStatuses } from "../../BatteryMonitoring.api.types";

export type BatteryStatusKeysObject = {
  [K in keyof typeof BatteryStatuses]: string;
};

export enum batteryStatusBgColors {
  OFF = "#E6E6E6",
  EMPTY = "#F8DCDC",
  DISCHARGING = "#E3EEFB",
  CHARGING = "#F1F5DF",
  FULL = "#F1F5DF",
  HOLDING = "#E6E6E6",
  TESTING = "#E6E6E6",

  // custom one, not provided by API
  NO_DATA = "#E6E6E6",
}

export enum batteryStatusDotsColors {
  OFF = "#808080",
  EMPTY = "#DE504F",
  DISCHARGING = "#73AAEC",
  CHARGING = "#B8CE5B",
  FULL = "#B8CE5B",
  HOLDING = "#808080",
  TESTING = "#808080",

  // custom one, not provided by API
  NO_DATA = "#808080",
}
