import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(0.5, 3, 0.5, 3),
        borderRadius: theme.spacing(1, 1, 0, 0),
        marginTop: theme.spacing(2)
    },
    heading: {
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "24px",
        margin: theme.spacing(2.0, 0)
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center',
    },
    portfolios: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "15.4px",
        color: "#858E99",
    },
    pill: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "#E1E4E9",
        borderRadius: "32px",
        padding: "2px 6px 2px 6px",
        fontSize: "12px",
        fontWeight: 500,
        color: "#0C1E33",
        lineHeight: "14.4px",
    },
    label: {
        marginRight: "4px",
    },
    accordionExpand: {
        cursor: "pointer",
        fontWeight: 500,
        fontSize: "15px",
    }
}));
