// 15 mins
export const date_values = [
  "15 Feb 2024 00:00",
  "15 Feb 2024 00:15",
  "15 Feb 2024 00:30",
  "15 Feb 2024 00:45",
  "15 Feb 2024 01:00",
  "15 Feb 2024 01:15",
  "15 Feb 2024 01:30",
  "15 Feb 2024 01:45",
  "15 Feb 2024 02:00",
  "15 Feb 2024 02:15",
  "15 Feb 2024 02:30",
  "15 Feb 2024 02:45",
  "15 Feb 2024 03:00",
  "15 Feb 2024 03:15",
  "15 Feb 2024 03:30",
  "15 Feb 2024 03:45",
  "15 Feb 2024 04:00",
  "15 Feb 2024 04:15",
  "15 Feb 2024 04:30",
  "15 Feb 2024 04:45",
  "15 Feb 2024 05:00",
  "15 Feb 2024 05:15",
  "15 Feb 2024 05:30",
  "15 Feb 2024 05:45",
  "15 Feb 2024 06:00",
  "15 Feb 2024 06:15",
  "15 Feb 2024 06:30",
  "15 Feb 2024 06:45",
  "15 Feb 2024 07:00",
  "15 Feb 2024 07:15",
  "15 Feb 2024 07:30",
  "15 Feb 2024 07:45",
  "15 Feb 2024 08:00",
  "15 Feb 2024 08:15",
  "15 Feb 2024 08:30",
  "15 Feb 2024 08:45",
  "15 Feb 2024 09:00",
  "15 Feb 2024 09:15",
  "15 Feb 2024 09:30",
  "15 Feb 2024 09:45",
  "15 Feb 2024 10:00",
  "15 Feb 2024 10:15",
  "15 Feb 2024 10:30",
  "15 Feb 2024 10:45",
  "15 Feb 2024 11:00",
  "15 Feb 2024 11:15",
  "15 Feb 2024 11:30",
  "15 Feb 2024 11:45",
  "15 Feb 2024 12:00",
  "15 Feb 2024 12:15",
  "15 Feb 2024 12:30",
  "15 Feb 2024 12:45",
  "15 Feb 2024 13:00",
  "15 Feb 2024 13:15",
  "15 Feb 2024 13:30",
  "15 Feb 2024 13:45",
  "15 Feb 2024 14:00",
  "15 Feb 2024 14:15",
  "15 Feb 2024 14:30",
  "15 Feb 2024 14:45",
  "15 Feb 2024 15:00",
  "15 Feb 2024 15:15",
  "15 Feb 2024 15:30",
  "15 Feb 2024 15:45",
  "15 Feb 2024 16:00",
  "15 Feb 2024 16:15",
  "15 Feb 2024 16:30",
  "15 Feb 2024 16:45",
  "15 Feb 2024 17:00",
  "15 Feb 2024 17:15",
  "15 Feb 2024 17:30",
  "15 Feb 2024 17:45",
  "15 Feb 2024 18:00",
  "15 Feb 2024 18:15",
  "15 Feb 2024 18:30",
  "15 Feb 2024 18:45",
  "15 Feb 2024 19:00",
  "15 Feb 2024 19:15",
  "15 Feb 2024 19:30",
  "15 Feb 2024 19:45",
  "15 Feb 2024 20:00",
  "15 Feb 2024 20:15",
  "15 Feb 2024 20:30",
  "15 Feb 2024 20:45",
  "15 Feb 2024 21:00",
  "15 Feb 2024 21:15",
  "15 Feb 2024 21:30",
  "15 Feb 2024 21:45",
  "15 Feb 2024 22:00",
  "15 Feb 2024 22:15",
  "15 Feb 2024 22:30",
  "15 Feb 2024 22:45",
  "15 Feb 2024 23:00",
  "15 Feb 2024 23:15",
  "15 Feb 2024 23:30",
  "15 Feb 2024 23:45",
  "16 Feb 2024 00:00",
  "16 Feb 2024 00:15",
  "16 Feb 2024 00:30",
  "16 Feb 2024 00:45",
  "16 Feb 2024 01:00",
  "16 Feb 2024 01:15",
  "16 Feb 2024 01:30",
  "16 Feb 2024 01:45",
  "16 Feb 2024 02:00",
  "16 Feb 2024 02:15",
  "16 Feb 2024 02:30",
  "16 Feb 2024 02:45",
  "16 Feb 2024 03:00",
  "16 Feb 2024 03:15",
  "16 Feb 2024 03:30",
  "16 Feb 2024 03:45",
  "16 Feb 2024 04:00",
  "16 Feb 2024 04:15",
  "16 Feb 2024 04:30",
  "16 Feb 2024 04:45",
  "16 Feb 2024 05:00",
  "16 Feb 2024 05:15",
  "16 Feb 2024 05:30",
  "16 Feb 2024 05:45",
  "16 Feb 2024 06:00",
  "16 Feb 2024 06:15",
  "16 Feb 2024 06:30",
  "16 Feb 2024 06:45",
  "16 Feb 2024 07:00",
  "16 Feb 2024 07:15",
  "16 Feb 2024 07:30",
  "16 Feb 2024 07:45",
  "16 Feb 2024 08:00",
  "16 Feb 2024 08:15",
  "16 Feb 2024 08:30",
  "16 Feb 2024 08:45",
  "16 Feb 2024 09:00",
  "16 Feb 2024 09:15",
];

export const actual_production = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.47, 4.0, 5.0, 8.12, 10.0, 11.0,
  15.0, 18.58, 20.0, 35.0, 36.0, 37.0, 37.23, 58.0, 59.0,
];

export const production_forecast_minimum = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.42, 3.6, 4.5, 7.31, 9.0, 9.9, 13.5,
  16.72, 18.0, 31.5, 32.4, 33.3, 33.51, 52.2, 53.1, 54.0, 54.86, 55.8, 56.7,
  57.6, 58.27, 61.2, 62.1, 63.0, 61.3, 48.6, 49.5, 50.4, 51.66, 52.2, 53.1,
  54.0, 55.93, 64.8, 65.7, 66.6, 67.01, 63.0, 63.9, 64.8, 64.93, 64.8, 65.7,
  66.6, 67.55, 52.2, 53.1, 54.0, 56.84, 53.1, 54.9, 54.0, 52.9, 40.99, 33.39,
  31.5, 20.92, 13.5, 9.0, 6.3, 2.31, 2.7, 1.67, 1.67, 0.65, 0.18, 0.14, 0.09,
  0.09, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.34, 1.8, 4.05, 3.77, 5.45, 9.09, 9.9,
  9.69, 10.07, 22.5, 21.6, 24.3, 24.99, 57.6, 52.2, 62.1, 62.91, 63.09, 70.83,
  64.63, 59.97, 52.2, 66.6, 63.9, 71.08, 61.2, 65.23, 66.51, 64.47, 55.67,
  55.85, 57.6, 54.28, 40.38, 44.07, 42.46, 42.92, 34.83, 36.19, 37.35, 36.38,
  23.37, 23.67, 19.51, 11.48, 16.56, 26.1, 33.3, 33.78, 14.13, 13.5, 7.2, 1.82,
  4.32, 2.49, 2.49, 0.67, 0.83, 0.81, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 7.22, 9.09, 12.56, 13.66, 16.18,
  15.92, 17.92, 22.38, 23.83, 24.73, 27.97, 27.97, 27.97, 27.97, 27.97, 32.27,
  34.07, 36.73, 43.86, 36.72, 29.25, 29.25, 21.78, 25.91, 28.09, 28.09, 30.27,
  31.32, 26.54, 26.54, 21.77, 43.88, 40.68, 40.92, 38.19, 27.26, 26.87, 24.23,
  18.58, 15.82, 13.07, 13.05, 10.08, 15.03, 11.25, 2.41, 0.95, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
];

export const production_forecast_maximum = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.52, 4.4, 5.5, 8.93, 11.0, 12.1,
  16.5, 20.44, 22.0, 38.5, 39.6, 40.7, 40.95, 63.8, 64.9, 66.0, 67.05, 68.2,
  69.3, 70.4, 71.21, 74.8, 75.9, 77.0, 74.92, 59.4, 60.5, 61.6, 63.14, 63.8,
  64.9, 66.0, 68.35, 79.2, 80.3, 81.4, 81.9, 77.0, 78.1, 79.2, 79.35, 79.2,
  80.3, 81.4, 82.57, 63.8, 64.9, 66.0, 69.47, 64.9, 67.1, 66.0, 64.65, 50.09,
  40.81, 38.5, 25.57, 16.5, 11.0, 7.7, 2.83, 3.3, 2.05, 2.05, 0.8, 0.22, 0.17,
  0.11, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.64, 2.2,
  4.95, 4.6, 6.66, 11.11, 12.1, 11.84, 12.31, 27.5, 26.4, 29.7, 30.55, 70.4,
  63.8, 75.9, 76.9, 77.11, 86.57, 78.99, 73.3, 63.8, 81.4, 78.1, 86.88, 74.8,
  79.73, 81.29, 78.79, 68.04, 68.26, 70.4, 66.34, 49.36, 53.87, 51.89, 52.45,
  42.57, 44.23, 45.65, 44.47, 28.57, 28.93, 23.84, 14.03, 20.24, 31.9, 40.7,
  41.28, 17.27, 16.5, 8.8, 2.23, 5.28, 3.05, 3.05, 0.82, 1.01, 0.99, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  8.82, 11.11, 15.35, 16.7, 19.78, 19.46, 21.9, 27.36, 29.13, 30.22, 34.19,
  34.19, 34.19, 34.19, 34.19, 39.44, 41.64, 44.89, 53.6, 44.88, 35.75, 35.75,
  26.62, 31.67, 34.33, 34.33, 37.0, 38.28, 32.44, 32.44, 26.61, 53.64, 49.72,
  50.01, 46.68, 33.32, 32.84, 29.62, 22.71, 19.34, 15.97, 15.95, 12.32, 18.37,
  13.75, 2.95, 1.16, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
];

// hourly

export const hourly_dates = [
  "15 Feb 2024 00:00",
  "15 Feb 2024 01:00",
  "15 Feb 2024 02:00",
  "15 Feb 2024 03:00",
  "15 Feb 2024 04:00",
  "15 Feb 2024 05:00",
  "15 Feb 2024 06:00",
  "15 Feb 2024 07:00",
  "15 Feb 2024 08:00",
  "15 Feb 2024 09:00",
  "15 Feb 2024 10:00",
  "15 Feb 2024 11:00",
  "15 Feb 2024 12:00",
  "15 Feb 2024 13:00",
  "15 Feb 2024 14:00",
  "15 Feb 2024 15:00",
  "15 Feb 2024 16:00",
  "15 Feb 2024 17:00",
  "15 Feb 2024 18:00",
  "15 Feb 2024 19:00",
  "15 Feb 2024 20:00",
  "15 Feb 2024 21:00",
  "15 Feb 2024 22:00",
  "15 Feb 2024 23:00",
  "16 Feb 2024 00:00",
  "16 Feb 2024 01:00",
  "16 Feb 2024 02:00",
  "16 Feb 2024 03:00",
  "16 Feb 2024 04:00",
  "16 Feb 2024 05:00",
  "16 Feb 2024 06:00",
  "16 Feb 2024 07:00",
  "16 Feb 2024 08:00",
  "16 Feb 2024 09:00",
  "16 Feb 2024 10:00",
  "16 Feb 2024 11:00",
  "16 Feb 2024 12:00",
  "16 Feb 2024 13:00",
  "16 Feb 2024 14:00",
  "16 Feb 2024 15:00",
  "16 Feb 2024 16:00",
  "16 Feb 2024 17:00",
  "16 Feb 2024 18:00",
  "16 Feb 2024 19:00",
  "16 Feb 2024 20:00",
  "16 Feb 2024 21:00",
  "16 Feb 2024 22:00",
  "16 Feb 2024 23:00",
  "17 Feb 2024 00:00",
  "17 Feb 2024 01:00",
  "17 Feb 2024 02:00",
  "17 Feb 2024 03:00",
  "17 Feb 2024 04:00",
  "17 Feb 2024 05:00",
  "17 Feb 2024 06:00",
  "17 Feb 2024 07:00",
  "17 Feb 2024 08:00",
  "17 Feb 2024 09:00",
  "17 Feb 2024 10:00",
  "17 Feb 2024 11:00",
  "17 Feb 2024 12:00",
  "17 Feb 2024 13:00",
  "17 Feb 2024 14:00",
  "17 Feb 2024 15:00",
  "17 Feb 2024 16:00",
  "17 Feb 2024 17:00",
  "17 Feb 2024 18:00",
  "17 Feb 2024 19:00",
  "17 Feb 2024 20:00",
  "17 Feb 2024 21:00",
  "17 Feb 2024 22:00",
  "17 Feb 2024 23:00",
];

export const hourly_actual_production = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.47, 27.12, 64.58, 145.23,
];
export const hourly_production_forecast_minimum = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 22.59, 63.05, 122.28, 207.49, 251.2, 256.88,
  221.38, 225.18, 270.87, 270.95, 274.75, 225.31, 214.9, 126.79, 31.11, 6.7,
  0.49, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.34, 15.07, 38.75, 93.39, 234.81,
  258.53, 253.78, 257.41, 223.39, 169.83, 144.75, 78.03, 109.74, 36.65, 9.98,
  1.63, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 28.86, 63.68,
  98.92, 111.89, 146.91, 117.0, 112.36, 106.18, 163.67, 96.94, 52.02, 29.64,
  0.0, 0.0, 0.0,
];

export const hourly_production_forecast_maximum = [
  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 27.61, 77.06, 149.46, 253.59, 307.03, 313.96,
  270.58, 275.22, 331.07, 331.16, 335.81, 275.37, 262.65, 154.97, 38.03, 8.19,
  0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.64, 18.41, 47.36, 114.15, 287.0,
  315.98, 310.18, 314.61, 273.03, 207.57, 176.92, 95.37, 134.12, 44.8, 12.2,
  2.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 35.28, 77.84,
  120.9, 136.76, 179.56, 143.0, 137.33, 129.78, 200.05, 118.48, 63.58, 36.23,
  0.0, 0.0, 0.0,
];

//daily
export const daily_dates = ["15 Feb 2024", "16 Feb 2024", "17 Feb 2024"];

export const daily_actual_production = [237.4];

export const daily_production_forecast_minimum = [2791.91, 1927.09, 1128.09];

export const daily_production_forecast_maximum = [3412.34, 2355.34, 1378.78];
