export interface Field {
    title: string;
    value: string | number;
    growth?: number;
    prevValue?: string | number;
    unit?: string;
    prevValueUnit?: string;
}

export interface Widget {
    profit: number;
    title: string;
    fields: Array<Field>;
    imageUrl?: string;
    latitude?: number;
    longitude?: number;
    path?: string;
    pr?: string;
    workingInverters?: number;
    totalInverters?: number;
    actualResult?: number;
    expectedResult?: number;
    chartType?: ChartTypes;
    chartData?: any;
    errors?: number;
    location?: string;
    weathers?: any;
}

export interface DropdownOption {
    label: string;
    value: any;
}

export type SetDateType =
    React.Dispatch<React.SetStateAction<{
        startDate: Date;
        endDate: Date;
        key: string;
    }[]>>


export interface DateType {
    startDate: Date;
    endDate: Date;
    key: string;
}

export interface XAxis {
    type?: string;
    categories: number[] | string[];
    endOnTick?: boolean;
    showLastLabel?: boolean;
    startOnTick?: boolean;
    visible?: boolean;
    tickPositioner?: Function;
    labels?: {
        rotation?: number;
        formatter?: Function;
    }
}
export interface YAxis {
    categories?: string[];
    labels?: {
        style?: {
            color?: string;
            fontFamily?: string;
            fontSize?: string;
        };
        format?: string;
    };
    visible: boolean;
    opposite: boolean;
    gridLineColor?: string;
    allowDecimals: boolean;
    type: string;
    gridLineWidth: number;
    title: {
        text: string;
        style?: {
            color?: string;
        };
    };
}

export enum ChartTypes {
    Bullet = 'bullet',
    BarChart = 'barChart',
    LineChart = 'lineChart'
}

export enum Fields {
    Plant = 'plant',
    empty = '',
    Alarms = 'alarms',
    Capacity = 'capacity',
    GenDown = 'genDown',
    Generation = 'generation',
    Insolation = 'insolation',
    PR = 'pr',
    TCPR = 'tcpr',
    CUF = 'cuf',
    PlantAv = 'plantAv',
    GridAv = 'gridAv',
    LastUpdated = 'lastUpdated',
    // ViewMore = 'viewMore',
}

export enum GenerationFields {
    Plant = 'plant',
    Capacity = 'capacity',
    PVGeneration = 'pvGeneration',
    Consumption = 'consumption',
    GridExport = 'gridExport',
    GridImport = 'gridImport',
    // ViewMore = 'viewMore',
}