import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    menuWrapper: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        paddingLeft: theme.spacing(3)
    },
    title: {
        fontSize: "24px",
        fontWeight: 500,
    },
    button: {
        display: "flex",
        fontSize: "14px",
        borderRadius: "6px !important",
        padding: "8px 24px !important",
    },
    cancelButton: {
        display: "flex",
        fontSize: "16px !important",
        borderRadius: "20px !important",
        padding: "8px 24px !important",
    },
    containerPadding: {
        padding: theme.spacing(2),
    },
    closeButton: {
        cursor: "pointer",
        paddingTop: theme.spacing(0.6)
    },
    ToDate: {
        padding: theme.spacing(2, 0, 0, 1)
    },
    FromDate: {
        padding: theme.spacing(2, 1, 0, 0)
    },
    formatType: {
        padding: theme.spacing(2, 0, 0, 0)
    },
    Granularity: {
        padding: theme.spacing(2, 1, 0, 0)
    },
    Format: {
        padding: theme.spacing(2, 0, 0, 1)
    }
}));