import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  barTabsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  tooltipHeader: {
    backgroundColor: "#222146",
    color: "white",
    padding: 0,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },

  barTabButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: 30,
    padding: theme.spacing(1, 3),
    margin: "0 3px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
  },

  barTabButtonSelected: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },

  marginRight: {
    marginRight: theme.spacing(2),
  },
}));
