import { combineReducers } from "@reduxjs/toolkit";
import { analyticsSharedReducer } from "./shared/analyticsSharedSlice";
import { analyticsDashboardReducer } from "./dashboard/dashboardSlice";


const AnalyticsReducer = combineReducers({
    analyticsShared: analyticsSharedReducer,
    analyticsDashboard: analyticsDashboardReducer,
});

export type AppState = ReturnType<typeof AnalyticsReducer>;

export default AnalyticsReducer;