import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { getPageItem } from "../../shared/utils/pages";
import { MenuItem, PageItem, Roles } from "../../shared/interfaces";
import { ReactComponent as ANALYTICS_LIGHT } from "../../shared/icons/sidebar/light/ANALYTICS_LIGHT.svg";
import { ReactComponent as ANALYTICS_BLACK } from "../../shared/icons/sidebar/dark/ANALYTICS_BLACK.svg";
import { AnalyticsNavigation } from "./AnalyticsNavigation.component";
import { AnalyticsDashboard } from "./AnalyticsDashboard";
import { AlarmOverviewPage } from "./AlarmOverviewPage/AlarmOverviewPage.component";
import { AnalyticsForecasting } from "./AnalyticsForecasting/AnalyticsForecasting.component";
import { Reports } from "../../sidem/pages/Reports";

export enum AnalyticsPagesEnum {
  AnalyticsDashboard = "/analytics/analytics-dashboard",
  Report = "/analytics/report",
  //demo
  AnalyticsForecasting = "/analytics/analytics-forecasting",
  // demo
  AlarmOverviewPage = "/analytics/alarm-overview",
}

type AnalyticsPagesMap = {
  [key in AnalyticsPagesEnum]?: PageItem;
};

const getPages = (): AnalyticsPagesMap => ({
  [AnalyticsPagesEnum.AnalyticsDashboard]: getPageItem(
    AnalyticsPagesEnum.AnalyticsDashboard,
    AnalyticsDashboard,
    ""
  ),
  [AnalyticsPagesEnum.AnalyticsForecasting]: getPageItem(
    AnalyticsPagesEnum.AnalyticsForecasting,
    AnalyticsForecasting,
    ""
  ),
  [AnalyticsPagesEnum.AlarmOverviewPage]: getPageItem(
    AnalyticsPagesEnum.AlarmOverviewPage,
    AlarmOverviewPage,
    ""
  ),
  [AnalyticsPagesEnum.Report]: getPageItem(
    AnalyticsPagesEnum.Report,
    Reports,
    ""
  ),
});

export const AnalyticsPages: React.FC<any> = ({
  imageService
}) => {
  const pages: AnalyticsPagesMap = useMemo(() => {
    return getPages();
  }, [imageService]);

  return (
    <Routes>
      {Object.values(pages).map(({ path, component: Component, params }) => (
        <Route
          key={path}
          path={path}
          element={<Component {...params} />}
        />
      ))}
    </Routes>
  );
};

export const getAnalyticsMenuItem = (
  SkyfriDemoRole: any,
  tenant: any
): MenuItem => ({
  idx: Roles.ANALYTICS,
  title: "ANALYTICS",
  Icon: ANALYTICS_LIGHT,
  DarkIcon: ANALYTICS_BLACK,
  navigation: <AnalyticsNavigation />,
  items: [
    {
      path: AnalyticsPagesEnum.AnalyticsDashboard,
      component: AnalyticsDashboard,
      title: "Dashboard",
      params: {},
    },
    SkyfriDemoRole && tenant === "greenproduction"
      ? {
        path: AnalyticsPagesEnum.AnalyticsForecasting,
        component: AnalyticsForecasting,
        title: "Forecasting",
        params: {},
      }
      : {
        path: AnalyticsPagesEnum.AnalyticsForecasting,
        component: AnalyticsForecasting,
        title: "",
        params: {},
      },
    {
      path: AnalyticsPagesEnum.Report,
      component: Reports,
      title: "Reports",
      params: {},
    },
    SkyfriDemoRole
      ? {
        path: AnalyticsPagesEnum.AlarmOverviewPage,
        component: AlarmOverviewPage,
        title: "Alarm Overview",
        params: {},
      }
      : {
        path: AnalyticsPagesEnum.AlarmOverviewPage,
        component: AlarmOverviewPage,
        title: "",
        params: {},
      },
  ],
});
