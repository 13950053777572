export const formSchema = {
  tenantName: {
    type: "text",
    label: "Tenant name*",
    required: true,
    requiredText: "Tenant name is required",
  },
};

export const formSchemaTeam = {
  teamName: {
    type: "text",
    label: "Team name*",
    required: true,
    requiredText: "Team name is required",
  },
};

export const formSchemaTeamMember = {
  tenantUserId: {
    type: "autocomplete",
    label: "User*",
    options: [],
    required: true,
    onEditDisplay: false,
    isAddButtonShow: false,
    requiredText: "User is required",
  },
};

export const formSchemaTeamPlant = {
  portfolioId: {
    type: "dropdown",
    label: "Portfolio*",
    options: [],
    required: true,
    onEditDisplay: false,
    isAddButtonShow: false,
    requiredText: "Portfolio is required",
  },

  plantId: {
    type: "dropdown",
    label: "Plant*",
    options: [],
    required: true,
    onEditDisplay: false,
    isAddButtonShow: false,
    requiredText: "Plant is required",
  },
};

export const formSchemaMpduleTenant = {
  ModulId: {
    type: "dropdown",
    options: [],
    label: "Module*",
    required: true,
    requiredText: "Module is required",
  },
};

export const portfolioformSchema = {
  portfolioName: {
    type: "text",
    label: "Name*",
    required: true,
    requiredText: "Name is required",
  },
};

export const userTenantFormSchema = {
  userId: {
    type: "AddMultiAutocomplete",
    label: "User*",
    options: [],
    required: true,
    onEditDisplay: false,
    onAddDisplay: true,
    requiredText: "User is required",
  },
  roles: {
    type: "AddMultiAutocomplete",
    options: [],
    label: "Role*",
    required: true,
    onEditDisplay: true,
    onAddDisplay: true,
    isMultiple: true,
    requiredText: "Role is required",
  },
};

export const userEditTenantFormSchema = {
  userId: {
    type: "autocomplete",
    label: "User*",
    options: [],
    required: true,
    onEditDisplay: false,
    onAddDisplay: true,
    requiredText: "User is required",
  },
  isActive: {
    type: "checkbox",
    label: "Active",
    required: false,
    onEditDisplay: true,
    onAddDisplay: false,
  },
  roles: {
    type: "roleDropdown",
    options: [],
    label: "Role*",
    required: true,
    onEditDisplay: true,
    onAddDisplay: true,
    isMultiple: true,
    requiredText: "Role is required",
  },
};

export const plantFormSchema = {
  plantName: {
    type: "text",
    label: "Plant*",
    required: true,
    requiredText: "Plant is required",
  },
  plantCode: {
    type: "text",
    label: "Plant code*",
    required: true,
    requiredText: "Plant code is required",
  },
  // plantTimeZone: {
  //   type: "text",
  //   label: "Plant code*",
  //   required: true,
  //   requiredText: "Plant code is required",
  // },
};

export const UserRoleformSchema = {
  roleId: {
    type: "dropdown",
    label: "Role Id*",
    options: [],
    required: true,
    requiredText: "Role Id is required",
  },
};

export const roleFormSchema = {
  name: {
    type: "text",
    label: "Name*",
    required: true,
    requiredText: "Name is required",
  },
};

export const roleTeamFormSchema = {
  TeamId: {
    label: "Name*",
    type: "dropdown",
    options: [],
    required: true,
    requiredText: "Team is required",
  },
};
