import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import {
  BatteryChargeDischargeHistoricalArgs,
  BatteryChargeDischargeHistoricalResponse,
  BatteryChargeDischargePowerArgs,
  BatteryChargeDischargePowerResponse,
  BatteryChargeDischargeResponse,
  BatteryChargeExtremasResponse,
  BatteryHeatmapResponse,
  BatteryHeatmapStringsAverageCurrentResponse,
  BatteryHeatmapStringsAverageTemperatureResponse,
  BatteryHeatmapStringsAverageVoltageResponse,
  BatteryHeatmapStringsMaxTemperatureResponse,
  BatteryHeatmapStringsMaxVoltageResponse,
  BatteryHeatmapStringsMinTemperatureResponse,
  BatteryHeatmapStringsMinVoltageResponse,
  BatteryInfoResponse,
  BatteryItemObject,
  BatteryPowerResponse,
  BatteryPowerStatusHistoricalResponse,
  BatterySocExtremasResponse,
  BatterySocHistoricalArgs,
  BatterySocHistoricalResponse,
  BatterySteeringSignalsArgs,
  BatterySteeringSignalsCountResponse,
  BatterySteeringSignalsResponse,
  BatteryVoltageAndFrequencyHistoricalArgs,
  BatteryVoltageAndFrequencyHistoricalResponse,
  BatteryVoltageAndFrequencyResponse,
} from "./BatteryMonitoring.api.types";


export const transformBatteryChargeDischargePowerResponse = (
  response: BatteryChargeDischargePowerResponse,
  meta: FetchBaseQueryMeta,
  args: BatteryChargeDischargePowerArgs
) => {
  return {
    ...response,
    items: response.items,
  };
};

export const transformBatteryHeatmapResponse = (
  response: BatteryHeatmapResponse
) => {
  return response;
};

export const transformBatterySocHistoricalResponse = (
  response: BatterySocHistoricalResponse,
  meta: FetchBaseQueryMeta,
  args: BatterySocHistoricalArgs
) => {
  return response
};

export const transformBatterySocExtremasResponse = (
  response: BatterySocExtremasResponse
) => {
  return response;
};

export const transformBatteryPowerStatusHistoricalResponse = (
  response: BatteryPowerStatusHistoricalResponse
) => {
  return response;
};

export const transformBatteryVoltageAndFrequencyHistoricalResponse = (
  response: BatteryVoltageAndFrequencyHistoricalResponse,
  meta: FetchBaseQueryMeta,
  args: BatteryVoltageAndFrequencyHistoricalArgs
) => {
  //
  const current: BatteryItemObject[] = [];
  const voltage_ll: BatteryItemObject[] = [];
  const voltage_ln: BatteryItemObject[] = [];
  const frequency: BatteryItemObject[] = [];
  const timestamp: string[] = [];

  const defaultItem = { value: null, unit: "", date: "" };

  response.items.forEach((item) => {
    current.push(item.current || defaultItem);
    voltage_ll.push(item.voltage_ll || defaultItem);
    voltage_ln.push(item.voltage_ln || defaultItem);
    frequency.push(item.frequency || defaultItem);
    timestamp.push(item.timestamp);
  });

  const items = new Array(frequency.length)
    .fill(null)
    .map((_, index) => ({
      current: current[index],
      voltage_ll: voltage_ll[index],
      voltage_ln: voltage_ln[index],
      frequency: frequency[index],
      timestamp: frequency[index].date,
    }));

  return { ...response, items };
};

export const transformBatteryChargeDischargeResponse = (
  response: BatteryChargeDischargeResponse
) => {
  return response;
};

export const transformBatteryChargeExtremasResponse = (
  response: BatteryChargeExtremasResponse
) => {
  return response;
};

export const transformBatteryChargeDischargeHistoricalResponse = (
  response: BatteryChargeDischargeHistoricalResponse,
  meta: FetchBaseQueryMeta,
  args: BatteryChargeDischargeHistoricalArgs
) => {
  let charge: BatteryItemObject[] = [];
  let discharge: BatteryItemObject[] = [];

  const defaultItem: BatteryItemObject = { value: null, unit: "", date: "" };

  let chargeUnit = "";
  let dischargeUnit = "";

  response.items.forEach((item) => {
    charge.push(
      item?.charged
        ? { ...item.charged, date: item.timestamp }
        : { ...defaultItem, date: item.timestamp }
    );

    discharge.push(
      item?.discharged
        ? { ...item.discharged, date: item.timestamp }
        : { ...defaultItem, date: item.timestamp }
    );

    if (item?.charged?.unit) {
      chargeUnit = item.charged.unit;
    }

    if (item?.discharged?.unit) {
      dischargeUnit = item.discharged.unit;
    }
  });

  charge = charge.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  discharge = discharge.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return {
    charge,
    discharge,
    chargeUnit,
    dischargeUnit,
  };
};

export const transformBatteryHeatmapStringsAverageCurrentResponse = (
  response: BatteryHeatmapStringsAverageCurrentResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsAverageTemperatureResponse = (
  response: BatteryHeatmapStringsAverageTemperatureResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsMinTemperatureResponse = (
  response: BatteryHeatmapStringsMinTemperatureResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsMaxTemperatureResponse = (
  response: BatteryHeatmapStringsMaxTemperatureResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsAverageVoltageResponse = (
  response: BatteryHeatmapStringsAverageVoltageResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsMinVoltageResponse = (
  response: BatteryHeatmapStringsMinVoltageResponse
) => {
  return response;
};

export const transformBatteryHeatmapStringsMaxVoltageResponse = (
  response: BatteryHeatmapStringsMaxVoltageResponse
) => {
  return response;
};

export const transformBatteryInfoResponse = (response: BatteryInfoResponse) => {
  return response;
};

export const transformBatterySteeringSignalsResponse = (
  response: BatterySteeringSignalsResponse
) => {
  return response;
};

export const transformBatterySteeringSignalsCountResponse = (
  response: BatterySteeringSignalsCountResponse
) => {
  return response;
};
