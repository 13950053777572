import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import clsx from "clsx";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Button, IconButton } from "@material-ui/core";
import { ReactComponent as TRANSPARENT_STRIPE } from "../../../shared/icons/app/TRANSPARENT_STRIPE.svg";
import { ReactComponent as STRIPE } from "../../../shared/icons/app/STRIPE.svg";
import { ReactComponent as EXTERNALLINK } from "../../../shared/icons/app/EXTERNALLINK.svg";
import { ReactComponent as RELEASENOTES } from "../../../shared/icons/app/RELEASENOTES.svg";
import { ReactComponent as DOCUMENT } from "../../../shared/icons/app/DOCUMENT.svg";
import { ReactComponent as LOGOUT } from "../../../shared/icons/app/LOGOUT.svg";
import { RELEASE_NOTES_URI, GUIDE_URI } from "./constants";
import { useProfile } from "./useProfile";
import { Skeleton } from "@mui/material";
import { useStyles } from "./styles";

const guideURI = GUIDE_URI;
const releaseNotesURI = RELEASE_NOTES_URI;

export const UserProfile: React.FC<any> = ({
  drawerOpen,
  active,
  setActive,
  isMobile,
}) => {
  const classes = useStyles();
  const {
    getInitials,
    onSwitchTenant,
    logOut,
    downloadURI,
    accounts,
    openUserProfile,
    setOpenUserProfile,
    tenants,
    setAnchorEl,
    setAnchorE2,
    setChildren,
    loading,
  } = useProfile();

  const openChildMenu = (event?: any, child?: any) => {
    setAnchorE2(event?.currentTarget);
    setChildren(child);
  };

  const menuOptions = [
    {
      label: "Documentation",
      icon: <DOCUMENT />,
      onClick: () => {
        downloadURI(guideURI, "Skyfri Intelligence Docs");
      },
    },
    {
      label: "Release Notes",
      icon: <RELEASENOTES />,
      onClick: () => {
        downloadURI(releaseNotesURI, "Release Notes");
      },
    },
  ];

  const renderMenuItem = (item, idx) => {
    if (item.child) {
      return (
        <div
          key={idx + item.label}
          className={classes.menuItem}
          onClick={(event) => openChildMenu(event, item.child)}
        >
          {item.label}
        </div>
      );
    }
    return (
      <div
        key={idx + item.label}
        className={clsx(
          { [classes.menuItem]: !isMobile },
          {
            [classes.menuItemMobile]: isMobile,
          }
        )}
        onClick={item.onClick}
      >
        <div className={classes.itemWrapper}>
          <div style={{ position: "relative", top: "2px" }}>{item.icon}</div>
          <span className={classes.itemLabel}>{item.label}</span>
        </div>
        <div className={classes.itemWrapper}>
          <EXTERNALLINK />
        </div>
      </div>
    );
  };

  const handleUserProfileClick = useCallback((event) => {
    setOpenUserProfile((openUserProfile) => !openUserProfile);
    setAnchorEl(event.currentTarget);
    setActive((active) => !active);
  }, []);

  return (
    <div
      id="userProfile"
      style={{
        width: "100%",
        height: 56,
        display: "flex",
        alignItems: "center",
      }}
      className={clsx(
        { [classes.userProfile]: !isMobile },
        {
          [classes.userProfileOpen]: drawerOpen && !isMobile,
        },
        {
          [classes.userProfileActive]: openUserProfile && active && !isMobile,
        }
      )}
      onClick={handleUserProfileClick}
    >
      <div
        style={{
          height: 56,
          display: "flex",
          width: isMobile && "56px",
          marginRight: isMobile && "-15px",
        }}
      >
        <span>
          {!isMobile && openUserProfile ? <STRIPE /> : <TRANSPARENT_STRIPE />}
        </span>
        <span
          className={clsx(
            { [classes.initials]: !isMobile },
            { [classes.initialsMobile]: isMobile }
          )}
          style={{
            backgroundColor:
              isMobile && openUserProfile && "rgba(151, 206, 253, 0.1)",
          }}
        >
          <IconButton className={classes.userInitials}>
            {getInitials()}
          </IconButton>
        </span>
      </div>
      {!isMobile && drawerOpen && (
        <span className={classes.menuTitle}>{accounts[0]?.username}</span>
      )}
      {isMobile && openUserProfile && (
        <SwipeableDrawer
          anchor={"top"}
          sx={{ top: "100px" }}
          open={openUserProfile}
          onClose={() => setOpenUserProfile(false)}
          onOpen={() => setOpenUserProfile(true)}
        >
          <Box
            sx={{
              height: "100vh",
              marginTop: "55px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="presentation"
          >
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.mobileHeader}>
                <div className={classes.userContainer}>
                  <div className={classes.userItem}>
                    <div className={classes.userLogo}>{getInitials()}</div>
                    <div>
                      <div className={classes.userName}>
                        {accounts[0]?.name}
                      </div>
                      <div className={classes.userEmail}>
                        {accounts[0]?.username}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.mobileSection}>
                {tenants?.length < 2 ? null : (
                  <>
                    <div style={{ margin: "20px" }}>
                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          width={540}
                          height={40}
                        />
                      ) : (
                        tenants?.map((item) => (
                          <Grid
                            container
                            spacing={1}
                            key={item?.tenantId}
                            className={classes.tenantWrapper}
                          >
                            <Grid
                              item
                              xs={9}
                              className={classes.tenantName}
                              style={{ color: "black" }}
                            >
                              {item?.tenantName}
                            </Grid>
                            <Grid item xs={3} className={classes.switchWrapper}>
                              {item?.isActive ? (
                                <div className={classes.current}>Current</div>
                              ) : (
                                <div
                                  className={classes.switch}
                                  onClick={() => onSwitchTenant(item)}
                                >
                                  Switch
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        ))
                      )}
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.support}
                sx={{ display: "flex ", flexDirection: "column" }}
              >
                {menuOptions?.map(renderMenuItem)}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.mobileButton}>
              <Button
                style={{ textTransform: "none" }}
                onClick={() => logOut()}
                variant="text"
                className={classes.logOutMobile}
                startIcon={<LOGOUT />}
              >
                Log out
              </Button>
            </Grid>
          </Box>
        </SwipeableDrawer>
      )}
      {!isMobile && openUserProfile && (
        <Box
          style={{
            borderRadius: "6px",
            left: drawerOpen ? "230px" : "65px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            position: "absolute",
            bottom: 0,
            width: "300px",
            backgroundColor: "#FFFFFF",
            cursor: "default",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.header}>
              <div className={classes.userContainer}>
                <div className={classes.userItem}>
                  <div className={classes.userLogo}>{getInitials()}</div>
                  <div>
                    <div className={classes.userName}>{accounts[0]?.name}</div>
                    <div className={classes.userEmail}>
                      {accounts[0]?.username}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              {loading ? (
                <Skeleton variant="rectangular" width={260} height={20} />
              ) : (
                tenants?.map((item) => (
                  <Grid
                    container
                    spacing={1}
                    key={item?.tenantId}
                    className={classes.tenantWrapper}
                  >
                    <Grid
                      item
                      xs={9}
                      className={classes.tenantName}
                      style={{ color: "black" }}
                    >
                      {item?.tenantName}
                    </Grid>
                    <Grid item xs={3} className={classes.switchWrapper}>
                      {item?.isActive ? (
                        <div className={classes.current}>Current</div>
                      ) : (
                        <div
                          className={classes.switch}
                          onClick={() => onSwitchTenant(item)}
                        >
                          Switch
                        </div>
                      )}
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.mobileSupport}
              sx={{ display: "flex ", flexDirection: "column" }}
            >
              {menuOptions?.map(renderMenuItem)}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={clsx(
              { [classes.button]: !isMobile },
              { [classes.mobileButton]: isMobile }
            )}
          >
            <Button
              style={{ textTransform: "none" }}
              onClick={() => logOut()}
              variant="text"
              className={classes.logOut}
              startIcon={<LOGOUT />}
            >
              Log out
            </Button>
          </Grid>
        </Box>
      )}
    </div>
  );
};
