import { getBearerToken } from "../../../store/authToken";
import { axGet } from "../../../store/axiosClient";
import * as PortfolioSlice from "./portfolioSlice";

const API_URL = "/SkyfriSidemApi/v1";

export function getPortfolios(format: string) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PortfolioSlice.portfoliosStart());
    try {
      const token = await getBearerToken();
      const data: any = await axGet(
        API_URL + `/GetPortfolioCardsKPIs?timeRequested=${format}`,
        token
      );
      dispatch(PortfolioSlice.portfoliosSuccess(data));
      return data;
    } catch (error) {
      dispatch(PortfolioSlice.portfoliosError(error));
    }
  };
}

export function getPortfolioCardsPlantSpecific(
  format: string,
  portfolio: string
) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PortfolioSlice.portfolioCardsStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL +
        `/GetPortfolioCardsPlantSpecific?PortfolioName=${portfolio}&timeRequested=${format}`,
        token
      );
      // console.log(data);

      dispatch(PortfolioSlice.portfolioCardsSuccess(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PortfolioSlice.portfolioCardsError());
    }
  };
}

export function getPortfolioCardsPlantSpecificToday(portfolio: string) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PortfolioSlice.portfolioCardsTodayStart());

    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL +
        `/GetPortfolioCardsPlantSpecificToday?PortfolioName=${portfolio}`,
        token
      );
      // console.log(data);
      dispatch(PortfolioSlice.portfolioCardsTodaySuccess());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PortfolioSlice.portfolioCardsTodayError());
    }
  };
}
export function getPortfolioCardsKPIsToday() {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PortfolioSlice.portfolioCardsKPITodayStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(API_URL + `/GetPortfolioCardsKPIsToday`, token);
      // console.log(data);
      dispatch(PortfolioSlice.portfolioCardsKPITodaySuccess());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PortfolioSlice.portfolioCardsKPITodayError());
    }
  };
}

export function getPortfolioPopupKPIsToday(format: string) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PortfolioSlice.portfolioPopupKPIsTodayStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(API_URL + `/GetPlantSummaryKPIs?timeRequested=${format}`, token);
      dispatch(PortfolioSlice.portfolioPopupKPIsTodaySuccess());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PortfolioSlice.portfolioPopupKPIsTodayError());
    }
  };
}
