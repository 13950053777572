import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
	},
}));

interface DaysProps {
	value: number;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	onChange: (val: any) => void;
}

interface TimeProps {
	value: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	onChange: (val: any) => void;
}

interface Props {
	daysProps?: DaysProps;
	timeProps?: TimeProps;
	width?: string;
	inHeight?: string;
}

export const DaysHoursSelector: React.FC<Props> = ({
	daysProps,
	timeProps,
	inHeight,
	width,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			{!daysProps ? null : (
				<TextField
					variant="outlined"
					type="number"
					label={daysProps.label}
					value={daysProps.value}
					disabled={daysProps?.disabled}
					placeholder={daysProps?.placeholder}
					onChange={(e) => daysProps.onChange(parseInt(e.target.value))}
					style={{ marginRight: 16 }}
				/>
			)}

			{!timeProps ? null : (
				<TextField
					id="time"
					label={timeProps.label}
					type="time"
					disabled={timeProps?.disabled}
					value={timeProps.value}
					placeholder={timeProps?.placeholder}
					onChange={(e) => timeProps.onChange(e.target.value)}
					sx={{
						"& .MuiInputBase-input": {
							height: inHeight,
						},
						width: width || 150,
						backgroundColor: timeProps.disabled? "#E1E4E9":"",
					}}
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{
						step: 300, // 5 min
					}}
				/>
			)}
		</div>
	);
};
