
import { RenderDropdown } from "../RenderDropdown";
import { RenderTextfield } from "../RenderTextField";
import { RenderDatePicker } from "../RenderDatePicker";
import { RenderRadio } from "../RenderRadio";
import { RenderLabel } from '../RenderLabel';
import { RenderCheckbox } from '../RenderCheckbox';
import { RenderAutoComplete } from '../RenderAutoComplete';
import { RenderMultiSelect } from "../RenderMultiSelect";

export const DynamicField = (dynamicProps: any) => {
  const renderInput = (formik, data: any, name: string, options: Array<string>, handleChange, width, onClick, value) => {
    switch (data.type) {
      case "roleDropdown": //Dropdown
        return <RenderMultiSelect
          options={data.options}
          handleChange={handleChange}
          value={value} />;
      case "dropdown": //Dropdown
        return <RenderDropdown
          isAdd={dynamicProps.isAdd}
          showOnEdit={data?.onEditDisplay}
          showOnAdd={data?.onAddDisplay}
          showAdd={data.showAdd}
          multiple={data?.isMultiple}
          onClick={(type) => onClick(type)}
          label={data.label}
          name={name}
          formik={formik}
          Data={data.options}
          handleChange={handleChange}
          width={width} />;
      case "text": //Text Field
        return <RenderTextfield info={data.info} label={data.label} type={data.type} name={name} formik={formik} width={data.width} />;
      case "number": //Number Field
        return <RenderTextfield label={data.label} type={data.type} name={name} formik={formik} />;
      case "email": //Email Field
        return <RenderTextfield label={data.label} type={data.type} name={name} formik={formik} disabled={dynamicProps.isEdit} />;
      case "date":
        return <RenderDatePicker label={data.label} name={name} formik={formik} />
      case "radio":
        return <RenderRadio default={data.default} label={data.label} name={name} formik={formik} value={name} options={data.options || []} isChecked={data.isChecked || false} />
      case "checkbox":
        return <RenderCheckbox isAdd={dynamicProps.isAdd} showOnEdit={data?.onEditDisplay} showOnAdd={data?.onAddDisplay} default={data.default} label={data.label} name={name} formik={formik} value={name} options={data.options || []} isChecked={data.isChecked || false} />
      case "label":
        return <RenderLabel label={data.label} type={data.type} name={name} formik={formik} />;
      case "autocomplete": //AutoComplete
        return (
          <RenderAutoComplete
            isAddButtonShow={data?.isAddButtonShow}
            handleAddClick={() => dynamicProps.handleAddClick()}
            isAdd={dynamicProps.isAdd}
            showOnEdit={data?.onEditDisplay}
            showOnAdd={data?.onAddDisplay}
            showAdd={data.showAdd}
            onClick={(type) => onClick(type)}
            label={data.label}
            name={name}
            formik={formik}
            Data={data.options}
            handleChange={handleChange}
            width={width}
          />
        );
      case "Addautocomplete": //Dropdown
        return <RenderAutoComplete
          isAddButtonShow={false}
          handleAddClick={() => dynamicProps.handleAddClick()}
          isAdd={dynamicProps.isAdd}
          showOnEdit={data?.onEditDisplay}
          showOnAdd={data?.onAddDisplay}
          showAdd={data.showAdd}
          onClick={(type) => onClick(type)}
          label={data.label}
          name={name}
          formik={formik}
          Data={data.options}
          handleChange={handleChange}
          width={width}
        />;
      case "AddMultiAutocomplete": //Dropdown
        return <RenderDropdown
          isAdd={dynamicProps.isAdd}
          showOnEdit={data?.onEditDisplay}
          showOnAdd={data?.onAddDisplay}
          showAdd={data.showAdd}
          multiple={data?.isMultiple}
          onClick={(type) => onClick(type)}
          label={data.label}
          name={name}
          formik={formik}
          Data={data.options}
          handleChange={handleChange}
          width={width} />;
      default:
        return (
          <RenderTextfield
            label={data.label}
            type={data.type}
            name={name}
            formik={formik}
            width={data.width}
          />
        );
    }
  }

  return (
    <>
      {
        renderInput(dynamicProps.formik, dynamicProps.data, dynamicProps.name, dynamicProps.options, dynamicProps.handleChange, dynamicProps.width, dynamicProps.onClick, dynamicProps.value,)
      }
    </>
  )

}