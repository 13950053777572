
export const MIN_COLOR = "#F3F2EF";
export const MAX_COLOR = "#F3A124";
export const getBackground = (
  max: number,
  min: number,
  value: number
): string => {
  const diaposon = max - min;
  const currentVal = value - min;
  const percent = (currentVal / diaposon) * 100;
  const percentInDec = parseInt((percent / 10)?.toString());
  switch (percentInDec) {
    case 9:
      return MIN_COLOR;
    case 8:
      return MIN_COLOR;
    case 7:
      return "#FAE7CB";
    case 6:
      return "#FAE7CB";
    case 5:
      return "#F6D5AF";
    case 4:
      return "#F6D5AF";
    case 3:
      return "#F6D5AF";
    case 2:
      return "#F4BE7E";
    case 1:
      return "#F4BE7E";
    case 0:
      return MAX_COLOR;
  }
  return MIN_COLOR;
};
export const legendArr = [
  {
    name: "Negligible",
    color: "#F3F2EF",
  },
  {
    name: "Low",
    color: "#FAE7CB",
  },
  {
    name: "Medium",
    color: "#F6D5AF",
  },
  {
    name: "High",
    color: "#F4BE7E",
  },
  {
    name: "Critical",
    color: "#F3A124",
  },
];
export const heatMapColors = {
  Negligible: "#F3F2EF",
  Low: "#FAE7CB",
  Medium: "#F6D5AF",
  High: "#F4BE7E",
  Critical: "#F3A124",
}