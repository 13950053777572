import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  userMenuOpen: {
    display: "flex",
    alignItems: "center",
  },
  onHover: {
    "&:hover": {
      backgroundColor: "rgba(151, 206, 253, 0.1)",
      cursor: "pointer",
      borderRadius: "0px",
    },
  },
  menuBorder: {
    width: "100%",
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
  },
  collapseMenuTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "#CACFD7 !important",
    marginLeft: "2px",
    marginTop: "-5px",
    fontVariant: "all-small-caps",
  },
}));
