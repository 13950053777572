import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(2, 2, 2, 2),
        borderRadius: theme.spacing(1),
        marginTop: "16px",
    },
}));