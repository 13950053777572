import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBearerToken } from "../../../../store/authToken";
import {
  BatteryData,
  HistoricalBatteryData,
} from "../services/BattteryMonitoringDataTypes";
import {
  transformBatteryData,
  transformHistoricalBatteryData,
} from "../utils/tranformBatteryData";

const BASE_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://skifryfpel-dev-apimngment.azure-api.net/";

const batteryApiUrl = `SkyfriEMSApi/v1`;

const getQueryParams = ({ fromDate, toDate }) => {
  return `fromDate=${fromDate}&toDate=${toDate}`;
};

export const batteryMonitoringApi = createApi({
  reducerPath: "batteryMonitoringApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}${batteryApiUrl}`,
    prepareHeaders: async (headers) => {
      const token = await getBearerToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
        headers.set("Content-Type", "text/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHistoricalBatteryData: builder.query({
      query: ({ fromDate, toDate }) => ({
        url: `Plant/batteriesHistorical?${getQueryParams({
          fromDate,
          toDate,
        })}`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: (response: HistoricalBatteryData[]) =>
        transformHistoricalBatteryData(response),
    }),
    getLiveBatteryData: builder.query({
      query: () => ({
        url: `Plant/batteriesLive`,
        method: "GET",
        cache: "no-cache",
      }),
      transformResponse: (response: BatteryData[]) =>
        transformBatteryData(response),
    }),
  }),
});

export const { useGetHistoricalBatteryDataQuery, useGetLiveBatteryDataQuery } =
  batteryMonitoringApi;
