import { getBearerToken } from "../../../store/authToken";
import { axGet, axPost } from "../../../store/axiosClient";
import axios from "axios";

export function getCrossEquipmentData(portfolio: string, plant: string) {
  return async () => {
    try {
      const token = await getBearerToken();
      const data: any = await axGet(
        `SkyfriSidemApi/v1/GetCrossEquipmentStructure?portfolioName=${portfolio}&plantId=${plant}`,
        token
      );
      return data;
    } catch (error) {
      return error.response.data
    }
  };
}
export function getCrossEquipmentComparisonData(
  portfolio: string,
  plant: string,
  FromDate: any,
  ToDate: any,
  args: any,
  aggregation: string,
) {
  return async () => {
    try {
      const token = await getBearerToken();
      const data: any = await axPost(
        `SkyfriSidemApi/v1/CrossEquipmentComparison`,
        {
          portfolioName: portfolio,
          plantId: plant,
          FromDate: FromDate,
          ToDate: ToDate,
          aggregation: aggregation,
          ...args,
        },
        token
      );
      return data;
    } catch (error) {
      return error.response.data
    }
  };
}

export function downloadCrossEquipmentComparisonData(
  portfolio: string,
  plant: string,
  FromDate: any,
  ToDate: any,
  args: any,
  aggregation: string,
) {
  return async () => {
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const data: any = await axios({
        method: "post",
        url:
          BASE_API_URL +
          `SkyfriSidemApi/v1/CrossEquipmentComparison/Report`,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
        data: {
          portfolioName: portfolio,
          plantId: plant,
          FromDate: FromDate,
          ToDate: ToDate,
          aggregation: aggregation,
          ...args,
        }
      });
      return data;
    } catch (error) {
      return error.response.data
    }
  };
}
