import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Modal, ActionButtons } from '../../../shared/components';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    margin: theme.spacing(2, 0, 2, 0),
    fontSize: 16,
    color: "black",
  },
}));

interface Props {
  isOpen: boolean;
  title?: string;
  titleComponent?: React.ReactNode;
  onClose: () => void;
  applyBtnTitle: string;
  applyColor?: string;
  onApply: (ptwId: any) => void;
  message: string;
  children?: React.ReactNode;
  width?:string;
  height?: string;
  marginTop?: string;
  startIcon?: any;
  justifyContent?:string;
}

export const ConfirmationModal: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { isOpen,width,height, title, titleComponent, applyBtnTitle, applyColor, onClose, onApply, message ,children, startIcon, marginTop, justifyContent} = props;

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      titleComponent={titleComponent}
      onClose={onClose}
      width={width}
      height={height}
      content={
       message? <div className={classes.message} style={{marginTop: marginTop? marginTop:"0px"}}>{message}</div> :''
      }
      children={children}
      actions={
        <ActionButtons
          saveTitle={applyBtnTitle}
          saveButtonType="submit"
          saveButtonColor={applyColor}
          onSave={onApply}
          cancelTitle='Cancel'
          onCancel={onClose}
          startIcon={startIcon}
          justifyContent={justifyContent}
        />
      }
    />
  );
}
