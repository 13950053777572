import { useEffect, useLayoutEffect, useState } from "react";
import {
  DateType,
  DropdownOption,
  SetDateType,
} from "../../../../components/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { analyticsSharedSelector } from "../../../../../features/Analytics/shared/analyticsSharedSlice";
import {
  setDateComparison,
  setGlobalDateAction,
  setGlobalLiveButtonAction,
} from "../../../../../features/Analytics/shared/analyticsSharedActions";
import { checkDate, convertTime } from "../../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetPortfoliosAndPlantsQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";

interface Data {
  portfolio: any;
  plant: any;
  selectedPlant: any;
  selectedPortfolio: any;
  setSelectedPlant: any;
  setSelectedPortfolio: any;
  date: DateType[];
  setDate: SetDateType;
  liveButton: boolean;
  checkLiveButton: (val: boolean) => void;
  onChangePortfolio: (value: string) => void;
  onChangePlant: (value: string) => void;
}

export const useHeaderWidget = (): Data => {
  const dispatch = useDispatch();

  const [url, setUrl] = useSearchParams({
    portfolioId: "",
    plantId: "",
    startDate: "",
    endDate: "",
  });
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { globalDate, liveButton } = useSelector(analyticsSharedSelector);

  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [plant, setPlant] = useState([]);
  const [tenantInfo, setTenantInfo] = useState([]);

  const [liveButtonState, setLiveButtonState] = useState(liveButton);
  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate[0].startDate),
      endDate: checkDate(endDate, globalDate[0].endDate),
      key: "selection",
    },
  ]);
  const { data: portfoliosAndPlantsData } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });

  useEffect(() => {
    if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0)
      return;
    setTenantInfo(portfoliosAndPlantsData);
  }, [portfoliosAndPlantsData]);

  useEffect(() => {
    getPortfolio(tenantInfo);
  }, [tenantInfo]);

  const onChangePortfolio = (value) => {
    const newPlants = [];
    setSelectedPortfolio(value);
    tenantInfo?.filter((item) => {
      if (item?.portfolioName === value) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    setSelectedPlant(newPlants?.[0].value);
  };

  const onChangePlant = (value) => {
    setSelectedPlant(value);
  };
  const getPortfolio = (data: any) => {
    let fil = [];
    let newPortfolio: Array<DropdownOption> | any[] = [];
    data?.filter((item) => {
      if (!fil.includes(item?.portfolioName)) {
        fil.push(item?.portfolioName);
        newPortfolio.push({
          label: item?.portfolioName,
          value: item?.portfolioName,
        });
      }
    });
    setPortfolio(newPortfolio);
    setSelectedPortfolio(newPortfolio?.[1]?.value); // demo
  };

  useEffect(() => {
    if (selectedPortfolio) {
      const newPlants: any[] = [];
      tenantInfo?.filter((item) => {
        if (item?.portfolioName === selectedPortfolio) {
          newPlants?.push({
            label: item?.plantName,
            value: item?.plantId,
          });
        }
      });

      setPlant(newPlants);
      setSelectedPlant(newPlants?.[0]?.value);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));
    const startTime = convertTime(date[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(date[0].endDate, "YYYY.MM.DD");
    if (startTime !== endTime) {
      dispatch(setDateComparison(false));
    } else {
      dispatch(setDateComparison(true));
    }

    setUrl(
      (prev) => {
        prev.set("startDate", date[0].startDate.toString());
        return prev;
      },
      { replace: true }
    );
    setUrl(
      (prev) => {
        prev.set("endDate", date[0].endDate.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  useLayoutEffect(() => {
    dispatch(setGlobalLiveButtonAction(liveButtonState));
  }, [liveButtonState]);

  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date[0].startDate : startDate,
      "YYYY.MM.DD"
    );
    const endTime = convertTime(
      !endDate ? date[0].endDate : endDate,
      "YYYY.MM.DD"
    );
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");
    if (
      startTime !== endTime ||
      (startTime !== dateToday && endTime !== dateToday)
    )
      setLiveButtonState(false);
  }, [liveButtonState, startDate, endDate]);

  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date[0].startDate : startDate,
      "YYYY.MM.DD"
    );
    const endTime = convertTime(
      !endDate ? date[0].endDate : endDate,
      "YYYY.MM.DD"
    );
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");

    if (startTime === dateToday && endTime === dateToday)
      setLiveButtonState(true);
  }, [startDate, endDate]);

  const checkLiveButton = (e) => {
    if (!liveButtonState) {
      setDate([
        {
          endDate: new Date().toISOString(),
          key: "selection",
          startDate: new Date().toISOString(),
        },
      ]);
    }
    setLiveButtonState(e);
  };

  return {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    date,
    setDate,
    liveButton,
    checkLiveButton,
    setSelectedPortfolio,
    setSelectedPlant,
    onChangePortfolio,
    onChangePlant,
  };
};
