import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../shared/utils/apiService";
interface Props {
  usertenantId: string;
  tenantId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  Subsidiary: any;
  Contacts: any;
  Company: any;
  user: any;
  userTenant: any;
  userRole: any;
  deleteRole: (roleId) => void;
  loading: any;
  getUsers: () => void;
  role: any;
}

export const useUserTenantSave = ({
  usertenantId,
  tenantId,
}: Props): Data => {
  const [Subsidiary, setSubsidiaries] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [userTenant, setUserTenant] = useState(null);
  const [userRole, setUserRole] = useState([]);
  const [loading, setloading] = useState(null);
  const [role, setRole] = useState(null);

  const fetchAll = async () => {
    await getRole();
    await getUsers();
    await getUserTenant();
    await getUserRoles();
  };

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    if (!userRole || !userTenant) return;
    var userRoles = userRole.map((x) => x.roleId);

    let newUserTenant = userTenant;
    newUserTenant.roles = userRoles || [];
    setUserTenant(newUserTenant);
  }, [userRole]);

  const getRole = async () => {
    await fetchAsync(`onboardingV2/v1/Roles/GetAllRoles`, "GET")
      .then((data: any) => {
        if (data) {
          let newRole = data?.map((item) => ({
            ...item,
            value: item.roleId,
            label: item.roleName,
          }));
          setRole(newRole);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";
    let url = "onboardingV2/v1/TenantUsers/AddUserToTenant";

    if (id && id !== "new") {
      params.TenantUserId = id;
      requestType = "PUT";
      url = "onboardingV2/v1/TenantUsers/UpdateTenantUser";
    }

    await fetchAsync(url, requestType, params)
      .then((data: any) => {
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  const getUserTenant = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/TenantUsers/GetTenantUsers?tenantId=${tenantId}`, "GET")
      .then(async (data: any) => {
        if (data) {
          let newUserTenant = data?.map((item) => ({
            ...item,
            id: item.tenantUserId,
            roles: [],
          }));
          let currentUserTenant = newUserTenant.find(
            (x) => x.id === usertenantId
          );
          setUserTenant(currentUserTenant);

          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getUserRoles = async () => {
    if (usertenantId === "new") return;
    setloading(true);
    await fetchAsync(`onboardingV2/v1/UsersToRoles/GetUserRolesByTenantUserId?tenantUserId=${usertenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newUserRole = data?.map((item) => ({
            ...item,
            id: item.userRoleId,
          }));
          setUserRole(newUserRole);
          setloading(false);
          return newUserRole;
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const deleteRole = async (roleId) => {
    setloading(true);
    await fetchAsync(`/UserRole?id=${roleId}`, "DELETE")
      .then(() => {
        getUserRoles();
        setloading(false);
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getUsers = async () => {
    if (usertenantId !== "new") return;
    setloading(true);
    await fetchAsync(`onboardingV2/v1/User/GetAllUsers`, "GET")
      .then((data: any) => {
        if (data) {
          let newUser = data?.map((item) => ({
            ...item,
            id: item.userId,
            value: item.userId,
            label: `${item.firstName} ${item.lastName}`,
          }));

          setUser(newUser?.reverse());
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  return {
    upsert,
    Subsidiary,
    Contacts,
    Company,
    user,
    userTenant,
    userRole,
    deleteRole,
    getUsers,
    loading,
    role,
  };
};
