import React, { lazy, Suspense } from "react";
import { Container } from "@mui/material";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";
import { useGenerationPlantOverview } from "./components/GenerationPlantOverview/useGenerationPlantOverview";
import { useGenerationOverview } from "./components/GenerationOverview/useGenerationOverview";
import { Fallback } from "../../../app/Fallback";

const HeaderWidget = lazy(() =>
  import("./components/HeaderWidget").then((module) => ({
    default: module.HeaderWidget,
  }))
);
const GenerationOverview = lazy(() =>
  import("./components/GenerationOverview").then((module) => ({
    default: module.GenerationOverview,
  }))
);
const GenerationPlantOverview = lazy(() =>
  import("./components/GenerationPlantOverview").then((module) => ({
    default: module.GenerationPlantOverview,
  }))
);

export const MonitoringGeneration: React.FC<any> = () => {
  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setSelectedPlant,
    date,
    setDate,
    liveButton,
    checkLiveButton,
    onChangePortfolio,
    preSelectedPlant,
    preSelectedPortfolio,
  } = useHeaderWidget();

  const {
    onSearch,
    search,
    plantOverviewLoading,
    plantOverviewData,
    POError,
    multiplePlants,
    userTimeZoneComp,
    DownloadData,
    downloadLoading,
    downloadError,
  } = useGenerationPlantOverview({
    selectedPortfolio,
    selectedPlant,
  });

  const {
    kpiData,
    kpiLoading,
    kpiError,
    missingData,
    graphData,
    graphLoading,
    xAxis,
    yAxis,
    lastUpdated,
  } = useGenerationOverview();

  return (
    <Suspense fallback={<Fallback />}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: "16px", paddingTop: "16px" }}
      >
        <HeaderWidget
          selectedPortfolio={selectedPortfolio}
          portfolio={portfolio}
          selectedPlant={selectedPlant}
          plant={plant}
          setSelectedPlant={setSelectedPlant}
          date={date}
          setDate={setDate}
          liveButton={liveButton}
          handleChangeLiveButton={checkLiveButton}
          lastUpdated={lastUpdated}
          multiplePlants={multiplePlants}
          onChangePortfolio={onChangePortfolio}
          preSelectedPlant={preSelectedPlant}
          preSelectedPortfolio={preSelectedPortfolio}
        />
        <GenerationOverview
          generationKPILoading={kpiLoading}
          error={kpiError}
          generationKPIData={kpiData}
          missingData={missingData}
          graphLoading={graphLoading}
          generationGraphData={graphData}
          xAxis={xAxis}
          yAxis={yAxis}
        />

        <GenerationPlantOverview
          onSearch={onSearch}
          search={search}
          data={plantOverviewData}
          loading={plantOverviewLoading}
          error={POError}
          userTimeZoneComp={userTimeZoneComp}
          DownloadData={DownloadData}
          downloadLoading={downloadLoading}
          downloadError={downloadError}
        />
      </Container>
    </Suspense>
  );
};
