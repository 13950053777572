import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LayoutView } from "./LayoutView";
import { Header } from "../../components/PortfolioHeader";
import { Box, Tab, Tabs } from "@mui/material";
import { OverviewHeader } from "../../../shared/components/Overview";
import { OverviewDropdown } from "../../components/OverviewDropdown";
import { PlantPerformance } from "./PlantPerformance";
import { InverterPerformance } from "./InverterPerformance";
import { Button } from "../../../shared/components";
import WeatherList from "../../components/WeatherList";
import { usePlants } from "./usePlants";
import { autoRefreshApi, convertTimeZoneName } from "../../../shared/utils/utils";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../features/Sidem/client/clientSlice";
import { fetchAsync } from "../../../shared/utils/apiService";

interface WeatherItem {
  dateTime: string;
  description: string;
  icon: string;
  id: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const PlantPage: React.FC<any> = ({ imageService }) => {
  const { plantId } = useParams<{ plantId: string }>();

  useEffect(() => {
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  const {
    handleInfoClick,
    date,
    getDateComponent,
    getDateReport,
    GetPlantActiveKPI,
    setProfileData,
    profileData,
    plantActiveKPI,
    weatherData,
    definition,
    setValue,
    tabValue,
  } = usePlants(plantId);

  const { client } = useSelector(clientSelector);

  useEffect(() => {
    if (!date) return;
    getDateReport(date);
  }, [date]);

  useEffect(() => {
    GetPlantActiveKPI();
    const myInterval = autoRefreshApi(() => GetPlantActiveKPI())

    return () => clearInterval(myInterval);
  }, [plantId])


  useEffect(() => {
    fetchAsync(`SkyfriSidemApi/v1/GetProfileInfo?plantId=${plantId}`, "GET")
      .then((profileData) => {
        localStorage.setItem(
          "profileData",
          JSON.stringify({
            hasTracker: profileData.hasTracker,
          })
        );

        return setProfileData(profileData);
      })
      .catch((e) => console.error(e));
  }, [plantId]);

  const [plantItems, setPlantItems] = useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const InfoBtn = () => (
    <div style={{ padding: "29px 16px 0px 16px" }}>
      <Button
        title="Site Info"
        color="#0C1E33"
        onClick={handleInfoClick}
        type="button"
      />
    </div>
  );
  const getCurrentWeatherItem = (
    weatherData: Array<WeatherItem>,
    date: string
  ): Array<WeatherItem> => {
    const item = weatherData?.find((item) => item?.dateTime === date);
    return item?.dateTime ? [item] : [];
  };
  const get5DaysWeatherItem = (
    weatherData: Array<WeatherItem>
  ): Array<WeatherItem> => {
    const filteredData = weatherData.slice(1, weatherData.length - 1);
    return filteredData;
  };
  const selectedDate = useMemo(
    () => date?.split("-")?.reverse()?.join("-"),
    [date]
  );
  useEffect(() => {
    if (!profileData) return;
    const dateNow = new Date().toISOString().slice(0, -1);
    const location = {
      title: "Location",
      value: profileData["location"],
    };
    const activePower = {
      title: "Active power",
      value:
        plantActiveKPI[0]?.actualPower?.toString().length > 4
          ? (plantActiveKPI[0]?.actualPower / 1000)?.toFixed(0) + " MW"
          : (plantActiveKPI[0]?.actualPower | 0)?.toFixed(0) + " kW",
    };
    const moduleTemp = {
      title: "Module temp",
      value: `${plantActiveKPI[0]?.actualModuleTemp?.toFixed(2) || 0} C`,
    };
    const actualAvailability = {
      title: "Plant Availability",
      value: `${(plantActiveKPI[0]?.actualAvailability * 100)?.toFixed(2) || 0
        } %`,
    };
    const gridAvailability = {
      title: "Grid Availability",
      value: `${plantActiveKPI[0]?.grid_Availability * 100 || 0} %`,
    };
    const date = {
      title: "Today`s date",
      value: profileData?.todaysDate || "",
    };
    const weather = {
      title: "Today's weather",
      value: (
        <WeatherList
          notHighlighted={true}
          weathers={getCurrentWeatherItem(weatherData, selectedDate)}
          selectedDate={selectedDate}
        />
      ),
    };
    const nameplate = {
      title: "Nameplate capacity",
      value: `${profileData?.acCapacity?.toFixed(2) || 0} MW ac | ${profileData?.connectedDC?.toFixed(2) || 0
        } MWp`,
    };
    const irradiation = {
      title: "Irradiation",
      value: `${plantActiveKPI[0]?.actualIrradiance?.toFixed(2) | 0} W/m2`,
    };
    const ambientTemp = {
      title: "Ambient temp",
      value: `${plantActiveKPI[0]?.actualAmbTemp?.toFixed(2) | 0} C`,
    };
    const updated = {
      title: "Last updated",
      value: !client
        ? null
        : `${plantActiveKPI[0]?.lastUpdated} ${convertTimeZoneName(client?.timeZone, "HH:mm", dateNow, "z")}`,
    };
    const weatherForecast = {
      title: "Weather Forecast",
      value: (
        <WeatherList
          notHighlighted={true}
          weathers={get5DaysWeatherItem(weatherData)}
          selectedDate={selectedDate}
        />
      ),
    };

    const nData = [
      location,
      activePower,
      moduleTemp,
      date,
      nameplate,
      irradiation,
      ambientTemp,
      weather,
      updated,
      actualAvailability,
      gridAvailability,
      weatherForecast,
    ];
    setPlantItems(nData);
  }, [profileData, client, plantActiveKPI, weatherData]);

  return (
    <>
      <Header
        title={profileData?.plantName}
      // headerDropdown={getDateComponent}
      // InfoBtn={InfoBtn}
      />
      <OverviewHeader
        title="Overview"
        dropdown={
          <OverviewDropdown
            colsA={12}
            items={plantItems}
            fourCols={true}
            fieldCount={7}
          />
        }
      />
      <div
        style={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
          display: "flex",
          flex: 1
        }}
      >
        <div style={{
          flex: 1
        }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="plant tabs">
            <Tab label="PLANT PERFORMANCE" {...a11yProps(0)} />
            <Tab label="INVERTER PERFORMANCE" {...a11yProps(1)} />
            <Tab label="PLANT LAYOUT" {...a11yProps(2)} />
          </Tabs>
        </div>

        <div style={{ justifyContent: "end", alignItems: "center", paddingRight: "10px" }}>
          {getDateComponent()}

        </div>

      </div>
      <TabPanel value={tabValue} index={0}>
        <PlantPerformance
          plantId={plantId}
          date={date}
          definition={definition}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <InverterPerformance
          plantId={plantId}
          date={date}
          definition={definition}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <LayoutView
          imageService={imageService}
          plantId={plantId}
          profileData={profileData}
        />
      </TabPanel>
    </>
  );
};

export default PlantPage;
