import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: theme.spacing(3, 3, 3, 3),
    borderRadius: theme.spacing(1),
    marginTop: "0",
    paddingBottom: "12px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  percentage: {
    fontWeight: 500,
    fontSize: "48px !important",
    lineHeight: "52.8px !important",
  },
  batteryStatus: {},
  heading: {
    fontWeight: 500,
    fontSize: "20px !important",
    lineHeight: "20px !important",
  },
  values: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "17.6px",
  },
  type: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16.8px",
  },
  loadingColor: {
    color: "#C7C8C9",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
  },
  batteryWrapper: {
    display: "flex",
    flexDirection: "column",
    background: "none",
  },
  batterySoh: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "10px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  soh: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.6px",
  },
  sohValue: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15.6px",
  },
}));
