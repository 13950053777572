export const soilingChartColors = {
  critical: "#BC4F73",
  high: "#D795AB",
  medium: "#BC4F7366",
  low: "#F2DCE3",
};

export const formSchemaFormatType = {
  GranularityOptions: [
    { label: "1 day", value: "1 day" },
  ],
  FormatOptions: [
    { label: "xlsx", value: "xlsx" },
    { label: "csv", value: "csv" },
  ]
};

export const getBlockAndInvFromString = (equipmentName: string) => {
  let inv = ''
  let block = '';

  if (equipmentName.includes('Inv')) {
    let split = equipmentName.split('-')[1].split('.');
    inv = split[1];
    block = split[0];
  } else {
    const split = equipmentName.split(".");
    block = split[0];
    inv = split[1];
  }

  return {
    block,
    inv
  }
}

export const legendArr = [
  {
    name: "Low",
    color: soilingChartColors.low,
  },
  {
    name: "Medium",
    color: soilingChartColors.medium,
  },
  {
    name: "High",
    color: soilingChartColors.high,
  },
  {
    name: "Critical",
    color: soilingChartColors.critical,
  },
];