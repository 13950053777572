import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  blockId: number;
  equipmentName: string;
  inverterId: number;
  specificCurrent: number;
  stringId: string;
}

interface Props {
  plantId: string;
  date: string;
  inverterId: string;
  blockId: string;
}

interface Data {
  data: Array<Array<number>>;
  error?: string;
  loading?: boolean;
  xLabels: Array<string>;
  yLabels: Array<string>;
}

export const useHeatmap = (props: Props): Data => {
  const [data, setData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }

    setData([
      data?.map((item) => Math.round(item.specificCurrent * 100) / 100),
    ]);
    setYLabels([""]);
    setXLabels(data?.map((item) => item.stringId));
  };

  useEffect(() => {
    const { date, plantId, blockId, inverterId } = props;

    if (!date || !plantId || !blockId || !inverterId) return;

    setLoading(true);
    setError("");
    fetchAsync(`SkyfriSidemApi/v1/GetCBHeatMap?`, "GET", {
      plantId,
      date,
      blockId,
      inverterId,
    })
      .then((data: Array<Item>) => {
        transformData(data);
        setLoading(false);
        setError("");
      })
      .catch(setError);
  }, [props]);

  return {
    data,
    xLabels,
    yLabels,
    error,
    loading,
  };
};
