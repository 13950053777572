import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCrossEquipmentComparisonData,
  getCrossEquipmentComparisonData,
  getCrossEquipmentData,
} from "../../../features/Monitoring/crossEquimentComparison/crossEquimentComparisonActions";
import { checkDate, convertTime } from "../../../shared/utils/utils";
import { DropdownOption } from "../../../shared/components";
import moment from "moment";
import { monitoringSharedSelector } from "../../../features/Monitoring/shared/monitoringSharedSlice";
import { setGlobalDateAction } from "../../../features/Monitoring/shared/monitoringSharedActions";
import { useGetPortfoliosAndPlantsQuery } from "../../../features/Sidem/shared/sidemSharedApi";

interface Data {
  portfolio: any;
  plant: any;
  loading: any;
  error: any;
  selectedPlant: any;
  selectedPortfolio: any;
  aggregation: any;
  date: any;
  setSelectedPortfolio: any;
  setSelectedPlant: any;
  setAggregation: any;
  setDate: any;
  inverter: any;
  setInverter: any;
  mfm: any;
  setMfm: any;
  wms: any;
  setWms: any;
  data: any;
  xAxises: any;
  setXAxises: any;
  yAxises: any;
  getCrossData: any;
  equipmentLoading?: boolean;
  graphDataLoading?: boolean;
  downloadGraph?: () => void;
  showAlert?: boolean;
  onChangePortfolio: (value: string) => void;
  onChangePlant: (value: string) => void;
}

export const useCrossEquipmentComparison = (): Data => {
  const [url, setUrl] = useSearchParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { globalDate } = useSelector(monitoringSharedSelector);

  const [data, setData] = useState([]);
  const [xAxises, setXAxises] = useState({});
  const [yAxises, setyAxises] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [plant, setPlant] = useState([]);
  const [equipmentLoading, setEquipmentLoading] = useState(true);
  const [graphDataLoading, setGraphDataLoading] = useState(false);
  const [loading, setloading] = useState(null);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [error, setError] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedPlant, setSelectedPlant] = useState(plantId);
  const [aggregation, setAggregation] = useState("1h");
  const [maxValues, setMaxValues] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [crossEquipData, setCrossEquipData] = useState(null);

  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate[0].startDate),
      endDate: checkDate(endDate, globalDate[0].endDate),
      key: "selection",
    },
  ]);
  const [inverter, setInverter] = useState({
    devices: [],
    parameters: [],
    deviceOptions: [],
    parameterOptions: [],
    inverterData: [],
  });
  const [mfm, setMfm] = useState({
    devices: [],
    parameters: [],
    deviceOptions: [],
    parameterOptions: [],
    mfmData: [],
  });
  const [wms, setWms] = useState({
    devices: [],
    parameters: [],
    deviceOptions: [],
    parameterOptions: [],
    wmsData: [],
  });

  const dispatch = useDispatch();
  const { data: portfoliosAndPlantsData } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });
  const dateFormat =
    moment(startDate).format("DD MMM YYYY") ===
      moment(endDate).format("DD MMM YYYY")
      ? "HH:mm"
      : "DD. MMM HH:mm";

  const getPortfoliosInfo = async () => {
    if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0)
      return;
    setTenantInfo(portfoliosAndPlantsData);
    getPortfolio(portfoliosAndPlantsData);
  };

  useLayoutEffect(() => {
    if (!tenantInfo.length) return;
    getPortfolioAndPlant();
  }, [tenantInfo, plantId]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));
    setUrl(
      (prev) => {
        prev.set("startDate", date[0].startDate.toString());
        return prev;
      },
      { replace: true }
    );
    setUrl(
      (prev) => {
        prev.set("endDate", date[0].endDate.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  useEffect(() => {
    getPortfoliosInfo();
  }, [portfoliosAndPlantsData]);

  useEffect(() => {
    if (!selectedPortfolio) return;
    getPlants(selectedPortfolio);
  }, [selectedPortfolio]);

  useEffect(() => {
    setUrl(
      (prev) => {
        prev.set("plantId", selectedPlant.toString());
        return prev;
      },
      { replace: true }
    );
  }, [selectedPlant]);

  useEffect(() => {
    getIverterParameters(crossEquipData?.inverterDeviceOptions);
  }, [inverter.devices]);

  useEffect(() => {
    getMfmParameters(crossEquipData?.mfmDeviceOptions);
  }, [mfm.devices]);

  useEffect(() => {
    getWmsParameters(crossEquipData?.wmsDeviceOptions);
  }, [wms.devices]);

  const getPortfolioAndPlant = () => {
    tenantInfo.forEach((item) => {
      if (parseInt(plantId) === item.plantId) {
        setSelectedPlant(item.plantId);
        setSelectedPortfolio(item.portfolioName);
      }
    });
  };

  const onChangePortfolio = (value) => {
    const newPlants = [];
    setSelectedPortfolio(value);
    tenantInfo?.filter((item) => {
      if (item?.portfolioName === value) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    setSelectedPlant(newPlants[0].value);
  };

  const onChangePlant = (value) => {
    setSelectedPlant(value);
  };

  const processOptions = (data) => {
    let options = [];
    data?.map((item) => {
      const exist = options?.filter((op) => item.equipName === op.label);
      if (exist.length <= 0) {
        options.push({
          label: item.equipName,
          value: item.equipName,
        });
      }
    });
    return options;
  };

  const getOptionsData = async () => {
    setEquipmentLoading(true);
    const data: any = await dispatch(
      getCrossEquipmentData(selectedPortfolio, selectedPlant)
    );

    if (!data) {
      setEquipmentLoading(false);
      return;
    }

    setCrossEquipData(data);

    if (data?.inverterDeviceOptions) {
      const inverterOptions = processOptions(data?.inverterDeviceOptions);
      setInverter({ ...inverter, deviceOptions: inverterOptions });
    }
    if (data?.mfmDeviceOptions) {
      const mfmOptions = processOptions(data?.mfmDeviceOptions);
      setMfm({ ...mfm, deviceOptions: mfmOptions });
    }
    if (data?.wmsDeviceOptions) {
      const wmsOptions = processOptions(data?.wmsDeviceOptions);
      setWms({ ...wms, deviceOptions: wmsOptions });
    }
    setEquipmentLoading(false);
  };
  useEffect(() => {
    if (!selectedPlant || !selectedPortfolio) return;
    getOptionsData();
  }, [selectedPlant]);

  const getListArgs = () => {
    const listArgs: any = {
      InverterDevices:
        inverter?.devices?.map((item) => item.label).length > 0
          ? inverter?.devices?.map((item) => item.label)
          : null,
      InverterParameters:
        inverter?.parameters?.map((item) => item.label).length > 0
          ? inverter?.parameters?.map((item) => item.label)
          : null,
      MfmDevices:
        mfm?.devices?.map((item) => item.label).length > 0
          ? mfm?.devices?.map((item) => item.label)
          : null,
      MfmParameters:
        mfm?.parameters?.map((item) => item.label).length > 0
          ? mfm?.parameters?.map((item) => item.label)
          : null,
      WmsDevices:
        wms?.devices?.map((item) => item.label).length > 0
          ? wms?.devices?.map((item) => item.label)
          : null,
      WmsParameters:
        wms?.parameters?.map((item) => item.label).length > 0
          ? wms?.parameters?.map((item) => item.label)
          : null,
    };
    return listArgs;
  };

  const getCrossData = async () => {
    setGraphDataLoading(true);
    const dt: any = await dispatch(
      getCrossEquipmentComparisonData(
        selectedPortfolio,
        selectedPlant,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        getListArgs(),
        aggregation
      )
    );
    setMaxValues(dt?.data?.maxValues);
    setInverter({ ...inverter, inverterData: dt?.data?.inverter });
    setMfm({ ...mfm, mfmData: dt?.data?.mfm });
    setWms({ ...wms, wmsData: dt?.data?.wms });
    setGraphDataLoading(false);
  };

  const getPortfolio = async (data: any) => {
    setloading(true);
    let fil = [];
    let newPortfolio: Array<DropdownOption> | any[] = [];
    data?.filter((item) => {
      if (!fil.includes(item?.portfolioName)) {
        fil.push(item?.portfolioName);
        newPortfolio.push({
          label: item?.portfolioName,
          value: item?.portfolioName,
        });
      }
    });
    setPortfolio(newPortfolio);
    if (!plantId) setSelectedPortfolio(newPortfolio[0].value);
    setloading(false);
  };

  const seen = new Set();

  const removeDuplicatesObjects = (arr) => {
    return arr.reduce((acc, current) => {
      const x = acc.find((item) => item.title?.text === current.title?.text);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const removeDuplicateValues = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const convertData = (data, name) => {
    const tempData = [];
    const yAxis = [];
    let xAxis = [];
    data?.forEach((equipment) => {
      equipment.variables?.forEach((variable, i) => {
        yAxis.push({
          visible: true,
          allowDecimals: false,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: variable.unit,
          },
        });
        tempData.push({
          name: `${equipment.equipName} ${variable.variableName}`,
          data: variable.data.map((val) => {
            xAxis.push(convertTime(val?.sensorTime, dateFormat));
            return val.value;
          }),
          tooltip: {
            valueSuffix: variable.unit ? variable.unit : "",
          },
        });
      });
    });
    return { data: tempData, xAxis: xAxis, yAxis: yAxis };
  };

  const returnEmptyObject = () => {
    return {
      data: [],
      xAxis: [
        //   {
        //   gridLineWidth: 1,
        //   categories: [],
        //   endOnTick: true,
        //   showLastLabel: true,
        //   startOnTick: false
        // }
      ],
      yAxis: [
        // {
        //   visible: true,
        //   allowDecimals: false,
        //   type: "linear",
        //   gridLineWidth: 0,
        //   title: {
        //     text: "",
        //   },
        // },
      ],
    };
  };

  const dataManipulation = () => {
    const inverterData = !inverter.inverterData?.length
      ? returnEmptyObject()
      : convertData(inverter?.inverterData, "inverter");
    const mfmData = !mfm.mfmData?.length
      ? returnEmptyObject()
      : convertData(mfm?.mfmData, "mfm");
    const wmsData = !wms.wmsData?.length
      ? returnEmptyObject()
      : convertData(wms?.wmsData, "wms");

    let allData = [...inverterData.data, ...mfmData.data, ...wmsData.data];
    let allYAxis = [...inverterData.yAxis, ...mfmData.yAxis, ...wmsData.yAxis];
    allYAxis = removeDuplicatesObjects(allYAxis);
    const allXAxis = {
      gridLineWidth: 1,
      endOnTick: false,
      showLastLabel: true,
      startOnTick: false,
      categories: removeDuplicateValues([
        ...inverterData.xAxis,
        ...mfmData.xAxis,
        ...wmsData.xAxis,
      ]),
    };

    const newYAxis = [];
    allData = allYAxis
      .map((item, i) => {
        newYAxis.push({
          ...item,
          opposite: i % 2 === 0 ? true : false,
        });
        return allData.map((element) => {
          if (item?.title?.text === element?.tooltip.valueSuffix)
            return {
              ...element,
              yAxis: i,
            };
          if (item?.title?.text === null && element?.tooltip.valueSuffix === "")
            return {
              ...element,
              yAxis: i,
              tooltip: {
                valueSuffix: "",
              },
            };
        });
      })
      .flat()
      .filter((item) => item !== undefined);
    return {
      data: allData,
      XAxis: allXAxis,
      yAxis: newYAxis,
    };
  };

  useEffect(() => {
    const data = dataManipulation();
    setData(data.data);
    setXAxises(data.XAxis);
    setyAxises(data.yAxis);
  }, [inverter.inverterData, wms.wmsData, mfm.mfmData]);

  const getIverterParameters = (data: any) => {
    setloading(true);
    let newParameter: Array<DropdownOption> = [];
    data?.filter((item) => {
      inverter?.devices?.map((device) => {
        if (item.equipName === device.value) {
          newParameter.push({
            label: item?.variable,
            value: item?.variable,
          });
        }
      });
    });
    newParameter = newParameter.filter(
      (v, i, a) =>
        a.findIndex((v2) => ["label", "value"].every((k) => v2[k] === v[k])) ===
        i
    );
    setInverter({ ...inverter, parameterOptions: newParameter });
    setloading(false);
  };

  const getMfmParameters = (data: any) => {
    setloading(true);
    let newParameter: Array<DropdownOption> = [];
    data?.filter((item) => {
      mfm?.devices?.map((device) => {
        if (item.equipName === device.value) {
          newParameter.push({
            label: item?.variable,
            value: item?.variable,
          });
        }
      });
    });
    newParameter = newParameter.filter(
      (v, i, a) =>
        a.findIndex((v2) => ["label", "value"].every((k) => v2[k] === v[k])) ===
        i
    );
    setMfm({ ...mfm, parameterOptions: newParameter });
    setloading(false);
  };

  const getWmsParameters = (data: any) => {
    setloading(true);
    let newParameter: Array<DropdownOption> = [];
    data?.filter((item) => {
      wms?.devices?.map((device) => {
        if (item.equipName === device.value) {
          newParameter.push({
            label: item?.variable,
            value: item?.variable,
          });
        }
      });
    });
    newParameter = newParameter.filter(
      (v, i, a) =>
        a.findIndex((v2) => ["label", "value"].every((k) => v2[k] === v[k])) ===
        i
    );
    setWms({ ...wms, parameterOptions: newParameter });
    setloading(false);
  };

  const getPlants = async (portfolio: string) => {
    setloading(true);
    const newPlants: Array<DropdownOption> | any[] = [];
    tenantInfo?.filter((item) => {
      if (item?.portfolioName === portfolio) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    setPlant(newPlants);
    if (!plantId) setSelectedPlant(newPlants[0].value);
    setloading(false);
  };

  const downloadGraph = async () => {
    const data: any = await dispatch(
      downloadCrossEquipmentComparisonData(
        selectedPortfolio,
        selectedPlant,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        getListArgs(),
        aggregation
      )
    );
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    let fileName = `DataExplorerReport_${moment(startDate).format(
      "DD-MM-YYYY"
    )}_${moment(endDate).format("DD-MM-YYYY")}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  return {
    plant,
    portfolio,
    loading,
    error,
    selectedPlant,
    selectedPortfolio,
    aggregation,
    date,
    setSelectedPortfolio,
    setSelectedPlant,
    setAggregation,
    setDate,
    inverter,
    setInverter,
    mfm,
    setMfm,
    wms,
    setWms,
    data,
    xAxises,
    setXAxises,
    yAxises,
    getCrossData,
    equipmentLoading,
    graphDataLoading,
    downloadGraph,
    showAlert,
    onChangePortfolio,
    onChangePlant,
  };
};
