
export const TooltipWrapper = (props: any) => (
  <div
    style={{
      backgroundColor: "white",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      color: "black",
      border: "none !important",
      padding: 15,
      outline: 'none'
    }}
  >
    {props.children}
  </div>
);
