import { useState } from "react";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useSearchParams } from "react-router-dom";
import { downloadAllGraphsData } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardActions";
import {
  convertTime,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/rootStore";
import { useGetMppStatusBarQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";

export const useStatusWidget = () => {
  const dispatch = useAppDispatch();
  const [url] = useSearchParams();
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const { globalPortfolioName } = useAppSelector(monitoringDashboardSelector);

  const { liveButton } = useAppSelector(monitoringSharedSelector);

  const {
    data: mppStatusBarData,
    isLoading: isMppStatusBarFetching,
    isError: isMppStatusBarError,
  } = useGetMppStatusBarQuery(
    { plantId, portfolioName: globalPortfolioName },
    {
      skip: isAnyItemFalsy([plantId, globalPortfolioName]),
      pollingInterval: liveButton ? 60000 : undefined,
    }
  );

  const DownloadData = async (
    granularity,
    closeMenu,
    setOpenSnackbar,
    setSeverity,
    setMessage
  ) => {
    setDownloadError(false);
    setDownloadLoading(true);

    const data: any = await dispatch(
      downloadAllGraphsData(
        globalPortfolioName,
        plantId,
        convertTime(startDate, "YYYY.MM.DD"),
        convertTime(endDate, "YYYY.MM.DD"),
        granularity
      )
    );

    if (!data) {
      setDownloadError(true);
      setDownloadLoading(false);
      setOpenSnackbar(true);
      setSeverity("error");
      setMessage("Couldn't export the report. Try again later.");
      return;
    }

    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");
    link.href = url;
    let fileName = `MonitoringAllGraphs_${convertTime(
      startDate,
      "DD-MM-YYYY"
    )}_${convertTime(endDate, "DD-MM-YYYY")}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    closeMenu();
    setDownloadLoading(false);
    setOpenSnackbar(true);
    setSeverity("success");
    setMessage("Your report is ready.");
  };

  return {
    statusLoading: isMppStatusBarFetching,
    statusData: mppStatusBarData ? mppStatusBarData?.statusData : null,
    statusError: Boolean(
      mppStatusBarData
        ? mppStatusBarData.statusError
        : false || isMppStatusBarError
    ),
    genData: mppStatusBarData ? mppStatusBarData?.genData : null,
    DownloadData,
    downloadLoading,
    downloadError,
  };
};

export type StatusWidgetData = ReturnType<typeof useStatusWidget>;