export const Fields = [
    "Plant", "Generation", "Irradiation", "PR", "Availability", "Inverter"
];




export const data = [
    { id: 0, plantName: "South Solar", generation: "358", irradiation: "6.7", actualPr: "-0.003", availability: "17.2", inverter: "20/20" },
    { id: 1, plantName: "50MW Avaada Green HN Project Pvt. Ltd", generation: "358", irradiation: "6.7", actualPr: "-0.003", availability: "17.2", inverter: "20/20" },
    { id: 2, plantName: "Sundrops solar farm", generation: "-358", irradiation: "-6.7", actualPr: "003", availability: "-1.2", inverter: "20/20" },
    { id: 2, plantName: "Sundrops solar farm", generation: "-358", irradiation: "-6.7", actualPr: "003", availability: "-1.2", inverter: "20/20" }

];