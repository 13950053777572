import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(0.5, 2, 0.5, 2),
        borderRadius: theme.spacing(1),
        marginBottom: "16px",
    },
    selector: {
        borderRadius: 5,
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        display: "flex",
    },
    label: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "14px",
        color: "#707070",
        marginBottom: theme.spacing(0.5),
    },
    select: {
        width: "200px",
        "&::before": {
            display: "none",
        },

        "&::after": {
            display: "none",
        },
    },
    marginRight: {
        marginRight: "10px",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    boxStyle: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "10px",
        paddingRight: "10px",
    }
}));