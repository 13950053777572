import React from "react";
import { Tooltip } from "@material-ui/core";
import BarAndLineComposedChart from "../../components/BarAndLineComposedChart";
import WidgetItem from "../../components/WidgetItem";
import SimpleLineChart from "../../components/SimpleLineChart";
import { useSharedFormState } from "./../../../shared/utils/sharedStates";
import { HeaderDataProps } from "./interfaces";
import { multilineChartLines } from "./config";
import { useStyles } from "./styles";
import {
  CustomBarTooltip,
  CustomLineTooltip,
  CustomMultiLineTooltip,
} from "./Tooltips.component";
import DottedLineChart from "../../components/DottedLineChart";

export const PortfolioHeaderData: React.FC<HeaderDataProps> = (props) => {
  const {
    fields,
    barChartData,
    lineChart7Days,
    lineChart7DaysValue,
    lineChart30Days,
    lineChart30DaysValue,
    customComponentAboveFields,
    productionFactorLineChartValue,
    productionFactorLineChartData,
    definition,
  } = props;

  const { viewDefinitions } = useSharedFormState();

  const classes = useStyles();

  const getLabel = (val: number) => {
    if (typeof val !== "number") return null;

    const stl = val >= 0 ? classes.menuLabel : classes.negativeLabel;
    const value = (val >= 0 ? "+" : "") + (val * 100).toFixed(2) + "%";

    return <div className={stl}>{value}</div>;
  };

  const multiChartProps = {
    yAxises: [],
    showLegend: false,
    showXAxis: false,
    minHeight: "80px",
    showDots: true,
    strokeWidth: 2,
    tooltipContent: <CustomMultiLineTooltip />,
  };
  return (
    <div className={classes.widgetWrapper}>
      <div className={classes.chartWrapper}>
        <Tooltip
          title={!viewDefinitions ? "" : definition["Generation last 7D"]}
        >
          <div className={classes.itemTitle}>Generation last 7D</div>
        </Tooltip>
        <BarAndLineComposedChart
          data={barChartData}
          normalLevel={70}
          formatLabel="KWh/ MWh"
          maxHeight={90}
          tooltipContent={<CustomBarTooltip />}
          strokeWidth={2}
          name="Generation (MWh)"
        />
      </div>

      <div>
        <Tooltip
          title={!viewDefinitions ? "" : definition["Production factor"]}
        >
          <div className={classes.itemTitle}>Production factor</div>
        </Tooltip>
        <WidgetItem
          title="Last 7 days"
          menu={getLabel(productionFactorLineChartValue)}
          minHeight={50}
          definition={definition}
        >
          <SimpleLineChart
            label={"value"}
            data={productionFactorLineChartData}
            tooltipContent={<CustomLineTooltip />}
          />
        </WidgetItem>
        <Tooltip
          title={
            !viewDefinitions ? "" : definition["Generation & Irradiation delta"]
          }
        >
          <div className={classes.itemTitle}>
            Generation & Irradiation delta
          </div>
        </Tooltip>

        <WidgetItem
          title="Last 7 days"
          menu={getLabel(lineChart7DaysValue)}
          minHeight={50}
          definition={definition}
        >
          <DottedLineChart
            label="label"
            lines={multilineChartLines}
            data={lineChart7Days}
            {...multiChartProps}
          />
        </WidgetItem>

        <WidgetItem
          title="Last 30 days"
          menu={getLabel(lineChart30DaysValue)}
          minHeight={50}
          definition={definition}
        >
          <DottedLineChart
            label={"name"}
            lines={multilineChartLines}
            data={lineChart30Days}
            {...multiChartProps}
          />
        </WidgetItem>
      </div>
    </div>
  );
};
