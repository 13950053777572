import React from "react";

import Widget from "../Widget";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../Loader";

interface Props {
  title: string;
  data: Array<any>;
  showYAxises?: boolean;
  customMenu?: React.ReactNode;
  error?: string;
  loading?: boolean;
  tooltipContent?: any;
  height?: number;
  size?: any;
  definition?: any;
  styles?: any;
  XAxisKey: string;
  YAxisKey: string;
}

export const SinglelineChartWidget: React.FC<Props> = (props: Props) => {
  const {
    title,
    data,
    YAxisKey,
    XAxisKey,
    customMenu,
    error,
    loading,
    height,
    tooltipContent,
    size,
    styles,
  } = props;

  return (
    <Widget
      label={title}
      customMenu={customMenu}
      height={height}
      size={size}
      styles={styles}
    >
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />
      {!data?.length || loading || error?.length ? null : (
        <ResponsiveContainer width="100%" height={370}>
          <LineChart
            height={370}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={XAxisKey}  />
            <YAxis dataKey={YAxisKey} />
            <Tooltip content={tooltipContent} />
            <Legend />
            <Line
              type="monotone"
              dataKey={YAxisKey}
              stroke="#4472C4"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Widget>
  );
};
