import React, { useMemo } from 'react';
import CandleChart from '../CandleChart';
import SimpleBarChart from '../SimpleBarChart';
import SimpleLineChart from '../SimpleLineChart';
import { ChartTypes } from './interfaces';
import { useStyles } from './styles';

interface Props {
  chartType: ChartTypes;
  data: any;
  showChart: boolean;
}

export const CardsCharts: React.FC<Props> = ({ chartType, data, showChart }) => {
  const classes = useStyles();

  const getChart = (type: ChartTypes, data: any) => {
    switch (type) {
      case ChartTypes.Bullet:
        return <CandleChart actualResult={data.actualResult} expectedResult={data.expectedResult} />
      case ChartTypes.BarChart:
        return <SimpleBarChart data={data?.values} xAxis={false} normalLevel={data?.level} formatLabel="kW/kWp" />
      case ChartTypes.LineChart:
        return <SimpleLineChart label={'value'} data={data?.values} />
    }
  };

  const isNumber = (val) =>
    val && typeof val === 'number'

  const hasData = (type: ChartTypes, data: any) => {
    switch (type) {
      case ChartTypes.Bullet:
        return isNumber(data?.actualResult) && isNumber(data?.expectedResult);
      case ChartTypes.BarChart:
        return data?.values?.length && isNumber(data?.level);
      case ChartTypes.LineChart:
        return data?.values?.length;
    }
  };

  const hasChartData = useMemo(() => hasData(chartType, data), [chartType, data]);
  const content = useMemo(() => getChart(chartType, data), [chartType, data]);
  const isBar = chartType === ChartTypes.BarChart;

  return (hasChartData && showChart) ? (
    <div className={classes.chart} style={{ height: isBar ? 75 : 50 }}>
      {content}
    </div>
  ) : null;
}
