import * as errorSlice from "./errorSlice";
import posthog from "posthog-js";

export function errorReducer(status?: any, error?: string) {
  return async (dispatch: (arg0: any) => void) => {
    if (status && (status === 403 || status === 401 )) {
      dispatch(
        errorSlice.setError(
          error ||
            "You don't have permissions to view this page. Try logging out and logging in again."
        )
      );
      posthog.capture("Error: " + (error?error:"No permissions to view page"));
    }
  };
}
