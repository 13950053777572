import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStyles } from "../../styles";

interface TableProps {
  cells: [];
}
export const SheetTable:React.FC<any> = ({ cells }: TableProps) => {
  
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {cells.map((cell) => (
              <TableCell className={classes.cellHeader}>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3, 4, 5].map((cell) => {
            return (
              <TableRow
                key={cell}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {[...Array(cells.length)].map((n, i) => (
                  <TableCell key={i} component="th" scope="row"></TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
