import { DropdownOption } from "../../../shared/components";


export const aggregationTypes: Array<DropdownOption> = [
    { label: 'No Aggregation', value: 'No Aggregation' },
    // { label: '1 min', value: '1m' },
    { label: '5 min', value: '5m' },
    { label: '30 min', value: '30m' },
    { label: '1 hr', value: '1h' },

];

export const CE_DEFINITION = "To compare equipment, select the equipment you want to compare, choose any parameters, select an aggregation, and press the submit button."
