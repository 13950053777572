import React, { useMemo } from "react";
import { useStyles } from "./styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { usePowerChart } from "./usePowerChart";
import "./styles.css";

HighchartsMore(Highcharts);

export const PowerChart = () => {
  const classes = useStyles();
  const { options } = usePowerChart();
  const memoizedOptions = useMemo(() => options, [options]);

  return (
    <div className={classes.wrapper}>
      <HighchartsReact highcharts={Highcharts} options={memoizedOptions} />
    </div>
  );
};
