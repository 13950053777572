import { useEffect, useLayoutEffect, useState } from "react";
import { checkDate, convertTime } from "./../../../../../shared/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalPortfolioNameAction } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardActions";
import { DropdownOption } from "../../../../components/interfaces";
import { useSearchParams } from "react-router-dom";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import {
  setDateComparison,
  setGlobalDateAction,
  setGlobalLiveButtonAction,
} from "../../../../../features/Monitoring/shared/monitoringSharedActions";
import { useGetPortfoliosAndPlantsQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";

interface Data {
  portfolio: DropdownOption[];
  plant: DropdownOption[];
  selectedPlant: any;
  selectedPortfolio: any;
  setSelectedPortfolio: any;
  setSelectedPlant: any;
  date: any[];
  setDate: any;
  liveButton: boolean;
  checkLiveButton: (val: boolean) => void;
  globalDate: any[];
  onChangePlant: (val: string | number) => void;
  onChangePortfolio: (val: string | number) => void;
}

export const useHeaderWidget = (): Data => {
  const [url, setUrl] = useSearchParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const dispatch = useDispatch();

  const { globalDate, liveButton } = useSelector(monitoringSharedSelector);

  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedPlant, setSelectedPlant] = useState<string | number>(plantId);
  const [portfolio, setPortfolio] = useState([]);
  const [plant, setPlant] = useState([]);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [liveButtonState, setLiveButtonState] = useState(liveButton);
  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate?.[0]?.startDate),
      endDate: checkDate(endDate, globalDate?.[0]?.endDate),
      key: "selection",
    },
  ]);

  const { data: portfoliosAndPlantsData } = useGetPortfoliosAndPlantsQuery({
    accessType: "PV",
  });

  useEffect(() => {
    getPortfoliosInfo();
  }, [portfoliosAndPlantsData]);

  useEffect(() => {
    if (!selectedPortfolio) return;
    getPlants(selectedPortfolio);
    dispatch(setGlobalPortfolioNameAction(selectedPortfolio));
  }, [selectedPortfolio]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));

    const startTime = convertTime(date?.[0]?.startDate, "YYYY.MM.DD");
    const endTime = convertTime(date?.[0]?.endDate, "YYYY.MM.DD");
    if (startTime !== endTime) {
      dispatch(setDateComparison(false));
    } else {
      dispatch(setDateComparison(true));
    }

    setUrl(
      (prev) => {
        prev.set("startDate", date?.[0]?.startDate.toString());
        prev.set("endDate", date?.[0]?.endDate.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  useEffect(() => {
    setLiveButtonState(liveButton);
  }, [liveButton]);

  useEffect(() => {
    dispatch(setGlobalLiveButtonAction(liveButtonState));
  }, [liveButtonState]);

  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date?.[0]?.startDate : startDate,
      "YYYY.MM.DD"
    );
    const endTime = convertTime(
      !endDate ? date?.[0]?.endDate : endDate,
      "YYYY.MM.DD"
    );
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");
    if (
      startTime !== endTime ||
      (startTime !== dateToday && endTime !== dateToday)
    )
      setLiveButtonState(false);
  }, [liveButton, startDate, endDate]);

  useEffect(() => {
    const startTime = convertTime(date?.[0]?.startDate, "YYYY.MM.DD");
    const endTime = convertTime(date?.[0]?.endDate, "YYYY.MM.DD");
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");
    if (startTime === dateToday && endTime === dateToday)
      setLiveButtonState(true);
  }, [date]);

  useLayoutEffect(() => {
    if (!tenantInfo.length) return;
    getPortfolioAndPlant();
  }, [tenantInfo, plantId]);

  useEffect(() => {
    setUrl(
      (prev) => {
        prev.set("plantId", selectedPlant?.toString());
        return prev;
      },
      { replace: true }
    );
  }, [selectedPlant]);

  const getPortfolioAndPlant = () => {
    tenantInfo.forEach((item) => {
      if (parseInt(plantId) === item?.plantId) {
        setSelectedPlant(item?.plantId);
        setSelectedPortfolio(item?.portfolioName);
      }
    });
  };

  const onChangePortfolio = (value) => {
    const newPlants = [];
    setSelectedPortfolio(value);
    tenantInfo?.forEach((item) => {
      if (item?.portfolioName === value) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    if (newPlants.length > 0) {
      setSelectedPlant(newPlants[0].value);
    } else {
      setSelectedPlant("");
    }
    setPlant(newPlants);
  };

  const onChangePlant = (value) => {
    setSelectedPlant(value);
  };

  const checkLiveButton = (e) => {
    if (!liveButtonState) {
      setDate([
        {
          endDate: new Date().toISOString(),
          key: "selection",
          startDate: new Date().toISOString(),
        },
      ]);
    }
    setLiveButtonState(e);
  };

  const getPortfoliosInfo = async () => {
    if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0)
      return;
    setTenantInfo(portfoliosAndPlantsData);
    getPortfolio(portfoliosAndPlantsData);
  };

  const getPortfolio = async (data: any) => {
    const fil = [];
    const newPortfolio: Array<DropdownOption> = [];
    data?.forEach((item) => {
      if (!fil.includes(item?.portfolioName)) {
        fil.push(item?.portfolioName);
        newPortfolio.push({
          label: item?.portfolioName,
          value: item?.portfolioName,
        });
      }
    });
    setPortfolio(newPortfolio);
    if (!plantId && newPortfolio.length > 0) {
      setSelectedPortfolio(newPortfolio?.[0]?.value);
    }
  };

  const getPlants = async (portfolio: any) => {
    const newPlants = [];
    tenantInfo?.forEach((item) => {
      if (item?.portfolioName === portfolio) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    setPlant(newPlants);
    if (!plantId && newPlants.length > 0) {
      setSelectedPlant(newPlants?.[0]?.value);
    }
  };

  return {
    portfolio,
    plant,
    selectedPortfolio,
    setSelectedPortfolio,
    selectedPlant,
    setSelectedPlant,
    date,
    setDate,
    liveButton,
    checkLiveButton,
    globalDate,
    onChangePlant,
    onChangePortfolio,
  };
};
