import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Modal } from '../../../shared/components';

const useStyles = makeStyles((theme: Theme) => ({
    message: {
        margin: theme.spacing(2, 0, 6, 0),
        fontSize: 16,
        color: 'black'
    }
}));

interface Props {
    isOpen: boolean;
    title?: string;
    titleComponent?: React.ReactNode;
    onClose: () => void;
    applyBtnTitle?: string;
    applyColor?: string;
    onApply?: () => void;
    message?: string;
}

export const PlantPopup: React.FC<Props> = (props) => {
    const classes = useStyles();

    const { isOpen, title, titleComponent, onClose, } = props;

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            titleComponent={titleComponent}
            onClose={onClose}
            content={
                (title === "Issues" ?
                    null
                    :
                    null)
            }
            width={"60%"}
            height={"80%"}
        />
    );
}