import { useBatteryTemperatureWidget } from "../../useBatteryTemperatureWidget";
import { DataWithUnitWidget } from "../DataWithUnitWidget";

export const BatteryTemperatureWidget = () => {
  const {
    options: batteryTemperatureData,
    isLoading: isBatteryTemperatureLoading,
    isFetching: isBatteryTemperatureFetching,
    isError: isBatteryTemperatureError,
  } = useBatteryTemperatureWidget();

  return (
    <DataWithUnitWidget
      title="Temperature"
      options={batteryTemperatureData}
      optionsCount={{ top: 2, bottom: 2 }}
      isLoading={isBatteryTemperatureLoading}
    />
  );
};
