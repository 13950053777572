import React from "react";
import { Surface, Symbols } from "recharts";

export const RenderCustomLegend = (props: any) => {
  const { payload } = props;

  return (
    <div className="customized-legend">
      {payload.map((entry, i) => {
        const { dataKey, color } = entry;        
        return (
          <span
            className="legend-item"
            style={{
              marginRight: 20,
            }}
            key={i}
          >
            {/* @ts-ignore */}
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols cx={5} cy={5} type="square" size={100} fill={color} />
            </Surface>
            <span> {dataKey}</span>
          </span>
        );
      })}
    </div>
  );
};
