import React from 'react'
import { Toaster, toast } from 'sonner'
import './toaster.css'

interface ToasterSnackbarProps {
    position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right' | 'top-center' | 'bottom-center';
    visibleToasts?: number;
}

export const ToasterSnackbar = (props: ToasterSnackbarProps) => {
    const {
        position = "bottom-left",
        visibleToasts = 3
    } = props

    return (
        <div style={{ display: 'flex' }}>
            <Toaster
                position={position}
                duration={4500} // 4.5 seconds
                richColors
                visibleToasts={visibleToasts}
                closeButton={false}
                toastOptions={{
                    className: 'fullscreen-toast'
                }}
            />
        </div>
    )
}
