import { Skeleton } from "@mui/material";
import {
  DataWithUnitWidgetOption,
  DataWithUnitWidgetProps,
} from "./DataWithUnitWidget.types";
import { useStyles } from "./styles";
import { useMemo } from "react";
import { ChartGroupButton } from "../../../../components/CustomCombinedChart/ChartGroupButton.component";

export const DataWithUnitWidget = ({
  title,
  options,
  optionsCount,
  isLoading,
}: DataWithUnitWidgetProps) => {
  const classes = useStyles();

  const isTabs = options && "tabs" in options;

  const buttons = useMemo(() => {
    if (!isTabs) {
      return null;
    } else {
      const { tabs } = options;

      return tabs.values.map((tab) => ({
        label: tab.label,
        onClick: () => tabs.onChange(tab.key),
        active: tab.key === tabs.active,
        disabled: false,
      }));
    }
  }, [isTabs, options]);

  const mappedOptionsTop = useMemo(() => {
    const _options: DataWithUnitWidgetOption[] =
      options && "top" in options
        ? isTabs
          ? options.top?.[options.tabs.active]
          : options.top || options
        : options || null;

    return Array.isArray(_options)
      ? _options?.map((option) => {
          return (
            <div key={option.label} className={`${classes.option} top`}>
              <div className={classes.optionValueWrapper}>
                <p className={classes.optionValue}>{option.value}</p>
                {option.unit && (
                  <p className={classes.optionUnit}>{option.unit}</p>
                )}
              </div>
              <p className={classes.optionLabel}>{option.label}</p>
            </div>
          );
        })
      : null;
  }, [options, isTabs]);

  const mappedOptionsBottom = useMemo(() => {
    const _options: DataWithUnitWidgetOption[] =
      options && "bottom" in options
        ? isTabs
          ? options.bottom?.[options.tabs.active]
          : options.bottom || null
        : null;

    return Array.isArray(_options)
      ? _options?.map((option) => {
          return (
            <div key={option.label} className={`${classes.option} bottom`}>
              <p className={classes.optionLabel}>{option.label}</p>
              <div className={classes.optionBottomValueWrapper}>
                <p className={classes.optionBottomValue}>{option.value}</p>
                {option.unit && (
                  <p className={classes.optionBottomUnit}>{option.unit}</p>
                )}
              </div>
            </div>
          );
        })
      : null;
  }, [options, isTabs]);

  const loadersTop = useMemo(() => {
    const _optionsCount =
      typeof optionsCount === "object" ? optionsCount.top || 1 : optionsCount;
    return new Array(_optionsCount)
      .fill(null)
      .map(() => <Skeleton width="100%" height="46px" />);
  }, [optionsCount]);

  const loadersBottom = useMemo(() => {
    const _optionsCount =
      typeof optionsCount === "object" ? optionsCount.bottom || 1 : null;

    if (!_optionsCount) {
      return null;
    }

    return new Array(_optionsCount).fill(null).map(() => (
      <div className={`${classes.option} bottom`}>
        <Skeleton width="150px" height="15px" />
        <Skeleton width="36px" height="15px" />
      </div>
    ));
  }, [optionsCount]);

  const shouldDisplayOptionsTop = useMemo(() => {
    return (
      (typeof optionsCount === "number" && optionsCount > 0) ||
      (typeof optionsCount === "object" &&
        optionsCount &&
        "top" in optionsCount &&
        optionsCount.top > 0)
    );
  }, [optionsCount]);

  const shouldDisplayOptionsBottom = useMemo(() => {
    return (
      typeof optionsCount === "object" &&
      optionsCount &&
      "bottom" in optionsCount &&
      optionsCount.bottom > 0
    );
  }, [optionsCount]);

  return (
    <div className={classes.wrapper}>
      <p className={classes.heading}>
        {title}
        {isTabs && <ChartGroupButton buttons={buttons} />}
      </p>

      <div className={classes.optionsWrapper}>
        {shouldDisplayOptionsTop && (
          <div className={`${classes.options} top`}>
            {isLoading ? (
              <div className={classes.loadersTopWrapper}>{loadersTop}</div>
            ) : (
              mappedOptionsTop
            )}
          </div>
        )}

        {shouldDisplayOptionsBottom && (
          <div className={`${classes.options} bottom`}>
            {isLoading ? loadersBottom : mappedOptionsBottom}
          </div>
        )}
      </div>
    </div>
  );
};
