import React from "react";
import { Dropdown, Typography } from "../../../../../shared/components";

interface selectProps {
  name: string;
  label?: string;
  subLabel?: string;
  formik: any;
  Data: any;
  width?: any;
  handleChange?: (value: any, name: string) => void;
  onClick?: (key: string) => void;
  showAdd?: boolean;
  isAdd?: boolean;
  showOnEdit?: boolean;
  showOnAdd?: boolean;
  multiple?: boolean;
}

export const RenderDropdown: React.FC<selectProps> = (props) => {
  return (
    <>
      {(props.isAdd == true && props.showOnAdd == true) ||
        (props.isAdd == false && props.showOnEdit == true) || (props.isAdd == undefined) ? (
        <Typography label={props.label} subLabel={props.subLabel}>
          {" "}
          <Dropdown
            label={""}
            multiple={props.multiple || false}
            name={props.name}
            value={props.multiple ? (props.formik.values[props.name] || []) : (props.formik.values[props.name] || "")}
            options={props.Data || []}
            width={props.width != undefined ? props.width : 300}
            onChangeValue={(filterValue: string): void => {
              props.formik.setFieldValue(props.name, filterValue);
              if (props.handleChange) props.handleChange(filterValue, props.name);
            }}
            showError={
              props.formik?.touched[props.name] &&
              Boolean(props.formik.errors[props.name])
            }
            errorMessage={props.formik.errors[props.name]}
            showAdd={props.showAdd}
            onAddNew={(key) => props.onClick(key)}
          />
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}


