import React, { useEffect, useState } from "react";
import { FormControl, Select } from "@mui/material";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { useStyles } from "./styles";
import { ActionButtons } from "../ActionButtons";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./index.css";
import { isDateInInvalidFormat } from "../../../shared/utils/utils";
import { ReactComponent as LEFT_ARROW_BLACK } from "../../icons/LEFTARROWBLACK.svg";
import { ReactComponent as RIGHT_ARROW_GRAY } from "../../icons/RIGHTARROWGRAY.svg";

interface Props {
  date: any;
  maxDate?: any;
  onChange: any;
  isDisabled?: boolean;
}

export const DateRange: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { date, maxDate, onChange, isDisabled } = props;

  const [newDate, setNewDate] = useState(date);

  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    const fixedDate = date.map((item) => {
      let startDate = item.startDate;
      let endDate = item.endDate;
      if (isDateInInvalidFormat(startDate)) {
        startDate = moment(startDate, "YYYY.MM.DD").toISOString();
      }
      if (isDateInInvalidFormat(endDate)) {
        endDate = moment(endDate, "YYYY.MM.DD").toISOString();
      }
      return {
        ...item,
        startDate,
        endDate,
      };
    });
    setNewDate(fixedDate);
  }, [date]);

  const handleApply = () => {
    setSelectOpen(false);
    onChange({
      selection: {
        endDate: newDate[0].endDate,
        key: newDate[0].key,
        startDate: newDate[0].startDate,
      },
    });
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  const handleChange = (item) => {
    setNewDate([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: "selection",
      },
    ]);
  };

  const handleOpen = () => {
    setSelectOpen(true);
  };

  const checkStartEndDate = () => {
    if (
      moment(date[0].startDate).format("yyyy-MM-DD") ===
      moment(date[0].endDate).format("yyyy-MM-DD")
    )
      return true;
    else return false;
  };

  const checkDate = () => {
    if (
      moment(date[0].startDate).format("yyyy-MM-DD") ===
      moment().format("yyyy-MM-DD")
    )
      return true;
    else return false;
  };

  const changeDateButton = (format: string) => {
    if (format === "subtract") {
      onChange({
        selection: {
          endDate: new Date(
            moment(date[0].startDate).subtract(1, "days").format()
          ).toISOString(),
          key: "selection",
          startDate: new Date(
            moment(date[0].startDate).subtract(1, "days").format()
          ).toISOString(),
        },
      });
      setNewDate([
        {
          startDate: new Date(
            moment(date[0].startDate).subtract(1, "days").format()
          ).toISOString(),
          endDate: new Date(
            moment(date[0].startDate).subtract(1, "days").format()
          ).toISOString(),
          key: "selection",
        },
      ]);
    }

    if (format === "add") {
      onChange({
        selection: {
          endDate: new Date(
            moment(date[0].startDate).add(1, "days").format()
          ).toISOString(),
          key: "selection",
          startDate: new Date(
            moment(date[0].startDate).add(1, "days").format()
          ).toISOString(),
        },
      });
      setNewDate([
        {
          startDate: new Date(
            moment(date[0].startDate).add(1, "days").format()
          ).toISOString(),
          endDate: new Date(
            moment(date[0].startDate).add(1, "days").format()
          ).toISOString(),
          key: "selection",
        },
      ]);
    }
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className={isDisabled ? classes.disabledDiv : ""}
      >
        {checkStartEndDate() ? (
          <LEFT_ARROW_BLACK
            style={{ cursor: "pointer" }}
            onClick={() => changeDateButton("subtract")}
          />
        ) : null}
        <FormControl sx={{ m: 1 }} size="small">
          <Select
            multiple
            disabled={isDisabled}
            sx={{
              fontWeight: 500,
              fontSize: "15px",
              width: "auto",
              fontFamily: "inter",
              maxWidth: "250px",
              "& .MuiSvgIcon-root": {
                paddingBottom: "2px",
              },
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
            }}
            // variant={"outlined"}
            value={[
              moment(newDate[0].startDate).format("DD MMM, YYYY"),
              moment(newDate[0].endDate).format("DD MMM, YYYY"),
            ]}
            labelId="format-select"
            id="format-select"
            label="Period"
            className={classes.select}
            renderValue={(selected) => {
              if (selected[0] === selected[1]) {
                return <span> {selected[0]} </span>;
              }
              return (
                <span>
                  {" "}
                  {selected[0]} - {selected[1]}{" "}
                </span>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  border: "1px solid var(--light-primary-gray-40, #E1E4E9)",
                  borderRadius: "8px",
                  boxShadow:
                    "rgb(0 0 0 / 0%) 0px 5px 5px 0px, rgb(0 0 0 / 0%) 0px 8px 10px 1px, rgb(0 0 0 / 10%) 0px 8px 24px 2px",
                },
              },
            }}
            open={selectOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            IconComponent={() => <></>}
          >
            <div>
              <DateRangePicker
                disabled={isDisabled}
                onChange={(item) => handleChange(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                months={1}
                ranges={[
                  {
                    startDate: new Date(newDate[0].startDate),
                    endDate: new Date(newDate[0].endDate),
                    key: "selection",
                  },
                ]}
                direction="horizontal"
                rangeColors={["#DEEFFE"]}
                maxDate={maxDate ? maxDate : new Date()}
                editableDateInputs={false}
                style={{ paddingRight: "10px" }}
              />
              <div className={classes.boxStyle}>
                <div style={{ paddingTop: "48px", paddingLeft: "20px" }}>
                  {moment(newDate[0].startDate).format("DD MMM, YYYY") ===
                  moment(newDate[0].endDate).format("DD MMM, YYYY")
                    ? moment(newDate[0].startDate).format("DD MMM, YYYY")
                    : `${moment(newDate[0].startDate).format(
                        "DD MMM, YYYY"
                      )} - ${moment(newDate[0].endDate).format(
                        "DD MMM, YYYY"
                      )}`}
                </div>
                <div>
                  <ActionButtons
                    saveTitle="Apply"
                    onSave={() => {
                      return handleApply();
                    }}
                    saveButtonType="submit"
                    cancelTitle="Cancel"
                    onCancel={() => {
                      handleClose();
                    }}
                    borderTop={false}
                  />
                </div>
              </div>
            </div>
          </Select>
        </FormControl>
        {checkStartEndDate() ? (
          <RIGHT_ARROW_GRAY
            style={{
              cursor: `${checkDate() ? "initial" : "pointer"}`,
              opacity: `${checkDate() ? "25%" : "100%"}`,
            }}
            onClick={() => (checkDate() ? null : changeDateButton("add"))}
          />
        ) : null}
      </div>
    </>
  );
};
