import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertTime,
  getHoursAndMinutesFromISO,
  GLOBAL_COLORS,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryPowerStatusHistoricalQuery,
} from "../../BatteryMonitoring.api";
import { BatteryPowerStatusHistoricalResponse } from "../../BatteryMonitoring.api.types";
import { chartOptions } from "../../../../../shared/utils/chartOptions";
import { PowerChartWidgetData } from "./PowerChartWidget.types";
import {
  CHART_COLORS,
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTION_VALUE,
} from "../../utils";
import { useUrlParams } from "../../../../../shared/utils/hooks";
import { getXAxisCategories } from "../BatteryControlChartWidget/utils";

export const usePowerChartWidget = (): PowerChartWidgetData => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const { liveButton, globalDate } = useAppSelector(
    monitoringDashboardSelector
  );

  const [dropdownValue, setDropdownValue] = useState<DROPDOWN_OPTION_VALUE>(
    DROPDOWN_OPTION_VALUE.ONE_MIN
  );

  const [options, setOptions] = useState({
    title: { text: "" },
    lang: {
      noData: "",
    },
  });

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const { data, isLoading, isFetching, isError } =
    useGetBatteryPowerStatusHistoricalQuery(
      {
        fromDate,
        toDate,
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        granularity: dropdownValue.toString(),
      },
      {
        skip: isAnyItemFalsy([
          fromDate,
          toDate,
          plantId,
          DEFAULT_EQUIPMENT_NAME,
          dropdownValue.toString(),
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

  const getChart = useCallback((data: BatteryPowerStatusHistoricalResponse) => {
    const active = [];
    const reactive = [];
    const apparent = [];
    const power = [];

    data?.items?.forEach((item) => {
      const date = Date.parse(
        String(
          item?.timestamp ||
            item?.active?.date ||
            item?.apparent?.date ||
            item?.power?.date ||
            item?.reactive?.date
        )
      );

      active.push({
        x: date,
        y: item?.active?.value || null,
        color: CHART_COLORS.ACTIVE_POWER,
        unit: item?.active?.unit || "",
      });

      reactive.push({
        x: date,
        y: item?.reactive?.value || null,
        color: CHART_COLORS.REACTIVE_POWER,
        unit: item?.reactive?.unit || "",
      });

      apparent.push({
        x: date,
        y: item?.apparent?.value || null,
        color: CHART_COLORS.APPARENT_POWER,
        unit: item?.apparent?.unit || "",
      });

      power.push({
        x: date,
        y: item?.power?.value || null,
        color: CHART_COLORS.POWER_FACTOR,
        unit: item?.power?.unit || "",
      });
    });

    const xAxis = {
      endOnTick: true,
      showLastLabel: false,
      startOnTick: false,
      type: "datetime",
      tickLength: 0,
      crosshair: {
        width: 1,
        color: GLOBAL_COLORS.DATA_VIZ_GRAY_30,
      },
      labels: {
        formatter: function () {
          const result = getXAxisCategories(
            this.value,
            data?.items || [],
            Number(dropdownValue)
          );
          return result;
        },
      },
    };

    const yAxis = [
      // active reactive apparent
      {
        visible: true,
        opposite: false,
        allowDecimals: true,
        type: "linear",
        gridLineWidth: 0,
        title: {
          text: "kW, kVA, kVAR",
        },
        threshold: 0,
        startOnTick: false,
        endOnTick: false
      },

      // power factor
      {
        labels: {
          format: "{text}",
        },
        visible: true,
        opposite: true,
        allowDecimals: false,
        type: "linear",
        gridLineWidth: 0,
        title: {
          text: "",
        },
        min: -1,
        max: 1,
        startOnTick: false,
        endOnTick: false
      },
    ];

    const activeUnit = active.find((item) => item.unit)?.unit || "";
    const reactiveUnit = reactive.find((item) => item.unit)?.unit || "";
    const apparentUnit = apparent.find((item) => item.unit)?.unit || "";
    const powerUnit = power.find((item) => item.unit)?.unit || "";

    const series = [
      {
        name: "Active Power",
        data: active,
        tooltip: {
          valueSuffix: activeUnit,
        },
        yAxis: 0,
        type: "line",
        events: {},
        cursor: "pointer",
        color: CHART_COLORS.ACTIVE_POWER,
        zIndex: 1,
      },
      {
        name: "Apparent Power",
        data: apparent,
        tooltip: {
          valueSuffix: apparentUnit,
        },
        yAxis: 0,
        type: "line",
        events: {},
        cursor: "pointer",
        color: CHART_COLORS.APPARENT_POWER,
        zIndex: 1,
      },
      {
        name: "Reactive Power",
        data: reactive,
        tooltip: {
          valueSuffix: reactiveUnit,
        },
        yAxis: 0,
        type: "line",
        events: {},
        cursor: "pointer",
        color: CHART_COLORS.REACTIVE_POWER,
        zIndex: 1,
      },
      {
        name: "Power Factor",
        data: power,
        tooltip: {
          valueSuffix: powerUnit,
        },
        yAxis: 1,
        type: "line",
        events: {},
        cursor: "pointer",
        color: CHART_COLORS.POWER_FACTOR,
        zIndex: 0,
      },
    ];

    const plotOptions = {
      line: {
        marker: {
          enabled: false,
        },
      },
      area: {
        marker: {
          enabled: false,
        },
      },
      series: { turboThreshold: 0, connectNulls: true },
    };

    const tooltipFormatter = function (s, point) {
      const date = new Date(point.x);
      const hour = date.getHours();
      const minute = date.getMinutes();

      const hourString = hour < 10 ? `0${hour}` : hour;
      const minuteString = minute < 10 ? `0${minute}` : minute;

      const series = point.series.chart.series;

      const mappedValues = series.reduce((acc, serie) => {
        const dataPoint = serie.data.find((item) => item.x === point.x);

        if (dataPoint && (dataPoint.y || dataPoint.y === 0)) {
          const { name, color } = serie;
          const { valueSuffix = "" } = serie.userOptions.tooltip;

          acc += `
            <div style="display:flex;">
              <div style="display:flex; align-items:baseline; font-size:13px; font-weight:400; padding-bottom:8px;">
                <tspan style="font-size:20px; color: ${color}; fill: ${color};">●</tspan>&nbsp;${name}
              </div>
              <div style="display:grid; width:100%;">
                <div style="font-size:15px; font-weight:bold;justify-self:end; padding-top:7px;">
                  &nbsp;&nbsp;&nbsp;${dataPoint.y} ${valueSuffix}
                </div>
              </div>
              <br/>
            </div>
          `;
        }
        return acc;
      }, "");

      return `
        <span style="font-size:15px; font-weight:bold">${hourString}:${minuteString}<hr></span>
        ${mappedValues}
      `;
    };

    return {
      xAxis,
      yAxis,
      tooltipFormatter: tooltipFormatter,
      series,
      plotOptions,
      isLoading,
      isFetching
    };
  }, []);

  useEffect(() => {
    const title = "Power & PF";

    const { xAxis, yAxis, tooltipFormatter, series, plotOptions } =
      getChart(data);

    const options = {
      ...chartOptions({
        title: title,
        colors: [],
        loading: isLoading,
        xAxis: xAxis,
        yAxis: yAxis,
        tooltipFormatter: tooltipFormatter,
        plotOptions: plotOptions,
        data: series,
      }),
    };

    const newOptions = {
      ...options,
      chart: {
        borderRadius: 8,
        height: "400px",
        zooming: { type: "x" },
        events: {
          load() {
            if (isLoading || isFetching) {
              this.showLoading();
            } else {
              this.hideLoading();
            }
          },
          redraw() {
            if (isLoading || isFetching) {
              this.showLoading();
            } else {
              this.hideLoading();
            }
          },
        },
      },
      loading: {
        showDuration: 0,
        hideDuration: 1000,
        labelStyle: {
          fontSize: "16px",
        },
      },
      legend: {
        itemStyle: {
          fontWeight: "regular",
          fontFamily: "Inter",
        },
      },
      xAxis: xAxis,
      yAxis: yAxis,
      styledMode: true,
      title: {
        ...options.title,
        x: -4,
        y: 24 + 5,
        margin: 46,
        fontFamily: "Inter",
      },
      navigation: {
        buttonOptions: {
          y: 16,
          x: -16,
        },
      },
    };

    setOptions(newOptions);
  }, [data, isLoading, dropdownValue, getChart]);

  // TODO: kaz uncomment in future when other granularities than 1 minute will appear

  // const onDropdownChange = useCallback((value: DROPDOWN_OPTION_VALUE) => {
  //   setDropdownValue(value);
  // }, []);

  return {
    plantId,
    startDate,
    endDate,
    liveButton,
    globalDate,
    dropdownOptions: null,
    // TODO: kaz for now only 1 minute granularity, uncomment in future when other granularities than 1 minute will appear
    //  {
    //   value: dropdownValue,
    //   // items: DROPDOWN_OPTIONS.slice(0, 2),
    //   items: DROPDOWN_OPTIONS.slice(1, 2),
    //   onChange: onDropdownChange,
    // },
    buttonGroups: [],
    chartOptions: options,
    data,
  };
};
