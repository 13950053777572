import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  warningMessage: {
    color: '#9F9F9F',
    margin: 'auto',
    textAlign: 'center',
  }
}));

interface Props {
  label: string;
  size?: number;
}

export const WarningMessage: React.FC<Props> = ({ label, size = 32 }: Props) => {
  const classes = useStyles();
  
  return (
    <div className={classes.warningMessage} style={{ fontSize: size }}>{label}</div>
  )
}
