import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "./styles";

export const CustomTooltip = ({ active, payload }: { active?: boolean, payload?: Array<any> }) => {
  const classes = useStyles();

  if (!active || !payload?.length)
    return null;

  const data = payload?.[0]?.payload;
  let inv;
  let block;
  if (data?.label.includes('Inv')) {
    let split = data?.label.split('-')[1]
    split = split.split('.')
    inv = split[1]
    block = split[0]
  }
  else {
    const split = data?.label?.split(".");
    block = split[0]
    inv = split[1]
  }

  return (
    <div>
      <div className={classes.tooltipHeader}>
        <div>
          Block {block},  Inverter {inv}
        </div>
        <IconButton style={{ color: "white" }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <TooltipWrapper>
        <div>Actual Efficiency: {data?.value?.toFixed(1)} % </div>
        <div>Expected Efficiency: {data?.expectedValue?.toFixed(1)} % </div>
      </TooltipWrapper>
    </div>
  )
}