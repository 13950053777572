import { Suspense, useState } from "react";
import { Fallback } from "../../../app/Fallback";
import {
  Accordion,
  AccordionSummary,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import {
  Button,
  ConfirmationModal,
  SelectorDropdown,
  Snackbar,
} from "../../../shared/components";
import { useLobbyScreen } from "./useLobbyScreen";
import {
  energyScreenOptions,
  energyScreenOptionsParent,
  overViewScreenOptions,
  powerIrrScreenOptions,
  powerIrrScreenOptionsParent,
  siteMonthlyScreenOptions,
  siteMonthlyScreenOptionsParent,
  siteOverviewScreenOptions,
  siteOverviewScreenOptionsParent,
  siteYearlyScreenOptions,
  siteYearlyScreenOptionsParent,
} from "./utils";
import { CustomAccordion } from "./customAccordion/customAccordion";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LogosWidget } from "./LogosWidget";
import { Button2 } from "../../../shared/components/Button2/Button2.component";
import { ImagesWidget } from "./ImagesWidget";
import { appTheme } from "../../../app/theme";
import { ColorWidget } from "./ImagesWidget/ColorWidget";

export const LobbyScreen = () => {
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();
  const {
    selectedUser,
    users,
    selectedTenant,
    tenants,
    onChangeTenant,
    onChangeUser,
    userSelectDisabled,
    lobbyScreenPagePreferencesData,
    overViewScreenVersion,
    setOverViewScreenVersion,
    setEnergyScreenCheckBoxes,
    setPowerIrrScreenCheckBoxes,
    setEnergyScreenDropdown,
    setPowerIrrScreenDropdown,
    setSiteOverviewScreenCheckBoxes,
    setSiteOverviewScreenDropdown,
    setSiteMonthlyDropdown,
    setSiteMonthlyCheckBoxes,
    setSiteYearlyDropdown,
    setSiteYearlyCheckBoxes,
    postData,
    severity,
    isOpen,
    message,
    setIsOpen,
    openCancelModal,
    setOpenCancelModal,
    primaryLogo,
    secondaryLogo,
    primaryLogoSize,
    secondaryLogoSize,
    uploadPrimaryLogo,
    uploadSecondaryLogo,
    removePrimaryLogo,
    removeSecondaryLogo,
    deletePrimaryLogo,
    deleteSecondaryLogo,
    isLoading,
    isFetching,
    primaryLogoLoading,
    secondaryLogoLoading,
    primaryLogoName,
    secondaryLogoName,
    siteImages,
    uploadSiteImage,
    siteImagesFetching,
    siteImagesLoading,
    deleteSiteImage,
    colors,
    setColors,
    defaultColors,
    tenantName,
    tenantDefaultColors,
  } = useLobbyScreen();

  const navigate = useNavigate();

  return (
    <Suspense fallback={<Fallback />}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          margin: "12px 12px 6px 12px",
          height: "97vh",
          overflowY: "auto",
          backgroundColor: "white",
          width: "auto",
          borderRadius: "4px",
        }}
      >
        <div className={classes.wrapper}>
          <div className={classes.parentWidth}>
            <div className={classes.heading}>Lobby screen</div>
            <div className={classes.description}>
              You can customize the screens that specific users will see on
              their lobby dashboard.
            </div>
            <div className={classes.flexItem}>
              <div className={classes.tenantSelect}>
                <div className={classes.tenantText}>Tenant</div>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <SelectorDropdown
                      value={selectedTenant}
                      options={tenants}
                      onChangeValue={(filterValue: string): void =>
                        onChangeTenant(filterValue)
                      }
                      marginRight="0px"
                      border
                      minWidth="120px"
                      verticalAnchorOrigin={"bottom"}
                      horizontalAnchorOrigin={"center"}
                      verticalTransformOrigin={"top"}
                      horizontalTransformOrigin={"center"}
                    />
                  </FormControl>
                </div>
              </div>
              <div className={classes.userSelect}>
                <div className={classes.userText}>User</div>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <SelectorDropdown
                      value={selectedUser}
                      options={users}
                      onChangeValue={(filterValue: string): void =>
                        onChangeUser(filterValue)
                      }
                      marginRight="0px"
                      border
                      minWidth="120px"
                      verticalAnchorOrigin={"bottom"}
                      horizontalAnchorOrigin={"center"}
                      verticalTransformOrigin={"top"}
                      horizontalTransformOrigin={"center"}
                      disabled={userSelectDisabled}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div style={{ marginBottom: "75px" }}>
              {!lobbyScreenPagePreferencesData?.length &&
                !(isLoading || isFetching) ? null : (
                <>
                  <Card
                    variant="outlined"
                    sx={{ marginRight: "10px", marginY: "24px" }}
                  >
                    {/* Logos */}
                    <CardHeader
                      title="Logos"
                      titleTypographyProps={{
                        fontSize: "20px",
                        fontWeight: 600,
                        fontFamily: "Inter",
                      }}
                      subheader="You may upload up to two logos in SVG format. For better visibility, we recommend uploading white versions of your logos."
                      subheaderTypographyProps={{
                        fontSize: "15px",
                        fontWeight: 400,
                        color: "#0C1E33",
                        fontFamily: "Inter",
                      }}
                      sx={{ padding: "24px 16px" }}
                    />
                    <Divider />
                    <LogosWidget
                      logoType="Primary logo"
                      logo={primaryLogo}
                      logoSize={primaryLogoSize}
                      uploadLogo={uploadPrimaryLogo}
                      removeLogo={removePrimaryLogo}
                      deleteLogo={deletePrimaryLogo}
                      logoLoading={primaryLogoLoading}
                      logoName={primaryLogoName}
                    />
                    <LogosWidget
                      logoType="Delivered by (optional)"
                      logo={secondaryLogo}
                      logoSize={secondaryLogoSize}
                      uploadLogo={uploadSecondaryLogo}
                      removeLogo={removeSecondaryLogo}
                      deleteLogo={deleteSecondaryLogo}
                      logoLoading={secondaryLogoLoading}
                      logoName={secondaryLogoName}
                    />
                  </Card>

                  {/* Colors control */}
                  <Card variant="outlined"
                    sx={{ marginRight: "10px", marginY: "24px" }}>

                    <div style={{
                      padding: "24px 16px"
                    }}>
                      <Grid container>
                        <Grid item xs={6} style={{
                          fontSize: "20px",
                          fontWeight: 600,
                          fontFamily: "Inter",
                        }}>
                          Colors
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                          <span style={{ paddingRight: "10px" }}>
                            <Button
                              title='Skyfri default'
                              onClick={() => {
                                defaultColors()
                              }}
                              outlined
                              color={appTheme.main}
                            />
                          </span>
                          <Button
                            title={`${tenantName} default`}
                            onClick={() => {
                              tenantDefaultColors()
                            }}
                            outlined
                            color={appTheme.main}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ margin: "20px 0px 0px 0px" }}>
                          <Grid gap={1.3} container>
                            <Grid item>
                              <ColorWidget type="Background" setColor={(color) => {
                                setColors({ ...colors, backgroundColor: color.hex })
                              }}
                                color={colors?.backgroundColor} />
                            </Grid>
                            <Grid item>
                              <ColorWidget type="Energy, power" setColor={(color) => {
                                setColors({
                                  ...colors, energyColor: color.hex,
                                  energyColorGradient: `rgba(${color?.rgb?.r},${color?.rgb?.g},${color?.rgb?.b},0)`
                                })
                              }}
                                color={colors?.energyColor}
                              />
                            </Grid>
                            <Grid item>
                              <ColorWidget type="Export"
                                setColor={(color) => {
                                  setColors({
                                    ...colors, gridExportColor: color.hex,
                                    gridExportColorGradient: `rgba(${color?.rgb?.r},${color?.rgb?.g},${color?.rgb?.b},0)`
                                  })
                                }}
                                color={colors?.gridExportColor}
                              />
                            </Grid>

                            <Grid item>
                              <ColorWidget type="Import"
                                setColor={(color) => {
                                  setColors({
                                    ...colors, gridImportColor: color.hex,
                                    gridImportColorGradient: `rgba(${color?.rgb?.r},${color?.rgb?.g},${color?.rgb?.b},0)`
                                  })
                                }}
                                color={colors?.gridImportColor}
                              />
                            </Grid>
                            <Grid item>
                              <ColorWidget type="Irradiance"
                                setColor={(color) => {
                                  setColors({
                                    ...colors, irradianceColor: color.hex,
                                    irradianceColorGradient: `rgba(${color?.rgb?.r},${color?.rgb?.g},${color?.rgb?.b},0)`
                                  })
                                }}
                                color={colors?.irradianceColor}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Card>

                  <Card variant="outlined" sx={{ marginRight: "10px" }}>
                    {/* Version control */}
                    <CardHeader
                      title="Screens & Versions"
                      titleTypographyProps={{
                        fontSize: "20px",
                        fontWeight: 600,
                        fontFamily: "Inter",
                      }}
                      subheader="Customize the screens and versions that specific users will see on their lobby dashboard."
                      subheaderTypographyProps={{
                        fontSize: "15px",
                        fontWeight: 400,
                        color: "#0C1E33",
                        fontFamily: "Inter",
                      }}
                      sx={{ padding: "24px 16px" }}
                    />
                    <Divider />
                    {isLoading || isFetching ? (
                      <div style={{ padding: " 10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Accordion elevation={0} expanded={expanded}>
                          <AccordionSummary
                            style={{ cursor: "default" }}
                            expandIcon={
                              <ExpandMoreIcon
                                style={{ cursor: "default", opacity: 0.2, marginTop: "38px" }}
                              />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={(event) => event.stopPropagation()} // Prevent the click event from bubbling up to the parent Accordion
                          >
                            <Grid
                              container
                              onClick={(event) => event.stopPropagation()}
                            >
                              <Grid item xs={12}>
                                <div className={classes.accordionTitle}>
                                  Aggregate Screens
                                </div>
                              </Grid>
                              <Grid item xs={4} sx={{ display: "flex" }} alignItems={"center"}>
                                <div className={classes.accordionSubtitle}>
                                  Aggregate: 12-Month Production
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <div>
                                  <FormGroup style={{ cursor: "default" }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox defaultChecked disabled />
                                      }
                                      label={
                                        <Typography
                                          sx={{
                                            fontFamily: "Inter",
                                            fontSize: "15px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          All sites
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Inter",
                                }}
                              >
                                <div>
                                  <SelectorDropdown
                                    value={overViewScreenVersion}
                                    options={overViewScreenOptions}
                                    onChangeValue={(
                                      filterValue: string
                                    ): void =>
                                      setOverViewScreenVersion(filterValue)
                                    }
                                    marginRight="0px"
                                    border
                                    minWidth="180px"
                                    verticalAnchorOrigin={"bottom"}
                                    horizontalAnchorOrigin={"center"}
                                    verticalTransformOrigin={"top"}
                                    horizontalTransformOrigin={"center"}
                                    optionSubtitle
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                        </Accordion>
                      </div>
                    )}
                    {isLoading || isFetching ? (
                      <div style={{ padding: "10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Divider sx={{ marginX: "16px" }} />
                        <div className={classes.accordionTitle} style={{ marginTop: 10, paddingLeft: 16, paddingBottom: 2 }}>
                          Site specific screens
                        </div>
                        <div>
                          <CustomAccordion
                            title="Site: Overview"
                            dropdownOptionsParent={siteOverviewScreenOptionsParent}
                            dropdownOptions={siteOverviewScreenOptions}
                            plants={lobbyScreenPagePreferencesData}
                            initialCheckedState={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteOverviewPageEnabled
                            )}
                            dropdownVals={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteOverviewPageVersion
                            )}
                            initialDropdownValues={Array(50).fill("1")}
                            setCheckedState={setSiteOverviewScreenCheckBoxes}
                            setDropDownSate={setSiteOverviewScreenDropdown}
                            optionSubtitle={false}
                          />
                        </div>
                      </div>
                    )}

                    {isLoading || isFetching ? (
                      <div style={{ padding: " 10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Divider sx={{ marginX: "16px" }} />
                        <div>
                          <CustomAccordion
                            title="Site: 30-Day Production"
                            dropdownOptionsParent={energyScreenOptionsParent}
                            dropdownOptions={energyScreenOptions}
                            plants={lobbyScreenPagePreferencesData}
                            initialCheckedState={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.energyPageEnabled
                            )}
                            dropdownVals={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.energyPageVersion
                            )}
                            initialDropdownValues={Array(50).fill("1")}
                            setCheckedState={setEnergyScreenCheckBoxes}
                            setDropDownSate={setEnergyScreenDropdown}
                            optionSubtitle
                          />
                        </div>
                      </div>
                    )}
                    {isLoading || isFetching ? (
                      <div style={{ padding: " 10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Divider sx={{ marginX: "16px" }} />
                        <div>
                          <CustomAccordion
                            title="Site: Today's Power"
                            dropdownOptionsParent={powerIrrScreenOptionsParent}
                            dropdownOptions={powerIrrScreenOptions}
                            plants={lobbyScreenPagePreferencesData}
                            initialCheckedState={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.powerPageEnabled
                            )}
                            dropdownVals={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.powerPageVersion
                            )}
                            initialDropdownValues={Array(50).fill("1")}
                            setCheckedState={setPowerIrrScreenCheckBoxes}
                            setDropDownSate={setPowerIrrScreenDropdown}
                            optionSubtitle={false}
                          />
                        </div>
                      </div>
                    )}
                    {isLoading || isFetching ? (
                      <div style={{ padding: " 10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Divider sx={{ marginX: "16px" }} />
                        <div>
                          <CustomAccordion
                            title="Total and Monthly Energy Production this and last year"
                            dropdownOptionsParent={siteMonthlyScreenOptionsParent}
                            dropdownOptions={siteMonthlyScreenOptions}
                            plants={lobbyScreenPagePreferencesData}
                            initialCheckedState={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteMonthlyPageEnabled
                            )}
                            dropdownVals={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteMonthlyPageVersion
                            )}
                            initialDropdownValues={Array(50).fill("1")}
                            setCheckedState={setSiteMonthlyCheckBoxes}
                            setDropDownSate={setSiteMonthlyDropdown}
                            optionSubtitle={false}
                          />
                        </div>
                      </div>
                    )}
                    {isLoading || isFetching ? (
                      <div style={{ padding: "10px 16px 10px 16px" }}>
                        <Skeleton variant="rounded" height={50} />
                      </div>
                    ) : (
                      <div>
                        <Divider sx={{ marginX: "16px" }} />
                        <div>
                          <CustomAccordion
                            title="Total Energy Production and Yearly Production"
                            dropdownOptionsParent={siteYearlyScreenOptionsParent}
                            dropdownOptions={siteYearlyScreenOptions}
                            plants={lobbyScreenPagePreferencesData}
                            initialCheckedState={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteYearlyPageEnabled
                            )}
                            dropdownVals={lobbyScreenPagePreferencesData?.map(
                              (item) => item?.siteYearlyPageVersion
                            )}
                            initialDropdownValues={Array(50).fill("1")}
                            setCheckedState={setSiteYearlyCheckBoxes}
                            setDropDownSate={setSiteYearlyDropdown}
                            optionSubtitle={false}
                          />
                        </div>
                      </div>
                    )}
                  </Card>
                  {/* Site images */}
                  <Card
                    variant="outlined"
                    sx={{ marginRight: "10px", marginY: "24px" }}
                  >
                    <CardHeader
                      title="“Site: Overview” settings"
                      titleTypographyProps={{
                        fontSize: "20px",
                        fontWeight: 600,
                        fontFamily: "Inter",
                      }}
                      subheader="If at least one of your sites has the “Site: Overview” screen enabled, you should upload an image for those sites here."
                      subheaderTypographyProps={{
                        fontSize: "15px",
                        fontWeight: 400,
                        color: "#0C1E33",
                        fontFamily: "Inter",
                      }}
                      sx={{ padding: "24px 16px" }}
                    />
                    <Divider />
                    {
                      siteImages?.map((siteImage) => {
                        return (
                          <ImagesWidget
                            imageType={siteImage?.plantName}
                            image={siteImage?.plantImage}
                            uploadImage={uploadSiteImage}
                            removeImage={(plantId) => deleteSiteImage(plantId)}
                            imageLoading={siteImagesFetching || siteImagesLoading}
                            imageName={siteImage?.fileName}
                            plantId={siteImage?.plantId}
                            imageOriginalSize={siteImage?.plantImageOriginalSize}
                          />
                        )
                      })
                    }
                  </Card>
                </>
              )}
            </div>
          </div>
        </div>
        <Snackbar
          severity={severity}
          open={isOpen}
          message={message}
          handleClose={() => setIsOpen(false)}
        />
        <ConfirmationModal
          isOpen={openCancelModal}
          title="Are you sure you want to leave this page?"
          onClose={() => setOpenCancelModal(false)}
          applyBtnTitle="Leave"
          applyColor="#0C1E33"
          onApply={() => {
            setOpenCancelModal(false);
            navigate(-1);
          }}
          message={`Changes you made may not be saved.`}
        />
      </Container>
      <Grid
        container
        spacing={0}
        className={classes.footer}
        sx={{ paddingLeft: "83px" }}
      >
        <Grid
          item
          xs={12}
          style={{
            background: "white",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
        >
          <Divider orientation="horizontal" flexItem />
          <div
            style={{
              marginRight: "35px",
              display: "flex",
              justifyContent: "end",
              padding: "20px",
            }}
          >
            <span style={{ paddingLeft: "10px" }}>
              <Button2
                type="tertiary"
                size={40}
                onClick={() => {
                  !lobbyScreenPagePreferencesData?.length
                    ? navigate(-1)
                    : setOpenCancelModal(true);
                }}
              >
                Cancel
              </Button2>
            </span>
            <span style={{ paddingLeft: "10px" }}>
              <Button2
                size={40}
                type="primary"
                onClick={() => {
                  postData();
                }}
              >
                Save
              </Button2>
            </span>
          </div>
        </Grid>
      </Grid>
    </Suspense>
  );
};
