import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TabsContainer } from "./features/TabsContainer";
import { ParameterContainer } from "./features/ParameterContainer";
import { useStyles } from "./styles";
import { Button } from "../../../shared/components";
import { SaveTemplatePopup } from "./features/SaveTemplatePopup";
import { useMediaQuery, useTheme } from "@mui/material";
import { useReports } from "./useCustomReports";
import { useLocation } from 'react-router-dom';
import { Snackbar } from "../../../shared/components/Snackbar";
import { appTheme } from "../../../app/theme";

export const CustomReports: React.FC<any> = () => {
  const classes = useStyles();
  const [sheetHeaders, setSheetHeaders] = useState<any[]>([]);
  const [curTab, setCurTab] = useState<any>();
  const {
    GetAllColumns,
    columns,
    handleClickAdd,
    params,
    setParams,
    setTitle,
    setTemplateId,
    handleClickUpdate,
    DownloadTemplate,
    message,
    severity,
    isOpen,
    setIsOpen,
  } = useReports({ sheetHeaders });

  let location: any = useLocation();

  useEffect(() => {
    const getData = async () => {
      const cols = await GetAllColumns();
      if (location?.state) {
        formatSheetData(location?.state?.templateSheets, cols);
        setTitle(location?.state?.title);
        setTemplateId(location?.state?.templateId);
      }
    };
    getData();
  }, []);

  const [saveValues, setSaveValues] = useState({
    name: "",
    for: "",
  });
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getButton = (title: string, handleClick: any) => (
    <Button
      title={title}
      className={classes.buttonStyle}
      color={appTheme.main}
      onClick={handleClick}
    />
  );
  const formatSheetData = (sheets: any, cols: any) => {
    let newFormValues = [];
    Object.entries(sheets).forEach(([key, value]) => {
      let cells = [];
      // @ts-ignore
      value.sheetColumns.map((val) => {
        const col = cols.filter((col) => col.columnId === val.columnId);
        if (col.length > 0) {
          cells.push(col[0]?.columnName);
        }
      });
      newFormValues.push({
        // @ts-ignore
        sheet: parseInt(value.sheetName.split(" ")[1]),
        cells: cells,
      });
    });
    setSheetHeaders(newFormValues);
  };
  const addToSheet = (params, type) => {
    let column = [],
      headers = [];
    if (type === "param") {
      column = params.filter((param) => param.value);
      // add the sheet in template
      headers = column.map((item) => item.title);
    } else {
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          headers.push(key);
        }
      });
    }
    // add sheet headers
    let newFormValues = [...sheetHeaders];
    const tab = curTab ? curTab : 1;
    if (newFormValues.find((c) => c.sheet === tab) !== undefined) {
      let newcells = newFormValues.find((c) => c.sheet === tab).cells;
      newcells.push(...headers);
      newcells = newcells.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      newFormValues.find((c) => c.sheet === tab).cells = newcells;
    } else {
      newFormValues.push({ sheet: tab, cells: headers });
    }
    setSheetHeaders(newFormValues);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <Typography variant="h4" component="h1">
          Custom Reports
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={8}>
          <TabsContainer
            getButton={getButton}
            setCurTab={setCurTab}
            sheetHeaders={sheetHeaders}
            addToSheet={addToSheet}
          />
          {location?.state ? (
            <div className={classes.btnGroup}>
              <div className={classes.btn}>
                {getButton("Update template", handleClickUpdate)}
              </div>
              <div className={classes.btn}>
                {getButton("Download template", () => DownloadTemplate())}
              </div>
            </div>
          ) : (
            <div className={classes.btnGroup}>
              <div className={classes.btn}>
                {getButton("Save template", handleClickOpen)}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.paramContainer}>
          <ParameterContainer
            getButton={getButton}
            addToSheet={addToSheet}
            params={params}
            setParams={setParams}
          />
        </Grid>
      </Grid>
      <SaveTemplatePopup
        open={open}
        handleClose={handleClose}
        handleSave={handleClickAdd}
        fullScreen={fullScreen}
        saveValues={saveValues}
        setSaveValues={setSaveValues}
        setTitle={setTitle}
      />
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};
