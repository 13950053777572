import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../../../components/Loader';
import Widget from '../../../../components/Widget';
import { Chart } from './Chart.component';
import { useExpectedGeneration } from './useExpectedGeneration';

interface Props {
  plantId: string;
  date: string;
}

const useStyles = makeStyles((theme) => ({
  Widget: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    }
  }
}));

export const ExpectedGenerationWidget: React.FC<Props> = ({ plantId, date }) => {
  const { data, error, loading } = useExpectedGeneration({ plantId, date });
  const hide = !loading && !data?.length;

  const classes = useStyles();

  return hide ? null : (
    <Widget label="Theoretical generation graph" height={600} size={6} styles={classes.Widget} >
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }} id="containter">
        <Loader loading={loading} height={500} error={error} errorTextSize={32} />
        {(!data?.length || loading || error?.length) ? null : (
          <Chart data={data} width={"100%"} />
        )}
      </div>
    </Widget>
  )
}
