import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";
import { Button } from "../../../../../shared/components";
import { ReactComponent as LOCATION_BLACK } from "./../../../../../shared/icons/sidem/LOCATION_BLACK.svg";
import { ReactComponent as CAPACITY } from "./../../../../../shared/icons/monitoring/dashboard/CAPACITY.svg";
import { DateType } from "../../../../components/interfaces";
import { useStyles } from "./styles";
import {
  BatteryCurrentStatusResponse,
  GRID_CONNECTION_STATE,
  INVERTER_STATE,
} from "../../BatteryMonitoring.api.types";
import { Divider } from "@material-ui/core";
import { OnOffWidget } from "../OnOffWidget";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryInfoQuery,
} from "../../BatteryMonitoring.api";
import {
  GLOBAL_COLORS,
  isAnyItemFalsy,
  isNullOrUndefined,
} from "../../../../../shared/utils/utils";
import { useAppSelector } from "../../../../../store/rootStore";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useUrlParams } from "../../../../../shared/utils/hooks";

interface Props {
  date: DateType[];
  loading: boolean;
  data: BatteryCurrentStatusResponse;
  error: boolean;
  plant: Array<any>;
  selectedPlant: any;
}

export const DEFAULT_BESS_NAME = "Offenburg BESS";
export const DEFAULT_BESS_LOCATION = "Offenburg, Germany";

export enum INVERTER_STATE_BACKGROUND_COLORS {
  OFF = "#E6E6E6",
  SLEEPING = "#E6E6E6",
  STARTING = "#FFEFCC",
  RUNNING = "#E4F2EE",
  THROTTLED = "#FFEFCC",
  SHUTTING_DOWN = "#FFEFCC",
  FAULT = "#F8DCDC",
  STANDBY = "#E6E6E6",

  // custom one, not from the API
  NO_DATA = "#E6E6E6",
}

export enum INVERTER_STATE_DOT_COLORS {
  OFF = "#808080",
  SLEEPING = "#808080",
  STARTING = "#FEAE00",
  RUNNING = "#79BEAA",
  THROTTLED = "#DE504F",
  SHUTTING_DOWN = "#FEAE00",
  FAULT = "#DE504F",
  STANDBY = "#808080",

  // custom one, not from the API
  NO_DATA = "#808080",
}

export enum GRID_CONNECTION_STATE_BACKGROUND_COLORS {
  CONNECTED = "#EDF4F2",
  DISCONNECTED = "#FCEEED",

  // custom one, not from the API
  NO_DATA = "#E6E6E6",
}

export enum GRID_CONNECTION_STATE_DOT_COLORS {
  CONNECTED = "#79BEAA",
  DISCONNECTED = "#DE504F",

  // custom one, not from the API
  NO_DATA = "#808080",
}

export const SiteInfoWidget = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleSiteInfo = () => {
    navigate(`/siteInfo/plant?plantId=${plantId}`);
  };

  const { plantId } = useUrlParams({
    plantId: "",
  });

  const { liveButton } = useAppSelector(monitoringDashboardSelector);

  const {
    data: dataBatteryInfo,
    isLoading: isLoadingBatteryInfo,
    isFetching: isFetchingBatteryInfo,
    isError: isErrorBatteryInfo,
  } = useGetBatteryInfoQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
    },
    {
      skip: isAnyItemFalsy([plantId, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const [selectValue, setSelectValue] = useState(null);

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={1} alignItems={"center"} sx={{ marginTop: 0 }}>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={"auto"}>
              <div className={classes.secondText}>
                <div className={classes.plantText}>{DEFAULT_BESS_NAME}</div>
                <div className={classes.gapBetween}></div>
              </div>
            </Grid>

            <Grid item xs={"auto"}>
              <div className={classes.divFlex}>
                <div className={classes.divFlex}>
                  {isLoadingBatteryInfo ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem" }}
                      animation="pulse"
                      width={100}
                      height={24}
                    />
                  ) : (
                    <>
                      <div className={classes.divFlex}>
                        <LOCATION_BLACK />
                      </div>
                      <div className={classes.headerText}>
                        {DEFAULT_BESS_LOCATION}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={"auto"}>
              <div className={classes.divFlex}>
                <div className={classes.divFlex}>
                  {isLoadingBatteryInfo ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem" }}
                      animation="pulse"
                      width={100}
                      height={24}
                    />
                  ) : (
                    <>
                      <div className={classes.divFlex}>
                        <CAPACITY width={16} height={16} />
                      </div>
                      <div className={classes.headerText}>
                        {dataBatteryInfo?.capacityWh?.value}{" "}
                        {dataBatteryInfo?.capacityWh?.unit}{" "}
                        <span className={classes.headerTextCapacity}>
                          {dataBatteryInfo?.capacityAh?.value}{" "}
                          {dataBatteryInfo?.capacityAh?.unit}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ margin: "18px 0 12px 0" }} />

      <Grid container spacing={1} alignContent={"center"}>
        <Grid item sx={{ width: "100%" }}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={"auto"}>
              <div className={classes.onOffWrapper}>
                {isLoadingBatteryInfo ? (
                  <Skeleton width="150px" height="20px" />
                ) : (
                  <OnOffWidget
                    label={"Inverter state"}
                    status={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.inverterWorkingState
                          )
                          ? INVERTER_STATE.NO_DATA
                          : INVERTER_STATE[
                              dataBatteryInfo?.inverterWorkingState
                            ]
                        : INVERTER_STATE.NO_DATA
                    }
                    dotColor={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.inverterWorkingState
                          )
                          ? INVERTER_STATE_DOT_COLORS.NO_DATA
                          : INVERTER_STATE_DOT_COLORS[
                              dataBatteryInfo?.inverterWorkingState
                            ]
                        : INVERTER_STATE_DOT_COLORS.NO_DATA
                    }
                    backgroundColor={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.inverterWorkingState
                          )
                          ? INVERTER_STATE_BACKGROUND_COLORS.NO_DATA
                          : INVERTER_STATE_BACKGROUND_COLORS[
                              dataBatteryInfo?.inverterWorkingState
                            ]
                        : INVERTER_STATE_BACKGROUND_COLORS.NO_DATA
                    }
                  />
                )}
                {isLoadingBatteryInfo ? (
                  <Skeleton width="150px" height="20px" />
                ) : (
                  <OnOffWidget
                    label={"Grid connection"}
                    status={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.gridConnectionState
                          )
                          ? GRID_CONNECTION_STATE.NO_DATA
                          : GRID_CONNECTION_STATE[
                              dataBatteryInfo?.gridConnectionState
                            ]
                        : GRID_CONNECTION_STATE.NO_DATA
                    }
                    dotColor={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.gridConnectionState
                          )
                          ? GRID_CONNECTION_STATE_DOT_COLORS.NO_DATA
                          : GRID_CONNECTION_STATE_DOT_COLORS[
                              dataBatteryInfo?.gridConnectionState
                            ]
                        : GRID_CONNECTION_STATE_DOT_COLORS.NO_DATA
                    }
                    backgroundColor={
                      liveButton
                        ? isNullOrUndefined(
                            dataBatteryInfo?.gridConnectionState
                          )
                          ? GRID_CONNECTION_STATE_BACKGROUND_COLORS.NO_DATA
                          : GRID_CONNECTION_STATE_BACKGROUND_COLORS[
                              dataBatteryInfo?.gridConnectionState
                            ]
                        : GRID_CONNECTION_STATE_BACKGROUND_COLORS.NO_DATA
                    }
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "18px",
                  marginLeft: "auto",
                  // TODO: kaz remove it when the site info page is ready
                  cursor: "not-allowed",
                }}
              >
                <Button
                  // TODO: kaz styles for the button when not disabled
                  // color={GLOBAL_COLORS.BLACK}
                  // borderColor={GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40}
                  // TODO: kaz uncomment this in the future, when the site info page is ready
                  color={GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40}
                  borderRadius="6px"
                  // className={classes.siteInfoButton}
                  outlined={true}
                  type="button"
                  title={"Site info"}
                  // TODO: kaz uncomment this in the future, when the site info page is ready
                  disable={true}
                  onClick={handleSiteInfo}
                >
                  Site info
                </Button>

                {/* <Menu
                  id={"export-graphs-menu"}
                  label={"Export graphs"}
                  options={EXPORT_GRAPH_OPTIONS_MOCK}
                  value={selectValue}
                  // dontCloseOnSelect,
                  onChange={(updatedValue) => {
                    setSelectValue(updatedValue);
                  }}
                /> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
