import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    width: "100%",
    // height: "100%",
    overflow: "hidden",
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
  },

  modalHeader: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, -2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerFixed: {
    position: "sticky",
    top: "0",
    backgroundColor: "white",
    zIndex: 9999,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 600,
  },

  content: {
    flex: 1,
    overflow: "auto",
  },
}));

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: any;
  titleComponent?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  width?: string;
  height?: string | number;
  children?: React.ReactNode;
}

export const CustomModal: React.FC<ModalProps> = (props) => {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    title,
    titleComponent,
    content,
    actions,
    width,
    height,
    children,
  } = props;
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <>
        <Box
          style={{
            maxWidth: width,
            width,
            maxHeight: height,
            height,
            overflowY: "auto",
          }}
        >
          <div className={classes.modalContent} style={{ overflowY: "auto" }}>
            <div className={`${classes.modalHeader} ${classes.headerFixed}`}>
              {titleComponent ? (
                titleComponent
              ) : (
                <div className={classes.modalTitle}>{title}</div>
              )}

              <IconButton
                type="submit"
                sx={{ p: "10px" }}
                style={{ marginRight: -16 }}
                aria-label="search"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {content}
            {children}
            {actions}
          </div>
        </Box>
      </>
    </Modal>
  );
};
