import { useState, useEffect } from "react";
import { filterMenu } from "./utils";
import { useGetPortfoliosAndPlantsQuery } from "../../../features/Sidem/shared/sidemSharedApi";
interface Data {
  selectedPlant: any;
  selectedPortfolio: any;
  setSelectedPlant: any;
  setSelectedPortfolio: any;
  portfolio: any[];
  plant: any[];
  tenantInfo: any[];
  portfoliosAndPlantsData: any;
  onChangePortfolio: (val: string | number) => void;
  preSelectedPlant: any;
  preSelectedPortfolio: any;
  multipleTimeZone: any;
  setMultipleTimeZone: any;
  loadingAlarms: boolean;
  setLoadingAlarms: any;
  filteredAlarms: any;
  alarms: any;
  setFilteredAlarms: any;
  openFilterMenu: boolean;
  setOpenFilterMenu: any;
  filterMenuList: any;
  setFilterMenuList: any;
  selectedStatus: any;
  setSelectedStatus: any;
  selectedTypes: any;
  setSelectedTypes: any;
  selectedSortOrder: any;
  setSelectedSortOrder: any;
}

export const useNotificationsList = ({ alarms, setAlarms }): Data => {
  const [selectedStatus, setSelectedStatus] = useState("allStatuses");
  const [selectedTypes, setSelectedTypes] = useState("allTypes");
  const [selectedSortOrder, setSelectedSortOrder] = useState("newestOnTop");
  const [filterMenuList, setFilterMenuList] = useState(filterMenu);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState([]);
  const [preSelectedPlant, setPreSelectedPlant] = useState([]);
  const [preSelectedPortfolio, setPreSelectedPortfolio] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [multipleTimeZone, setMultipleTimeZone] = useState(false);
  const [plant, setPlant] = useState([]);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [loadingAlarms, setLoadingAlarms] = useState(false);
  const [filteredAlarms, setFilteredAlarms] = useState([]);

  const { data: portfoliosAndPlantsData } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });

  const getPortfolio = async (data: any) => {
    let fil = [];
    let newPortfolio: any[] = [];
    data?.filter((item) => {
      if (!fil.includes(item?.portfolioName)) {
        fil.push(item?.portfolioName);
        newPortfolio.push({
          label: item?.portfolioName,
          value: item?.portfolioName,
        });
      }
    });
    setPortfolio(newPortfolio);
    if (!selectedPlant) setSelectedPortfolio(newPortfolio[0].value);
  };

  useEffect(() => {
    const checkMultipleTimezone = () => {
      if (!selectedPortfolio || selectedPlant.length < 2) {
        setMultipleTimeZone(false);
        return;
      }
      const selectedPlantData = selectedPlant.map((plantId) =>
        tenantInfo.find((item) => item.plantId === plantId)
      );
      const selectedTimezones = selectedPlantData.map(
        (plant) => plant?.timeZone
      );
      const hasDuplicateTimezones =
        new Set(selectedTimezones).size !== selectedTimezones.length;
      setMultipleTimeZone(hasDuplicateTimezones);
    };
    checkMultipleTimezone();
  }, [selectedPortfolio, selectedPlant, tenantInfo]);

  const getPlants = async (portfolio: any) => {
    const newPlants = [];
    tenantInfo?.forEach((item) => {
      return portfolio.forEach((el) => {
        if (el.label === item?.portfolioName) {
          newPlants.push({
            label: item?.plantName,
            value: item?.plantId,
            timeZone: item?.timeZone,
            portfolioName: item?.portfolioName,
          });
        }
      });
    });

    setPlant(newPlants);
    if (!selectedPlant) {
      setPreSelectedPlant(newPlants.map((item) => item.value));
      setSelectedPlant(newPlants);
    }
    if (!selectedPlant.length && selectedPlant) {
      setPreSelectedPlant(selectedPlant?.map((item) => item.value));
    }
  };

  const onChangePortfolio = (value) => {
    const newPlants = [];
    setSelectedPortfolio(value);
    tenantInfo?.filter((item) => {
      value?.forEach((el) => {
        if (item?.portfolioName === el.value) {
          newPlants.push({
            label: item?.plantName,
            value: item?.plantId,
          });
        }
      });
    });
    setPreSelectedPlant(newPlants?.map((item) => item.value));
    if (!selectedPortfolio.length) setSelectedPlant(newPlants);
  };

  const getPortfoliosInfo = async () => {
    if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0)
      return;
    setTenantInfo(portfoliosAndPlantsData);
    getPortfolio(portfoliosAndPlantsData);
  };

  useEffect(() => {
    getPortfoliosInfo();
  }, [portfoliosAndPlantsData]);

  useEffect(() => {
    let filteredAlarms = alarms;
    if (selectedPlant.length > 0) {
      filteredAlarms = filteredAlarms?.filter((item) =>
        selectedPlant.some((plant) => plant.value === item.plantId)
      );
    }
    if (selectedStatus === "active") {
      filteredAlarms = filteredAlarms?.filter((item) => item.isActive);
    } else if (selectedStatus === "selfResolved") {
      filteredAlarms = filteredAlarms?.filter((item) => !item.isActive);
    }
    if (selectedTypes === "underPerformance") {
      filteredAlarms = filteredAlarms?.filter(
        (item) => item?.alarmCategoryName === "Underperformance"
      );
    } else if (selectedTypes === "communicationFailure") {
      filteredAlarms = filteredAlarms?.filter(
        (item) => item?.alarmCategoryName === "Communication failure"
      );
    } else if (selectedTypes === "tripping") {
      filteredAlarms = filteredAlarms?.filter(
        (item) => item?.alarmCategoryName === "Tripping"
      );
    } else if (selectedTypes === "inverterError") {
      filteredAlarms = filteredAlarms?.filter(
        (item) => item?.alarmCategoryName === "Inverter"
      );
    }

    if (selectedSortOrder === "newestOnTop") {
      filteredAlarms = filteredAlarms?.slice().sort((a, b) => {
        const dateA = new Date(a.createdOn) as any;
        const dateB = new Date(b.createdOn) as any;
        return dateB - dateA;
      });
    } else if (selectedSortOrder === "oldestOnTop") {
      filteredAlarms = filteredAlarms?.slice().sort((a, b) => {
        const dateA = new Date(a.createdOn) as any;
        const dateB = new Date(b.createdOn) as any;
        return dateA - dateB;
      });
    }
    setFilteredAlarms(filteredAlarms);
  }, [selectedPlant, selectedStatus, selectedTypes, selectedSortOrder, alarms]);

  return {
    selectedSortOrder,
    setSelectedSortOrder,
    selectedTypes,
    setSelectedTypes,
    selectedStatus,
    setSelectedStatus,
    loadingAlarms,
    setLoadingAlarms,
    multipleTimeZone,
    setMultipleTimeZone,
    preSelectedPortfolio,
    preSelectedPlant,
    selectedPlant,
    selectedPortfolio,
    setSelectedPlant,
    setSelectedPortfolio,
    portfolio,
    plant,
    tenantInfo,
    portfoliosAndPlantsData,
    onChangePortfolio,
    alarms,
    filteredAlarms,
    setFilteredAlarms,
    openFilterMenu,
    setOpenFilterMenu,
    filterMenuList,
    setFilterMenuList,
  };
};
