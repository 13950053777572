import { Cell } from "../../../../shared/components/Table";


export enum SubsidiaryField {
  Name = "subsidiaryName",
  AdressLine1 = "adressLine1",
  AdressLine2 = "adressLine2",
  PostalCode = "postalCode",
  City = "city",
  Country = "country",
  OrgNumber = "orgNumber",
  RevenueAccountCode = "revenueAccountCode",
}

export interface ISubsidiaryForm {
  [SubsidiaryField.Name]: string;
  [SubsidiaryField.AdressLine1]: string;
  [SubsidiaryField.AdressLine2]: string;
  [SubsidiaryField.PostalCode]: string;
  [SubsidiaryField.City]: string;
  [SubsidiaryField.Country]: string;
  [SubsidiaryField.OrgNumber]: string;
  [SubsidiaryField.RevenueAccountCode]: string;
}

export interface ISubsidiary extends ISubsidiaryForm {
  id: number;
}

export class Subsidiary implements ISubsidiary {
  id: number;
  [SubsidiaryField.Name]: string;
  [SubsidiaryField.AdressLine1]: string;
  [SubsidiaryField.AdressLine2]: string;
  [SubsidiaryField.PostalCode]: string;
  [SubsidiaryField.City]: string;
  [SubsidiaryField.Country]: string;
  [SubsidiaryField.OrgNumber]: string;
  [SubsidiaryField.RevenueAccountCode]: string;
}

export enum commonFields {
  Entities = "entities",
  PageNumber = "pageNumber",
  TotalCount = "totalcount",
}

export class IPaginatedSubsidiary {
  [commonFields.Entities]: Array<Subsidiary>;
  [commonFields.PageNumber]: number;
  [commonFields.TotalCount]: number;
}

export interface InputProps {
  name: string;
  type: string;
  label?: string;
  subLabel?: string;
  multiline?: boolean;
  maxRows?: number;
  defaultValue?: string;
}

export enum Fields {
  SubsidiaryName = "subsidiaryName",
  AdressLine1 = "adressLine1",
  AdressLine2 = "adressLine2",
  PostalCode = "postalCode",
  City = "city",
  Country = "country",
  OrgNumber = "orgNumber",
  RevenueAccountCode = "revenueAccountCode",
}

const x1: Cell = {
  key: "plant",
  label: "Plant",
  align: "left",
};


const x2: Cell = {
  key: "portfolio",
  label: "Portfolio",
  align: "left",
};

export const plantViewHeaderCell = [x1,x2];
export const plantViewTableType = "modules";