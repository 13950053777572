import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    padding: 10,
  },

  pageLabel: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
  },

  formElementsWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  form: {
    flex: 1,
    flexDirection: "column",
    minWidth: 300,
    maxWidth: 500,
  },

  inputWrapper: {
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },

  subLabel: {
    fontWeight: 400,
  },
  m: {
    margin: "5px",
  },
  background: {
    background: "#D5D4DE",
  },
  text: {
    color: "black !important",
  },
  Active: {
    background: `${theme.palette.primary.main} !important`,
    color: "white !important",
    borderRadius: "5px !important",
  },
  center: {
    textAlign: "center",
  },
  customTooltip: {
    background: "white",
    boxShadow: "0px 0px 5px gray",
    padding: "1rem",
    width: "250px",
    border: "none",
  },
  tooltipText: {
    fontWeight: "bolder",
  },
  InlineFilters: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  Inline: {
    display: "flex",
    alignItems: "center",
  },
  filters: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pageHeader: {
    width: "100%",
    // backgroundColor: "white !important",
    padding: 10,
  },
  Heading: {
    fontSize: "1.17em",
    fontWeight: 550,
  }
}));
