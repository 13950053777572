import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    tooltipHeader: {
        backgroundColor: "#222146",
        color: "white",
        padding: 0,
        paddingLeft: 15,
        fontWeight: 500,
        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
    },

}));
