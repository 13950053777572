
import React from 'react';

import { Datepicker, Typography } from "../../../../../shared/components";

interface selectProps {
  name: string;
  label?: string;
  subLabel?: string;
  formik: any;
}

export const RenderDatePicker: React.FC<selectProps> = (props) => (

  <Typography label={props.label} subLabel={props.subLabel}>
    <Datepicker
      label={""}
      name={props.name}
      value={props.formik.values[props.name] || ""}
      onChange={(newDate: Date): void => {
        props.formik.setFieldValue(props.name, newDate);
      }}
      showError={props.formik?.touched[props.name] && Boolean(props.formik.errors[props.name])}
            errorMessage={props.formik.errors[props.name] as string}
    />
  </Typography>
);