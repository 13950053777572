import { useEffect, useState, useLayoutEffect, useCallback } from "react";
import {
  DateType,
  DropdownOption,
  SetDateType,
} from "../../../../components/interfaces";
import { useSearchParams } from "react-router-dom";
import { checkDate, convertTime } from "../../../../../shared/utils/utils";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import {
  setDateComparison,
  setGlobalDateAction,
  setGlobalLiveButtonAction,
  setGlobalPlantIdAction,
  setGlobalPortfolioNameAction,
  setglobalSelectedInverterI,
} from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardActions";
import { useGetPortfoliosAndPlantsQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useAppDispatch, useAppSelector } from "../../../../../store/rootStore";

interface Data {
  portfolio: DropdownOption[];
  plant: DropdownOption[];
  selectedPlant: any;
  selectedPortfolio: any;
  setSelectedPortfolio: any;
  setSelectedPlant: any;
  date: DateType[];
  setDate: SetDateType;
  globalDate: any[];
  onChangePlant: (val: string | number) => void;
  onChangePortfolio: (val: string | number) => void;
  liveButton: boolean;
  handleChangeLiveButton: (val: boolean) => void;
}

export const useHeaderWidget = (): Data => {
  const dispatch = useAppDispatch();

  const [url, setUrl] = useSearchParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { selectedInverterI, liveButton, globalDate } = useAppSelector(
    monitoringDashboardSelector
  );

  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedPlant, setSelectedPlant] = useState<string | number>(plantId);
  const [portfolio, setPortfolio] = useState([]);
  const [plant, setPlant] = useState([]);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate[0].startDate),
      endDate: checkDate(endDate, globalDate[0].endDate),
      key: "selection",
    },
  ]);

  const { data: portfoliosAndPlantsData } = useGetPortfoliosAndPlantsQuery({ accessType: "BESS" });

  useEffect(() => {
    getPortfoliosInfo();
  }, [portfoliosAndPlantsData]);

  useEffect(() => {
    if (!selectedPortfolio) {
      return;
    }

    getPlants(selectedPortfolio);
    dispatch(setGlobalPortfolioNameAction(selectedPortfolio));
  }, [selectedPortfolio]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));

    const startTime = convertTime(date[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(date[0].endDate, "YYYY.MM.DD");

    if (startTime !== endTime) {
      dispatch(setDateComparison(false));
    } else {
      dispatch(setDateComparison(true));
    }

    setUrl(
      (prev) => {
        prev.set("startDate", date[0].startDate.toString());
        return prev;
      },
      { replace: true }
    );

    setUrl(
      (prev) => {
        prev.set("endDate", date[0].endDate.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  useLayoutEffect(() => {
    if (!tenantInfo.length) return;
    getPortfolioAndPlant();
  }, [tenantInfo, plantId]);

  useEffect(() => {
    if (!selectedPlant) return;

    dispatch(setGlobalPlantIdAction(String(selectedPlant)));

    setUrl(
      (prev) => {
        prev.set("plantId", selectedPlant.toString());
        return prev;
      },
      { replace: true }
    );
  }, [selectedPlant]);

  const getPortfolioAndPlant = () => {
    tenantInfo.forEach((item) => {
      if (parseInt(plantId) === item.plantId) {
        setSelectedPlant(item.plantId);
        setSelectedPortfolio(item.portfolioName);
      }
    });
  };

  const onChangePortfolio = (value) => {
    const newPlants = [];
    setSelectedPortfolio(value);
    tenantInfo?.filter((item) => {
      if (item?.portfolioName === value) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });
    dispatch(setglobalSelectedInverterI(null));
    setSelectedPlant(newPlants[0].value);
  };

  const onChangePlant = (value) => {
    setSelectedPlant(value);
    dispatch(setglobalSelectedInverterI(null));
  };

  const getPortfoliosInfo = async () => {
    if (!portfoliosAndPlantsData || portfoliosAndPlantsData?.length <= 0)
      return;
    setTenantInfo(portfoliosAndPlantsData);
    getPortfolio(portfoliosAndPlantsData);

    if (selectedInverterI) {
      setSelectedPortfolio(selectedInverterI.portfolio);
    }
  };

  const getPortfolio = async (data: any) => {
    let fil = [];
    let newPortfolio: Array<DropdownOption> | any[] = [];
    data?.filter((item) => {
      if (!fil.includes(item?.portfolioName)) {
        fil.push(item?.portfolioName);
        newPortfolio.push({
          label: item?.portfolioName,
          value: item?.portfolioName,
        });
      }
    });

    console.log(newPortfolio);
    setPortfolio(newPortfolio);

    if (!plantId) {
      setSelectedPortfolio(newPortfolio[0].value);
    }
  };

  const getPlants = async (portfolio: any) => {
    const newPlants = [];
    tenantInfo?.filter((item) => {
      if (item?.portfolioName === portfolio && item.plantType != null && (item.plantType == "BESS" || item.plantType == "PV-BESS")) {
        newPlants.push({
          label: item?.plantName,
          value: item?.plantId,
        });
      }
    });

    setPlant(newPlants);

    if (!plantId) setSelectedPlant(newPlants[0].value);
    if (!selectedInverterI) dispatch(setglobalSelectedInverterI(null));
  };

  const handleChangeLiveButton = useCallback((isChecked: boolean) => {
    dispatch(setGlobalLiveButtonAction(isChecked));

    if (isChecked) {
      const newDate = new Date().toISOString();

      setDate([
        {
          endDate: newDate,
          key: "selection",
          startDate: newDate,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date[0].startDate : startDate,
      "YYYY.MM.DD"
    );

    const endTime = convertTime(
      !endDate ? date[0].endDate : endDate,
      "YYYY.MM.DD"
    );

    const dateToday = convertTime(new Date(), "YYYY.MM.DD");

    if (
      startTime !== endTime ||
      (startTime !== dateToday && endTime !== dateToday)
    ) {
      dispatch(setGlobalLiveButtonAction(false));
    }
  }, [startDate, endDate]);

  return {
    portfolio,
    plant,
    selectedPortfolio,
    setSelectedPortfolio,
    selectedPlant,
    setSelectedPlant,
    date,
    setDate,
    globalDate,
    onChangePlant,
    onChangePortfolio,
    liveButton,
    handleChangeLiveButton,
  };
};
