import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: theme.spacing(0.5, 3, 3, 3),
    borderRadius: theme.spacing(1, 1, 1, 1),
    marginTop: theme.spacing(2),
    marginBottom: "200px",
  },
  title: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "22px",
    marginTop: "24px",
  },
  heading: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    marginTop: theme.spacing(2.5),
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
  },
  searchFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
  innerSearch: {
    marginTop: "20px",
    "@media (max-width: 600px)": {
      width: "100%",
      paddingRight: "0px",
    },
  },
}));
