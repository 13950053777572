import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    padding: 30,
  },

  pageLabel: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
  },

  formElementsWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },

  inputWrapper: {
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },

  subLabel: {
    fontWeight: 400,
  },
  m: {
    margin: "5px",
  },
  card: {
    padding: "20px",
    marginBottom: "3px",
  },
  detailText: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  detailItem: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    borderBottom: "1px solid #d1d3d4b5",
    height: 7,
    width: "100%",
  },
  fieldLabel: {
    fontSize: 14,
    color: "#707070",
    fontWeight: 600,
  },
  fieldValue: {
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
}));
