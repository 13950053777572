import React from "react";
import { UseUserRole } from './useUserRole';
import { Card } from "@material-ui/core";
import { MenuWithBtn, IMenuItem } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import { useStyles } from "./styles";
import { userRoleViewHeaderCell, userRoleViewTableType } from './components/interfaces'
import { useNavigate } from "react-router-dom";

export const UserRole: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onNewUser = () => {
    navigate(`/onboarding/userrole/new`);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>User role</div>

      <Card style={{ padding: "20px" }}>
        <MenuWithBtn
          menuItems={[]}
          active={undefined}
          title={"User role"}
          btnTitle={"New user role"}
          onBtnClick={() => onNewUser()}
          onSelectItem={(item: IMenuItem): void => {
          }}
        />
        <br />
        <CustomTable
          tableType={userRoleViewTableType}
          headerCells={userRoleViewHeaderCell}
          data={null}
          onDeleteClick={(id) => { }}
        />
      </Card>

    </div>
  );
};

