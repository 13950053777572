import { daysOfWeek, months } from "../../../../../shared/utils/utils";
import {
  BatteryHeatmapFormat,
  BatteryHeatmapRange,
  BatteryHeatmapStringsAverageCurrentResponse,
} from "../../BatteryMonitoring.api.types";
import { DROPDOWN_OPTION_VALUE } from "../../utils";

export enum VALUE_TYPE {
  TEMPERATURE = "Temperature",
  VOLTAGE = "Voltage",
  CURRENT = "Current",
}

export enum VALUE_FORMAT {
  MAX = "Max",
  AVG = "Avg",
  MIN = "Min",
}

export const getElementsCountAndFormat = (
  granularity: number,
  daysDiff: number
) => {
  const parsedDaysDiff = daysDiff + 1;

  let count = 0;
  let format: BatteryHeatmapFormat = "hour";

  if (daysDiff === 0) {
    count = 24;
    format = "hour";
  } else if (daysDiff === 1) {
    if (granularity === DROPDOWN_OPTION_VALUE.ONE_HOUR) {
      count = 48;
      format = "hour";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_DAY) {
      count = 2;
      format = "day";
    }
  } else if (daysDiff > 1 && daysDiff <= 29) {
    if (granularity === DROPDOWN_OPTION_VALUE.ONE_DAY) {
      count = parsedDaysDiff;
      format = "day";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_WEEK) {
      count = Math.ceil(parsedDaysDiff / 7);
      format = "week";
    }
  } else if (daysDiff > 29 && daysDiff <= 59) {
    if (granularity === DROPDOWN_OPTION_VALUE.ONE_DAY) {
      count = parsedDaysDiff;
      format = "day";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_WEEK) {
      count = Math.ceil(parsedDaysDiff / 7);
      format = "week";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_MONTH) {
      count = Math.ceil(parsedDaysDiff / 30);
      format = "month";
    }
  } else if (daysDiff > 59) {
    if (granularity === DROPDOWN_OPTION_VALUE.ONE_WEEK) {
      count = Math.ceil(parsedDaysDiff / 7);
      format = "week";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_MONTH) {
      count = Math.ceil(parsedDaysDiff / 30);
      format = "month";
    } else if (granularity === DROPDOWN_OPTION_VALUE.ONE_YEAR) {
      count = Math.ceil(parsedDaysDiff / 365);
      format = "year";
    }
  } else {
    count = 24;
    format = "hour";
  }

  return { count, format };
};

const formatSeries = (arr: number[][], color: number, label: string) => {
  return {
    name: label,
    dataLabels: {
      enabled: false,
    },
    colorAxis: color,
    rowsize: 0.85,
    colsize: 0.95,
    data: arr,
  };
};

export const DEFAULT_HEATMAP_TEMPERATURE_RANGE: BatteryHeatmapRange = {
  criticallyLow: 0,
  low: 15,
  optimal: 30,
  high: 45,
};

export const DEFAULT_HEATMAP_VOLTAGE_RANGE: BatteryHeatmapRange = {
  criticallyLow: 1,
  low: 2,
  optimal: 6,
  high: 9,
};

export const DEFAULT_HEATMAP_CURRENT_RANGE: BatteryHeatmapRange = {
  criticallyLow: 1,
  low: 2,
  optimal: 6,
  high: 9,
};

export const DEFAULT_HEATMAP_UNITS = {
  [VALUE_TYPE.TEMPERATURE]: "°C",
  [VALUE_TYPE.VOLTAGE]: "V",
  [VALUE_TYPE.CURRENT]: "A",
};

export enum BATTERY_HEATMAP_RANGE {
  CriticalLow = "CriticalLow",
  Low = "Low",
  Optimal = "Optimal",
  High = "High",
  CriticalHigh = "CriticalHigh",
}

export const getSeries = (
  data: BatteryHeatmapStringsAverageCurrentResponse,
  unit: string,
  range: BatteryHeatmapRange
) => {
  if (range) {
    const criticalLowValues = [];
    const lowValues = [];
    const optimalValues = [];
    const highValues = [];
    const criticalHighValues = [];

    const { criticallyLow, low, optimal, high } = range;

    const matrix = data?.items ? transformData(data?.items) : null;

    matrix.forEach((item) => {
      const [_x, _y, value] = item;

      if (value < criticallyLow) {
        criticalLowValues.push(item);
      } else if (value <= low) {
        lowValues.push(item);
      } else if (value <= optimal + 1) {
        optimalValues.push(item);
      } else if (value <= high) {
        highValues.push(item);
      } else {
        criticalHighValues.push(item);
      }
    });

    return [
      formatSeries(
        criticalLowValues,
        1,
        `Critically low (<${criticallyLow}${unit})`
      ),
      formatSeries(
        lowValues,
        2,
        `Low (${criticallyLow}${unit} - ${low}${unit})`
      ),
      formatSeries(
        optimalValues,
        3,
        `Optimal (${low}${unit} - ${optimal}${unit})`
      ),
      formatSeries(
        highValues,
        4,
        `High (${optimal + 1}${unit} - ${high}${unit})`
      ),
      formatSeries(criticalHighValues, 5, `Critically high (>${high}${unit})`),
    ];
  } else {
    const criticalLowValues = [];
    const lowValues = [];
    const optimalValues = [];
    const highValues = [];
    const criticalHighValues = [];

    const matrix = data?.items ? transformData(data?.items, true) : null;

    matrix.forEach((item) => {
      const [_x, _y, value, range] = item;
      const _value = [_x,_y, value]
      if (range === BATTERY_HEATMAP_RANGE.CriticalLow) {
        criticalLowValues.push(_value);
      } else if (range === BATTERY_HEATMAP_RANGE.Low) {
        lowValues.push(_value);
      } else if (range === BATTERY_HEATMAP_RANGE.Optimal) {
        optimalValues.push(_value);
      } else if (range === BATTERY_HEATMAP_RANGE.High) {
        highValues.push(_value);
      } else if (range === BATTERY_HEATMAP_RANGE.CriticalHigh) {
        criticalHighValues.push(_value);
      }
    });

    return [
      formatSeries(criticalLowValues, 1, `Critically low`),
      formatSeries(lowValues, 2, `Low`),
      formatSeries(optimalValues, 3, `Optimal`),
      formatSeries(highValues, 4, `High`),
      formatSeries(criticalHighValues, 5, `Critically high`),
    ];
  }
};

export const getXCategories = (count: number, format: string) => {
  let elements = new Array(count).fill("");

  if (format === "hour") {
    elements = elements.map((_, i) =>
      i >= 24
        ? `${i % 24 < 10 ? `0${i % 24}` : i % 24}:00`
        : `${i < 10 ? `0${i}` : i}:00`
    );

    elements = elements.map((_, i) => (i >= 10 ? `${i}:00` : `0${i}:00`));
  } else if (format === "day") {
    const repeatableDaysOfWeek = new Array(count)
      .fill("")
      .map((_, i) => daysOfWeek[i % 7]);

    elements = repeatableDaysOfWeek;
  } else if (format === "week") {
    const weeks = new Array(count).fill("").map((_, i) => `Week ${i + 1}`);

    elements = weeks;
  } else if (format === "month") {
    const repeatableMonths = new Array(count)
      .fill("")
      .map((_, i) => months[i % 12]);

    elements = repeatableMonths;
  } else if (format === "year") {
    const currentYear = new Date().getFullYear();
    const years = new Array(count)
      .fill("")
      .map((_, i) => currentYear - i)
      .reverse()
      .map((year) => String(year));

    elements = years;
  }

  return elements;
};

export const getYCategories = (count: number) => {
  return new Array(count).fill("").map((_, i) => `${i + 1}`);
};

export const tooltipFormatter = (
  valueType: VALUE_TYPE,
  format: BatteryHeatmapFormat,
  prefix?: string
) => {
  // TODO: kaz pass unit as argument, not hardcoded
  const unit = DEFAULT_HEATMAP_UNITS[valueType];

  return function () {
    const color = this.point.color || this.series.color;

    let title = `${this.series.xAxis.categories[this.point.x]} - ${
      this.series.xAxis.categories[this.point.x + 1] ||
      this.series.xAxis.categories[0]
    }`;

    if (format !== "hour") {
      title = `${this.series.xAxis.categories[this.point.x]}`;
    }

    return `<div style="background-color: white; padding: 5px;">
        ${title}<br><span style="color:${color}">&#9632;</span>&nbsp;${
      prefix ? prefix + " " : ""
    } ${
      this.series.yAxis.categories[this.point.y]
    } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${
      this.point.value
    }${unit}
      </div>`;
  };
};

export function getAllStringNames(data) {
  let stringNames = new Set();

  data.items.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key.startsWith("string_")) {
        stringNames.add(key);
      }
    });
  });

  return Array.from(stringNames).sort();
}

export function transformData(items, isRangeDynamic?: boolean) {
  let result = [];

  items.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      if (
        key.startsWith("string_") &&
        item[key] !== null &&
        item[key] !== undefined &&
        item[key].value !== null
      ) {
        let x = parseInt(key.split("_")[1], 10);
        const res = [index, x, item[key].value];
        if (isRangeDynamic) {
          res.push(item[key].status);
        }
        result.push(res);
      }
    });
  });

  return result;
}
export function getUnit(data) {
  for (let item of data.items) {
    for (let key in item) {
      if (
        key.startsWith("string_") &&
        item[key] !== null &&
        item[key].unit !== undefined
      ) {
        return item[key].unit;
      }
    }
  }
  return "";
}
