import { useMemo, useState } from "react";
import { DropdownOption } from "../../../../../shared/components";
import { fetchAsync } from "../../../../../shared/utils/apiService";

const formatTypes: Array<DropdownOption> = [
  { label: "Average", value: "Average" },
  { label: "Median", value: "Median" },
  { label: "Maximum", value: "Maximum" },
];
interface BEData {
  label: number;
  invLabel: string;
  avgMdLabel: string;
  invValue: Array<number>;
  avgMdValue: Array<number>;
}

interface Props {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
}

interface Data {
  formatTypes: Array<DropdownOption>;
  setFormat: (val: any) => void;
  loading: boolean;
  error: string;
  format: string;
  data: Array<any>;
}

export const useLossWaterfall = (props: Props): Data => {
  const [data, setData] = useState({} as any);
  const [format, setFormat] = useState(formatTypes[0].value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { date, plantId, inverterId, blockId } = props;

  useMemo(() => {
    if (!date || !plantId || !inverterId) {
      return;
    }

    setLoading(true);
    setError("");
    fetchAsync(
      `SkyfriSidemApi/v1/GetWaterfallEnergyLossInvSp?Date=${date}&plantId=${plantId}&inverterId=${inverterId}&blockId=${blockId}&type=${format}`,
      "GET"
    )
      .then((data: any) => {
        const newData = data?.map((item) => {
          const val = item?.invValue?.split(",");
          return {
            name: item.name,
            value: [parseInt(val[0] && val[0]), parseInt(val[1] && val[1])],
            label: item.invLabel,
          };
        });

        setLoading(false);

        if (!newData?.length) {
          setError("No data");
        } else {
          setData(newData);
        }
      })
      .catch((e) => setError(e));
  }, [format, date]);

  return {
    formatTypes,
    setFormat,
    loading,
    error,
    format,
    data,
  };
};
