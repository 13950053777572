import { makeStyles } from "@material-ui/core/styles";
import { GLOBAL_COLORS, hexToRGBA } from "../../../../../shared/utils/utils";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  label: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18px",
    margin: 0,
  },
  statusWrapper: {
    height: "24px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    borderRadius: "32px",
    "&.off": {
      backgroundColor: hexToRGBA(GLOBAL_COLORS.LIGHT_ALARMS_CRITICAL, 0.1),
    },
    "&.on": {
      backgroundColor: hexToRGBA(GLOBAL_COLORS.LIGHT_GREEN_BACKGROUND, 0.1),
    },
  },
  dot: {
    width: "6px",
    height: "6px",
    minWidth: "6px",
    minHeight: "6px",
    borderRadius: "50%",
    "&.off": {
      backgroundColor: GLOBAL_COLORS.LIGHT_ALARMS_CRITICAL,
    },
    "&.on": {
      backgroundColor: GLOBAL_COLORS.LIGHT_ALARMS_SUCCESS,
    },
  },
  status: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15.6px",
  },
}));
