import { useState } from "react";

import { ISubsidiaryForm } from "./interfaces";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  moduleId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  Subsidiary: ISubsidiaryForm;
  Contacts: any;
  Company: any;
}

export const UseModuleSave = ({ moduleId }: Props): Data => {

  const [Subsidiary, setSubsidiaries] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType = "POST";

    if (id && id != "new") {
      params.tenantId = id;
      requestType = "PUT";
    }

    await fetchAsync(`/Module`, requestType, params)
      .then((data: any) => {
        if (data?.ok == false) {
          callback(false);
        } else {
          callback(true);
        }

      })
      .catch((e) => { console.error(e); callback(false); });
  };




  return {
    Subsidiary,
    Contacts,
    Company,
    upsert
  };
};
