import React from "react";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import { ReactComponent as COLLAPSE } from "../../../shared/icons/app/COLLAPSE.svg";
import { ReactComponent as EXPAND } from "../../../shared/icons/app/EXPAND.svg";
import { useStyles } from "./styles";

interface Props {
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  active: boolean;
  setActive: (value: boolean) => void;
}

export const Menu = (props: Props) => {
  const { drawerOpen, setDrawerOpen, active, setActive } = props;
  const classes = useStyles();

  return (
    <div
      id="menu"
      style={{ width: drawerOpen && "100%" }}
      onClick={() => setDrawerOpen(!drawerOpen)}
      className={clsx(
        { [classes.userMenuOpen]: drawerOpen },
        classes.menuBorder,
        classes.onHover
      )}
    >
      <div>
        <IconButton
          style={{ padding: "0px" }}
          aria-label="open drawer"
        >
          {drawerOpen ? <COLLAPSE /> : <EXPAND />}
        </IconButton>
      </div>

      {drawerOpen && (
        <div className={classes.collapseMenuTitle}>Collapse menu</div>
      )}
    </div>
  );
};
