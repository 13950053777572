import { getBearerToken } from "../../../store/authToken";
import { axGet } from "../../../store/axiosClient";
import * as reportsSlice from "./clientSlice";

interface ClientInfo {
  clientLogo: string;
  clientName: string;
  portfolioImages: string;
  pr: number;
  budget_PR: number;
  revenue: number;
  portfolios: number;
}

const API_URL = `/SkyfriSidemApi/v1`
export function getClientInfo() {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(reportsSlice.clientStart());
    try {
      const token = await getBearerToken();
      const data: ClientInfo = await axGet(`${API_URL}/ClientInfo`, token);
      dispatch(reportsSlice.clientSuccess(data));
      return data;
    } catch (error) {
      dispatch(reportsSlice.clientError(error));
      return error.response.data;
    }
  };
}
