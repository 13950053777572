import { makeStyles, Theme } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(3),
        paddingTop: 0,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
    },

    modalHeader: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, -2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerFixed: {
        position: "sticky",
        top: "0",
        backgroundColor: "white",
        zIndex: 9999
    },
    modalTitle: {
        fontSize: 24,
        fontWeight: 500,
        paddingTop: 24,
    },

    content: {
        flex: 1,
        overflow: 'auto'
    },
    mount: {
        backgroundColor: '#E8ECF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#0C1E33',
        padding: theme.spacing(1.4, 0.6),
        borderRadius: 500,
        height: 20,
        fontSize: 13,
        fontWeight: 500,
    },
    hrLine: {
        opacity: 0.1,
        color: "#0C1E33",
        border: "none",
        height: "2px",
        backgroundColor: "#0C1E33",
        marginTop: "15px",
    },
    headerFields: {
        backgroundColor: "#E8ECF2",
        margin: theme.spacing(1, 0, 0, 0),
    },
    headerFieldsText: {
        fontWeight: 400,
        fontSize: 15,
        padding: theme.spacing(1.1, 0, 1.1, 1)

    },
    headerFieldsBorderCenter: {
        height: "auto",
        borderLeft: "1px solid #E1E4E9",
        padding: 0
    },
    fieldBottom: {
        borderBottom: "1px solid #E1E4E9",
    },
    fieldsText: {
        fontWeight: 500,
        fontSize: 17,
        lineHeight: "18px",
    },
    notification: {
        backgroundColor: '#FF5500',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        padding: theme.spacing(0, 0.6),
        borderRadius: 500,
        fontSize: 14
    },
    zeroNotification: {
        backgroundColor: '#9EA5AE',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        padding: theme.spacing(0, 0.6),
        borderRadius: 500,
        fontSize: 14
    },
    fieldsBorderCenter: {
        height: "125px",
        borderLeft: "1px solid #E1E4E9",
        padding: 0
    },
    location: {
        fontWeight: 400,
        fontSize: 13,
        paddingTop: "10px",
    },
    gridPaddingFirst: {
        padding: theme.spacing(1, 0, 0, 1)
    },
    gridPadding: {
        padding: theme.spacing(1, 0, 0, 1)
    },
    growth: {
        marginLeft: theme.spacing(0.5),
        color: "#79BEAA",
        fontWeight: 500,
        fontSize: 20,
        display: "flex",
        alignItems: "center",
    },

    growthIcon: {
        fontSize: "1em !important",
    },

    negativeGrowth: {
        color: "#DE504F",
    },
    mutedText: {
        fontWeight: 400,
        fontSize: 13,
        color: "#0C1E33",
        opacity: 0.5,
    },


    // --------------

    tabelheadbg: {
        backgroundColor: "#E8ECF2"
    },
    tabelheadcell: {
        borderRight: "1px solid #E1E4E9",
    },
    tablefieldcell: {
        borderRight: "1px solid #E1E4E9",
    },
    tabelfieldcellnoborder: {
        borderRight: 0,
    },





}));