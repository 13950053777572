import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import {
  convertTime,
  getSelectedString,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import {
  useGetGenerationGraphDataQuery,
  useGetGenerationGraphPeriodDataQuery,
  useGetMppGenPageKpiQuery,
} from "../../../../../features/Sidem/shared/sidemSharedApi";

export const useGenerationOverview = () => {
  const [url] = useSearchParams();
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");
  const plantId = url.get("plantId");
  const portfolioId = url.get("portfolioId");

  const { liveButton, dateEqual } = useAppSelector(monitoringSharedSelector);

  const portfoliosName = portfolioId
    ? getSelectedString(JSON.parse(portfolioId), "label")
    : undefined;

  const plantsId = plantId
    ? getSelectedString(JSON.parse(plantId), "value")
    : undefined;

  const {
    data: kpiData,
    error: kpiDataError,
    isFetching: kpiLoading,
  } = useGetMppGenPageKpiQuery(
    { fromDate: startDate, toDate: endDate, portfoliosName, plantsId },
    {
      skip: isAnyItemFalsy([startDate, endDate, portfoliosName, plantsId]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const kpiError = Boolean(kpiDataError || kpiData?.error);

  const fromDate = startDate ? convertTime(startDate, "YYYY.MM.DD") : undefined;
  const toDate = startDate ? convertTime(endDate, "YYYY.MM.DD") : undefined;

  const { data: generationGraphData, isFetching: isGenerationGraphFetching } =
    useGetGenerationGraphDataQuery(
      {
        fromDate,
        portfoliosName,
        plantsId,
        dateEqual,
      },
      {
        pollingInterval: liveButton ? 60 * 1000 : undefined,
        skip:
          !dateEqual &&
          isAnyItemFalsy([fromDate, portfoliosName, plantsId]),
      }
    );

  const {
    data: generationGraphPeriodData,
    isFetching: isGenerationGraphPeriodFetching,
  } = useGetGenerationGraphPeriodDataQuery(
    {
      fromDate,
      toDate,
      portfoliosName,
      plantsId,
      dateEqual,
    },
    {
      pollingInterval: liveButton ? 60 * 1000 : undefined,
      skip:
        dateEqual &&
        isAnyItemFalsy([fromDate, toDate, portfoliosName, plantsId]),
    }
  );

  const graphData = useMemo(() => {
    return (
      (dateEqual ? generationGraphData : generationGraphPeriodData) || null
    );
  }, [dateEqual, generationGraphData, generationGraphPeriodData]);

  const graphLoading = Boolean(
    isGenerationGraphFetching || isGenerationGraphPeriodFetching
  );

  const result = {
    kpiData: kpiData?.data || null,
    kpiLoading,
    kpiError,
    missingData: Boolean(kpiData?.missingData),
    graphData: graphData?.data || null,
    graphLoading,
    xAxis: graphData?.xAxis || null,
    yAxis: graphData?.yAxis || null,
    lastUpdated: kpiData?.lastUpdated || 0,
  };

  return result;
};

export type GenerationOverviewData = ReturnType<typeof useGenerationOverview>;
