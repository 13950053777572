import React from "react";
import clsx from "clsx";
import { ArrowUpwardSharp, ArrowDownwardSharp } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip";
import { useSharedFormState } from "./../../../shared/utils/sharedStates";
import { useStyles } from "./styles";

export interface Field {
  title: string;
  value: string | number;
  growth?: number;
  prevValue?: string | number;
  currentValue?: string | number;
  unit?: string;
  prevValueUnit?: string;
}

interface FieldsProps {
  fields: Array<Field>;
  items?: any;
  weathers?: any;
  oneColumn?: boolean;
  definition?: any;
  cols?: boolean;
  fieldCount?: number;
  overview?: boolean;
}

export const NewFields: React.FC<FieldsProps> = (props: FieldsProps) => {
  const classes = useStyles();
  const { fields, oneColumn, fieldCount } = props;
  const { viewDefinitions } = useSharedFormState();

  return (
    <>
      <div
        className={clsx(classes.fields, { [classes.oneColumn]: oneColumn })}
        style={{
          gridTemplateColumns: props.cols ? "1fr 1fr 1fr 1fr" : "1fr 1fr",
        }}
      >
        {fields?.map((item: any, idx) => {
          const borderBottom = idx >= 0 && idx <= (fieldCount ? fieldCount : 7);
          const borderCenter = idx >= 0 && idx % 2 === 1;
          return (
            <div
              key={item.title}
              className={clsx(
                classes.field,
                borderBottom && classes.fieldBottom,
                !props?.overview ? borderCenter && classes.fieldRight : null,
              )}
            >
              <div className={classes.fieldValue}>
                <div
                  style={{
                    color:
                      item.title !== "Generation delta"
                        ? "black"
                        : item.negative
                          ? "#BC4F73"
                          : "#2D826B",
                  }}
                  className={classes.value}
                >
                  <div className={classes.info}>
                    {item?.currentValue}
                    {item?.value}
                  </div>
                  <div className={clsx(item?.unit === "%" ? classes.info : classes.unit)}>{item?.unit}</div>
                </div>
                {item.prevValue && (
                  <div className={classes.prevvalue}>
                    <div className={classes.prevValue}>{item.prevValue}</div>
                    <div className={classes.unit}>{item?.prevValueUnit}</div>
                  </div>
                )}
                {(item.growth || item.growth === 0) && (
                  <div
                    className={clsx(classes.growth, {
                      [classes.negativeGrowth]: item.growth < 0,
                    })}
                  >
                    {item.title !== "Generation delta" &&
                      item.growth !== 0 ? `${item.growth?.toFixed(1)}%` : null}
                    {
                      item.growth !== 0 ?
                        item.growth < 0 ? (
                          <ArrowDownwardSharp className={classes.growthIcon} />
                        ) : (
                          <ArrowUpwardSharp className={classes.growthIcon} />
                        )
                        :
                        null}
                  </div>
                )}
              </div>
              <Tooltip
                title={!viewDefinitions ? "" : props.definition?.[item.title]}
              >
                <div
                  className={clsx(classes.fieldLabel, {
                    [classes.oneColumnFieldLabel]: oneColumn,
                  })}
                >
                  {item.title}
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </>
  );
};
