import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/rootReducers";

const initialState = {
  errorMessage: "An error Occured, Please try again!",
  onError: false,
};
export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      return {
        ...state,
        errorMessage: payload,
        onError: true,
      };
    },
    resetError: (state) => {
      return {
        ...state,
        errorMessage: "",
        onError: false,
      };
    },
  },
});
export const { setError, resetError } = errorSlice.actions;
export const errorSelector = (state: RootState) => state.error;
export const errorReducer = errorSlice.reducer;
