import axios from "axios";
import { axGet } from "../../../../store/axiosClient";
import * as PlantsSlice from "./plantsSlice";
import { getBearerToken } from "../../../../store/authToken";

const API_URL = "/SkyfriSidemApi/v1";

export function getPlantProfile(plantId: string) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.plantsStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL + `/GetProfileInfo?plantId=${plantId}`,
        token
      );
      dispatch(PlantsSlice.plantsSuccess(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PlantsSlice.plantsError(error));
    }
  };
}

export function getPlantProfileInfo(plantId: string) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.plantProfileInfoStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL + `/GetProfileInfo?plantId=${plantId}`,
        token
      );
      dispatch(PlantsSlice.plantProfileInfoSuccess());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(PlantsSlice.plantProfileInfoError());
    }
  };
}

export function getTimeFramePerformanceRatio(
  timeFrame: string,
  date: any,
  plantId: any
) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.performanceRatioStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL +
        `/GetTimeFramePerformanceRatio?timeFrame=${timeFrame}&plantId=${plantId}&date=${date}`,
        token
      );
      dispatch(PlantsSlice.performanceRatioSuccess());
      return data;
    } catch (error) {
      dispatch(PlantsSlice.performanceRatioError());
    }
  };
}
export function getRealTimeActualPerformanceRatio(plantId: any) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.performanceRatioStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL + `/GetRealTimeActualPerformanceRatio?plantId=${plantId}`,
        token
      );
      dispatch(PlantsSlice.performanceRatioSuccess());
      return data;
    } catch (error) {
      dispatch(PlantsSlice.performanceRatioError());
    }
  };
}
export function getLast24hrACPowerandIrrTrend(plantId: any, date: any) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.ACPowerandIrrTrendStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL +
        `/GetLast24hrACPowerandIrrTrend?plantId=${plantId}&Date=${date}`,
        token
      );
      dispatch(PlantsSlice.ACPowerandIrrTrendSuccess());
      return data;
    } catch (error) {
      dispatch(PlantsSlice.ACPowerandIrrTrendError());
    }
  };
}

export function GetInverterHeatmap(plantId: any, date: any) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.InverterHeatmapStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        API_URL +
        `/GetInverterHeatmap?date=${date}&plantId=${plantId}`,
        token
      );
      dispatch(PlantsSlice.InverterHeatmapSuccess());
      return data;
    } catch (error) {
      dispatch(PlantsSlice.InverterHeatmapError());
    }
  };
}

export function getSoilingLossInvReport(
  fromDate: any,
  toDate: any,
  plantId: string,
  format: string
) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.SoilingLossInvReportStart());
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const data: any = await axios({
        method: "get",
        url:
          BASE_API_URL +
          API_URL +
          `/GetSoilingLossInvReport?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&type=${format}`,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
      });
      dispatch(PlantsSlice.SoilingLossInvReportSuccess());
      return data.data;
    } catch (error) {
      dispatch(PlantsSlice.SoilingLossInvReportError());
    }
  };
}

export function GetWaterfallPRLossReport(
  fromDate: any,
  toDate: any,
  plantId: string,
  format: string
) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.WaterfallPRLossReportStart());
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const data: any = await axios({
        method: "get",
        url:
          BASE_API_URL +
          API_URL +
          `/GetWaterfallPRLossReport?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&tracker=all`,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
      });
      dispatch(PlantsSlice.WaterfallPRLossReportSuccess());
      return data.data;
    } catch (error) {
      dispatch(PlantsSlice.WaterfallPRLossReportError());
    }
  };
}

export function GetWaterfallEnergyLossReport(
  fromDate: any,
  toDate: any,
  plantId: string,
  format: string
) {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(PlantsSlice.WaterfallEnergyLossReportStart());
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const data: any = await axios({
        method: "get",
        url:
          BASE_API_URL +
          API_URL +
          `/GetWaterfallEnergyLossReport?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}&tracker=all`,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
      });
      dispatch(PlantsSlice.WaterfallEnergyLossReportSuccess());
      return data.data;
    } catch (error) {
      dispatch(PlantsSlice.WaterfallEnergyLossReportError());
    }
  };
}