import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { convertTime } from "../../../shared/utils/utils";
import TopIssuesWidget from "./features/TopIssuesWidget";
import SpecificCurrentWidget from "./features/SpecificCurrentWidget";
import InverterInternalTempWidget from "./features/InverterInternalTempWidget";
import InverterEfficiencyWidget from "./features/InverterEfficiencyWidget";
import InverterPRIrradiationWidget from "./features/InverterPRIrradiationWidget";
import LossWaterfallWidget from "./features/LossWaterfallWidget";
import HeatmapWidget from "./features/HeatmapWidget";
import { useStyles } from "./styles";
import { definition } from "./data";
import { InverterHeader } from "./InverterHeader.component";
import InverterCustomWidget from "./features/InverterCustomWidget";

export const InverterPage: React.FC<any> = ({ imageService }) => {
  const classes = useStyles();
  const { inverterId, plantId, blockId } = useParams<{
    inverterId: string;
    plantId: string;
    blockId: string;
  }>();
  const location: any = useLocation();

  const [date, setDate] = React.useState(location?.state?.date ||
    convertTime(new Date().toISOString(), "yyyy-MM-DD"));

  const props = useMemo(
    () => ({
      imageService,
      inverterId,
      plantId,
      blockId,
      date: date,
      definition,
    }),
    [imageService, inverterId, plantId, blockId, date]
  );

  return (
    <div>
      <InverterHeader {...props}
        date={date}
        setDate={setDate} />
      <Grid container spacing={2} className={classes.pageWrapper}>
        <TopIssuesWidget {...props} />

        <LossWaterfallWidget {...props} />

        <InverterPRIrradiationWidget {...props} />

        <InverterEfficiencyWidget {...props} />

        <InverterInternalTempWidget {...props} />

        <SpecificCurrentWidget {...props} />

        <HeatmapWidget {...props} />

        <InverterCustomWidget {...props} />
      </Grid>
    </div>
  );
};
