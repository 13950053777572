import { useEffect, useState } from "react";
import {
    useAddImageMutation,
    useAddLobbyScreenPagePreferencesMutation,
    useAddLogoMutation,
    useAddTenantColorsMutation,
    useDeleteImageMutation,
    useDeleteLogoMutation,
    useGetAllTenantsQuery,
    useGetLobbyScreenPagePreferencesQuery,
    useGetSiteImagesQuery,
    useGetTenantColorsQuery,
    useGetTenantUsersQuery,
    useGetUsersColorsQuery
} from "../../../features/Onboarding/onBoardingApi";
import { getLobbyLogo } from "../../../features/Onboarding/onBoardingActions";
import { convertByteArraryToBlob, formatBytes } from "../../../shared/utils/utils";

interface Data {
    users: any[];
    selectedUser: string;
    tenants: any[];
    selectedTenant: string;
    userSelectDisabled: boolean;
    lobbyScreenPagePreferencesData: any;
    overViewScreenVersion: string;
    isOpen: boolean;
    severity: string;
    message: string;
    openCancelModal: boolean;
    setOverViewScreenVersion: (value: string) => void;
    setEnergyScreenCheckBoxes: (value: boolean[]) => void;
    setPowerIrrScreenCheckBoxes: (value: boolean[]) => void;
    setEnergyScreenDropdown: (value: string[]) => void;
    setPowerIrrScreenDropdown: (value: string[]) => void;
    setSiteOverviewScreenCheckBoxes: (value: boolean[]) => void;
    setSiteOverviewScreenDropdown: (value: string[]) => void;
    setSiteMonthlyDropdown: (value: string[]) => void;
    setSiteMonthlyCheckBoxes: (value: boolean[]) => void;
    setSiteYearlyDropdown: (value: string[]) => void;
    setSiteYearlyCheckBoxes: (value: boolean[]) => void;
    postData: () => void;
    setIsOpen: (value: boolean) => void;
    setOpenCancelModal: (value: boolean) => void;
    onChangeTenant: (value: string | number) => void;
    onChangeUser: (value: string | number) => void;
    primaryLogo: string;
    secondaryLogo: string;
    primaryLogoSize: string;
    secondaryLogoSize: string;
    uploadPrimaryLogo: (file: File) => void;
    uploadSecondaryLogo: (file: File) => void;
    removePrimaryLogo: () => void;
    removeSecondaryLogo: () => void;
    deletePrimaryLogo: () => void;
    deleteSecondaryLogo: () => void;
    isLoading: boolean;
    isFetching: boolean;
    primaryLogoLoading: boolean;
    secondaryLogoLoading: boolean;
    primaryLogoName: string;
    secondaryLogoName: string;
    siteImages: any[];
    uploadSiteImage: (file: File, plantId: string | number) => void;
    siteImagesFetching: boolean;
    siteImagesLoading: boolean;
    deleteSiteImage: (plantId: string | number) => void;
    colors: {
        backgroundColor: string;
        energyColor: string;
        energyColorGradient: string;
        gridExportColor: string;
        gridExportColorGradient: string;
        gridImportColor: string;
        gridImportColorGradient: string;
        irradianceColor: string;
        irradianceColorGradient: string;
    };
    setColors: (value: any) => void;
    defaultColors: () => void;
    tenantName: string;
    tenantDefaultColors: () => void;
}

export const useLobbyScreen = (): Data => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState<string>();
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState<string>();
    const [userSelectDisabled, setUserSelectDisabled] = useState<boolean>(true);
    const [energyScreenCheckBoxes, setEnergyScreenCheckBoxes] = useState<boolean[]>(null);
    const [energyScreenDropdown, setEnergyScreenDropdown] = useState<string[]>(null);
    const [powerIrrScreenCheckBoxes, setPowerIrrScreenCheckBoxes] = useState<boolean[]>(null);
    const [powerIrrScreenDropdown, setPowerIrrScreenDropdown] = useState<string[]>(null);
    const [siteOverviewScreenCheckBoxes, setSiteOverviewScreenCheckBoxes] = useState<boolean[]>(null);
    const [siteOverviewScreenDropdown, setSiteOverviewScreenDropdown] = useState<string[]>(null);
    const [siteMonthlyDropdown, setSiteMonthlyDropdown] = useState<string[]>(null);
    const [siteMonthlyCheckBoxes, setSiteMonthlyCheckBoxes] = useState<boolean[]>(null);
    const [siteYearlyDropdown, setSiteYearlyDropdown] = useState<string[]>(null);
    const [siteYearlyCheckBoxes, setSiteYearlyCheckBoxes] = useState<boolean[]>(null);
    const [overViewScreenVersion, setOverViewScreenVersion] = useState("1");
    const [dataToSend, setDataToSend] = useState([]);
    const [severity, setSeverity] = useState<string>("error");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [primaryLogo, setPrimaryLogo] = useState<string>(null);
    const [secondaryLogo, setSecondaryLogo] = useState<string>(null);
    const [primaryLogoSize, setPrimaryLogoSize] = useState<string>(null);
    const [secondaryLogoSize, setSecondaryLogoSize] = useState<string>(null);
    const [primaryLogoLoading, setPrimaryLogoLoading] = useState<boolean>(true);
    const [secondaryLogoLoading, setSecondaryLogoLoading] = useState<boolean>(true);
    const [primaryLogoName, setPrimaryLogoName] = useState<string>("");
    const [secondaryLogoName, setSecondaryLogoName] = useState<string>("");
    const [siteImages, setSiteImages] = useState([]);
    const [plantIds, setPlantIds] = useState(null);
    const [plantsInfo, setPlantsInfo] = useState([]);
    const [colors, setColors] = useState({
        backgroundColor: null,
        energyColor: null,
        energyColorGradient: null,
        gridExportColor: null,
        gridExportColorGradient: null,
        gridImportColor: null,
        gridImportColorGradient: null,
        irradianceColor: null,
        irradianceColorGradient: null,
    });
    const [tenantName, setTenantName] = useState(null);

    const { data: tenantsData } = useGetAllTenantsQuery();
    const { data: usersData } = useGetTenantUsersQuery({ tenantId: selectedTenant },
        { skip: !selectedTenant }
    );
    const { data: lobbyScreenPagePreferencesData, refetch: refetchLobbyScreenPagePreferencesData, isLoading, isFetching } = useGetLobbyScreenPagePreferencesQuery({ userId: selectedUser, tenantId: selectedTenant },
        { skip: !selectedUser || !selectedTenant });
    const { data: siteImagesData, isLoading: siteImagesLoading, isFetching: siteImagesFetching } = useGetSiteImagesQuery({ tenantId: selectedTenant, plantId: plantIds },
        { skip: !plantIds || !selectedTenant });

    const { data: tenantColorsData } = useGetTenantColorsQuery({ tenantId: selectedTenant },
        { skip: !selectedTenant });

    const { data: userColorsData } = useGetUsersColorsQuery({ tenantId: selectedTenant, userId: selectedUser },
        { skip: !selectedTenant || !selectedUser });

    const [postPreferences,] = useAddLobbyScreenPagePreferencesMutation();
    const [postLogo] = useAddLogoMutation();
    const [postImage] = useAddImageMutation()
    const [deleteImage] = useDeleteImageMutation();
    const [deleteLogo] = useDeleteLogoMutation();
    const [addColors] = useAddTenantColorsMutation();

    useEffect(() => {
        if (!lobbyScreenPagePreferencesData) return;
        setPlantIds(lobbyScreenPagePreferencesData?.map(item => item?.plantId))
        if (lobbyScreenPagePreferencesData?.[0]?.aggregatePageVersion === null) {
            setOverViewScreenVersion("1")
            return;
        }
        setOverViewScreenVersion(lobbyScreenPagePreferencesData?.[0]?.aggregatePageVersion)
    }, [lobbyScreenPagePreferencesData])

    useEffect(() => {
        if (!lobbyScreenPagePreferencesData) return;
        setPlantsInfo(lobbyScreenPagePreferencesData?.filter((item, index) => {
            if (siteOverviewScreenCheckBoxes?.[index]) {
                return {
                    plantName: item?.plantName,
                    plantId: item?.plantId,
                }
            }
        }))
    }, [siteOverviewScreenCheckBoxes, lobbyScreenPagePreferencesData])


    useEffect(() => {
        if (!selectedUser || !selectedTenant) return;
        refetchLobbyScreenPagePreferencesData()
        primaryLogoFunction();
        secondaryLogoFunction();
    }, [selectedUser, selectedTenant])

    useEffect(() => {
        if (!selectedTenant) return;
        tenants?.filter((item) => {
            if (item?.value === selectedTenant) {
                setTenantName(item?.label.split(" ")[0])
            }
        })
    }, [selectedTenant])

    useEffect(() => {
        const data = lobbyScreenPagePreferencesData?.map((item, index) => {
            return {
                ...item,
                powerPageVersion: powerIrrScreenDropdown?.[index] === null ? "1" : powerIrrScreenDropdown?.[index],
                energyPageVersion: energyScreenDropdown?.[index] === null ? "1" : energyScreenDropdown?.[index],
                siteOverviewPageVersion: siteOverviewScreenDropdown?.[index] === null ? "1" : siteOverviewScreenDropdown?.[index],
                powerPageEnabled: powerIrrScreenCheckBoxes?.[index] === null ? false : powerIrrScreenCheckBoxes?.[index],
                energyPageEnabled: energyScreenCheckBoxes?.[index] === null ? false : energyScreenCheckBoxes?.[index],
                siteOverviewPageEnabled: siteOverviewScreenCheckBoxes?.[index] === null ? false : siteOverviewScreenCheckBoxes?.[index],
                siteMonthlyPageEnabled: siteMonthlyCheckBoxes?.[index] === null ? false : siteMonthlyCheckBoxes?.[index],
                siteMonthlyPageVersion: siteMonthlyDropdown?.[index] === null ? "1" : siteMonthlyDropdown?.[index],
                siteYearlyPageEnabled: siteYearlyCheckBoxes?.[index] === null ? false : siteYearlyCheckBoxes?.[index],
                siteYearlyPageVersion: siteYearlyDropdown?.[index] === null ? "1" : siteYearlyDropdown?.[index],
                aggregatePageVersion: overViewScreenVersion,
                userId: selectedUser,
                tenantId: selectedTenant,
            }
        })

        if (!data || !data.length) return;
        setDataToSend(data);
    }, [siteMonthlyCheckBoxes, siteMonthlyDropdown,
        siteYearlyDropdown, siteYearlyCheckBoxes,
        overViewScreenVersion,
        energyScreenCheckBoxes, energyScreenDropdown,
        powerIrrScreenCheckBoxes, powerIrrScreenDropdown,
        siteOverviewScreenCheckBoxes, siteOverviewScreenDropdown])

    useEffect(() => {
        setTenants(tenantsData?.map(item => {
            return {
                label: item?.tenantName,
                value: item?.tenantId
            }
        }))
    }, [tenantsData])

    useEffect(() => {
        if (!selectedTenant || !usersData) return;
        setUserSelectDisabled(false);
        setUsers(
            (usersData || [])
                ?.reduce((acc, item) => {
                    if (item?.userRoles?.includes("LOBBYSCREEN")) {
                        acc.push({
                            label: item.userName,
                            value: item.userId
                        });
                    }
                    return acc;
                }, [])
        );
    }, [selectedTenant, usersData])

    const getFileName = (fileNameString) => {
        const filenameRegex = /filename\*?=UTF-8''([\w-]+(?:\.[\w-]+)*)/i;

        // Match the filename using the regex
        const match = fileNameString.match(filenameRegex);

        // Extract the filename from the match
        const filename = match ? match[1] : null;
        return filename;
    }


    const primaryLogoFunction = async () => {
        setPrimaryLogoLoading(true)
        const data: any = await getLobbyLogo('PRIMARY', selectedUser, selectedTenant)
        if (data?.status === 204 || !data) {
            setPrimaryLogo(null)
            setPrimaryLogoLoading(false)

            return
        };
        const sizeOnDisk = data?.data?.size;
        const sizeInKB = sizeOnDisk / 1024;
        const url = URL.createObjectURL(data?.data);
        setPrimaryLogoName(getFileName(data?.headers?.["content-disposition"]))
        setPrimaryLogo(url)
        setPrimaryLogoSize(sizeInKB?.toFixed(1))
        setPrimaryLogoLoading(false)
    }

    const secondaryLogoFunction = async () => {
        setSecondaryLogoLoading(true)
        const data: any = await getLobbyLogo('SECONDARY', selectedUser, selectedTenant)
        if (data?.status === 204 || !data) {
            setSecondaryLogo(null)
            setSecondaryLogoLoading(false)
            return;
        }
        const sizeOnDisk = data?.data?.size;
        const sizeInKB = sizeOnDisk / 1024;
        const url = URL.createObjectURL(data?.data);
        setSecondaryLogoName(getFileName(data?.headers?.["content-disposition"]))
        setSecondaryLogo(url)
        setSecondaryLogoSize(sizeInKB?.toFixed(1))
        setSecondaryLogoLoading(false)
    }

    const uploadPrimaryLogo = async (file) => {
        let newFormData = new FormData();
        newFormData.append("TenantId", selectedTenant);
        newFormData.append("UserId", selectedUser);
        newFormData.append("LogoFile", file);
        newFormData.append("LogoType", "PRIMARY");
        const resp: any = await postLogo({
            data: newFormData
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            primaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }

    const removePrimaryLogo = async () => {
        let newFormData = new FormData();
        newFormData.append("TenantId", selectedTenant);
        newFormData.append("UserId", selectedUser);
        newFormData.append("LogoType", "PRIMARY");
        const resp: any = await postLogo({
            data: newFormData
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            primaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }


    const uploadSecondaryLogo = async (file) => {
        let newFormData = new FormData();
        newFormData.append("TenantId", selectedTenant);
        newFormData.append("UserId", selectedUser);
        newFormData.append("LogoFile", file);
        newFormData.append("LogoType", "SECONDARY");
        const resp: any = await postLogo({
            data: newFormData
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            secondaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }

    const removeSecondaryLogo = async () => {
        let newFormData = new FormData();
        newFormData.append("TenantId", selectedTenant);
        newFormData.append("UserId", selectedUser);
        newFormData.append("LogoType", "SECONDARY");
        const resp: any = await postLogo({
            data: newFormData
        })
        // resp.error because rtk query is not giving any other response

        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            secondaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }

    const deletePrimaryLogo = async () => {
        const resp: any = await deleteLogo({
            tenantId: selectedTenant,
            userId: selectedUser,
            logoType: "PRIMARY"
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            primaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage("Primary Logo not deleted");
            setIsOpen(true);
        }
    }

    const deleteSecondaryLogo = async () => {
        const resp: any = await deleteLogo({
            tenantId: selectedTenant,
            userId: selectedUser,
            logoType: "SECONDARY"
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
            secondaryLogoFunction();
        }
        else {
            setSeverity("error");
            setMessage("Secondary Logo not deleted");
            setIsOpen(true);
        }
    }

    const postData = async () => {
        if (!dataToSend?.length) return;
        const resp: any = await postPreferences({ data: JSON.stringify(dataToSend) });
        const colorsResp: any = await addColors({ data: JSON.stringify({ ...colors, userId: selectedUser }), tenantId: selectedTenant });
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200 && colorsResp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage("Data saved successfully");
            setIsOpen(true);
        }
        else {
            setSeverity("error");
            setMessage("Data not saved");
            setIsOpen(true);
        }
    }

    const onChangeTenant = (value) => {
        setSelectedTenant(value);
        // setSelectedUser(null);
    };

    const onChangeUser = (value) => {
        setSelectedUser(value);
    }

    useEffect(() => {
        getSiteImages()
    }, [siteImagesData, plantsInfo])

    const checkSiteImage = (siteImage: string, imageType: string) => {
        if (!siteImage) return null;
        return `data:${imageType};base64,${siteImage}`;
    }

    const getSiteImages = () => {
        if (!siteImagesData) {
            setSiteImages(plantsInfo?.map((item) => {
                return {
                    plantImage: null,
                    plantName: item?.plantName,
                    fileName: '',
                    plantImageSize: 0,
                    plantId: item?.plantId
                }
            }))
        }
        else {
            setSiteImages(plantsInfo?.map((item, index) => {
                return {
                    plantImage: checkSiteImage(siteImagesData?.[index]?.plantImage?.fileContents, siteImagesData?.[index]?.plantImage?.contentType),
                    plantName: item?.plantName,
                    fileName: siteImagesData?.[index]?.plantImage?.fileDownloadName,
                    plantImageSize: formatBytes(siteImagesData?.[index]?.compressedFileSize),
                    plantImageOriginalSize: formatBytes(siteImagesData?.[index]?.originalFileSize),
                    plantId: item?.plantId
                }
            }))
        }
    }

    const uploadSiteImage = async (file, plantId) => {
        let newFormData = new FormData();
        newFormData.append("TenantId", selectedTenant);
        newFormData.append("UserId", selectedUser);
        newFormData.append("PlantId", plantId);
        newFormData.append("ImageFile", file);
        const resp: any = await postImage({
            data: newFormData
        })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }

    const deleteSiteImage = async (plantId) => {
        const resp: any = await deleteImage({ tenantId: selectedTenant, plantId })
        // resp.error because rtk query is not giving any other response
        if (resp?.error?.originalStatus === 200) {
            setSeverity("success");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
        else {
            setSeverity("error");
            setMessage(resp?.error?.data);
            setIsOpen(true);
        }
    }

    useEffect(() => {
        updateUserColors(userColorsData)
    }, [userColorsData])

    const updateUserColors = (data) => {
        setColors(data)
    }

    const tenantDefaultColors = () => {
        setColors(tenantColorsData)
    }

    const defaultColors = () => {
        setColors({
            backgroundColor: "#0C1E33",
            energyColor: "#00A3FF",
            energyColorGradient: "rgba(0, 163, 255,0)",
            gridExportColor: "#00FFC2",
            gridExportColorGradient: "rgba(0, 255, 194,0)",
            gridImportColor: "#CE3F4E",
            gridImportColorGradient: "rgba(206, 63, 78,0)",
            irradianceColor: "#CE3F4E",
            irradianceColorGradient: "rgba(206, 63, 78, 0)",
        })
    }

    return {
        users,
        selectedUser,
        tenants,
        selectedTenant,
        userSelectDisabled,
        lobbyScreenPagePreferencesData,
        overViewScreenVersion,
        openCancelModal,
        severity,
        isOpen,
        message,
        onChangeTenant,
        onChangeUser,
        setOverViewScreenVersion,
        setEnergyScreenCheckBoxes,
        setPowerIrrScreenCheckBoxes,
        setEnergyScreenDropdown,
        setPowerIrrScreenDropdown,
        setSiteOverviewScreenCheckBoxes,
        setSiteOverviewScreenDropdown,
        setSiteMonthlyDropdown,
        setSiteMonthlyCheckBoxes,
        postData,
        setIsOpen,
        setOpenCancelModal,
        primaryLogo,
        secondaryLogo,
        primaryLogoSize,
        secondaryLogoSize,
        uploadPrimaryLogo,
        uploadSecondaryLogo,
        removePrimaryLogo,
        removeSecondaryLogo,
        deletePrimaryLogo,
        deleteSecondaryLogo,
        isLoading,
        isFetching,
        primaryLogoLoading,
        secondaryLogoLoading,
        primaryLogoName,
        secondaryLogoName,
        setSiteYearlyDropdown,
        setSiteYearlyCheckBoxes,
        siteImages,
        uploadSiteImage,
        siteImagesFetching,
        siteImagesLoading,
        deleteSiteImage,
        colors,
        setColors,
        defaultColors,
        tenantName,
        tenantDefaultColors
    };
};