import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    portfolioRow: {
        "&:hover": {
            backgroundColor: "#F1F3F8 !important"
        },
    },
    plantRow: {
        "&:hover": {
            backgroundColor: "#F1F3F8 !important"
        },
    }
}));