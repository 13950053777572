import React, { useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { IconButton, InputAdornment, Menu } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ReactComponent as TRANSPARENT_STRIPE } from "../../../shared/icons/app/TRANSPARENT_STRIPE.svg";
import { ReactComponent as STRIPE } from "../../../shared/icons/app/STRIPE.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@mui/material/Switch";
import { ReactComponent as SETTINGS } from "../../../shared/icons/app/SETTINGS.svg";
import { ReactComponent as DOWNARROW } from "../../../shared/icons/sidem/DOWNARROW.svg";
import { ReactComponent as UPARROW } from "../../../shared/icons/sidem/UPARROW.svg";
import { ReactComponent as SEARCH } from "../../../shared/icons/sidem/SEARCH.svg";
import { ReactComponent as INFO } from "../../../shared/icons/app/INFO.svg";
import { ReactComponent as TIME } from "../../../shared/icons/app/TIME.svg";
import { timeFormatList } from "./utils";
import { useStyles } from "./styles";
import clsx from "clsx";
import data from "./mockData/data.json";
import { useGlobalSettings } from "./useGlobalSettings";

export const GlobalSettings: React.FC<any> = (props) => {
  const { drawerOpen, active, setActive, isMobile } = props;
  const classes = useStyles();

  const {
    currency,
    setCurrency,
    timeZone,
    setTimeZone,
    timeFormat,
    setTimeFormat,
    openDropdown,
    setOpenDropdown,
    isDropdownOpen,
    setIsDropdownOpen,
    anchorEl,
    setAnchorEl,
    openSettings,
    setOpenSettings,
    searchText,
    setSearchText,
    viewDefinitions,
    setViewDefinitions,
    lightTheme,
    setLightTheme,
    automaticRefresh,
    setAutomaticRefresh,
    timeLabel,
    setTimeLabel,
    selectedOption,
    setSelectedOption,
    userTimeZone,
    setUserTimeZone,
    alarmNotification,
    setAlarmNotification,
  } = useGlobalSettings();
  interface Option {
    value: string;
    abbr: string;
    offset: number;
    isdst: boolean;
    text: string;
    utc: string[];
  }
  const handleOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };
  const handleDropDown = () => {
    setOpenDropdown((openDropdown) => !openDropdown);
  };
  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setOpenDropdown(false);
  };
  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  const getTimeZoneInput = (options: Option[]) => (
    <>
      <TextField
        id="text-field"
        size="medium"
        style={{
          marginTop: "0px",
          fontSize: "13px",
          fontFamily: "Inter",
          width: 150,
        }}
        value={selectedOption ? selectedOption.text : ""}
        onClick={handleDropDown}
        InputProps={{
          style: {
            fontSize: "14px",
            fontFamily: "Inter",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.root}
                onClick={handleDropDown}
                style={{ padding: "0px !important" }}
              >
                {openDropdown ? <UPARROW /> : <DOWNARROW />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {openDropdown && (
        <Box
          style={{
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            width: "fit-content",
            position: "absolute",
            bottom: !isMobile && 0,
            height: "fit-content",
            transform: isMobile ? "translateX(-80%)" : "none",
            left: isMobile ? "75%" : "310px",
            // right: "0px",
            backgroundColor: "white",
            borderRadius: "6px",
            zIndex: 10,
          }}
        >
          <FormControl sx={{ m: 1, margin: "14px" }} variant="filled">
            <TextField
              type="Search"
              style={{
                width: "100%",
                marginTop: "0px",
                fontSize: "13px",
                fontFamily: "Inter",
              }}
              value={searchText}
              onChange={handleSearchTextChange}
              placeholder="Search"
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: "14px",
                  fontFamily: "Inter",
                  paddingLeft: "0px",
                },
                startAdornment: (
                  <InputAdornment position="end">
                    <SEARCH />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              maxHeight={260}
              maxWidth={300}
              style={{
                marginTop: "10px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {searchText
                ? options
                  .filter((option) =>
                    option?.text
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map((option) => (
                    <MenuItem
                      key={option?.value}
                      onClick={() => handleOptionClick(option)}
                      style={{
                        marginLeft: "-15px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        color: "black",
                      }}
                    >
                      {option?.text}
                    </MenuItem>
                  ))
                : options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    style={{
                      marginLeft: "-15px",
                      fontSize: "13px",
                      fontFamily: "Inter",
                      color: "black",
                    }}
                  >
                    {option?.text}
                  </MenuItem>
                ))}
            </Box>
          </FormControl>
        </Box>
      )}
    </>
  );
  const getSwitcher = (checked: boolean, onCheck: (val: boolean) => void) => (
    <Switch
      checked={checked}
      onClick={() => onCheck(!checked)}
      defaultChecked
    />
  );
  const getAlarmTitle = () => (
    <span>
      When enabled, you will receive emails notifying you about equipment that
      has been down for longer than 36 hours.
    </span>
  );
  const getTitle = () => (
    <span>
      You’ll see an{" "}
      <span className={classes.title}>
        {" "}
        <span style={{ marginTop: "2px !important" }}>
          <TIME />
        </span>{" "}
        Actual time{" "}
      </span>
      for a specific plant if there is a difference with your local time
    </span>
  );

  const getItem = (id: any, label: string, icon?: boolean, component?: any) => (
    <div style={{ margin: 20 }}>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item style={{ color: "black" }} className={classes.label}>
          {label}
          {icon && (
            <Tooltip
              title={id === "timeLabel" ? getTitle() : getAlarmTitle()}
              classes={{
                tooltip:
                  id === "timeLabel" ? classes.tooltip : classes.alarmTooltip,
              }}
              style={{ marginLeft: "5px" }}
            >
              <span onClick={() => { }}>
                <INFO />
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item style={{ padding: 0 }}>
          {component}
        </Grid>
      </Grid>
    </div>
  );

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "arrow-popover" : undefined;

  const handleOnChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSettingsClick = useCallback((event) => {
    setOpenSettings((openSettings) => !openSettings);
    setAnchorEl(event.currentTarget);
    setActive(!active);
  }, []);

  const getSelector = (
    options: Array<any>,
    value: any,
    onChange: (val: any) => void
  ) => (
    <Select
      native
      value={value}
      onChange={(e) => onChange(e?.target?.value)}
      variant="standard"
      style={{
        fontSize: "16px",
        paddingRight: "0px !important",
        fontFamily: "Inter",
      }}
      inputProps={{
        className: classes.input,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
      className={classes.dropdownContent}
      IconComponent={() => (
        <IconButton onClick={handleOnChange} className={classes.root}>
          {<DOWNARROW />}
        </IconButton>
      )}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {options?.map((item, idx) => (
        <option key={item + idx} value={item}>
          {item}
        </option>
      ))}
    </Select>
  );

  return (
    <div
      id="userProfile"
      style={{
        width: "100%",
        height: 56,
        display: "flex",
        alignItems: "center",
      }}
      className={clsx(
        classes.globalSettings,
        {
          [classes.globalSettingsOpen]: drawerOpen,
        },

        {
          [classes.globalSettingsActive]: openSettings && active,
        }
      )}
      onClick={handleSettingsClick}
    >
      <div style={{ height: 56, display: "flex" }}>
        <span>
          {!isMobile && openSettings ? <STRIPE /> : <TRANSPARENT_STRIPE />}
        </span>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SETTINGS />
        </span>
      </div>
      {!isMobile && drawerOpen && (
        <span className={classes.menuTitle}>Settings</span>
      )}
      {isMobile && openSettings && (
        <SwipeableDrawer
          anchor={"top"}
          sx={{ top: "100px" }}
          open={openSettings}
          onClose={() => setOpenSettings(false)}
          onOpen={() => setOpenSettings(true)}
        >
          <Box
            sx={{
              height: "100vh",
              marginTop: "50px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="presentation"
          >
            {getItem(
              "alarm",
              "Subscribe to equipment alarms",
              true,
              getSwitcher(alarmNotification, setAlarmNotification)
            )}
            {getItem(
              "timeLabel",
              "Time labels",
              true,
              getSwitcher(timeLabel, setTimeLabel)
            )}
            {getItem(null, "Time zone", false, getTimeZoneInput(data))}
            {getItem(
              null,
              "Time format",
              false,
              getSelector(timeFormatList, timeFormat, setTimeFormat)
            )}
          </Box>
        </SwipeableDrawer>
      )}

      {!isMobile && openSettings && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            borderRadius: "6px",
            left: drawerOpen ? "230px" : "65px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            position: "absolute",
            bottom: 0,
            width: "360px",
            backgroundColor: "#FFFFFF",
            cursor: "default",
          }}
        >
          {getItem(
            "alarm",
            "Subscribe to equipment alarms",
            true,
            getSwitcher(alarmNotification, setAlarmNotification)
          )}
          {getItem(
            "timeLabel",
            "Time labels",
            true,
            getSwitcher(timeLabel, setTimeLabel)
          )}
          {getItem(null, "Time zone", false, getTimeZoneInput(data))}
          {getItem(
            null,
            "Time format",
            false,
            getSelector(timeFormatList, timeFormat, setTimeFormat)
          )}
        </Box>
      )}
    </div>
  );
};
