import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { getPageItem } from "../../shared/utils/pages";
import { MenuItem, Roles } from "../../shared/interfaces";
import PlantPage from "./PlantPage";
import ClientPage from "./ClientPage";
import PortfolioPage from "./PortfolioPage";
import InverterPage from "./InverterPage";
import { PlantInformation } from "./PlantPage/components/PlantInformation/PlantInformation";
import { AlarmOverviewPage } from "./AlarmOverviewPage/AlarmOverviewPage.component";
import { Reports } from "./Reports";
import { SidemNavigation } from "./SidemNavigation.component";
import { CustomReports } from "./CustomReportsPage/CustomReports.component";
import { ReactComponent as ANALYTICS_LIGHT } from "../../shared/icons/sidebar/light/ANALYTICS_LIGHT.svg";
import { ReactComponent as SIDEM_BLACK } from "../../shared/icons/sidebar/dark/SIDEM_BLACK.svg";
import { Error404Page } from "../../app/Error404Page";

export enum SidemPagesEnum {
  ClientPage = "/sidem/portfolios",
  PortfolioPage = "/sidem/portfolios/:portfolioId",
  PlantPage = "/sidem/portfolios/:portfolioId/plant/:plantId",
  InverterPage = "/sidem/portfolios/:portfolioId/plant/:plantId/block/:blockId/inverter/:inverterId",
  PlantInformation = "/sidem/portfolios/:portfolioId/plant/:plantId/information",
  AlarmOverviewPage = "/sidem/alarm-overview",
  CustomReportsPage = "/sidem/custom-reports",
  Report = "/sidem/report",
}

type SidemPagesMap = {
  [key in SidemPagesEnum]?: any;
};

const getPages = (
  imageService: any,
  role?: any
): SidemPagesMap => ({
  [SidemPagesEnum.ClientPage]: getPageItem(SidemPagesEnum.ClientPage, ClientPage, "", { imageService }
  ),
  [SidemPagesEnum.PortfolioPage]: getPageItem(
    SidemPagesEnum.PortfolioPage,
    PortfolioPage,
    "",
    { imageService }
  ),
  [SidemPagesEnum.PlantPage]: getPageItem(
    SidemPagesEnum.PlantPage,
    PlantPage,
    "",
    { imageService }
  ),
  [SidemPagesEnum.InverterPage]: getPageItem(
    SidemPagesEnum.InverterPage,
    InverterPage,
    "",
    { imageService }
  ),
  [SidemPagesEnum.PlantInformation]: getPageItem(
    SidemPagesEnum.PlantInformation,
    PlantInformation,
    "",
  ),
  [SidemPagesEnum.AlarmOverviewPage]: role
    ? getPageItem(SidemPagesEnum.AlarmOverviewPage, AlarmOverviewPage, "")
    : getPageItem(SidemPagesEnum.AlarmOverviewPage, Error404Page, "", {}),
  [SidemPagesEnum.CustomReportsPage]: getPageItem(
    SidemPagesEnum.CustomReportsPage,
    CustomReports,
    "",
  ),
});

export const SidemPages: React.FC<any> = ({
  imageService,
  SkyfriDemoRole,
}) => {
  const pages: SidemPagesMap = useMemo(() => {
    return getPages(imageService, SkyfriDemoRole);
  }, [imageService]);

  return (
    <Routes>
      {Object.values(pages).map(({ path, component: Component, params }) => (
        <Route
          key={path}
          path={path}
          element={<Component {...params} />}
        />
      ))}
    </Routes>
  );
};

export const getSidemMenuItem = (SkyfriDemoRole): MenuItem => ({
  idx: Roles.SIDEM,
  title: "ANALYTICS",
  Icon: ANALYTICS_LIGHT,
  DarkIcon: SIDEM_BLACK,
  navigation: <SidemNavigation />,
  items: [
    {
      path: SidemPagesEnum.ClientPage,
      component: ClientPage,
      title: "Portfolio Overview",
      params: {},
    },
    SkyfriDemoRole
      ? {
        path: SidemPagesEnum.AlarmOverviewPage,
        component: AlarmOverviewPage,
        title: "Alarm Overview",
        params: {},
      }
      : {
        path: SidemPagesEnum.AlarmOverviewPage,
        component: AlarmOverviewPage,
        title: "",
        params: {},
      },
    {
      path: SidemPagesEnum.Report,
      component: Reports,
      title: "Reports",
      params: {},
    },
  ],
});
