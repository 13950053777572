import React, { useState, useEffect } from "react";
import moment from "moment";
import { UseAlarmOverview } from "./useAlarmOverview";
import Loader from "../../components/Loader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Card, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Dropdown, Datepicker, Typography } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";

import { Filters } from "./components/filters.component";

import { useStyles } from "./components/styles";

import {
  trippingViewHeaderCell,
  underperformingViewHeaderCell,
  accidentViewHeaderCell,
} from "./components/interfaces";

export const AlarmOverviewPage: React.FC = () => {
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedPlant, setSelectedPlant] = useState("");
  const [filter, setFilter] = useState("Today");
  const [dateFrom, setDateFrom] = useState(moment().format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [status, setStatus] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [alarmTypeId, setAlarmTypeId] = useState("");

  const classes = useStyles();

  const {
    plant,
    trippingInverters,
    portfolio,
    underPerformingInverters,
    generationLoss,
    generationlossToday,
    totalGenerationloss,
    alarmType,
    category,
    equipment,
    equipmentList,
    alarms,
    alarmGenerationlossToday,
    totalAlarms,
    trippingUnderperformingAlarms,
    activeAlarms,
    alarmGenerationlossTotal,
    Pagination,
    loading,
    loadingAlarm,
    error,
  } = UseAlarmOverview({
    selectedPlant,
    dateFrom,
    dateTo,
    categoryId,
    selectedPortfolio,
    equipmentId,
    alarmTypeId,
    status,
  });

  useEffect(() => {
    if (portfolio.length <= 0) return;
    setSelectedPortfolio(portfolio[0]["value"]);
  }, [portfolio]);

  useEffect(() => {
    if (plant.length <= 0) return;
    setSelectedPlant(plant[0]["value"]);
  }, [plant]);

  const onChangeFilter = (type: any) => {
    setFilter(type);
    Pagination(alarms?.["totalRecords"]).onChange(0, 5);

    switch (type) {
      case "Today":
        setDateFrom(moment().format("YYYY-MM-DD"));
        break;
      case "Week":
        setDateFrom(moment().subtract(7, "d").format("YYYY-MM-DD"));
        break;
      case "Month":
        setDateFrom(moment().subtract(30, "d").format("YYYY-MM-DD"));
        break;
      case "Year":
        setDateFrom(moment().subtract(1, "y").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const changeMonth = (date) => {
    setDateFrom(moment(date).startOf("month").format("YYYY-MM-DD"));
    setDateTo(moment(date).endOf("month").format("YYYY-MM-DD"));
  };

  const changeFromDate = (date) => {
    if (
      moment(date).format("YYYY-MM-DD") > moment(dateTo).format("YYYY-MM-DD")
    ) {
      setDateTo(moment(date).format("YYYY-MM-DD"));
    }
    setDateFrom(moment(date).format("YYYY-MM-DD"));
  };

  const changeToDate = (date) => {
    if (
      moment(date).format("YYYY-MM-DD") < moment(dateFrom).format("YYYY-MM-DD")
    ) {
      setDateFrom(moment(date).format("YYYY-MM-DD"));
    }
    setDateTo(moment(date).format("YYYY-MM-DD"));
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (active) {
      return (
        <div className={classes.customTooltip}>
          {payload ? (
            <>
              <p
                className={classes.tooltipText}
              >{`Inverter: ${payload[0]?.payload.equipName}`}</p>
              <div>{` ${Number(payload[0]?.payload.inverterDown) +
                Number(payload[0]?.payload.inverterUnderPerformance)
                } Alarm`}</div>
              <div className={classes.Inline}>
                <Box
                  sx={{ width: 10, height: 10, backgroundColor: "#2B2958" }}
                />{" "}
                &nbsp; {payload[0]?.payload.inverterDown} tripping alarm
              </div>
              <div className={classes.Inline}>
                <Box
                  sx={{ width: 10, height: 10, backgroundColor: "#A275C8" }}
                />
                &nbsp; {payload[0]?.payload.inverterUnderPerformance}{" "}
                underperforming alarm
              </div>
              <p
                className={classes.tooltipText}
              >{`${payload[0]?.payload.generationloss === null ?
                0
                :
                Math.abs(payload[0]?.payload.generationloss) >= 1000 ?
                  (payload[0]?.payload.generationloss / 1000).toFixed(2) + " MWh"
                  :
                  Math.abs(payload[0]?.payload.generationloss.toFixed(2)) + " kWh"} generation loss`}</p>
            </>
          ) : (
            ""
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className={classes.pageHeader}>
        <Card elevation={5} style={{ padding: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Dropdown
                label={"Portfolio"}
                value={selectedPortfolio}
                options={portfolio}
                width={selectedPortfolio.length}
                onChangeValue={(filterValue: string): void =>
                  setSelectedPortfolio(filterValue)
                }
              />
              <span style={{ marginRight: 25 }}></span>
              <Dropdown
                label={"Plant"}
                value={selectedPlant}
                options={plant}
                onChangeValue={(filterValue: string): void =>
                  setSelectedPlant(filterValue)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} container justify="flex-end">
              <ButtonGroup
                aria-label="outlined  button group"
                className={classes.background}
              >
                <Button
                  className={`${classes.text} ${filter === "Today" ? classes.Active : ""
                    }`}
                  onClick={() => onChangeFilter("Today")}
                  style={{ textTransform: "capitalize" }}
                >
                  Today
                </Button>
                <Button
                  className={`${classes.text} ${filter === "Week" ? classes.Active : ""
                    }`}
                  onClick={() => onChangeFilter("Week")}
                  style={{ textTransform: "capitalize" }}
                >
                  Week
                </Button>
                <Button
                  className={`${classes.text} ${filter === "Month" ? classes.Active : ""
                    }`}
                  onClick={() => onChangeFilter("Month")}
                  style={{ textTransform: "capitalize" }}
                >
                  Month
                </Button>
                <Button
                  className={`${classes.text} ${filter === "Year" ? classes.Active : ""
                    }`}
                  onClick={() => onChangeFilter("Year")}
                  style={{ textTransform: "capitalize" }}
                >
                  Year
                </Button>
                <Button
                  className={`${classes.text} ${filter === "Custom" ? classes.Active : ""
                    }`}
                  onClick={() => onChangeFilter("Custom")}
                  style={{ textTransform: "capitalize" }}
                >
                  Custom
                </Button>
              </ButtonGroup>
            </Grid>
            {filter === "Custom" ? (
              <Grid item xs={12} sm={12} md={12} container justify="flex-end">
                <Typography label={"By month"}>
                  <Datepicker
                    ShowMonth={true}
                    value={new Date(dateFrom)}
                    onChange={(date) => changeMonth(date)}
                  />
                </Typography>
                <div style={{ marginRight: "5px" }}></div>
                <Typography label={"By time period(from)"}>
                  <div style={{ display: "inline-flex" }}>
                    <Datepicker
                      value={new Date(dateFrom)}
                      onChange={(date) => changeFromDate(date)}
                    />
                  </div>
                </Typography>
                <div style={{ marginRight: "5px" }}></div>
                <Typography label={"To"}>
                  <div style={{ display: "inline-flex" }}>
                    <Datepicker
                      value={new Date(dateTo)}
                      onChange={(date) => changeToDate(date)}
                    />
                  </div>
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Card>
      </div>
      <Loader loading={loading} error={error} />
      {!loading ? (
        <div className={classes.pageWrapper}>
          {/* <br /> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={5}>
              <Card
                style={{
                  padding: "20px 5px 5px 20px",
                  height: "300px",
                  maxHeight: "300px",
                }}
              >
                <div className={classes.pageLabel}>
                  Highest tripping inverters{" "}
                </div>
                <CustomTable
                  tableType={""}
                  headerCells={trippingViewHeaderCell}
                  data={trippingInverters}
                  minWidth={10}
                  isDoubleClickActive={false}
                  maxHeight={200}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card style={{ padding: "20px 5px 5px 20px", height: "300px" }}>
                <div className={classes.pageLabel}>
                  Most underperforming inverters{" "}
                </div>
                <CustomTable
                  tableType={""}
                  headerCells={underperformingViewHeaderCell}
                  data={underPerformingInverters}
                  minWidth={10}
                  maxHeight={200}
                  isDoubleClickActive={false}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Card style={{ padding: "20px 5px 5px 20px", height: "300px" }}>
                <div className={classes.pageLabel}>
                  Approximate generation loss
                </div>
                <Grid container spacing={2} style={{ paddingTop: "2rem" }}>
                  <Grid item xs={6}>
                    <h3 className={classes.center}>
                      {
                        generationlossToday !== undefined ?
                          Math.abs(generationlossToday) >= 1000 ?
                            `${(Math.abs(generationlossToday) / 1000)?.toFixed(2)} MWh`
                            :
                            `${Math.abs(generationlossToday)?.toFixed(2)} kWh`
                          :
                          `0 kWh`
                      }
                      <br />{" "}
                      {filter === "Custom" ? "" : filter?.toLocaleLowerCase()}
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3 className={classes.center}>
                      {
                        totalGenerationloss !== undefined ?
                          Math.abs(totalGenerationloss) >= 1000 ?
                            `${(Math.abs(totalGenerationloss) / 1000)?.toFixed(2)} MWh`
                            :
                            `${Math.abs(totalGenerationloss)?.toFixed(2)} kWh`
                          :
                          `0 kWh`
                      }
                      <br />
                      total
                    </h3>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card style={{ padding: "20px 5px 5px 20px" }}>
                <div className={classes.pageLabel}>
                  Alarms
                  <InfoOutlined />
                </div>
                <div style={{ marginBottom: "1em", fontSize: 16 }}>
                  {trippingUnderperformingAlarms} alarms{" "}
                  {filter === "Custom"
                    ? `(from ${moment(dateFrom).format(
                      "DD/MM/YYYY"
                    )} to ${moment(dateTo).format("DD/MM/YYYY")})`
                    : filter?.toLowerCase()}
                </div>
                <div style={{ marginLeft: "2em" }}>No of alarms</div>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    height={300}
                    data={generationLoss}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="equipName" />
                    <YAxis />
                    <Tooltip content={CustomTooltip} />
                    <Legend />

                    <Bar
                      dataKey="inverterDown"
                      name="Tripping alarms"
                      stackId="a"
                      fill="#2B2958"
                    />
                    <Bar
                      dataKey="inverterUnderPerformance"
                      name="Underperforming alarms"
                      stackId="a"
                      fill="#A275C8"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card style={{ padding: "20px 5px 5px 20px" }}>
                <div className={classes.pageLabel}>Alarm list</div>
                <Filters
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  filter={filter}
                  alarmType={alarmType}
                  alarmTypeId={alarmTypeId}
                  setAlarmTypeId={(name) => setAlarmTypeId(name ? name : "")}
                  categoryId={categoryId}
                  category={category}
                  setCategory={(name) => setCategoryId(name ? name : "")}
                  equipmentList={equipmentList}
                  equipmentId={equipmentId}
                  equipment={equipment}
                  setEquipment={(name) => setEquipmentId(name ? name : "")}
                  status={status}
                  setStatus={(name) => setStatus(name ? name : "")}
                  activeAlarms={activeAlarms}
                  totalAlarms={totalAlarms}
                  alarmGenerationlossToday={alarmGenerationlossToday}
                  alarmGenerationlossTotal={alarmGenerationlossTotal}
                />
                <Loader loading={loadingAlarm} error={error} />
                {!loadingAlarm ? (
                  <CustomTable
                    tableType={""}
                    headerCells={accidentViewHeaderCell}
                    data={alarms?.["data"]}
                    minWidth={10}
                    isDoubleClickActive={false}
                    paginationProps={Pagination(alarms?.["totalRecords"])}
                  />
                ) : (
                  <CustomTable
                    tableType={""}
                    headerCells={accidentViewHeaderCell}
                    data={null}
                    minWidth={10}
                    isDoubleClickActive={false}
                    paginationProps={Pagination(alarms?.["totalRecords"])}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
