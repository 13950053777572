import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2)
  },

  label: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },

  subLabel: {
    fontWeight: 400,
  },

  noChildren: {
    marginBottom: 0,
  }
}));

interface Props {
  label?: string;
  subLabel?: string;
  children?: React.ReactNode;
  display?:string;
}

export const Typography: React.FC<Props> = ({ label, subLabel, children,display}) => {
  const classes = useStyles();

  return (
    <div style={{display:display,alignItems: "end"}} className={clsx(classes.wrapper, { [classes.noChildren]: !children })}>
      <div className={clsx(classes.label, { [classes.subLabel]: subLabel?.length })}>{label || subLabel}</div>

      {children}
    </div>
  );
};
