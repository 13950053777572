import React from 'react'
import { useStyles } from './styles';
import { useHistory } from 'react-router';
import { Grid, TableCell } from '@mui/material';
import Chip from '@mui/material/Chip';
import { convertTimeZoneForPlants, convertkWToMW, kWhOrMWh } from '../../../../../../shared/utils/utils';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as GREEN_ARROW_UP } from '../../../../../../shared/icons/analytics/GREEN_ARROW_UP.svg';
import { ReactComponent as RED_ARROW_DOWN } from '../../../../../../shared/icons/analytics/RED_ARROW_DOWN.svg';
import { ReactComponent as LOCATION_BLACK } from '../../../../../../shared/icons/sidem/LOCATION_BLACK.svg';
import { ReactComponent as TIMEZONE_CLOCK } from "../../../../../../shared/icons/app/TIMEZONE_CLOCK.svg";


import clsx from "clsx";

interface Props {
    headerCells: any;
    row: any;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const CustomHiddenRow = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { row, headerCells } = props;

    const [
        plant,
        alarms,
        capacity,
        genDown,
        generation,
        insolation,
        pr,
        tcpr,
        cuf,
        plantAv,
        gridAv,
        lastUpdated,
    ] = headerCells;

    const AlarmIcon = row?.alarms?.icon;

    const checkClassNames = (value: any) => {
        if (value)
            return value;
        else return "Equal";
    }

    const noData = () =>
        <div className={classes.noData}>
            <span className={classes.noDataText}>
                No Data
            </span>
        </div>


    const renderValues = (value, expectedValue: string, unit?: string) => {

        return (
            <>
                {
                    value ?
                        <div className={expectedValue ? classes[checkClassNames(expectedValue)] : classes.Equal}>
                            <span className={expectedValue ? classes[`${checkClassNames(expectedValue)}Text`] : classes.EqualText}>
                                {convertkWToMW(value)?.toFixed(2)}
                            </span>
                            <span className={expectedValue ? classes[`${checkClassNames(expectedValue)}TextUnit`] : classes.EqualTextUnit}>
                                {
                                    unit ? unit : kWhOrMWh(value)
                                }
                            </span>
                        </div>
                        :
                        noData()
                }
            </>

        )
    }

    const checkDeltaIcon = (value: any) => {
        if (value === "Low")
            return <RED_ARROW_DOWN />

        else if (value === "High")
            return <GREEN_ARROW_UP />

        else return null;
    }


    return (
        <>
            {/* plantName */}
            <TableCell key={plant.key} align={plant.align} style={{ minWidth: "225px", width: "225px", maxWidth: "225px" }}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs={"auto"}>
                        <div className={classes.heading} >
                            {row?.plantName}
                            <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.locationName ?
                                <span className={classes.locationName}>
                                    {row?.locationName}
                                </span>
                                : null} placement="bottom">
                                <div className={classes.loactionBg}>
                                    <LOCATION_BLACK />
                                </div>
                            </LightTooltip>
                            <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.timeZone ?
                                <span className={classes.locationName}>
                                    {convertTimeZoneForPlants(row?.timeZone, "MMM DD, HH:mm Z", new Date(),)}
                                </span>
                                : null} placement="bottom">
                                <span >
                                    <div className={classes.timeZoneClockBg}>
                                        <TIMEZONE_CLOCK height={16} width={16} />
                                    </div>
                                </span>
                            </LightTooltip>

                        </div>
                    </Grid>
                </Grid>
            </TableCell>


            {/* Alarms */}
            <TableCell key={alarms.key} align={alarms.align} sx={{ minWidth: "120px", width: "120px", maxWidth: "120px" }}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs={"auto"}>

                        <Chip
                            size='small'
                            sx={{
                                '& .MuiChip-label': { opacity: row?.alarms?.opacity },
                                backgroundColor: row?.alarms?.backGroundColor,
                                color: row?.alarms?.textColor,
                                fontFamily: [
                                    'Inter',
                                    'Roboto',
                                    'sans-serif'
                                ].join(',')
                            }}
                            label={row?.numberOfAlarms}
                            icon={<AlarmIcon style={{ opacity: row?.alarms?.opacity }} />} />

                    </Grid>
                </Grid>
            </TableCell>

            {/* dcCapacityMW */}
            <TableCell key={capacity.key} align={capacity.align} sx={{ minWidth: "160px", width: "160px", maxWidth: "160px" }}>
                {
                    renderValues(row?.dcCapacityMW, null, "MW/MWp")
                }
            </TableCell>

            {/* GenD */}
            <TableCell key={genDown.key} align={genDown.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.genDDelta ?
                    <>
                        <div className={clsx(classes[row?.genDDeltaExpected], classes.flexType)}>
                            <span className={classes[`${row?.genDDeltaExpected}Text`]}>
                                {row?.genDDelta}%
                            </span>
                            {
                                checkDeltaIcon(row?.genDDeltaExpected)
                            }
                        </div>
                    </>
                    : null} placement="bottom">
                    <Grid
                        container
                        alignItems="center"
                    >
                        {
                            renderValues(row?.genD, row?.genDExpected)
                        }
                    </Grid>
                </LightTooltip>
            </TableCell>

            {/* actual gen */}
            <TableCell key={generation.key} align={generation.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.actualGenDelta ?
                    <>
                        <div className={clsx(classes[row?.actualGenExpected], classes.flexType)}>
                            <span className={classes[`${row?.actualGenExpected}Text`]}>
                                {row?.actualGenDelta}%
                            </span>
                            {
                                checkDeltaIcon(row?.actualGenExpected)
                            }
                        </div>
                    </>
                    : null} placement="bottom">
                    <Grid
                        container
                        alignItems="center"
                    >
                        {
                            renderValues(row?.actualGen, row?.actualGenExpected)
                        }
                    </Grid>
                </LightTooltip>
            </TableCell >

            {/* insolation */}
            <TableCell key={insolation.key} align={insolation.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.actualIrradiationDelta ?
                    <>
                        <div className={clsx(classes[row?.actualIrradiationExpected], classes.flexType)}>
                            <span className={classes[`${row?.actualIrradiationExpected}Text`]}>
                                {row?.actualIrradiationDelta}%
                            </span>
                            {
                                checkDeltaIcon(row?.actualIrradiationExpected)
                            }
                        </div>
                    </>
                    : null} placement="bottom">
                    <Grid
                        container
                        alignItems="center"
                    >
                        {
                            renderValues(row?.actualIrradiation, row?.actualIrradiationExpected, "kWh/m²")
                        }
                    </Grid>
                </LightTooltip>
            </TableCell>

            {/* PR */}
            <TableCell key={pr.key} align={pr.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.actualPrDelta ?
                    <>
                        <div className={clsx(classes[row?.actualPrExpected], classes.flexType)}>
                            <span className={classes[`${row?.actualPrExpected}Text`]}>
                                {row?.actualPrDelta}%
                            </span>
                            {
                                checkDeltaIcon(row?.actualPrExpected)
                            }
                        </div>
                    </>
                    : null} placement="bottom">
                    <Grid
                        container
                        alignItems="center"
                    >
                        {
                            renderValues((row?.actualPr), row?.actualPrExpected, "%")
                        }
                    </Grid>
                </LightTooltip>
            </TableCell>

            {/* TCPR */}
            <TableCell key={tcpr.key} align={tcpr.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                {
                    renderValues((row?.tcpr), null, "%")
                }
            </TableCell>

            {/* plant avail */}
            <TableCell key={plantAv.key} align={plantAv.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                <LightTooltip enterTouchDelay={1} leaveTouchDelay={2000} title={row?.actualAvailabilityDelta ?
                    <>
                        <div className={clsx(classes[row?.actualAvailabilityExpected], classes.flexType)}>
                            <span className={classes[`${row?.actualAvailabilityExpected}Text`]}>
                                {row?.actualAvailabilityDelta}%
                            </span>
                            {
                                checkDeltaIcon(row?.actualAvailabilityExpected)
                            }
                        </div>
                    </>
                    : null} placement="bottom">
                    <Grid
                        container
                        alignItems="center"
                    >
                        {
                            renderValues((row?.actualAvailability), row?.actualAvailabilityExpected, "%")
                        }
                    </Grid>
                </LightTooltip>
            </TableCell>

            {/* grid avail */}
            <TableCell key={gridAv.key} align={gridAv.align} sx={{ minWidth: "140px", width: "140px", maxWidth: "140px" }}>
                {
                    renderValues((row?.gridAvailability), null, "%")
                }
            </TableCell>

            {/* last updated */}
            <TableCell key={lastUpdated.key} align={lastUpdated.align} sx={{ minWidth: "170px", width: "170px", maxWidth: "170px" }}>
                {
                    <div className={classes.lastUpdated}>
                        {row?.lastUpdated} ago
                    </div>
                }
            </TableCell>
        </>
    )
}
