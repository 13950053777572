import { useEffect, useState } from "react";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  userId: string;
}

interface Data {
  upsert: (params: any, id: string, callback: any) => void;
  user: any;
  Contacts: any;
  Company: any;
}

export const useUserSave = ({ userId }: Props): Data => {
  const [user, setUser] = useState(null);
  const [Contacts, setContacts] = useState(null);
  const [Company, setCompany] = useState(null);

  useEffect(() => {
    GetUser();
  }, []);

  const GetUser = async () => {
    if (!userId) return;
    await fetchAsync(`onboardingV2/v1/User/GetAllUsers`, "GET")
      .then((data: any) => {
        if (data) {
          var usrInfo = data?.find((x) => x.userId === userId);
          setUser(usrInfo);
        }
      })
      .catch((e) => console.error(e));
  };

  const upsert = async (params: any, id: string, callback: any) => {
    let requestType;
    if (id && id != "new") {
      params.userId = id;
      requestType = "PUT";
      await fetchAsync(`onboardingV2/v1/User/UpdateUser`, requestType, params)
        .then((data: any) => {
          callback(data);
        })
        .catch((e: any) => console.error(e));
    }
    if (id === "new") {
      requestType = "POST";
      await fetchAsync(`onboardingV2/v1/User/AddUser`, requestType, params)
        .then((data: any) => {
          callback(data);
        })
        .catch((e: any) => console.error(e));
    }
  };

  return {
    upsert,
    user,
    Contacts,
    Company,
  };
};
