import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { UseTeamMemberSave } from "./useTeamMemberSave";
import { GetFormik, initializeValidationsObsolete } from "./../../components/Utils/utils";
import { formSchemaTeamMember } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";

interface Props {
  subsidiaryId: string;
  type: string;
  callback: any;
}

export const TeamMemberSavePage: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { teamId } = useParams<{ teamId: string }>();
  const { teamMemberId } = useParams<{ teamMemberId: string }>();

  const { userTenant, upsert } = UseTeamMemberSave({
    teamId,
    tenantId,
    teamMemberId,
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const createNewTeamMember = teamMemberId === "new";

  useEffect(() => {
    if (userTenant) formSchemaTeamMember.tenantUserId.options = userTenant;
  }, [userTenant]);

  useEffect(() => {
    initializeValidationsObsolete(formSchemaTeamMember, (resp) => {
      setValidationSchema(resp);
    });
  }, [userTenant]);

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.tenantId = tenantId;

    upsert(fields, teamId, (resp) => {
      if (resp?.ok === false) {
        setSeverity("error");
        setMessage("Operation unsuccessfull");
        setIsOpen(true);
      } else {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true);
      }
      setTimeout(() => {
        let URI = `/onboarding/team/${tenantId}/${teamId}`;
        renderPage(URI);
      }, 2000);
    });
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewTeamMember ? (
          <>Add new team member </>
        ) : (
          <>Edit team member</>
        )}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(formSchemaTeamMember).map((x) => (
              <DynamicField
                name={x}
                data={formSchemaTeamMember[x]}
                formik={formik}
              />
            ))}
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save team member"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Cancel"
              onCancel={() => {
                let URI = `/onboarding/team/${tenantId}/${teamId}`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
