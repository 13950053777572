import { combineReducers } from "@reduxjs/toolkit";
import { alarmsReducer } from "./alarms/alarmSlice";
import { clientReducer } from "./client/clientSlice";
import { portfolioReducer } from "./portfolios/portfolioSlice";
import { reportReducer } from "./reports/reportSlice";
import { crossEquimentComparisonReducer } from './crossEquimentComparison/crossEquimentComparisonSlice'

const SidemReducer = combineReducers({
  portfolio: portfolioReducer,
  report: reportReducer,
  alarm: alarmsReducer,
  client: clientReducer,
  crossEquimentComparison: crossEquimentComparisonReducer,
});

export type AppState = ReturnType<typeof SidemReducer>;

export default SidemReducer;
