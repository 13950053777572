import { useCallback, useMemo, useState } from "react";
import { analyticsSharedSelector } from "../../../../../features/Analytics/shared/analyticsSharedSlice";
import {
  convertTime,
  getSelectedString,
} from "../../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetAnalyticsPlantsCardQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useAppSelector } from "../../../../../store/rootStore";

export const usePlants = () => {

  const [url] = useSearchParams();
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");
  const plantIdUrl = url.get("plantId");
  const portfolioIdUrl = url.get("portfolioId");

  const [search, setSearch] = useState("");

  const { liveButton } = useAppSelector(analyticsSharedSelector);
  // const userTimezone = moment.tz.guess();

  const portfolioName = portfolioIdUrl ? getSelectedString(JSON.parse(portfolioIdUrl), "label") : undefined;
  const plantId = plantIdUrl ? getSelectedString(JSON.parse(plantIdUrl), "value") : undefined;

  const { data, isFetching, error } = useGetAnalyticsPlantsCardQuery(
    {
      fromDate: convertTime(startDate, "YYYY.MM.DD"),
      toDate: convertTime(endDate, "YYYY.MM.DD"),
      portfolioName,
      plantId,
    },
    {
      skip: !plantIdUrl || !endDate || !startDate,
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const searchResults = useMemo(() => {
    let result = [];

    if (!data?.data) {
      return result;
    }

    const searchByPlantName = data?.data
      ?.map((item) => ({
        ...item,
        plant: item.plant.filter((plant) =>
          plant.plantName
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ),
      }))
      .filter((item) => item.plant.length > 0);

    const searchByPortfolioName = data?.data?.filter((item) =>
      item.portfolioName
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())
    );

    if (searchByPlantName.length > 0) {
      result = searchByPlantName;
    } else if (searchByPortfolioName.length > 0) {
      result = searchByPortfolioName;
    }

    return result;
  }, [search, data]);

  const onSearch = useCallback((newSearch: string) => {
    setSearch(newSearch);
  }, []);

  return {
    plantsData: searchResults,
    search,
    setSearch,
    onSearch,
    plantsLoading: isFetching,
    plantError: Boolean(error),
  };
};
