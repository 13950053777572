import React, { useMemo, useRef } from "react";
import { Container } from "@mui/material";
import { HeaderWidget } from "./components/HeaderWidget/HeaderWidget.component";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";
import { SiteInfoWidget } from "./components/SiteInfoWidget";
import { BatteryStatusAndPercentage } from "./components/BatteryStatusAndPercentage";
import { ChargeDischargeChartWidget } from "./components/ChargeDischargeChartWidget";
import { Grid } from "@material-ui/core";
import { BatteryHeatmap } from "./components/BatteryHeatmap";
import { ChartWrapper } from "./components/ChartWrapper";
import { PowerChartWidget } from "./components/PowerChartWidget";
import { VoltageChartWidget } from "./components/VoltageChartWidget";
import { BatteryPowerWidget } from "./components/BatteryPowerWidget";
import { BatteryVoltageAndFrequencyWidget } from "./components/BatteryVoltageAndFrequencyWidget";
import { BatteryTemperatureWidget } from "./components/BatteryTemperatureWidget";
import { BatteryChargeDischargeSocWidget } from "./components/BatteryChargeDischargeSocWidget";
import { diffDateDays } from "../../../shared/utils/utils";
import { useUrlParams } from "../../../shared/utils/hooks";
import { BatteryControlChartWidget } from "./components/BatteryControlChartWidget";

export const BatteryMonitoring: React.FC = () => {
  const { startDate, endDate } = useUrlParams({ startDate: "", endDate: "" });

  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setDate,
    globalDate,
    onChangePortfolio,
    onChangePlant,
    liveButton,
    handleChangeLiveButton,
  } = useHeaderWidget();

  const asideWidgetsRef = useRef(null);

  const daysDiff = useMemo(() => {
    return diffDateDays(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: "16px" }}>
      <HeaderWidget
        selectedPortfolio={selectedPortfolio}
        portfolio={portfolio}
        selectedPlant={selectedPlant}
        plant={plant}
        date={globalDate}
        setDate={setDate}
        onChangePortfolio={onChangePortfolio}
        onChangePlant={onChangePlant}
        liveButton={liveButton}
        handleChangeLiveButton={handleChangeLiveButton}
      />

      <SiteInfoWidget />

      <Grid container spacing={2} style={{ marginTop: "8px" }}>
        {liveButton && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <BatteryStatusAndPercentage />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={5}>
              <BatteryPowerWidget />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <BatteryVoltageAndFrequencyWidget />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4} xl={3}>
              <Grid container spacing={2} ref={asideWidgetsRef}>
                <Grid item xs={12}>
                  <BatteryTemperatureWidget />
                </Grid>
                <Grid item xs={12}>
                  <BatteryChargeDischargeSocWidget />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={8} xl={9}>
              <ChargeDischargeChartWidget
                height={(asideWidgetsRef?.current?.clientHeight || 150) - 16}
              />
            </Grid>
            {daysDiff < 3 && (
              <>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <PowerChartWidget
                    height={
                      (asideWidgetsRef?.current?.clientHeight || 150) - 16
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <VoltageChartWidget
                    height={
                      (asideWidgetsRef?.current?.clientHeight || 150) - 16
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ChartWrapper>
            <BatteryHeatmap />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          {/* TODO: add battery calibration */}
        </Grid>

        <Grid item xs={12}>
          <ChartWrapper>
            <BatteryControlChartWidget height={400} />
          </ChartWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};
