import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";
import { ILine, IYAxis } from "../../../../components/MultiLineChart";

export enum Lines {
  PR = "PR",
  Irradiation = "Irradiation",
}

export const yAxises: Array<IYAxis> = [
  {
    yAxisId: "PR",
    orientation: "left",
    domain: [0, 100],
  },
  {
    yAxisId: "Irradiation",
    orientation: "left",
    domain: [0, 1],
  },
];

export const lines: Array<ILine> = [
  {
    key: Lines.PR,
    stroke: GRAPH_COLORS.normal,
    yAxisId: "PR",
  },
  {
    key: Lines.Irradiation,
    stroke: GRAPH_COLORS.orange,
    yAxisId: "Irradiation",
  },
];
