import { Cell } from "../../../../shared/components/Table";


export enum SubsidiaryField {
  Name = "subsidiaryName",
  AdressLine1 = "adressLine1",
  AdressLine2 = "adressLine2",
  PostalCode = "postalCode",
  City = "city",
  Country = "country",
  OrgNumber = "orgNumber",
  RevenueAccountCode = "revenueAccountCode",
}

export interface ISubsidiaryForm {
  [SubsidiaryField.Name]: string;
  [SubsidiaryField.AdressLine1]: string;
  [SubsidiaryField.AdressLine2]: string;
  [SubsidiaryField.PostalCode]: string;
  [SubsidiaryField.City]: string;
  [SubsidiaryField.Country]: string;
  [SubsidiaryField.OrgNumber]: string;
  [SubsidiaryField.RevenueAccountCode]: string;
}

export interface ISubsidiary extends ISubsidiaryForm {
  id: number;
}

export class Subsidiary implements ISubsidiary {
  id: number;
  [SubsidiaryField.Name]: string;
  [SubsidiaryField.AdressLine1]: string;
  [SubsidiaryField.AdressLine2]: string;
  [SubsidiaryField.PostalCode]: string;
  [SubsidiaryField.City]: string;
  [SubsidiaryField.Country]: string;
  [SubsidiaryField.OrgNumber]: string;
  [SubsidiaryField.RevenueAccountCode]: string;
}

export enum commonFields {
  Entities = "entities",
  PageNumber = "pageNumber",
  TotalCount = "totalcount",
}

export class IPaginatedSubsidiary {
  [commonFields.Entities]: Array<Subsidiary>;
  [commonFields.PageNumber]: number;
  [commonFields.TotalCount]: number;
}

export interface InputProps {
  name: string;
  type: string;
  label?: string;
  subLabel?: string;
  multiline?: boolean;
  maxRows?: number;
  defaultValue?: string;
}

export enum Fields {
  SubsidiaryName = "subsidiaryName",
  AdressLine1 = "adressLine1",
  AdressLine2 = "adressLine2",
  PostalCode = "postalCode",
  City = "city",
  Country = "country",
  OrgNumber = "orgNumber",
  RevenueAccountCode = "revenueAccountCode",
}

const x1: Cell = {
  key: "firstName",
  label: "First name",
  align: "left",
  background:"#EFF0F0"
};

const x2: Cell = {
  key: "lastName",
  label: "Last name",
  align: "left",
  background:"#EFF0F0"
};
const x3: Cell = {
  key: "email",
  label: "Email",
  align: "left",
  background:"#EFF0F0"
};
const x4: Cell = {
  key: "jobTitle",
  label: "Job title",
  align: "left",
  background:"#EFF0F0"
};
const x5: Cell = {
  key: "edit",
  label: "",
  align: "left",
  background:"#EFF0F0"
};
const x6: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background:"#EFF0F0"
};

export const userViewHeaderCell = [x1, x2,x3,x4,x5,x6];
export const userViewTableType = "user";
export const filters=["firstName","lastName","email"]