import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { convertTimeZone, get24Hours } from "../../../../../shared/utils/utils";
import { ILine } from "../../../../components/MultiLineChart";
import { fetchAsync } from "../../../../../shared/utils/apiService";

const colorsArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

interface Item {
  blockId: number;
  inverterId: number;
  stringId: number;
  stringUnderPerformance: boolean;
  sumAvgSpecificCurrent: number;
  sumMaxSpecificCurrent: number;
  sumMinSpecificCurrent: number;
  sumSpecificCurrent: number;
  timeStamp: string;
}

interface Data {
  data: Array<any>;
  lines: Array<ILine>;
  equipmentType?: string;
  error: string;
  loading: boolean;
}

interface Props {
  blockId: string;
  inverterId: string;
  plantId: string;
  date: string;
}

export const useSpecificCurrent = ({
  blockId,
  inverterId,
  plantId,
  date,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [equipmentType, setEquipmentType] = useState("");
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);

  const getUnderperformingValues = (
    arr: Array<Item>
  ): Array<{ key: string; value: any }> => {
    const sortedData = arr.sort((a, b) =>
      a.sumSpecificCurrent > b.sumSpecificCurrent ? 1 : -1
    );

    return sortedData.map((item) => ({
      key: " " + item.stringId,
      value: item.sumSpecificCurrent,
    }));
  };

  const transformData = (data: Array<Item>, type: string) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }

    const initialData = new Array(24).fill(null).reduce((res, item, idx) => {
      const sensor_time = get24Hours(client?.timeZone, "HH:mm", idx);
      res[sensor_time] = { sensor_time };
      return res;
    }, {});

    const map = data?.reduce((res, curr) => {
      const timeLabel = convertTimeZone(
        client?.timeZone,
        "HH:mm",
        curr.timeStamp
      );
      res[timeLabel] = { ...res[timeLabel], [curr.stringId]: curr };

      return res;
    }, initialData);

    const sortedData = Object.keys(map)
      ?.sort()
      ?.map((item) => map[item]);
    const { sensor_time, ...otherFields } = sortedData.find(
      (item) => Object.keys(item)?.length > 1
    );

    const lines = Object.values(otherFields)?.map((item: Item, idx) => ({
      key: type + " " + item.stringId,
      stroke: colorsArray[idx],
    }));

    setLines(lines);

    const newData = sortedData.map((item) => {
      const lineValues: { [key: number]: number } = {};
      const { sensor_time, ...otherData } = item;
      Object.values(otherData).forEach((curr: Item) => {
        lineValues[type + " " + curr.stringId] = curr.sumSpecificCurrent;
      });

      return {
        sensor_time,
        values: getUnderperformingValues(Object.values(otherData)),
        ...lineValues,
      };
    });

    setData(newData);
    setLoading(false);
  };

  useEffect(() => {
    if (!inverterId || !blockId) return;

    setError("");
    setLoading(true);
    fetchAsync(
      `SkyfriSidemApi/v1/GetStringCurrent?date=${date}&blockId=${blockId}&inverterId=${inverterId}&plantId=${plantId}`,
      "GET"
    )
      .then((data: any) => {
        setEquipmentType(data?.type);
        transformData(data.data, data?.type);
      })
      .catch((e) => setError(e));
  }, [blockId, inverterId, plantId, date, client]);

  return {
    data,
    lines,
    equipmentType,
    error,
    loading,
  };
};
