import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, MenuItem, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import { ErrorMessage } from "../ErrorMessage";
import { SvgIcon, Select } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

export interface DropdownOption {
  label: string;
  value: any;
  subtitle?: string;
}

interface Props {
  value: any;
  options: Array<DropdownOption>;
  onChangeValue: (e: any) => void;
  label?: string;
  name?: string;
  width?: number | string;
  multiple?: boolean;
  showError?: boolean;
  errorMessage?: string;
  showAdd?: boolean;
  onAddNew?: (key: string) => void;
  disabled?: boolean;
  Icon?: any;
  marginRight?: string;
  border?: boolean;
  minWidth?: string;
  verticalAnchorOrigin?: number | "bottom" | "top" | "center"
  horizontalAnchorOrigin?: number | "left" | "right" | "center";
  verticalTransformOrigin?: number | "bottom" | "top" | "center";
  horizontalTransformOrigin?: number | "left" | "right" | "center";
  optionSubtitle?: boolean;
}

const useStyles = makeStyles((theme) => ({
  rootFirstSelect: {
    paddingLeft: "5px",
  },
  button: {
    minWidth: "100%",
    justifyContent: "start",
  },
  select: {
    fontWeight: 500,
    paddingLeft: "8px",
    fontSize: "16px",
    cursor: "pointer",
    "& .MuiSelect-selectMenu": {
      maxWidth: "160px",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    "@media (max-width: 490px)": {
      "& .MuiSelect-selectMenu": {
        maxWidth: "62px",
      },
    },
  },
  customBadge: {
    backgroundColor: "rgba(	225,228,233,0.7)",
  },
  dropdownInput: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E8ECF2",
      borderRadius: "5px",
      transition: "0.1s ease background-color",
    },
  },
  dropdownInputDisabled: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "default",
  },
  svgIcon: {
    position: "relative",
    top: "2px",
    left: "9px",
    cursor: "pointer",
  },
}));
export const SelectorDropdown: React.FC<Props> = ({
  value,
  onChangeValue,
  options,
  label,
  name = "",
  width,
  multiple,
  showError,
  errorMessage,
  showAdd,
  onAddNew,
  disabled,
  Icon = null,
  marginRight = "20px",
  border = false,
  minWidth = '',
  verticalAnchorOrigin = "bottom",
  horizontalAnchorOrigin = "center",
  verticalTransformOrigin = "top",
  horizontalTransformOrigin = "center",
  optionSubtitle = false

}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const renderOptions = (options) => {
    return options?.map((item, idx) => (
      <MenuItem key={item.label + idx} value={item.value} disabled={item?.disabled} >
        <div style={{ display: 'flex', flexDirection: "column", fontFamily: 'Inter', }}>
          <span>  {item.label} </span>
          <div style={{ fontWeight: 400, fontSize: "13px", color: "#858E99", maxWidth: "300px", whiteSpace: "break-spaces" }}>{item?.subtitle}</div>
        </div>
      </MenuItem>
    ));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 0, marginRight: marginRight }}>
      {!label ? null : (
        <InputLabel id="label" style={{ fontSize: 14 }}>
          {label}
        </InputLabel>
      )}
      <div className={disabled ? classes.dropdownInputDisabled : classes.dropdownInput}>
        {!Icon ? null : (
          <SvgIcon className={classes.svgIcon} color="primary">
            <Icon />
          </SvgIcon>
        )}
        <Select
          value={value}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={(e: any) => onChangeValue(e.target.value)}
          name={name}
          multiple={multiple}
          className={classes.select}
          variant="outlined"
          disabled={disabled}
          sx={{
            fontFamily: "Inter",
            minWidth: minWidth ? minWidth : "",
            "& .MuiOutlinedInput-notchedOutline": {
              border: border ? "1px solid #e0e3e8" : "none",
            },
          }}
          IconComponent={() =>
            open ? (
              <ExpandLessRoundedIcon
                sx={{ color: "#757575", cursor: "pointer" }}
              />
            ) : (
              <ExpandMoreRoundedIcon
                onClick={() => !disabled && setOpen(true)}
                sx={{ color: "#757575", cursor: disabled ? "default" : "pointer" }}
              />
            )
          }
          MenuProps={{
            anchorOrigin: {
              vertical: verticalAnchorOrigin,
              horizontal: horizontalAnchorOrigin,
            },
            transformOrigin: {
              vertical: verticalTransformOrigin,
              horizontal: horizontalTransformOrigin,
            },
            PaperProps: {
              style: {
                border: "1px solid var(--light-primary-gray-40, #E1E4E9)",
                borderRadius: "8px",
                boxShadow:
                  "rgb(0 0 0 / 0%) 0px 5px 5px 0px, rgb(0 0 0 / 0%) 0px 8px 10px 1px, rgb(0 0 0 / 10%) 0px 8px 24px 2px",
              },
            },
          }}
          inputProps={
            optionSubtitle ?
              {
                renderValue: (option) => {
                  const value = options?.find(item => {
                    if (item?.value === option)
                      return item?.label
                  })
                  return value?.label
                },
              }
              :
              null
          }
        >
          {options?.length === 0 ? (
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
          ) : (
            renderOptions(options)
          )}
          <div>
            {showAdd ? (
              <Button
                className={classes.button}
                onClick={() => onAddNew(name)}
                startIcon={<AddIcon />}
              >
                Add new
              </Button>
            ) : (
              ""
            )}
          </div>
        </Select>
      </div>
      <ErrorMessage showError={showError} errorMessage={errorMessage} />
    </FormControl>
  );
};
