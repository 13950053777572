import React, { useEffect, useRef } from "react";
import { GLOBAL_COLORS } from "../../../../../../shared/utils/utils";

interface BatteryProps {
  chargeLevel: number;
}

const OUTER_WIDTH = 116;
const OUTER_HEIGHT = 45;
const LINE_WIDTH = 2;
const START_POINT = 10;
const PADDING = 4;

const BORDER_RADIUS = 10;

const INNER_WIDTH = OUTER_WIDTH - PADDING * 2;
const INNER_HEIGHT = OUTER_HEIGHT - PADDING * 2;
const INNER_START_POINT = START_POINT + PADDING;

const drawBattery = (
  ctx: CanvasRenderingContext2D,
  chargePercentage: number
) => {
  // Draw wrapper
  ctx.beginPath();
  ctx.lineWidth = LINE_WIDTH;
  ctx.roundRect(
    START_POINT,
    START_POINT,
    OUTER_WIDTH,
    OUTER_HEIGHT,
    BORDER_RADIUS
  );

  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.moveTo(
    OUTER_WIDTH + START_POINT + LINE_WIDTH,
    (OUTER_HEIGHT - START_POINT) / 2 + START_POINT / 2
  );
  ctx.lineTo(OUTER_WIDTH + START_POINT + LINE_WIDTH, OUTER_HEIGHT);
  ctx.stroke();
  ctx.closePath();

  const barWidth = (chargePercentage / 100) * INNER_WIDTH;

  let backgroundColor = GLOBAL_COLORS.BATTERY_LOW;

  if (chargePercentage <= 30) {
    backgroundColor = GLOBAL_COLORS.BATTERY_LOW;
  } else if (chargePercentage <= 60) {
    backgroundColor = GLOBAL_COLORS.BATTERY_MEDIUM;
  } else {
    backgroundColor = GLOBAL_COLORS.BATTERY_HIGH;
  }

  ctx.fillStyle = backgroundColor;

  ctx.roundRect(
    INNER_START_POINT,
    INNER_START_POINT,
    barWidth,
    INNER_HEIGHT,
    BORDER_RADIUS
  );

  ctx.fill();
  ctx.fillStyle = GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40;

  for (let i = 1; i < 10; i++) {
    ctx.fillRect(
      INNER_START_POINT + (INNER_WIDTH / 10) * i,
      INNER_START_POINT + INNER_HEIGHT / 4,
      1,
      INNER_HEIGHT / 2
    );
  }
};

export const Battery: React.FC<BatteryProps> = ({ chargeLevel }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (ctx) {
      ctx.clearRect(0, 0, canvas!.width, canvas!.height);

      drawBattery(ctx, chargeLevel);
    }
  }, [chargeLevel]);

  return (
    <canvas
      title={`${chargeLevel.toFixed(1)}%`}
      ref={canvasRef}
      width={OUTER_WIDTH + START_POINT * 2}
      height={OUTER_HEIGHT + START_POINT * 2}
    />
  );
};
