import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    fields: {
        display: "grid",
        alignItems: "center",
        marginTop: "1em",
    },

    oneColumn: {
        gridTemplateColumns: "1fr",
    },

    field: {
        flex: 0.5,
        height: "5rem",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
    },
    fieldBottom: {
    },
    fieldRight: {
        height: "5rem",
    },

    fieldLabel: {
        fontSize: 14,
        color: "#707070",
        marginRight: theme.spacing(0.5),
    },

    oneColumnFieldLabel: {
        width: "50%",
    },

    fieldValue: {
        fontSize: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width: "max-content",
        maxWidth: "inherit",
    },

    growth: {
        marginLeft: theme.spacing(0.5),
        color: "#2D826B",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
    },

    growthIcon: {
        fontSize: "1.4em !important",
        marginBottom: 4,
    },

    negativeGrowth: {
        color: "#BC4F73",
    },

    divider: {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        height: 10,
        marginLeft: theme.spacing(0.5),
    },

    prevValue: {
        color: "#C94B4B",
    },
    value: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
    },
    prevvalue: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        marginRight: theme.spacing(3),
    },
    unit: {
        fontSize: 12,
        color: "#707070",
        marginLeft: theme.spacing(0.5),
    },
}));
