import React from 'react';
import { Stage, Layer, Image } from "react-konva";
import clsx from 'clsx';

import LayoutPanel from '../../../components/LayoutPanel';

import { ProfileData } from './interfaces';
import { useStyles } from './styles';
import { usePanels } from './usePanels';
import { Html } from "react-konva-utils";
import { useSelector } from 'react-redux';
import { clientSelector } from '../../../../features/Sidem/client/clientSlice';
import { PanelItem } from '../../../../features/Sidem/shared/sidemSharedApi.types';

interface Props {
  imageService: any;
  plantId: string;
  profileData: ProfileData;
}

export const LayoutView: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { client } = useSelector(clientSelector);

  const { imageService, plantId, profileData } = props;
  const { actualPR, invertersAv, invertersDown } = profileData;

  const { imageX, imageY, image, imageIsLoaded, activeAlerts, imageSize, panels } = usePanels({ imageService, plantId });

  const drawPanel = (panel: PanelItem) => {
    if (!panel?.centerCoords) return;

    return <LayoutPanel timeZone={client?.timeZone} key={panel.key} imageX={imageX} imageY={imageY} panel={panel} />
  };

  return (
    <>
      {imageSize.width > 0 && imageSize.height > 0 && (

        <Stage width={imageSize.width} height={imageSize.height} style={{ overflow: 'auto' }}>

          <Layer>
            <Html>
              <div className={classes.buttons} style={{ top: `0px`, marginLeft: `1em`, marginTop: `1em` }}>
                <div className={classes.button}>PR: {(actualPR * 100)?.toFixed(2)}%</div>
                <div className={clsx(classes.button, classes.invertersBtn, { [classes.errorBtn]: invertersDown > 0 })}>
                  Inverters: {invertersAv - invertersDown}/{invertersAv}
                </div>
                <div className={clsx(classes.button, classes.invertersBtn)}>Incidents: {activeAlerts?.length}</div>
              </div>
            </Html>
            {!imageIsLoaded ? null : (
              <Image image={image} x={imageX} y={imageY} width={imageSize.width} height={imageSize.height} />
            )}
            {panels.map(drawPanel)}
          </Layer>
        </Stage>
      )}
    </>
  )
}
