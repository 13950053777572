import { combineReducers } from "@reduxjs/toolkit";
import SidemReducer from "../features/Sidem/sidemReducer";
import { errorReducer } from "../features/Notifications/errorSlice";
import MonitoringReducer from "../features/Monitoring/monitoringReducer";
import OnboardingReducer from "../features/Onboarding/onBoardingReducer";
import { onBoardingApi } from "../features/Onboarding/onBoardingApi";
import AnalyticsReducer from "../features/Analytics/analyticsReducer";
import { plantSiteInfoApi } from "../features/siteInfo/PlantSiteInfo/plantSiteInfoApi";
import { monitoringSharedApi } from "../features/Monitoring/shared/monitoringSharedApi";
import { monitoringDashboardApi } from "../features/Monitoring/monitoringDashboard/monitoringDashboardApi";
import { monitoringInverterPerformanceApi } from "../features/Monitoring/inverterPerformance/inverterPerformanceApi";
import { sidemSharedApi } from "../features/Sidem/shared/sidemSharedApi";
import { batteryMonitoringApi } from "../monitoring/pages/MonitoringDashboard/services/BatteryMonitoringApi";
import { batteryOverviewApi } from "../monitoring/pages/BatteryMonitoring/BatteryMonitoring.api";

const rootReducer = combineReducers({
  Monitoring: MonitoringReducer,
  Sidem: SidemReducer,
  Analytics: AnalyticsReducer,
  error: errorReducer,
  Onboarding: OnboardingReducer,
  [plantSiteInfoApi.reducerPath]: plantSiteInfoApi.reducer,
  [onBoardingApi.reducerPath]: onBoardingApi.reducer,
  [monitoringSharedApi.reducerPath]: monitoringSharedApi.reducer,
  [monitoringDashboardApi.reducerPath]: monitoringDashboardApi.reducer,
  [monitoringInverterPerformanceApi.reducerPath]:
    monitoringInverterPerformanceApi.reducer,
  [sidemSharedApi.reducerPath]: sidemSharedApi.reducer,
  [batteryMonitoringApi.reducerPath]: batteryMonitoringApi.reducer,
  [batteryOverviewApi.reducerPath]: batteryOverviewApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
