import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  containerPadding: {
    padding: theme.spacing(2),
  },
  closeButton: {
    cursor: "pointer",
    paddingTop: theme.spacing(0.6)
  },
  title: {
    fontSize: "22px",
    fontWeight: 500,
  },
  kpi:{
    color:"#0C1E33",
    fontWeight:500,
    fontSize:15,
    paddingTop:theme.spacing(1),
  },
  kpiName:{
    paddingTop:theme.spacing(1),
  },
  line: {
    color:"#E1E4E9",
    border: "none",
    height: "2px",
    backgroundColor: "#E1E4E9",
  },
  lightText:{
    color: "#B6C0CC"
  },
  growth: {
    marginLeft: theme.spacing(0.5),
    color: "#2D826B",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },

  growthIcon: {
    fontSize: "1.4em !important",
    marginBottom: 4,
  },

  negativeGrowth: {
    color: "#BC4F73",
  },

}));