import { Cell } from "../../../../shared/components/Table";

const x2: Cell = {
  key: "tenantName",
  label: "Tenant Name",
  align: "left",
  background: "#EFF0F0",
};

const x3: Cell = {
  key: "edit",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const tenantViewHeaderCell = [x2, x3];
export const tenantViewTableType = "tenant";
export const tenantFilters = ["tenantName"];

const x4: Cell = {
  key: "moduleName",
  label: "Module",
  align: "left",
  background: "#EFF0F0",
};

const x5: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const moduleTenantViewHeaderCell = [x4, x5];
export const moduleTenantViewTableType = "tenant";
export const moduleFilters = ["moduleName"];

const x6: Cell = {
  key: "portfolioName",
  label: "Portfolio name",
  align: "left",
  background: "#EFF0F0",
};

const x6_1: Cell = {
  key: "module",
  label: "Module",
  align: "left",
  background: "#EFF0F0",
};

const x7: Cell = {
  key: "portfolioDelete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

const x7_1: Cell = {
  key: "portfolioEdit",
  label: "",
  align: "left",
  background: "#EFF0F0",
};
//
export const portfolioViewHeaderCell = [x6, x6_1, x7_1, x7];
export const portfolioViewTableType = "portfolio";
export const portfolioFilters = ["portfolioName"];

const x8: Cell = {
  key: "userName",
  label: "Name",
  align: "left",
  color: "red !important",
  // background: "#EFF0F0",
};

const x8_1: Cell = {
  key: "userEmail",
  label: "E-mail",
  align: "left",
  // background: "#EFF0F0",
};

const x8_2: Cell = {
  key: "userRoles",
  label: "Role",
  align: "left",
  // background: "#EFF0F0",
};

// const x9: Cell = {
//   key: "plantsVisibility",
//   label: "Plants Visibility",
//   align: "left",
//   // background: "#EFF0F0",
// };

const x10_1: Cell = {
  key: "userEditDelete",
  label: "",
  align: "right",
  // background: "#EFF0F0",
};

// const x10: Cell = {
//   key: "delete",
//   label: "",
//   align: "left",
//   // background: "#EFF0F0",
// };

export const userTenantViewHeaderCell = [x8, x8_1, x8_2, x10_1];
export const userTenantViewTableType = "tenantusers";
export const userTenantFilters = ["userName", "tenantName"];
export const teamFilters = ["teamName"];

const x11: Cell = {
  key: "plantName",
  label: "Plant Name",
  align: "left",
  background: "#EFF0F0",
};

const x11_1: Cell = {
  key: "plantCode",
  label: "Plant code",
  align: "left",
  background: "#EFF0F0",
};

const x11_2: Cell = {
  key: "plantTimeZone",
  label: "Plant Time Zone",
  align: "left",
  background: "#EFF0F0",
};

const x12: Cell = {
  key: "edit",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

const x12_1: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const plantViewHeaderCell = [x11, x11_1, x11_2, x12, x12_1];
export const plantViewTableType = "plant";
export const plantFilters = ["plantName"];

const x13: Cell = {
  key: "roleName",
  label: "Role",
  align: "left",
  background: "#EFF0F0",
};

const x14: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const userRoleViewHeaderCell = [x13, x14];
export const userRoleViewTableType = "usersRoles";
export const roleFilters = ["roleName"];

const x15: Cell = {
  key: "teamName",
  label: "Team Name",
  align: "left",
  background: "#EFF0F0",
};

const x16: Cell = {
  key: "edit",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

const x17: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const teameViewHeaderCell = [x15, x16, x17];

const x18: Cell = {
  key: "firstName",
  label: "First name",
  align: "left",
  background: "#EFF0F0",
};
const x19: Cell = {
  key: "lastName",
  label: "Last name",
  align: "left",
  background: "#EFF0F0",
};
const x20: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};
export const TeamMembersViewHeaderCell = [x18, x19, x20];
export const teamMemberFilters = ["firstName", "lastName"];

const x21: Cell = {
  key: "name",
  label: "Name",
  align: "left",
  background: "#EFF0F0",
};

const x22: Cell = {
  key: "edit",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

const x23: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};
export const RoleViewHeaderCell = [x21, x22, x23];

export const rolePermissionFilters = ["name"];

const x24: Cell = {
  key: "entityName",
  label: "Screen name",
  align: "left",
  background: "#EFF0F0",
};

const x25: Cell = {
  key: "permission",
  label: "Permission",
  align: "left",
  type: "checkbox",
  background: "#EFF0F0",
};

export const PermissionViewHeaderCell = [x24, x25];
export const permissionFilters = ["entityName"];
const x26: Cell = {
  key: "name",
  label: "Team name",
  align: "left",
  background: "#EFF0F0",
};

const x27: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};

export const TeamViewHeaderCell = [x26, x27];

export const teamRoleFilters = ["name"];

const x28: Cell = {
  key: "plantName",
  label: "Plant name",
  align: "left",
  background: "#EFF0F0",
};
const x29: Cell = {
  key: "portfolioName",
  label: "Portfolio name",
  align: "left",
  background: "#EFF0F0",
};

const x30: Cell = {
  key: "delete",
  label: "",
  align: "left",
  background: "#EFF0F0",
};
export const TeamPlantsViewHeaderCell = [x28, x29, x30];

export const teamPlantFilters = ["plantName", "portfolioName"];
