import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetInverterHeatmap } from "../../../../../features/Sidem/portfolios/plants/plantsActions";
import { autoRefreshApi } from "../../../../../shared/utils/utils";
interface Item {
  actualPR: number;
  pR_Delta: number;
  equipmentName: any;
  clientName: string;
  inverterName: string;
  plantId: string;
  specificPower: number;
  stringName: string;
}
interface Props {
  plantId: string;
  date: string;
}
interface Data {
  data: Array<Array<number>>;
  error: string;
  loading: boolean;
  xLabels: Array<string>;
  yLabels: Array<string>;
  popUpData: Array<Array<object>>;
}

export const useHeatmap = ({ plantId, date }: Props): Data => {
  const [data, setData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [popUpData, setPopUpData] = useState([]);

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }
    setLoading(true);

    const tempData = data?.map(
      (item) => Math.round(item?.actualPR * 100) / 100
    );
    const tempLabels = data?.map((item) => item.equipmentName);

    let dataArr = [];
    while (tempData?.length) {
      dataArr.push(tempData?.splice(0, 20));
    }
    let labelArr = [];
    while (tempLabels?.length) {
      labelArr.push(tempLabels?.splice(0, 20));
    }
    let popUpArr = [];

    while (data?.length) {
      popUpArr.push(data?.splice(0, 20));
    }
    setData(dataArr);
    setYLabels([""]);
    setXLabels(labelArr);
    setPopUpData(popUpArr);
    setLoading(false);
    setError("");
  };

  const dispatch = useDispatch();

  const fetchData = async () => {
    const res: any = await dispatch(GetInverterHeatmap(plantId, date));
    if (!res || !res.length) {
      setData([""]);
      setYLabels([""]);
      setXLabels([]);
      setPopUpData([""]);
      setLoading(false);
      setError("No data");
    }
    transformData(res);
  };

  useEffect(() => {
    if (!date) {
      setLoading(true);
      return;
    }

    setLoading(true);
    setError("");
    fetchData();
    const myInterval = autoRefreshApi(() => fetchData());
    return () => clearInterval(myInterval);
  }, [plantId, date]);

  return {
    data,
    xLabels,
    yLabels,
    error,
    loading,
    popUpData,
  };
};
