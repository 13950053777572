import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
import { DropdownOption } from "../../../analytics/components/interfaces";
// AnalyticsShared
export interface IAnalyticsSharedState {

    globalDate?: {
        startDate: Date | string;
        endDate: Date | string;
        key: string;
    }[];
    dateEqual?: boolean;
    globalSelectedPlant?: DropdownOption[];
    globalSelectedPortfolio?: DropdownOption[];
    liveButton?: boolean;
    accordionExpand?: boolean;
    multiplePlants?: boolean;
}

const initialState: IAnalyticsSharedState = {
    globalDate: [{
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        key: "selection",
    }],
    dateEqual: true,

    globalSelectedPlant: null,

    globalSelectedPortfolio: null,

    liveButton: true,

    accordionExpand: false,

    multiplePlants: false,
};

// name
// reducer
// actions
// caseReducers

// All of the states which are shared between analytics pages

export const analyticsSharedSlice = createSlice({
    name: "analyticsshared",
    initialState,
    reducers: {

        setGlobalDate: (state, { payload }) => {
            return {
                ...state,
                globalDate: payload,
            };
        },

        dateComparison: (state, { payload }) => {
            return {
                ...state,
                dateEqual: payload,
            };
        },

        setLiveButton: (state, { payload }) => {
            return {
                ...state,
                liveButton: payload,
            };
        },

        getPlantId: (state, { payload }) => {
            return {
                ...state,
                globalSelectedPlant: payload,
            };
        },

        getPortfolioName: (state, { payload }) => {
            return {
                ...state,
                globalSelectedPortfolio: payload,
            };
        },

        setAccordionExpand: (state, { payload }) => {
            return {
                ...state,
                accordionExpand: payload,
            };
        },
        setMultiplePlants: (state, { payload }) => {
            return {
                ...state,
                multiplePlants: payload,
            };
        },
    },
});

// AnalyticsShared actions
export const {
    setGlobalDate,
    dateComparison,
    getPlantId,
    getPortfolioName,
    setLiveButton,
    setAccordionExpand,
    setMultiplePlants,
} = analyticsSharedSlice.actions;

// AnalyticsShared state

export const analyticsSharedSelector = (state: RootState) =>
    state.Analytics.analyticsShared;

// AnalyticsShared reducer

export const analyticsSharedReducer = analyticsSharedSlice.reducer;