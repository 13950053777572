import React, { useMemo, useCallback, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import posthog from "posthog-js";
import { createTheme, ThemeProvider } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { CookieBanner } from "./CookieBanner";
import {
  AuthError,
  EventMessage,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import { PagesRoles, Roles, RolesMap } from "../shared/interfaces";
import { SidemPages } from "../sidem";
import { OnboardingPages } from "../onBoarding";
import { useSharedFormState } from "./../shared/utils/sharedStates";
import { LOCALSTORAGEKEYS, ROLES } from "./constants/constant";
import Navigation from "./navigation";
import "./index.css";
import { MonitoringPages } from "../monitoring";
import { AnalyticsPages } from "../analytics";
import { SiteInfoPages } from "../siteInfo";
import {
  getBearerToken,
  getEmailFromToken,
  getScope,
  parseJwt,
} from "../store/authToken";
import { CustomError } from "./ErrorFallback/ErrorFallback.component";

const PASSWORD_RESET_ERROR_CODE = "AADB2C90118";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#0C1E33", //Need to define Skyfri color
      light: "#fff",
      dark: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#EFF0F0", // Need to define Skyfri color
    },
    info: {
      main: "#0C1E33",
      light: "#fff",
    },
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: ["Inter", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#424242", //Need to define Skyfri color
      light: "#424242",
      dark: "#fff",
    },
    secondary: {
      main: "#424242", // Need to define Skyfri color
    },
    info: {
      main: "#424242",
      light: "#fff",
    },
  },
});

interface AppProps {
  apiURL: string;
  apimKey: string;
  scope: Array<string>;
}

const getRoles = async (instance): Promise<RolesMap> => {
  let roles = {
    [Roles.MONITORING]: false,
    [Roles.BATTERY_MONITORING]: false,
    [Roles.SIDEM]: false,
    [Roles.OPERATIONS]: false,
    [Roles.CMMS]: false,
    [Roles.BILLING]: false,
    [Roles.ONBOARDING]: false,
    [Roles.COMMERCIAL]: false,
    [Roles.ANALYTICS]: false,
  };

  try {
    var token = await getBearerToken();

    const tokenContent = parseJwt(token);
    const rolesArr = tokenContent?.extension_roles?.split(",") || [];
    const email = getEmailFromToken(tokenContent);
    const fullName = tokenContent?.name.toString();

    if (email?.length > 0 && fullName?.length > 0) {
      posthog.identify(email, { name: fullName, email: email });
    }

    if (rolesArr.length <= 0) {
      instance.logoutRedirect().catch((e) => console.log(e));
    }

    if (rolesArr?.includes("SkyfriAdmin")) {
      rolesArr.push(Roles.ONBOARDING);
    }

    rolesArr?.forEach((item: Roles) => {
      roles[item] = true;
    });
  } catch (e) {
    console.log(e, roles);
    return roles;
  }
  return roles;
};

const ErrorComponent = ({ error }) => {
  const parsedError = error.toString();

  // password reset is beign treated as an error by MSAL, so we basically don't want to show an error which is actually not an error
  if (parsedError.includes(PASSWORD_RESET_ERROR_CODE)) {
    return null;
  }

  return <p>An Error Occurred: {parsedError}</p>;
};

const LoadingComponent = () => (
  <Box className="auth-container">
    <CircularProgress />
    <div className="auth-text">Authentication in progress...</div>
  </Box>
);

const App: React.FC<AppProps> = () => {
  const { instance, accounts } = useMsal();
  const { lightTheme } = useSharedFormState();
  const [tenant, setTenant] = useState<string | undefined>(undefined);
  const [roles, setRoles] = useState({} as RolesMap);

  const [scope, setScope] = useState(
    localStorage.getItem(LOCALSTORAGEKEYS.SCOPE)
      ? localStorage.getItem(LOCALSTORAGEKEYS.SCOPE)
      : getScope()
  );

  const checkUserData = () => {
    const item = localStorage.getItem(LOCALSTORAGEKEYS.SCOPE);
    if (item) {
      setScope(item);
    }
  };

  useEffect(() => {
    const newTenant = accounts[0]?.idTokenClaims?.extension_tenantName;
    if (newTenant !== undefined) {
      setTenant(newTenant as string);
    }
  }, [accounts]);

  useEffect(() => {
    instance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_FAILURE && message.error) {
        const error = message.error as AuthError;

        if (
          error.errorMessage &&
          error.errorMessage.includes(PASSWORD_RESET_ERROR_CODE)
        ) {
          instance.loginRedirect({
            authority: process.env.REACT_APP_RESET_PASSWORD_AUTHORITY,
            scopes: ["openid", "profile"],
          });
        }
      }
    });

    window.addEventListener("storage", checkUserData);

    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, [instance]);

  useEffect(() => {
    if (!instance || accounts.length <= 0 || !scope) {
      return;
    }
    getRoles(instance).then((roles) => setRoles(roles));
  }, [instance, accounts, scope]);

  const imageService = useCallback(
    !process.env.REACT_APP_IMAGE_URL
      ? null
      : (url: string) =>
        `${process.env.REACT_APP_IMAGE_URL}/${url}?${process.env.REACT_APP_IMAGE_TOKEN}`,
    [process.env.REACT_APP_IMAGE_URL, process.env.REACT_APP_IMAGE_TOKEN]
  );

  const imageServiceCMMS = useCallback(
    !process.env.REACT_APP_IMAGE_URL_CMMS
      ? null
      : (url: string) =>
        `${process.env.REACT_APP_IMAGE_URL_CMMS}/${url}?${process.env.REACT_APP_IMAGE_TOKEN_CMMS}`,
    [
      process.env.REACT_APP_IMAGE_URL_CMMS,
      process.env.REACT_APP_IMAGE_TOKEN_CMMS,
    ]
  );

  const authRequest = {
    scopes: ["openid", "profile"],
  };

  const content = useMemo(() => {
    if (!Object.keys(roles)?.length || !imageService || !imageServiceCMMS) {
      return null;
    }

    const SkyfriDemoRole = roles[PagesRoles.SKYFRIDEMO];
    const BatteryMonitoringRole = roles[Roles?.BATTERY_MONITORING];

    return (
      <Navigation roles={roles} tenant={tenant}>
        <SiteInfoPages imageService={imageService} />

        {!Object.keys(roles).some((r) => roles[r] === true) && (
          <CustomError
            title={"Missing access rights"}
            errorMessage={
              "Please contact your Skyfri administrator to get access"
            }
          ></CustomError>
        )}

        {!roles[Roles.MONITORING] ? null : (
          <MonitoringPages
            SkyfriDemoRole={SkyfriDemoRole}
            imageService={imageService}
            tenant={tenant}
            BatteryMonitoringRole={BatteryMonitoringRole}
          />
        )}

        {!roles[Roles.ANALYTICS] ? null : (
          <SidemPages
            imageService={imageService}
            SkyfriDemoRole={SkyfriDemoRole}
          />
        )}

        {!roles[Roles.ANALYTICS] ? null : (
          <AnalyticsPages
            imageService={imageService}
            SkyfriDemoRole={SkyfriDemoRole}
            tenant={tenant}
          />
        )}

        {!roles[Roles.ONBOARDING] ? null : <OnboardingPages />}
      </Navigation>
    );
  }, [roles, imageService]);

  return (
    <ThemeProvider theme={lightTheme ? darkTheme : theme}>
      <UnauthenticatedTemplate>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
        ></MsalAuthenticationTemplate>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <BrowserRouter>{content}</BrowserRouter>
        {posthog.has_opted_out_capturing() ||
          posthog.has_opted_in_capturing() ? null : (
          <CookieBanner />
        )}
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
};

export default App;
