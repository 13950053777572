import { convertTime, isAnyItemFalsy } from "./../../../shared/utils/utils";
import { useAppSelector } from "./../../../store/rootStore";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryTemperatureHistoricalQuery,
  useGetBatteryTemperatureLiveQuery,
} from "./BatteryMonitoring.api";
import { monitoringDashboardSelector } from "../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useMemo, useState } from "react";
import { useUrlParams } from "../../../shared/utils/hooks";
import { getUnit, getValue } from "./utils";

export const useBatteryTemperatureWidget = () => {
  const { plantId, startDate, endDate } = useUrlParams({
    plantId: "",
    startDate: "",
    endDate: "",
  });

  const fromDate = useMemo(() => {
    return convertTime(startDate, "YYYY.MM.DD");
  }, [startDate]);

  const toDate = useMemo(() => {
    return convertTime(endDate, "YYYY.MM.DD");
  }, [endDate]);

  const [activeScreen, setActiveScreen] = useState("battery");

  const { globalPortfolioName, liveButton } = useAppSelector(
    monitoringDashboardSelector
  );

  const {
    data: dataLive,
    isLoading: isLoadingLive,
    isFetching: isFetchingLive,
    isError: isErrorLive,
    isUninitialized: isUninitializedLive,
  } = useGetBatteryTemperatureLiveQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
    },
    {
      skip: isAnyItemFalsy([plantId, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const {
    data: dataHistorical,
    isLoading: isLoadingHistorical,
    isFetching: isFetchingHistorical,
    isError: isErrorHistorical,
    isUninitialized: isUninitializedHistorical,
  } = useGetBatteryTemperatureHistoricalQuery(
    {
      plantId,
      equipmentName: DEFAULT_EQUIPMENT_NAME,
      fromDate,
      toDate,
    },
    {
      skip: isAnyItemFalsy([plantId, DEFAULT_EQUIPMENT_NAME]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  const resultLive = useMemo(() => {
    const res = {
      options: {
        tabs: {
          active: activeScreen,
          onChange: (s: string) => {
            setActiveScreen(s);
          },
          values: [
            { key: "battery", label: "Battery pack" },
            { key: "cabinet", label: "Cabinet" },
          ],
        },
      },
      isLoading: isLoadingLive || isUninitializedLive,
      isFetching: isFetchingLive,
      isError: isErrorLive,
    };

    if (dataLive) {
      const parsedData = {
        top: {
          battery: [
            {
              label: "Current highest temp.",
              value: getValue(dataLive?.battery?.highest),
              unit: getUnit(dataLive?.battery?.highest),
            },
            {
              label: "Current lowest temp.",
              value: getValue(dataLive?.battery?.lowest),
              unit: getUnit(dataLive?.battery?.lowest),
            },
          ],
          cabinet: [
            {
              label: "Current ambient temp.",
              value: getValue(dataLive?.cabinet?.ambient),
              unit: getUnit(dataLive?.cabinet?.ambient),
            },
            {
              label: "Current cabinet temp.",
              value: getValue(dataLive?.cabinet?.cabinet),
              unit: getUnit(dataLive?.cabinet?.cabinet),
            },
          ],
        },
        bottom: {
          battery: [
            {
              label: "Max battery pack temperature",
              value: getValue(dataLive?.battery?.max),
              unit: getUnit(dataLive?.battery?.max),
            },
            {
              label: "Min battery pack temperature",
              value: getValue(dataLive?.battery?.min),
              unit: getUnit(dataLive?.battery?.min),
            },
          ],
          cabinet: [
            {
              label: "Max cabinet temperature",
              value: getValue(dataLive?.cabinet?.max),
              unit: getUnit(dataLive?.cabinet?.max),
            },
            {
              label: "Min cabinet temperature",
              value: getValue(dataLive?.cabinet?.min),
              unit: getUnit(dataLive?.cabinet?.min),
            },
          ],
        },
      };

      res.options = { ...res.options, ...parsedData };
    }

    return res;
  }, [
    activeScreen,
    dataLive,
    isLoadingLive,
    isUninitializedLive,
    isFetchingLive,
    isErrorLive,
  ]);

  const resultHistorical = useMemo(() => {
    const res = {
      options: {
        tabs: {
          active: activeScreen,
          onChange: (s: string) => {
            setActiveScreen(s);
          },
          values: [
            { key: "battery", label: "Battery pack" },
            { key: "cabinet", label: "Cabinet" },
          ],
        },
      },
      isLoading: isLoadingHistorical || isUninitializedHistorical,
      isFetching: isFetchingHistorical,
      isError: isErrorHistorical,
    };

    if (dataHistorical) {
      const parsedData = {
        top: {
          battery: [
            {
              label: "Avg. temperature",
              value: getValue(dataHistorical?.battery?.avg),
              unit: getUnit(dataHistorical?.battery?.avg),
            },
          ],
          cabinet: [
            {
              label: "Avg. ambient temp.",
              value: getValue(dataHistorical?.cabinet?.avgAmbient),
              unit: getUnit(dataHistorical?.cabinet?.avgAmbient),
            },
            {
              label: "Avg. cabinet temp.",
              value: getValue(dataHistorical?.cabinet?.avgCabinet),
              unit: getUnit(dataHistorical?.cabinet?.avgCabinet),
            },
          ],
        },
        bottom: {
          battery: [
            {
              label: "Max battery pack temperature",
              value: getValue(dataHistorical?.battery?.max),
              unit: getUnit(dataHistorical?.battery?.max),
            },
            {
              label: "Min battery pack temperature",
              value: getValue(dataHistorical?.battery?.min),
              unit: getUnit(dataHistorical?.battery?.min),
            },
          ],
          cabinet: [
            {
              label: "Max cabinet temperature",
              value: getValue(dataHistorical?.cabinet?.maxCabinet),
              unit: getUnit(dataHistorical?.cabinet?.maxCabinet),
            },
            {
              label: "Min cabinet temperature",
              value: getValue(dataHistorical?.cabinet?.minCabinet),
              unit: getUnit(dataHistorical?.cabinet?.minCabinet),
            },
          ],
        },
      };

      res.options = { ...res.options, ...parsedData };
    }

    return res;
  }, [
    activeScreen,
    dataHistorical,
    isLoadingHistorical,
    isUninitializedHistorical,
    isFetchingHistorical,
    isErrorHistorical,
  ]);

  return liveButton ? resultLive : resultHistorical;
};

export type TemperatureWidgetData = ReturnType<
  typeof useBatteryTemperatureWidget
>;
