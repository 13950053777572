import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { Textfield, Typography } from "../../../../../shared/components";

interface Props {
  name: string;
  type: string;
  label?: string;
  subLabel?: string;
  multiline?: boolean;
  maxRows?: number;
  defaultValue?: string;
  formik: any;
  width?: string
  info?: string;
  disabled?: boolean;
}

export const RenderTextfield: React.FC<Props> = (props) => (
  <>
    <Typography display={"flex"} label={props.label} subLabel={props.subLabel}>
      {
        props.info ?
          <Tooltip title={props.info}>
            <IconButton color="primary" aria-label="upload picture" component="span">
              <InfoIcon />
            </IconButton>
          </Tooltip>

          : ""
      }
    </Typography>
    <Textfield
      disabled={props.disabled}
      name={props.name}
      type={props.type}
      width={props.width}
      value={props.formik.values[props.name]}
      defaultValue=""
      rows={props?.maxRows}
      onChange={props.formik.handleChange}
      error={
        props.formik?.touched[props.name] && Boolean(props.formik.errors[props.name])
      }
      helperText={props.formik?.touched[props.name] && props.formik.errors[props.name]}
    />
  </>
);
