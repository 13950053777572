import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    widgetStyle: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(0.5, 2, 2, 2),
        borderTopLeftRadius: theme.spacing(0),
        borderTopRightRadius: theme.spacing(0),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },
    selector: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        margin: 5,
        paddingBottom: theme.spacing(1),

    },
    heading: {
        fontWeight: 700,
        fontSize: "16px",
        paddingBottom: theme.spacing(1),
    },
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: theme.spacing(2, 2, 2, 2),
        borderRadius: theme.spacing(1),
        marginTop: "8px",
    },
    mainHeading: {
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "26.4px",
        marginBottom: "20px",
    },
}));