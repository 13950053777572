import React, { useState, useEffect } from "react";
import { useRole } from "./useRole";
import { Card } from "@material-ui/core";
import { Search, Modal } from "../../../shared/components";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import Loader from "../../components/shared/Loader";
import { useStyles } from "./styles";
import { searchData } from "../components/Utils/utils";
import { Snackbar } from "../../../shared/components/Snackbar";
import { roleViewHeaderCell, roleViewTableType, filters, } from "./components/interfaces";
import { useNavigate } from "react-router-dom";
import { Button2 } from "../../../shared/components/Button2/Button2.component";
import { Divider } from "@mui/material";

export const Role: React.FC = () => {
  const classes = useStyles();
  const { role, deleteRoles, loading } = useRole();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) return;
    if (search && search !== "") {
      setFilteredData(searchData(search, role, filters));
    } else {
      setFilteredData(role);
    }
  }, [search, role]);

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };

  const onNewRole = () => {
    navigate(`/onboarding/role/new`);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteClick = (id) => {
    setSelectedId(id);
    setOpenDeleteModal(true);
  };

  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>Roles</div>
      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2 size={40} type="tertiary" onClick={onCloseDeleteModal}>
                  Cancel
                </Button2>
              </div>
              <div style={{ marginLeft: "8px" }}>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    deleteRoles(selectedId, (resp) => {
                      setSearch("");
                      if (resp?.ok === false) {
                        setSeverity("error");
                        setMessage("Operation unsuccessfull");
                        setIsOpen(true);
                      } else {
                        setSeverity("success");
                        setMessage("Record deleted successfully");
                        setIsOpen(true);
                      }
                    });
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
          </>
        }
      />
      <Card style={{ padding: "20px" }}>
        <br />
        {loading ? (
          <>
            <CustomTable
              tableType={roleViewTableType}
              headerCells={roleViewHeaderCell}
              data={[]}
              onDeleteClick={(id) => deleteClick(id)}
            />
            <Loader
              loading={loading}
              height={300}
              error={""}
              errorTextSize={32}
            />
          </>
        ) : (
          <>
            <div style={{ maxWidth: "400px" }}>
              <Search
                placeholder="Search for role"
                value={search}
                onChange={searchHandler}
                onKeyUp={searchHandler}
              />
            </div>
            <br />
            <CustomTable
              module={"/onboarding/"}
              tableType={roleViewTableType}
              maxHeight={"76vh"}
              headerCells={roleViewHeaderCell}
              data={filteredData}
              onDeleteClick={(id) => deleteClick(id)}
            />
          </>
        )}
      </Card>
    </div>
  );
};
