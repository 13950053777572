import { useEffect, useState } from "react";
import { convertTimeZone } from "../../../../../shared/utils/utils";
import { SimpleLineChartItem } from "../../../../components/SimpleLineChart";
import { useSharedFormState } from "../../../../../shared/utils/sharedStates";
import { useDispatch, useSelector } from "react-redux";
import { getTimeFramePerformanceRatio } from "../../../../../features/Sidem/portfolios/plants/plantsActions";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  actualPerformanceRatio: number;
  alarms: Array<any>;
  averageDeltaPR: number;
  deltaPR: number;
  expectedPerformanceRatio: number;
  numberOfAlarms: number;
  timeStamp: string;
  actualAvailability: number;
}

const minute = 1000 * 60;
const hour = minute * 60;
export const label = "actual_pr";
export const label1 = "expected_pr";
const label2 = "availability";
const label3 = "active_incidents";
const label4 = "daily_pr_delta";

interface Props {
  plantId: string;
  date: string;
}

export interface RealtimeItem<T> {
  data?: T;
  result?: number;
  loading?: boolean;
  error?: string;
}

interface Data {
  yesterday: RealtimeItem<SimpleLineChartItem>;
  last60minutes: RealtimeItem<SimpleLineChartItem>;
  last24Hours: RealtimeItem<Array<SimpleLineChartItem>>;
  last7Days: RealtimeItem<Array<SimpleLineChartItem>>;
  last30Days: RealtimeItem<Array<SimpleLineChartItem>>;
  realTime: RealtimeItem<SimpleLineChartItem>;
}

export const useRealtimePerformance = ({
  plantId,
  date,
}: Props): Data => {
  const { client } = useSelector(clientSelector);

  const [realTime, setRealTime] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<SimpleLineChartItem>);
  const [last60minutes, setLast60Minutes] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<SimpleLineChartItem>);
  const [last24Hours, setLast24Hours] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<Array<SimpleLineChartItem>>);
  const [yesterday, setYesterday] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<SimpleLineChartItem>);
  const [last7Days, setLast7Days] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<Array<SimpleLineChartItem>>);
  const [last30Days, setLast30Days] = useState({
    data: null,
    result: undefined,
    loading: false,
    error: "",
  } as RealtimeItem<Array<SimpleLineChartItem>>);

  const transformData = (item: Item): SimpleLineChartItem => ({
    name: convertTimeZone(client?.timeZone, "D.M.YY HH:mm", item?.timeStamp),
    [label]: item.actualPerformanceRatio,
    [label1]: item.expectedPerformanceRatio,
    [label2]: item.actualAvailability,
    [label3]: item.alarms?.length || "NA",
    [label4]: item.deltaPR,
  });

  const { automaticRefresh } = useSharedFormState();

  const makeBulletChartRequest = (
    url: string,
    setData: (data: RealtimeItem<SimpleLineChartItem>) => void,
    interval?: number
  ) => {
    setData({
      loading: true,
    });

    fetchAsync(`SkyfriSidemApi/v1/${url}`, "GET")
      .then((data: Item | Array<Item>) => {
        const item: Item = Array.isArray(data) ? data[0] : data;
        if (item.actualPerformanceRatio) {
          setData({
            data: transformData(item),
            result:
              (item.actualPerformanceRatio - item.expectedPerformanceRatio) *
              100,
            loading: false,
          });
        } else {
          setData({
            error: "No actual performance ratio",
          });
        }
      })
      .catch((error) => setData({ error }));

    if (interval && automaticRefresh) {
      return setInterval(() => {
        makeBulletChartRequest(url, setData);
      }, interval);
    }
  };

  const sortByTimeStamp = (arr: Array<{ timeStamp: string }>): Array<any> =>
    arr.sort((a, b) => {
      const [date1] = a.timeStamp.split(" ");
      const [date2] = b.timeStamp.split(" ");
      const getTime = (str: string) => new Date(str)?.getTime();

      return getTime(date1) > getTime(date2) ? 1 : -1;
    });
  const dispatch = useDispatch();

  const makeChartRequest = async (
    timeFrame: string,
    setData: (data: RealtimeItem<Array<SimpleLineChartItem>>) => void,
    interval?: number
  ) => {
    setData({
      loading: true,
    });
    const data: any = await dispatch(
      getTimeFramePerformanceRatio(timeFrame, date, plantId)
    );
    if (data) {
      const sortedData: any = sortByTimeStamp(data);

      const newFormatData = sortedData.map(transformData);
      setData({
        data: newFormatData,
        result: data[0]?.averageDeltaPR * 100,
        loading: false,
      });
    }
    if (interval && automaticRefresh) {
      return setInterval(() => {
        makeChartRequest(timeFrame, setData);
      }, interval);
    }
  };
  // @ts-ignore
  useEffect(() => {
    if (!plantId || !date || !client) return;

    const interval1 = makeBulletChartRequest(
      `GetRealTimeActualPerformanceRatio?plantId=${plantId}`,
      setRealTime,
      minute
    );
    const interval2 = makeBulletChartRequest(
      `GetTimeFramePerformanceRatio?timeFrame=Last60min&plantId=${plantId}&date=${date}`,
      setLast60Minutes,
      minute
    );
    const interval3 = makeBulletChartRequest(
      `GetTimeFramePerformanceRatio?timeFrame=Last7Days&plantId=${plantId}&date=${date}`,
      setYesterday,
      hour
    );
    const interval4 = makeChartRequest("Last24H", setLast24Hours, hour);
    const interval5 = makeChartRequest("Last7Days", setLast7Days);
    const interval6 = makeChartRequest("Last30Days", setLast30Days);

    return async () => {
      clearInterval(await interval1);
      clearInterval(await interval2);
      clearInterval(await interval3);
      clearInterval(await interval4);
      clearInterval(await interval5);
      clearInterval(await interval6);
    };
  }, [plantId, date, client]);

  return {
    realTime,
    yesterday,
    last60minutes,
    last24Hours,
    last30Days,
    last7Days,
  };
};
