import React from 'react';

interface SvgProps {
  height?: number | string;
  width?: number | string;
  fill?: string;
}

export const CustomCloseSVG = (props: SvgProps) => {
  const { height, width, fill = '#0C1E33' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3 0.70875C12.91 0.31875 12.28 0.31875 11.89 0.70875L6.99997 5.58875L2.10997 0.69875C1.71997 0.30875 1.08997 0.30875 0.699971 0.69875C0.309971 1.08875 0.309971 1.71875 0.699971 2.10875L5.58997 6.99875L0.699971 11.8887C0.309971 12.2787 0.309971 12.9087 0.699971 13.2987C1.08997 13.6887 1.71997 13.6887 2.10997 13.2987L6.99997 8.40875L11.89 13.2987C12.28 13.6887 12.91 13.6887 13.3 13.2987C13.69 12.9087 13.69 12.2787 13.3 11.8887L8.40997 6.99875L13.3 2.10875C13.68 1.72875 13.68 1.08875 13.3 0.70875Z" fill={fill} />
    </svg>
  );
};

