import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    "& .MuiFormLabel-root": {
      "& fieldset": {
        borderColor: "#E1E4E9",
      },
    },
  },
  pageWrapper: {
    padding: 30,
  },
  cardWrapper: {
    padding: 20,
  },
  cardUserTenantWrapper: {
    height: "93vh",
  },

  userTenantPageWrapper: {
    padding: 14,
    borderRadius: 8,
  },
  selector: {
    borderRadius: 5,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    display: "flex",
  },

  userTenantHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "100px",
    height: "400px",
    borderBottom: "1px solid #E1E4E9",
  },

  pageLabel: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
  },

  formElementsWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  form: {
    flex: 1,
    flexDirection: "column",
    minWidth: 300,
    maxWidth: 500,
  },

  inputWrapper: {
    marginBottom: theme.spacing(2),
  },

  label: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },

  subLabel: {
    fontWeight: 400,
  },
  m: {
    margin: "5px",
  },
  floatRight: {
    float: "right",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  timeZoneLabel: {
    fontSize: "14px !important",
    fontWeight: "bold",
  },
  timeZoneSelect: {
    marginTop: "6px",
  },
  timeZone: {
    width: "100%",
    marginTop: "-11px",
    marginLeft: "0px",
  },
  cancelButton: {
    marginRight: "20px !important",
  },
  saveButton: {
    marginRight: "40px !important",
  },
  moduleTypeText: {
    fontSize: "16px",
    lineHeight: "17.6px",
    fontWeight: 600,
  },
  assetTypeText: {
    fontSize: "14px",
    lineHeight: "15.4px",
    fontWeight: 500,
  },
}));
