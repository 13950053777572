import { Box, Popover } from '@mui/material';
import { useState } from 'react'
import ColorPicker from 'react-pick-color';
import './ColorWidget.css';

interface Props {
    type: string;
    color: string;
    setColor: (color: any) => void;
}
export const ColorWidget = (props: Props) => {

    const { type,
        color,
        setColor
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div style={{ cursor: "pointer", }}
                className='button-color'
                onClick={handleClick}>
                <Box component="section"
                    sx={{
                        px: 2,
                        py: 1,
                        border: '1px solid #E1E4E9',
                        borderRadius: "4px",
                        color: '#0C1E33',
                        fontWeight: 500,
                        fontSize: '14px'
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {type}
                        <div
                            style={{
                                width: '16px',
                                height: "16px",
                                background: color,
                                borderRadius: "4px",
                                border: '1px solid #E1E4E9'
                            }}></div>
                        <div>
                            {color}
                        </div>
                    </div>
                </Box>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <ColorPicker hideAlpha color={color} onChange={color => setColor(color)} />
            </Popover>
        </>
    )
}
