import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    position: "sticky",
    zIndex: 1,
  },

  button: {
    padding: theme.spacing(0.5),
    textAlign: "center",
    fontSize: 16,
    borderRadius: 3,
    fontWeight: 500,
    backgroundColor: "#70BEAB",
    color: theme.palette.primary.main,
  },

  invertersBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },

  errorBtn: {
    backgroundColor: "#C94B4B",
  },
}));
