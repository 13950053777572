import * as React from "react";
import { useStyles } from "./styles";
import { Grid } from "@mui/material";
// import { NewFields } from "../Fields";
import { OverviewFields } from "../OverviewFields";

export const OverviewDropdown: React.FC<any> = ({
  items,
  definition,
  fieldCount,
  fourCols,
  colsA,
  colsB,
  children,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={12} md={colsA ? colsA : 6}>
        <div className={classes.items}>
          <OverviewFields
            cols={fourCols}
            fields={items}
            definition={definition}
            fieldCount={fieldCount}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={colsB ? colsB : 6}>
        {children}
      </Grid>
    </>
  );
};
