import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertTime, getQueryParams } from "../../../shared/utils/utils";
import {
  BatteryChargeDischargeSocArgs,
  BatteryChargeDischargeSocResponse,
  BatteryCurrentStatusArgs,
  BatteryPowerResponse,
  BatteryCurrentStatusResponse,
  BatteryPowerArgs,
  BatteryStatusAndPercentageArgs,
  BatteryStatusAndPercentageResponse,
  BatteryTemperatureLiveArgs,
  BatteryTemperatureLiveResponse,
  BatteryVoltageAndFrequencyArgs,
  BatteryVoltageAndFrequencyResponse,
  BatteryHeatmapTransformedResponse,
  BatteryHeatmapArgs,
  BatteryChargeDischargePowerArgs,
  BatteryChargeDischargePowerTransformedResponse,
  BatterySocHistoricalTransformedResponse,
  BatterySocHistoricalArgs,
  BatterySocExtremasTransformedResponse,
  BatterySocExtremasArgs,
  BatteryPowerStatusHistoricalTransformedResponse,
  BatteryPowerStatusHistoricalArgs,
  BatteryVoltageAndFrequencyHistoricalTransformedResponse,
  BatteryVoltageAndFrequencyHistoricalArgs,
  BatteryChargeDischargeTransformedResponse,
  BatteryChargeDischargeArgs,
  BatteryChargeExtremasTransformedResponse,
  BatteryChargeExtremasArgs,
  BatteryChargeDischargeHistoricalTransformedResponse,
  BatteryChargeDischargeHistoricalArgs,
  BatteryHeatmapStringsAverageCurrentTransformedResponse,
  BatteryHeatmapStringsAverageCurrentArgs,
  BatteryHeatmapStringsAverageTemperatureTransformedResponse,
  BatteryHeatmapStringsAverageTemperatureArgs,
  BatteryHeatmapStringsMinTemperatureTransformedResponse,
  BatteryHeatmapStringsMinTemperatureArgs,
  BatteryHeatmapStringsMaxTemperatureTransformedResponse,
  BatteryHeatmapStringsMaxTemperatureArgs,
  BatteryHeatmapStringsAverageVoltageTransformedResponse,
  BatteryHeatmapStringsAverageVoltageArgs,
  BatteryHeatmapStringsMinVoltageTransformedResponse,
  BatteryHeatmapStringsMinVoltageArgs,
  BatteryHeatmapStringsMaxVoltageTransformedResponse,
  BatteryHeatmapStringsMaxVoltageArgs,
  BatteryInfoTransformedResponse,
  BatteryInfoArgs,
  BatterySteeringSignalsTransformedResponse,
  BatterySteeringSignalsArgs,
  BatterySteeringSignalsCountTransformedResponse,
  BatterySteeringSignalsCountArgs,
  BatteryTemperatureHistoricalResponse,
  BatteryTemperatureHistoricalArgs,
} from "./BatteryMonitoring.api.types";
import {
  transformBatteryChargeDischargePowerResponse,
  transformBatteryHeatmapResponse,
  transformBatterySocExtremasResponse,
  transformBatterySocHistoricalResponse,
  transformBatteryPowerStatusHistoricalResponse,
  transformBatteryVoltageAndFrequencyHistoricalResponse,
  transformBatteryChargeDischargeResponse,
  transformBatteryChargeExtremasResponse,
  transformBatteryChargeDischargeHistoricalResponse,
  transformBatteryHeatmapStringsAverageCurrentResponse,
  transformBatteryHeatmapStringsAverageTemperatureResponse,
  transformBatteryHeatmapStringsMinTemperatureResponse,
  transformBatteryHeatmapStringsMaxTemperatureResponse,
  transformBatteryHeatmapStringsAverageVoltageResponse,
  transformBatteryHeatmapStringsMinVoltageResponse,
  transformBatteryHeatmapStringsMaxVoltageResponse,
  transformBatteryInfoResponse,
  transformBatterySteeringSignalsResponse,
  transformBatterySteeringSignalsCountResponse,
} from "./BatteryMonitoring.api.transforms";

export const DEFAULT_EQUIPMENT_NAME = "BESS_01";
export const DEFAULT_EQUIPMENT_CHARGE_DISCHARGE_NAME = "Ground_01";

export const batteryApiUrl = `${process.env.REACT_APP_API_URL}SkyfriEMSApi/v1/Battery`;

export const checkIsToday = (date: string) => {
  return new Date(date).toDateString() === new Date().toDateString();
};

export const batteryOverviewApi = createApi({
  reducerPath: "batteryOverviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: batteryApiUrl,
    prepareHeaders: (headers) => {
      const token = "Bearer " + localStorage.getItem("token");
      if (token) {
        headers.set("authorization", token);
        headers.set(
          "Ocp-Apim-Subscription-Key",
          process.env.REACT_APP_API_MKEY
        );
        headers.set("Content-Type", "text/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBatteryStatusAndPercentage: builder.query<
      BatteryStatusAndPercentageResponse,
      BatteryStatusAndPercentageArgs
    >({
      query: ({ plantId, equipmentName }) =>
        `/statusAndPercentage${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
    }),
    getBatteryCurrentStatus: builder.query<
      BatteryCurrentStatusResponse,
      BatteryCurrentStatusArgs
    >({
      query: ({ plantId, portfolioName }) =>
        `/currentStatus${getQueryParams({
          plantId,
          portfolioName,
          cache: "false",
        })}`,
    }),
    getBatteryPower: builder.query<BatteryPowerResponse, BatteryPowerArgs>({
      query: ({ plantId, equipmentName }) => ({
        url: `/power${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
        cache: "no-cache",
      }),
    }),
    getBatteryVoltageAndFrequency: builder.query<
      BatteryVoltageAndFrequencyResponse,
      BatteryVoltageAndFrequencyArgs
    >({
      query: ({ plantId, equipmentName }) => ({
        url: `/voltageAndFrequency${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
        cache: "no-cache",
      }),
    }),
    getBatteryTemperatureLive: builder.query<
      BatteryTemperatureLiveResponse,
      BatteryTemperatureLiveArgs
    >({
      query: ({ plantId, equipmentName }) => ({
        url: `/temperature${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
        cache: "no-cache",
      }),
    }),
    getBatteryTemperatureHistorical: builder.query<
      BatteryTemperatureHistoricalResponse,
      BatteryTemperatureHistoricalArgs
    >({
      query: ({ plantId, fromDate, toDate, equipmentName }) => ({
        url: `/temperatureHistorical${getQueryParams({
          plantId,
          equipmentName,
          fromDate,
          toDate,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
        cache: "no-cache",
      }),
    }),
    getBatteryChargeDischargeSoc: builder.query<
      BatteryChargeDischargeSocResponse,
      BatteryChargeDischargeSocArgs
    >({
      query: ({ plantId, equipmentName }) => ({
        url: `/chargeDischargeSoc${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
        cache: "no-cache",
      }),
    }),
    getBatteryChargeDischargePower: builder.query<
      BatteryChargeDischargePowerTransformedResponse,
      BatteryChargeDischargePowerArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/chargeDischargePower${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryChargeDischargePowerResponse,
    }),
    getBatterySocHistorical: builder.query<
      BatterySocHistoricalTransformedResponse,
      BatterySocHistoricalArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/socHistorical${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatterySocHistoricalResponse,
    }),
    getBatterySocExtremas: builder.query<
      BatterySocExtremasTransformedResponse,
      BatterySocExtremasArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName }) => ({
        url: `/socExtremas${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatterySocExtremasResponse,
    }),
    getBatteryPowerStatusHistorical: builder.query<
      BatteryPowerStatusHistoricalTransformedResponse,
      BatteryPowerStatusHistoricalArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName }) => ({
        url: `/powerStatusHistorical${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryPowerStatusHistoricalResponse,
    }),
    getBatteryVoltageAndFrequencyHistorical: builder.query<
      BatteryVoltageAndFrequencyHistoricalTransformedResponse,
      BatteryVoltageAndFrequencyHistoricalArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/voltageAndFrequencyHistorical${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity: Number(granularity),
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryVoltageAndFrequencyHistoricalResponse,
    }),
    getBatteryChargeDischarge: builder.query<
      BatteryChargeDischargeTransformedResponse,
      BatteryChargeDischargeArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName }) => ({
        url: `/chargeDischarge${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryChargeDischargeResponse,
    }),
    getBatteryChargeExtremas: builder.query<
      BatteryChargeExtremasTransformedResponse,
      BatteryChargeExtremasArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName }) => ({
        url: `/chargeExtremas${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryChargeExtremasResponse,
    }),
    getBatteryChargeDischargeHistorical: builder.query<
      BatteryChargeDischargeHistoricalTransformedResponse,
      BatteryChargeDischargeHistoricalArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/chargeDischargeHistorical${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryChargeDischargeHistoricalResponse,
    }),
    getBatteryHeatmapStringsAverageCurrent: builder.query<
      BatteryHeatmapStringsAverageCurrentTransformedResponse,
      BatteryHeatmapStringsAverageCurrentArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsAverageCurrent${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsAverageCurrentResponse,
    }),
    getBatteryHeatmapStringsAverageTemperature: builder.query<
      BatteryHeatmapStringsAverageTemperatureTransformedResponse,
      BatteryHeatmapStringsAverageTemperatureArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsAverageTemperature${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse:
        transformBatteryHeatmapStringsAverageTemperatureResponse,
    }),
    getBatteryHeatmapStringsMinTemperature: builder.query<
      BatteryHeatmapStringsMinTemperatureTransformedResponse,
      BatteryHeatmapStringsMinTemperatureArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsMinTemperature${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsMinTemperatureResponse,
    }),
    getBatteryHeatmapStringsMaxTemperature: builder.query<
      BatteryHeatmapStringsMaxTemperatureTransformedResponse,
      BatteryHeatmapStringsMaxTemperatureArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsMaxTemperature${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsMaxTemperatureResponse,
    }),
    getBatteryHeatmapStringsAverageVoltage: builder.query<
      BatteryHeatmapStringsAverageVoltageTransformedResponse,
      BatteryHeatmapStringsAverageVoltageArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsAverageVoltage${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsAverageVoltageResponse,
    }),
    getBatteryHeatmapStringsMinVoltage: builder.query<
      BatteryHeatmapStringsMinVoltageTransformedResponse,
      BatteryHeatmapStringsMinVoltageArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsMinVoltage${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsMinVoltageResponse,
    }),
    getBatteryHeatmapStringsMaxVoltage: builder.query<
      BatteryHeatmapStringsMaxVoltageTransformedResponse,
      BatteryHeatmapStringsMaxVoltageArgs
    >({
      query: ({ fromDate, toDate, plantId, equipmentName, granularity }) => ({
        url: `/stringsMaxVoltage${getQueryParams({
          fromDate,
          toDate,
          plantId,
          equipmentName,
          granularity,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryHeatmapStringsMaxVoltageResponse,
    }),
    getBatteryInfo: builder.query<
      BatteryInfoTransformedResponse,
      BatteryInfoArgs
    >({
      query: ({ plantId, equipmentName }) => ({
        url: `/batteryInfo${getQueryParams({
          plantId,
          equipmentName,
          cache: "false",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatteryInfoResponse,
    }),
    getBatterySteeringSignals: builder.query<
      BatterySteeringSignalsTransformedResponse,
      BatterySteeringSignalsArgs
    >({
      query: ({
        plantId,
        equipmentName,
        fromDate,
        toDate,
        pageSize,
        pageNumber,
        search,
        notExecutedOnly,
        source,
        sort,
      }) => ({
        url: `/steeringSignals${getQueryParams({
          plantId,
          equipmentName,
          fromDate,
          toDate,
          pageSize,
          pageNumber: pageNumber + 1,
          search,
          notExecutedOnly,
          source,
          cache: checkIsToday(fromDate) ? "false" : "true",
          sort,
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatterySteeringSignalsResponse,
    }),
    getBatterySteeringSignalsCount: builder.query<
      BatterySteeringSignalsCountTransformedResponse,
      BatterySteeringSignalsCountArgs
    >({
      query: ({ 
        plantId,
        equipmentName,
        fromDate,
        toDate,
        search,
        notExecutedOnly,
        source,
       }) => ({
        url: `/steeringSignalsCount${getQueryParams({
          plantId,
          equipmentName,
          fromDate,
          toDate,
          search,
          notExecutedOnly,
          source,
          cache: checkIsToday(fromDate) ? "false" : "true",
        })}`,
        method: "GET",
        headers: {
          "Content-Type": "text/json",
        },
      }),
      transformResponse: transformBatterySteeringSignalsCountResponse,
    }),
  }),
});

export const {
  useGetBatteryStatusAndPercentageQuery,
  useGetBatteryCurrentStatusQuery,
  useGetBatteryPowerQuery,
  useGetBatteryVoltageAndFrequencyQuery,
  useGetBatteryTemperatureLiveQuery,
  useGetBatteryTemperatureHistoricalQuery,
  useGetBatteryChargeDischargeSocQuery,
  useGetBatteryChargeDischargePowerQuery,
  useGetBatterySocHistoricalQuery,
  useGetBatterySocExtremasQuery,
  useGetBatteryPowerStatusHistoricalQuery,
  useGetBatteryVoltageAndFrequencyHistoricalQuery,
  useGetBatteryChargeDischargeQuery,
  useGetBatteryChargeExtremasQuery,
  useGetBatteryChargeDischargeHistoricalQuery,
  useGetBatteryHeatmapStringsAverageCurrentQuery,
  useGetBatteryHeatmapStringsAverageTemperatureQuery,
  useGetBatteryHeatmapStringsMinTemperatureQuery,
  useGetBatteryHeatmapStringsMaxTemperatureQuery,
  useGetBatteryHeatmapStringsAverageVoltageQuery,
  useGetBatteryHeatmapStringsMinVoltageQuery,
  useGetBatteryHeatmapStringsMaxVoltageQuery,
  useGetBatteryInfoQuery,
  useGetBatterySteeringSignalsQuery,
  useGetBatterySteeringSignalsCountQuery,
} = batteryOverviewApi;
