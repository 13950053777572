import React from "react";
import { ReferenceLine } from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../../components/Loader";
import SimpleBarChart from "../../../../components/SimpleBarChart";
import Widget from "../../../../components/Widget";
import { CustomTooltip } from "./CustomTooltip.componets";
import { useEfficiency } from "./useEfficiency";
import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  plantId: string;
  date: string;
}

const useStyles = makeStyles((theme) => ({
  Widget: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));

export const EfficiencyWidget: React.FC<Props> = ({
  plantId,
  date,
}) => {
  const { data, level, error, loading, expectedValue } = useEfficiency({
    plantId,
    date,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const hide = !loading && !data?.length;


  const onGoToDrillDownPage = (inverter) => {
    let inv;
    let block;
    if (inverter.label.includes('Inv')) {
      let split = inverter.label.split('-')[1]
      split = split.split('.')
      inv = split[1]
      block = split[0]
    }
    else {
      const split = inverter.label?.split(".");
      block = split[0]
      inv = split[1]
    }
    navigate(`${location.pathname}/block/${block}/inverter/${inv}`,
      {
        state: { date },
      })
  }
  const classes = useStyles();

  return (
    <Widget
      label="Inverter Efficiency"
      height={600}
      size={6}
      styles={classes.Widget}
    >
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />

      {loading || !data?.length || error?.length ? null : (
        <SimpleBarChart
          yAxis
          customContent={
            <ReferenceLine
              position={"end"}
              y={expectedValue}
              label={{
                value: `Expected: ${expectedValue?.toFixed(1)}%`,
                position: "insideLeft",
                dy: -6,
              }}
              stroke={GRAPH_COLORS.success}
              strokeDasharray="3 3"
            ></ReferenceLine>
          }
          data={data}
          onClick={onGoToDrillDownPage}
          normalLevel={level}
          formatLabel=""
          tooltipContent={<CustomTooltip />}
          ticks={[75, 80, 85, 90, 95, 100]}
          domain={[75, 100]}
        />
      )}
    </Widget>
  );
};
