import Box from "@mui/material/Box";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ChartGroupButton } from "./ChartGroupButton.component";
import { ChartSelect } from "./ChartSelect.component";

interface Props {
  container: HTMLElement;
  onResize: (h: number, isOverlaping: boolean) => void;
  dropdown?: {
    value: number;
    items: {
      label: string;
      value: number;
    }[];
    onChange: (value: number | string) => void;
  };
  buttonGroups?: {
    buttons: {
      label: string;
      onClick: () => void;
      active?: boolean;
      disabled?: boolean;
    }[];
  }[];
}

export const ChartTopMenu: React.FC<Props> = ({
  container,
  dropdown,
  buttonGroups,
  onResize,
}) => {
  const menuRef = useRef(null);

  const [containerWidth, setContainerWidth] = useState(container.offsetWidth);
  const title = container?.querySelector(".highcharts-title");
  const exporting = container?.querySelector(".highcharts-exporting-group");

  let menuWidth =
    containerWidth - (title?.clientWidth + exporting?.clientWidth) - 128 - 64;

  const isMenuNarrow = menuWidth < 400;

  if (isMenuNarrow) {
    menuWidth = containerWidth;
  }

  const dropdownComponent = useMemo(() => {
    return dropdown ? <ChartSelect {...dropdown} /> : null;
  }, [dropdown]);

  const buttonComponents = useMemo(() => {
    return (
      <>
        {buttonGroups.map((buttonGroup, index) => {
          if (
            buttonGroup &&
            buttonGroup.buttons &&
            buttonGroup.buttons.length > 0
          ) {
            return (
              <ChartGroupButton key={index} buttons={buttonGroup.buttons} />
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }, [buttonGroups]);

  // Resize observer to update container width
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, [container]);

  useEffect(() => {
    onResize(menuRef.current?.clientHeight || 0, isMenuNarrow);
  }, [onResize, menuRef.current?.clientHeight, isMenuNarrow]);

  return (
    <Box
      ref={menuRef}
      style={{
        position: "absolute",
        top: isMenuNarrow ? "46px" : "18px",
        left: isMenuNarrow ? 0 : (title?.clientWidth || 0) + 128,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: isMenuNarrow ? containerWidth : menuWidth,
        maxWidth: isMenuNarrow ? containerWidth : menuWidth,
        flexWrap: "wrap",
        gap: 20,
        padding: "5px",
      }}
    >
      {dropdownComponent}
      {buttonComponents}
    </Box>
  );
};
