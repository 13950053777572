import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Cell } from "./interfaces";
import { color } from "./../../constant";

interface Props {
  cells: Array<Cell>;
  collapseCells?: boolean;
  paddingLeft?: string | number;
  width?: string | number;
  height?: string | number;
  headerBgColor?: string;
  headerColor?: string;
  paddingY?: string | number;
  fontSize?: string | number;
  fontWeight?: string | number;
  borderRight?: boolean;
  sortable?: boolean;
  order?: "asc" | "desc";
  orderBy?: string | null;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
}

const iconStyle = {
  fontSize: "16px", // Adjust the size as needed
};

export const CustomTableHead: React.FC<Props> = ({
  cells,
  collapseCells,
  paddingLeft,
  width,
  height,
  headerBgColor = color.gray,
  paddingY,
  fontSize,
  fontWeight,
  borderRight,
  headerColor,
  sortable,
  order = "desc", // Default to 'desc'
  orderBy,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort?.(event, property);
    };

  const getSortIcon = (direction: "asc" | "desc") => {
    return direction === "asc" ? (
      <ArrowUpwardIcon sx={iconStyle} />
    ) : (
      <ArrowDownwardIcon sx={iconStyle} />
    );
  };

  return (
    <TableHead>
      <TableRow
        style={{ backgroundColor: headerBgColor }}
        sx={{
          "& > *": {
            borderRight: borderRight
              ? "1px solid rgba(224, 224, 224, 1)"
              : null,
          },
          "th:last-child,th:first-child": {
            borderRight: borderRight ? 0 : null,
          },
          // "th:last-child": {
          //   display: "flex",
          //   alignItems: "right",
          // },
        }}
      >
        {cells &&
          cells.map((headCell, index) => (
            <TableCell
              key={headCell.key}
              align={headCell.align}
              sortDirection={
                sortable && orderBy === headCell.key ? order : false
              }
              style={{
                paddingLeft: paddingLeft,
                width: width,
                height: height,
                color: headerColor,
              }}
              sx={{
                paddingY: paddingY,
                fontSize: fontSize,
                fontWeight: fontWeight,
              }}
            >
              <Tooltip title={headCell?.definition} placement="bottom">
                {sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.key}
                    direction={orderBy === headCell.key ? order : "asc"}
                    onClick={createSortHandler(headCell.key)}
                    IconComponent={() =>
                      orderBy === headCell.key ? (
                        getSortIcon(order)
                      ) : (
                        <ArrowDownwardIcon sx={iconStyle} />
                      )
                    }
                    sx={{
                      color: headerColor, // Ensure text color remains the same
                      "&.Mui-active": {
                        color: headerColor, // Ensure active sort label does not change color
                      },
                      "& .MuiTableSortLabel-icon": {
                        color: headerColor, // Ensure sort icon color remains the same
                      },
                    }}
                  >
                    <span>{headCell.label}</span>
                  </TableSortLabel>
                ) : (
                  <span>{headCell.label}</span>
                )}
              </Tooltip>
            </TableCell>
          ))}

        {!collapseCells ? null : <TableCell key="collapse" />}
      </TableRow>
    </TableHead>
  );
};
