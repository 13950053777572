import React, { useState } from 'react'
import { useStyles } from './styles';
import { Button, Snackbar } from '../../../../shared/components';
import { appTheme } from '../../../../app/theme';
import { Grid, Skeleton } from '@mui/material';

interface Props {
    imageType: string;
    image: string;
    imageSize?: string;
    imageOriginalSize: string;
    uploadImage: (file: File, plantId: string | number) => void;
    removeImage: (plantId: string | number) => void;
    imageLoading: boolean;
    imageName: string;
    plantId: string | number;
}

export const ImagesWidget = (props: Props) => {
    const {
        imageType,
        image,
        uploadImage,
        removeImage,
        imageLoading,
        imageName,
        plantId,
        imageOriginalSize
    } = props;

    const classes = useStyles();
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const onImageChange = async (event) => {
        if (event.target.files && event.target.files?.length > 0) {
            const file = event.target.files[0]
            for (let i = 0; i < event.target.files?.length; i++) {
                let img = event.target.files[i];
                let extension =
                    img.name.substring(img.name.lastIndexOf(".") + 1, img.name.length) ||
                    img.name;
                if (
                    extension?.toLowerCase() !== "jpeg" && extension?.toLowerCase() !== "jpg" && extension?.toLowerCase() !== "png"
                ) {
                    setMessage("Please upload a jpeg, jpg or a png file");
                    setSeverity("error");
                    setIsOpen(true);
                    return;
                }
                uploadImage(file, plantId);
            }
        }
    };
    const triggerFileInput = () => {
        const fileInput = document.getElementById(`file-input-${imageType}`);
        fileInput.click(); // Simulate a click event on the file input
    };

    return (
        <div className={classes.imageSpacing}>
            <div className={classes.imageHeader}>
                {imageType}
            </div>
            {
                imageLoading ?
                    <div style={{ padding: "10px 0px 10px 0px" }}>
                        <Skeleton variant="rounded" height={80} />
                    </div>
                    :
                    <div className={classes.imageBackground}>
                        <Grid container>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                                {
                                    !image ?
                                        <div className={classes.noImage}>
                                            No image added
                                        </div> :
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                <img
                                                    alt={`${imageType}`}
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        marginRight: "1px",
                                                        borderRadius: "5px",
                                                        cursor: "default",
                                                        display: "inline-block",
                                                        background: "#CACFD7",
                                                    }}
                                                    title={`${imageType}`}
                                                    src={image}
                                                />
                                            </div>
                                            <div>
                                                <div className={classes.imageName}>
                                                    {imageName}
                                                </div>
                                                <div className={classes.imageSizeText}>
                                                    {imageOriginalSize}
                                                </div>
                                            </div>

                                        </div>
                                }
                            </Grid>

                            <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <input
                                    type="file"
                                    id={`file-input-${imageType}`}
                                    style={{ display: 'none' }}
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={(e) => onImageChange(e)}
                                />
                                <span style={{ paddingLeft: "10px" }}>
                                    {
                                        image &&
                                        <Button
                                            title='Delete'
                                            onClick={() => {
                                                removeImage(plantId)
                                            }}
                                            outlined
                                            color={appTheme.main}
                                        />
                                    }
                                </span>
                                <span style={{ paddingLeft: "10px" }}>
                                    <Button
                                        title='Upload'
                                        onClick={() => {
                                            triggerFileInput()
                                        }}
                                        color={appTheme.main}
                                    />
                                </span>
                            </Grid>
                        </Grid>
                        <Snackbar severity={severity} open={isOpen} message={message} handleClose={() => setIsOpen(false)} />
                    </div>
            }
        </div>
    )
}
