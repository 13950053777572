import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { ActionButtons } from "../../../../shared/components";
import { useStyles } from "./../styles";
import { UseRoleTeamSave } from "./useRoleTeamSave";
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete } from "./../../components/Utils/utils";
import { roleTeamFormSchema } from "./metaData";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { Snackbar } from "./../../../../shared/components/Snackbar";

export const RoleTeamSavePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { roleId } = useParams<{ roleId: string }>();
  const { roleTeamId } = useParams<{ roleTeamId: string }>();

  const { team, upsert } = UseRoleTeamSave({
    tenantId
  });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const [severity, setSeverity] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const createNewTeam = roleTeamId === "new";

  useEffect(() => {
    initializeValidationsObsolete(roleTeamFormSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, []);

  useEffect(() => {
    if (team) roleTeamFormSchema.TeamId.options = team;
  }, [team]);

  useEffect(() => {
    if (team || createNewTeam) {
      initializeValuesObsolete(
        roleTeamFormSchema,
        createNewTeam,
        [],
        (resp) => {
          setInitialValues(resp);
        }
      );
    }
  }, [team]);

  const onSubmitHandler = (values) => {
    let fields = JSON.parse(JSON.stringify(values, null, 2));
    fields.teamRoleId = roleId;

    upsert(fields, null, (resp) => {
      if (resp?.ok === false) {
        setSeverity("error");
        setMessage("Operation unsuccessfull");
        setIsOpen(true);
      } else {
        setSeverity("success");
        setMessage("Record added successfully");
        setIsOpen(true);
      }
      setTimeout(() => {
        let URI = `/onboarding/roles/${tenantId}/${roleId}`;
        renderPage(URI);
      }, 2000);

    });
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
      <div className={classes.pageLabel}>
        {createNewTeam ? <>Add Team </> : <>Edit Team</>}
      </div>

      <Card style={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <div className={classes.formElementsWrapper}>
            {Object.keys(roleTeamFormSchema).map((x) => (
              <DynamicField
                name={x}
                data={roleTeamFormSchema[x]}
                formik={formik}
              />
            ))}
          </div>
        </form>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionButtons
              saveTitle="Save team"
              onSave={() => {
                formik.validateForm();
                return formik.submitForm();
              }}
              saveButtonType="submit"
              cancelTitle="Cancel"
              onCancel={() => {
                let URI = `/onboarding/roles/${tenantId}/${roleId}`;
                renderPage(URI);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
