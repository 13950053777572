import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { getAlertColor } from "./utils";
import { convertTimeZone } from "../../../shared/utils/utils";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AlertItem } from "../../../features/Sidem/shared/sidemSharedApi.types";

interface Notification {
  title: string;
  description: string;
  isActive: boolean;
  date: string;
  id: string;
  generationLoss?: string;
}

interface Alarm {
  alarmCategoryCode: number;
  alarmCategoryName: string;
  alarmLevel: number;
  createdOn: string;
  description: string;
  displayName: string;
  isActive: boolean;
  modifiedOn: string;
  nodeId: string;
  plantId: string; //number;
  generationLoss?: string;
}

interface Props {
  alarm: AlertItem;
  isNonActive?: boolean;
  timeZone?: string;
}

const createNotification = (
  title: string,
  description: string,
  isActive: boolean,
  date: string,
  id: string,
  generationLoss: string
): Notification => ({ title, description, isActive, date, id, generationLoss });

const transformAlarmToNotification = (alarm: AlertItem): Notification => {
  const description = alarm.description || "Description";
  const date = alarm.createdOn
  return createNotification(
    alarm?.alarmCategoryName,
    description,
    alarm.isActive,
    date,
    date,
    alarm.generationLoss
  );
};

export const LayoutAlarmNotification: React.FC<Props> = ({
  alarm,
  isNonActive,
  timeZone,
}) => {
  const classes = useStyles();
  const notification = useMemo(
    () => transformAlarmToNotification(alarm),
    [alarm]
  );
  const getDescription = (level) => {
    const arr = ["Information", "Warning", "Error", "Critical"];
    return arr[level];
  };



  return (
    <div className={classes.layoutNotification}>
      <div
        className={classes.tooltipHeader}
        style={{ backgroundColor: getAlertColor(alarm, isNonActive) }}
      >
        <div>{alarm.description}</div>
        <IconButton>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <div className={classes.displayFlex} style={{ padding: 10 }}>
        <div className={classes.content}>
          <div className={classes.layoutTitle}>
            Warning
            <span
              className={classes.layoutValue}
            >{` ${alarm.description}`}</span>
          </div>
          <div className={classes.layoutTitle}>
            Date
            <span
              className={classes.layoutValue}
            >{` ${convertTimeZone(timeZone, "D.M.YY  HH:mm", notification.date, "z")}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
