import { useEffect, useState } from "react";
import { autoRefreshApi } from "../../../../../shared/utils/utils";
import { SimpleBarChartItem } from "../../../../components/SimpleBarChart";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  equipmentName: string;
  inverterActualEfficiency: number;
  inverterExpectedEfficiency: number;
  plantId: string;
}

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: Array<SimpleBarChartItem>;
  level: number;
  error: string;
  loading: boolean;
  expectedValue: number;
}

export const useEfficiency = ({ plantId, date }: Props): Data => {
  const [data, setData] = useState([]);
  const [level, setLevel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [expectedValue, setExpectedValue] = useState(0);
  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      setLoading(false);
      return;
    }
    setLoading(true);

    const arr: Array<SimpleBarChartItem> = data?.map((item) => ({
      label: item.equipmentName.replace("Inverter", "Inv"),
      value: item.inverterActualEfficiency,
      expectedValue: item.inverterExpectedEfficiency,
    }));

    const sortedArr = arr?.sort((a, b) => b.value - a.value).reverse();
    setLevel(sortedArr[0]?.value + 1);
    setExpectedValue(sortedArr[0]?.expectedValue);
    setData(sortedArr);
    setError("");
    setLoading(false);
  };

  const getInverterEfficiencyGraph = () => {
    fetchAsync(`SkyfriSidemApi/v1/GetInverterEfficiencyGraph?`, "GET", {
      PlantId: plantId,
      Date: date,
    })
      .then((data: Array<Item>) => {
        setError("");
        transformData(data);
      })
      .catch(setError);
  };

  useEffect(() => {
    setLoading(true);
    setError("");
    getInverterEfficiencyGraph();

    const myInterval = autoRefreshApi(() => getInverterEfficiencyGraph());
    return () => clearInterval(myInterval);
  }, [plantId, date]);

  return {
    data,
    level,
    error,
    loading,
    expectedValue,
  };
};
