import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";
import { ILine, IYAxis } from "../../../../components/MultiLineChart";

export enum Lines {
  AC_Power = "AC-Power",
  Irradiation = "Irradiation",
}

export const yAxises: Array<IYAxis> = [
  {
    yAxisId: "right",
    orientation: "right",
    domain: [0, 1],
  },
  {
    yAxisId: "left",
    orientation: "left",
    domain: [0, 1],
  },
];

export const lines: Array<ILine> = [
  {
    key: Lines.AC_Power,
    stroke: GRAPH_COLORS.normal,
    yAxisId: "right",
  },
  {
    key: Lines.Irradiation,
    stroke: GRAPH_COLORS.orange,
    yAxisId: "left",
  },
];
