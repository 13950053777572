import React from "react";
import { ReactComponent as LOADER_LOGO } from "../../../src/shared/icons/app/LOADER_LOGO.svg";
import { useStyles } from "./styles";

export const Fallback: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.loader}>
        <LOADER_LOGO />
      </div>
    </div>
  );
};
