import React from 'react';

interface SvgProps {
    height?: number | string;
    width?: number | string;
}

export const CustomPortfolioIcon = (props: SvgProps) => {
    const { height, width } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3355 1.32918C12.1243 1.22361 11.8758 1.22361 11.6646 1.32918L1.66463 6.32918C1.41054 6.45622 1.25004 6.71592 1.25004 7C1.25004 7.28408 1.41054 7.54378 1.66463 7.67082L11.6646 12.6708C11.8758 12.7764 12.1243 12.7764 12.3355 12.6708L22.3355 7.67082C22.5895 7.54378 22.75 7.28408 22.75 7C22.75 6.71592 22.5895 6.45622 22.3355 6.32918L12.3355 1.32918ZM12 11.1615L3.67709 7L12 2.83853L20.323 7L12 11.1615ZM1.32922 11.6646C1.51446 11.2941 1.96497 11.1439 2.33545 11.3292L12 16.1615L21.6646 11.3292C22.0351 11.1439 22.4856 11.2941 22.6709 11.6646C22.8561 12.0351 22.7059 12.4856 22.3355 12.6708L12.3355 17.6708C12.1243 17.7764 11.8758 17.7764 11.6646 17.6708L1.66463 12.6708C1.29415 12.4856 1.14398 12.0351 1.32922 11.6646ZM1.32922 16.6646C1.51446 16.2941 1.96497 16.1439 2.33545 16.3292L12 21.1615L21.6646 16.3292C22.0351 16.1439 22.4856 16.2941 22.6709 16.6646C22.8561 17.0351 22.7059 17.4856 22.3355 17.6708L12.3355 22.6708C12.1243 22.7764 11.8758 22.7764 11.6646 22.6708L1.66463 17.6708C1.29415 17.4856 1.14398 17.0351 1.32922 16.6646Z" fill="#0C1E33"/>
        </svg>
    );
};