import React from "react";
import { ReactComponent as NOALARMS } from "../../../shared/icons/app/NOALARMS.svg";
import { Grid, Typography } from "@mui/material";

export const EmptyAlarms = () => {
  return (
    <>
      <Grid container spacing={1} style={{}}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <NOALARMS />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            fontSize: "16px",
            lineHeight: "15.6px",
          }}
        >
          No alarms
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "232px",
            height: "32px",
            color: "#858E99",
            fontSize: "13px",
            lineHeight: "15.6px",
          }}
        >
          No alarms found for specified plants and filters.
        </Grid>
      </Grid>
    </>
  );
};
