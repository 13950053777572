import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useSearchParams } from "react-router-dom";
import { convertTime, isAnyItemFalsy } from "../../../../../shared/utils/utils";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import { useAppSelector } from "../../../../../store/rootStore";
import { useGetMppKPIQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useMemo } from "react";

export const useKpiWidget = () => {
  const [url] = useSearchParams();
  const plantId = url.get("plantId");
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");

  const { globalPortfolioName, plantTimeZone } = useAppSelector(
    monitoringDashboardSelector
  );

  const { liveButton } = useAppSelector(monitoringSharedSelector);

  const fromDate = useMemo(() => {
    return startDate ? convertTime(startDate, "YYYY.MM.DD") : undefined;
  }, [startDate]);

  const toDate = useMemo(() => {
    return endDate ? convertTime(endDate, "YYYY.MM.DD") : undefined;
  }, [endDate]);

  const { data, isLoading, isFetching, error } = useGetMppKPIQuery(
    {
      fromDate,
      toDate,
      plantId,
      portfolioName: globalPortfolioName,
      plantTimeZone,
    },
    {
      skip: isAnyItemFalsy([
        fromDate,
        toDate,
        globalPortfolioName,
        plantId,
      ]),
      pollingInterval: liveButton ? 60 * 1000 : undefined,
    }
  );

  return {
    kpiLoading: isLoading,
    kpiData: data?.kpiData || null,
    kpiError: Boolean(error || !data?.error),
    lastUpdated: data?.lastUpdated || 0,
    kpiSecondLoading: isFetching,
  };
};
