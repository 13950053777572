import { useCallback, useEffect, useState } from "react";
import { setGlobalAccordionExpandAction } from "../../../../../features/Analytics/shared/analyticsSharedActions";
import { useSearchParams } from "react-router-dom";
import { getSelectedString } from "../../../../../shared/utils/utils";
import { useGetPortfolioAlarmsCountQuery } from "../../../../../features/Sidem/shared/sidemSharedApi";
import { useAppDispatch } from "../../../../../store/rootStore";

export const useSummaryWidget = () => {
  const dispatch = useAppDispatch();

  const [url] = useSearchParams();

  const portfolioIdUrl = url.get("portfolioId");

  const [expand, setExpand] = useState(false);

  const portfolioName = portfolioIdUrl
    ? getSelectedString(JSON.parse(portfolioIdUrl), "label")
    : undefined;

  const { data } = useGetPortfolioAlarmsCountQuery(
    { portfolioName },
    { skip: !portfolioName }
  );

  const numberOfPortfolios = portfolioIdUrl
    ? JSON.parse(portfolioIdUrl)?.length
    : 0;

  const toggleAcordion = useCallback(() => {
    setExpand((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(setGlobalAccordionExpandAction(expand));
  }, [expand]);

  return {
    summaryData: {
      numberOfPortfolios,
      numberOfAlarms: data || 0,
    },
    toggleAcordion,
    expand,
  };
};
