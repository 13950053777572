import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { monitoringSharedSelector } from "../../features/Monitoring/shared/monitoringSharedSlice";
import {
  setDateComparison,
  setGlobalDateAction,
  setGlobalLiveButtonAction,
} from "../../features/Monitoring/shared/monitoringSharedActions";
import { useSearchParams } from "react-router-dom";
import { checkDate, convertTime } from "../../shared/utils/utils";
import { useLocation } from "react-router-dom";

type ButtonView = "Table" | "Treemap";

interface ContextType {
  isLive: boolean;
  setIsLive: React.Dispatch<React.SetStateAction<boolean>>;
  selectedButtonView: ButtonView; // Define type for selectedButtonView
  setSelectedButtonView: (view: ButtonView) => void;
  checkLiveButton: (val: boolean) => void;
  date: any;
  setDate: any;
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
}
const MonitoringHeaderContext = createContext<ContextType | undefined>(
  undefined
);

export const MonitoringHeaderProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLive, setIsLive] = useState<boolean>(false);
  const [selectedButtonView, setSelectedButtonView] =
    useState<ButtonView>("Table");
  const [path, setPath] = useState<string>("");
  const [url, setUrl] = useSearchParams({
    startDate: "",
    endDate: "",
  });
  const { globalDate, liveButton } = useSelector(monitoringSharedSelector);
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");
  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate[0].startDate),
      endDate: checkDate(endDate, globalDate[0].endDate),
      key: "selection",
    },
  ]);
  useEffect(() => {
    setSelectedButtonView("Table");
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newPath = queryParams.get("path") || "";
    setPath(newPath);
  }, [location.search]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));
    // setDateComparison
    const startTime = convertTime(date[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(date[0].endDate, "YYYY.MM.DD");
    if (startTime !== endTime) {
      dispatch(setDateComparison(false));
    } else {
      dispatch(setDateComparison(true));
    }

    setUrl(
      (prev) => {
        prev.set("startDate", date[0].startDate.toString());
        return prev;
      },
      { replace: true }
    );
    setUrl(
      (prev) => {
        prev.set("endDate", date[0].endDate.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date]);

  useLayoutEffect(() => {
    dispatch(setGlobalLiveButtonAction(isLive));
  }, [isLive]);

  const toggleLive = () => {
    setIsLive((prevState) => !prevState);
  };

  const handleChangeLiveButton = (value: boolean) => {
    setIsLive(value);
  };
  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date[0].startDate : startDate,
      "YYYY.MM.DD"
    );
    const endTime = convertTime(
      !endDate ? date[0].endDate : endDate,
      "YYYY.MM.DD"
    );
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");
    if (
      startTime !== endTime ||
      (startTime !== dateToday && endTime !== dateToday)
    )
      setIsLive(false);
  }, [isLive, startDate, endDate]);

  useEffect(() => {
    const startTime = convertTime(
      !startDate ? date[0].startDate : startDate,
      "YYYY.MM.DD"
    );
    const endTime = convertTime(
      !endDate ? date[0].endDate : endDate,
      "YYYY.MM.DD"
    );
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");

    if (startTime === dateToday && endTime === dateToday) setIsLive(true);
  }, [startDate, endDate]);

  const checkLiveButton = (e) => {
    if (!isLive) {
      setDate([
        {
          endDate: new Date().toISOString(),
          key: "selection",
          startDate: new Date().toISOString(),
        },
      ]);
    }
    setIsLive(e);
  };

  return (
    <MonitoringHeaderContext.Provider
      value={{
        checkLiveButton,
        selectedButtonView,
        setSelectedButtonView,
        isLive,
        setIsLive,
        date,
        setDate,
        path,
        setPath,
      }}
    >
      {children}
    </MonitoringHeaderContext.Provider>
  );
};

export const useMonitoringHeaderContext = (): ContextType => {
  const context = useContext(MonitoringHeaderContext);
  if (!context) {
    throw new Error(
      "useMonitoringHeaderContext must be used within MonitoringHeaderProvider"
    );
  }
  return context;
};
