import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { Typography } from "../../../../../shared/components";

interface Props {
  name: string;
  type: string;
  label?: string;
  subLabel?: string;
  multiline?: boolean;
  maxRows?: number;
  defaultValue?: string;
  formik: any;
  width?: string
  info?: string;
}

export const RenderLabel: React.FC<Props> = (props) => (
  <>
    <Typography display={"flex"} label={props.label} subLabel={props.subLabel}>
      {
        props.info ?
          <Tooltip title={props.info}>
            <IconButton color="primary" aria-label="upload picture" component="span">
              <InfoIcon />
            </IconButton>
          </Tooltip>

          : ""
      }
    </Typography>



    <Typography display={"flex"} label={props.formik.values[props.name]}>
      {
        props.info ?
          <Tooltip title={props.info}>
            <IconButton color="primary" aria-label="upload picture" component="span">
              <InfoIcon />
            </IconButton>
          </Tooltip>

          : ""
      }
    </Typography>
  </>
);
