import { convertTime } from "../../../../../shared/utils/utils";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import { useStyles } from "./styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@material-ui/core";

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<any>;
}) => {
  const classes = useStyles();
  if (!active) return null;

  const data = payload[0]?.payload;
  const [block, inverter] = data?.label?.split(".");

  return (
    <div>
      <div className={classes.tooltipHeader}>
        <div>
          Block {block},  Inverter {inverter}
        </div>
        <IconButton style={{ color: "white" }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <TooltipWrapper>
        <div>
          <strong>Date: </strong> {convertTime(data?.date, "DD.MM.yy")}{" "}
        </div>
        {/* <div>Inverter room: {block}</div> */}
        <div>Specific Energy: {data?.value} kWh/kWp </div>
      </TooltipWrapper>
    </div>
  );
};
