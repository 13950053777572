import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import {
  convertTimeZone,
} from "../../../../../shared/utils/utils";
import BarAndLineComposedChart from "../../../../components/BarAndLineComposedChart";
import Loader from "../../../../components/Loader";
import Widget from "../../../../components/Widget";
import { CustomBarTooltip } from "./Tooltips.component";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface InverterProps {
  plantId: string;
  blockId: string;
  inverterId: string;
  date: string;
  definition?: any;
}

export const InverterPRIrradiationWidget = ({
  plantId,
  date,
  blockId,
  inverterId,
}: InverterProps) => {
  const [error, setError] = useState("");

  const [barChartData, setBarChartData] = useState([]);

  const { client } = useSelector(clientSelector);

  useEffect(() => {
    if (!plantId || !blockId || !inverterId || !date) return;

    fetchAsync(
      `SkyfriSidemApi/v1/GetInverterPRandIrr?date=${date}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}`,
      "GET"
    )
      .then((data: any) => {
        if (!data?.length) {
          setError("No data");
        }
        const map = data?.reduce((res, curr) => {
          const [date, time] = curr.timeStamp.split("T");
          if (res[date]) {
            res[date] = { ...res[date], [time]: curr };
          } else {
            res[date] = { [time]: curr };
          }

          return res;
        }, {});

        const sortedData = Object.keys(map)
          ?.sort()
          ?.map((item) => {
            const keys = Object.keys(map[item])?.sort();
            return keys?.map((key) => map[item][key]);
          });

        setBarChartData(
          sortedData?.flat().map((item) => ({
            label: convertTimeZone(
              client?.timeZone,
              "D.M.YY HH:mm",
              item.timeStamp
            ),
            barValue: item.actualPr?.toFixed(0),
            lineValue: item.actualIrradiation?.toFixed(1),
          }))
        );
      })
      .catch((e) => console.log(e));
  }, [plantId, blockId, date, inverterId]);

  return (
    <Widget
      label="Inverter PR% and Irradiation (Generation hours)"
      height={600}
      size={6}
    >
      {barChartData && barChartData?.length > 0 ? (
        <BarAndLineComposedChart
          data={barChartData}
          tooltipContent={<CustomBarTooltip />}
          normalLevel={70}
          formatLabel="kWh"
          maxHeight={90}
          strokeWidth={2}
          name="Inverter PR%"
        />
      ) : (
        <Loader loading={false} height={300} error={error} errorTextSize={32} />
      )}
    </Widget>
  );
};
