import React from "react";
import { Checkbox } from "../../../../../shared/components";
interface selectProps {
  name: string;
  label?: string;
  subLabel?: string;
  value: string;
  formik: any;
  isChecked: boolean;
  options?: Array<string>;
  default?: any;
  isAdd?: boolean;
  showOnEdit?: boolean;
  showOnAdd?: boolean;
}

export const RenderCheckbox: React.FC<selectProps> = (props) => (
  <>
    {(props.isAdd == true && props.showOnAdd == true) ||
      (props.isAdd == false && props.showOnEdit == true) ? (
      <Checkbox
        checked={props.formik.values[props.name] || false}
        label={props.label}
        onChange={(newval: boolean): void => {
          props.formik.setFieldValue(props.name, newval);
        }}
      />
    ) : (
      ""
    )}
  </>
);
