import React from "react";
import { TextField } from "@material-ui/core";

interface Props {
	name: string;
	type: string;
	value?: any;
	disabled?: boolean;
	defaultValue?: string;
	onChange: (e: any) => void;
	rows?: number;
	error?: boolean;
	helperText?: string;
	width?: string;
	placeholder?: string;
	inHeight?: string;
	label?: string;
	pm?: boolean;
}

export const Textfield: React.FC<Props> = (props) => (
	<TextField
		variant="outlined"
		style={{
			marginBottom: 8,
			width: props.width ? props.width : "",
			marginTop: props.pm ? "8px" : "auto",
		}}
		fullWidth={props.width ? false : true}
		id={props.name}
		name={props.name}
		label={props.label || ""}
		type={props.type}
		value={props.value || ""}
		disabled={props?.disabled}
		defaultValue={props?.defaultValue || ""}
		multiline={props?.rows > 1}
		rows={props?.rows || 1}
		onChange={props.onChange}
		error={props.error}
		helperText={props.helperText}
		placeholder={props?.placeholder || ""}
		InputProps={{
			style: { height: props.inHeight || "auto" },
		}}
	/>
);
