import React, { lazy, Suspense } from "react";
import { useHeaderWidget } from "./components/HeaderWidget/useHeaderWidget";
import { Container } from "@mui/material";
import { useInverterSpecificEnergyWidget } from "./components/InverterSpecificEnergyWidget/useInverterSpecificEnergyWidget";
import { useInverterKPIWidget } from "./components/InverterKPIWidget/useInverterKPIWidget";
import { useStatusWidget } from "./components/StatusWidget/useStatusWidget";
import { Fallback } from "../../../app/Fallback";

const HeaderWidget = lazy(() =>
  import("./components/HeaderWidget").then((module) => ({
    default: module.HeaderWidget,
  }))
);
const InverterSpecificEnergyWidget = lazy(() =>
  import("./components/InverterSpecificEnergyWidget").then((module) => ({
    default: module.InverterSpecificEnergyWidget,
  }))
);
const InverterKPIWidget = lazy(() =>
  import("./components/InverterKPIWidget").then((module) => ({
    default: module.InverterKPIWidget,
  }))
);
const InverterKpiRawDataWidget = lazy(() =>
  import("./components/InverterKpiRawDataWidget").then((module) => ({
    default: module.InverterKpiRawDataWidget,
  }))
);
const StatusWidget = lazy(() =>
  import("./components/StatusWidget").then((module) => ({
    default: module.StatusWidget,
  }))
);

export const InverterPerformance: React.FC<any> = () => {
  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setDate,
    globalDate,
    onChangePortfolio,
    onChangePlant,
  } = useHeaderWidget();

  const { statusLoading, statusData, statusError } = useStatusWidget();

  const {
    sortType,
    inverterSpecificEnergyData,
    xLabels,
    yLabels,
    popUpData,
    inverterSpecificEnergyLoading,
    onChangeSelectedInverter,
    globalSelectedInverter,
    onChangeSortBy,
    sortBy,
    onSortType,
    title,
  } = useInverterSpecificEnergyWidget();

  const {
    KPIData,
    KPIGraphData,
    xAxis,
    yAxis,
    isInverterKPIError,
    isInverterKPILoading,
    inverterGraphLoading,
  } = useInverterKPIWidget();

  return (
    <Suspense fallback={<Fallback />}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: "16px", paddingTop: "16px" }}
      >
        <HeaderWidget
          selectedPortfolio={selectedPortfolio}
          portfolio={portfolio}
          selectedPlant={selectedPlant}
          plant={plant}
          date={globalDate}
          setDate={setDate}
          onChangePortfolio={onChangePortfolio}
          onChangePlant={onChangePlant}
        />

        <StatusWidget
          plant={plant}
          selectedPlant={selectedPlant}
          date={globalDate}
          loading={statusLoading}
          data={statusData}
          error={statusError}
        />

        <InverterSpecificEnergyWidget
          sortType={sortType}
          data={inverterSpecificEnergyData}
          xLabels={xLabels}
          yLabels={yLabels}
          popUpData={popUpData}
          loading={inverterSpecificEnergyLoading}
          onChangeSelectedInverter={onChangeSelectedInverter}
          onChangeSortBy={onChangeSortBy}
          sortBy={sortBy}
          onSortType={onSortType}
          title={title}
        />
        {globalSelectedInverter ? (
          <>
            <InverterKpiRawDataWidget />
          </>
        ) : null}

        <InverterKPIWidget
          inverterKPILoading={isInverterKPILoading}
          KPIData={KPIData}
          KPIGraphData={KPIGraphData}
          xAxis={xAxis}
          yAxis={yAxis}
          kpiError={isInverterKPIError}
          inverterGraphLoading={inverterGraphLoading}
        />
      </Container>
    </Suspense>
  );
};
