import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    paddingBottom: "1.5rem",
    borderBottom: "1px solid #D1D3D4",
  },
  description: {
    flex: 1,
    fontSize: 16,
    margin: theme.spacing(0, 2),
  },
  buttonsWrapper: {
    position: "absolute",
    marginTop: "10px",
    marginLeft: "10px",
    zIndex: 1000,
  },
  dataWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "auto",
  },
  incidentText: {
    textDecoration: "underline",
    fontStyle: "italic",
  },
}));
