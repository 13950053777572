import { Cell } from "../../shared/components";
import { DropdownOption, Fields } from "./interfaces";


export const formatTypes: Array<DropdownOption> = [
    { label: 'Today', value: 'Today' },
    { label: 'Yesterday', value: 'Yesterday' },
    { label: 'Last 7 days', value: 'Last7days' },
    { label: 'MTD', value: 'MTD' },
    { label: 'QTD', value: 'QTD' },
    { label: 'YTD', value: 'YTD' },
    { label: 'FYTD', value: 'FYTD' },
];

export const colors = ['#97CEFD', '#0C1E33', '#1572DF', '#34A114', '#966BF6',
    '#FF5500', "#43A588", "#FFC107", "#9E5F53", "#95AFCE", "#CDC57D", "#FF8E8D"];


const createCell = (key: string, label: string, align: "left" | "right" | "center", definition: string, getValueComponent = null): Cell =>
    ({ key, label, align, definition, getValueComponent });

export const headerCells: Array<Cell> = [
    createCell(Fields.empty, "", "left", ""),
    createCell(Fields.Plant, "Plant", "left", "The name of the solar power plant."),
    createCell(Fields.Alarms, "Alarms", "left", "The number of active alarms for this plant."),
    createCell(Fields.Capacity, "Capacity", "left", "Capacity is the maximum output generation of the power plant — shown as a measure between actual capacity and theoretical peak capacity."),
    createCell(Fields.GenDown, "Gen. delta", "left", "The difference in percentage between the budgeted and the actual generation of the power plant."),
    createCell(Fields.Generation, "Generation", "left", "Generation is measured as cumulative export energy and represents total energy generated by the power plant."),
    createCell(Fields.Insolation, "Insolation", "left", "The solar power received from the sun over a surface over a period of time."),
    createCell(Fields.PR, "PR", "left", "The performance ratio (PR) is a measure of how energy efficient and reliable a power plant is."),
    createCell(Fields.TCPR, "TCPR", "left", "The temperature-adjusted performance ratio (TCPR) takes into account the effect of temperature into the PR calculation."),
    createCell(Fields.PlantAv, "Plant avail.", "left", "Plant availability is the percentage of the time that the plant is available to provide energy."),
    createCell(Fields.GridAv, "Grid avail.", "left", "Grid availability is the percentage of the time that the plant is available to provide energy to the grid."),
    createCell(Fields.LastUpdated, "Last updated", "left", "Last time an update was received from the plant."),
];
