import { useEffect, useState } from "react";
import { autoRefreshApi } from "../../../../../shared/utils/utils";
import { PowerData } from "./interfaces";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: Array<PowerData>;
  profileData: any;
  loading: boolean;
  level: number;
  error: string;
  filterValue: string;
  onClick: (e: any) => void;
}

export const usePowerComparison = ({
  plantId,
  date,
}: Props): Data => {
  const profileData = JSON.parse(localStorage.getItem("profileData") || "{}");

  const [powerComparisonData, setPowerComparisonData] = useState([]);
  const [powerComparisonLevel, setPowerComparisonLevel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filterValue, setFilterValue] = useState("all");

  const setPowerData = (data: PowerData) => {
    if (!data) {
      setError("No data");
      return setLoading(false);
    }

    setPowerComparisonLevel(data.treshold);

    const newPowerData = data.inverters.map((item) => ({
      date: data?.timeStamp,
      label: item?.block_id + "." + item?.inverter_id,
      value: item.specific_power,
    }));

    setPowerComparisonData(newPowerData);
    setLoading(false);
  };

  const onClick = (event) => {
    const filterValue = event.currentTarget.getAttribute("name");
    setFilterValue(filterValue);
    getInvertersSpecificPowerComparisonRequest(filterValue);
  };

  const getInvertersSpecificPowerComparisonRequest = (filterValue = "") => {
    if (!date) return;

    setLoading(true);
    setError("");
    fetchAsync(
      `SkyfriSidemApi/v1/GetInvertersSpecificPowerComparison?plantId=${plantId}${filterValue && `&tracker=${filterValue}`
      }&Date=${date}`,
      "GET"
    )
      .then(setPowerData)
      .catch((e) => setError("No data"));
  };

  useEffect(() => {
    setLoading(true);
    getInvertersSpecificPowerComparisonRequest(filterValue);

    const myInterval = autoRefreshApi(() =>
      getInvertersSpecificPowerComparisonRequest(filterValue)
    );
    return () => clearInterval(myInterval);
  }, [date]);

  useEffect(() => {
    setFilterValue(profileData?.hasTracker ? "all" : "");
  }, [profileData?.hasTracker]);

  return {
    data: powerComparisonData,
    level: powerComparisonLevel,
    error,
    loading,
    profileData,
    filterValue,
    onClick,
  };
};
