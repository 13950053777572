import { analyticsSharedSelector } from "../../../../../../../features/Analytics/shared/analyticsSharedSlice";
import { convertTime, getSelectedString } from "../../../../../../../shared/utils/utils";
import { useSearchParams } from "react-router-dom";
import { useGetAnalyticsSummaryCardGenKPIQuery } from "../../../../../../../features/Sidem/shared/sidemSharedApi";
import { useAppSelector } from "../../../../../../../store/rootStore";

const useGetSearchParams = () => {
    const [url] = useSearchParams()
    return (key: string) => url.get(key) 
}

export const useAccordion = () => {
    const getUrlSearchParams = useGetSearchParams();

    const startDate = getUrlSearchParams('startDate')
    const endDate = getUrlSearchParams('endDate')
    const plantIdUrl = getUrlSearchParams('plantId')
    const portfolioIdUrl = getUrlSearchParams('portfolioId')

    const portfolioName = portfolioIdUrl ? getSelectedString(JSON.parse(portfolioIdUrl), "label") : undefined;
    const plantId = plantIdUrl ? getSelectedString(JSON.parse(plantIdUrl), "value") : undefined;

    const fromDate = startDate ? convertTime(startDate, "YYYY.MM.DD") : undefined
    const toDate = endDate ? convertTime(endDate, "YYYY.MM.DD") : undefined

    const { liveButton, accordionExpand } = useAppSelector(analyticsSharedSelector)

    const { data, isLoading, error, isFetching } = useGetAnalyticsSummaryCardGenKPIQuery({
        fromDate,
        toDate,
        portfolioName,
        plantId
    }, {
        skip: !fromDate || !toDate || !portfolioName || !plantId,
        pollingInterval: liveButton ? 60 * 1000 : undefined
    })

    return {
        accordionData: data,
        accordionLoading: isLoading,
        accordionExpand,
        accordionSecondLoading: isFetching,
        accordionError: error,
    };
};