import { useEffect, useState } from "react";
import { } from "./interfaces";
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
    tenantId: string;
    teamId: string;
    portfolioId: string
}

interface Data {
    upsert: (params: any, id: string, callback: any) => void;
    plant: any;
    portfolio: any;
}

export const UseTeamPlantSave = ({ tenantId, teamId, portfolioId }: Props): Data => {

    const [plant, setPlant] = useState(null);
    const [portfolio, setPortfolio] = useState(null);

    const fetchAll = async () => {
        await getportfolio();
    };

    useEffect(() => {
        if (teamId === "new") return;
        fetchAll();
    }, []);

    useEffect(() => {
        if (!portfolioId) return;
        getPlant();
    }, [portfolioId]);

    const upsert = async (params: any, id: string, callback: any) => {
        let requestType = "POST";
        params.teamId = id;
        const fields = {
            plantId: params.plantId,
            teamId: params.teamId,
        }
        await fetchAsync(`onboardingV2/v1/PlantsToTeam/AssignPlantToTeam`, requestType, fields)
            .then((data: any) => {
                callback(data);
            })
            .catch((e) => { console.error(e); callback(e) });
    };



    const getportfolio = async () => {
        await fetchAsync(`onboardingV2/V1/Portfolio/GetPortfolios?tenantId=${tenantId}`, "GET")
            .then((data: any) => {
                if (data) {
                    let newPortfolio = data?.map((item) => ({
                        ...item,
                        id: item.portfolioId,
                        value: item.portfolioId,
                        label: `${item.portfolioName}`
                    }));
                    setPortfolio(newPortfolio);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const getPlant = async () => {
        await fetchAsync(`onboardingV2/v1/Plant/GetPlants?portfolioId=${portfolioId}`, "GET")
            .then((data: any) => {
                let newPlant = data?.map((item) => ({
                    ...item,
                    id: item.plantId,
                    value: item.plantId,
                    label: `${item.plantName}`
                }));
                setPlant(newPlant || []);

            })
            .catch((e) => { console.error(e); });
    };

    return {
        upsert,
        plant,
        portfolio
    };
};
