import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Dropdown, DropdownOption } from "../Dropdown";
import { DateRange } from "../DateRange";

const useStyles = makeStyles((theme: Theme) => ({
  plantSelectors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
  },
  section: {
    display: "flex",
  }
}));

interface Props {
  portfolio: DropdownOption;
  portfolios: Array<DropdownOption>;
  plant: any;
  plants: any;
  monthTitle: string;
  changeMonth: (num: number) => void;
  viewType?: any;
  onChange: (portfolio: any, plant: any) => void;
  date?: any;
  setDate?: any;
  id: string;
}

export const PlantDropdown: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { portfolio, portfolios, plant, plants, onChange, viewType, date, setDate, changeMonth, monthTitle, id } = props;
  const onChangeDate = (item: any) => {
    setDate([item.selection]);
  };

  return (
    <div className={classes.plantSelectors}>
      <div className={classes.section}>
        <Dropdown
          value={portfolio}
          options={portfolios}
          onChangeValue={(val) => onChange(val, null)}
          label="Portfolio"
        />
        <div style={{ marginRight: 16 }} />
        <Dropdown
          value={plant}
          options={plants}
          onChangeValue={(val) => onChange(portfolio, val)}
          label="Plant"
        />
      </div>
      {(viewType === "List" && id === 'Calendar') && <DateRange
        date={date}
        maxDate={new Date(date[0].startDate.getFullYear(), date[0].startDate.getMonth() + 1, 1)}
        onChange={onChangeDate}
      />}
    </div>
  );
};
