import {
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesLineOptions,
} from "highcharts";
import { XAxis, YAxis } from "../../../analytics/components/interfaces";

export enum Period {
  WEEK = 7,
  MONTH = 30,
}

export interface KPIResponse {
  clientName: string;
  dcCapacityMW: number;
  actGen: number;
  genDiff: number;
  genDelta: number;
  genDiffFlag: string;
  genDeltaFlag: string;
  insolation: number;
  insolationDelta: number;
  insolationDeltaFlag: string;
  actPR: number;
  prDelta: number;
  prDeltaFlag: string;
  actCUFAC: number;
}

export interface AvailabilityResponse {
  clientName: string;
  actAvailability: number;
  gridAvailability: number;
  availabilityDelta: number;
  availabilityFlag: string;
}

export interface SummaryDropdownRespone {
  kpiResponse: KPIResponse;
  availabilityResponse: AvailabilityResponse;
}

export interface SummaryDropdownArgs {
  plantId: string;
  portfolioName: string;
  fromDate: string;
  toDate: string;
}

export interface AnalyticsSummaryIrradDeltaResponse {
  clientName: string;
  genDelta: number;
  genDeltaAvg: number;
  genDeltaAvgFlag: string;
  irradDelta: number;
  irradDeltaAvg: number;
  irradDeltaAvgFlag: string;
  sensorTimeLocal: string;
}

export interface GenerationIrradiationData {
  name: string;
  data: number[];
  yAxis: number;
  tooltip: {
    valueSuffix: string;
  };
}

export interface AnalyticsSummaryIrradDeltaTransformedResponse {
  generationIrradiationData: GenerationIrradiationData[];
  xAxis: XAxis;
  yAxis: YAxis[];
}

export interface AnalyticsSummaryIrradDeltaArgs {
  plantId: string;
  portfolioName: string;
  period: Period;
}

export interface AnalyticsSummaryCardGenerationResponse {
  clientName: string;
  sensorTimeLocal: string;
  generation: number;
  insolation: string;
}

export interface AnalyticsSummaryCardGenerationData {
  name: string;
  data: number[];
  yAxis: number;
  tooltip: {
    valueSuffix: string;
  };
}

export interface AnalyticsSummaryCardGenerationTransformedResponse {
  data: AnalyticsSummaryCardGenerationData[];
  xAxis: XAxis;
  yAxis: YAxis[];
}

export interface AnalyticsSummaryCardGenerationArgs {
  plantId: string;
  portfolioName: string;
  period: Period;
}

export interface AnalyticsSummaryCardProductionResponse {
  clientName: string;
  prodFactor: number;
  prodFactorAvg: number;
  prodFactorAvgFlag: string | null;
  sensorTimeLocal: string;
}

export interface AnalyticsSummaryCardProductionData {
  name: string;
  data: number[];
  yAxis: number;
  tooltip: {
    valueSuffix: string;
  };
}

export interface AnalyticsSummaryCardProductionTransformedResponse {
  data: AnalyticsSummaryCardProductionData[];
  xAxis: XAxis;
  yAxis: YAxis[];
}

export interface AnalyticsSummaryCardProductionArgs {
  plantId: string;
  portfolioName: string;
  period: Period;
}

export interface PortfolioAlarmsCountArgs {
  portfolioName: string;
}

export enum PlantStatus {
  ON = "On",
  OFF = "Off",
}

export interface Plant {
  plantId: string;
  portfolioName: string;
  plantName: string;
  locationName: string;
  timeZone: string;
  dcCapacityMW: number;
  actualGen: number;
  actualGenExpected: string;
  actualGenDelta: number;
  genD: number;
  genDExpected: string;
  actualIrradiation: number;
  actualIrradiationDelta: number;
  actualIrradiationExpected: string;
  actualPr: number;
  actualPrExpected: string;
  actualPrDelta: number;
  actualCuf: number;
  actualCufExpected: string;
  actualCufDelta: number;
  tcpr: number;
  actualAvailability: number;
  actualAvailabilityExpected: string;
  actualAvailabilityDelta: number;
  gridAvailability: number;
  numberOfAlarms: number;
  plantStatus: PlantStatus;
  lastUpdated: string;
}

export interface PlantData {
  portfolioName: string;
  dcCapacityMW: number;
  actualGen: number;
  actualGenExpected: string;
  actualGenDelta: number;
  genD: number;
  genDExpected: string;
  actualIrradiation: number;
  actualIrradiationDelta: number;
  actualIrradiationExpected: string;
  actualPr: number;
  actualPrExpected: string;
  actualPrDelta: number;
  actualCuf: number;
  actualCufExpected: string;
  actualCufDelta: number;
  tcpr: number;
  actualAvailability: number;
  actualAvailabilityExpected: string;
  actualAvailabilityDelta: number;
  gridAvailability: number;
  numberOfAlarms: number;
  plant: Plant[];
}

export interface AnalyticsPlantsCardResponse {
  multipleTimeZones: boolean;
  plantData: PlantData[];
}

export interface AnalyticsPlantsCardTransformedResponse {
  multipleTimeZones: boolean;
  data: PlantData[];
}

export interface AnalyticsPlantsCardArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  portfolioName: string;
}

export interface PlantProfileResponse { }

export interface PlantProfileArgs {
  plantId: string;
}

export interface InverterKPIGraphItem {
  clientName: string;
  plantId: string;
  blockId: number;
  inverterId: number;
  generation: number;
  prValue: number;
  yield: number;
  efficiency: number;
  downtime: number;
}

interface CustomTooltip {
  valueSuffix: string;
}

export interface InverterKPIGraphData {
  name: string;
  data?: (number | [number, number] | null)[]; // Adjust based on your data structure
  tooltip: CustomTooltip;
  type?: string;
  yAxis?: number;
  color?: string;
  marker?: {
    enabled: boolean;
    radius: number;
  };
  lineWidth?: number;
}

export type InverterKPIGraphResponse = InverterKPIGraphItem[];

export interface InverterKPIGraphTransformedResponse {
  data: InverterKPIGraphData[];
  xAxis: XAxis;
  yAxis: YAxis[];
}

export interface InverterKPIGraphArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
}

export interface PortfoliosAndPlantsResponseItem {
  amsMeter: string;
  plantId: string;
  plantName: string;
  portfolioName: string;
  tenantName: string;
  timeZone: string;
}

export type PortfoliosAndPlantsResponse = PortfoliosAndPlantsResponseItem[];

export interface PortfoliosAndPlantsArgs {
  accessType: string;
}


export interface ImageCoordinatesItem {
  portFolioName: string;
  plantId: string;
  nodeId: string;
  equipCoords: number[];
  centerCoords: number[];
  plantLayout: string;
}

export type ImageCoordinatesResponse = ImageCoordinatesItem[];

export interface ImageCoordinatesArgs {
  plantId: string;
}

export interface AlertItem {
  acknowledged: boolean;
  acknowledgedBy: string;
  acknowledgedOn: string;
  alarmCategoryCode: number;
  alarmCategoryName: string;
  alarmGenerationLoss: number;
  alarmId: string;
  alarmLevel: number;
  createdOn: string;
  description: string;
  displayName: string;
  equipmentCategory: string;
  generationLoss: string;
  isAccidentCreated: boolean;
  isActive: boolean;
  modifiedOn: string;
  nodeDisplayName: string;
  nodeId: string;
  plantId: string;
  plantName: string;
  portfolioName: string;
  resolvedOn: string;
}

export type MostRecentAlertsByPlantsResponse = AlertItem[];

export interface MostRecentAlertsByPlantsArgs {
  plantId: string;
  top: number;
}

export interface PanelItem {
  centerCoords: number[];
  equipCoords: number[];
  errors: AlertItem[];
  key: string;
  nodeId: string;
}

export interface OverviewKPIResponse {
  activeInverters: number;
  clientName: string;
  lastUpdated: string;
  timeZone?: string;
  totalAcMw: number;
  totalAlarms: number;
  totalCo2Saved: number;
  totalDcMw: number;
  totalGet: number;
  totalInverters: number;
  totalTreesPlanted: number;
}

export interface OverviewKPIArgs {
  fromDate: string;
  toDate: string;
  portfoliosName: string;
  plantsId: string;
}

export interface AlarmNotificationResponse {
  userIsOptedIn: boolean;
}

export interface GraphData {
  name: string;
  data: number[];
  tooltip: {
    valueSuffix: string;
  };
}

export interface MppGenPageKpiItem {
  acCapacityMw: number;
  clientName: string;
  consumption: number;
  dcCapacityMw: number;
  gridExport: number;
  gridImport: number;
  lastUpdated: string;
  pvGen: number;
  timeZone: string;
}

export type MppGenPageKpiResponse = MppGenPageKpiItem[];

export interface MppGenPageKpiArgs {
  fromDate: string;
  toDate: string;
  portfoliosName: string;
  plantsId: string;
}

export interface MppGenPageKpiTransformedResponse {
  data: MppGenPageKpiItem | null;
  missingData: boolean;
  lastUpdated: number;
  error: boolean;
}

export interface GenerationGraphDataItem {
  sensorTimeLocal: string;
  consumption: number;
  gridExport: number;
  gridImport: number;
  pvGen: number;
}
export type GenerationGraphDataResponse = GenerationGraphDataItem[];

export interface GenerationGraphDataArgs {
  fromDate: string;
  portfoliosName: string;
  plantsId: string;
  dateEqual: boolean;
}

export type GenerationGraphPeriodDataResponse = GenerationGraphDataItem[];

export interface GenerationGraphPeriodDataArgs {
  fromDate: string;
  toDate: string;
  portfoliosName: string;
  plantsId: string;
  dateEqual: boolean;
}

export interface GenerationGraphDataTransformedResponse {
  xAxis: XAxis;
  yAxis: YAxis[];
  data: GraphData[];
}

export interface GenerationGraphPeriodDataTransformedResponse {
  xAxis: XAxis;
  yAxis: YAxis[];
  data: GraphData[];
}

export interface MppIsolationComparisonItem {
  date: string;
  equipmentName: string;
  value: string;
  variable: {
    value: string;
  };
}

export type MppIsolationComparisonResponse = MppIsolationComparisonItem[];

export interface MppIsolationComparisonArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
}

export interface MppIsolationComparisonTransformedResponseData {
  name: string;
  data: string[];
  yAxis: number;
  tooltip: {
    valueSuffix: string;
  };
}

export interface MppIsolationComparisonTransformedResponse {
  data: MppIsolationComparisonTransformedResponseData[];
  xAxis: XAxis;
  yAxis: YAxis[];
}

export interface MppEnergyVsIrradiationItem {
  clientName: string;
  plantId: string;
  date: string;
  generation: number;
  irradiation: number;
}

export type MppEnergyVsIrradiationResponse = MppEnergyVsIrradiationItem[];

export interface MppEnergyVsIrradiationArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
}

export interface MppStatusBarItem {
  clientName: string;
  plantName: string;
  plantId: string;
  acCapacityMW: number;
  dcCapacityMWp: number;
  locationName: string;
  plantStatus: string;
  timeZone: string;
  lastUpdated: string;
  gridStatus: string;
}

export type MppStatusBarResponse = MppStatusBarItem[];

export interface MppStatusBarArgs {
  plantId: string;
  portfolioName: string;
}

export interface MppInverterSpecificEnergyvsPRItem {
  clientName: string;
  plantId: number;
  blockId: number;
  inverterId: number;
  specificEnergy: number;
  specificEnergyMedian: number;
  inverterPR: number;
  isUnderperforming: boolean;
}

export type MppInverterSpecificEnergyvsPRResponse =
  MppInverterSpecificEnergyvsPRItem[];

export interface ClickEvent {
  point: {
    category: string;
  };
}

export interface MppInverterSpecificEnergyvsPRArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
  onClickEventSet: (event: ClickEvent) => void;
}

export interface MppInverterSpecificEnergyHeatMapArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
}

export enum heatMapColors {
  Negligible = "#F3F2EF",
  Low = "#FAE7CB",
  Medium = "#F6D5AF",
  High = "#F4BE7E",
  Critical = "#F3A124",
}

export interface InverterSpecificEnergyHeatMapItemObject {
  clientName: string;
  plantId: string;
  date: string;
  blockId: string;
  inverterId: string;
  specificEnergy: number;
  equipmentName: string;
  severity: keyof typeof heatMapColors;
}

export type InverterSpecificEnergyHeatMapItem =
  InverterSpecificEnergyHeatMapItemObject[];

export type MppInverterSpecificEnergyHeatMapResponse =
  InverterSpecificEnergyHeatMapItem[];

export interface MppInverterSpecificPowerHeatmapObject {
  clientName: string;
  plantId: string;
  blockId: string;
  inverterId: string;
  sensorTime: string;
  specificPower: number;
  equipmentName: string;
  severity: keyof typeof heatMapColors;
}

export type MppInverterSpecificPowerHeatmapItem =
  MppInverterSpecificPowerHeatmapObject[];

export type MppInverterSpecificPowerHeatmapResponse =
  MppInverterSpecificPowerHeatmapItem[];

export interface MppInverterSpecificPowerHeatmapArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
}

export interface MppKPIArgs {
  fromDate: string;
  toDate: string;
  portfolioName: string;
  plantId: string;
  plantTimeZone: string;
}

export interface KpiData {
  clientName: string;
  portfolioName: string;
  plantId: number;
  actualGen: number | string;
  actualGenUnit: string;
  actualIrradiation: number | string;
  actualIrradiationUnit: string;
  actualPR: number | string;
  actualPRUnit: string;
  actualAvailability: number | string;
  actualAvailabilityUnit: string;
  gridAvailability: number | string;
  gridAvailabilityUnit: string;
  irradianceLive: number | string;
  irradianceLiveUnit: string;
  acPowerlive: number | string;
  acPowerliveUnit: string;
  lastUpdated: string;
}

export type MppKPIResponse = KpiData[];

export interface MppMeteoCardItem {
  clientName: string;
  plantId: string;
  ambTemp: number;
  moduleTemp: number;
  raifallCumm: number;
  windDirection: number;
  windSpeed: number;
}

export type MppMeteoCardResponse = MppMeteoCardItem[];

export interface MppMeteoCardArgs {
  fromDate: string;
  toDate: string;
  portfolioName: string;
  plantId: string;
}

export interface MppPlantOverviewKpiValueItem {
  plantId: string;
  plantName: string;
  actualGen: number;
  actualIrradiation: number;
  actualPr: number;
  actualAvailability: number;
  gridAvailability: number;
  numberOfAlarms: number;
  invertersDown: number;
  invertersAv: number;
  poaIrradiance: number;
  actualPower: number;
  lastUpdated: string;
  plantStatus: PlantStatus;
  timeZone: string;
}

export interface MppPlantOverviewKPIResponse {
  multipleTimeZones: boolean;
  values: MppPlantOverviewKpiValueItem[];
}

export interface MppPlantOverviewKPIArgs {
  fromDate: string;
  toDate: string;
  portfoliosName: string | "all";
  plantsId: string | "all";
}

export interface MppKpiAlarm {
  textColor: string;
  opacity: number;
  numberOfAlarms: number | "Error";
  backGroundColor: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

export interface PlantCapacityItem {
  locationName: string;
  plantName: string;
  plantId: string;
  timezone: string;
  acCapacity: number;
  dcCapacity: number;
}

export type PlantCapacityResponse = PlantCapacityItem[];

export interface PlantCapacityArgs {
  plantsId: string;
  portfoliosName: string;
}

export interface MppEquipCardItem {
  clientName: string;
  plantId: string;
  inverterAvailability: string;
  scbAvailability: string | null;
  transformerAvailability: string | null;
}

export type MppEquipCardResponse = MppEquipCardItem[];

export interface MppEquipCardArgs {
  fromDate: string;
  toDate: string;
  portfolioName: string;
  plantId: string;
}

export interface MppAcPowervsIrradiationArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
}

export interface MppAcPowervsIrradiationItem {
  clientName: string;
  plantId: string;
  sensorTimeLocal: string;
  acPower: number;
  poaIrradiance: number;
}

export type MppAcPowervsIrradiationResponse = MppAcPowervsIrradiationItem[];

export interface MppIrradianceComparisonArgs {
  plantId: string;
  fromDate: string;
  toDate: string;
}

export interface MppIrradianceComparisonValueItemParameters {
  parameterName: string;
  value: number | null;
}

export interface MppIrradianceComparisonValueItem {
  parameters: MppIrradianceComparisonValueItemParameters[];
  sensorTime: string;
}

export interface MppIrradianceComparisonItem {
  equipmentName: string;
  values: MppIrradianceComparisonValueItem[];
}

export type MppIrradianceComparisonResponse = MppIrradianceComparisonItem[];
