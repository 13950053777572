import { useEffect, useState } from "react";
import { autoRefreshApi } from "../../../../../shared/utils/utils";

import { ILine } from "../../../../components/MultiLineChart";
import { fetchAsync } from "../../../../../shared/utils/apiService";

enum Lines {
  InternalTemperature2 = "Inverter interior temperature 2",
  InternalTemperature1 = "Inverter interior temperature 1",
  AvgTemperature = "Average Temeperature",
}

const ln: Array<ILine> = [
  {
    key: Lines.AvgTemperature,
    stroke: "#E6B333",
  },
  {
    key: Lines.InternalTemperature1,
    stroke: "#3366E6",
  },
  {
    key: Lines.InternalTemperature2,
    stroke: "#999966",
  },
];

interface Item {
  actualAmbTemp: number;
  date: string;
  equipmentName: string;
  heatSinkTemp: number;
  interiorTemp1: number;
  interiorTemp2: number;
  number: number;
  plantId: string;
  tiltType: string;
}

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: Array<any>;
  lines: Array<ILine>;
  error: string;
  loading: boolean;
}

export const useInverterTemperature = ({
  plantId,
  date,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [lines, setLines] = useState([]);
  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError("");
    const newData = data?.map((item) => ({
      name: item.equipmentName,
      [Lines.InternalTemperature1]: item.interiorTemp1,
      [Lines.InternalTemperature2]: item.interiorTemp2,
      [Lines.AvgTemperature]: item.actualAmbTemp,
    }));

    setLines(ln);

    setError("");
    setData(newData);
    setLoading(false);
  };

  const GetInverterInteriorTemperatureGraph = () => {
    fetchAsync(
      `SkyfriSidemApi/v1/GetInverterInteriorTemperatureGraph?`,
      "GET",
      {
        plantId,
        Date: date,
      }
    )
      .then((data: Array<Item>) => {
        transformData(data);
      })
      .catch(setError);
  };

  useEffect(() => {
    if (!plantId || !date) {
      setLoading(false);
      return;
    }

    setError("");
    setLoading(true);
    GetInverterInteriorTemperatureGraph();

    const myInterval = autoRefreshApi(() =>
      GetInverterInteriorTemperatureGraph()
    );
    return () => clearInterval(myInterval);
  }, [plantId, date]);

  return {
    data,
    lines,
    error,
    loading,
  };
};
