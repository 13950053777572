import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    margin: 0,
    padding: theme.spacing(3),
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },

  widgets: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },

  menuButtons: {
    display: "flex",
    margin: theme.spacing(1),
  },

  icon: {
    width: "20px",
    height: "20px",
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },

  chartWrapper: {
    margin: theme.spacing(2, 0, 2.5, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",
  },

  button: {
    padding: theme.spacing(0.5),
    textAlign: "center",
    fontSize: 16,
    borderRadius: 3,
    fontWeight: 500,
    width: "calc(50% - 8px)",
    backgroundColor: "#70BEAB",
    color: theme.palette.primary.main,
  },

  rightBtn: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },

  errorBtn: {
    backgroundColor: "#C94B4B",
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
  },

  labels: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #D1D3D4",
  },

  item: {
    display: "flex",
    alignItems: "center",
  },
}));
