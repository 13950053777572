import React from "react";
import { PageTitle } from "../../../shared/base/PageTitle/PageTitle.component";
import { DatePickerWithArrows } from "../../../shared/components";
import { useStyles } from "./styles";

interface InverterHeaderProps {
  blockId: string;
  inverterId: string;
  date?: Date;
  setDate?: (val) => void;
}
export const InverterHeader = ({
  blockId,
  inverterId,
  date,
  setDate,
}: InverterHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.inverterHeader}>
      <div>
        <PageTitle label={`Block ${blockId} Inverter ${inverterId}`} />
      </div>
      <div>
        <DatePickerWithArrows
          date={date}
          setDate={setDate}
        />
      </div>

    </div>
  );
};
