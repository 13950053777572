import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { Button, Checkbox } from "./../../../../../shared/components";
import { useStyles } from "./components/styles";
import { plantInformation } from "./components/mockUpData";
import { Plant } from "./components/interface";
import { fetchAsync } from "../../../../../shared/utils/apiService";

export const PlantInformation: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { plantId } = useParams<{ plantId: string }>();
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [plantData, setplantData] = useState({} as Plant);

  useEffect(() => {
    fetchAsync(`SkyfriSidemApi/v1/GetProfileInfo?plantId=${plantId}`, "GET")
      .then((data: Plant) => {
        setplantData(data);
      })
      .catch((e) => console.log(e));
  }, [plantId]);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const viewPlant = () => {
    const URI = `/sidem/portfolios/${portfolioId}/plant/${plantId}`;
    renderPage(URI);
  };

  return (
    <div className={classes.pageWrapper}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={6}>
            Client Name : {plantData?.clientName} <br />
            Site Name : {plantData?.plantName}
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <Button
              color={"rgb(43, 41, 88)"}
              type="button"
              title={"Edit"}
              onClick={() => console.log("")}
            >
              Edit
            </Button>
            &nbsp;
            <Button
              color={"rgb(43, 41, 88)"}
              type="button"
              title={"Save"}
              onClick={() => console.log("")}
            >
              Save
            </Button>
            &nbsp;
            <Button
              color={"rgb(43, 41, 88)"}
              type="button"
              title={"Close"}
              onClick={() => viewPlant()}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.card}>
        <div className={classes.pageLabel}>Site detail</div>
        <Grid container spacing={2} className={classes.detailText}>
          {plantData?.siteCode && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Site Code</div>
                <div className={classes.fieldValue}>{plantData?.siteCode}</div>
              </div>
            </Grid>
          )}
          {plantData?.latitude && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Lattitude</div>
                <div className={classes.fieldValue}>{plantData?.latitude}</div>
              </div>
            </Grid>
          )}
          {plantData?.longitude && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Longitude</div>
                <div className={classes.fieldValue}>{plantData?.longitude}</div>
              </div>
            </Grid>
          )}
          {plantData?.postcode && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Postcode</div>
                <div className={classes.fieldValue}>{plantData?.postcode}</div>
              </div>
            </Grid>
          )}
          {plantData?.tiltdeg && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Tilt</div>
                <div className={classes.fieldValue}>{plantData?.tiltdeg}</div>
              </div>
            </Grid>
          )}
          {plantData?.tariff && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Tariff</div>
                <div className={classes.fieldValue}>{plantData?.tariff}</div>
              </div>
            </Grid>
          )}
          {plantData?.connectedDC && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Total DC Capacity</div>
                <div className={classes.fieldValue}>
                  {plantData?.connectedDC}
                </div>
              </div>
            </Grid>
          )}
          {plantData?.actualPR && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>PR guaranteed</div>
                <div className={classes.fieldValue}>{plantData?.actualPR}</div>
              </div>
            </Grid>
          )}
          {plantData?.degradationPerYear && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Degration per year</div>
                <div className={classes.fieldValue}>
                  {plantData?.degradationPerYear}
                </div>
              </div>
            </Grid>
          )}
          {plantData?.timeZone && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Time zone</div>
                <div className={classes.fieldValue}>{plantData?.timeZone}</div>
              </div>
            </Grid>
          )}
          {plantData?.azimuthdeg && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Azimuth</div>
                <div className={classes.fieldValue}>
                  {plantData?.azimuthdeg}
                </div>
              </div>
            </Grid>
          )}
          {plantData?.acCapacity && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Total AC Capacity</div>
                <div className={classes.fieldValue}>
                  {plantData?.acCapacity}
                </div>
              </div>
            </Grid>
          )}
          {plantData?.availGuaranteed && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>
                  Availability guaranteed
                </div>
                <div className={classes.fieldValue}>
                  {plantData?.availGuaranteed}
                </div>
              </div>
            </Grid>
          )}
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>Offtaker</div>
        <Grid container spacing={2} className={classes.detailText}>
          {plantInformation.Offtaker && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}></div>
                <div className={classes.fieldValue}>
                  {plantInformation.Offtaker}
                </div>
              </div>
            </Grid>
          )}
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>SPV detail</div>
        <Grid container spacing={2} className={classes.detailText}>
          {plantInformation.SPV.name && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Name</div>
                <div className={classes.fieldValue}>
                  {plantInformation.SPV.name}
                </div>
              </div>
            </Grid>
          )}
          {plantInformation.SPV.address && (
            <Grid item xs={6} md={4} sm={4}>
              <div className={classes.detailItem}>
                <div className={classes.fieldLabel}>Address</div>
                <div className={classes.fieldValue}>
                  {plantInformation.SPV.address}
                </div>
              </div>
            </Grid>
          )}
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>Inventers</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Type:{plantInformation.inventers.type} <br />
            Manufacturer:{plantInformation.inventers.manufacturer} <br />
            AC:DC Ratio:{plantInformation.inventers.ratio}
            <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Capacity:{plantInformation.inventers.capacity} <br />
            Number: {plantInformation.inventers.number}
            <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>

        <div className={classes.pageLabel}>Modules</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Type:{plantInformation.modules.type} <br />
            Manufacturer:{plantInformation.modules.manufacturer} <br />
            Surface area (m2):{plantInformation.modules.area}
            <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Capacity:{plantInformation.modules.capacity} <br />
            Number:{plantInformation.modules.number} <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>Combiner box</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Manufacturer:{plantInformation.combiner.manufacturer} <br />
            Number:{plantInformation.combiner.number} <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Model:{plantInformation.combiner.model} <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>Transformer</div>
        <h4>Main Transformer</h4>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Manufacturer:{plantInformation.transformer.main.manufacturer} <br />
            Number:{plantInformation.transformer.main.number} <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Model:{plantInformation.transformer.main.model} <br />
            Rated capacity:{plantInformation.transformer.main.rated} <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>

        <h4>Auxiliary Transformer</h4>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Manufacturer:{plantInformation.transformer.auxiliary.manufacturer}{" "}
            <br />
            Number: {plantInformation.transformer.auxiliary.number}
            <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Model:{plantInformation.transformer.auxiliary.model} <br />
            Rated capacity:{plantInformation.transformer.auxiliary.rated} <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>

        <div className={classes.pageLabel}>Pyranometers</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Type:{plantInformation.pyranometers.type} <br />
            Model:{plantInformation.pyranometers.model} <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Manufacturer: {plantInformation.pyranometers.manufacturer}
            <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>

        <div className={classes.pageLabel}>Tilt/Orientation/Tracking</div>
        <div className={classes.form}>
          <Checkbox
            checked={true}
            label="Tracker"
            onChange={() => console.log("")}
          />
          <Checkbox
            checked={false}
            label="Non-tracker"
            onChange={() => console.log("")}
          />
          <Checkbox
            checked={false}
            label="Both"
            onChange={() => console.log("")}
          />
        </div>
        <div className={classes.pageLabel}>O&M</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={6} sm={6}>
            Name: {plantInformation.o_m.name} <br />
            O&M Contract Start Date:{plantInformation.o_m.startDate} <br />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            Contact person & contact number:{plantInformation.o_m.contactPerson}{" "}
            <br />
            Duration:{plantInformation.o_m.duration} <br />
          </Grid>
          <div className={classes.divider} />
        </Grid>
        <div className={classes.pageLabel}>Last updated on:</div>
        <Grid container spacing={2} className={classes.detailText}>
          <Grid item xs={12} md={12} sm={12}>
            Date:{plantInformation.updateInformation.date}&nbsp;Time:
            {plantInformation.updateInformation.time}&nbsp;Edited by:
            {plantInformation.updateInformation.editedBy}
          </Grid>
          <div className={classes.divider} />
        </Grid>
      </Card>
    </div>
  );
};
