import { checkDate, convertTime } from "./../../../../../shared/utils/utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { monitoringSharedSelector } from "../../../../../features/Monitoring/shared/monitoringSharedSlice";
import {
  setDateComparison,
  setGlobalDateAction,
  setGlobalLiveButtonAction,
} from "../../../../../features/Monitoring/shared/monitoringSharedActions";
import { useSearchParams } from "react-router-dom";

interface Data {
  selectedTab: any;
  setSelectedTab: any;
  selectedButtonView: string;
  setSelectedButtonView: any;
  date: any;
  setDate: any;
  liveButton: boolean;
  checkLiveButton: (val: boolean) => void;
  view: string;
  setView: any;
}

export const useHeaderWidget = (): Data => {
  const dispatch = useDispatch();
  const [url, setUrl] = useSearchParams();
  const startDate = url.get("startDate");
  const endDate = url.get("endDate");
  const [selectedButtonView, setSelectedButtonView] = useState(
    "Table" as string
  );

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [view, setView] = useState("Technical view");
  const { globalDate, liveButton } = useSelector(monitoringSharedSelector);
  const [liveButtonState, setLiveButtonState] = useState(liveButton);
  const [date, setDate] = useState([
    {
      startDate: checkDate(startDate, globalDate[0]?.startDate),
      endDate: checkDate(endDate, globalDate[0]?.endDate),
      key: "selection",
    },
  ]);

  useEffect(() => {
    dispatch(setGlobalDateAction(date));
    const startTime = convertTime(date?.[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(date?.[0].endDate, "YYYY.MM.DD");
    if (startTime !== endTime) {
      dispatch(setDateComparison(false));
    } else {
      dispatch(setDateComparison(true));
    }
    setUrl(
      (prev) => {
        prev.set("startDate", startTime.toString());
        return prev;
      },
      { replace: true }
    );
    setUrl(
      (prev) => {
        prev.set("endDate", endTime.toString());
        return prev;
      },
      { replace: true }
    );
  }, [date, dispatch, setUrl]);

  useLayoutEffect(() => {
    dispatch(setGlobalLiveButtonAction(liveButtonState));
  }, [liveButtonState, dispatch]);

  useEffect(() => {
    const startTime = convertTime(startDate || date[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(endDate || date[0].endDate, "YYYY.MM.DD");
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");
    if (
      startTime !== endTime ||
      (startTime !== dateToday && endTime !== dateToday)
    )
      setLiveButtonState(false);
  }, [liveButtonState, startDate, endDate, date]);

  useEffect(() => {
    if (view === "Environmental view") {
      setLiveButtonState(false);
    } else {
      setLiveButtonState(true);
    }
  }, [view]);

  useEffect(() => {
    const startTime = convertTime(startDate || date[0].startDate, "YYYY.MM.DD");
    const endTime = convertTime(endDate || date[0].endDate, "YYYY.MM.DD");
    const dateToday = convertTime(new Date(), "YYYY.MM.DD");

    if (startTime === dateToday && endTime === dateToday)
      setLiveButtonState(true);
  }, [startDate, endDate, date]);

  const checkLiveButton = (e) => {
    if (!liveButtonState) {
      setDate([
        {
          endDate: new Date().toISOString(),
          key: "selection",
          startDate: new Date().toISOString(),
        },
      ]);
    }
    setLiveButtonState(e);
  };

  useEffect(() => {
    if (selectedTab === 1) {
      setLiveButtonState(true);
      setSelectedButtonView("Table");
      setView("Technical view");
    }
  }, [selectedTab]);

  return {
    selectedTab,
    setSelectedTab,
    view,
    setView,
    date,
    setDate,
    liveButton,
    checkLiveButton,
    selectedButtonView,
    setSelectedButtonView,
  };
};
