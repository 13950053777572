import {
  transformBatteryChargeDischargeHistoricalResponse,
  transformBatteryChargeDischargePowerResponse,
  transformBatteryChargeDischargeResponse,
  transformBatteryChargeExtremasResponse,
  transformBatteryHeatmapResponse,
  transformBatteryHeatmapStringsAverageCurrentResponse,
  transformBatteryHeatmapStringsAverageTemperatureResponse,
  transformBatteryHeatmapStringsAverageVoltageResponse,
  transformBatteryHeatmapStringsMaxTemperatureResponse,
  transformBatteryHeatmapStringsMaxVoltageResponse,
  transformBatteryHeatmapStringsMinTemperatureResponse,
  transformBatteryHeatmapStringsMinVoltageResponse,
  transformBatteryInfoResponse,
  transformBatteryPowerStatusHistoricalResponse,
  transformBatterySocExtremasResponse,
  transformBatterySocHistoricalResponse,
  transformBatterySteeringSignalsCountResponse,
  transformBatterySteeringSignalsResponse,
  transformBatteryVoltageAndFrequencyHistoricalResponse,
} from "./BatteryMonitoring.api.transforms";
import { VALUE_TYPE } from "./components/BatteryHeatmap/utils";

export interface BatteryStatusAndPercentageArgs {
  plantId: string;
  equipmentName: string;
}

export enum BatteryStatuses {
  OFF,
  EMPTY,
  DISCHARGING,
  CHARGING,
  FULL,
  HOLDING,
  TESTING,
  // custom one, not provided by API
  NO_DATA = "NO_DATA"
}

export enum BatteryStatusesLabels {
  OFF = "Off",
  EMPTY = "Empty",
  DISCHARGING = "Discharging",
  CHARGING = "Charging",
  FULL = "Full",
  HOLDING = "Holding",
  TESTING = "Testing",

  // custom one, not provided by API
  NO_DATA = "No data"
}

export interface BatteryItemObject {
  value: number | null;
  unit: string | null;
  date: string;
}

export interface BatteryStatusAndPercentageResponse {
  stateOfCharge: BatteryItemObject;
  stateOfHealth: BatteryItemObject;
  status: BatteryItemObject;
}

export interface BatteryCurrentStatusArgs {
  portfolioName: string;
  plantId: string;
}

export interface BatteryCurrentStatusResponse {
  locationName: string;
  mwh: number;
  mah: number;
  status: {
    grid: boolean;
    inverter: boolean;
  };
}

export interface BatteryPowerArgs {
  equipmentName: string;
  plantId: string;
}

export interface BatteryItem {
  value: number | null;
  unit: string | null;
  date: string;
}

export interface BatteryPowerResponse {
  active: BatteryItemObject;
  reactive: BatteryItemObject;
  apparent: BatteryItemObject;
  factor: BatteryItemObject;
}

export interface BatteryVoltageAndFrequencyArgs {
  equipmentName: string;
  plantId: string;
}

export interface BatteryVoltageAndFrequencyResponse {
  current: BatteryItemObject;
  voltage_ll: BatteryItemObject;
  voltage_ln: BatteryItemObject;
  frequency: BatteryItemObject;
}

export interface BatteryTemperatureLiveArgs {
  equipmentName: string;
  plantId: string;
}

export interface BatteryTemperatureLiveObject {
  highest: BatteryItemObject;
  lowest: BatteryItemObject;
  min: BatteryItemObject;
  max: BatteryItemObject;
}

export interface CabinetTemperatureLiveObject {
  ambient: BatteryItemObject;
  cabinet: BatteryItemObject;
  min: BatteryItemObject;
  max: BatteryItemObject;
}

export interface BatteryTemperatureLiveResponse {
  battery: BatteryTemperatureLiveObject;
  cabinet: CabinetTemperatureLiveObject;
}

export interface BatteryTemperatureHistoricalArgs {
  equipmentName: string;
  plantId: string;
  fromDate: string;
  toDate: string;
}

export interface BatteryTemperatureHistoricalObject {
  avg: BatteryItemObject;
  max: BatteryItemObject;
  min: BatteryItemObject;
}

export interface CabinetTemperatureHistoricalObject {
  avgAmbient: BatteryItemObject;
  avgCabinet: BatteryItemObject;
  maxCabinet: BatteryItemObject;
  minCabinet: BatteryItemObject;
}

export interface BatteryTemperatureHistoricalResponse {
  battery: BatteryTemperatureHistoricalObject;
  cabinet: CabinetTemperatureHistoricalObject;
}

export interface BatteryChargeDischargeSocArgs {
  equipmentName: string;
  plantId: string;
}

export interface BatteryChargeDischargeSocResponse {
  charged: number;
  discharged: number;
  maxCharge: number;
  maxDischarge: number;
  maxSoc: number;
  minSoc: number;
}
export interface BatteryChargeDischargePowerArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryChargeDischargePowerResponse {
  items: BatteryItemObject[];
}

export interface BatteryEnergyChartArgs {
  portfolioName: string;
  plantId: string;
  startDate: string;
  endDate: string;
  granularity: string;
}

export type BatteryChargeDischargePowerTransformedResponse = ReturnType<
  typeof transformBatteryChargeDischargePowerResponse
>;

export interface BatteryHeatmapArgs {
  portfolioName: string;
  plantId: string;
  startDate: string;
  endDate: string;
  granularity: string;
  valueType: string;
  valueFormat: string;
}

export type BatteryHeatmapFormat = "hour" | "day" | "week" | "month" | "year";

export type BatteryHeatmapRange = {
  criticallyLow: number;
  low: number;
  optimal: number;
  high: number;
};

export interface BatteryHeatmapResponse {
  values: {
    [key: string]: number[][];
  };
  elementsCount: number;
  elementsFormat: BatteryHeatmapFormat;
  stringsCount: number;
  ranges: {
    [key: string]: BatteryHeatmapRange;
  };
}

export type BatteryHeatmapTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapResponse
>;

export interface BatterySocHistoricalArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatterySocHistoricalResponse {
  items: BatteryItemObject[];
}

export type BatterySocHistoricalTransformedResponse = ReturnType<
  typeof transformBatterySocHistoricalResponse
>;

export interface BatterySocExtremasArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
}

export interface BatterySocExtremasResponse {
  maxSoc: BatteryItemObject;
  minSoc: BatteryItemObject;
}

export type BatterySocExtremasTransformedResponse = ReturnType<
  typeof transformBatterySocExtremasResponse
>;

export interface BatteryPowerStatusHistoricalArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;

  granularity: string;
}

export interface BatteryPowerStatusHistoricalItem {
  active: BatteryItemObject | null;
  reactive: BatteryItemObject | null;
  apparent: BatteryItemObject | null;
  power: BatteryItemObject | null;
  timestamp: string;
}

export interface BatteryPowerStatusHistoricalResponse {
  items: BatteryPowerStatusHistoricalItem[];
}

export type BatteryPowerStatusHistoricalTransformedResponse = ReturnType<
  typeof transformBatteryPowerStatusHistoricalResponse
>;

export interface BatteryVoltageAndFrequencyHistoricalArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;

  granularity: string;
}

export interface BatteryVoltageAndFrequencyHistoricalItem {
  current: BatteryItemObject | null;
  voltage_ll: BatteryItemObject | null;
  voltage_ln: BatteryItemObject | null;
  frequency: BatteryItemObject | null;
  timestamp: string;
}

export interface BatteryVoltageAndFrequencyHistoricalResponse {
  items: BatteryVoltageAndFrequencyHistoricalItem[];
}

export type BatteryVoltageAndFrequencyHistoricalTransformedResponse =
  ReturnType<typeof transformBatteryVoltageAndFrequencyHistoricalResponse>;

export interface BatteryChargeDischargeArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
}

export interface BatteryChargeDischargeResponse {
  charged: BatteryItemObject | null;
  discharged: BatteryItemObject | null;
}

export type BatteryChargeDischargeTransformedResponse = ReturnType<
  typeof transformBatteryChargeDischargeResponse
>;

export interface BatteryChargeExtremasArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
}

export interface BatteryChargeExtremasResponse {
  maxCharge: BatteryItemObject | null;
  maxDischarge: BatteryItemObject | null;
}

export type BatteryChargeExtremasTransformedResponse = ReturnType<
  typeof transformBatteryChargeExtremasResponse
>;

export interface BatteryChargeDischargeHistoricalArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryChargeDischargeHistoricalResponse {
  items: {
    charged: BatteryItemObject | null;
    discharged: BatteryItemObject | null;
    timestamp: string;
  }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryChargeDischargeHistoricalTransformedResponse = ReturnType<
  typeof transformBatteryChargeDischargeHistoricalResponse
>;

export interface BatteryHeatmapStringsAverageCurrentArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsAverageCurrentResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsAverageCurrentTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsAverageCurrentResponse
>;

export interface BatteryHeatmapStringsAverageTemperatureArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsAverageTemperatureResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsAverageTemperatureTransformedResponse =
  ReturnType<typeof transformBatteryHeatmapStringsAverageTemperatureResponse>;

export interface BatteryHeatmapStringsMinTemperatureArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsMinTemperatureResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsMinTemperatureTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsMinTemperatureResponse
>;

export interface BatteryHeatmapStringsMaxTemperatureArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsMaxTemperatureResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsMaxTemperatureTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsMaxTemperatureResponse
>;

export interface BatteryHeatmapStringsAverageVoltageArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsAverageVoltageResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsAverageVoltageTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsAverageVoltageResponse
>;

export interface BatteryHeatmapStringsMinVoltageArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsMinVoltageResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsMinVoltageTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsMinVoltageResponse
>;

export interface BatteryHeatmapStringsMaxVoltageArgs {
  fromDate: string;
  toDate: string;
  plantId: string;
  equipmentName: string;
  granularity: string;
}

export interface BatteryHeatmapStringsMaxVoltageResponse {
  items: { [key: string]: BatteryItemObject | null }[];
  pageNumber: number;
  pageSize: number;
}

export type BatteryHeatmapStringsMaxVoltageTransformedResponse = ReturnType<
  typeof transformBatteryHeatmapStringsMaxVoltageResponse
>;

export interface BatteryInfoArgs {
  plantId: string;
  equipmentName: string;
}

// TODO: kaz it will be enum instead of hardcoded string
export interface BatteryInfoResponse {
  inverterWorkingState: string;
  gridConnectionState: string;
  capacityAh: BatteryItemObject | null;
  capacityWh: BatteryItemObject | null;
}

export type BatteryInfoTransformedResponse = ReturnType<
  typeof transformBatteryInfoResponse
>;

export interface BatterySteeringSignalsArgs {
  plantId: string;
  equipmentName: string;
  fromDate: string;
  toDate: string;
  pageSize: number;
  pageNumber: number;
  search?: string;
  notExecutedOnly?: "true" | "false";
  source?: string;
  sort: "Asc" | "Desc";
}

export interface BatterySteeringSignalItem {
  dateTimeLocal: string;
  dateTimeUTC: string;
  // TODO: kaz it will be enum
  source: string;
  // TODO: kaz it will be enum
  type: string;
  percentageReduction: number | null;
  desiredLevel: number;
  executed: boolean;
}

export interface BatterySteeringSignalsResponse {
  items: { [key: string]: BatterySteeringSignalItem[] };
  pageNumber: number;
  pageSize: number;
  numberOfElements: number;
  totalNumberOfElements: number;
}

export type BatterySteeringSignalsTransformedResponse = ReturnType<
  typeof transformBatterySteeringSignalsResponse
>;

export interface BatterySteeringSignalsCountArgs {
  plantId: string;
  equipmentName: string;
  fromDate: string;
  toDate: string;
  search?: string;
  notExecutedOnly?: "true" | "false";
  source?: string;
}

export type BatterySteeringSignalsCountResponse = {
  Total: number;
  [key: string]: number;
};

export type BatterySteeringSignalsCountTransformedResponse = ReturnType<
  typeof transformBatterySteeringSignalsCountResponse
>;

export enum INVERTER_STATE {
  OFF = "Off",
  SLEEPING = "Sleeping",
  STARTING = "Starting",
  RUNNING = "Running",
  THROTTLED = "Throttled",
  SHUTTING_DOWN = "Shutting down",
  FAULT = "Fault",
  STANDBY = "Standby",

  // custom one, not provided from API
  NO_DATA = "No data",
}

export enum BATTERY_CONTROL_STATUS {
  PeakShaving = "Peak shaving",
  Trading = "Trading",
  aFFR = "aFFR",
  "0PVExport" = "0 PV Export",
  MaxSiteLoad = "Max site load",
}

export enum GRID_CONNECTION_STATE {
  DISCONNECTED = "Disconnected",
  CONNECTED = "Connected",

  // custom one, not provided from API
  NO_DATA = "No data",
}

export enum ENERGY_PROVIDERS {
  "Skyfri" = "Skyfri",
  "AmpereCloud" = "AmpereCloud",
  "Ebox" = "Ebox",
  "Multiple sources" = "Multiple sources",
}
