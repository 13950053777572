import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '3px 14px 0px 14px',
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  }
}));

interface Props {
  showError: boolean;
  errorMessage: string;
}

export const ErrorMessage: React.FC<Props> = ({ showError, errorMessage }) => {
  const classes = useStyles();

  return !showError ? null : (
    <div className={classes.errorMessage}>
      {errorMessage}
    </div>
  );
}
