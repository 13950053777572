import { useMemo } from "react";
import HeatMap from "react-heatmap-grid";

import { getBackground } from "./utils";

interface Props {
  data: Array<Array<number>>;
  xLabels: Array<string>;
  yLabels?: Array<string>;
  colors?: Array<Array<string>>;
}

export const HeatmapChart: React.FC<Props> = (props) => {
  const { data, xLabels, yLabels, colors } = props;

  const xLabelsVisibility = useMemo(
    () => xLabels?.map((item, idx) => idx + 1),
    [xLabels]
  );

  return (
    <div style={{ fontSize: "13px", overflow: "auto", position: "relative" }}>
      <HeatMap
        xLabels={xLabels}
        yLabels={yLabels}
        xLabelsLocation={"top"}
        xLabelsVisibility={xLabelsVisibility}
        xLabelWidth={60}
        yLabelWidth={50}
        yLabelTextAlign="left"
        data={data}
        squares={xLabels.length <= 27 ? false : true}
        height={32}
        width={64}
        onClick={(x, y) => { }}
        cellStyle={(background, value, min, max, data, x, y) => ({
          background: colors?.length
            ? colors[y][x]
            : getBackground(max, min, value),
          fontSize: "11.5px",
          color: "black",
          cursor: "default !IMPORTANT",
        })}
        cellRender={(value) =>
          value && <div style={{ width: "auto" }}>{value?.toFixed(2)}</div>
        }
        tooltip={false}
      />
    </div>
  );
};
