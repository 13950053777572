import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    imageSpacing: {
        padding: "24px 16px",
    },

    imageBackground: {
        backgroundColor: "#F7F7F7",
        borderRadius: 8,
        padding: theme.spacing(2),
    },
    imageHeader: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '17.6px',
        letterSpacing: '-0.02em',
        textAlign: 'left',
        paddingBottom: "16px"
    },
    imageSizeText: {
        paddingLeft: "10px",
        color: "#858E99",
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.02em',
        textAlign: 'left'
    },
    noImage: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.02em',
        textAlign: 'left',
        color: "#858E99"
    },
    imageName: {
        paddingLeft: "10px",
        paddingBottom: "5px",
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.02em',
        textAlign: 'left'
    }
}));