import React from "react";
import { FormControl, Grid } from "@mui/material";
import { AutocompleteCheckbox } from "../../../../../shared/components/AutocompleteCheckbox";
import { useStyles } from "./styles";
import { Button2 } from "../../../../../shared/components/Button2/Button2.component";
import Loader from "../../../../components/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CE_DEFINITION } from "../../utils";
import Zoom from "@mui/material/Zoom";

interface Props {
  title: string;
  data?: Array<any>;
  error?: string;
  loading?: boolean;
  height?: number | string;
  size?: any;
  definition?: any;
  styles?: any;
  type?: string;

  inverter: any;
  setInverter: any;
  mfm: any;
  setMfm: any;
  wms: any;
  setWms: any;
  getCrossData: any;
  showAlert: boolean;
}

export const EquipmentsWidget: React.FC<Props> = (props: Props) => {
  const {
    title,
    loading,
    inverter,
    setInverter,
    mfm,
    setMfm,
    wms,
    setWms,
    getCrossData,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleChangeInverterDevices = (event: any, value) => {
    setInverter({ ...inverter, devices: event });
  };
  const handleChangeInverterParameters = (event: any, value) => {
    setInverter({ ...inverter, parameters: event });
  };
  const handleChangeMfmDevices = (event: any, value) => {
    setMfm({ ...mfm, devices: event });
  };
  const handleChangeMfmParameters = (event: any, value) => {
    setMfm({ ...mfm, parameters: event });
  };

  const handleChangeWmsDevices = (event: any, value) => {
    setWms({ ...wms, devices: event });
  };

  const handleChangeWmsParameters = (event: any, value) => {
    setWms({ ...wms, parameters: event });
  };

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <Loader loading={loading} height={200} />
      ) : (
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <div className={classes.mainHeading}>{title}</div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={CE_DEFINITION}
                  arrow
                  placement="right"
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "12px",
                        lineHeight: "14.4px",
                        bgcolor: "white",
                        color: "black",
                        boxShadow: "4px 6px 24px rgba(12, 30, 51, 0.2);",
                        "& .MuiTooltip-arrow": {
                          color: "white",
                        },
                        padding: "14px",
                      },
                    },
                  }}
                >
                  <div
                    style={{
                      marginTop: "4px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={handleTooltipOpen}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </Grid>

          {/* Inverter */}
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={1}>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <div className={classes.heading}>Inverter</div>
                <AutocompleteCheckbox
                  label={"Select devices"}
                  value={inverter?.devices}
                  optionsData={inverter?.deviceOptions}
                  placeholder={"Inverter"}
                  handleChange={handleChangeInverterDevices}
                />
              </FormControl>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <AutocompleteCheckbox
                  label={"Select parameters"}
                  value={inverter?.parameters}
                  optionsData={inverter?.parameterOptions}
                  placeholder={"Inverter"}
                  handleChange={handleChangeInverterParameters}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* MFM */}

          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={1}>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <div className={classes.heading}>Energy Meters</div>
                <AutocompleteCheckbox
                  label={"Select devices"}
                  value={mfm?.devices}
                  optionsData={mfm?.deviceOptions}
                  placeholder={"Energy Meters"}
                  handleChange={handleChangeMfmDevices}
                />
              </FormControl>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <AutocompleteCheckbox
                  label={"Select parameters"}
                  value={mfm?.parameters}
                  optionsData={mfm?.parameterOptions}
                  placeholder={"Inverter"}
                  handleChange={handleChangeMfmParameters}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* WMS */}

          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={1}>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <div className={classes.heading}>Weather Monitoring System</div>
                <AutocompleteCheckbox
                  label={"Select devices"}
                  value={wms?.devices}
                  optionsData={wms?.deviceOptions}
                  placeholder={"Weather Monitoring System"}
                  handleChange={handleChangeWmsDevices}
                />
              </FormControl>
              <FormControl
                className={classes.selector}
                sx={{ m: 1, width: "100%" }}
              >
                <AutocompleteCheckbox
                  label={"Select parameters"}
                  value={wms?.parameters}
                  optionsData={wms?.parameterOptions}
                  placeholder={"WMS"}
                  handleChange={handleChangeWmsParameters}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent={"end"}>
            <div style={{ padding: "10px 12px 10px 10px" }}>
              <Button2 type="primary" onClick={getCrossData} size={40}>
                Submit
              </Button2>
              {/* {
                                    showAlert ?
                                        <Button
                                            title="Submit"
                                            color={"#0C1E33"}
                                            onClick={() => setOpenModal(true)}
                                        />
                                        :
                                        <Button
                                            title="Submit"
                                            color={"#0C1E33"}
                                            onClick={getCrossData}
                                        />
                                }
                                <ConfirmationModal
                                    isOpen={openModal}
                                    title="Warning"
                                    onClose={() => setOpenModal(false)}
                                    applyBtnTitle="Continue"
                                    applyColor="#C94B4B"
                                    onApply={() => {
                                        setOpenModal(false);
                                        getCrossData()
                                    }}
                                    message={`Data you want to visualize is large./n This may take some time to load. Do you want to continue?`}
                                /> */}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
