export const legendArr = [
    {
        name: "Negligible",
        color: "#F3F2EF",
    },
    {
        name: "Low",
        color: "#FAE7CB",
    },
    {
        name: "Medium",
        color: "#F6D5AF",
    },
    {
        name: "High",
        color: "#F4BE7E",
    },
    {
        name: "Critical",
        color: "#F3A124",
    },
];

export const colors = ['#97CEFD', '#0C1E33', '#1572DF', '#34A114', '#966BF6',
    '#FF5500', "#43A588", "#FFC107", "#9E5F53", "#95AFCE", "#CDC57D", "#FF8E8D"];