import React from "react";
import { Grid } from '@material-ui/core';
import Widget from "../../../../components/Widget";
import CandleChart from "../../../../components/CandleChart";
import SimpleLineChart, {
  SimpleLineChartItem,
} from "../../../../components/SimpleLineChart";
import Loader from "../../../../components/Loader";
import WidgetItem from "../../../../components/WidgetItem";
import { useStyles } from "./styles";
import { CustomTooltip } from "./CustomTooltip.component";
import {
  label,
  label1,
  RealtimeItem,
  useRealtimePerformance,
} from "./useRealtimePerformance";
import { getYesterday } from "../../../../../shared/utils/utils";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const RealTimePerformanceWidget: React.FC<Props> = ({
  plantId,
  date,
  definition,
}) => {
  const classes = useStyles();
  const { client } = useSelector(clientSelector);

  const {
    realTime,
    yesterday,
    last60minutes,
    last24Hours,
    last30Days,
    last7Days,
  } = useRealtimePerformance({ plantId, date });

  const getLabel = (val: number) => {
    if (typeof val !== "number") return null;

    const stl = val >= 0 ? classes.menuLabel : classes.negativeLabel;

    const num = Math.abs(val % 1) > 0.05 ? val.toFixed(1) : val.toFixed(0);
    const value = (val >= 0 ? "+" : "") + num + "%";

    return <div className={stl}>{value}</div>;
  };
  const getBulletChartLabel = (val: number, data: any) => {
    if (typeof val !== "number") return null;

    const stl = val >= 0 ? classes.menuLabel : classes.negativeLabel;

    const num = Math.abs(val % 1) > 0.05 ? val.toFixed(1) : val.toFixed(0);
    const value = (val >= 0 ? "+" : "") + num + "%";
    const actualResult = data[label] * 100;
    const expectedResult = data[label1] * 100;

    return (
      <div className={classes.bulletChartLabel}>
        <div className={stl}>{value}</div>
        <div style={{ display: "flex" }}>
          <div className={classes.bulletChartLabelItem}>
            <span className={classes.bulletChartLabelTitle}>Actual PR</span>
            <span className={classes.bulletChartLabelValue}>
              {actualResult.toFixed(2)}%
            </span>
          </div>
          <div className={classes.bulletChartLabelItem}>
            <span className={classes.bulletChartLabelTitle}>Expected PR</span>
            <span className={stl}>{expectedResult.toFixed(2)}%</span>
          </div>
        </div>
      </div>
    );
  };

  const getBulletChartItem = (
    { data, result, loading, error }: RealtimeItem<SimpleLineChartItem>,
    title = "",
    showMenu = false
  ) => (
    <WidgetItem title={title} menu={null} definition={definition}>
      <Loader loading={loading} error={error} />
      {!data || loading ? null : (
        <div>
          {getBulletChartLabel(result, data)}
          <CandleChart
            actualResult={data[label] * 100}
            expectedResult={data[label1] * 100}
          />
        </div>
      )}
    </WidgetItem>
  );

  const getLineChart = (
    { data, result, loading, error }: RealtimeItem<Array<SimpleLineChartItem>>,
    title = "",
    getTooltipLabel: (val: string) => React.ReactNode
  ) => (
    <WidgetItem title={title} menu={getLabel(result)} definition={definition}>
      <Loader loading={loading} error={error} />
      {!data?.length || loading ? null : (
        <SimpleLineChart
          label={label}
          data={data}
          tooltipContent={<CustomTooltip getLabel={getTooltipLabel} />}
        />
      )}
    </WidgetItem>
  );

  const getTimeLabel = (timeStamp: string) => {
    const label = timeStamp?.split(" ")[1];
    return (
      <div style={{ fontWeight: 500, fontSize: 12, marginTop: 4 }}>
        Time: {label}
      </div>
    );
  };

  const getDateLabel = (timeStamp: string) => {
    const correctedTimestamp = timeStamp?.split(" ")[0];
    return (
      <div style={{ fontWeight: 500, fontSize: 12, marginTop: 4 }}>
        Date: {correctedTimestamp}
      </div>
    );
  };

  return (
    <Widget
      label="Real Time Performance Ratio"
      size={5}
      styles={classes.realTimeWidget}
      height={600}
      definition={definition}
    >
      <Grid container xs={12} md={12} lg={12} direction="row">
        <Grid item xs={12} md={12} lg={12}>
          {getBulletChartItem(realTime, "Today's PR, delta", true)}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {getBulletChartItem(yesterday, `${getYesterday(client?.timeZone, date)}`, true)}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ marginBottom: "30px" }}>
            {getLineChart(last24Hours, "Last 24 hours PR", getTimeLabel)}
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ marginBottom: "30px" }}>
            {getLineChart(last7Days, "Last 7 days PR", getDateLabel)}
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ marginBottom: "65px" }}>
            {getLineChart(last30Days, "Last 30 days PR", getDateLabel)}
          </div>
        </Grid>
      </Grid>
    </Widget>
  );
};
