import React from "react";
import { useSelector } from "react-redux";
import { errorSelector } from "../../features/Notifications/errorSlice";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMsal } from "@azure/msal-react";
import posthog from "posthog-js";

const useStyles = makeStyles((theme: Theme) => ({
  helpWrapper: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "16px",
    margin: "16px",
    height: "calc(100vh - 84px)",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  heading: {
    padding: "10px 0 6px 0",
    fontSize: "20px",
  },
  subHeading: {
    width: "250px",
    margin: "0 auto",
    color: "#858E99",
  },
  logoutLink: {
    textDecoration: "none",
    color: "#1572DF",
    padding: "16px 0 0 0",
    margin: "0 auto",
    width: "fit-content",
    borderRadius: "0px",
    transition: "all 0.05s",
    position: "relative",
    borderBottom: "2px solid transparent",
    "&:hover": {
      borderBottom: "2px solid #1572df",
    }
  }
}));

export const ErrorFallback = () => {
  const { errorMessage } = useSelector(errorSelector);
  posthog.capture("Error: " + (errorMessage ? errorMessage : "No permissions to view page"));
  return (
    <CustomError title="We've encountered an error" errorMessage={errorMessage || "Something unexpected happened."}></CustomError>
  );
};

export const CustomError = ({ title, errorMessage }: { title: string, errorMessage: string }) => {
  const classes = useStyles();
  const { instance } = useMsal();
  const logOut = () => instance.logoutRedirect().catch(e => console.log(e));
  return (
    <div>
      <div className={classes.helpWrapper}>
        <div>
          <img alt="Skyfri logo with error" src="/icons/skyfri-logo-error.svg" />
        </div>
        <div className={classes.heading}>{title}</div>
        <div className={classes.subHeading}>{errorMessage}</div>
        <a className={classes.logoutLink} href="#" onClick={logOut}>Go back to login screen</a>
      </div>
    </div>
  );
};