import { TooltipWrapper } from '../../../../components/TooltipWrapper';

export const CustomTooltip = ({ active, payload, getLabel }: { active?: boolean, payload?: Array<any>, getLabel: (val: string) => React.ReactNode }) => {
  if (!active)
    return null;

  const data = payload[0]?.payload;

  return (
    <TooltipWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgray', paddingBottom: 8 }}>
        <div style={{ fontWeight: 500, fontSize: 12, marginRight: 16 }}> PR Δ: {(data?.daily_pr_delta * 100)?.toFixed(0)} %</div>
        <div style={{ fontWeight: 500, fontSize: 12 }}>{getLabel(data?.name)}</div>
      </div>

      <div style={{ fontWeight: 500, fontSize: 12, marginTop: 4 }}>Performance: <span style={{ fontWeight: 500 }}>{(data?.actual_pr * 100)?.toFixed(0)}%</span></div>
      {/* <div style={{ fontWeight: 200, fontSize: 12, marginTop: 4 }}>Active incidents: <span style={{ fontWeight: 500 }}> {data?.active_incidents} </span></div> */}
      <div style={{ fontWeight: 500, fontSize: 12, marginTop: 4 }}>Availability: {(data?.availability * 100)?.toFixed(0)} % </div>
    </TooltipWrapper>
  )
};