import { useSelector } from "react-redux";
import {
  autoRefreshApi,
  convertTimeZone,
} from "./../../../../../shared/utils/utils";
import { useEffect, useState } from "react";
import { Lines } from "./data";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: Array<any>;
  loading: boolean;
  error: string;
}

export const usePRIrradiationWidget = ({
  plantId,
  date,
}: Props): Data => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);

  const sortByTimeStamp = (arr: Array<{ timeStamp: string }>) => {
    return arr?.sort((a, b) => {
      const [date1] = a.timeStamp.split(" ");
      const [date2] = b.timeStamp.split(" ");
      const getTime = (str: string) => new Date(str)?.getTime();

      return getTime(date1) > getTime(date2) ? 1 : -1;
    });
  };

  const get24hrPRandIrrTrend = () => {
    fetchAsync(
      `SkyfriSidemApi/v1/Get24hrPRandIrrTrend?plantId=${plantId}&Date=${date}`,
      "GET"
    )
      .then((data: Array<any>) => {
        setLoading(true);
        const sortedData: any = sortByTimeStamp(data);
        if (sortedData.length <= 0) setError("No Data");
        setData(
          sortedData?.flat().map((item) => ({
            sensor_time: convertTimeZone(
              client?.timeZone,
              "DD.MM.YY HH:mm",
              item.timeStamp
            ),
            [Lines.PR]: (item?.actualPerformanceRatio * 100)?.toFixed(1),
            [Lines.Irradiation]: item?.sumIrradianceVertical?.toFixed(1),
          }))
        );
        setLoading(false);
      })
      .catch(setError);
  };

  useEffect(() => {
    if (!client) {
      setLoading(true);
      return;
    }
    setLoading(true);
    setError("");
    get24hrPRandIrrTrend();
    const myInterval = autoRefreshApi(() => get24hrPRandIrrTrend());
    return () => clearInterval(myInterval);
  }, [date, client]);

  return {
    data,
    loading,
    error,
  };
};
