import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  notification: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    color: "black",
  },
  layoutNotification: {
    marginBottom: theme.spacing(1),
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  displayFlex: {
    display: "flex",
  },

  leftPart: {
    marginTop: theme.spacing(0.2),
    marginLeft: theme.spacing(0.4),
  },
  rightPart: {
    marginTop: theme.spacing(0.5),
  },

  id: {
    marginTop: theme.spacing(0.2),
    fontSize: 10,
    fontStyle: "italic",
  },

  content: {
    flex: 1,
  },

  title: {
    wordBreak: "break-all",
    fontSize: 16,
    width: "100px",
    fontWeight: 700,
    lineHeight: "16px",
  },
  layoutTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "21px",
    paddingTop: theme.spacing(0.2),
  },
  layoutValue: {
    fontWeight: 600,
  },
  description: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    marginLeft: theme.spacing(1.4),
  },
  capitilize: {
    textTransform: "lowercase",
  },

  dateAndStatus: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  status: {
    display: "flex",
  },

  statusLabel: {
    fontStyle: "italic",
    fontSize: 10,
    marginRight: theme.spacing(0.2),
  },

  statusValue: {
    fontWeight: 600,
    fontSize: 10,
  },
  downPart: {
    display: "flex",
    flexDirection: "column",
  },
  date: {
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "15.6px",
    opacity: 0.5,
    paddingTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.4),
  },
  genLoss: {
    fontSize: 14,
    marginLeft: theme.spacing(1.4),
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "14px",
  },
  approxGeneraion: {
    fontSize: 9,
    fontWeight: "bold",
  },
  tooltipHeader: {
    color: "black",
    padding: 0,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  hrLine: {
    opacity: 0.1,
    color: "#0C1E33",
    border: "none",
    height: "2px",
    backgroundColor: "#0C1E33",
  },
}));
