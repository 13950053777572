import { useEffect, useState } from "react";
import { fetchAsync } from "../../../shared/utils/apiService";

interface Data {
  role: any;
  deleteRoles: (id: any, callback: any) => void;
  loading: any;
}

export const useRole = (): Data => {
  const [role, setRole] = useState(null);
  const [loading, setloading] = useState(null);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    setloading(true);
    fetchAsync(`onboardingV2/v1/Roles/GetAllRoles`, "GET")
      .then((data: any) => {
        if (data) {
          let newRole = data?.map(item => ({
            ...item,
            id: item.roleId
          }));
          setRole(newRole);
        }
        setloading(false);
      })
      .catch((e) => { console.error(e); setloading(false); });
  };

  const deleteRoles = async (id: any, callback: any) => {
    setloading(true);
    fetchAsync(`/Role/?id=${id}`, "DELETE")
      .then((data: any) => {
        getRoles();
        callback(data);
      })
      .catch((e) => { console.error(e); setloading(false); callback(e) });
  };

  return {
    role,
    deleteRoles,
    loading
  };
};
