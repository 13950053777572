import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";

export interface ICrossEquimentComparisonState {

    globalDate?: {
        startDate: Date;
        endDate: Date;
        key: string;
    }[];
    dateEqual?: boolean;
    liveButton?: boolean;

    globalPlantId?: {
        label: string | number;
        value: string | number;
    }[];
    globalPortfolioName?: {
        label: string | number;
        value: string | number;
    }[];

    plantTimeZone?: string;

}

const initialState: ICrossEquimentComparisonState = {
    globalDate: [{
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    }],
    liveButton: true,

    globalPlantId: [],

    globalPortfolioName: null,

    dateEqual: true,

    plantTimeZone: null,
};

// name
// reducer
// actions
// caseReducers
export const monitoringGenerationSlice = createSlice({
    name: "monitoringgeneration",
    initialState,
    reducers: {
        setGlobalDate: (state, { payload }) => {
            return {
                ...state,
                globalDate: payload,
            };
        },
        setLiveButton: (state, { payload }) => {
            return {
                ...state,
                liveButton: payload,
            };
        },
        getPlantId: (state, { payload }) => {
            return {
                ...state,
                globalPlantId: payload,
            };
        },
        dateComparison: (state, { payload }) => {
            return {
                ...state,
                dateEqual: payload,
            };
        },
        getPortfolioName: (state, { payload }) => {
            return {
                ...state,
                globalPortfolioName: payload,
            };
        },
        getPlantTimeZone: (state, { payload }) => {
            return {
                ...state,
                plantTimeZone: payload,
            };
        },
    },
});

export const {
    setGlobalDate,
    setLiveButton,
    getPlantId,
    dateComparison,
    getPortfolioName,
    getPlantTimeZone,
} = monitoringGenerationSlice.actions;

export const monitoringGenerationSelector = (state: RootState) =>
    state.Monitoring.MonitoringGeneration;

// MonitoringDashboard reducer

export const monitoringGenerationReducer = monitoringGenerationSlice.reducer;