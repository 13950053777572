import React, { useState, useEffect } from 'react'
import { useStyles } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsPagesEnum } from '.';
import { Typography } from '@mui/material';

interface ClientInfo {
    clientLogo: string;
    clientName: string;
    portfolioImages: string;
    pr: number;
    budget_PR: number;
    revenue: number;
    portfolios: number;
    timeZone: string;
}


export const AnalyticsNavigation: React.FC<any> = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const [clientInfo, setClientInfo] = useState({} as ClientInfo);
    const [portfolioName, setPortfolioName] = useState("");
    const [plantName, setPlantName] = useState("");
    const [plantId, setPlantId] = useState(null);
    const [inverterId, setInverterId] = useState(null);
    const [blockId, setBlockId] = useState(null);
    const [title, setTitle] = useState("");

    const goTo = (url: AnalyticsPagesEnum) => {
        switch (url) {
            case AnalyticsPagesEnum.AnalyticsDashboard:
                return navigate(AnalyticsPagesEnum.AnalyticsDashboard);
        }
    };

    const getNavigationDetails = async () => {
        const pathname = location?.pathname;
        const url = pathname?.split("/");

        const portfolioId = url[3];
        const plantId = url[5];
        const blockId = url[7];
        const inverterId = url[9];

        if (!portfolioId) {
            setInverterId(null);
            setBlockId(null);
            setPlantName("");
            setPlantId(null);
            return setPortfolioName("");
        }
        setPortfolioName(portfolioId);

        if (!plantId) {
            setBlockId(null);
            setInverterId(null);
            setPlantName("");
            setPlantId(null);
            return;
        }
        setPlantId(plantId);

        let data; //clean this!
        if (data) {
            setPlantName(data?.plantName);
        }

        if (!inverterId || !blockId) {
            setInverterId(null);
            setBlockId(null);
            return;
        }
        setInverterId(inverterId);
        setBlockId(blockId);
    };

    useEffect(() => {
        if (!location) return;

        getNavigationDetails();
    }, [location]);

    const getData = async () => {
        let data; //clean this!
        setClientInfo(data);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (location?.pathname?.includes("/analytics-dashboard")) {
            setTitle(`Dashboard`);
            return;
        }
    }, [location]);

    return (
        <>
            <Typography
                className={classes.subtitle}
                variant="body1"
                noWrap
                onClick={() =>
                    location?.pathname?.includes("/analytics-dashboard")
                        ? goTo(AnalyticsPagesEnum.AnalyticsDashboard)
                        : null
                }
            >
                {title}
            </Typography>
        </>
    )
}
