import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { monitoringDashboardSelector } from "../../../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/rootStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GLOBAL_COLORS,
  convertTime,
  getHoursAndMinutesFromISO,
  getShortenedNumber,
  isAnyItemFalsy,
} from "../../../../../shared/utils/utils";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatterySocHistoricalQuery,
  useGetBatterySteeringSignalsCountQuery,
  useGetBatterySteeringSignalsQuery,
} from "../../BatteryMonitoring.api";
import { ENERGY_PROVIDERS } from "../../BatteryMonitoring.api.types";
import { chartOptions } from "../../../../../shared/utils/chartOptions";
import {
  BatteryControlChartWidgetData,
  ProviderSerie,
} from "./BatteryControlChartWidget.types";
import {
  fillGapsBetweenDate,
  getColorForProvider,
  CHART_COLORS,
  groupByGranularity,
  getBatteryControlGranularity,
  getXAxisCategories,
  tooltipFormatter,
} from "./utils";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsAnnotations from "highcharts/modules/annotations";
import HighchartsStock from "highcharts/modules/stock";
import { useUrlParams } from "../../../../../shared/utils/hooks";
import { useStyles } from "../../styles";
import { fillGapsBetweenTimestamps, getPinpointShape } from "../../utils";
import { Order } from "../../../../../shared/interfaces";
import { capitalizeFirstLetter } from "../../../../../onBoarding/pages/components/Utils/utils";
import { useDataManipulation } from "../../../BatteryDetails/components/BatteryControlTable/hooks";
import { BatteryControlTableData } from "../../../BatteryDetails/components/BatteryControlTable/BatteryControlTable.types";
import { MonitoringPagesEnum } from "../../..";

HighchartsAccessibility(Highcharts);
HighchartsStock(Highcharts);
HighchartsMore(Highcharts);
HighchartsAnnotations(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.pinpoint = getPinpointShape;

export const useBatteryControlChartWidget =
  (): BatteryControlChartWidgetData => {
    const navigate = useNavigate();

    const { plantId, startDate, endDate } = useUrlParams({
      plantId: "",
      startDate: "",
      endDate: "",
    });

    const [order, setOrder] = useState<Order>("desc");
    const [orderBy] = useState<keyof BatteryControlTableData>("date");

    const fromDate = useMemo(() => {
      return convertTime(startDate, "YYYY.MM.DD");
    }, [startDate]);

    const toDate = useMemo(() => {
      return convertTime(endDate, "YYYY.MM.DD");
    }, [endDate]);

    const granularity = getBatteryControlGranularity(startDate, endDate);

    const { liveButton, globalDate, globalPortfolioName } = useAppSelector(
      monitoringDashboardSelector
    );

    const [options, setOptions] = useState({
      chart: {
        events: {},
      },
      title: {
        text: "Battery control",
        align: "left",
        x: -4,
        y: 24 + 12,
        margin: 52,
        style: {
          fontWeight: 500,
          fontSize: "20px",
          fontFamily: "Inter",
        },
      },
      navigation: {},
      lang: {
        noData: "",
      },
      tooltip: {},
    });

    const {
      data: dataSoc,
      isLoading: isLoadingSoc,
      isFetching: isFetchingSoc,
      isError: isErrorSoc,
      isUninitialized: isUninitializedSoc,
    } = useGetBatterySocHistoricalQuery(
      {
        fromDate,
        toDate: convertTime(endDate, "YYYY.MM.DD"),
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        // TODO: kaz implement dropdownValue?
        granularity: "0",
      },
      {
        skip: isAnyItemFalsy([
          startDate,
          endDate,
          plantId,
          DEFAULT_EQUIPMENT_NAME,
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const {
      data: dataBatteryControl,
      isLoading: isLoadingBatteryControl,
      isFetching: isFetchingBatteryControl,
      isError: isErrorBatteryControl,
    } = useGetBatterySteeringSignalsQuery(
      {
        plantId,
        fromDate,
        toDate,
        search: "",
        pageSize: 10000,
        pageNumber: 0,
        source: "",
        notExecutedOnly: "false",
        equipmentName: DEFAULT_EQUIPMENT_NAME,
        sort: capitalizeFirstLetter(order) as "Asc" | "Desc",
      },
      {
        skip: isAnyItemFalsy([
          plantId,
          fromDate,
          toDate,
          DEFAULT_EQUIPMENT_NAME,
        ]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const {
      data: dataBatteryControlCount,
      isLoading: isLoadingBatteryControlCount,
      isFetching: isFetchingBatteryControlCount,
      isUninitialized: isUninitializedBatteryControlCount,
      isError: isErrorBatteryControlCount,
    } = useGetBatterySteeringSignalsCountQuery(
      {
        plantId,
        fromDate,
        toDate,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
      },
      {
        skip: isAnyItemFalsy([
          plantId,
          fromDate,
          toDate,
          DEFAULT_EQUIPMENT_NAME,
        ]),

        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

    const isLoading = useMemo(() => {
      return (
        isLoadingSoc || isLoadingBatteryControl || isLoadingBatteryControlCount
      );
    }, [isLoadingSoc, isLoadingBatteryControl, isLoadingBatteryControlCount]);

    const isFetching = useMemo(() => {
      return (
        isFetchingSoc ||
        isFetchingBatteryControl ||
        isFetchingBatteryControlCount
      );
    }, [
      isFetchingSoc,
      isFetchingBatteryControl,
      isFetchingBatteryControlCount,
    ]);

    const { parsedData } = useDataManipulation(
      dataSoc,
      dataBatteryControl,
      orderBy,
      order
    );

    const batteryControlDataGrouped = useMemo(() => {
      if (!parsedData.length) {
        return [];
      }

      const sorted = parsedData.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      return groupByGranularity(sorted, startDate, endDate);
    }, [parsedData, startDate, endDate]);

    const labelsPositioner = useCallback(() => {
      return fillGapsBetweenTimestamps(startDate, endDate, granularity).map(
        (date) => Date.parse(String(date))
      );
    }, [startDate, endDate, granularity]);

    const providersSeries = useMemo(() => {
      let seriesObj: {
        [key: string]: ProviderSerie;
      } = {};

      batteryControlDataGrouped?.forEach((period, index) => {
        const providersKeys = Object.keys(period.providers);
        const providerEnum =
          providersKeys.length > 1
            ? ENERGY_PROVIDERS["Multiple sources"]
            : providersKeys[0];

        let label = ENERGY_PROVIDERS[providerEnum];

        if (!label) {
          // TODO: kaz if label is unknown, it means that there was no results in specified period, so just skip the iteration
          return;
        }

        label =
          label +
          `${
            dataBatteryControlCount?.[providerEnum] ||
            dataBatteryControlCount?.[providerEnum] === 0
              ? ` (${dataBatteryControlCount?.[providerEnum]})`
              : ""
          }`;

        if (!seriesObj[providerEnum]) {
          seriesObj[providerEnum] = {
            data: [],
            linecap: "square",
            zoneAxis: "x",
            color: getColorForProvider(providerEnum),
            step: "left",
            lineWidth: 8,
            name: label || "Unknown",
            enableMouseTracking: false,
            tooltip: {
              enable: false,
              shared: false,
            },
            yAxis: 0,
          };
        }
        seriesObj[providerEnum].data.push({
          x: period.startDate,
          startDate: period.startDate,
          endDate: period.endDate,
          index: index,
          y: 0,
          color: getColorForProvider(providerEnum),
          name: label || "Unknown",
        });
      });

      return seriesObj;
    }, [batteryControlDataGrouped, dataBatteryControlCount]);

    const socData = useMemo(() => {
      return (
        (
          dataSoc?.items?.map((val) => {
            return {
              x: val.date,
              y: val.value,
            };
          }) || []
        ).flat() || []
      );
    }, [dataSoc]);

    const filledProvidersSeries = useMemo(() => {
      const result: ProviderSerie[] = [
        ...Object.values(
          fillGapsBetweenDate(providersSeries, batteryControlDataGrouped)
        ),
      ];

      return result.map((provider) => {
        return {
          ...provider,
          data: provider.data.map((item) => {
            return {
              x: item.x,
              y: item.y,
              name: item.name,
              color: item.color,
            };
          }),
        };
      });
    }, [providersSeries, batteryControlDataGrouped]);

    const socSeries = useMemo(() => {
      const result: ProviderSerie = {
        type: "spline",
        lineWidth: 2,
        linecap: "square",
        name: "SoC",
        step: "left",
        zoneAxis: "x",
        data: socData,
        yAxis: 1,
        enableMouseTracking: false,
        id: "soc-series",
        color: CHART_COLORS.SOC,
        connectNulls: true,
        marker: {
          symbol: "diamond",
        },
        tooltip: {
          shared: false,
          enable: false,
        },
      };

      return result;
    }, [socData]);

    const flagsSeries: ProviderSerie = useMemo(() => {
      const timestampFlatData = batteryControlDataGrouped
        .filter((item) => item.areProvidersReal)
        .map((item) => {
          const providers = Object.values(item.providers);
          const providerEnum =
            providers.length > 1 ? "Multiple sources" : providers[0][0].source;

          return {
            items: providers.flat(),
            startDate: item.startDate,
            provider: providerEnum,
          };
        });

      const data = timestampFlatData.map((item, index) => {
        return {
          x: item.startDate,
          y: 0,
          title: getShortenedNumber(item.items.length),
          color: getColorForProvider(item.provider),
          fillColor: getColorForProvider(item.provider),
          style: {
            color: "#FFFFFF",
          },
        };
      });

      return {
        type: "flags",
        y: -44,
        allowOverlapX: true,
        name: "flags",
        id: "flags",
        onSeries: "soc-series",
        shape: "pinpoint",
        data: data,
        enableMouseTracking: true,
        linkedTo: "soc-series",
        width: 28,
        height: 28,
        zIndex: 10,
        states: {
          hover: {
            enabled: false,
          },
        },
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
        dataLabels: {
          align: "center",
          verticalAlign: "top",
        },
      };
    }, [batteryControlDataGrouped]);

    const seriesWithXParsedToDate = useMemo(() => {
      const result: ProviderSerie[] = [
        ...filledProvidersSeries,
        socSeries,
        flagsSeries,
      ].map((serie) => {
        return {
          ...serie,
          data: serie.data.map((item) => {
            return {
              ...item,
              x: item.x ? Date.parse(String(item.x)) : null,
            };
          }),
        };
      });

      return result;
    }, [filledProvidersSeries, socSeries, flagsSeries]);

    const formatter = useMemo(() => {
      return tooltipFormatter(batteryControlDataGrouped);
    }, [batteryControlDataGrouped]);

    const getChart = useCallback(() => {
      const xAxis = {
        minRange: 1,
        ordinal: false,
        categories: [],
        endOnTick: false,
        startOnTick: false,
        showLastLabel: true,
        type: "datetime",
        tickPositioner: labelsPositioner,
        crosshair: {
          width: 1,
          color: GLOBAL_COLORS.DATA_VIZ_GRAY_30,
        },
        labels: {
          formatter: function () {
            return getXAxisCategories(
              this.value,
              batteryControlDataGrouped,
              granularity
            );
          },
        },
        events: {},
      };

      const yAxis = [
        {
          startOnTick: false,
          endOnTick: false,
          visible: false,
          opposite: false,
          allowDecimals: true,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: "Signals",
          },
          threshold: 0,
          min: 0,
          max: 10,
        },
        {
          startOnTick: false,
          endOnTick: false,
          visible: true,
          opposite: false,
          allowDecimals: true,
          type: "linear",
          gridLineWidth: 0,
          title: {
            text: "%",
          },
          threshold: 0,
          min: 0,
          max: 120,
          tickInterval: 20,
          showLastLabel: false,
        },
      ];

      const plotOptions = {
        flags: {
          accessibility: {
            exposeAsGroupOnly: true,
            description: "Flagged events.",
          },
        },
        series: {
          turboThreshold: 0,
          states: {
            inactive: {
              opacity: 0.5,
            },
            hover: {
              opacity: 1,
            },
          },
          connectNulls: false,
          marker: {
            enabled: false,
            inactive: { enabled: false },
            hover: {
              enabled: false,
              fill: "transparent",
            },
          },
        },
        line: {
          series: {
            connectNulls: false,
          },
          inactive: { enabled: false },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      };

      const tooltip = tooltipFormatter(batteryControlDataGrouped);

      return {
        xAxis,
        yAxis,
        tooltipFormatter: tooltip,
        series: seriesWithXParsedToDate,
        plotOptions,
      };
    }, [
      labelsPositioner,
      batteryControlDataGrouped,
      granularity,
      formatter,
      seriesWithXParsedToDate,
      isLoading,
      isFetching,
    ]);

    useEffect(() => {
      if (!dataBatteryControl || !dataSoc || !dataBatteryControlCount) {
        return;
      }

      const loading =
        isLoadingBatteryControl ||
        isLoadingSoc ||
        isFetchingSoc ||
        isFetchingBatteryControl;

      const title = "Battery control";

      const { xAxis, yAxis, tooltipFormatter, series, plotOptions } =
        getChart();

      const newOptions = {
        ...chartOptions({
          title,
          colors: [],
          loading: loading,
          xAxis: xAxis,
          yAxis: yAxis,
          tooltipFormatter: tooltipFormatter,
          plotOptions: plotOptions,
          data: series,
        }),
      };

      const overridenOptions = {
        ...newOptions,
        chart: {
          ...newOptions.chart,
          styledMode: true,
          zooming: { type: "x" },
          events: {
            load() {
              if (isLoading || isFetching) {
                this.showLoading();
              } else {
                this.hideLoading();
              }
            },
            redraw() {
              if (isLoading || isFetching) {
                this.showLoading();
              } else {
                this.hideLoading();
              }
            },
          },
        },
        loading: {
          showDuration: 0,
          hideDuration: 1000,
          labelStyle: {
            fontSize: "16px",
          },
        },
        tooltip: {
          ...newOptions.tooltip,
          formatter: tooltipFormatter,
        },
        title: {
          text: "Battery control",
          align: "left",
          x: -4,
          y: 24 + 12,
          margin: 52,
          style: {
            fontWeight: 500,
            fontSize: "20px",
            fontFamily: "Inter",
          },
        },
        navigation: {
          buttonOptions: {
            y: 16,
            x: -16,
          },
        },
      };
      setOptions(overridenOptions);
    }, [
      dataBatteryControl,
      dataSoc,
      dataBatteryControlCount,
      isLoadingBatteryControl,
      isLoadingSoc,
      isLoadingBatteryControlCount,
      isFetchingBatteryControl,
      isFetchingBatteryControlCount,
      isFetchingSoc,
      batteryControlDataGrouped,
      getChart,
    ]);

    const buttonGroups = useMemo(() => {
      return [
        {
          buttons: [
            {
              label: "Table view",
              onClick: () => {
                navigate(
                  `${MonitoringPagesEnum.BatteryDetails}${window.location.search}`
                );
              },
              active: false,
              style: {
                border: `1px solid ${GLOBAL_COLORS.LIGHT_PRIMARY_GRAY_40}`,
              },
            },
          ],
        },
      ];
    }, []);

    return {
      plantId,
      startDate,
      endDate,
      liveButton,
      globalDate,
      buttonGroups: buttonGroups,
      chartOptions: options,
    };
  };
