import { Cell } from "../../../../shared/components/Table";

const x1: Cell = {
  key: "name",
  label: "Name",
  align: "left",
};


export const mockUpData = {
  headerCells: [x1],
  data: [

    {
      name: "Daily Generation report",
    },
    {
      name: "Performance report",
    },
  ],
  tableType: "contacts",
  actionType: "dropdown",
};