import React from 'react';

interface SvgProps {
    height?: number | string;
    width?: number | string;
}

export const CustomPlantIcon = (props: SvgProps) => {
    const { height, width } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L1.41421 8.58579C0.633165 9.36683 0.633163 10.6332 1.41421 11.4142L5 15M5 5L8.58579 1.41421C9.36684 0.633163 10.6332 0.633165 11.4142 1.41421L15 5M5 5L15 15M15 15L18.5858 11.4142C19.3668 10.6332 19.3668 9.36684 18.5858 8.58579L15 5M15 15L11.4142 18.5858C10.6332 19.3668 9.36683 19.3668 8.58579 18.5858L5 15M15 5L5 15" stroke="#0C1E33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};