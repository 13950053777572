import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { RadioButton, Typography } from "../../../../../shared/components";

interface selectProps {
  name: string;
  label?: string;
  subLabel?: string;
  value: string;
  formik: any;
  isChecked: boolean,
  options?: Array<string>;
  default?: any;
}

export const RenderRadio: React.FC<selectProps> = (props) => (

  <Typography label={props.label} subLabel={props.subLabel}>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
    >
      {
        props.formik.values[props.name] == "" ?
          props.options.map(x => {
            props.formik.values[props.name] = props.default;
            return (
              <RadioButton default={props.default} checked={props.formik.values[props.name] == x || props.default == x ? true : false} value={x} customLabel={x} onChange={(newval: string): void => {
                props.formik.setFieldValue(props.name, newval);
              }} />
            )
          }
          )
          :
          props.options.map(x =>
            <RadioButton default={props.default} checked={props.formik.values[props.name] == x ? true : false} value={x} customLabel={x} onChange={(newval: string): void => {
              props.formik.setFieldValue(props.name, newval);
            }} />)

      }
    </RadioGroup>


  </Typography>
);
