import { makeStyles } from '@material-ui/core/styles';
import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";

export const useStyles = makeStyles((theme) => ({
  realTimeWidget: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },

  chartsContainer: {
    flex: 1,
    display: "grid",
    overflow: "hidden",
    gridTemplateRows: "1fr 1fr 3fr 3fr 3fr",
    gridGap: theme.spacing(2),
  },

  menuLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: GRAPH_COLORS.success,
  },

  negativeLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: GRAPH_COLORS.error,
  },
  bulletChartLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bulletChartLabelItem: {
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    marginLeft: "1em",
  },
  bulletChartLabelTitle: {
    fontSize: 12,
    fontWeight: 500,
    color: GRAPH_COLORS.darkGray,
  },
  bulletChartLabelValue: {
    fontSize: 14,
    fontWeight: 700,
    color: GRAPH_COLORS.darkGray,
  },
}));
