import { SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { sources } from "./utils";
import { Order } from "../../../../../shared/interfaces";

import { BatteryControlTableData } from "./BatteryControlTable.types";
import { findClosestItem } from "../../../BatteryMonitoring/utils";
import { BATTERY_CONTROL_STATUS, BatterySocHistoricalResponse, BatterySteeringSignalsTransformedResponse } from "../../../BatteryMonitoring/BatteryMonitoring.api.types";

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDownload() {
  const downloadLoading = false;
  const downloadError = false;

  const handleDownload = useCallback(() => {
    alert("TODO: download data");
  }, []);

  return { downloadLoading, downloadError, handleDownload };
}

export function usePage() {
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageReset = useCallback(() => {
    setPageNumber(0);
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  return { pageNumber, handlePageReset, handlePageChange };
}

export function useFilters(handlePageReset: () => void) {
  const [search, setSearch] = useState("");
  const [sourceFilter, setSourceFilter] = useState<string[]>([]);
  const [notExecutedOnly, setNotExecutedOnly] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  const handleSourceFilterChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes("Show all")) {
      if (value.length === 1) {
        setSourceFilter(sources);
      } else if (value.length > 1 && value.length - 1 < sources.length) {
        setSourceFilter(sources);
      } else if (value.length > 1 && value.length - 1 === sources.length) {
        setSourceFilter([]);
      }
    } else {
      setSourceFilter(value);
    }
    handlePageReset();
  };

  const handleNotExecutedOnlyChange = useCallback(
    (isChecked: boolean) => {
      setNotExecutedOnly(isChecked);
      handlePageReset();
    },
    [handlePageReset]
  );

  const handleSearchChange = useCallback(
    (val: string) => {
      setSearch(val);
      handlePageReset();
    },
    [handlePageReset]
  );

  useEffect(() => {
    if (sources.length === 1) {
      setSourceFilter(sources);
    }
  }, [sources]);

  return {
    search,
    sourceFilter,
    notExecutedOnly,
    debouncedSearch,
    handleSourceFilterChange,
    handleNotExecutedOnlyChange,
    handleSearchChange,
  };
}

export function useDataManipulation(
  dataSoc: BatterySocHistoricalResponse,
  dataBatteryControl: BatterySteeringSignalsTransformedResponse,
  orderBy: string,
  order: string
) {
  const parsedData: BatteryControlTableData[] = useMemo(() => {
    if (!dataSoc || !dataBatteryControl) {
      return [];
    }

    const socNumbersAsTimestamps = dataSoc.items.map((s) => {
      return { ...s, timestamp: new Date(s.date).getTime() };
    });

    const result = Object.values({ ...dataBatteryControl.items })
      .flat()
      .map((item) => {
        let itemDate: number | Date = new Date(item.dateTimeLocal);
        itemDate.setMilliseconds(0);

        itemDate = itemDate.getTime();

        let socItem = dataSoc.items.find((s) => {
          let socDate: number | Date = new Date(s.date);
          socDate.setMilliseconds(0);
          socDate = socDate.getTime();

          return socDate === itemDate;
        });

        if (!socItem) {
          socItem = findClosestItem(
            socNumbersAsTimestamps,
            itemDate,
            "timestamp"
          );
        }


        return {
          date: new Date(item.dateTimeLocal),
          source: item.source,
          soc: socItem?.value || 0,
          signal: BATTERY_CONTROL_STATUS[item.type],
          executed: item.executed,
          value: item.desiredLevel,
        };
      });

    return result;
  }, [dataSoc, dataBatteryControl]);

  const sortedData = useMemo(() => {
    return [...parsedData].sort((a, b) => {
      if (orderBy === "date") {
        return order === "asc"
          ? a.date.getTime() - b.date.getTime()
          : b.date.getTime() - a.date.getTime();
      } else {
        const compareA = a[orderBy];
        const compareB = b[orderBy];
        if (compareA < compareB) {
          return order === "asc" ? -1 : 1;
        }
        if (compareA > compareB) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [parsedData, order, orderBy]);

  return { parsedData, sortedData };
}
