import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import clsx from 'clsx';
import { useStyles } from "./styles";

export const OverviewHeader: React.FC<any> = ({ title, dropdown,items,children,colsA, className}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className={clsx(className, classes.overviewContainer)}>
      <Accordion
        expanded={expanded === "panel1"}
        //elevation={10}
        onChange={handleChange("panel1")}
        sx={{boxShadow: "0px 2px 8px 0px rgb(12 30 51 / 20%)"}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="overview"
        >
          <div className={classes.title}>{title}</div>
        </AccordionSummary>
        <AccordionDetails>
          <hr className={classes.line} />
          <Grid container>{dropdown}</Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
