import { Grid } from "@material-ui/core";
import React from "react";
import { Surface, Symbols } from "recharts";

export const RenderCustomToggleLegend = (props: any) => {
  const { payload, lines = null, setLines = null } = props;
  const handleClick = (dataKey: any) => {
    const fil = lines?.map((line) => {
      if (line.key === dataKey) {
        return { ...line, strokeOpacity: line.strokeOpacity === 1 ? 0 : 1 };
      } else {
        return line;
      }
    });
    setLines(fil);
  };
  return (
    <Grid container className="customized-legend">
      {payload.map((entry, i) => {
        const { dataKey, color, payload } = entry;
        return (
          <Grid
            item
            xs={3}
            className="legend-item"
            style={{
              marginBottom: 5,
              color: payload?.strokeOpacity === 0 ? "gray" : "black",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => handleClick(dataKey)}
            key={i}
          >
            {/* @ts-ignore */}
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols
                cx={5}
                cy={5}
                type="square"
                size={100}
                fill={payload?.strokeOpacity === 0 ? "gray" : color}
              />
            </Surface>
            <span> {dataKey}</span>
          </Grid>
        );
      })}
    </Grid>
  );
};
