import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { AutoComplete, Typography } from "../../../../../shared/components";
import { appTheme } from "../../../../../app/theme";

interface selectProps {
  name: string;
  label?: string;
  subLabel?: string;
  formik: any;
  Data: any;
  width?: any;
  handleChange?: (value: any, name: string) => void;
  onClick?: (key: string) => void;
  showAdd?: boolean;
  isAdd?: boolean;
  showOnEdit?: boolean;
  showOnAdd?: boolean;
  handleAddClick?: () => void;
  isAddButtonShow?: boolean;
}

export const RenderAutoComplete: React.FC<selectProps> = (props) => (
  <>
    {(props.isAdd === true && props.showOnAdd === true) ||
    (props.isAdd === false && props.showOnEdit === true) ||
    props.isAdd === undefined ? (
      <div style={{ width: props.width !== undefined ? props.width : 300 }}>
        <Typography label={props.label} subLabel={props.subLabel}></Typography>
        <AutoComplete
          value={props.formik.values[props.name] || ""}
          options={props.Data || []}
          width={props.width !== undefined ? props.width : 300}
          onChangeValue={(filterValue: string): void => {
            props.formik.setFieldValue(props.name, filterValue);
            if (props.handleChange) props.handleChange(filterValue, props.name);
          }}
          showError={
            props.formik?.touched[props.name] &&
            Boolean(props.formik.errors[props.name])
          }
          errorMessage={props.formik.errors[props.name]}
        ></AutoComplete>
        {props.isAddButtonShow === false ? (
          <></>
        ) : (
          <Button
            style={{ background: appTheme.main }}
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => props.handleAddClick()}
          >
            Add user
          </Button>
        )}
      </div>
    ) : (
      ""
    )}
  </>
);
