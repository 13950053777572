import React, { useMemo } from 'react'
import { useStyles } from './styles';
import { LineChart } from '../../../../../../../shared/components/LineChart';
import { colors } from '../../../../../../components/utils';
import { useGenerationIrradiationDelta7D } from './useGenerationIrradiationDelta7DWidget';


export const GenerationIrradiationDelta7D = () => {
    const { loading, error, xAxis, yAxis, generationIrradiationData } = useGenerationIrradiationDelta7D();
    const classes = useStyles();

    const memoizedData = useMemo(() => {
        return (
            <>
                <div className={classes.wrapper}>
                    <LineChart
                        title='Generation & Irradiation delta'
                        subtitle='Last 7 days'
                        colors={colors}
                        data={generationIrradiationData}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        height={120}
                        exporting={false}
                        showMarkers={true}
                        loading={loading}
                        legend={false}
                    />
                </div>
            </>
        )

    }, [generationIrradiationData, loading, yAxis, xAxis]);
    return memoizedData;
}
