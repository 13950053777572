import { axDelete, axGet, axPost } from "../../../store/axiosClient";
import * as ReportSlice from "./reportSlice";
import { getBearerToken } from "../../../store/authToken";
import axios from "axios";

const API_URL = `/SkyfriSidemApi/v1`

export function getReportTemplates() {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.templateStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(`${API_URL}/GetTemplate`, token);
      dispatch(ReportSlice.templateSuccess(data));
      return data;
    } catch (error) {
      dispatch(ReportSlice.templateError());
    }
  };
}
export function getReportTemplateById(id: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.templateByIdStart());
    try {
      const token = await getBearerToken();
      const data: any = await axGet(
        `${API_URL}/GetTemplate?id=${id}`,
        token
      );
      dispatch(ReportSlice.templateByIdSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.templateByIdError());
    }
  };
}
export function generateCustomReport(
  templateId: string,
  fromDate: any,
  toDate: any,
  selectedPlant: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.generateCustomReportStart());
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const res: any = await axios({
        method: "get",
        url:
          BASE_API_URL +
          `${API_URL}/GenerateCustomReport?id=${templateId}&fromDate=${fromDate}&toDate=${toDate}&plantId=${selectedPlant}`,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
      });
      dispatch(ReportSlice.generateCustomReportSuccess());
      return res.data;
    } catch (error) {
      dispatch(ReportSlice.generateCustomReportError());
    }
  };
}
export function getPortfolioReport(URI: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.portfolioReportStart());
    try {
      const token = await getBearerToken();
      const BASE_API_URL = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://skifryfpel-dev-apimngment.azure-api.net/";
      const res: any = await axios({
        method: "get",
        url: BASE_API_URL + URI,
        responseType: "blob",
        headers: {
          authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
          responseType: "blob",
        },
      });
      dispatch(ReportSlice.portfolioReportSuccess());
      return res.data;
    } catch (error) {
      dispatch(ReportSlice.portfolioReportError());
    }
  };
}
export function getPortfolioPerformanceReport(
  fromDate: any,
  toDate: any,
  selectedPlant: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.portfolioPerReportStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        `${API_URL}/GetPortfolioPerformanceReport?fromDate=${fromDate}&toDate=${toDate}&plantId=${selectedPlant}`,
        token
      );
      dispatch(ReportSlice.portfolioPerReportSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.portfolioPerReportError());
    }
  };
}
export function getReportColumns() {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.reportColumnsStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(`${API_URL}/GetColumns`, token);
      dispatch(ReportSlice.reportColumnsSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.reportColumnsError());
    }
  };
}
export function downloadCustomTemplate(templateId: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.downloadReportStart());
    try {
      const token = await getBearerToken();

      const data: any = await axGet(
        `alarm/GenerateCustomReportTemplate?id=${templateId}`,
        token
      );
      dispatch(ReportSlice.downloadReportSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.downloadReportError());
    }
  };
}
export function createCustomTemplate(template: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.createCustomReportStart());
    try {
      const token = await getBearerToken();

      const data: any = await axPost(
        `${API_URL}/InsertTemplate`,
        template,
        token
      );
      dispatch(ReportSlice.createCustomReportSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.createCustomReportError());
    }
  };
}
export function updateCustomTemplate(updatedTemplate: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.updateCustomTemplateStart());
    try {
      const token = await getBearerToken();

      const data: any = await axPost(
        `${API_URL}/UpdateTemplate`,
        updatedTemplate,
        token
      );
      dispatch(ReportSlice.updateCustomTemplateSuccess());
      return data;
    } catch (error) {
      dispatch(ReportSlice.updateCustomTemplateError());
    }
  };
}
export function deleteCustomTemplate(tempId: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(ReportSlice.deleteCustomTemplateStart());
    try {
      const token = await getBearerToken();
      await axDelete(`${API_URL}/DeleteTemplate?id=${tempId}`, {}, token);
      dispatch(ReportSlice.deleteCustomTemplateSuccess());
    } catch (error) {
      dispatch(ReportSlice.deleteCustomTemplateError());
    }
  };
}
