import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Button } from "../../../../shared/components";
import { Card, Typography, useMediaQuery, Theme } from "@material-ui/core";
import { ReactComponent as SAVE } from "../../../../shared/icons/cmms/SAVE.svg";
import { UserSavePage } from "./../../User/components/UserSavePage.component";
import { ActionButtons, Modal, MultiSelectorDropdown, } from "../../../../shared/components";
import { useUserTenantSave } from "./useUserTenantSave";
import { GetFormik, initializeValidationsObsolete, initializeValuesObsolete, capitalizeFirstLetter, } from "./../../components/Utils/utils";
import { useGetModulesBuTenantIdQuery, useGetPlantsToUserQuery, useGetPlantsByTenantAndModuleIdQuery, usePostPlantsByTenantAndModuleIdMutation, } from "../../../../features/Onboarding/onBoardingApi";
import { userTenantFormSchema, userEditTenantFormSchema } from "./metaData";
import { CustomCloseSVG } from "../../../../shared/icons/cmms/CLOSE.component";
import { DynamicField } from "./../../components/shared/DynamicFields/DynamicField";
import { appTheme } from "../../../../app/theme";
import { Button2 } from "../../../../shared/components/Button2/Button2.component";
import { useStyles } from "../styles";
import { getModuleID } from "../../../../shared/utils/utils";
import { ToasterSnackbar } from "../../../../shared/components/Toaster";
import { ToastMessage } from "../../../../shared/components/Toaster/ToastMessage";

export const UserTenantSavePage: React.FC = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { usertenantId } = useParams<{ usertenantId: string }>();
  const { tenantId } = useParams<{ tenantId: string }>();

  const { user, upsert, userTenant, userRole, deleteRole, getUsers, role } =
    useUserTenantSave({
      usertenantId,
      tenantId,
    });

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedRole, setSelectedRole] = useState([]);
  const [id, setId] = useState("");
  const [addRole, setAddRole] = React.useState([]);
  const [selectedPlantPV, setSelectedPlantPV] = useState([]);
  const [selectedPlantBattery, setSelectedPlantBattery] = useState([]);
  const [selectedPlantLobbyscreen, setSelectedPlantLobbyscreen] = useState([]);
  const [pvPlantOptions, setPvPlantOptions] = useState([]);
  const [batteryPlantOptions, setBatteryPlantOptions] = useState([]);
  const [lobbyscreenPlantOptions, setLobbyScreenPlantOptions] = useState([]);
  const [preSelectedPlantPV, setPreSelectedPlantPV] = useState([]);
  const [preSelectedPlantBattery, setPreSelectedPlantBattery] = useState([]);
  const [preSelectedPlantLobbyscreen, setPreSelectedPlantLobbyscreen] = useState([]);

  const { data: modules } = useGetModulesBuTenantIdQuery({ tenantId });

  const { data: PlantsForMonitoringData } = useGetPlantsByTenantAndModuleIdQuery(
    { tenantId, moduleId: getModuleID(modules, 'monitoring'), },
    { skip: !tenantId || !modules, refetchOnMountOrArgChange: true });

  const { data: PlantsForLobbyscreenData } = useGetPlantsByTenantAndModuleIdQuery(
    { tenantId, moduleId: getModuleID(modules, 'lobbyscreen'), },
    { skip: !tenantId || !modules, refetchOnMountOrArgChange: true });

  const { data: plantsForMonitoringUserData } = useGetPlantsToUserQuery(
    { tenantId, userId: userTenant?.userId, moduleId: getModuleID(modules, 'monitoring'), },
    {
      skip: !modules || !userTenant?.userId || !tenantId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: plantsForLobbyscreenUserData } = useGetPlantsToUserQuery(
    { tenantId, userId: userTenant?.userId, moduleId: getModuleID(modules, 'lobbyscreen'), },
    {
      skip: !modules || !userTenant?.userId || !tenantId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [postPlants] = usePostPlantsByTenantAndModuleIdMutation();
  const [postLobbyScreenPlants] = usePostPlantsByTenantAndModuleIdMutation();


  useEffect(() => {
    if (!PlantsForMonitoringData || !modules) return;
    setPvPlantOptions(getPlants(PlantsForMonitoringData, "PV", getModuleID(modules, 'monitoring')))
    setBatteryPlantOptions(getPlants(PlantsForMonitoringData, "BESS", getModuleID(modules, 'monitoring')))
  }, [PlantsForMonitoringData, modules])

  useEffect(() => {
    if (!PlantsForLobbyscreenData || !modules) return;
    setLobbyScreenPlantOptions(getPlants(PlantsForLobbyscreenData, null, getModuleID(modules, 'lobbyscreen')));
  }, [PlantsForLobbyscreenData, modules])

  useEffect(() => {
    if (!plantsForMonitoringUserData) return;

    const preSelectedPlantPV = [];
    const preSelectedPlantBESS = [];
    plantsForMonitoringUserData?.forEach(item => {
      if (item.plantType.includes("PV")) {
        preSelectedPlantPV.push(item.plantName);
      }
      if (item.plantType.includes("BESS")) {
        preSelectedPlantBESS.push(item.plantName);
      }

    });
    setPreSelectedPlantPV(preSelectedPlantPV);
    setPreSelectedPlantBattery(preSelectedPlantBESS);
  }, [plantsForMonitoringUserData]);

  useEffect(() => {
    if (!plantsForLobbyscreenUserData) return;
    const preSelectedPlantLobbyscreen = [];
    plantsForLobbyscreenUserData?.forEach(item => {
      preSelectedPlantLobbyscreen.push(item.plantName);
    });
    setPreSelectedPlantLobbyscreen(preSelectedPlantLobbyscreen);
  }, [plantsForLobbyscreenUserData]);

  const createNewUserTenant = usertenantId === "new";

  useEffect(() => {
    if (role) {
      userTenantFormSchema.roles.options = role;
      userEditTenantFormSchema.roles.options = role;
    }
  }, [role]);


  useEffect(() => {
    if (user) {
      userTenantFormSchema.userId.options = user;
      userEditTenantFormSchema.userId.options = user;
    }
  }, [user]);

  useEffect(() => {
    initializeValidationsObsolete(userTenantFormSchema, (resp) => {
      setValidationSchema(resp);
    });
  }, [user, userTenant, userRole]);

  useEffect(() => {
    if (userTenant) {
      initializeValuesObsolete(
        userEditTenantFormSchema,
        createNewUserTenant,
        userTenant,
        (resp) => {
          setInitialValues(resp);
        }
      );
    }
  }, [userTenant, user, userRole]);

  useEffect(() => {
    if (createNewUserTenant) {
      initializeValuesObsolete(
        userTenantFormSchema,
        createNewUserTenant,
        userTenant,
        (resp) => {
          setInitialValues(resp);
        }
      );
    }
  }, [userTenant, user, userRole]);

  useEffect(() => {
    setSelectedPlantPV(selectedPlantPV);
  }, [selectedPlantPV]);

  const getPlants = (plants: any, plantType: string = null, moduleId: string) => {

    if (!plantType) {
      return plants?.map((item) => {
        return {
          label: capitalizeFirstLetter(item?.plantName),
          value: item?.plantName,
          id: item?.plantId,
          moduleId: moduleId,
        };
      });
    }
    else {
      return plants?.filter((item) => item?.plantType?.includes(plantType))?.map((item) => {
        return {
          label: capitalizeFirstLetter(item?.plantName),
          value: item?.plantName,
          id: item?.plantId,
          moduleId: moduleId,
        };
      });
    }
  };

  const onSubmitHandler = async (values: any) => {
    if (id === "edit") {
      let fields = JSON.parse(JSON.stringify(values, null, 2));
      fields.TenantId = tenantId;
      fields.isActive =
        fields.isActive === "" ||
          fields.isActive === null ||
          fields.isActive === undefined
          ? false
          : fields.isActive;
      const selectedUserRole = role.filter((item) =>
        selectedRole.includes(item.roleDisplayName)
      );
      fields.roles = [...selectedUserRole.map((item) => item.roleId)];
      upsert(fields, usertenantId, (resp) => {
        if (resp?.ok === false) {
          ToastMessage(`Error: ${resp?.status}, ${resp?.statusText}`, 'error');
        } else {
          ToastMessage(`Record added successfully`, 'success');
        }
      });
      const payload = {
        TenantId: tenantId,
        ModuleId: getModuleID(modules, "monitoring"),
        plantAccesses:
          [
            ...selectedPlantPV?.map((item) => {
              return { plantId: item.id, accessType: "PV" }
            }),
            ...selectedPlantBattery?.map((item) => {
              return { plantId: item.id, accessType: "BESS" }
            })
          ],
        UserId: userTenant?.userId,
      };
      const lobbyScreenPayload = {
        TenantId: tenantId,
        ModuleId: getModuleID(modules, "lobbyscreen"),
        plantAccesses: selectedPlantLobbyscreen?.map((item) => { return { plantId: item.id, accessType: "PV" } }),
        UserId: userTenant?.userId,
      };
      const lobbyscreenPayloadString = JSON.stringify(lobbyScreenPayload);
      const payloadString = JSON.stringify(payload);
      const plantsResp = await postPlants({ data: payloadString });
      if ("error" in plantsResp) {
        if ("status" in plantsResp?.error) {
          ToastMessage(`Error: ${plantsResp?.error?.status}, monitoring plants not saved`, 'error');
        }
      }
      const lobbyScreenPlantResp = await postLobbyScreenPlants({ data: lobbyscreenPayloadString });
      if ("error" in lobbyScreenPlantResp) {
        if ("status" in lobbyScreenPlantResp?.error) {
          ToastMessage(`Error: ${lobbyScreenPlantResp?.error?.status}, lobbyscreen plants not saved`, 'error');
        }
      }
    } else {
      let fields = JSON.parse(JSON.stringify(values, null, 2));
      fields.TenantId = tenantId;
      fields.isActive =
        fields.isActive === "" ||
          fields.isActive === null ||
          fields.isActive === undefined
          ? false
          : fields.isActive;
      const selectedUserRole = role.filter((item) =>
        selectedRole.includes(item.roleDisplayName)
      );
      // const newRoleIdsToInsert = selectedUserRole.filter((item) => !fields?.roles?.some(e => e === item.roleId));
      fields.roles = [...addRole];
      upsert(fields, usertenantId, (resp) => {
        if (resp?.ok === false) {
          ToastMessage(`Error: ${resp?.status}, ${resp?.statusText}`, 'error');
        } else {
          ToastMessage(`Record added successfully`, 'success');
        }
        setTimeout(() => {
          renderPage(`/onboarding/tenant/${tenantId}?index=2`);
        }, 2000);
      });
    }
  };

  const formik = GetFormik(initialValues, validationSchema, onSubmitHandler);

  const renderPage = (URI) => {
    navigate(URI);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddClick = () => {
    setOpenModal(true);
  };

  const handleCallback = () => {
    setOpenModal(false);
    getUsers();
  };

  const handleChange = (value) => {
    setSelectedRole(value);
  };

  const handleOnCancel = () => {
    let URI = `/onboarding/tenant/${tenantId}?index=2`;
    renderPage(URI);
  };

  useEffect(() => {
    if (userTenant && userTenant.userRoles) {
      const userRolesArray = userTenant?.userRoles
        ?.split(",")
        .map((role) => role.trim());
      setSelectedRole(userRolesArray);
    }
  }, [userTenant]);

  const handleOnSave = () => {
    formik.validateForm();
    setId("edit");
    return formik.submitForm();
  };

  const handleAddChange = (value) => {
    setAddRole(value);
  };

  const renderEditTenantUser = () => (
    <div style={{ height: "100%", position: "relative" }}>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className={classes.userTenantHeader}
          style={{ flex: 0 }}
        >
          <div
            style={{ fontWeight: 500, fontSize: "28px", padding: "1rem 2rem " }}
          >
            Edit User
          </div>
          <div
            style={{ paddingTop: 20, padding: "1rem 2rem ", cursor: "pointer" }}
            onClick={() => {
              handleOnCancel();
            }}
          >
            <CustomCloseSVG width={30} height={30} />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            flex: 0,
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={4}
              style={{ borderRight: "1px solid #E1E4E9", height: "80vh" }}
            >
              <Grid
                container
                spacing={1}
                style={{ padding: "40px", marginTop: "-20px" }}
              >
                <Grid
                  item
                  xs={12}
                  lg={12}
                  md={12}
                  style={{ fontSize: "20px", fontWeight: 500 }}
                >
                  General
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  md={12}
                  style={{ display: "flex", marginTop: "20px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          flexDirection: isSmallScreen ? "column" : "row",
                        }}
                      >
                        <Grid
                          item
                          xs={"auto"}
                          style={{
                            alignSelf: isSmallScreen
                              ? "flex-start"
                              : "baseline",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              width: "100px",
                            }}
                          >
                            First Name
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ alignSelf: "baseline" }}
                        >
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              alignSelf: "flex-start",
                              width: "100px",
                            }}
                          >
                            {userTenant?.userName.split(" ")[0] || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={"auto"}
                          style={{ alignSelf: "baseline" }}
                        >
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              width: "100px",
                            }}
                          >
                            Last Name
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ alignSelf: "baseline" }}
                        >
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              alignSelf: "flex-start",
                              width: "100px",
                            }}
                          >
                            {userTenant?.userName.split(" ")[1] || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={"auto"}
                          style={{ alignSelf: "baseline" }}
                        >
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 400,
                              width: "100px",
                            }}
                          >
                            Email
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ alignSelf: "baseline" }}
                        >
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              alignSelf: "flex-start",
                              width: "200px",
                            }}
                          >
                            {userTenant?.userEmail || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      style={{ marginTop: "10px" }}
                    >
                      {Object.keys(userEditTenantFormSchema).map((x) => (
                        <DynamicField
                          handleChange={handleChange}
                          value={selectedRole}
                          isAdd={false}
                          name={x}
                          data={userEditTenantFormSchema[x]}
                          formik={formik}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} lg={8} md={8}>
              <Grid
                container
                spacing={1}
                style={{ padding: "40px", marginTop: "-20px" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ fontSize: "20px", fontWeight: 500 }}
                >
                  Plants visibility
                  <div
                    style={{
                      fontSize: "15px",
                      lineHeight: "18px",
                      fontWeight: 400,
                      marginTop: "2px",
                    }}
                  >
                    Below you can select the plants this user can see in each
                    module.
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.moduleTypeText}
                  style={{ paddingTop: "32px" }}
                >
                  Monitoring
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.assetTypeText}
                  style={{ paddingTop: "16px" }}
                >
                  PV assets
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: "16px" }}
                >
                  <MultiSelectorDropdown
                    preSelected={true}
                    preSelectedValue={preSelectedPlantPV}
                    padding="0px 55px 0px 5px"
                    variant="outlined"
                    value={selectedPlantPV}
                    options={pvPlantOptions}
                    selected={selectedPlantPV}
                    setSelected={setSelectedPlantPV}
                    label={"plants"}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.assetTypeText}
                  style={{ paddingTop: "16px" }}
                >
                  Batteries
                </Grid>
                <Grid
                  item
                  xs={"auto"}
                  style={{ paddingTop: "16px" }}
                >
                  <MultiSelectorDropdown
                    preSelected={true}
                    preSelectedValue={preSelectedPlantBattery}
                    padding="0px 55px 0px 5px"
                    variant="outlined"
                    width={"200px"}
                    value={selectedPlantBattery}
                    options={batteryPlantOptions}
                    selected={selectedPlantBattery}
                    setSelected={setSelectedPlantBattery}
                    label={"batteries"}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.moduleTypeText}
                  style={{ paddingTop: "32px" }}
                >
                  Lobby screen
                </Grid>
                <Grid
                  item
                  xs={"auto"}
                  style={{ paddingTop: "16px" }}
                >
                  <MultiSelectorDropdown
                    preSelected={true}
                    preSelectedValue={preSelectedPlantLobbyscreen}
                    padding="0px 55px 0px 5px"
                    variant="outlined"
                    width={"200px"}
                    value={selectedPlantLobbyscreen}
                    options={lobbyscreenPlantOptions}
                    selected={selectedPlantLobbyscreen}
                    setSelected={setSelectedPlantLobbyscreen}
                    label={"plants"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            minHeight: "90px",
            borderTop: "1px solid #E1E4E9",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            className={classes.cancelButton}
            title={"Cancel"}
            outlined
            noBorder
            color={appTheme.main}
            type={"button"}
            onClick={() => {
              handleOnCancel();
            }}
          />
          <Button
            className={classes.saveButton}
            startIcon={<SAVE />}
            title={"Save"}
            color={appTheme.main}
            noBorder
            type={"button"}
            onClick={() => {
              handleOnSave();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  const renderAddTenantUser = () => (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className={clsx({ [classes.form]: createNewUserTenant })}
      >
        <div
          className={clsx({
            [classes.formElementsWrapper]: createNewUserTenant,
          })}
        >
          {Object.keys(userTenantFormSchema).map((x) => (
            <DynamicField
              handleChange={handleAddChange}
              isAddButtonShow={true}
              isAdd={true}
              name={x}
              data={userTenantFormSchema[x]}
              formik={formik}
              handleAddClick={() => handleAddClick()}
            />
          ))}
        </div>
      </form>
      <Grid container spacing={0} sx={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <ActionButtons
            saveTitle="Save"
            onSave={() => {
              formik.validateForm();
              return formik.submitForm();
            }}
            saveButtonType="submit"
            cancelTitle="Back"
            onCancel={() => {
              let URI = `/onboarding/tenant/${tenantId}?index=2`;
              renderPage(URI);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.userTenantPageWrapper}>
      <ToasterSnackbar />

      {createNewUserTenant && (
        <div className={classes.pageLabel}>
          <>Assign user to this tenant </>
        </div>
      )}
      <Modal
        isOpen={openModal}
        title={"Add user"}
        onClose={onCloseModal}
        content={
          <UserSavePage
            type="modal"
            callback={() => handleCallback()}
          />
        }
      />
      <Modal
        isOpen={openDeleteModal}
        title={"Delete"}
        onClose={onCloseDeleteModal}
        content={
          <>
            <div>Deleting a record cannot be undone</div>
            <div>Do you still want to the delete the record</div>
            <Divider style={{ marginTop: "10px" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "8px",
              }}
            >
              <div>
                <Button2 size={40} type="tertiary" onClick={onCloseDeleteModal}>
                  Cancel
                </Button2>
              </div>
              <div>
                <Button2
                  size={40}
                  type="primary"
                  onClick={() => {
                    deleteRole(selectedId);
                    setOpenDeleteModal(false);
                  }}
                >
                  Delete
                </Button2>
              </div>
            </div>
          </>
        }
      />
      <Card
        className={clsx({
          [classes.cardWrapper]: createNewUserTenant,
          [classes.cardUserTenantWrapper]: !createNewUserTenant,
        })}
      >
        {createNewUserTenant ? (
          <>{renderAddTenantUser()}</>
        ) : (
          <>{renderEditTenantUser()}</>
        )}
        <br />
      </Card>
    </div>
  );
};
