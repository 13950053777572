import React from "react";
import { Grid } from "@material-ui/core";
import { InverterSoilingWidget } from "../../../features/InverterSoilingWidget";
import PowerComparisonWidget from "../features/PowerComparisonWidget";
import EfficiencyWidget from "../features/EfficiencyWidget";
import HeatmapWidget from "../features/HeatmapWidget";
import InverterTemperatureWidget from "../features/InverterTemperatureWidget";
import InverterHeatmapWidget from "../features/InverterHeatmapWidget";

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}
export const InverterPerformance: React.FC<Props> = (props) => (
  <Grid container spacing={2}>
    <InverterHeatmapWidget {...props} />

    <EfficiencyWidget {...props} />

    <PowerComparisonWidget {...props} />

    <HeatmapWidget {...props} />

    <InverterSoilingWidget {...props} />

    <InverterTemperatureWidget {...props} />
  </Grid>
);
