import React, { useEffect, useState } from "react";
import { useStyles } from "./components/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Datepicker, Button } from "./../../../shared/components";
import { Template } from "./components/template.component";
import { mockUpData } from "./components/mockUpData";
import { useReports } from "./useReports";
import { useSharedFormState } from "./../../../shared/utils/sharedStates";
import Loader from "../../components/Loader";
import { Snackbar } from "./../../../shared/components/Snackbar";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { OutlinedDropdown } from "../../../shared/components/OutlinedDropdown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownloadIcon from "@mui/icons-material/Download";

export const Reports: React.FC<any> = () => {
  const classes = useStyles();
  const { activetenants } = useSharedFormState();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedName, setSelectedName] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedPlant, setSelectedPlant] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const handleChangePlants = (event: any, value: any) => {
    setSelectedPlants(value);
  };

  const {
    GetPortfolioReport,
    loading,
    error,
    portfolio,
    plant,
    customReports,
    DeleteCustomReport,
    UpdateCustomReport,
    severity,
    message,
    isOpen,
    setMessage,
    setSeverity,
    setIsOpen,
    GenerateCustomReport,
  } = useReports({
    selectedPortfolio,
    selectedPlant,
    selectedPlants,
    setSelectedPlants,
  });

  const generateReport = () => {

    if (dateFrom > dateTo) {
      setMessage(
        "There is no records for selected plants or period. Try again with another data."
      );
      setSeverity("error");
      setIsOpen(true);
      return;
    }

    if (!selectedPortfolio || !selectedPlants || !dateFrom || !dateTo) {
      setMessage("Please fill in required input fields");
      setSeverity("error");
      setIsOpen(true);
      return;
    }

    if (templateId != 0) {
      GenerateCustomReport(templateId, dateFrom, dateTo, (resp) => {
        if (resp == "error") {
          setMessage(
            "There is no records for selected plants or period. Try again with another data."
          );
          setSeverity("error");
          setIsOpen(true);
          return;
        }
      });
    } else {
      GetPortfolioReport(
        activetenants,
        dateFrom,
        dateTo,
        selectedIndex,
        (resp) => {
          if (resp == "error") {
            setMessage(
              "There is no records for selected plants or period. Try again with another data."
            );
            setSeverity("error");
            setIsOpen(true);
            return;
          }
        }
      );
    }
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Report templates
              </Typography>
              <Template
                setTemplateId={(id) => setTemplateId(id)}
                DeleteCustomReport={DeleteCustomReport}
                UpdateCustomReport={UpdateCustomReport}
                customReportsData={customReports}
                data={mockUpData?.data}
                selectedIndex={selectedIndex}
                selectedName={selectedName}
                setSelectedIndex={(index) => setSelectedIndex(index)}
                setSelectedName={(name) => setSelectedName(name)}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{ marginBottom: "1em" }}
              >
                {selectedName}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <OutlinedDropdown
                    label="Portfolio"
                    value={selectedPortfolio}
                    data={portfolio}
                    onChangeValue={setSelectedPortfolio}
                    required
                    inHeight="50px"
                  />

                  <span className={classes.marginRight}></span>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={plant}
                    getOptionLabel={(dt) => dt.label}
                    defaultValue={selectedPlants}
                    value={selectedPlants}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          {...getTagProps({ index })}
                          label={option.label}
                          size="small"
                        />
                      ));
                    }}
                    onChange={(e: any, value: any) =>
                      handleChangePlants(e, value)
                    }
                    style={{ width: 200, maxWidth: 200 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root":
                          {
                            paddingTop: "0.4em",
                          },
                        }}
                        label="Plants *"
                        placeholder="Plants"
                        size="small"
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <span className={classes.marginLeft}></span>
                  <Datepicker
                    label="From *"
                    value={dateFrom}
                    onChange={(date) => setDateFrom(date)}
                    inHeight="35px"
                    inWidth="150px"
                  />
                  <span className={classes.marginRight}></span>
                  <Datepicker
                    label="To *"
                    value={dateTo}
                    onChange={(date) => setDateTo(date)}
                    inHeight="35px"
                    inWidth="150px"
                    maxDate={new Date()}
                  />
                  <span className={classes.marginRight}></span>
                  <Button
                    height="50px"
                    color={"rgb(11 30 51)"}
                    type="button"
                    title={"Download"}
                    onClick={() => generateReport()}
                    disable={
                      selectedPlants && selectedPortfolio && dateFrom && dateTo
                        ? false
                        : true
                    }
                    startIcon={<DownloadIcon />}
                    padding="0 1.5rem"
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                <Grid item xs={12}>
                  {loading && (
                    <Loader
                      loading={loading}
                      height={300}
                      error={error}
                      errorTextSize={32}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        severity={severity}
        open={isOpen}
        message={message}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};