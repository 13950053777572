import { axGet } from "../../../store/axiosClient";
import * as AlarmSlice from "./alarmSlice";
import { getBearerToken } from "../../../store/authToken";

const API_URL = `/SkyfriSidemApi/v1`
export function getAlarmsByPlant(
  dateFrom: any,
  dateTo: any,
  pageSize: any,
  PageNo: any,
  selectedPlant: any,
  status: any,
  categoryId: any,
  equipment: any,
  alarmTypeId: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmsStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetAllAlarmsByPlant?fromDate=${dateFrom}&toDate=${dateTo}&PageSize=${pageSize}&PageNo=${PageNo}&plantId=${selectedPlant}&status=${status}&category=${categoryId}&equipment=${equipment}&alertType=${alarmTypeId}`,
        token
      );
      dispatch(AlarmSlice.alarmsSuccess(data));
      return data;
    } catch (error) {
      dispatch(AlarmSlice.alarmsError());
    }
  };
}
export function getAlarmCategory() {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmCategoryStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetAlarmCategory
      `,
        token
      );
      dispatch(AlarmSlice.alarmCategorySuccess());
      return data;
    } catch (error) {
      dispatch(AlarmSlice.alarmCategoryError());
    }
  };
}
export function getAlarmsEquipmentStructure(plantId: any) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmsStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetAlarmsEquipmentStructure?plantId=${plantId}`,
        token
      );
      // dispatch(AlarmSlice.alarmsSuccess(data));
      return data;
    } catch (error) {
      console.error(error);
      // dispatch(AlarmSlice.alarmsError());
    }
  };
}
export function getAlarmsTrippingInverters(
  dateFrom: any,
  dateTo: any,
  selectedPlant: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmsStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetInvertersMttrMtbfGenLosscustom?fromDate=${dateFrom}&toDate=${dateTo}&plantId=${selectedPlant}`,
        token
      );
      // dispatch(AlarmSlice.alarmsSuccess(data));
      return data;
    } catch (error) {
      console.error(error);
      // dispatch(AlarmSlice.alarmsError());
    }
  };
}
export function getAlarmsUnderperformingInverters(
  dateFrom: any,
  dateTo: any,
  selectedPlant: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmsStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetInvertersMttrMtbfGenLosscustom_underperformance?fromDate=${dateFrom}&toDate=${dateTo}&plantId=${selectedPlant}`,
        token
      );
      // dispatch(AlarmSlice.alarmsSuccess(data));
      return data;
    } catch (error) {
      console.error(error);
      // dispatch(AlarmSlice.alarmsError());
    }
  };
}
export function getAlarmsGenerationLoss(
  dateFrom: any,
  dateTo: any,
  selectedPlant: any
) {
  return async (dispatch: (arg0: any) => Promise<void>) => {
    dispatch(AlarmSlice.alarmsStart());
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        token = await getBearerToken();
      }
      const data: any = await axGet(
        `${API_URL}/GetInvertersGenLosscustomGraph?fromDate=${dateFrom}&toDate=${dateTo}&plantId=${selectedPlant}`,
        token
      );
      // dispatch(AlarmSlice.alarmsSuccess(data));
      return data;
    } catch (error) {
      console.error(error);
      // dispatch(AlarmSlice.alarmsError());
    }
  };
}

