import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { generateColorHex } from "../../../../../shared/utils/colorHex";
import { autoRefreshApi, convertTime } from "../../../../../shared/utils/utils";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Props {
  plantId: string;
  date: string;
}

export const useWeatherStation = ({ plantId, date }: Props) => {
  const [data, setData] = useState([]);
  const [lines, setLines] = useState([]);
  const [yAxises, setyAxises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);

  const fetchData = () => {
    fetchAsync(
      `/SkyfriSidemApi/v1/GetWeatherStationDataDaily?plantId=${plantId}&fromDate=${date}`,
      "GET"
    )
      .then((data: any) => {
        if (!data || data.length <= 0) return;
        setLoading(true);

        let reduceData = {};
        let lineNames = [];
        const createItemObject = (item) => {
          let itemObj = {};
          for (const [key, value] of Object.entries(item)) {
            if (
              !key.includes("clientName") &&
              !key.includes("equip_Name") &&
              !key.includes("plantId") &&
              !key.includes("sensorTime")
            ) {
              if (value !== null) {
                itemObj[item.equip_Name + "_" + key] = value;
                lineNames.push(item.equip_Name + "_" + key);
              }
            }
          }
          return itemObj;
        };
        data.map((item) => {
          const timeLabel = convertTime(item.sensorTime, "HH:mm");
          let labelExists = false;

          for (const key of Object.keys(reduceData)) {
            if (key === timeLabel) {
              labelExists = true;
            }
          }
          if (!labelExists) {
            reduceData[timeLabel] = { ...createItemObject(item) };
          } else {
            reduceData[timeLabel] = {
              ...createItemObject(item),
              ...reduceData[timeLabel],
            };
          }
        });
        lineNames = lineNames.filter(
          (item, index) => lineNames.indexOf(item) === index
        );

        let ln = [];
        lineNames.forEach((item) => {
          const randomColor = generateColorHex();
          ln.push({
            key: item,
            stroke: randomColor,
            yAxisId: item,
            strokeOpacity: 1,
          });
        });
        const sortedDataArr = Object.keys(reduceData)
          ?.flat()
          ?.map((item) => ({ ...reduceData[item], sensor_time: item }));
        const filData = sortedDataArr?.map((item) => {
          const timeLabel = convertTime(item.sensorTime, "HH:mm");
          return {
            sensor_time: timeLabel,
            ...item,
          };
        });
        setData(filData);
        setLines(ln);
        setLoading(false);
      })
      .catch(setError);
  };
  useEffect(() => {
    if (!client) {
      setLoading(true);
      return;
    }
    setLoading(true);
    fetchData();

    const myInterval = autoRefreshApi(() => fetchData());
    return () => clearInterval(myInterval);
  }, [date, plantId, client]);

  return {
    data,
    loading,
    error,
    lines,
    setLines,
    yAxises,
  };
};
