import ReactDOM from "react-dom";
import { MSAAPP } from "./app";
import posthog from "posthog-js";

posthog.init(
  // new
  "phc_1gEPiqMvUdAvu6m313Bnh4sgW1jZSiLiH07ZSWfmp2W",
  { api_host: "https://eu.posthog.com" }
);

ReactDOM.render(<MSAAPP />, document.getElementById("root"));
