import React from 'react'
import { makeStyles } from '@material-ui/core';

import { Button } from '../../../../../shared/components';

import { Types } from './useHeatmap';
// import { GRAPH_COLORS } from "../../../../../shared/utils/graphColors";

const useStyles = makeStyles(() => ({
  menuWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    display: "flex",
    fontSize: "14px",
    borderRadius: "6px !important",
    padding: "8px 24px !important"
  }
}));

interface Props {
  activeType: Types;
  setType: (val: Types) => void;
  content?: any;
}

export const CustomMenu: React.FC<Props> = ({ activeType, setType, content }) => {
  const classes = useStyles();

  const getButton = (title: string, type: Types, active: Types) => (
    <Button
      title={title}
      className={classes.button}
      outlined={active !== type}
      color={"#0C1E33"}
      noBorder={true}
      onClick={() => setType(type)}
    />
  );

  return (
    <div className={classes.menuWrapper}>
      <div style={{ background: "#E8ECF2", borderRadius: "6px", }}>
        {getButton("Show all", Types.All, activeType)}

        {getButton("Worst 15", Types.Fifteen, activeType)}

        {getButton("Worst 30", Types.Thirty, activeType)}
      </div>

      {!content ? null : (
        <>
          <div style={{ marginRight: 0 }} />
          {content}
        </>
      )}
    </div>
  );
}
