import React from 'react'
import { makeStyles } from '@material-ui/core';
import { MultilineChartWidget } from '../../../../components/MultilineChartWidget';
import { CustomTooltip } from './CustomTooltip.componets';
import { useSpecificCurrent } from './useSpecificCurrent';

interface Props {
  plantId: string;
  inverterId: string;
  blockId: string;
  date: string;
  definition?: any;
}

const useStyles = makeStyles(theme => ({
  widget: {
    marginRight: theme.spacing(2)
  }
}))

export const SpecificCurrentWidget: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { equipmentType, data, lines, error, loading } = useSpecificCurrent(props);

  return (
    <MultilineChartWidget
      title={'Specific ' + equipmentType + ' current'}
      styles={classes.widget}
      size={6}
      lines={lines}
      data={data}
      loading={loading}
      error={error}
      height={500}
      tooltipContent={<CustomTooltip equipment={equipmentType} />}
      definition={props.definition}
    />
  )
}
