import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedModule: {
    name: "",
    id: "",
  },
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setSelectModule: (state, { payload }) => {
      return {
        ...state,
        selectedModule: payload,
      };
    },
  },
});

export const { setSelectModule } = portfolioSlice.actions;

export const selectSelectedModule = (state) => {
  return state.Onboarding.portfolio;
};

export const portfolioReducer = portfolioSlice.reducer;
