import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow, Collapse } from "@mui/material";
import {
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  DeleteOutline,
} from "@mui/icons-material";
import { TextField, IconButton } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Cell } from "./interfaces";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { ReactComponent as ELLIPSES } from "../../icons/cmms/ELLIPSES.svg";
import { CustomEditSVG } from "../../icons/cmms/EDIT.component";
import { CustomDeleteSVG } from "../../icons/cmms/DELETE.component";
import MenuItem from "@mui/material/MenuItem";
import { Button as CustomButton } from "../../../shared/components";
import { Datepicker } from "./../Datepicker";
import moment from "moment";
import { Checkbox } from "../Checkbox";
import { useNavigate } from "react-router-dom";
import { Button2 } from "../Button2/Button2.component";

interface Props {
  module?: string;
  tableType: string;
  data: any;
  columns: Array<Cell>;
  hiddenContent?: React.ReactNode;
  isOpen?: boolean;
  onDeleteClick?: (id: any) => void;
  onViewMoreClick?: (id: any) => void;
  onUpdateField?: (id: any, key: string, value: string) => void;
  isDoubleClickActive?: boolean;
  isActionAllow?: boolean;
  handleCheckboxClick?: (data: any, roleInfo: any) => void;
  timeLabel?: boolean;
  userTimeZone?: string;
}

const useStyles = makeStyles(() => ({
  tableFlex: {
    display: "flex !important",
    alignItems: "baseline !important",
    fontWeight: 800,
    height: "72px !important",
    border: "none",
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    backgroundColor: "#e8ecf2",
    width: "97px",
    height: "32px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#7088a04f",
      transition: "background-color 0.2s",
    },
  },
  deleteButton: {
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    marginLeft: "10px",
    justifyContent: "center",
    backgroundColor: "#F8DCDC",
    width: "32px",
    height: "32px",
    "&:hover": {
      backgroundColor: "#f3a3a2",
      transition: "background-color 0.2s",
    },
  },
}));

export const CustomTableRow: React.FC<Props> = ({
  timeLabel,
  userTimeZone,
  tableType,
  module,
  data,
  columns,
  hiddenContent,
  isOpen,
  onDeleteClick,
  onViewMoreClick,
  onUpdateField,
  isDoubleClickActive,
  isActionAllow,
  handleCheckboxClick,
}) => {
  const [showCells, setShowCells] = useState(isOpen ? isOpen : false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [totalAmount, setTotalAmount] = React.useState(0);
  const [daltaValue, setDaltaValue] = React.useState(0);
  const [date, setdate] = React.useState(null);

  useEffect(() => {
    setTotalAmount(data.price * data.numberOfUnits);
    setDaltaValue(data.actualValue - data.p50);
  }, []);

  const handleColorChange = (cell: any) => {
    if (!cell?.handleColorChange) return;
    let currentValue = Number(data[cell.key]?.split(" ")[0]);
    let dangerValue = Number(
      data[cell?.handleColorChange.compareColumn]?.split(" ")[0]
    );
    if (dangerValue > currentValue) return cell?.handleColorChange.color;
    return "";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id, Action) => {
    switch (Action) {
      case "Edit":
        navigate("/billing/" + tableType + "/" + id);
        break;
      case "Delete":
        onDeleteClick(id);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };
  const switchShowingCells = () => setShowCells(!showCells);
  const doubleClickEvt = (evt) => {
    if (isDoubleClickActive === undefined || isDoubleClickActive === false)
      return;
    navigate((module || "/billing/") + tableType + "/" + data.id);
  };
  const customizeEditColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"right"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "end",
          }}
        >
          <Button2
            size={32}
            type={"secondary"}
            iconLeft={<CustomEditSVG width={14} height={14} />}
            onClick={() => {
              navigate((module || "/billing/") + tableType + "/" + id);
            }}
          >
            Edit
          </Button2>
        </div>
      </TableCell>
    );
  };

  const customizeEditDeleteColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"center"}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            <Button2
              size={32}
              type={"secondary"}
              iconLeft={<CustomEditSVG width={14} height={14} />}
              onClick={() => {
                navigate((module || "/billing/") + tableType + "/" + id);
              }}
            >
              Edit
            </Button2>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button2
              size={32}
              type={"secondary"}
              icon={<CustomDeleteSVG width={14} height={14} />}
              onClick={() => {
                onDeleteClick(id);
              }}
            ></Button2>
          </div>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "end",
            }}
          > */}

          {/* </div> */}
          <div>
            {/* <Button2
              size={32}
              type={"secondary"}
              icon={<CustomDeleteSVG width={14} height={14} />}
              onClick={(Action) => {
                onDeleteClick(id);
              }}
            ></Button2> */}
          </div>

          {/* <div
            className={classes.editButton}
            onClick={() => {
              navigate((module || "/billing/") + tableType + "/" + id);
            }}
          >
            <CustomEditSVG width={16} height={16} />
            <span style={{ marginLeft: "8px" }}>Edit</span>
          </div> */}
          {/* <div
            className={classes.deleteButton}
            onClick={(Action) => {
              onDeleteClick(id);
            }}
          >
            <CustomDeleteSVG width={16} height={16} />
          </div> */}
        </div>
      </TableCell>
    );
  };

  const customizePortfolioEditColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          onClick={() => {
            navigate((module || "/billing/") + tableType + "/" + id);
          }}
        >
          <EditOutlined />
          <span>Edit</span>
        </div>
      </TableCell>
    );
  };

  const customizeViewColumn = (data: any) => {
    return (
      <TableCell
        style={{ cursor: "pointer" }}
        key={data["id"]}
        align={"center"}
      >
        <CustomButton
          title="View all"
          color="#0C1E33"
          onClick={() => {
            navigate(
              (module || "/billing/") + tableType + "/" + data["id"] + "/info",
              {
                state: {
                  data,
                },
              }
            );
          }}
        />
      </TableCell>
    );
  };
  const customizeMoreColumn = (data: any) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={data["id"]} align={"left"}>
        <Button
          startIcon={<ELLIPSES />}
          style={{
            color: "black",
            textTransform: "none",
            fontSize: "13px",
            padding: "8px 16px",
            fontWeight: 500,
            backgroundColor: "#E8ECF2",
          }}
          onClick={() => {
            onViewMoreClick({ toggleModal: true, rowId: data["id"] });
          }}
        >
          More
        </Button>
      </TableCell>
    );
  };

  const customizeViewMoreColumn = (id: string) => {
    const x = 2;
    return (
      <TableCell
        style={{ cursor: "pointer" }}
        key={data["id"]}
        align={"center"}
      >
        <CustomButton
          title="View more"
          color="#0C1E33"
          onClick={() => {
            onViewMoreClick({ toggleModal: true, ptwId: data["id"] });
          }}
        />
      </TableCell>
    );
  };

  const customizeDeleteColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"center"}>
        <Button2
          size={32}
          type={"secondary"}
          icon={<CustomDeleteSVG width={14} height={14} />}
          onClick={() => {
            onDeleteClick(id);
          }}
        ></Button2>
      </TableCell>
    );
  };

  const customizePortfolioDeleteColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          onClick={(Action) => {
            onDeleteClick(id);
          }}
        >
          <DeleteOutline />
          <span>Delete</span>
        </div>
      </TableCell>
    );
  };
  const customizePlantsVisibiltyColumn = (id: string) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          onClick={(Action) => {
            onDeleteClick(id);
          }}
        >
          <span>All</span>
        </div>
      </TableCell>
    );
  };

  const customizeActionDropdown = (obj: any) => {
    return (
      <TableCell key={obj.id} align={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreHorizIcon style={{ color: "black" }} />
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleClose(obj.id, "Edit")}>
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleClose(obj.id, "Delete")}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
      </TableCell>
    );
  };

  const handleDateChange = (date, key, id) => {
    setdate(date);
    onUpdateField(id, key, date);
  };

  const handleChange = (e, key, id) => {
    onUpdateField(id, key, e.target.value);
    if (tableType === "invoice")
      setTotalAmount(Number(e.target.value) * data.price);
    if (tableType === "forecast") {
      if (Number(e.target.value) < 0) return;
      setDaltaValue(Number(e.target.value) - data.p50);
    }
  };

  const preventMinus = (e, type) => {
    if (e.code === "Minus" && type === "non-negative") {
      e.preventDefault();
    }
  };

  const classes = useStyles();

  const customizeInputField = (
    id: string,
    type: string,
    key: string,
    value: string,
    percenatge: boolean
  ) => {
    return (
      <TableCell
        className={
          (type === "number" || type === "non-negative") && percenatge
            ? classes.tableFlex
            : ""
        }
        style={{ cursor: "pointer" }}
        key={id}
        align={"left"}
      >
        <TextField
          variant="outlined"
          type={type}
          id={key + id}
          inputProps={{
            min: 0,
            style: {
              textAlign:
                type === "number" || type === "non-negative"
                  ? "center"
                  : "left",
            },
          }}
          name={key}
          defaultValue={value}
          onKeyPress={(e) => preventMinus(e, type)}
          onChange={(e) => handleChange(e, key, id)}
        />
        &nbsp;
        {type === "number" && percenatge ? "%" : ""}
      </TableCell>
    );
  };

  const setValue = (cell) => {
    if (tableType === "invoice" && cell.key === "totalAmount") {
      return totalAmount ? totalAmount.toFixed(2) : data[cell.key].toFixed(2);
    } else if (cell.isDate === true) {
      if (data[cell.key]) return moment(data[cell.key]).format("DD-MM-YY");
      else {
        return "-";
      }
    } else if (cell.decimal) {
      return data[cell.key] != null ? data[cell.key].toFixed(cell.decimal) : 0;
    } else if (tableType === "forecast" && cell.key === "delta") {
      if (daltaValue === undefined || daltaValue === null)
        return daltaValue.toFixed(2);

      return daltaValue.toFixed(2);
    } else {
      return data[cell.key];
    }
  };

  const customizeDatePicker = (id: string, key: string, value: Date) => {
    return (
      <TableCell style={{ cursor: "pointer" }} key={id} align={"left"}>
        <Datepicker
          value={value || date}
          onChange={(newDate: Date) => handleDateChange(newDate, key, id)}
        />
      </TableCell>
    );
  };

  return (
    <>
      <TableRow
        className="Table-hover"
        sx={{ "& > *": { borderBottom: showCells ? "unset" : null } }}
        onClick={(event) => { }}
        role="checkbox"
        aria-checked={false}
        tabIndex={-1}
        selected={false}
        onDoubleClick={(evt) => {
          doubleClickEvt(evt);
        }}
      >
        {columns?.map((cell, idx) => {
          if (cell.key === "edit") {
            return customizeEditColumn(data["id"]);
          } else if (cell.key === "portfolioEdit") {
            return customizePortfolioEditColumn(data["id"]);
          } else if (cell.key === "delete") {
            return customizeDeleteColumn(data["id"]);
          } else if (cell.key === "userEditDelete") {
            return customizeEditDeleteColumn(data["tenantUserId"]);
          } else if (cell.key === "plantsVisibility") {
            return customizePlantsVisibiltyColumn(data["id"]);
          } else if (cell.key === "portfolioDelete") {
            return customizePortfolioDeleteColumn(data["id"]);
          } else if (cell.key === "actions") {
            return customizeActionDropdown(data);
          } else if (cell.key === "view") {
            return customizeViewColumn(data);
          } else if (cell.key === "viewMore") {
            return customizeViewMoreColumn(data);
          } else if (cell.key === "more") {
            return customizeMoreColumn(data);
          } else if (
            cell.type === "text" ||
            cell.type === "number" ||
            cell.type === "non-negative"
          ) {
            return customizeInputField(
              data["id"],
              cell.type,
              cell.key,
              data[cell.key],
              cell.isPercentage
            );
          } else if (cell.type === "date") {
            return customizeDatePicker(data["id"], cell.key, data[cell.key]);
          } else if (cell.type === "checkbox") {
            return (
              <TableCell key={cell.key + idx} align={cell.align}>
                {data[cell.key]?.map((item) => (
                  <Checkbox
                    checked={item?.hasAccess}
                    label={item?.permissionName}
                    onChange={() => handleCheckboxClick(item, data)}
                  />
                ))}
              </TableCell>
            );
          } else {
            return (
              <TableCell key={cell.key + idx} align={cell.align}>
                <div
                  style={{
                    color: handleColorChange(cell),
                    textTransform: cell.isCapitalized ? "capitalize" : "none",
                  }}
                >
                  {cell?.getValueComponent
                    ? cell?.getValueComponent(data[cell.key])
                    : setValue(cell)}
                </div>
              </TableCell>
            );
          }
        })}
        {isActionAllow === false ? null : !hiddenContent ? null : (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={switchShowingCells}
            >
              {showCells ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {!showCells ? null : (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, marginTop: 16 }}
            colSpan={columns?.length + 1}
          >
            <Collapse in={showCells} timeout="auto" unmountOnExit>
              {hiddenContent}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
