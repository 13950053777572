import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getPortfolioCardsKPIsToday,
  getPortfolioCardsPlantSpecificToday,
} from "../../../features/Sidem/portfolios/portfolioActions";
import {
  deleteCustomTemplate,
  generateCustomReport,
  getPortfolioReport,
  getReportTemplates,
} from "../../../features/Sidem/reports/reportActions";
import { reportSelector } from "../../../features/Sidem/reports/reportSlice";
import { useNavigate } from "react-router-dom";
interface DropdownOption {
  label: string;
  value: any;
}

interface Props {
  selectedPortfolio?: any;
  selectedPlant: any;
  selectedPlants: any[];
  setSelectedPlants: any;
}

interface Data {
  GetPortfolioReport: (
    tenant: any,
    datefrom,
    dateTo,
    selectedIndex,
    callback
  ) => void;
  GenerateCustomReport: (templateId: any, datefrom, dateTo, callback) => void;
  DeleteCustomReport: (id: any) => void;
  UpdateCustomReport: (obj: any) => void;
  customReports: any;
  loading: any;
  error: any;
  plant: any;
  portfolio: any;
  severity: any;
  setSeverity: any;
  setMessage: any;
  setIsOpen: any;

  message: any;
  isOpen: any;
}

export const useReports = ({
  selectedPortfolio,
  selectedPlant,
  selectedPlants,
  setSelectedPlants,
}: Props): Data => {
  const [loading, setloading] = useState(null);
  const [error, setError] = useState("");
  const [portfolio, setPortfolio] = useState([] as Array<DropdownOption>);
  const [plant, setPlant] = useState([] as Array<DropdownOption>);
  const [customReports, setCustomReports] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [severity, setSeverity] = useState("success");

  const dispatch = useDispatch();
  const { deleteCustomTemplateSuccessful } = useSelector(reportSelector);

  useEffect(() => {
    getPortfolio();
    getCustomReports();
  }, []);

  useEffect(() => {
    if (!selectedPortfolio) return;
    setPlant([]);
    setSelectedPlants([]);
    getPlants(selectedPortfolio);
  }, [selectedPortfolio]);

  const GetPortfolioReport = async (
    tenant: any,
    datefrom: any,
    dateTo: any,
    selectedIndex,
    callback: any
  ) => {
    setloading(true);
    let URI = "";
    const plants = selectedPlants.map((pl) => pl.value);
    const plantsNames = selectedPlants.map((pl) => pl.label);
    if (selectedIndex === 0)
      URI = `SkyfriSidemApi/v1/GetPortfolioReports?fromDate=${moment(datefrom).format("YYYY-MM-DD")
        }&toDate=${moment(dateTo).format("YYYY-MM-DD")
        }&plantId=${plants}`;
    if (selectedIndex === 1)
      URI = `SkyfriSidemApi/v1/GetPortfolioPerformanceReport?fromDate=${moment(datefrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")
        }&plantId=${plants}`;
    const data: any = await dispatch(getPortfolioReport(URI));
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      let fileName = "Report.xlsx";
      if (selectedIndex === 0) {
        fileName = `DGR_${plantsNames?.join('_')?.slice(0, 32)}_${moment(datefrom).format("DD-MM-YYYY")
          }_to_${moment(dateTo).format("DD-MM-YYYY")}.xlsx`;
      }
      if (selectedIndex === 1) {
        fileName = `Performance Report_${plantsNames?.join('_')?.slice(0, 32)}_${moment(datefrom).format("DD-MM-YYYY")
          }_to_${moment(dateTo).format("DD-MM-YYYY")}.xlsx`;
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      callback("success");
    } else {
      callback("error");
    }
    setloading(false);
  };

  const GenerateCustomReport = async (
    templateId: any,
    datefrom: any,
    dateTo: any,
    callback: any
  ) => {
    setloading(true);
    const data: any = await dispatch(
      generateCustomReport(
        templateId,
        moment(datefrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
        selectedPlant
      )
    );
    if (data) {
      if (data?.message) {
        callback("error");
        setloading(false);
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Report_${selectedPlant}_${moment(datefrom).format(
        "DD-MM-YYYY"
      )}&${moment(dateTo).format("DD-MM-YYYY")}).xlsx`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      callback("success");
    } else {
      callback("error");
    }
    setloading(false);
  };

  const getPortfolio = async () => {
    const data: any = await dispatch(getPortfolioCardsKPIsToday());
    if (data) {
      const newPortfolio: Array<DropdownOption> = data?.map((item) => ({
        label: item.portfolioName,
        value: item.portfolioName,
      }));
      setPortfolio(newPortfolio);
    }
  };

  const getPlants = async (portfolio: string) => {
    const data: any = await dispatch(
      getPortfolioCardsPlantSpecificToday(portfolio)
    );
    if (data) {
      const newPlants: Array<DropdownOption> = data?.map((item) => ({
        label: item.plant_Name,
        value: item.plantId,
      }));
      setPlant(newPlants);
    }
  };
  const getCustomReports = async () => {
    const data: any = await dispatch(getReportTemplates());
    if (data) {
      setCustomReports(data);
    }
  };
  const DeleteCustomReport = async (tempId: any) => {

    await dispatch(deleteCustomTemplate(tempId));
  };
  useMemo(() => {
    if (deleteCustomTemplateSuccessful) {
      setMessage("Report deleted successfully!");
      setSeverity("success");
      setIsOpen(true);
      getCustomReports();
    }
  }, [deleteCustomTemplateSuccessful]);

  const navigate = useNavigate();
  const UpdateCustomReport = (obj: any) => {
    navigate("/sidem/custom-reports", { state: obj });
  };

  return {
    GetPortfolioReport,
    loading,
    error,
    portfolio,
    plant,
    customReports,
    DeleteCustomReport,
    UpdateCustomReport,
    severity,
    message,
    isOpen,
    setIsOpen,
    setSeverity,
    setMessage,
    GenerateCustomReport,
  };
};
