import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { MenuItem, PagesRoles, Roles, RolesMap } from "../../shared/interfaces";
import { SidemPagesEnum, getSidemMenuItem } from "../../sidem";
import { onBoardingPagesEnum, getOnboardingMenuItem } from "../../onBoarding";
import { getAnalyticsMenuItem, AnalyticsPagesEnum } from "../../analytics";
import { MonitoringPagesEnum, getMonitoringMenuItem } from "../../monitoring";
import { errorSelector } from "../../features/Notifications/errorSlice";
import { getScope } from "../../store/authToken";

interface Data {
  errorMessage: any;
  onError: any;
  topMenuRef: any;
  goHome: () => void;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  currentItem: MenuItem;
  setCurrentItem: (value: MenuItem) => void;
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
  showHeader: boolean;
  setShowHeader: (value: boolean) => void;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  menu: Array<MenuItem>;
  setMenu: (value: Array<MenuItem>) => void;
  currentModule: string;
  setCurrentModule: (value: string) => void;
  activeMenuItem: MenuItem;
  setActiveMenuItem: (value: MenuItem) => void;
  previousMenuItem: MenuItem;
  setPreviousMenuItem: (value: MenuItem) => void;
  showSubHeader: any;
  setShowSubHeader: (value: any) => void;
  onHover: boolean;
  setOnHover: (value: boolean) => void;
  activeSubItemPath: string;
  setActiveSubItemPath: (value: string) => void;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: (value: HTMLButtonElement | null) => void;
  menuItemClicked: any;
  setMenuItemClicked: (value: any) => void;
  selectedAccordion: any;
  setSelectedAccordion: (value: any) => void;
  active: boolean;
  setActive: (value: boolean) => void;
  hoveredItem: any;
  setHoveredItem: (value: any) => void;
  navigate: any;
  location: any;
  isMobile: boolean;
}

interface Props {
  roles: RolesMap;
  tenant: string;
}

export const useNavigation = ({ roles, tenant }: Props): Data => {
  type Menu = Array<MenuItem>;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);
  const [menu, setMenu] = useState([]);
  const [currentModule, setCurrentModule] = useState("");
  const [activeMenuItem, setActiveMenuItem] = useState({} as MenuItem);
  const [previousMenuItem, setPreviousMenuItem] = useState({} as MenuItem);
  const [showSubHeader, setShowSubHeader] = useState({} as any);
  const [onHover, setOnHover] = useState(false);
  const [activeSubItemPath, setActiveSubItemPath] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuItemClicked, setMenuItemClicked] = useState({
    id: null,
    clicked: false,
  });
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [active, setActive] = useState(false);
  const { errorMessage, onError } = useSelector(errorSelector);
  const topMenuRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();

  if (window.location.pathname === "/") {
    goHome();
  }

  const populateMenu = (roles: any) => {
    const menu: Menu = [];
    if (roles[Roles.MONITORING]) {
      menu.push(getMonitoringMenuItem());
    }

    if (roles[Roles.ANALYTICS]) {
      menu.push(getAnalyticsMenuItem(roles[PagesRoles.SKYFRIDEMO], tenant));
    }

    if (roles[Roles.ONBOARDING]) {
      menu.push(getOnboardingMenuItem());
    }
    setMenu(menu);
  };

  const onChangeModule = (item: MenuItem) => {
    localStorage.setItem("scope", getScope(2));
    setActiveMenuItem(item);
    setShowSubHeader(item);
    const pathName = location?.pathname;
    item?.items?.forEach((subItem) => {
      if (pathName.includes(subItem?.path)) {
        setActiveSubItemPath(subItem?.path);
      }
    });
  };

  useEffect(() => {
    populateMenu(roles);
    const pathname = location?.pathname;
    const url = pathname?.split("/");
    const module = url[1];
    const SkyfriDemoRole = roles[PagesRoles.SKYFRIDEMO];

    if (module === "monitoring" && roles[Roles.MONITORING]) {
      setCurrentModule("MONITORING");
      return onChangeModule(getMonitoringMenuItem());
    }

    if (module === "analytics" && roles[Roles.ANALYTICS]) {
      setCurrentModule("ANALYTICS");
      return onChangeModule(getAnalyticsMenuItem(SkyfriDemoRole, tenant));
    }

    if (module === "sidem" && roles[Roles.ANALYTICS]) {
      setCurrentModule("SIDEM");
      return onChangeModule(getSidemMenuItem(SkyfriDemoRole));
    }

    if (module === "onboarding" && roles[Roles.ONBOARDING]) {
      setCurrentModule("ONBOARDING");
      return onChangeModule(getOnboardingMenuItem());
    }
  }, [location, roles]);

  function goHome() {
    if (roles?.MONITORING == true) {
      if (location.pathname === MonitoringPagesEnum.MonitoringDashboard) return;
      navigate(MonitoringPagesEnum.MonitoringDashboard);
    } else if (roles?.ANALYTICS == true)
      navigate(AnalyticsPagesEnum.AnalyticsDashboard);
    else if (roles?.SIDEM == true) navigate(SidemPagesEnum.ClientPage);
    if (roles?.MONITORING == true)
      if (location.pathname === MonitoringPagesEnum.MonitoringDashboard) null;
      else navigate(MonitoringPagesEnum.MonitoringDashboard);
    else if (roles?.SIDEM == true) navigate(SidemPagesEnum.ClientPage);
    else if (
      roles?.SIDEM == false &&
      roles?.CMMS != true &&
      roles?.BILLING == false &&
      roles?.ONBOARDING == true
    )
      navigate(onBoardingPagesEnum.TenantPage);
  }

  return {
    isMobile,
    hoveredItem,
    setHoveredItem,
    goHome,
    drawerOpen,
    setDrawerOpen,
    currentItem,
    setCurrentItem,
    showMenu,
    setShowMenu,
    showHeader,
    setShowHeader,
    showSidebar,
    setShowSidebar,
    menu,
    setMenu,
    currentModule,
    setCurrentModule,
    activeMenuItem,
    setActiveMenuItem,
    previousMenuItem,
    setPreviousMenuItem,
    showSubHeader,
    setShowSubHeader,
    onHover,
    setOnHover,
    activeSubItemPath,
    setActiveSubItemPath,
    expanded,
    setExpanded,
    anchorEl,
    setAnchorEl,
    menuItemClicked,
    setMenuItemClicked,
    selectedAccordion,
    setSelectedAccordion,
    active,
    setActive,
    topMenuRef,
    navigate,
    location,
    errorMessage,
    onError,
  };
};
