import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as GRANULARITY_ICON } from "../../../shared/icons/dropdown/GRANULARITY.svg";

interface Props {
  value: number;
  items: {
    label: string;
    value: number;
  }[];
  onChange: (value: number | string) => void;
}

export const ChartSelect: React.FC<Props> = (props: Props) => {
  const { value, items, onChange } = props;

  return (
    <Select
      id="granularity"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        minWidth: 130,
        borderRadius: 2,
        backgroundColor: "#FFFFFF",
        color: "#000000",
        height: 32,
        fontSize: "14px",
      }}
      startAdornment={<GRANULARITY_ICON />}
    >
      {items.map((item, index) => {
        return (
          <MenuItem key={index} value={item.value} style={{ fontSize: "13px" }}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
