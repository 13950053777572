import React, { useMemo } from 'react'
import clsx from 'clsx';
import { Button } from "@material-ui/core";
import WaterfallChart from '../../../../components/WaterfallChart';
import Loader from '../../../../components/Loader';
import Widget from '../../../../components/Widget';
import { CustomTooltip } from './CustomTooltip.component';
import { useLossWaterfall } from './useLossWaterfall';
import { useStyles } from './styles';
import DownloadMenu from '../../../../components/DownloadMenu';

interface Props {
  plantId: string;
  date: string;
  definition?: any;
}

export const LossWaterfallWidget: React.FC<Props> = ({ plantId, date, definition }) => {
  const classes = useStyles();

  const { formatTypes, setFormat, onClick, filterValue, profileData, loading, error, format, data } = useLossWaterfall({ plantId, date });

  const getWaterfallMenu = (active: string) => (
    <div className={classes.waterfallMenu}>
      {formatTypes.map(item => (
        <div
          key={item.label}
          className={clsx(classes.item, { [classes.active]: item.value === active })}
          onClick={() => {
            setFormat(item.value);
          }}
        >
          {item.label}
        </div>
      ))}
      <DownloadMenu chartType={"LossWaterFallPlant"} lossWaterFallType={`${format}`} plantId={plantId} />
    </div>
  );

  const getButton = (name: string, label: string) => (
    <Button
      className={clsx(classes.barTabButton, filterValue === name && classes.barTabButtonSelected)}
      variant='contained'
      name={name}
      onClick={onClick}
    >
      {label}
    </Button>
  );

  const trackerButtons = () => (
    <div className={classes.barTabsContainer}>
      {getButton('tracker', 'Tracker')}
      {getButton('nonTracker', 'Non-tracker')}
      {getButton('all', 'All')}
    </div>
  );

  const content = useMemo(
    () =>
      !profileData?.hasTracker ? (
        <WaterfallChart
          data={data}
          formatLabel={format}
          definition={definition}
          tooltipContent={<CustomTooltip format={format} />}
        />
      ) : (
        <>
          <WaterfallChart
            data={data}
            formatLabel={format}
            definition={definition}
            tooltipContent={<CustomTooltip format={format} />}
          />
          {trackerButtons()}
        </>
      ),
    [profileData, data, format]
  );
  return (
    <Widget label="Loss Waterfall" size={7} height={600} customMenu={getWaterfallMenu(format)}>
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />
      {(!data?.length || loading) ? null : content}
    </Widget>
  )
}
