import { DropdownOption } from "../../../analytics/components/interfaces";
import * as analyticsSharedSlice from "./analyticsSharedSlice";


export const setGlobalDateAction = (payload: object) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.setGlobalDate(payload));
        return payload;
    };
};

export const setGlobalPlantIdAction = (payload: DropdownOption[]) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.getPlantId(payload));
        return payload;
    };
};


export const setDateComparison = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.dateComparison(payload));
        return payload;
    };
};


export const setGlobalPortfolioNameAction = (payload: DropdownOption[]) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.getPortfolioName(payload));
        return payload;
    };
};

export const setGlobalLiveButtonAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.setLiveButton(payload));
        return payload;
    };
};

export const setGlobalAccordionExpandAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.setAccordionExpand(payload));
        return payload;
    };
}

export const setMultiplePlantsAction = (payload: boolean) => {
    return async (dispatch: (arg0: any) => void) => {
        dispatch(analyticsSharedSlice.setMultiplePlants(payload));
        return payload;
    };
}