import { useGetBatteryChargeDischargeSocWidget } from "../../useBatteryChargeDischargeSocWidget";
import { DataWithUnitWidget } from "../DataWithUnitWidget";

export const BatteryChargeDischargeSocWidget = () => {
  const {
    data: batteryChargeDischargeSocData,
    isLoading: isBatteryChargeDischargeSocLoading,
  } = useGetBatteryChargeDischargeSocWidget();

  return (
    <DataWithUnitWidget
      title="Charge, Discharge & SOC"
      options={batteryChargeDischargeSocData}
      optionsCount={{ top: 2, bottom: 4 }}
      isLoading={isBatteryChargeDischargeSocLoading}
    />
  );
};
