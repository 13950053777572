import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    minHeight: "calc(100% - 64px)",
    overflow: "auto",
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
  },

  header: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3, 0),
  },

  headerTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  marginRight: {
    marginRight: "10px",
  },
  marginLeft: {
    marginLeft: "10px",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "5px",
  },
  secondayLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "5px",
    width: "100px",
  },
  displayFlex: {
    display: "flex",
  },
  justifyContent: {
    justifyContent: "end",
  },
}));
