import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { clientSelector } from "../../../../../features/Sidem/client/clientSlice";
import { convertTimeZone, get24Hours } from "../../../../../shared/utils/utils";
import { graphLines } from "./data";
import { fetchAsync } from "../../../../../shared/utils/apiService";

interface Item {
  blockId: number;
  equipmentName: string;
  inverterId: number;
  specificCurrent: number;
  stringId: string;
}

interface Props {
  plantId: string;
  date: string;
  inverterId: string;
  blockId: string;
}

interface Data {
  data: Array<Array<number>>;
  error?: string;
  loading?: boolean;
  xLabels: Array<string>;
  yLabels: Array<string>;
  setLines: any;
  lines: any;
}

export const useInverterCustom = (props: Props): Data => {
  const [data, setData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { client } = useSelector(clientSelector);
  const [lines, setLines] = useState([]);

  const transformData = (data: Array<Item>) => {
    if (!data?.length) {
      setError("No data");
      return setLoading(false);
    }
    const newData = data?.map((item: any) => ({
      sensor_time: convertTimeZone(client?.timeZone, "HH:mm", item.sensorTime),
      "R Phase Current": item?.rPhaseCurrent,
      "Y Phase Current": item?.yPhaseCurrent,
      "B Phase Current": item?.bPhaseCurrent,
      "AC Power": item?.acPower,
      "AC Frequency": item?.acFrequency,
      "DC Current": item?.dcCurrent,
      "DC Power": item?.dcPower,
      "DC Voltage": item?.dcVoltage,
      "Total Energy": item?.totalEnergy,
      "Todays Energy": item?.todayEnergy,
      "Inverter Efficiency": item?.inverterEfficiency,
      POA: item?.poa,
    }));
    const initialData = new Array(24).fill(null).reduce((res, item, idx) => {
      const time = get24Hours(client?.timeZone, "HH:mm", idx);
      res[time] = { time };
      return res;
    }, {});
    const map = newData?.reduce((res, curr) => {
      const timeLabel = curr["sensor_time"];
      if (!res[timeLabel]) return res;
      res[timeLabel] = {
        ...curr,
      };
      return res;
    }, initialData);
    const sortedData = Object.keys(map)
      ?.sort()
      ?.map((item) => map[item]);

    setLines(graphLines);
    setData(newData);
    setYLabels([""]);
    // setXLabels(sortedData?.map((item) => item.stringId));
  };

  useEffect(() => {
    const { date, plantId, blockId, inverterId } = props;

    if (!date || !plantId || !blockId || !inverterId) return;

    setLoading(true);
    setError("");
    fetchAsync(
      `/SkyfriSidemApi/v1/GetRealTimeInverterInformationGraph?date=${date}&plantId=${plantId}&blockId=${blockId}&inverterId=${inverterId}`,
      "GET"
    )
      .then((data: Array<Item>) => {
        transformData(data);
        setLoading(false);
      })
      .catch(setError);
  }, [props]);

  return {
    data,
    lines,
    setLines,
    xLabels,
    yLabels,
    error,
    loading,
  };
};
