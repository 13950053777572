export const getLabelForWMS = (key) => {
  const label = key.split("_")[0] + " " + key.split("_")[1];
  return label;
};

export const getUnitForWMS = (key) => {
  const unit = key.split("_")[2]
    ? key.split("_")[2].includes("Degrees")
      ? "°"
      : key.split("_")[2].includes("Percent")
      ? "%"
      : key.split("_")[2]
    : "";
  return unit;
};
