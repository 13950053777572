export const timeAgo = (utcTimeString: string): string => {
  const millisecondsPerMinute: number = 60 * 1000;
  const millisecondsPerHour: number = 60 * millisecondsPerMinute;
  const millisecondsPerDay: number = 24 * millisecondsPerHour;
  const millisecondsPerMonth: number = 30 * millisecondsPerDay;

  const utcDate: Date = new Date(utcTimeString);
  const currentDate: Date = new Date();
  const localDate: Date = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * millisecondsPerMinute
  );
  const timeDifference: number = currentDate.getTime() - localDate.getTime();

  const minutesAgo: number = Math.floor(timeDifference / millisecondsPerMinute);
  const hoursAgo: number = Math.floor(timeDifference / millisecondsPerHour);
  const daysAgo: number = Math.floor(timeDifference / millisecondsPerDay);
  const monthsAgo: number = Math.floor(timeDifference / millisecondsPerMonth);

  if (monthsAgo >= 1) {
    return `${monthsAgo} ${monthsAgo === 1 ? "month" : "months"} ago`;
  } else if (daysAgo >= 1) {
    return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
  } else if (hoursAgo >= 1) {
    return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
  } else if (minutesAgo >= 1) {
    return `${minutesAgo} ${minutesAgo === 1 ? "min" : "mins"} ago`;
  } else {
    return "just now";
  }
};

export const filterMenu = [
  {
    category: "Statuses",
    categoryId: "statuses", // Unique ID for the category
    options: [
      { label: "All statuses", checked: false, id: "allStatus" },
      { label: "Active", checked: false, id: "active" },
      { label: "Self-resolved", checked: false, id: "selfResolved" },
    ],
  },
  {
    category: "Types",
    categoryId: "types", // Unique ID for the category
    options: [
      { label: "All types", checked: false, id: "allTypes" },
      { label: "Underperformance", checked: false, id: "underPerformance" },
      {
        label: "Communication failure",
        checked: false,
        id: "communicationFailure",
      },
      { label: "Tripping", checked: false, id: "tripping" },
      { label: "Inverter error", checked: false, id: "inverterError" },
    ],
  },
  {
    category: "Sort Order",
    categoryId: "sortOrder", // Unique ID for the category
    options: [
      { label: "Newest on top", checked: false, id: "newestOnTop" },
      { label: "Oldest on top", checked: false, id: "oldestOnTop" },
    ],
  },
];

export const statusMenu = [
  { label: "All statuses", id: "allStatuses" },
  { label: "Active", id: "active" },
  { label: "Self-resolved", id: "selfResolved" },
];

export const typeMenu = [
  { label: "All types", id: "allTypes" },
  { label: "Underperformance", id: "underPerformance" },
  {
    label: "Communication failure",
    id: "communicationFailure",
  },
  { label: "Tripping", id: "tripping" },
  { label: "Inverter error", id: "inverterError" },
];

export const sortMenu = [
  { label: "Newest on top", id: "newestOnTop" },
  { label: "Oldest on top", id: "oldestOnTop" },
];

// Example usage
