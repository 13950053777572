import { Popover } from "@material-ui/core";

export enum Sides {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right",
  center = "center",
}

type VerticalSides = Sides.top | Sides.bottom | Sides.center | number;
type HorizontalSides = Sides.left | Sides.right | Sides.center | number;

export interface Box {
  vertical: VerticalSides;
  horizontal: HorizontalSides;
}

interface Props {
  open?: boolean;
  anchorEl: any;
  onClose: () => void;
  children: any;
  anchorOrigin: Box;
  transformOrigin: Box;
}

export const Popup: React.FC<Props> = ({
  anchorEl,
  onClose,
  children,
  anchorOrigin,
  transformOrigin,
}) => (
  <Popover
    id={!!anchorEl ? "simple-popover" : undefined}
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
  // style={{ pointerEvents: "none" }} the culprit of the bug because of this the popup is not clickable
  >
    {children}
  </Popover>
);
