import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deletePortfolioReq } from '../../../../features/Onboarding/portfolios/portfolioAction'
import { addTenantReq } from '../../../../features/Onboarding/tenant/tenantActions'
import { fetchAsync } from "../../../../shared/utils/apiService";

interface Props {
  tenantId: string;
}

interface Data {
  upsertTenant: (params: any, id: string, callback: any) => void;
  deleteModuleTenants: (moduleTenantId: any, tenantId: any, callback: any) => any;
  deletePortfolio: (portfolioId: any, callback: any) => any;
  deleteUserTenant: (tenantUserId: any, callback: any) => any;
  deleteTeam: (teamId: any, callback: any) => any;
  deleteRole: (roleId: any, callback: any) => any;
  upsertModuleTenant: (params: any, callback: any) => any;
  upsertPermissions: (params: any, callback: any) => any;
  moduleOptions: any;
  setModuleOptions: any;
  tenant: any;
  moduleTenant: any;
  portfolio: any;
  userTenant: any;
  team: any;
  roles: any;
  loading: any;
  module: any;
  setModule: any;
}

export const useTenantSave = ({ tenantId }: Props): Data => {
  const [tenant, setTenant] = useState(null);
  const [moduleTenant, setModuleTenant] = useState(null);
  const [moduleOptions, setModuleOptions] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [userTenant, setUserTenant] = useState(null);
  const [team, setTeam] = useState(null);
  const [roles, setRoles] = useState(null);
  const [loading, setloading] = useState(null);
  const [module, setModule] = useState(null);

  const dispatch = useDispatch()

  const fetchAll = async () => {
    await getTenants();
    await getModuleTenants();
    await getportfolio();
    await getuserTenant();
    await getTeams();
  };
  useEffect(() => {
    if (!tenantId || tenantId === "new") return null;
    fetchAll();
  }, [tenantId]);

  const getTeams = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/Teams/GetTeamsByTenantId?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newTeam = data?.map((item) => ({
            ...item,
            id: item.teamId,
          }));
          setTeam(newTeam);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  //todo - remove Roles are not processed here anymore
  const getRoles = async () => {
    setloading(true);
    await fetchAsync(`/TeamRole/All?id=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newRole = data?.map((item) => ({
            ...item,
            id: item.teamRoleId,
          }));
          setRoles(newRole);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getTenants = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/Tenant/GetTenants`, "GET")
      .then((data: any) => {
        if (data) {
          let newTenant = data?.map((item) => ({
            ...item,
            id: item.tenantId,
          }));
          let currentTenant = newTenant.find((x) => x.id === tenantId);
          setTenant(currentTenant);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const upsertModuleTenant = async (params: any, callback: any) => {
    await fetchAsync(`onboardingV2/v1/ModuleTenant/AddModulesForTenant`, "POST", params)
      .then((data: any) => {
        getModuleTenants();
        callback(data);
      })
      .catch((e) => { console.error(e); callback(e) });
  };


  const deleteModuleTenants = async (moduleTenantId: any, tenantId: any, callback: any) => {
    let request = {
      ModuleTenantId: moduleTenantId,
      TenantId: tenantId,
    };

    await fetchAsync(`onboardingV2/v1/ModuleTenant/DeleteModulesForTenant`, "DELETE", request)
      .then((data: any) => {
        getModuleTenants();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };


  const getModule = async (selectedModules) => {
    await fetchAsync(`onboardingV2/v1/Modules/GetModules`, "GET")
      .then((data: any) => {
        if (data) {
          let modules = [];
          let options = data?.map((item) => ({
            label: item.modulName.charAt(0).toUpperCase() + item.modulName.slice(1),
            value: item.modulName,
            id: item.moduleId,
            tenantId: tenantId,
          }));
          setModuleOptions(options);
          // eslint-disable-next-line array-callback-return
          data?.map((item) => {
            let resp = selectedModules?.filter(
              (x) => x.moduleId === item.moduleId
            );

            modules.push({
              isChecked: resp?.length > 0,
              moduleTenantId: resp[0]?.moduleTenantId || "",
              moduleName: item?.modulName,
              moduleId: item?.moduleId,
            });
          });

          setModuleTenant(modules);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getModuleTenants = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/ModuleTenant/GetModulesForTenant?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newModuleTenant = data?.map((item) => ({
            ...item,
            id: item.moduleTenantId,
          }));
          getModule(newModuleTenant);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getportfolio = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/V1/Portfolio/GetPortfolios?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newPortfolio = data?.map((item) => ({
            ...item,
            id: item.portfolioId,
          }));
          setPortfolio(newPortfolio);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const getuserTenant = async () => {
    setloading(true);
    await fetchAsync(`onboardingV2/v1/TenantUsers/GetTenantUsers?tenantId=${tenantId}`, "GET")
      .then((data: any) => {
        if (data) {
          let newPortfolio = data?.map((item) => ({
            ...item,
            // id: item.tenantUserId,
          }));
          setUserTenant(newPortfolio);
          setloading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
      });
  };

  const deletePortfolio = async (portfolioId: any, callback: any) => {
    const payload = {
      PortfolioId: `${portfolioId}`,
    }
    const res: any = await dispatch(deletePortfolioReq(payload))
    if (!res) return;
    callback(res);
    if (res.status === 200) {
      getportfolio();
    }
  };

  const deleteUserTenant = async (tenantUserId: any, callback: any) => {
    const selectedUser = userTenant.find(obj => { return obj.tenantUserId === tenantUserId; });
    let request = {
      TenantId: selectedUser.tenantId,
      TenantUserId: tenantUserId,
      UserId: selectedUser.userId
    };

    await fetchAsync(`onboardingV2/v1/TenantUsers/RemoveUserFromTenant`, "DELETE", request)
      .then((data: any) => {
        getuserTenant();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  const deleteRole = async (teamRoleId: any, callback: any) => {
    await fetchAsync(`/TeamRole?id=${teamRoleId}`, "DELETE")
      .then((data: any) => {
        getRoles();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  const deleteTeam = async (teamId: any, callback: any) => {
    let request = {
      TenantId: tenantId,
      TeamId: teamId,
    };

    await fetchAsync(`onboardingV2/v1/Teams/DeleteTeam`, "DELETE", request)
      .then((data: any) => {
        getTeams();
        callback(data);
      })
      .catch((e) => {
        console.error(e);
        callback(e);
      });
  };

  const upsertTenant = async (params: any, id: string, callback: any) => {
    setloading(true);
    let requestType;
    if (id && id != "new") {
      params.tenantId = id;
      requestType = "PUT";
      await fetchAsync(`onboardingV2/v1/Tenant/UpdateTenant`, requestType, params)
        .then((data: any) => {
          callback(data);
        })
    }
    if (id === "new") {
      requestType = "POST";
      const res: any = await dispatch(addTenantReq(params));
      if (!res) return;
      callback(res);
    }
    setloading(false);
  };

  const upsertPermissions = async (params: any, callback: any) => {
    let requestType = "POST";

    await fetchAsync(`/Tenant`, requestType, params)
      .then((data: any) => {
        callback();
      })
      .catch((e) => console.error(e));
  };

  return {
    module,
    setModule,
    upsertTenant,
    tenant,
    moduleTenant,
    deleteModuleTenants,
    deletePortfolio,
    portfolio,
    userTenant,
    deleteUserTenant,
    loading,
    upsertModuleTenant,
    team,
    deleteTeam,
    roles,
    deleteRole,
    upsertPermissions,
    moduleOptions,
    setModuleOptions
  };
};
