import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Widget } from "../../components/CardsPageWidget";
import { ChartTypes } from "../../components/CardsCharts";
import { formatTypes, getWidgets, showOptions } from "./utils";
import { getFakeWidgets } from "./data";
import {
  getPortfolioCardsKPIsToday,
  getPortfolioPopupKPIsToday,
  getPortfolios,
} from "../../../features/Sidem/portfolios/portfolioActions";
import { useDispatch, useSelector } from "react-redux";
import {
  portfolioSelector,
  setGlobalDateForCards,
} from "../../../features/Sidem/portfolios/portfolioSlice";
import { fetchAsync } from "../../../shared/utils/apiService";

const minute = 1000 * 60;

interface Item {
  actualPerformanceRatio: number;
  dataStreamIsDelayedbyTimespan: boolean;
  expectedPerformanceRatio: number;
  prDelta: number;
  sensorTime: string;
}

interface Props {
  show: string;
  fakeFormat: string;
}

interface Data {
  loading: boolean;
  error: string;
  data: Array<Widget>;
  showFakeData: boolean;
  isDemo: boolean;
  onGoToPage: (widget: Widget) => () => void;
  getCards: () => void;
  arrangeData: (items: any) => void;
  updateWidgetChart: (widget: Widget, chartType: ChartTypes) => void;
  openPortfolioModal?: boolean;
  setOpenPortfolioModal?: (arg: boolean) => void;
  portfolioPopupData?: any;
  plantTitle?: string;
  setPlantTitle?: any;
  popupLoading?: boolean;
  popupError?: string;
  setFormat: (el: string) => void;
  date: string;
}

export const useCards = ({
  show,
  fakeFormat,
}: Props): Data => {
  const navigate = useNavigate();
  const [widgets, setWidgets] = useState([]);
  const [fakeWidgets, setFakeWidgets] = useState([]);
  const [isDemo, setIsDemo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
  const [portfolioPopupData, setPortfolioPopupData] = useState([]);
  const [plantTitle, setPlantTitle] = useState("");
  const [popupLoading, setPopupLoading] = useState(false);
  const [popupError, setPopupError] = useState("");
  const [format, setFormat] = useState("");

  const showFakeData = isDemo && show === showOptions[1];
  const data = showFakeData ? fakeWidgets : widgets;

  const onGoToPage = (widget: Widget) => () => {
    if (!widget?.path?.length) return;
    navigate(`/sidem/portfolios/${widget.path}`);
  };

  useEffect(() => {
    if (show === showOptions[0]) return;

    setFakeWidgets(getFakeWidgets(fakeFormat));
  }, [show, fakeFormat]);
  const dispatch = useDispatch();

  const { portfolios, portfoliosSuccessful, portfoliosFailedMessage } =
    useSelector(portfolioSelector);

  const { date } = useSelector(portfolioSelector);

  useEffect(() => {
    if (!format) {
      if (!date) {
        setFormat(formatTypes[0].value);
      }
      return;
    }
    dispatch(setGlobalDateForCards(format));
  }, [format]);

  const getCards = async (interval?: any) => {
    setLoading(true);
    let data: any;
    if (date === "Today") {
      data = await dispatch(getPortfolioCardsKPIsToday());
    } else {
      data = await dispatch(getPortfolios(date));
    }
    if (!data) return;
    const title = data?.[0]?.client;
    const isDemo = title === "Green Production";
    const newWidgets = getWidgets(data);
    setIsDemo(isDemo);
    setWidgets(newWidgets);
    if (portfoliosFailedMessage) {
      setError(portfoliosFailedMessage);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!date) return;
    let interval;
    const getData = async () => {
      interval = await getCards(minute * 15);
    };
    getData();
    setPortfolioPopupData([]);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  const getPopupData = async () => {
    let data: any;

    if (!portfolioPopupData.length) {
      setPopupLoading(true);
      data = await dispatch(getPortfolioPopupKPIsToday(date));
      setPortfolioPopupData(data);
    }

    if (!data && !portfolioPopupData.length) {
      setPopupLoading(false);
      setPopupError("No Data");
    }
    setPopupLoading(false);
  };

  useEffect(() => {
    if (!openPortfolioModal) return;
    getPopupData();
  }, [openPortfolioModal]);

  const switchChartData = (
    chartType: ChartTypes,
    widget: Widget,
    values: Array<any>
  ) => ({
    chartType,
    chartData: {
      actualResult: widget.actualResult,
      expectedResult: widget.expectedResult,
      level: 100,
      values,
    },
  });

  const getChartOptions = (
    widget: Widget,
    chartType: ChartTypes
  ): Promise<any> => {
    if (widget?.chartData?.values?.length) {
      return Promise.resolve(
        switchChartData(chartType, widget, widget?.chartData?.values)
      );
    } else {
      return new Promise((res, rej) => {
        fetchAsync(
          `SkyfriSidemApi/v1/GetActualPRwithExpectedPRPortfoliocards?timeRequested=${date}&portfolioName=${widget?.path}`,
          "GET"
        )
          .then((data: Array<Item>) => {
            const values = data
              ?.map((item) => ({
                value: item.actualPerformanceRatio,
                expectedValue: item.expectedPerformanceRatio,
                label: item?.sensorTime?.split("T")[1]?.slice(0, 5),
              }))
              ?.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              });

            res(switchChartData(chartType, widget, values));
          })
          .catch((e) => rej(e));
      });
    }
  };

  const updateWidgetChart = async (widget: Widget, chartType: ChartTypes) => {
    if (chartType === "bullet") {
      onGoToPage(widget)();
      return;
    }
    const newWidgets = [];
    for await (let item of widgets) {
      if (item.path === widget?.path) {
        const options = await getChartOptions(widget, chartType);
        newWidgets?.push({
          ...widget,
          ...options,
        });
      } else {
        newWidgets?.push(item);
      }
    }

    setWidgets(newWidgets);
  };

  const arrangeData = (item: any) => {
    // i2
    const delta = {
      title: "Generation delta",
      value: `${item["profit"]}`,
      unit: "KWh",
      negative: item["profit"] < 0 ? true : false,
      growth: item["profit"],
    };
    // i8
    const CUF = {
      title: "CUF",
      value: item.fields[6].value ? item.fields[6].value : 0,
      negative: item["prDelta"] < 0 ? true : false,
      unit: "%",
      growth: item.fields[6].growth,
    };
    // i3
    const alarms = {
      title: "Active alarms",
      value: item["errors"] !== null ? `${item["errors"]}` : `N/A`,
    };
    // const cufDelta = {
    //   title: "CUF delta",
    //   value: item["cufDelta"] ? item["cufDelta"] : 0,
    //   unit: "%",
    // };
    let newData = [...item.fields];
    const iO = item.fields.findIndex((o) => o.title === "Plants");
    const i1 = item.fields.findIndex((o) => o.title === "Capacity DC");
    const i4 = item.fields.findIndex((o) => o.title === "Generation");
    const i5 = item.fields.findIndex((o) => o.title === "Irradiation");
    const i6 = item.fields.findIndex((o) => o.title === "PR");
    const i7 = item.fields.findIndex((o) => o.title === "Availability");
    // const i8 = item.fields.findIndex((o) => o.title === "CUF/PLF");
    const i9 = item.fields.findIndex((o) => o.title === "Grid Availability");

    const plants = newData[iO];
    const capacityDC = newData[i1];
    const generation = newData[i4];
    const irradiation = newData[i5];
    const pr = newData[i6];
    const availability = newData[i7];
    // const CUF = newData[i8];
    const gridAvailability = newData[i9];

    const nData = [
      plants,
      capacityDC,
      delta,
      // prDelta,
      alarms,
      generation,
      irradiation,
      pr,
      CUF,
      availability,
      // cufDelta,
      gridAvailability,
    ];
    return nData;
  };

  return {
    loading,
    error,
    data,
    showFakeData,
    isDemo,
    onGoToPage,
    updateWidgetChart,
    getCards,
    arrangeData,
    openPortfolioModal,
    setOpenPortfolioModal,
    portfolioPopupData,
    plantTitle,
    setPlantTitle,
    popupLoading,
    popupError,
    setFormat,
    date,
  };
};
