import React from "react";
import { CustomCombinedChart } from "../../../../components/CustomCombinedChart";
import { useBatteryHeatmapWidget } from "./useBatteryHeatmapWidget";

interface Props {}

export const BatteryHeatmap: React.FC<Props> = (props: Props) => {
  const {
    // plantId,
    // startDate,
    // endDate,
    // liveButton,
    // globalDate,
    dropdownOptions,
    buttonGroups,
    chartOptions,
  } = useBatteryHeatmapWidget();

  return (
    <CustomCombinedChart
      menu={{
        dropdown: dropdownOptions,
        buttonGroups: buttonGroups,
      }}
      chartOptions={chartOptions}
    />
  );
};
