import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAlarmCategory,
  getAlarmsByPlant,
  getAlarmsEquipmentStructure,
  getAlarmsGenerationLoss,
  getAlarmsTrippingInverters,
  getAlarmsUnderperformingInverters,
} from "../../../features/Sidem/alarms/alarmsActions";
// import {
//   getPortfolioCardsKPIsToday,
//   getPortfolioCardsPlantSpecificToday,
// } from "../../../features/Sidem/portfolios/portfolioActions";
import { getDateForMaps } from "../../../shared/utils/utils";

import { DropdownOption } from "./components/interfaces";
import { useGetPortfoliosAndPlantsQuery } from "../../../features/Sidem/shared/sidemSharedApi";

interface Props {
  selectedPlant: any;
  dateFrom: any;
  dateTo: any;
  categoryId: any;
  selectedPortfolio: any;
  equipmentId: any;
  alarmTypeId: any;
  status: any;
}

interface Data {
  trippingInverters: any;
  portfolio: any;
  underPerformingInverters: any;
  generationLoss: any;
  generationlossToday: any;
  totalGenerationloss: any;
  alarmType: any;
  allEquipments: any;
  category: any;
  equipment: any;
  equipmentList: any;
  plant: any;
  alarms: any;
  alarmGenerationlossToday: any;
  totalAlarms: any;
  trippingUnderperformingAlarms: any;
  activeAlarms: any;
  alarmGenerationlossTotal?: any;
  Pagination: (Count: any) => any;
  loading: any;
  error: any;
  loadingAlarm: any;
}

export const UseAlarmOverview = ({
  selectedPlant,
  dateFrom,
  dateTo,
  categoryId,
  selectedPortfolio,
  equipmentId,
  alarmTypeId,
  status,
}: Props): Data => {
  const [trippingInverters, setTrippingInverters] = useState([]);
  const [underPerformingInverters, setUnderPerformingInverters] = useState([]);
  const [generationLoss, setgenerationLoss] = useState([]);
  const [generationlossToday, setGenerationlossToday] = useState(0);
  const [totalGenerationloss, setTotalGenerationloss] = useState(0);
  const [alarmType, setAlarmType] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [category, setCategory] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [plant, setPlant] = useState([] as Array<DropdownOption>);
  const [alarms, setAlarms] = useState({});
  const [alarmGenerationlossToday, setAlarmGenerationlossToday] = useState(0);
  const [totalAlarms, setTotalAlarms] = useState(0);
  const [trippingUnderperformingAlarms, setTrippingUnderperformingAlarms] =
    useState(0);
  const [activeAlarms, setActiveAlarms] = useState(0);
  const [alarmGenerationlossTotal, setAlarmGenerationlosstotal] = useState(0);
  const [loading, setloading] = useState(null);
  const [loadingAlarm, setLoadingAlarm] = useState(null);
  const [equipment, setEquipment] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedPlant) return;
    getTrippingInverters();
    geUnderPerformingInverters();
    getGenerationLossGraph();
    getAlarmType();
    getEquipments(selectedPlant);
  }, [selectedPlant, dateFrom, dateTo]);

  const {
    data: portfoliosAndPlantsData,
    isFetching: isPortfoliosAndPlantsFetching,
    error: portfoliosAndPlantsError,
  } = useGetPortfoliosAndPlantsQuery({ accessType: "PV" });

  useEffect(() => {
    if (!selectedPlant) return;
    getAlarms(rowsPerPage, 0);
  }, [selectedPlant, dateFrom, dateTo]);

  useEffect(() => {
    if (!selectedPlant) return;
    getAlarms(rowsPerPage, 0);
  }, [status, categoryId, equipmentId, alarmTypeId]);

  useEffect(() => {
    getEquipment(categoryId);
  }, [categoryId, allEquipments]);

  useEffect(() => {
    if (!selectedPortfolio) return;
    getPlants(selectedPortfolio);
  }, [selectedPortfolio]);

  const getEquipment = (category) => {
    if (!categoryId) {
      const newfilteredData = [
        ...new Set(allEquipments.map((item) => item.equipmentName)),
      ];
      const newresp = newfilteredData?.map((item) => ({
        label: item,
        value: item,
      }));
      setEquipment(newresp);
    } else {
      const filteredData = allEquipments?.filter(
        (x) => x?.category === category
      );
      const uniqueData = [
        ...new Set(filteredData.map((item) => item.equipmentName)),
      ];
      const newEquipment = uniqueData?.map((item) => ({
        label: item,
        value: item,
      }));
      setEquipment(newEquipment);
    }
  };

  const portfolio: Array<DropdownOption> = useMemo(() => {
    const fil = [];
    const newPortfolio: Array<DropdownOption> = [];

    portfoliosAndPlantsData?.forEach((item) => {
      if (!fil.includes(item.portfolioName)) {
        fil.push(item.portfolioName);
        newPortfolio.push({
          label: item.portfolioName,
          value: item.portfolioName,
        });
      }
    });

    return newPortfolio;
  }, [portfoliosAndPlantsData]);

  const getTrippingInverters = async () => {
    const data: any = await dispatch(
      getAlarmsTrippingInverters(dateFrom, dateTo, selectedPlant)
    );
    if (!data) return;
    setTrippingInverters(data);
  };

  const geUnderPerformingInverters = async () => {
    const data: any = await dispatch(
      getAlarmsUnderperformingInverters(dateFrom, dateTo, selectedPlant)
    );
    if (!data) return;
    setUnderPerformingInverters(data);
  };

  const getGenerationLossGraph = async () => {
    setloading(true);
    const data: any = await dispatch(
      getAlarmsGenerationLoss(dateFrom, dateTo, selectedPlant)
    );
    setTrippingUnderperformingAlarms(data.length);
    setloading(false);
    if (!data || data?.message) return;
    setgenerationLoss(data);
    setGenerationlossToday(data[0]?.avgGenerationLoss);
    setTotalGenerationloss(data[0]?.totalGenerationLoss);
  };

  const getAlarmType = async () => {
    const data: any = await dispatch(getAlarmCategory());
    if (!data) return;
    const newAlarmType: Array<DropdownOption> = data?.map((item) => ({
      label: item.alarmCategoryName,
      value: item.alarmCategoryName,
    }));
    // filter repeated data
    const vals = newAlarmType.map((item) => item.value);
    const filteredAlarmType = newAlarmType.filter(
      ({ value }, index) => !vals.includes(value, index + 1)
    );
    setAlarmType(filteredAlarmType);
  };

  const getEquipments = async (plantId) => {
    const data: any = await dispatch(getAlarmsEquipmentStructure(plantId));
    if (!data) return;
    setAllEquipments(data);
    const filteredData = [...new Set(data.map((item) => item.category))];
    const resp = filteredData?.map((item) => ({
      label: item,
      value: item,
    }));
    setCategory(resp);
  };

  const getPlants = async (portfolio: string) => {
    setloading(true);
    const newPlants: Array<DropdownOption> = [];
    portfoliosAndPlantsData?.filter((item) => {
      if (item.portfolioName === portfolio) {
        newPlants.push({
          label: item.plantName,
          value: item.plantId,
        });
      }
    });
    setPlant(newPlants);
    setloading(false);
  };

  const getAlarms = async (pageSize: number, PageNo: number) => {
    setLoadingAlarm(true);
    const data: any = await dispatch(
      getAlarmsByPlant(
        dateFrom,
        dateTo,
        pageSize,
        PageNo,
        selectedPlant,
        status,
        categoryId,
        equipmentId,
        alarmTypeId
      )
    );
    if (!data) return;
    // filter data for status field
    const res = {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        modifiedOn: getDateForMaps(item.modifiedOn),
        status: item.isActive ? "Active" : "Resolved",
        generationLoss: item.generationloss ? item.generationloss : 0,
        AccidentCreated: item.isAccidentCreated ? "yes" : "No",
      })),
    };

    setAlarms(res);
    setAlarmGenerationlossToday(data?.["todayGenerationloss"]);
    setTotalAlarms(data?.["totalAlarms"]);
    setActiveAlarms(data?.["activeAlarms"]);
    setAlarmGenerationlosstotal(data?.["totalGenerationloss"]);
    setLoadingAlarm(false);
  };

  const Pagination = (Count: number) => {
    let pagination = {
      options: [5, 10, 20],
      totalRecord: Count,
      onChange: (page: number, rowsPerPage: number) => {
        setRowsPerPage(rowsPerPage);
        getAlarms(rowsPerPage, page);
      },
    };
    return pagination;
  };

  return {
    trippingInverters,
    portfolio,
    underPerformingInverters,
    generationLoss,
    generationlossToday,
    totalGenerationloss,
    alarmType,
    allEquipments,
    category,
    equipment,
    equipmentList,
    plant,
    alarms,
    alarmGenerationlossToday,
    totalAlarms,
    trippingUnderperformingAlarms,
    activeAlarms,
    alarmGenerationlossTotal,
    Pagination,
    error,
    loading,
    loadingAlarm,
  };
};
