import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  overviewContainer: {
    margin: 0,
    padding: theme.spacing(2, 2, 2, 2),
    backgroundColor: "white",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
    marginLeft: theme.spacing(1),
  },
  items: {
    marginLeft: "1.8em",
    padding: theme.spacing(0, 3, 0, 0),
  },
  headerItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(3),
  },

  itemTitle: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#616161",
    marginLeft: theme.spacing(2),
  },

  itemValue: {
    fontSize: 15,
    fontWeight: 500,
    display: "flex",
    marginLeft: theme.spacing(2),
  },
  divider: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    color: "#A6A6A6",
  },
  line: {
    border: "1px solid #d1d3d4b5",
    margin: "0 5px 7px 7px",
  },
}));
