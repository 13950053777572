import React from "react";
import { Widget } from "../../../components/CardsPageWidget";
import MapLeaflet from "./MapLeaflet";

interface Props {
  widgets: Array<Widget>;
}

export const MapView: React.FC<Props> = (props) => {
  return (
    <>
      <MapLeaflet widgets={props.widgets} />
    </>
  );
};
