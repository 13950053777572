import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";

export interface IReportState {
  gettingTemplates?: boolean;
  reportsFailed?: boolean;
  reportsSuccessful?: boolean;
  templates?: any[];

  gettingTemplateById?: boolean;
  reportByIdFailed?: boolean;
  reportByIdSuccessful?: boolean;

  generatingCustomReport?: boolean;
  generateCustomReportFailed?: boolean;
  generateCustomReportSuccessful?: boolean;

  gettingPortfolioReport?: boolean;
  portfolioReportFailed?: boolean;
  portfolioReportSuccessful?: boolean;

  gettingPortfolioPerReport?: boolean;
  portfolioPerReportFailed?: boolean;
  portfolioPerReportSuccessful?: boolean;

  gettingReportColumns?: boolean;
  reportColumnsFailed?: boolean;
  reportColumnsSuccessful?: boolean;

  downloadingReport?: boolean;
  downloadReportFailed?: boolean;
  downloadReportSuccessful?: boolean;

  creatingCustomReport?: boolean;
  createCustomReportFailed?: boolean;
  createCustomReportSuccessful?: boolean;

  updatingCustomTemplate?: boolean;
  updateCustomTemplateFailed?: boolean;
  updateCustomTemplateSuccessful?: boolean;

  deletingCustomTemplate?: boolean;
  deleteCustomTemplateFailed?: boolean;
  deleteCustomTemplateSuccessful?: boolean;
}
const initialState: IReportState = {
  gettingTemplates: false,
  reportsFailed: false,
  reportsSuccessful: false,
  templates: [],
  gettingTemplateById: false,
  reportByIdFailed: false,
  reportByIdSuccessful: false,
  generatingCustomReport: false,
  generateCustomReportFailed: false,
  generateCustomReportSuccessful: false,
  gettingPortfolioReport: false,
  portfolioReportFailed: false,
  portfolioReportSuccessful: false,
  gettingPortfolioPerReport: false,
  portfolioPerReportFailed: false,
  portfolioPerReportSuccessful: false,
  gettingReportColumns: false,
  reportColumnsFailed: false,
  reportColumnsSuccessful: false,
  downloadingReport: false,
  downloadReportFailed: false,
  downloadReportSuccessful: false,
  creatingCustomReport: false,
  createCustomReportFailed: false,
  createCustomReportSuccessful: false,
  updatingCustomTemplate: false,
  updateCustomTemplateFailed: false,
  updateCustomTemplateSuccessful: false,
  deletingCustomTemplate: false,
  deleteCustomTemplateFailed: false,
  deleteCustomTemplateSuccessful: false,
};
// name
// reducer
// actions
// caseReducers
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    // get templates
    templateStart: (state) => {
      return {
        ...state,
        gettingTemplates: true,
      };
    },
    templateSuccess: (state, { payload }) => {
      return {
        ...state,
        gettingTemplates: false,
        reportsSuccessful: true,
        reports: payload,
      };
    },
    templateError: (state) => {
      return {
        ...state,
        gettingTemplates: false,
        reportsFailed: true,
      };
    },
    // get template by id
    templateByIdStart: (state) => {
      return {
        ...state,
        gettingTemplateById: true,
      };
    },
    templateByIdSuccess: (state) => {
      return {
        ...state,
        gettingTemplateById: false,
        reportByIdSuccessful: true,
      };
    },
    templateByIdError: (state) => {
      return {
        ...state,
        gettingTemplateById: false,
        reportByIdFailed: true,
      };
    },
    // generate custom report
    generateCustomReportStart: (state) => {
      return {
        ...state,
        generatingCustomReport: true,
      };
    },
    generateCustomReportSuccess: (state) => {
      return {
        ...state,
        generatingCustomReport: false,
        generateCustomReportSuccessful: true,
      };
    },
    generateCustomReportError: (state) => {
      return {
        ...state,
        generatingCustomReport: false,
        generateCustomReportFailed: true,
      };
    },
    // portfolio report
    portfolioReportStart: (state) => {
      return {
        ...state,
        gettingPortfolioReport: true,
      };
    },
    portfolioReportSuccess: (state) => {
      return {
        ...state,
        gettingPortfolioReport: false,
        portfolioReportSuccessful: true,
      };
    },
    portfolioReportError: (state) => {
      return {
        ...state,
        gettingPortfolioReport: false,
        portfolioReportSuccessful: true,
      };
    },
    // portfolio performance report
    portfolioPerReportStart: (state) => {
      return {
        ...state,
        gettingPortfolioPerReport: true,
      };
    },
    portfolioPerReportSuccess: (state) => {
      return {
        ...state,
        gettingPortfolioPerReport: false,
        portfolioPerReportSuccessful: true,
      };
    },
    portfolioPerReportError: (state) => {
      return {
        ...state,
        gettingPortfolioPerReport: false,
        portfolioPerReportFailed: true,
      };
    },
    // get report columns
    reportColumnsStart: (state) => {
      return {
        ...state,
        gettingReportColumns: true,
      };
    },
    reportColumnsSuccess: (state) => {
      return {
        ...state,
        gettingReportColumns: false,
        reportColumnsSuccessful: true,
      };
    },
    reportColumnsError: (state) => {
      return {
        ...state,
        gettingReportColumns: false,
        reportColumnsFailed: true,
      };
    },
    // download custom report
    downloadReportStart: (state) => {
      return {
        ...state,
        downloadingReport: true,
      };
    },
    downloadReportSuccess: (state) => {
      return {
        ...state,
        downloadingReport: false,
        downloadReportSuccessful: true,
      };
    },
    downloadReportError: (state) => {
      return {
        ...state,
        downloadingReport: false,
        downloadReportFailed: true,
      };
    },
    // creating custom report
    createCustomReportStart: (state) => {
      return {
        ...state,
        creatingCustomReport: true,
      };
    },
    createCustomReportSuccess: (state) => {
      return {
        ...state,
        creatingCustomReport: false,
        createCustomReportSuccessful: true,
      };
    },
    createCustomReportError: (state) => {
      return {
        ...state,
        creatingCustomReport: false,
        createCustomReportFailed: true,
      };
    },
    // update custom template
    updateCustomTemplateStart: (state) => {
      return {
        ...state,
        updatingCustomTemplate: true,
      };
    },
    updateCustomTemplateSuccess: (state) => {
      return {
        ...state,
        updatingCustomTemplate: false,
        updateCustomTemplateSuccessful: true,
      };
    },
    updateCustomTemplateError: (state) => {
      return {
        ...state,
        updatingCustomTemplate: false,
        updateCustomTemplateFailed: true,
      };
    },
    // delete custom template
    deleteCustomTemplateStart: (state) => {
      return {
        ...state,
        deletingCustomTemplate: true,
      };
    },
    deleteCustomTemplateSuccess: (state) => {
      return {
        ...state,
        deletingCustomTemplate: false,
        deleteCustomTemplateSuccessful: true,
      };
    },
    deleteCustomTemplateError: (state) => {
      return {
        ...state,
        deletingCustomTemplate: false,
        deleteCustomTemplateFailed: true,
      };
    },
  },
});
// report actions
export const {
  templateStart,
  templateSuccess,
  templateError,
  templateByIdStart,
  templateByIdSuccess,
  templateByIdError,
  generateCustomReportStart,
  generateCustomReportSuccess,
  generateCustomReportError,
  portfolioReportStart,
  portfolioReportSuccess,
  portfolioReportError,
  portfolioPerReportStart,
  portfolioPerReportSuccess,
  portfolioPerReportError,
  reportColumnsStart,
  reportColumnsSuccess,
  reportColumnsError,
  downloadReportStart,
  downloadReportSuccess,
  downloadReportError,
  createCustomReportStart,
  createCustomReportSuccess,
  createCustomReportError,
  updateCustomTemplateStart,
  updateCustomTemplateSuccess,
  updateCustomTemplateError,
  deleteCustomTemplateStart,
  deleteCustomTemplateSuccess,
  deleteCustomTemplateError,
} = reportSlice.actions;
// report state
export const reportSelector = (state: RootState) => state.Sidem.report;
// report reducer
export const reportReducer = reportSlice.reducer;
