import { isAnyItemFalsy } from "./../../../shared/utils/utils";
import { useAppSelector } from "./../../../store/rootStore";
import {
  DEFAULT_EQUIPMENT_NAME,
  useGetBatteryPowerQuery,
} from "./BatteryMonitoring.api";
import { monitoringDashboardSelector } from "../../../features/Monitoring/monitoringDashboard/monitoringDashboardSlice";
import { useMemo } from "react";
import { useUrlParams } from "../../../shared/utils/hooks";
import { getValue, getUnit } from "./utils";

export const useBatteryPowerWidget = () => {
  const { plantId } = useUrlParams({
    plantId: "",
  });

  const { liveButton } = useAppSelector(monitoringDashboardSelector);

  const { data, isLoading, isFetching, isError, isUninitialized } =
    useGetBatteryPowerQuery(
      {
        plantId,
        equipmentName: DEFAULT_EQUIPMENT_NAME,
      },
      {
        skip: isAnyItemFalsy([plantId, DEFAULT_EQUIPMENT_NAME]),
        pollingInterval: liveButton ? 60 * 1000 : undefined,
      }
    );

  const result = useMemo(() => {
      return [
        {
          label: "Active power",
          value: getValue(data?.active),
          unit: getUnit(data?.active),
        },
        {
          label: "Reactive power",
          value: getValue(data?.reactive),
          unit: getUnit(data?.reactive),
        },
        {
          label: "Apparent power",
          value: getValue(data?.apparent),
          unit: getUnit(data?.apparent),
        },
        {
          label: "Power factor",
          value: getValue(data?.factor),
          unit: getUnit(data?.factor),
        },
      ];
  }, [data]);

  return {
    data: result,
    isLoading: isLoading || isUninitialized,
    isFetching,
    isError,
  };
};

export type StatusWidgetData = ReturnType<typeof useBatteryPowerWidget>;
