import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/rootReducers";
// CrossEquimentComparison
export interface ICrossEquimentComparisonState {
    gettingPortfolios?: boolean;
    portfoliosFailed?: boolean;
    portfoliosSuccessful?: boolean;

}

const initialState: ICrossEquimentComparisonState = {

    gettingPortfolios: false,
    portfoliosFailed: false,
    portfoliosSuccessful: false,
};


// name
// reducer
// actions
// caseReducers

export const crossEquimentComparisonSlice = createSlice({
    name: "crossequimentcomparison",
    initialState,
    reducers: {
        portfoliosStart: (state) => {
            return {
                ...state,
                gettingPortfolios: true,
            };
        },
        portfoliosSuccess: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosSuccessful: true,
            };
        },
        portfoliosError: (state) => {
            return {
                ...state,
                gettingPortfolios: false,
                portfoliosFailed: true,
            };
        },
    },
});

// CrossEquimentComparison actions
export const {
    portfoliosStart,
    portfoliosSuccess,
    portfoliosError,
} = crossEquimentComparisonSlice.actions;

// CrossEquimentComparison state

export const crossEquimentComparisonSelector = (state: RootState) =>
    state.Sidem.crossEquimentComparison;

// CrossEquimentComparison reducer

export const crossEquimentComparisonReducer = crossEquimentComparisonSlice.reducer;