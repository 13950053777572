import React, { useMemo } from "react";
import { CustomCombinedChart } from "../../../../components/CustomCombinedChart";
import { useChargeDischargeChartWidget } from "./useChargeDischargeChartWidget";
import { ChartWrapper } from "../ChartWrapper";

interface Props {
  height: number;
}

export const ChargeDischargeChartWidget: React.FC<Props> = (props: Props) => {
  const {
    plantId,
    startDate,
    endDate,
    liveButton,
    globalDate,
    dropdownOptions,
    buttonGroups,
    chartOptions,
  } = useChargeDischargeChartWidget();

  const { height } = props;

  const _chartOptions = useMemo(() => {
    if (!chartOptions || !chartOptions.chart) {
      return chartOptions;
    }
    const updatedOptions = { ...chartOptions };

    updatedOptions.chart.height = `${height}px`;
    return updatedOptions;
  }, [chartOptions, height]);

  return (
    <ChartWrapper>
      <CustomCombinedChart
        menu={{
          dropdown: dropdownOptions,
          buttonGroups: buttonGroups,
        }}
        chartOptions={_chartOptions}
      />
    </ChartWrapper>
  );
};
