import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTenant } from "./useTenant";
import { Card } from "@material-ui/core";
import {
  Search,
  MenuWithBtn,
  IMenuItem,
  Button,
} from "../../../shared/components";
import { Button2 } from "../../../shared/components/Button2/Button2.component";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { CustomTable } from "../../../shared/components/Table/Table.component";
import Loader from "../../components/shared/Loader";
import { useStyles } from "./styles";
import { searchData } from "./../components/Utils/utils";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

import {
  tenantViewHeaderCell,
  tenantViewTableType,
  tenantFilters,
} from "./components/interfaces";

export const Tenant: React.FC = () => {
  const classes = useStyles();

  const { tenants, loading } = useTenant();

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  const onNewTenant = () => {
    navigate(`/onboarding/tenant/new`);
  };

  useEffect(() => {
    if (!tenants) return;
    if (search && search != "") {
      setFilteredData(searchData(search, tenants, tenantFilters));
    } else {
      setFilteredData(tenants);
    }
  }, [search, tenants]);

  const searchHandler = (newSearch: string) => {
    setSearch(newSearch);
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageLabel}>Tenants </div>

      <Card style={{ padding: "20px" }}>
        <div style={{ float: "right" }}>
          <Button2
            size={40}
            type={"primary"}
            onClick={() => onNewTenant()}
            iconRight={<ControlPointIcon sx={{ fontSize: "24px" }} />}
          // iconLeft={<InfoIcon />}
          >
            Add
          </Button2>
        </div>

        {/* <Button
          color={"rgb(43, 41, 88)"}
          type="button"
          title={"Add"}
          showIcon={true}
          className={`${classes.floatRight} ${classes.marginBottom}`}
          onClick={() => onNewTenant()}
        >
          Add
        </Button> */}
        <div style={{ backgroundColor: "  red" }}>
          <Tooltip
            title="Click here to add tenant"
            className={`${classes.floatRight}`}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>

        <br />
        {loading ? (
          <>
            <CustomTable
              module={"/onboarding/"}
              tableType={tenantViewTableType}
              headerCells={tenantViewHeaderCell}
              data={[]}
              isDoubleClickActive={true}
              onDeleteClick={(id) => { }}
            />
            <Loader
              loading={loading}
              height={300}
              error={""}
              errorTextSize={32}
            />
          </>
        ) : (
          <>
            <div style={{ maxWidth: "400px" }}>
              <Search
                placeholder="Search for tenant"
                value={search}
                onChange={searchHandler}
                onKeyUp={searchHandler}
              />
            </div>
            <br />
            <CustomTable
              module={"/onboarding/"}
              maxHeight={"76vh"}
              tableType={tenantViewTableType}
              headerCells={tenantViewHeaderCell}
              data={filteredData}
              isDoubleClickActive={true}
              onDeleteClick={(id) => { }}
            />
          </>
        )}
      </Card>
    </div>
  );
};
