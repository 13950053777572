import moment from "moment";
import momentTZ from "moment-timezone";
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export const getTimeArray = (data: string): Array<any> => {
  let days = 0;
  let hours = "00";
  let minutes = "00";

  const hasDays = data?.includes(".");
  const [daysString, timeString] = data?.split(".");

  if (hasDays) {
    days = parseInt(daysString);
    hours = timeString?.slice(0, 2);
    minutes = timeString?.slice(3, 5);
  } else {
    hours = data?.slice(0, 2);
    minutes = data?.slice(3, 5);
  }

  return [days, hours, minutes];
};

export const calculateEndDate = (date: string, duration: string): string => {
  if (!date || !duration?.length) return null;

  const [days, hours, minutes] = getTimeArray(duration);

  const finalDate = new Date(date);
  finalDate.setDate(finalDate.getDate() + parseInt(days));
  finalDate.setHours(finalDate?.getHours() + parseInt(hours));
  finalDate.setMinutes(finalDate?.getMinutes() + parseInt(minutes));

  return finalDate?.toISOString();
};

export const convertGetArrayParam = (key: string, values: Array<any>) =>
  values?.reduce((str, curr) => (str += key + "=" + curr + "&"), "");

export const getFullDate = (date) => moment(date).format("MMMM YYYY");

export const convertToIST = (date) => {
  let time = new Date(date).toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  time = moment(time).format("HH:mm");
  return time;
};

export const convertTimeZone = (
  tz?: string,
  format?: string,
  timeStamp?: string,
  zone?: string
) => {
  const time = momentTZ(`${timeStamp}Z`);
  return zone
    ? time.tz(`${tz}`).format(`${format} ${zone}`)
    : time.tz(`${tz}`).format(`${format}`);
  ///
};

export const convertTimeZoneForPlants = (
  tz?: string,
  format?: string,
  timeStamp?: string | Date,
  zone?: string
) => {
  const time = moment(`${timeStamp}`);
  return zone
    ? time.tz(`${tz}`).format(`${format} ${zone}`)
    : time.tz(`${tz}`).format(`${format}`);
};

export const getPlantTime = (
  timeZone: string,
  showPlantCurrentDate: boolean,
  timeFormat: string
) => {
  const plantTime = convertTimeZoneForPlants(
    timeZone,
    showPlantCurrentDate ? "MMM Do HH:mm z" : "HH:mm z",
    new Date()
  );
  if (timeFormat === "12 hours") {
    if (moment(plantTime, "MMM Do HH:mm Z").isValid()) {
      return moment(plantTime, "MMM Do HH:mm Z").format("MMM Do hh:mm A");
    } else {
      return moment(plantTime, "HH:mm z").format("hh:mm A");
    }
    if (moment(plantTime, "MMM Do HH:mm Z").isValid()) {
      return moment(plantTime, "MMM Do HH:mm Z").format("MMM Do hh:mm A");
    } else {
      return moment(plantTime, "HH:mm z").format("hh:mm A");
    }
  } else if (timeFormat === "24 hours") {
    if (moment(plantTime, "MMM Do HH:mm Z").isValid()) {
      return moment(plantTime, "MMM Do HH:mm Z").format("MMM Do HH:mm");
    } else {
      return moment(plantTime, "HH:mm z").format("HH:mm");
    }
    if (moment(plantTime, "MMM Do HH:mm Z").isValid()) {
      return moment(plantTime, "MMM Do HH:mm Z").format("MMM Do HH:mm");
    } else {
      return moment(plantTime, "HH:mm z").format("HH:mm");
    }
  }
};

export const compareUserDateWithPlantDate = (
  plantTimeZone: string,
  userTimeZone: string
) => {
  const userCurrentDate = moment().tz(userTimeZone).format("YYYY-MM-DD");
  const plantCurrentDate = moment().tz(plantTimeZone).format("YYYY-MM-DD");
  if (userCurrentDate === plantCurrentDate) {
    return false;
  } else {
    return true;
  }
};

export const hoursAgoForAlarms = (
  timeStamp?: string | Date,
  timeZone?: string
) => {
  let now = new Date(new Date().toLocaleString("en", { timeZone: timeZone }));
  let temp = momentTZ(`${timeStamp}Z`);
  const newtemp = temp.tz(`${timeZone}`).format();
  let plantTime = new Date(
    new Date(newtemp).toLocaleString("en", { timeZone: timeZone })
  );
  const difference = dateDiffInDays(now, plantTime, 3600000);
  return Math.abs(difference);
  ///
};

function dateDiffInDays(a, b, time) {
  // const _MS_PER_DAY = 60000;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes()
  );
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes()
  );

  return Math.floor((utc2 - utc1) / time);
}

export const totalTimeAgo = (timeStamp?: string | Date, timeZone?: string) => {
  let now = new Date(new Date().toLocaleString("en", { timeZone: timeZone }));
  let plantTime = new Date(new Date(timeStamp).toLocaleString("en"));
  const difference = dateDiffInDays(now, plantTime, 60000);
  return convertTotalTimeAgo(Math.abs(difference));
  ///
};

const pluralize = (count, noun) => {
  return count === 1 ? noun : noun + "s";
};

export const convertTotalTimeAgo = (minutes: number) => {
  if (minutes >= 60) {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    if (hours >= 24) {
      let days = Math.floor(hours / 24);
      hours = hours % 24;

      if (days > 0) {
        return `${days} ${pluralize(days, "day")}`;
      } else {
        return `${hours} ${pluralize(hours, "hour")}`;
      }
    } else {
      return `${hours} ${pluralize(hours, "hour")}`;
    }
  } else {
    return `${minutes} min`;
  }
};

export const minsAgo = (timeStamp?: string | Date, timeZone?: string) => {
  let now = new Date(new Date().toLocaleString("en", { timeZone: timeZone }));
  let plantTime = new Date(new Date(timeStamp).toLocaleString("en"));
  const difference = dateDiffInDays(now, plantTime, 60000);
  return Math.abs(difference);
  ///
};

export const convertTime = (timeStamp?: string | Date, format?: string) => {
  if (typeof timeStamp === "string") {
    if (isValidDateFormat(timeStamp)) {
      return timeStamp;
    }
  }
  const time = moment(timeStamp, "").format(format);
  return time;
};

//check for all browsers
export const isDateInInvalidFormat = (dateString: string) => {
  const invalidFormatRegex = /^\d{4}[./-]\d{2}[./-]\d{2}$/;
  return (
    invalidFormatRegex.test(dateString) || isNaN(new Date(dateString).getTime())
  );
};

export const convertDateFormat = (dateString) => {
  if (isValidDateFormat(dateString)) {
    return moment(dateString).format("YYYY, MM, DD");
  }
  // Array of month abbreviations
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Split the dateString into parts: YYYY, MM, DD
  const [year, month, day] = dateString.split(".");

  // Convert the month number (MM) to the corresponding month abbreviation
  const monthName = months[parseInt(month) - 1]; // Convert to zero-based index

  // Return the date in the format DD MMM, YYYY
  return `${day} ${monthName}, ${year}`;
};

const isValidDateFormat = (dateString: string) => {
  // Regular expression to match YYYY.MM.DD format
  const pattern = /^\d{4}\.\d{2}\.\d{2}$/;

  // Test the string against the pattern
  return pattern.test(dateString);
};

export const checkDate = (urlDate, globalDate) => {
  if (!urlDate) {
    if (!globalDate) return new Date().toISOString();
    return globalDate.toString();
  } else return urlDate;
};

export const convertkWToMW = (val: number) => {
  if (val > 1000) return val / 1000;
  else return val;
};

export const kWOrMW = (val: number) => {
  if (val > 1000) return "MW";
  else return "kW";
};

export const kWhOrMWh = (val: number) => {
  if (val > 1000) return "MWh";
  else return "kWh";
};

export const convertUnitForGraphs = (val: number, unit?: string) => {
  let valToReturn;
  let unitToReturn;
  if (val > 1000) {
    if (unit === "kW") {
      valToReturn = (val / 1000).toFixed(2);
      unitToReturn = "MW";
      return valToReturn + " " + unitToReturn;
    } else if (unit === "kWh") {
      valToReturn = (val / 1000).toFixed(2);
      unitToReturn = "MWh";
      return valToReturn + " " + unitToReturn;
    } else return val.toFixed(2) + " " + unit;
  } else return val.toFixed(2) + " " + unit;
};

export const getYesterday = (tz?: string, date?: string | Date) => {
  const time = momentTZ(date);
  return time.tz(`${tz}`).subtract(1, "days").format(`D MMMM, YYYY`);
};
export const convertTimeZoneName = (
  tz?: string,
  format?: string,
  timeStamp?: string,
  zone?: string
) => {
  const time = momentTZ(`${timeStamp}Z`);
  return time.tz(`${tz}`).format(`z`);
};
export const get24Hours = (tz?: string, format?: string, hour?: number) => {
  const year = moment().format("YYYY");
  const month = moment().format("MM");
  const day = moment().format("D");
  const hours = moment({ hour }).format("HH");
  const min = moment(0).format("mm");

  let newDate = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hours),
    Number(min)
  ).toUTCString();
  let UTCTime = momentTZ(newDate);
  return UTCTime.tz(`${tz}`).format(`${format}`);
};

export const getDateForMaps = (t?: string) => {
  return moment(t).format("D-MM-YYYY");
};

export const getDateForDownloadGraph = (t?: string) => {
  return moment(t).format("YYYY.MM.D");
};

export const autoRefreshApi = (callback, time: number = 1) => {
  return setInterval(() => callback(), time * 60 * 1000);
};

export const autoRefreshApiTime = (callback, minutes: number) => {
  return setInterval(() => callback(), minutes * 60 * 1000);
};

export const separateValues = (inputArray) => {
  if (inputArray.includes("")) {
    return [];
  }
  const inputString = inputArray[0];

  const commaSeparated = inputString.split(",");
  const separatedValues = [];
  commaSeparated.forEach((substring) => {
    const values = substring.split("+");

    separatedValues.push({ label: values[0], value: values[1] });
  });
  return separatedValues;
};

export const formSchemaFormatType = {
  GranularityOptions: [
    { label: "1 min", value: "1m" },
    { label: "5 min", value: "5m" },
    { label: "10 min", value: "10m" },
    { label: "30 min", value: "30m" },
    { label: "1 hour", value: "1h" },
  ],
};

export const getQueryParams = (args: { [key: string]: string | number }) => {
  return `?${Object.entries(args)
    .filter(
      ([_, value]) =>
        value !== undefined &&
        value !== "" &&
        value !== null &&
        value !== "null"
    )
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
};

export const addCommasToNumber = (num: any) => {
  if (!num) return "";
  let numStr = Math.round(num).toString();
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isAnyItemFalsy = (array: unknown[]) =>
  array.some(
    (item) =>
      !item || item === "null" || item === "undefined" || item === "false"
  );

export const isAnyObjectValueNull = (obj: object) =>
  Object.values(obj).some((key) => key === null);

export const getSelectedString = <T>(option: T[], type: keyof T) => {
  let optionString = "";
  if (!option) return "";
  if (option.length === 1) {
    option.forEach((element) => {
      optionString = optionString + element[type];
    });
  }
  if (option.length > 1) {
    option.forEach((element) => {
      optionString = `${element[type]},` + optionString;
    });
    optionString = optionString.substring(0, optionString.length - 1);
  }
  return optionString;
};

export const addZeroToTimeIfMissing = (time: number | string) => {
  let parsedTime = Number(time);
  return parsedTime > 9 ? parsedTime : "0" + parsedTime;
};

export enum GLOBAL_COLORS {
  LIGHT_PRIMARY_GRAY_40 = "#E1E4E9",
  LIGHT_PRIMARY_PRIMARY = "#0C1E33",
  LIGHT_PRIMARY_PRIMARY_WITH_OPACITY = "#0C1E3399",
  LIGHT_ALARMS_ERROR = "#808080",
  LIGHT_ALARMS_CRITICAL = "#DE504F",
  CHART_ERROR_BAR = "#F34646",
  WHITE = "#FFFFFF",
  LIGHT_GREEN_BACKGROUND = "#49937D",
  LIGHT_ALARMS_SUCCESS = "#79BEAA",
  BATTERY_LOW = "#FF8730",
  BATTERY_MEDIUM = "#FFEA30",
  BATTERY_HIGH = "#B8CE5B",
  LIGHT_PRIMARY_ACCENT = "#1572DF",
  BORDER_LIGHT_SECONDARY = "#E4E2E4",
  BLACK = "#000000",
  LIGHTGREY = "#F5F5F5",
  LIGHT_TEXT_SECONDARY = "#858E99",
  DATA_VIZ_GRAY_30 = "#D7D7D7",
}

export const parseJSON = (inputString) => {
  try {
    const parsedData = JSON?.parse(inputString);
    return parsedData;
  } catch (error) {
    console.log(error, `error`);
  }
};

export const areAllSame = (arr) => {
  // If the array has less than 2 elements, return true
  if (arr.length < 2) {
    return true;
  }

  // Store the first element of the array for comparison
  const firstElement = arr[0];

  // Loop through the array starting from the second element
  for (let i = 1; i < arr.length; i++) {
    // If any element is not equal to the first element, return false
    if (arr[i] !== firstElement) {
      return false;
    }
  }

  // If all elements are equal to the first element, return true
  return true;
};

export const checkForNull = (arr) => {
  // Check if all values are null
  if (arr?.every((val) => val === null)) {
    // If all values are null, replace them with "1"
    arr.fill("1");
  }
  return arr;
};

export const changeNullToString = (arr: string[], valueChangeTo: string) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null) {
      arr[i] = valueChangeTo;
    }
  }
  return arr;
};

export const hexToRGBA = (hex: string, alpha: number) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export const getMatrix = (
  rows: number,
  columns: number,
  min: number = 0,
  max: number = 100
): number[][] => {
  const result: number[][] = [];

  for (let x = 0; x < rows; x++) {
    for (let y = 0; y < columns; y++) {
      let randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
      result.push([x, y, randomValue]);
    }
  }

  return result;
};

export const diffDateDays = (
  startDate: Date | string,
  endDate: Date | string
) => {
  return Math.floor(
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 60 * 60 * 24)
  );
};

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

export function getShortenedNumber(num: number): string {
  if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 1000000) {
    let formattedNum = (num / 1000).toFixed(1);
    if (formattedNum.endsWith(".0")) {
      formattedNum = formattedNum.slice(0, -2);
    }
    return formattedNum + "K";
  } else {
    let formattedNum = (num / 1000000).toFixed(1);
    if (formattedNum.endsWith(".0")) {
      formattedNum = formattedNum.slice(0, -2);
    }
    return formattedNum + "M";
  }
}

export const findLastIndex = <T>(
  array: T[],
  callback: (item: T) => boolean
): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (callback(array[i])) {
      return i;
    }
  }
  return -1;
};

export const convertByteArraryToBlob = (data: Uint8Array, type: string) => {
  if (!data) return null;
  let bytes = new Uint8Array(data?.length);
  for (var i = 0; i < data?.length; i++) {
    bytes[i] = data[i];
  }
  return new Blob([bytes], { type: type });
};

export function formatBytes(bytes) {
  if (bytes < 1024) {
    return bytes + " bytes";
  } else if (bytes < 1048576) {
    // 1024 * 1024
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return (bytes / 1048576).toFixed(2) + " MB";
  }
}
export const parseToFixed = (value: number, zerosCount: number = 2): number =>
  value % 1 !== 0 ? parseFloat(value.toFixed(zerosCount)) : value;

export const getHoursAndMinutesFromISO = (isoString: string): string => {
  const date = new Date(isoString);
  const hours = addZeroToTimeIfMissing(date.getHours());
  const minutes = addZeroToTimeIfMissing(date.getMinutes());

  return `${hours}:${minutes}`;
};

export const getModuleID = (modulesArray = [], moduleType: string) => {
  const module = modulesArray.find(
    (module) => module.moduleName === moduleType
  );
  return module ? module.moduleId : null;
};

export const isNullOrUndefined = (value: unknown) =>
  value === null || value === undefined;
