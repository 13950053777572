import { Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { useStyles } from './styles';
import { Button, Snackbar } from '../../../../shared/components';
import { appTheme } from '../../../../app/theme';

interface Props {
    logoType: string;
    logo: string;
    logoSize: string;
    uploadLogo: (file: File) => void;
    removeLogo: () => void;
    deleteLogo: () => void;
    logoLoading: boolean;
    logoName: string;
}
export const LogosWidget = (props: Props) => {

    const {
        logoType,
        logo,
        logoSize,
        uploadLogo,
        removeLogo,
        deleteLogo,
        logoLoading,
        logoName,
    } = props;

    const classes = useStyles();
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const onImageChange = async (event) => {
        if (event.target.files && event.target.files?.length > 0) {
            const file = event.target.files[0]
            for (let i = 0; i < event.target.files?.length; i++) {
                let img = event.target.files[i];
                let extension =
                    img.name.substring(img.name.lastIndexOf(".") + 1, img.name.length) ||
                    img.name;
                if (
                    extension?.toLowerCase() !== "svg"
                ) {
                    setMessage("Please upload a svg file");
                    setSeverity("error");
                    setIsOpen(true);
                    continue;
                }
                uploadLogo(file);
            }
        }
    };
    const triggerFileInput = () => {
        const fileInput = document.getElementById(`file-input-${logoType}`);
        fileInput.click(); // Simulate a click event on the file input
    };

    return (
        <div className={classes.logoSpacing}>
            <div className={classes.logoHeader}>
                {logoType}
            </div>
            {
                logoLoading ?
                    <div style={{ padding: "10px 0px 10px 0px" }}>
                        <Skeleton variant="rounded" height={80} />
                    </div>
                    :
                    <div className={classes.logoBackground}>
                        <Grid container>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                                {
                                    !logo ?
                                        <div className={classes.noLogo}>
                                            No logo added
                                        </div> :
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                {
                                                    logoLoading ?
                                                        <Skeleton variant="rounded" width={50} height={50} />
                                                        :
                                                        <img
                                                            alt={`${logoType}`}
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                marginRight: "1px",
                                                                borderRadius: "5px",
                                                                cursor: "default",
                                                                display: "inline-block",
                                                                background: "#CACFD7",
                                                            }}
                                                            title={`${logoType}`}
                                                            src={logo}
                                                        />
                                                }
                                            </div>
                                            <div>
                                                <div className={classes.logoName}>
                                                    {logoName}
                                                </div>
                                                <div className={classes.logoSizeText}>
                                                    {logoSize} KB
                                                </div>
                                            </div>

                                        </div>
                                }
                            </Grid>

                            <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <input
                                    type="file"
                                    id={`file-input-${logoType}`}
                                    style={{ display: 'none' }}
                                    accept="image/svg+xml"
                                    onChange={(e) => onImageChange(e)}
                                />
                                <span style={{ paddingLeft: "10px" }}>
                                    {
                                        logo &&
                                        <Button
                                            title='Delete'
                                            onClick={() => {
                                                removeLogo()
                                            }}
                                            outlined
                                            color={appTheme.main}
                                        />
                                    }
                                </span>
                                <span style={{ paddingLeft: "10px" }}>
                                    <Button
                                        title='Set default'
                                        onClick={() => {
                                            deleteLogo()
                                        }}
                                        outlined
                                        color={appTheme.main}
                                    />
                                </span>
                                <span style={{ paddingLeft: "10px" }}>
                                    <Button
                                        title='Upload'
                                        onClick={() => {
                                            triggerFileInput()
                                        }}
                                        color={appTheme.main}
                                    />
                                </span>
                            </Grid>
                        </Grid>
                        <Snackbar severity={severity} open={isOpen} message={message} handleClose={() => setIsOpen(false)} />
                    </div>
            }
        </div>
    )
}
