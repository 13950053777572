import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { Grid, Tooltip } from "@material-ui/core";
import moment from "moment";

import CardsPageWidget, {
  Widget,
  WidgetConfig,
} from "../../../components/CardsPageWidget";
import Loader from "../../../components/Loader";
import CandleChart from "../../../components/CandleChart";
import WeatherList from "../../../components/WeatherList";
import { ChartTypes } from "../../../components/CardsCharts";
import { useSharedFormState } from "./../../../../shared/utils/sharedStates";
import { useStyles } from "./styles";
import { Format } from "..";
import { getPlantProfile } from "../../../../features/Sidem/portfolios/plants/plantsActions";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../../store/rootStore";
import { useGetPlantProfileQuery } from "../../../../features/Sidem/shared/sidemSharedApi";

const definition = {
  Capacity: "It shows the Plant AC and DC Capcity",
  "Generation (kWh)":
    "It shows the actual irradiation | expected irradiation and irradiation delta for the plant",
  "Irradiation (kWh/m²)":
    "It shows the actual irradiation | expected irradiation and irradiation delta for the plant",
  Availability:
    "It shows the actual availability | expected availability and availability delta for the plant",
  "CUF/PLF": "It shows the actual CUF | expected CUF",
  Alert: "It shows the number of alert related to that plant",
  "Generation Delta":
    "It shows the generation delta from the expected generation",
  PR: "It shows the actual PR for the plant",
  Inverters:
    "It shows the number of inverter operational and number of inverter installed in that plant",
  "Generation last 7D":
    "It shows the bar chart of last 7 days generation and line chart of last 7 days irradiation for the portfolio",
  "Production factor":
    "Production factor is the diffence between generation delta and irradiation delta",
  "Generation & Irradiation delta":
    "Generation delta and irradiation delta is the % difference between the actaul and expected numbers of generation and irradiation respectively",
  "Last 7 days": "",
  "Last 30 days": "",
  "Grid Availability": "Loss due to the grid outages during generation hours",
};

const WeatherIconsFeature = ({ plantId }: { plantId: string }) => {
  const [weathers, setWeathers] = useState([]);

  const dispatch = useAppDispatch();
  // TODO: kaz this component is not used, hook below is not tested. Use it or remove - to investigate.
  // const { data, isFetching, error } = useGetPlantProfileQuery({ plantId }, { skip: !plantId })

  useEffect(() => {
    const getData = async () => {
      const dt: any = await dispatch(getPlantProfile(plantId));
      if (dt) {
        localStorage.setItem("profileData", "");
        setWeathers(
          dt?.weathers?.map((item) => ({
            ...item,
            dateTime: item.date,
          }))
        );
      }
    };
    getData();
  }, []);

  const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
  const today = moment().subtract(0, "days").format("DD-MM-YYYY");
  const tomorrow = moment().subtract(-1, "days").format("DD-MM-YYYY");
  const filteredWeathers = weathers?.filter(
    (weather) =>
      weather.date === yesterday ||
      weather.date === today ||
      weather.date === tomorrow
  );
  return <WeatherList size={40} weathers={filteredWeathers} />;
};

interface Props {
  loading: boolean;
  error: string;
  portfolioName: string;
  widgets: Array<Widget>;
  headerData: any;
  format: string;
  formatTypes: Array<Format>;
  setFormat: (val: string) => void;
  imageService: any;
  updateWidgetChart: (widget: Widget, chartType: ChartTypes) => void;
}

export const CardsView: React.FC<Props> = (props) => {
  const {
    loading,
    error,
    widgets,
    format,
    formatTypes,
    setFormat,
    imageService,
  } = props;

  const { viewDefinitions } = useSharedFormState();

  const classes = useStyles();
  const navigate = useNavigate();
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const onGoToPage = (widget: Widget) => () => {
    navigate(`/sidem/portfolios/${portfolioId}/plant/${widget.path}`);
  };

  const getTitleRightPart = (widget: Widget) => (
    <div className={classes.menuButtons}>
      <WeatherIconsFeature plantId={widget.path} />
    </div>
  );
  const getCustomFooter = ({
    pr,
    errors,
    workingInverters,
    totalInverters,
    actualResult,
    expectedResult,
  }: Widget) => (
    <>
      <div className={classes.chartWrapper}>
        <CandleChart
          actualResult={actualResult}
          expectedResult={expectedResult}
        />
      </div>

      <div className={classes.buttons}>
        <Tooltip title={!viewDefinitions ? "" : definition.PR}>
          <div className={classes.button}>PR: {pr}</div>
        </Tooltip>
        <Tooltip title={!viewDefinitions ? "" : definition.Inverters}>
          <div
            className={clsx(classes.button, classes.rightBtn, {
              [classes.errorBtn]: errors,
            })}
          >
            Inverters: {workingInverters}/{totalInverters}
          </div>
        </Tooltip>
      </div>
    </>
  );

  const getConfig = (widget: Widget): WidgetConfig => ({
    showTitle: true,
    titleRightPart: getTitleRightPart(widget),
    customFooter: getCustomFooter(widget),
  });
  const arrangeData = (item: any) => {
    const inverters = {
      title: "Inverters",
      value: `${item["totalInverters"] ? item["totalInverters"] : 0}`,
      unit: "installed",
      prevValue: `${item["totalInverters"] && item["workingInverters"]
        ? item["totalInverters"] - item["workingInverters"]
        : 0
        }`,
      prevValueUnit: "down",
    };
    // i2
    const delta = {
      title: "Generation delta",
      value: `${item["profit"]}`,
      unit: "KWh",
      negative: item["profit"] < 0 ? true : false,
      growth: item["profit"],
    };
    const CUF = {
      title: "CUF",
      value: item.fields[5].value ? item.fields[5].value : 0,
      negative: item["prDelta"] < 0 ? true : false,
      unit: "%",
      growth: item.fields[5].growth
    };
    // i4
    const alarms = {
      title: "Active alarms",
      value: item["errors"] !== null ? `${item["errors"]}` : `N/A`,
    };
    // i5
    const newData = [...item.fields];
    const i1 = item.fields.findIndex((o) => o.title === "Capacity");
    const i4 = item.fields.findIndex((o) => o.title === "Generation");
    const i10 = item.fields.findIndex((o) => o.title === "PR");
    const i5 = item.fields.findIndex((o) => o.title === "Irradiation");
    const i11 = item.fields.findIndex((o) => o.title === "TCPR");
    const i7 = item.fields.findIndex((o) => o.title === "Availability");
    const i9 = item.fields.findIndex((o) => o.title === "Grid Availability");
    const empty = {
      title: "",
      value: "",
    }

    const capacity = newData[i1];
    const generation = newData[i4];
    const pr = newData[i10];
    const irradiation = newData[i5];
    const availability = newData[i7];
    const gridAvailability = newData[i9];
    const tcpr = newData[i11];

    const nData = [
      inverters,
      capacity,
      delta,
      // prDelta,
      alarms,
      generation,
      irradiation,
      pr,
      tcpr,
      CUF,
      availability,
      // cufDelta,
      gridAvailability,
      empty //add an empty field to make line grid remove if we have more values
    ];

    return nData;
  };

  return (
    <>
      <Loader loading={loading} height={300} error={error} errorTextSize={32} />

      {!widgets?.length || loading ? null : (
        <Grid container spacing={2} className={classes.pageWrapper}>
          <div className={classes.widgets}>
            {widgets.map((item) => {

              const fields = arrangeData(item);
              return (
                <CardsPageWidget
                  format={format}
                  formatTypes={formatTypes}
                  imageService={imageService}
                  onSelectFormat={setFormat}
                  onClick={onGoToPage(item)}
                  key={item.title}
                  widget={item}
                  config={getConfig(item)}
                  definition={definition}
                  item={fields}
                  weatherFeature={<WeatherIconsFeature plantId={item?.path} />}
                  type={"CardsView"}
                  fieldCount={9}
                />
              );
            })}
          </div>
        </Grid>
      )}
    </>
  );
};
