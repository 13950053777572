import React, { useMemo } from 'react'
import { useStyles } from './styles';
import { colors } from '../../../../../../components/utils';
import { ColumnLineChart } from '../../../../../../../shared/components/ColumnLineChart';
import { useGenerationLast7D } from './useGenerationLast7DWidget';

export const GenerationLast7DWidget = () => {
    const { loading, xAxis, yAxis, generationLast7DData } = useGenerationLast7D();

    const classes = useStyles();

    const memoizedData = useMemo(() => {
        return (
            <>
                <div className={classes.wrapper}>
                    <ColumnLineChart
                        title='Generation last 7 days'
                        subtitle='Generation last 7 days'
                        colors={colors}
                        data={generationLast7DData}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        height={400}
                        tooltipString={''}
                        exporting={false}
                        loading={loading}
                    />
                </div>
            </>
        )
    }, [generationLast7DData, loading, yAxis, xAxis]);
    return memoizedData;
}
