import { autoRefreshApi } from "./../../../shared/utils/utils";
import { useEffect, useState } from "react";
import { soilingChartColors } from "./utils";
import { fetchAsync } from "../../../shared/utils/apiService";

export enum Types {
  Thirty = "30",
  Fifteen = "15",
  All = "all",
}

interface Item {
  equipmentName: string;
  soilingMain: number;
  category: string;
}

interface Props {
  plantId: string;
  date: string;
}

interface Data {
  data: any;
  error: string;
  loading: boolean;
}

export const useInverterSoiling = ({
  plantId,
  date,
}: Props): Data => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const setColor = (type) => {
    let color = "";
    switch (type) {
      case "High":
        color = soilingChartColors.high;
        break;
      case "Low":
        color = soilingChartColors.low;
        break;
      case "Medium":
        color = soilingChartColors.medium;
        break;
      case "Worst":
        color = soilingChartColors.critical;
        break;
      default:
        color = "FF9800";
        break;
    }
    return color;
  };
  const getSoilingLossInv = () => {
    fetchAsync(`/SkyfriSidemApi/v1/GetSoilingLossInv?`, "GET", {
      plantId,
      date,
    })
      .then((data: Array<Item>) => {
        if (!data?.length) {
          setError("No data");
          setLoading(false);
          return;
        }
        setError("");
        setLoading(true);
        data = data
          .sort(function (a, b) {
            return a.soilingMain - b.soilingMain;
          })
          .reverse();
        var newData = data?.map((item) => ({
          ...item,
          x: item.equipmentName,
          value: item.soilingMain + "%",
          y: item.soilingMain + 5,
          fillColor: setColor(item.category),
        }));

        setData(newData);
        setLoading(false);
        setError("");
      })
      .catch(setError);
  };
  useEffect(() => {
    if (!date) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setError("");
    getSoilingLossInv();

    const myInterval = autoRefreshApi(() => getSoilingLossInv());
    return () => clearInterval(myInterval);
  }, [plantId, date]);

  return {
    data,
    error,
    loading,
  };
};
